import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";

import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { map } from "rxjs/operators";
import { ConfigurationService } from "src/app/common/services/configuration.service";
import { LangChangeAction } from "src/app/root/store/index";
import { RootState } from "src/app/root/store/index";
import { AppEnvironment } from "src/environments/app-enviroment.model";

/**
 * Route Guard для страниц без пользователя.
 * - Проверяет флаг global в окружении. Если он true, то выставляет английский язык. Иначе - русский.
 */
@Injectable({
    providedIn: "root"
})
export class NoUserGuard implements CanActivate {
    //region Fields

    /**
     * Сервис по работе с конфигурациями окружения приложения.
     */
    private _configService: ConfigurationService;

    //endregion
    //region Ctor

    /**
     * Конструктор Route Guard-а.
     *
     * @param _store Сервис для управления и доступа к состоянию приложения.
     * @param configService Сервис по работе с конфигурациями окружения приложения.
     */
    constructor(private _store: Store<RootState>, configService: ConfigurationService) {

        this._configService = configService;
    }

    //endregion
    //region Public

    /**
     * Проверяет, можно ли осуществлять переход по текущему URL.
     *
     * - В данном случае, переход возможен всегда. Задача гуарда - преобразование данных.
     * - Проверяет флаг global в окружении. Если он true, то выставляет английский язык. Иначе - русский.
     *
     * @return Да/нет.
     */
    canActivate(): Observable<boolean> {

        return this._configService.loadConfigurations()
            .pipe(
                map((env: AppEnvironment) => env.global ? "en" : "ru"),
                tap((lang: string): void => this._store.dispatch(new LangChangeAction(lang))),
                map((): boolean => true),
            );
    }

    //endregion
}
