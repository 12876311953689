import { Store } from "@ngrx/store";
import { withLatestFrom } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { clientControlPageActions } from "src/app/admin/store/actions/client-control-page.action";
import { ClientControlPageStateSelectors } from "src/app/admin/store/selectors/client-control-page.selectors";
import { getRequestResult } from "src/app/common/models/request-state";
import { DlgService } from "src/app/common/services/dlg.service";
import { trustedEmailsAdditionActions } from "src/app/root/store/actions/trusted-emails-addition.action";
import { usersInvitationActions } from "src/app/root/store/actions/users-invitation.action";
import { currentUserSelectors } from "src/app/root/store/selectors/current-user.selector";
import { trustedEmailsAdditionSelectors } from "src/app/root/store/selectors/trusted-email-addition.selectors";
import { usersInvitationSelectors } from "src/app/root/store/selectors/users-invitation.selectors";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "../../common/services/dlg.service";
/**
 * Сервис для работы с диалогами с почтами и папками.
 */
var EmailsAndSpacesDlgService = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор сервиса для работы с диалогом приглашения пользователей в пространства документов.
     *
     * @param _store Состояние приложения.
     * @param dlgService Сервис для работы с диалогами.
     */
    function EmailsAndSpacesDlgService(_store, dlgService) {
        this._store = _store;
        this._dlgService = dlgService;
    }
    //endregion
    //region Public
    /**
     * Открывает диалог для приглашения пользователей в пространства документов.
     *
     * В зависимости от флага:
     * - предоставляет списки папок, доступных для приглашения
     * - требует/не требует обновления списка пользователей в админском интерфейсе.
     *
     *
     * @param asAdmin Флаг, который указывает, в каком интерфейсе открыт диалог.
     * @param preSelectedSpaces Список предварительно выбранных папок текущего пользователя.
     */
    EmailsAndSpacesDlgService.prototype.openInvitationDlg = function (asAdmin, preSelectedSpaces) {
        var _this = this;
        if (preSelectedSpaces === void 0) { preSelectedSpaces = []; }
        var dlg;
        var spacesSubscription;
        var requestStateSubscription;
        var dlgData = {
            headerKey: "dialogs.usersInvitation.title",
            okBtnKey: "dialogs.usersInvitation.applyButton",
            errorDescriptionKey: "dialogs.usersInvitation.errorDesc",
            successDescriptionKey: "dialogs.usersInvitation.success",
            openCallback: function () {
                _this._store.dispatch(usersInvitationActions.initialize());
                if (asAdmin) {
                    spacesSubscription = _this._store.select(ClientControlPageStateSelectors.clientSpaceList)
                        .pipe(filter(Boolean))
                        .subscribe(function (spaces) { return dlg.componentInstance.spaces = spaces; });
                }
                else {
                    spacesSubscription = _this._store.select(currentUserSelectors.spacesForInvitations)
                        .pipe(filter(Boolean))
                        .subscribe(function (spaces) { return dlg.componentInstance.spaces = spaces; });
                }
                dlg.componentInstance.preSelectedSpaces = preSelectedSpaces;
                requestStateSubscription = _this._store.select(usersInvitationSelectors.requestState)
                    .pipe(filter(Boolean))
                    .subscribe(function (state) {
                    dlg.componentInstance.requestState = state;
                    if (getRequestResult(state) && asAdmin) {
                        _this._store.dispatch(clientControlPageActions.updateUsersInfo(getRequestResult(state)));
                    }
                });
            },
            confirmCallback: function (data) {
                _this._store.dispatch(usersInvitationActions.invite({ value: data }));
            },
            closeCallback: function () { return dlg.close(); },
            destroyCallback: function () {
                spacesSubscription.unsubscribe();
                requestStateSubscription.unsubscribe();
            }
        };
        dlg = this._dlgService.openEmailsAndSpacesDlg(dlgData);
    };
    /**
     * Открывает диалог для добавления доверенных почт в пространства документов.
     */
    EmailsAndSpacesDlgService.prototype.openTrustedEmailsAdditionDlg = function () {
        var _this = this;
        var dlg;
        var spacesSubscription;
        var requestStateSubscription;
        var dlgData = {
            headerKey: "dialogs.trustedEmailsAddition.title",
            okBtnKey: "dialogs.trustedEmailsAddition.applyButton",
            errorDescriptionKey: "dialogs.trustedEmailsAddition.errorDesc",
            successDescriptionKey: "dialogs.trustedEmailsAddition.success",
            openCallback: function () {
                spacesSubscription = _this._store.select(ClientControlPageStateSelectors.clientSpaceList)
                    .pipe(filter(Boolean))
                    .subscribe(function (spaces) { return dlg.componentInstance.spaces = spaces; });
                requestStateSubscription = _this._store.select(trustedEmailsAdditionSelectors.requestState)
                    .pipe(filter(Boolean), withLatestFrom(_this._store.select(currentUserSelectors.canSeeTrustedEmails)))
                    .subscribe(function (_a) {
                    var state = _a[0], canSeeTrustedEmails = _a[1];
                    dlg.componentInstance.requestState = state;
                    if (getRequestResult(state) && canSeeTrustedEmails) {
                        _this._store.dispatch(clientControlPageActions.updateTrustedEmailsInfo(getRequestResult(state)));
                    }
                });
                _this._store.dispatch(trustedEmailsAdditionActions.initialize());
            },
            confirmCallback: function (data) {
                _this._store.dispatch(trustedEmailsAdditionActions.add({ value: data }));
            },
            closeCallback: function () { return dlg.close(); },
            destroyCallback: function () {
                spacesSubscription.unsubscribe();
                requestStateSubscription.unsubscribe();
            }
        };
        dlg = this._dlgService.openEmailsAndSpacesDlg(dlgData);
    };
    EmailsAndSpacesDlgService.ngInjectableDef = i0.defineInjectable({ factory: function EmailsAndSpacesDlgService_Factory() { return new EmailsAndSpacesDlgService(i0.inject(i1.Store), i0.inject(i2.DlgService)); }, token: EmailsAndSpacesDlgService, providedIn: "root" });
    return EmailsAndSpacesDlgService;
}());
export { EmailsAndSpacesDlgService };
