import { PendingExternalElementsState } from "src/app/spaces/modules/document-matching/store/states/pending-external-elements.state";
import { PendingExternalElementsAction } from "src/app/spaces/modules/document-matching/store/actions/pending-external-elements.action";
import { PendingExternalElementsActionType } from "src/app/spaces/modules/document-matching/store/actions/pending-external-elements.action";

/**
 * Начальное состояние редактируемых элементов для внешней системы.
 */
const initialState: PendingExternalElementsState = {
    customers: {},
    suppliers: {},
}

/**
 * Обработчик событий, связанных с редактированием элементов для внешней системы.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 *
 * @return Новое состояние.
 */
export function pendingExternalElementsReducer(
    state = initialState,
    action: PendingExternalElementsAction,
) {
    let result: PendingExternalElementsState = state;

    switch (action.type) {

        case PendingExternalElementsActionType.ADD_SUPPLIER: {

            if (!state.suppliers[action.value.id]) {

                result = { ...state, suppliers: { ...state.suppliers, [action.value.id]: action.value } };
            }

            break;
        }
        case PendingExternalElementsActionType.ADD_CUSTOMER: {

            if (!state.customers[action.value.id]) {

                result = { ...state, customers: { ...state.customers, [action.value.id]: action.value } };
            }

            break;
        }
        case PendingExternalElementsActionType.UPDATE_SUPPLIERS: {

            const suppliers = { ...state.suppliers };
            action.value.forEach(company => suppliers[company.id] = { ...company });

            result = { ...state, suppliers: suppliers };
            break;
        }
        case PendingExternalElementsActionType.UPDATE_CUSTOMERS: {

            const customers = { ...state.customers };
            action.value.forEach(company => customers[company.id] = { ...company });

            result = { ...state, customers: customers };
            break;
        }
        case PendingExternalElementsActionType.CLEAR: {

            result = { ...state, suppliers: {}, customers: {} };
            break;
        }
    }

    return result;
}
