<div class="mobile-main">
    <mobile-space-select class="mobile-main__space-selector"
        [spaces]="spaces$ | async"
        (select)="spaceSelectHandler($event)"
    >
    </mobile-space-select>
    <div class="mobile-main__buttons">
        <button
            *ngIf="!(userInfo$ | async).client.foreign"
            mat-raised-button
            color="primary"
            class="mobile-main__button"
            [disabled]="noSelectedSpace"
            (click)="onScanQrClick()"
        >
            {{'mobile.scanQr' | translate}}
        </button>
        <button
            mat-raised-button
            color="primary"
            class="mobile-main__button"
            [disabled]="noSelectedSpace"
            (click)="onUploadDocumentsMobile()"
        >
            {{'mobile.uploadDocuments' | translate}}
        </button>
    </div>
</div>
