import { DocumentsCountActionType, DocumentsCountAction } from "../actions";

/**
 * Состояние кол-ва документов, подходящих под фильтры реестра.
 */
export interface DocumentsCountState {

    /**
     * Кол-во документов загружается?
     */
    loading: boolean;

    /**
     * Кол-во документов загружено?
     */
    loaded: boolean;

    /**
     * Загрузка кол-ва документов, подходящих под фильтры реестра, завершилась ошибкой?
     */
    failed: boolean;

    /**
     * Кол-во документов.
     */
    documentsCount: number;
}

/**
 * Начальное состояние кол-ва документов, подходящих под фильтры реестра.
 */
const initialState: DocumentsCountState = {

    /**
     * Кол-во документов не загружается.
     */
    loading: false,

    /**
     * Кол-во документов не загружено.
     */
    loaded: false,

    /**
     * Загрузка кол-ва документов, подходящих под фильтры реестра, ошибкой не завершалась.
     */
    failed: false,

    /**
     * Кол-ва документов, подходящих под фильтры реестра, нет.
     */
    documentsCount: undefined,
}

/**
 * Обработчик событий, связанных с кол-вом документов, подходящих под фильтры реестра.
 * 
 * @param state Состояние кол-ва документов, подходящих под фильтры реестра.
 * @param action Событие произошедшее в системе.
 */
export function documentsCountReducer(state = initialState, action: DocumentsCountAction): DocumentsCountState {

    switch (action.type) {

        // Требование загрузить кол-во документов, подходящих под фильтры реестра.
        case DocumentsCountActionType.LOAD: {

            return {
                ...state,
                loading: true,
                loaded: false,
                failed: false
            };
        }

        // Уведомление об успешной загрузке кол-ва документов, подходящих под фильтры реестра.
        case DocumentsCountActionType.LOAD_SUCCESS: {

            const documentsCount = action.payload;
            return {
                ...state,
                loading: false,
                loaded: true,
                documentsCount
            };
        }

        // Уведомление о неудачной попытке загрузки кол-ва документов, подходящих под фильтры реестра.
        case DocumentsCountActionType.LOAD_FAIL: {

            return {
                ...state,
                loading: false,
                failed: true,
                documentsCount: undefined
            };
        }

        // Требование уменьшения количества документов, подходящих под фильтры реестра.
        case DocumentsCountActionType.DECREMENT: {

            let documentsCount: number = state.documentsCount - action.documentsNumber;

            return {
                ...state,
                documentsCount,
            };
        }
    }

    return state;
}

/**
 * Возвращает кол-во документов из состояния кол-ва документов, подходящих под фильтры реестра.
 * 
 * @param state Состояние кол-ва документов, подходящих под фильтры реестра.
 */
export const getDocumentsCount = (state: DocumentsCountState) => state.documentsCount;

/**
 * Возвращает флаг выполняющейся загрузки кол-ва документов из состояния кол-ва документов, подходящих под 
 * фильтры реестра.
 * 
 * @param state Состояние кол-ва документов, подходящих под фильтры реестра.
 */
export const getDocumentsCountLoading = (state: DocumentsCountState) => state.loading;

/**
 * Возвращает флаг успешно выполненной загрузки кол-ва документов из состояния кол-ва документов, подходящих под 
 * фильтры реестра.
 * 
 * @param state Состояние кол-ва документов, подходящих под фильтры реестра.
 */
export const getDocumentsCountLoaded = (state: DocumentsCountState) => state.loaded;

/**
 * Возвращает флаг неудачной попытки загрузки кол-ва документов из состояния кол-ва документов, подходящих под 
 * фильтры реестра.
 * 
 * @param state Состояние кол-ва документов, подходящих под фильтры реестра.
 */
export const getDocumentsCountFailed = (state: DocumentsCountState) => state.failed;
