import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
/**
 * Сервис для работы с метрикой Facebook Pixel.
 */
var FacebookPixelService = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор сервиса для работы с метрикой Facebook Pixel.
     */
    function FacebookPixelService() {
        this._scriptInserted = false;
    }
    //endregion
    //region Public
    /**
     * Добавляет скрипт для загрузки метрики на страницу приложения.
     */
    FacebookPixelService.prototype.add = function () {
        var facebookPixelEnabled = environment.facebookPixel ? environment.facebookPixel.enabled : false;
        var facebookPixelId = environment.facebookPixel ? environment.facebookPixel.id : null;
        if (facebookPixelEnabled && !this._scriptInserted && facebookPixelId) {
            var script = document.createElement('script');
            script.text = "\n                !function(f,b,e,v,n,t,s) {\n                    if(f.fbq)return;\n                    n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n                    if(!f._fbq) f._fbq=n;\n                    n.push=n;\n                    n.loaded=!0;\n                    n.version='2.0';\n                    n.queue=[];\n                    t=b.createElement(e);\n                    t.async=!0;\n                    t.src=v;\n                    s=b.getElementsByTagName(e)[0];\n                    s.parentNode.insertBefore(t,s);\n                }(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');\n                fbq('init', '" + facebookPixelId + "');\n                fbq('track', 'PageView');\n            ";
            var noscript = document.createElement('noscript');
            var url = "https://www.facebook.com/tr?id=" + facebookPixelId + "&ev=PageView&noscript=1";
            noscript.innerHTML = "<img height=\"1\" width=\"1\" style=\"display:none\" src=\"" + url + "\" alt=\"\" />";
            document.body.appendChild(script);
            document.body.appendChild(noscript);
            this._scriptInserted = true;
        }
    };
    FacebookPixelService.ngInjectableDef = i0.defineInjectable({ factory: function FacebookPixelService_Factory() { return new FacebookPixelService(); }, token: FacebookPixelService, providedIn: "root" });
    return FacebookPixelService;
}());
export { FacebookPixelService };
