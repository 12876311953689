import { Action } from '@ngrx/store';

import { ApiResponsePayloadAction } from 'src/app/root/store';
import { ApiResponse } from 'src/app/common/models/api-response';
import { EnteraDocumentState } from 'src/app/common/models/document-state';

/**
 * Типы событий, связанные со статусами документов.
 */
export enum DocumentStatesActionType {
    LOAD = '[Documents registry] Load document states',
    LOAD_SUCCESS = '[Documents registry] Load document states Success',
    LOAD_FAIL = '[Documents registry] Load document states Fail',
}

/**
 * Событие, требующее загрузки статусов документов.
 */
export class DocumentStatesLoadAction implements Action {
    readonly type = DocumentStatesActionType.LOAD;
    constructor() { }
}

/**
 * Событие успешной загрузки статусов документов.
 */
export class DocumentStatesLoadSuccessAction implements Action {
    readonly type = DocumentStatesActionType.LOAD_SUCCESS;
    constructor(public payload: EnteraDocumentState[]) { }
}

/**
 * Событие неудачной загрузки статусов документов.
 */
export class DocumentStatesLoadFailAction implements ApiResponsePayloadAction {
    readonly type = DocumentStatesActionType.LOAD_FAIL;
    constructor(public payload: ApiResponse) { }
}

/**
 * Тип, объединяющий все события, связанные со статусами документов.
 */
export type DocumentStatesAction =
    | DocumentStatesLoadAction
    | DocumentStatesLoadFailAction
    | DocumentStatesLoadSuccessAction;
