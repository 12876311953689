import { Injectable } from "@angular/core";
import { CanActivateChild } from "@angular/router";
import { CanActivate } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { documentTypesActions } from "src/app/root/store/actions/document-types.action";
import { RootState } from "src/app/root/store/reducers/index";
import { documentTypesSelector } from "src/app/root/store/selectors/document-types.selector";

/**
 * Защита от перехода по пути, проверяющая состояние запроса на загрузку типов документов.
 *
 * Если типы документов ещё не были загружены, диспатчит событие их загрузки.
 * Если загрузка типов ещё не произведена, ожидает её окончания.
 * Если при загрузке произошла ошибка, то запрещает переход по пути.
 * Если типы успешно загружены, то разрешает переход по пути.
 */
@Injectable({
    providedIn: 'root',
})
export class DocumentTypesGuard implements CanActivate, CanActivateChild {
    //region Ctor

    constructor(private _store: Store<RootState>) { }

    //endregion
    //region Public

    canActivate(): Observable<boolean> {

        return this._store.select(documentTypesSelector.requestState).pipe(
            tap(requestState => requestState === "initial" && this._store.dispatch(documentTypesActions.load())),
            filter(requestState => requestState === "success" || requestState === "fail"),
            map(requestState => requestState === "success"),
        );
    }

    canActivateChild(): Observable<boolean> {

        return this.canActivate();
    }

    //endregion
}