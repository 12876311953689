import { ChangeDetectorRef } from "@angular/core";
import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Inject } from "@angular/core";

import { MatDialogRef } from "@angular/material";
import { MAT_DIALOG_DATA } from "@angular/material";

import { TranslateService } from "@ngx-translate/core";

import { Observable } from "rxjs";
import { Subscription } from "rxjs";
import { ApiResponse } from "src/app/common/models";
import { SimpleDlgData } from "src/app/common/models";

/**
 * Компонент простого диалога с текстом.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'simple-alert-dlg',
    templateUrl: './simple-alert-dlg.component.html',
    styleUrls: ['./simple-alert-dlg.component.scss'],
})
export class SimpleAlertDlgComponent implements OnInit {
    //region Public fields

    /**
     * I18n-ключ заголовка диалога.
     */
    headerKey: string;

    /**
     * Параметры для подстановки в заголовок диалога.
     */
    headerParams: Object;

    /**
     * I18n-ключ текста для отображения в диалоге.
     */
    textKey: string;

    /**
     * Параметры для подстановки в текст диалога.
     */
    textParams: Object;

    /**
     * Находтся ли диалог в стандии загрузки. 
     */
    loading: boolean;

    /**
     * Ошибка, полученная от сервера.
     */
    error: ApiResponse;

    //endregion
    //region Private fields

    /**
     * Данные диалога.
     *
     * @private
     */
    private _data: SimpleDlgData;

    /**
     * Сервис для получения текстов на текущем языке по их ключам.
     *
     * @private
     */
    private _translateService: TranslateService;

    /**
     * Подписки компонента.
     */
    private _subscriptions: Subscription[] = [];

    //endregion
    //region Ctor

    /**
     * Конструктор компонента простого диалога с текстом.
     *
     * @param _dialogRef Компонент диалога.
     * @param _changeDetector Сервис для управления запуском определения angular'ом изменений данных,
     * произошедших в компоненте.
     * @param data Данные для простых диалогов.
     * @param translateService Сервис для работы с i18n-сообщениями.
     */
    constructor(
        private _dialogRef: MatDialogRef<SimpleAlertDlgComponent>,
        private _changeDetector: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) data: SimpleDlgData,
        translateService: TranslateService,
    ) {
        this._setData(data);
        this._translateService = translateService;
    }

    //endregion
    //region Hooks

    ngOnInit(): void {

        // Использование afterClosed() не вызывает cancelCallback
        this._subscriptions.push(this._dialogRef.beforeClose().subscribe(this._data.closeCallback));
    }

    ngOnDestroy(): void {

        // Отписываемся от всех подписок.
        this._subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
    }

    //endregion
    //region Getters and Setters

    /**
     * У диалога есть кнопка принятия?
     */
    get hasOkButton(): boolean {

        return !!(this._data.okCallback);
    }

    /**
     * I18n-ключ текста кнопки принятия диалога.
     */
    get okBtnKey(): string {

        return (this._data.okBtnKey || 'dialogs.ok');
    }

    /**
     * I18n-ключ текста кнопки закрытия диалога.
     */
    get closeBtnKey(): string {

        return (this._data.closeBtnKey || 'dialogs.close');
    }

    /**
     * Текст диалога.
     */
    get text(): Observable<string> {

        let result: Observable<string>;

        if (this._data.text) {

            result = this._data.text;
        }
        else {

            result = this._translateService.get(this.textKey, this.textParams);
        }

        return result;
    }

    //endregion
    //region Public

    /**
     * Обновление содержимого дилога.
     *
     * @param data новые данные диалога.
     */
    public updateData(data: SimpleDlgData): void {
        
        this._setData(data);
        this._changeDetector.markForCheck();   
    }
    
    //endregion
    //region Events

    /**
     * Обработчик клика по кнопке принятия диалога.
     */
    okClickHandler(): void {

        if (this._data.okCallback) {

            this._data.okCallback();
        }

        this._dialogRef.close();
    }

    //endregion
    //region Private

    /**
     * Выполняет обновление данных диалога на основе заданных данных.
     *
     * @param data Данные диалога.
     *
     * @private
     */
    private _setData(data: SimpleDlgData): void {

        this._data = data;
        this.headerKey = this._data.headerKey;
        this.headerParams = this._data.headerParams || {};
        this.textKey = this._data.textKey;
        this.textParams = this._data.textParams || {};
        this.loading = this._data.loading;
        this.error = this._data.error;
    }

    //endregion
}
