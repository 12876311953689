var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DocumentCreatorsActionType } from "../actions";
/**
 * Начальное состояние создателей документов.
 */
var initialState = {
    /**
     * Создатели документов не загружаются.
     */
    loading: false,
    /**
     * Создатели документов не загружены.
     */
    loaded: false,
    /**
     * Загрузка создателей документов ошибкой не завершалась.
     */
    failed: false,
    /**
     * Создателей документов нет.
     */
    documentCreators: []
};
/**
 * Обработчик событий, связанных с создателями документов.
 *
 * @param state Состояние создателей документов.
 * @param action Событие произошедшее в системе.
 */
export function documentCreatorsReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        // Требование загрузить создателей документов.
        case DocumentCreatorsActionType.LOAD: {
            return __assign({}, state, { loading: true, loaded: false, failed: false });
        }
        // Уведомление об успешной загрузке создателей документов.
        case DocumentCreatorsActionType.LOAD_SUCCESS: {
            var documentCreators = action.payload;
            return __assign({}, state, { loading: false, loaded: true, documentCreators: documentCreators });
        }
        // Увдомление о неудачной попытке загрузки создателей документов.
        case DocumentCreatorsActionType.LOAD_FAIL: {
            return __assign({}, state, { loading: false, failed: true, documentCreators: undefined });
        }
    }
    return state;
}
/**
 * Возвращает создателей документов из состояния создателей документов.
 *
 * @param state Состояние создателей документов.
 */
export var getDocumentCreators = function (state) { return state.documentCreators; };
/**
 * Возвращает флаг выполняющейся загрузки создателей документов из состояния создателей документов.
 *
 * @param state Состояние создателей документов.
 */
export var getDocumentCreatorsLoading = function (state) { return state.loading; };
/**
 * Возвращает флаг успешно выполненной загрузки создателей документов из состояния создателей документов.
 *
 * @param state Состояние создателей документов.
 */
export var getDocumentCreatorsLoaded = function (state) { return state.loaded; };
/**
 * Возвращает флаг неудачной попытки загрузки создателей документов из состояния создателей документов.
 *
 * @param state Состояние создателей документов.
 */
export var getDocumentCreatorsFailed = function (state) { return state.failed; };
