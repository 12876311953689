import { HttpClient } from "@angular/common/http";
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material";
import { MatDialogRef } from "@angular/material/dialog";

import { Observable } from "rxjs";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";

import { RecognitionTaskCreatedDlgComponent } from "src/app/root/containers/recognition-task-created-dlg/recognition-task-created-dlg.component"; /* circular dependency break */
import { ApiResponse } from "../../common/models";
import { Constants } from "../../common/models";
import { RecognitionTask } from "../../common/models";

/**
 * Сервис для вызова диалога после создания задачи на распознавание.
 */
@Injectable({
    providedIn: "root",
})
export class RecognitionTaskCreatedDlgService {
    //region Fields

    /**
     * Сервис для работы с диалогами.
     */
    private readonly _dialog: MatDialog;

    /**
     * HTTP-клиент.
     */
    private readonly _http: HttpClient;

    //endregion
    //region Ctor

    /**
     * Конструктор сервиса для вызова диалога после создания задачи на распознавание.
     *
     * @param dialog Сервис для работы с диалогами.
     * @param httpClient HTTP-клиент.
     */
    constructor(dialog: MatDialog, httpClient: HttpClient) {

        this._dialog = dialog;
        this._http = httpClient;
    }

    //endregion
    //region Public

    /**
     * Открывает диалог после создания задачи на распознавание.
     */
    open(recognitionTask: RecognitionTask): MatDialogRef<RecognitionTaskCreatedDlgComponent> {

        return this._dialog.open(RecognitionTaskCreatedDlgComponent, {
            data: recognitionTask,
            panelClass: [Constants.DIALOG_PANE_CLASS, "recognition-task-created-dlg-pane"],
            autoFocus: false,
            disableClose: true,
        });
    }

    //endregion
}
