import { requestState } from "src/app/common/models/request-state";
import { ExternalUnitsActionType } from "src/app/spaces/modules/document-matching/store/actions/external-units.action";
import { ExternalUnitsAction } from "src/app/spaces/modules/document-matching/store/actions/external-units.action";
import { ExternalUnitsState } from "src/app/spaces/modules/document-matching/store/states/external-units.state";

/**
 * Начальное состояние обработчика событий, связанных с состоянием единиц измерения.
 */
const initialState: ExternalUnitsState = {

    /**
     * Начальное состояние запроса единиц измерения с сервера.
     */
    externalUnitRequest: requestState.initial(),
};

/**
 * Обработчик событий, связанных с состоянием единиц измерения.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 *
 * @return Новое состояние.
 */
export function externalUnitsReducer(
    state: ExternalUnitsState = initialState,
    action: ExternalUnitsAction,
): ExternalUnitsState {
    let result: ExternalUnitsState = state;

    switch (action.type) {

        case ExternalUnitsActionType.LOAD: {

            result = {
                ...state,
                externalUnitRequest: requestState.pending(),
            };

            break;
        }
        case ExternalUnitsActionType.LOAD_SUCCESS: {

            result = {
                ...state,
                externalUnitRequest: requestState.success(action.value),
            };

            break;
        }
        case ExternalUnitsActionType.LOAD_FAIL: {

            result = {
                ...state,
                externalUnitRequest: requestState.fail(action.error),
            };

            break;
        }
    }

    return result;
}
