import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { currentUserInfoSelector } from '../../root/store';
import { currentUserStateSelector } from '../../root/store';
import { RouteService } from 'src/app/common/services';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "../../common/services/route.service";
/**
 * Route Guard.
 * Проверка возможности захода по URL в зависимости от наличия аутентифицированного пользователя.
 *
 * Если текущий пользователь есть, то переход по URL возможен, если текущего пользователя нет, то невозможен.
 */
var UserGuard = /** @class */ (function () {
    //region Ctor
    function UserGuard(_store, _routeService) {
        this._store = _store;
        this._routeService = _routeService;
    }
    //endregion
    //region Public
    UserGuard.prototype.canActivate = function () {
        var _this = this;
        return this._getCurrentUser()
            .pipe(tap(function (currentUser) { return !currentUser && _this._routeService.goToLogin(); }), switchMap(function (user) { return of(!!user); }), catchError(function () { return of(false); }));
    };
    UserGuard.prototype.canActivateChild = function () {
        return this.canActivate();
    };
    //endregion
    //region Private
    /**
     * Возвращает данные текущего аутентифицированного пользователя.
     *
     * Если данные по текущему аутентифицированному пользователю не запрашивались, то вызывает их загрузку.
     */
    UserGuard.prototype._getCurrentUser = function () {
        var _this = this;
        return this._store
            .pipe(
        // Запрашиваем состояние текущего пользователя.
        select(currentUserStateSelector), 
        // Фильтрация, чтобы дождаться попытки загрузки данных пользователя.
        filter(function (userState) { return userState.loaded || userState.failed; }), 
        // Запрашиваем получившиеся данные.
        switchMap(function () { return _this._store.pipe(select(currentUserInfoSelector)); }));
    };
    UserGuard.ngInjectableDef = i0.defineInjectable({ factory: function UserGuard_Factory() { return new UserGuard(i0.inject(i1.Store), i0.inject(i2.RouteService)); }, token: UserGuard, providedIn: "root" });
    return UserGuard;
}());
export { UserGuard };
