import { CurrentUserInfo } from "src/app/common/models/current-user-info";
import { Space } from "src/app/common/models/space";
import { CurrentUserSettingsActionType } from "../actions/current-user-settings.action";
import { CurrentUserSettingsAction } from "../actions/current-user-settings.action";
import { CurrentUserAction } from "../actions/current-user.action";
import { CurrentUserActionType } from "../actions/current-user.action";
import { PermissionItem } from "src/app/common/models/index";

/**
 * Состояние по данным текущего пользователя.
 */
export interface CurrentUserState {

    // Данные пользователя.
    currentUserInfo: CurrentUserInfo;

    // Данные успешно загружены?
    loaded: boolean;

    // Данные загружаются?
    loading: boolean;

    // Загрузка завершилась ошибкой?
    failed: boolean;
}

/**
 * Начальное состояние по данным текущего пользователя.
 */
export const initialState: CurrentUserState = {

    // Данных нет.
    currentUserInfo: undefined,

    // Данные не загружены.
    loaded: false,

    // Данные не загружаются в данный момент.
    loading: false,

    // Ошибки загрузки нет.
    failed: false,
};

/**
 * Обработка состояния о текущем пользователе в ответ на события.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 */
export function currentUserReducer(
    state = initialState,
    action: CurrentUserAction | CurrentUserSettingsAction,
): CurrentUserState {

    switch (action.type) {

        // На событие загрузки данных меняем флаг загрузки.
        case CurrentUserActionType.LOAD: {

            return {
                ...state,
                loading: true,
                loaded: false,
                failed: false
            };
        }

        // На событие успешной загрузки данных меняем флаги и меняем данные пользователя.
        case CurrentUserActionType.LOAD_SUCCESS: {

            const currentUserInfo = action.payload;

            return {
                ...state,
                currentUserInfo,
                loaded: true,
                loading: false,
            };
        }

        // На событие неудачной загрузки данных меняем флаги.
        case CurrentUserActionType.LOAD_FAIL: {

            return {
                ...state,
                currentUserInfo: undefined,
                loaded: false,
                loading: false,
                failed: true,
            };
        }

        // На событие успешной загрузки задачи на распознавание меняем флаг о наличии у пользователя задач на
        // распознавание.
        case CurrentUserActionType.RECOGNITION_TASK_UPLOADED: {

            return {
                ...state,
                currentUserInfo: {
                    ...state.currentUserInfo,
                    hasRecognitionTasks: true,
                },
            };
        }

        // На событие успешного сохранения флага необходимости показывать пользователю диалог после создания задачи на
        // распознавание.
        case CurrentUserSettingsActionType.SHOW_DIALOG_FLAG_SAVED_SUCCESSFULLY: {

            return {
                ...state,
                currentUserInfo: {
                    ...state.currentUserInfo,
                    settings: {
                        ...state.currentUserInfo.settings,
                        showRecognitionTaskCreatedDialog: action.flag,
                    },
                },
            };
        }

        // На событие успешного подтверждения номера телефона пользователем
        case CurrentUserActionType.PHONE_CONFIRM_SUCCESS: {

            return {
                ...state,
                currentUserInfo: {
                    ...state.currentUserInfo,
                    phoneConfirm: true,
                    phone: action.payload.phone
                },
            };
        }

        // На событие обновления данных пользователя после создания папки.
        case CurrentUserActionType.UPDATE_AND_REDIRECT: {

            return {
                ...state,
                currentUserInfo: {
                    ...state.currentUserInfo,
                    spaces: [ ...state.currentUserInfo.spaces, action.payload ].sort(sortSpaceFn),
                    spacePermissions: [
                        ...state.currentUserInfo.spacePermissions,
                        {
                            spaceId: action.payload.id,
                            permissions: [
                                PermissionItem.INTEGRATION.value,
                                PermissionItem.MANAGE_EMAIL_NOTIFICATIONS.value
                            ],
                        }
                    ]
                },
            };
        }

        // На событие обновления данных пользователя после изменения папки.
        case CurrentUserActionType.UPDATE_SPACE: {

            let updatedSpaceList: Space[] = state.currentUserInfo.spaces
                .map(space => space.id === action.payload.id && action.payload || space)
                .sort(sortSpaceFn);

            return {
                ...state,
                currentUserInfo: {
                    ...state.currentUserInfo,
                    spaces: updatedSpaceList,
                },
            };
        }
    }

    return state;
}

/**
 * Сортирует пространства документов по наименованию.
 *
 * @param space1 Пространство документов.
 * @param space2 Пространство документов.
 */
export const sortSpaceFn = (space1: Space, space2: Space) => {

    if (space1.name.toLowerCase() > space2.name.toLowerCase()) {

        return 1;
    }
    else {

        return -1;
    }
};

/**
 * Возвращает данные пользователя из состояния данных о текущем пользователе.
 *
 * @param state Состояние данных о текущем пользователе.
 */
export const getCurrentUserInfo = (state: CurrentUserState) => state.currentUserInfo;

/**
 * Возвращает язык текущего пользователя.
 *
 * @param user Данные о текущего пользователя.
 */
export const getCurrentUserLang = (user: CurrentUserInfo) => user ? user.lang : null;

/**
 * Возвращает флаг загружены ли данные пользователя из состояния данных о текущем пользователе.
 *
 * @param state Состояние данных о текущем пользователе.
 */
export const getCurrentUserLoaded = (state: CurrentUserState) => state.loaded;

/**
 * Возвращает флаг выполнения загрузки данных пользователя из состояния данных о текущем пользователе.
 *
 * @param state Состояние данных о текущем пользователе.
 */
export const getCurrentUserLoading = (state: CurrentUserState) => state.loading;

/**
 * Возвращает флаг была ли ошибка при попытке загрузки данных пользователя из состояния данных о текущем
 * пользователе.
 *
 * @param state Состояние данных о текущем пользователе.
 */
export const getCurrentUserFailed = (state: CurrentUserState) => state.failed;
