import { createSelector } from "@ngrx/store";
import { createFeatureSelector } from "@ngrx/store";
import { Space } from "src/app/common/models/space";
import { SelectedSpaceForMobileState } from "src/app/root/store/states/selected-space-for-mobile.state";

/**
 * Селектор состояния для выбранного пространства документов, в контексте которого происходит работа в случае, когда
 * используется мобильная версия UI.
 */
const state = createFeatureSelector<SelectedSpaceForMobileState>("selectedSpaceForMobile");

/**
 * Селектор выбранного пространства документа, который относится к мобильной версии.
 */
const space = createSelector(
    state,
    (selectedSpaceForMobileState: SelectedSpaceForMobileState): Space => selectedSpaceForMobileState.space
);

/**
 * Объект, объединяющий все селекторы, которые относятся к состоянию для выбранного пространства документов.
 */
export const SelectedSpaceForMobileSelectors = {
    state,
    space,
};
