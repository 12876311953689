var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { NgControl } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { UtilsService } from '../../services';
/**
 * Результат попытки приведения строки к числу.
 */
var ParseResult = /** @class */ (function () {
    function ParseResult() {
        //region Fields
        /**
         * Получившийся результат.
         */
        this.value = null;
        /**
         * Флаг, что строка не являлась корректным числом.
         */
        this.isInvalidNumber = false;
        //endregion
    }
    return ParseResult;
}());
/**
 * Компонент контрола (элемента формы) для ввода числа.
 */
var NumberInputComponent = /** @class */ (function () {
    //endregion
    //region Ctor
    function NumberInputComponent(ngControl, utilService) {
        /**
         * Входящие данные - кол-во знаков после запятой.
         */
        this.precision = 2;
        /**
         * Входящие данные - включение/отключение логирования внутренней работы контрола. По умолчанию логирование
         * выключено.
         */
        this.logEnabled = false;
        //endregion
        //region Public fields
        /**
         * Внутренний контрол, привязанный к текстовому полю.
         */
        this.valueControl = new FormControl();
        /**
         * Поток, который уведомляет подписчиков о том, что состояние контрола изменилось.
         */
        this.stateChanges = new Subject();
        /**
         * Управление ID-ком host-элемента.
         */
        this.id = "number-input-" + NumberInputComponent.nextId++;
        /**
         * Управление атрибутом aria-describedby host-элемента.
         */
        this.describedBy = '';
        //endregion
        //region Private fields
        /**
         * Текущее значение контрола.
         *
         * @private
         */
        this._value = null;
        /**
         * Callback, когда введённое значение изменилось.
         *
         * @private
         */
        this._changeCallback = function () { };
        /**
         * Callback, когда пользователь начал взаимодействовать с полем для ввода.
         *
         * @private
         */
        this._touchCallback = function () { };
        /**
         * Поле обязательно для заполнения?
         *
         * @private
         */
        this._required = false;
        /**
         * Поле отключено?
         *
         * @private
         */
        this._disabled = false;
        /**
         * Пользователь прикасался к контролу?
         *
         * @private
         */
        this._touched = false;
        this._utilService = utilService;
        // Эта логика добавлена вместо
        // {
        //     provide: NG_VALUE_ACCESSOR,
        //     useExisting: forwardRef(() => NumberInputComponent),
        //     multi: true
        // }
        // В противном случае возникает ошибка. Это решение описано на официальном сайте Angular Material:
        // https://v6.material.angular.io/guide/creating-a-custom-form-field-control#-code-ngcontrol-code-
        this.ngControl = ngControl;
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }
    Object.defineProperty(NumberInputComponent.prototype, "value", {
        //endregion
        //region MatFormFieldControl
        /**
         * Значение в контроле.
         */
        get: function () {
            return this._value;
        },
        //region Inputs
        /**
         * Входящие данные - значение для контрола.
         *
         * @param value Значение для контрола.
         */
        set: function (value) {
            this.writeValue(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NumberInputComponent.prototype, "placeholder", {
        /**
         * Placeholder поля ввода.
         */
        get: function () {
            return this._placeholder;
        },
        /**
         * Входящие данные - placeholder поля.
         */
        set: function (value) {
            this._placeholder = value;
            this.stateChanges.next();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NumberInputComponent.prototype, "required", {
        /**
         * Поле обязательно для заполнения?
         */
        get: function () {
            return this._required;
        },
        /**
         * Входящие данные - обязательность заполнения поля.
         */
        set: function (required) {
            this._required = coerceBooleanProperty(required);
            this.stateChanges.next();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NumberInputComponent.prototype, "disabled", {
        /**
         * Поле отключено?
         */
        get: function () {
            return this._disabled;
        },
        /**
         * Входящие данные - флаг выключения поля.
         */
        set: function (disabled) {
            this.setDisabledState(coerceBooleanProperty(disabled));
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Hooks
    NumberInputComponent.prototype.ngOnInit = function () {
        this._log('Number input control initiated');
    };
    NumberInputComponent.prototype.ngOnDestroy = function () {
        this.stateChanges.complete();
        this._log('Number input control destroyed');
    };
    //endregion
    //region ControlValueAccessor
    /**
     * Установка значения в контрол (model -> view).
     *
     * @param value Значение для установки в контрол.
     */
    NumberInputComponent.prototype.writeValue = function (value) {
        this._log('model -> view: initial value ', value);
        var parseResult = this._parseNumber(value);
        this._log('model -> view: parsed value ', parseResult);
        this._log('model -> view: current control value ', this._value);
        if (this._value !== parseResult.value) {
            this._log('model -> view: change inner value');
            this._value = parseResult.value;
            this.valueControl.setValue(this._formatNumber(this._value));
            this._changeCallback(this._value);
            this.stateChanges.next();
        }
    };
    /**
     * Регистрация callback'а для события, когда значение в контроле изменилось (view -> model).
     *
     * @param fn Callback для события, когда значение в контроле изменилось.
     */
    NumberInputComponent.prototype.registerOnChange = function (fn) {
        this._changeCallback = fn;
    };
    /**
     * Регистрация callback'а для события, когда пользователь начал взаимодействовать с контролом.
     *
     * @param fn Callback для события, когда пользователь начал взаимодействовать с контролом.
     */
    NumberInputComponent.prototype.registerOnTouched = function (fn) {
        this._touchCallback = fn;
    };
    /**
     * Включение/отключение контрола.
     *
     * @param isDisabled Флаг включённости/отключённости контрола.
     */
    NumberInputComponent.prototype.setDisabledState = function (isDisabled) {
        if (isDisabled && this.valueControl.enabled) {
            this.valueControl.disable();
        }
        else if (!isDisabled && this.valueControl.disabled) {
            this.valueControl.enable();
        }
        this._disabled = isDisabled;
        this.stateChanges.next();
    };
    Object.defineProperty(NumberInputComponent.prototype, "empty", {
        /**
         * Значение в контроле пустое?
         */
        get: function () {
            return (typeof this._value !== 'number' && !this._value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NumberInputComponent.prototype, "shouldLabelFloat", {
        /**
         * Label контрола должен находиться в верхней части?
         *
         * Например, для matInput, когда ставишь фокус в поле, placeholder перемещается наверх. Здесь аналоничная логика,
         * если фокус в поле ввода и есть какое-то значение, то placeholder должен перемещаться наверх.
         */
        get: function () {
            return this.focused || !this.empty;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NumberInputComponent.prototype, "errorState", {
        /**
         * Есть ошибки ввода в контрол?
         */
        get: function () {
            var errorState = false;
            if (this._required && this._value === null) {
                errorState = {
                    required: true,
                };
            }
            if (this.ngControl.errors) {
                errorState = __assign({}, this.ngControl.errors, errorState);
            }
            return errorState;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NumberInputComponent.prototype, "controlType", {
        /**
         * Тип контрола.
         *
         * Эта строка используется для формирования css-класса, который добавляется к mat-form-field.
         */
        get: function () {
            return 'number-input';
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Устанавливает заданный массив ID-ков в атрибут aria-describedby.
     *
     * @param ids Массив ID-ков.
     */
    NumberInputComponent.prototype.setDescribedByIds = function (ids) {
        this.describedBy = ids.join(' ');
    };
    /**
     * Обработчик клика по контролу.
     *
     * @param event Событие клика.
     */
    NumberInputComponent.prototype.onContainerClick = function (event) { };
    //endregion
    //region Events
    /**
     * Обработчик события получения фокуса поля для ввода.
     */
    NumberInputComponent.prototype.focusEventHandler = function () {
        this._log('Touch event: control value', this._value);
        this.focused = true;
        this._touched = true;
        this._touchCallback();
        this.stateChanges.next();
    };
    /**
     * Обработчик событий потери фокуса поля для ввода.
     */
    NumberInputComponent.prototype.blurEventHandler = function () {
        this._log('Blur event: previous control value', this._value);
        var viewValue = this.valueControl.value;
        this._log('Blur event: view value', viewValue);
        var parseResult = this._parseNumber(viewValue);
        this._log('Blur event: parsed control value', parseResult);
        if (!parseResult.isInvalidNumber && this._value !== parseResult.value) {
            this._value = parseResult.value;
            this._log('Blur event: new control value', this._value);
            this._changeCallback(this._value);
            this.stateChanges.next();
        }
        var formattedValue = this._formatNumber(this._value);
        if (parseResult.isInvalidNumber) {
            this._log('Blur event: restore previous view value', formattedValue);
        }
        this.valueControl.setValue(formattedValue);
        this.focused = false;
        this.stateChanges.next();
    };
    //endregion
    //region Private
    /**
     * Выполняет попытку приведения заданного значения к числу.
     *
     * @param value Какое-то значение.
     *
     * @return Результат приведения значения к числу.
     *
     * @private
     */
    NumberInputComponent.prototype._parseNumber = function (value) {
        var result = new ParseResult();
        if (value === null || value === undefined) {
            value = '';
        }
        // Если и так число задано, то его и берём.
        if (typeof value === 'number' && isFinite(value) && !isNaN(value)) {
            result.value = value;
        }
        // Если строка, то пытаемся её парсить.
        else if (typeof value === 'string') {
            // Возможное отделение дробной части запятой заменяем на точку.
            value = value.trim().replace(/,/g, '.');
            // Если точки в строке нет, то обрабатываем случай, когда в качестве разделителя дробной части
            // выступает пробел.
            if (value.indexOf('.') === -1) {
                // Удаляем все пробелы, кроме последнего и зменяем его на точку.
                value = this._utilService.removeAllButLast(value, ' ').replace(' ', '.');
            }
            // Если точка есть, то все пробелы удаляем.
            else {
                value = value.replace(/\s+/g, '');
            }
            // Если преобразованная строка является числом, то парсим его и округляем.
            if (this._utilService.isNumber(value)) {
                result.value = parseFloat(parseFloat(value).toFixed(this.precision));
            }
            else if (value === '') {
                result.value = (this.valueOnEmpty !== undefined && this._touched
                    ? this.valueOnEmpty
                    : null);
            }
            else {
                result.isInvalidNumber = true;
            }
        }
        return result;
    };
    /**
     * Выполняет форматирование заданного числа.
     *
     * @param value Число.
     *
     * @return Форматированное число.
     *
     * @private
     */
    NumberInputComponent.prototype._formatNumber = function (value) {
        var result = '';
        if (typeof value === 'number') {
            result = this._utilService.formatNumber(value, this.precision);
        }
        return result;
    };
    /**
     * Выполняет логирование заданной информации в зависимости от того, включено ли логирование внутренней работы
     * контрола или нет.
     *
     * @param params Данные для логирования.
     *
     * @private
     */
    NumberInputComponent.prototype._log = function () {
        var params = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            params[_i] = arguments[_i];
        }
        if (this.logEnabled) {
            params.unshift("[" + this.id + "]");
            console.log.apply(console, params);
        }
    };
    /**
     * Счётчик для создания уникальных ID экземпляров контрола.
     */
    NumberInputComponent.nextId = 0;
    return NumberInputComponent;
}());
export { NumberInputComponent };
