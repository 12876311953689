import { HttpEventType } from "@angular/common/http";
import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Gtag } from "angular-gtag";
import { spaceSelector } from "src/app/root/store/selectors/index";
import { RootState } from "rootStore";
import { of } from "rxjs";
import { withLatestFrom } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { last } from "rxjs/operators";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { Space } from "src/app/common/models";
import { ApiResponse } from "src/app/common/models";
import { MultipleRecognitionTaskApiResponse } from "src/app/common/models";
import { RecognitionTaskApiResponse } from "src/app/common/models";
import { FileWithHash } from "src/app/common/models/file-with-hash";
import { RecognitionTaskService } from "src/app/common/services";
import { FileHashUtils } from "src/app/common/utils/file-hash.utils";
import { uploadToRecognizeSelector } from "src/app/root/store/reducers/index";
import { SelectedSpaceForMobileSelectors } from "src/app/root/store/selectors/selected-space-for-mobile.selector";
import { UploadTaskToRecognizeAction } from "../../actions";
import { UploadTaskToRecognizeFailAction } from "../../actions";
import { UploadTaskToRecognizeProgressAction } from "../../actions";
import { UploadTaskToRecognizeSuccessAction } from "../../actions";
import { MarkFilesAsDuplicatesAction } from "../../actions";
import { AddFilesToTaskToRecognizeAction } from "../../actions";
import { GenerateHashForFilesAction } from "../../actions";
import { UploadToRecognizeActionType } from "../../actions";
import { FileExistenceCheckFailedAction } from "../../actions";
import { UploadToRecognizeState } from "../../reducers";

/**
 * Side-эффекты на события, связанные с отправкой документов на распознавание.
 */
@Injectable()
export class UploadToRecognizeEffects {
    //region Ctor

    constructor(
        private actions$: Actions,
        private store: Store<RootState>,
        private service: RecognitionTaskService,
        private readonly _gtag: Gtag,
    ) { }

    //endregion
    //region Public

    /**
     * Эффект нажатия кнопки загрузить в диалоге загрузки документов на распознавание.
     * Выполняется загрузка документа на распознавание - создание задачи на распознавание.
     */
    @Effect()
    upload$ = this.actions$.pipe(
        ofType<UploadTaskToRecognizeAction>(UploadToRecognizeActionType.UPLOAD),

        tap(() => this._gtag.event("click_recognize_documents")),
        // Загружаем файл
        map((action: UploadTaskToRecognizeAction) => action.payload),
        switchMap((payload: UploadToRecognizeState) =>
            this.service.createTasks(
                payload.filesWithMetaData.map(fileWithData => fileWithData.file),
                payload.spaceId,
                payload.forceOcrRecognition,
                payload.forceProcessing,
                payload.forceQueue,
                payload.comment,
                payload.mobile,
                payload.bankStatement,
            )
                .pipe(
                    // Прогресс.
                    tap(event => {

                        if (event.type === HttpEventType.UploadProgress) {

                            const progress = Math.floor(event.loaded * 100 / event.total);
                            this.store.dispatch(new UploadTaskToRecognizeProgressAction(progress))
                        }
                    }),

                    // Последнее событие(удача/ошибка).
                    last(),

                    // Обработка ответов.
                    map((last: HttpResponse<MultipleRecognitionTaskApiResponse | RecognitionTaskApiResponse>) => {

                        if (isRecognitionTaskApiResponse(last.body)) {

                            return new UploadTaskToRecognizeSuccessAction([ last.body.recognitionTask ]);
                        }
                        else {

                            return new UploadTaskToRecognizeSuccessAction(last.body.recognitionTasks);
                        }
                    }),

                    // Обработка ошибки.
                    catchError((response: ApiResponse) => of(new UploadTaskToRecognizeFailAction(response)))
                )
        )
    );

    /**
     * Эффект нажатия кнопки загрузить в диалоге загрузки документов на распознавание через мобильный интерфейс.
     * Выполняется загрузка документа на распознавание - создание задачи на распознавание.
     */
    @Effect()
    mobileUpload$ = this.actions$.pipe(
        ofType<UploadTaskToRecognizeAction>(UploadToRecognizeActionType.MOBILE_UPLOAD),
        withLatestFrom(this.store.select(uploadToRecognizeSelector)),
        map(([_, state]: [never, UploadToRecognizeState]): UploadToRecognizeState => state),
        withLatestFrom(this.store.select(SelectedSpaceForMobileSelectors.space)),
        map(([payload, space]: [UploadToRecognizeState, Space]): UploadToRecognizeState =>
            ({ ...payload, spaceId: space.id, openPostUploadDialog: false, mobile: true })
        ),
        map((state: UploadToRecognizeState) => new UploadTaskToRecognizeAction(state))
    );


    /**
     * Эффект успешной отправки файлов на распознавание.
     */
    @Effect({dispatch: false})
    uploadSuccess$ = this.actions$.pipe(
        ofType<UploadTaskToRecognizeAction>(UploadToRecognizeActionType.UPLOAD_SUCCESS),
        tap(() => this._gtag.event("send_to_recognition_successfully"))
    );

    /**
     * Генерирует хеши для файлов.
     */
    @Effect()
    generateFileHashes$ = this.actions$.pipe(
        ofType<GenerateHashForFilesAction>(UploadToRecognizeActionType.GENERATE_HASH_FOR_FILES),
        switchMap(action => FileHashUtils.filesToSHA256Hash(action.payload)
            .pipe(map(filesWithMetaData => new AddFilesToTaskToRecognizeAction(filesWithMetaData))),
        ),
    );

    /**
     * Осуществляет запрос проверки существования файлов.
     */
    @Effect()
    checkFilesExistence$ = this.actions$.pipe(
        ofType<AddFilesToTaskToRecognizeAction>(UploadToRecognizeActionType.ADD_FILES),
        withLatestFrom(this.store.select(spaceSelector)),
        switchMap(([action, space]) =>
                this.service.checkFilesExistence(space, action.payload.filter((file: FileWithHash) => !!file.file.size))
                    .pipe(
                        map(response => new MarkFilesAsDuplicatesAction(response.fileMetaDataList)),
                        catchError((error: ApiResponse) => of(new FileExistenceCheckFailedAction(error))),
                    ),
        ),
    );

    //endregion
}

function isRecognitionTaskApiResponse(
    val: RecognitionTaskApiResponse | MultipleRecognitionTaskApiResponse
): val is RecognitionTaskApiResponse {

    return !!val && !!val["recognitionTask"];
}
