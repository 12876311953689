import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { RootState } from '../reducers';
import { HeaderActionType } from '../actions';
import { tap } from 'rxjs/operators';

/**
 * Side-эффекты на события, связанные с заголовком сайта.
 */
@Injectable()
export class HeaderEffects {
    //region Fields

    /**
     * Сервис для работы с DOM.
     */
    private renderer: Renderer2;

    //endregion
    //region Ctor

    constructor(
        private actions$: Actions,
        @Inject(DOCUMENT) private document: any,
        private rendererFactory: RendererFactory2,
        private store: Store<RootState>
    ) { 
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    //endregion
    //region Ctor

    /**
     * Обработка события отображения заголовка сайта.
     */
    @Effect({ dispatch: false })
    showHeaderEffect$ = this.actions$
        .pipe(
            ofType(HeaderActionType.SHOW),
            tap(() => {

                this.renderer.addClass(this.document.body, 'with-header');
            })
        );

    /**
     * Обработка события скрытия заголовка сайта.
     */
    @Effect({ dispatch: false })
    hideHeaderEffect$ = this.actions$
        .pipe(
            ofType(HeaderActionType.HIDE),
            tap(() => {

                this.renderer.removeClass(this.document.body, 'with-header');
            })
        );

    //endregion
}