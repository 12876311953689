var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ofType } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { CurrencyService } from "src/app/common/services/currency.service";
import { currenciesActions } from "src/app/root/store/actions/currencies.action";
import { CurrenciesActionType } from "src/app/root/store/actions/currencies.action";
/**
 * Side-эффекты на события, связанные с валютами.
 */
var CurrenciesEffects = /** @class */ (function () {
    //region Ctor
    function CurrenciesEffects(_actions$, _store, _currencyService) {
        var _this = this;
        this._actions$ = _actions$;
        this._store = _store;
        this._currencyService = _currencyService;
        //endregion
        //region Effects
        /**
         * Эффект на событие получения валют с сервера.
         */
        this.getCurrencies$ = this._actions$.pipe(ofType(CurrenciesActionType.LOAD), switchMap(function () { return _this._currencyService.getCurrencies()
            .pipe(map(function (currencies) { return currenciesActions.loadSuccess({ value: currencies }); }), catchError(function (error) {
            return of(currenciesActions.loadFail({ error: error }));
        })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CurrenciesEffects.prototype, "getCurrencies$", void 0);
    return CurrenciesEffects;
}());
export { CurrenciesEffects };
