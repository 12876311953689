<div class="recognition-upload-dialog">
    <h1 class="recognition-upload-dialog__title mat-h1 mat-h1_slim text-center">
        {{"common.upload-to-recognize-dialog.title" | translate}}
    </h1>
    <entera-file-upload-zone *ngIf="!uploadState.error && uploadState.progress !== 100 && !uploadState.duplicateCheckingByHash"
        [uploadState]="uploadState"
        (addFiles)="addFilesHandler($event)"
        (remove)="remove.emit($event)"
    >
    </entera-file-upload-zone>
    <div
        *ngIf="
            uploadState
            && uploadState.filesWithMetaData.length
            && !uploadState.duplicateCheckingByHash
            && !uploadState.error
            && uploadState.progress !== 100
        "
        class="recognition-upload-dialog__send text-center"
    >
        <div *ngIf="(permissions$ | async)['RECOGNITION_FLAGS']"
             class="recognition-upload-dialog__process-flags"
        >
            <mat-checkbox [formControl]="forceOcr">
                {{ 'common.upload-to-recognize-dialog.forceOcr' | translate }}
            </mat-checkbox><br/>
            <mat-checkbox [formControl]="forceProcessing">
                {{ 'common.upload-to-recognize-dialog.forceProcessing' | translate }}
            </mat-checkbox><br/>
            <mat-checkbox [formControl]="forceQueue">
                {{ 'common.upload-to-recognize-dialog.forceQueue' | translate }}
            </mat-checkbox>
        </div>
        <mat-form-field class="recognition-upload-dialog__comment">
            <input
                matInput
                [placeholder]="'common.upload-to-recognize-dialog.comment' | translate"
                [formControl]="comment"
                maxlength="250"
            >
            <mat-hint>{{'common.upload-to-recognize-dialog.commentHint' | translate}}</mat-hint>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="upload.emit(uploadState)">
            <mat-icon>send</mat-icon>
            {{"common.upload-to-recognize-dialog.buttons.upload" | translate}}
        </button>
    </div>
    <div *ngIf="uploadState.error" class="recognition-upload-dialog__error">
        {{ uploadState.error.errorMessage }}
        <hr/>
        <div>
            <button mat-button (click)="close()">
                {{ 'dialogs.close' | translate }}
            </button>
        </div>
    </div>
    <div *ngIf="uploadState.progress === 100 && !uploadState.error || uploadState.duplicateCheckingByHash">
        <mat-spinner class="recognition-upload-dialog__spinner"></mat-spinner>
    </div>
    <button
        class="recognition-upload-dialog__close"
        (click)="close()"
        mat-icon-button
        color="primary"
    >
        <mat-icon class="mat-icon_large">close</mat-icon>
    </button>
</div>
