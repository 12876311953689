import { DocumentCreatorsActionType, DocumentCreatorsAction } from "../actions";
import { User } from "src/app/common/models/user";

/**
 * Состояние создателей документов.
 */
export interface DocumentCreatorsState {

    /**
     * Создатели документов загружаются?
     */
    loading: boolean;

    /**
     * Создатели документов загружены?
     */
    loaded: boolean;

    /**
     * Загрузка создателей документов завершилась ошибкой?
     */
    failed: boolean;

    /**
     * Создатели документов.
     */
    documentCreators: User[];
}

/**
 * Начальное состояние создателей документов.
 */
const initialState: DocumentCreatorsState = {

    /**
     * Создатели документов не загружаются.
     */
    loading: false,

    /**
     * Создатели документов не загружены.
     */
    loaded: false,

    /**
     * Загрузка создателей документов ошибкой не завершалась.
     */
    failed: false,

    /**
     * Создателей документов нет.
     */
    documentCreators: []
}

/**
 * Обработчик событий, связанных с создателями документов.
 * 
 * @param state Состояние создателей документов.
 * @param action Событие произошедшее в системе.
 */
export function documentCreatorsReducer(state = initialState, action: DocumentCreatorsAction): DocumentCreatorsState {

    switch(action.type) {

        // Требование загрузить создателей документов.
        case DocumentCreatorsActionType.LOAD: {

            return {
                ...state,
                loading: true,
                loaded: false,
                failed: false
            };
        }

        // Уведомление об успешной загрузке создателей документов.
        case DocumentCreatorsActionType.LOAD_SUCCESS: {

            const documentCreators = action.payload;
            return {
                ...state,
                loading: false,
                loaded: true,
                documentCreators
            };
        }

        // Увдомление о неудачной попытке загрузки создателей документов.
        case DocumentCreatorsActionType.LOAD_FAIL: {

            return {
                ...state,
                loading: false,
                failed: true,
                documentCreators: undefined
            };
        }
    }

    return state;
}

/**
 * Возвращает создателей документов из состояния создателей документов.
 * 
 * @param state Состояние создателей документов.
 */
export const getDocumentCreators = (state: DocumentCreatorsState) => state.documentCreators;

/**
 * Возвращает флаг выполняющейся загрузки создателей документов из состояния создателей документов.
 * 
 * @param state Состояние создателей документов.
 */
export const getDocumentCreatorsLoading = (state: DocumentCreatorsState) => state.loading;

/**
 * Возвращает флаг успешно выполненной загрузки создателей документов из состояния создателей документов.
 * 
 * @param state Состояние создателей документов.
 */
export const getDocumentCreatorsLoaded = (state: DocumentCreatorsState) => state.loaded;

/**
 * Возвращает флаг неудачной попытки загрузки создателей документов из состояния создателей документов.
 * 
 * @param state Состояние создателей документов.
 */
export const getDocumentCreatorsFailed = (state: DocumentCreatorsState) => state.failed;
