import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material";
import { UrlUtils } from "src/app/common/utils/url.utils";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@angular/common/http";
/**
 * Сервис для отправки ответов пользователя на сообщения.
 */
var BroadcastMessagesService = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор сервиса для отправки ответов пользователя на сообщения.
     *
     * @param dialog Сервис для работы с диалогами.
     * @param httpClient HTTP-клиент.
     */
    function BroadcastMessagesService(dialog, httpClient) {
        this._dialog = dialog;
        this._http = httpClient;
    }
    //endregion
    //region Public
    /**
     * Отправляет ответ на сообщение на сервер.
     *
     * @param broadcastMessageId ID сообщения, к которому относится ответ.
     * @param broadcastMessageResponse Ответ на сообщение
     */
    BroadcastMessagesService.prototype.sendResponse = function (broadcastMessageId, broadcastMessageResponse) {
        return this._http
            .post(UrlUtils.BroadcastMessageResponseUrl(broadcastMessageId), broadcastMessageResponse)
            .pipe(map(function (response) { return response.result; }), catchError(function (response) {
            return throwError(response.error);
        }));
    };
    BroadcastMessagesService.ngInjectableDef = i0.defineInjectable({ factory: function BroadcastMessagesService_Factory() { return new BroadcastMessagesService(i0.inject(i1.MatDialog), i0.inject(i2.HttpClient)); }, token: BroadcastMessagesService, providedIn: "root" });
    return BroadcastMessagesService;
}());
export { BroadcastMessagesService };
