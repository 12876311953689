<div class="create-ledger-dlg" [formGroup]="ledgerGroup">
    <h2 class="mat-h2 create-ledger-dlg__header">{{ "matcher.createLedger.title" | translate }}</h2>
    <div class="create-ledger-dlg__content">

        <div class="create-ledger-dlg__column">
            <mat-form-field>
                <input
                    matInput
                    [required]="true"
                    placeholder="{{'matcher.createLedger.ledgerName' | translate}}"
                    formControlName="ledgerName"
                >
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'matcher.createLedger.under' | translate}}</mat-label>
                <mat-select formControlName="under" required>
                    <mat-option *ngFor="let ledgerType of ledgerTypes" [value]="ledgerType.id">
                        {{ledgerType.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>


        <div class="create-ledger-dlg__column">
            <mat-form-field *ngIf="showGstinHandler()">
                <input
                    matInput
                    placeholder="{{'matcher.createLedger.gstin' | translate}}"
                    formControlName="gstin"
                >
            </mat-form-field>
            <mat-form-field *ngIf="showAddressHandler()">
                <input
                    matInput
                    placeholder="{{'matcher.createLedger.address' | translate}}"
                    formControlName="address"
                >
            </mat-form-field>
            <mat-form-field *ngIf="showStateHandler()">
                <input
                    matInput
                    placeholder="{{'matcher.createLedger.state' | translate}}"
                    formControlName="state"
                >
            </mat-form-field>
        </div>
    </div>
    <div  class="create-ledger-dlg__buttons">
        <button
            class="create-ledger-dlg__btn"
            mat-raised-button
            color="primary"
            (click)="okBtnHandler()"
        >
            {{ "matcher.createLedger.save" | translate }}
        </button>
        <button
            class="create-ledger-dlg__btn"
            mat-button
            (click)="cancelBtnHandler()"
        >
            {{ "button.cancel" | translate }}
        </button>
    </div>
</div>