import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material";
import { FirstUploadDlgComponent } from "../containers/first-upload-dlg/first-upload-dlg.component"; /* circular dependency break */
import { Constants } from "../../common/models";

/**
 * Сервис для вызова диалога с подсказкой после первой загрузки файла на распознавание.
 */
@Injectable({
    providedIn: "root",
})
export class FirstUploadDlgService {
    //region Fields

    /**
     * Сервис для работы с диалогами.
     */
    private readonly _dialog: MatDialog;

    //endregion
    //region Ctor

    /**
     * Конструктор сервиса для вызова диалога с подсказкой после первой загрузки файла на распознавание.
     *
     * @param dialog Сервис для работы с диалогами.
     */
    constructor(dialog: MatDialog) {

        this._dialog = dialog;
    }

    //endregion
    //region Public

    /**
     * Открывет диалог с подсказкой после первой загрузки файла на распознавание.
     */
    public open(): void {

        this._dialog.open(FirstUploadDlgComponent, {
            panelClass: [Constants.DIALOG_PANE_CLASS, "first-upload-dlg-pane"],
            autoFocus: false,
            disableClose: true,
        });
    }

    //endregion
}
