import { NgModule } from "@angular/core";
import { Injector } from "@angular/core";
import { APP_INITIALIZER } from "@angular/core";
import { Store } from "@ngrx/store";
import { select } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { filter } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { LogrocketService } from "src/app/root/services/logrocket.service";

import { environment } from "src/environments/environment";
import { LangService } from "../common/services";
import { MetrikaModule } from "../yandex-metrika";
import { Metrika } from "../yandex-metrika";
import { DEFAULT_COUNTER_ID } from "../yandex-metrika";
import { DEFAULT_COUNTER_ID_AOT } from "../yandex-metrika";
import { YANDEX_COUNTERS_CONFIGS } from "../yandex-metrika";
import { YANDEX_COUNTERS_CONFIGS_AOT } from "../yandex-metrika";
import { appInitializerFactory } from "../yandex-metrika";
import { countersFactory } from "../yandex-metrika";
import { defaultCounterIdFactory } from "../yandex-metrika";
import { FakeMetrika } from "../yandex-metrika";
import { components } from "./components";
import { RootComponent } from "./containers";
import { containers } from "./containers";
import { entryComponents } from "./containers";
import { guards } from "./guards";
import { langs } from "./i18n";
import { modules } from "./modules";
import { providers } from "./providers";
import { RootState } from "./store";
import { LangChangeAction } from "./store";
import { currentUserLangSelector } from "./store";
import { CurrentUserLoadAction } from "./store";

@NgModule({
    imports: [
        ...modules,
        environment.metrikaDisabled ? [] : {
            ngModule: MetrikaModule,
            providers: [
                {
                    provide: DEFAULT_COUNTER_ID_AOT,
                    useValue: environment.yandexCounters["main"].id,
                },
                {
                    provide: YANDEX_COUNTERS_CONFIGS_AOT,
                    useValue: Object.keys(environment.yandexCounters).map(key => environment.yandexCounters[key]),
                },
                {
                    provide: DEFAULT_COUNTER_ID,
                    useFactory: defaultCounterIdFactory,
                    deps: [YANDEX_COUNTERS_CONFIGS_AOT, DEFAULT_COUNTER_ID_AOT],
                },
                {
                    provide: YANDEX_COUNTERS_CONFIGS,
                    useFactory: countersFactory,
                    deps: [YANDEX_COUNTERS_CONFIGS_AOT],
                },
                {
                    provide: APP_INITIALIZER,
                    useFactory: appInitializerFactory,
                    deps: [YANDEX_COUNTERS_CONFIGS],
                    multi: true,
                },
                {
                    provide: Metrika,
                    useClass: Metrika,
                    deps: [Injector],
                }
            ],
        }
    ],
    declarations: [
        ...containers,
        ...components,
    ],
    entryComponents: [
        ...entryComponents,
    ],
    providers: [
        ...providers,
        ...guards,
        environment.metrikaDisabled ? {
            provide: Metrika,
            useClass: FakeMetrika,
        } : []
    ],
    bootstrap: [
        RootComponent
    ],
})
export class RootModule {
    //region Ctor

    constructor(
        private store: Store<RootState>,
        private translateService: TranslateService,
        private langService: LangService,
        private logrocketService: LogrocketService,
    ) {
        // На старте модуля загружаем его i18n-сообщения.
        this.langService.loadTranslations(this.translateService, langs);

        // Язык по умолчанию.
        // TODO Нужно в будущем ещё обращать внимание на куку ENTERA_LANG.
        this.store.dispatch(new LangChangeAction("ru"));

        // Вызываем загрузку пользователя.
        this.store.dispatch(new CurrentUserLoadAction());

        // Подписываемся на изменение языка пользователя, чтобы сигнилизировать его изменение.
        this.store
            .pipe(
                select(currentUserLangSelector),
                filter(lang => !!lang),
                tap(lang => this.store.dispatch(new LangChangeAction(lang)))
            )
            .subscribe();

        const gtagEnabled = environment.gtag && environment.gtag.enabled;

        const windowAsAny: any = window;
        windowAsAny.dataLayer = windowAsAny.dataLayer || [];
        windowAsAny.gtag = function () {
            if (gtagEnabled) {
                windowAsAny.dataLayer.push(arguments);
            }
        };

        if (gtagEnabled) {

            const gtagScript = document.createElement("script");
            gtagScript.src = "https://www.googletagmanager.com/gtag/js?id=" + environment.gtag.stream;
            gtagScript.async = true;
            document.body.appendChild(gtagScript);

            windowAsAny.gtag("js", new Date());
            windowAsAny.gtag(
                "config",
                environment.gtag.stream,
                { send_page_view: false, debug_mode: gtagEnabled && environment.gtag.debug}
            );
        }

        this.logrocketService.init();
    }

    //endregion
}
