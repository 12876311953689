import { Action } from '@ngrx/store';

import { ApiResponsePayloadAction } from "../";
import { ApiResponse } from '../../../common/models';
import { Space } from 'src/app/common/models';
import { BalanceInfo } from 'src/app/common/models';

/**
 * Типы событий, связанные с балансом.
 */
export enum BalanceActionType {
    LOAD = '[Root] Load balance for space',
    LOAD_SUCCESS = '[Root] Load balance for space Success',
    LOAD_FAIL = '[Root] Load balance for space Fail',
    WITHDRAW = '[Root] Withdraw balance for space',
    CLEAR = '[Root] Clear balance for space',
}

/**
 * Событие, требующее загрузки данных баланса для пространства документов.
 */
export class BalanceLoadAction implements Action {
    readonly type = BalanceActionType.LOAD;
    constructor(public payload: Space) { }
}

/**
 * Событие успешной загрузки баланса для пространства документов.
 */
export class BalanceLoadSuccessAction implements Action {
    readonly type = BalanceActionType.LOAD_SUCCESS;
    constructor(public payload: BalanceInfo) { }
}

/**
 * Событие неудачной загрузки баланса для пространства документов.
 */
export class BalanceLoadFailAction implements ApiResponsePayloadAction {
    readonly type = BalanceActionType.LOAD_FAIL;
    constructor(public payload: ApiResponse) { }
}

/**
 * Событие, требующее списания с баланса пространства документов заданной стоимости.
 */
export class BalanceWithdrawAction implements Action {
    readonly type = BalanceActionType.WITHDRAW;
    constructor(public payload: number) { }
}

/**
 * Событие, требующее очистки данных баланса для пространства документов.
 */
export class BalanceClearAction implements Action {
    readonly type = BalanceActionType.CLEAR;
    constructor() { }
}

/**
 * Тип, объединяющий все события, связанные с балансом.
 */
export type BalanceAction =
    | BalanceLoadAction
    | BalanceLoadFailAction
    | BalanceLoadSuccessAction
    | BalanceWithdrawAction
    | BalanceClearAction;
