import { createFeatureSelector } from "@ngrx/store";
import { createSelector } from "@ngrx/store";
import { getRequestResult } from "src/app/common/models/request-state";
import { DocumentTypesState } from "src/app/root/store/states/document-types.state";

/**
 * Селектор состояния типов документов.
 */
const documentTypesState = createFeatureSelector<DocumentTypesState>("documentTypes");

/**
 * Селектор типов документов.
 */
const documentTypes = createSelector(
    documentTypesState,
    state => getRequestResult(state.documentTypesRequest),
);

/**
 * Селектор состояния запроса на загрузку типов документов.
 */
const requestState = createSelector(
    documentTypesState,
    state => state.documentTypesRequest.type,
);

/**
 * Все селекторы, связанные с типами документов.
 */
export const documentTypesSelector = {
    documentTypes,
    requestState,
}