import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { HostListener } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppEnvironment } from "../../../../environments/app-enviroment.model";
import { Constants } from "../../../common/models";
import { ConfigurationService } from "../../../common/services/configuration.service";

/**
 * Компонент диалога для отображения подсказки после первой загрузки файла на распознавание.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "first-upload-dlg",
    templateUrl: "./first-upload-dlg.component.html",
    styleUrls: ["./first-upload-dlg.component.scss"],
})
export class FirstUploadDlgComponent {
    //region Fields

    /**
     * Диалог, в котором открывается текущий компонент.
     */
    private readonly _dialogRef: MatDialogRef<FirstUploadDlgComponent>;

    /**
     * Переменная для определения конфигурации окружения приложения.
     */
    readonly isGlobal$: Observable<boolean>;

    //endregion
    //region Ctor
    /**
     * Конструктор компонента диалога для отображения подсказки после первой загрузки файла на распознавание.
     *
     * @param dialogRef Диалог, в котором открывается текущий компонент.
     * @param configService Сервис по работе с конфигурациями окружения приложения.
     */
    constructor(dialogRef: MatDialogRef<FirstUploadDlgComponent>, configService: ConfigurationService) {

        this._dialogRef = dialogRef;
        this.isGlobal$ = configService.loadConfigurations().pipe(
            map((env: AppEnvironment) => env.global),
        );
    }

    //endregion
    //region Getters

    /**
     * Ссылка на инструкцию по интеграции нашего сервиса c 1C.
     */
    get seeMoreLink() {

        return Constants.MODULE_1C_BLOG_POST_URL;
    }

    //endregion
    //region Public

    /**
     * Обработчик клика по копке "Посмотреть" с переходом на страницу интеграций.
     */
    seeMoreClick(): void {

        this._dialogRef.close();
    }

    //endregion
    //region Events

    /**
     * Обработчик нажатия на Esc.
     *
     * Закрывает диалог.
     */
    @HostListener("window:keyup.esc")
    onKeyUp() {

        this._dialogRef.close();
    }

    //endregion
}
