import { createSelector } from "@ngrx/store";
import { PermissionItem } from "src/app/common/models";
import { Space } from "src/app/common/models";
import { ExpiryNotificationType } from "src/app/common/models/expiry-notification-type";
import { SpacePermission } from "src/app/common/models/space-permission-type.model";
import { spaceSelector } from "src/app/root/store/selectors/space.selector";
import { CurrentUserInfo } from "../../../common/models";

import { currentUserStateSelector } from "../reducers";
import { getCurrentUserInfo } from "../reducers/current-user.reducer";
import { getCurrentUserLang } from "../reducers/current-user.reducer";
import { getCurrentUserFailed } from "../reducers/current-user.reducer";
import { getCurrentUserLoaded } from "../reducers/current-user.reducer";
import { getCurrentUserLoading } from "../reducers/current-user.reducer";

/**
 * Селектор данных текущего пользователя.
 */
export const currentUserInfoSelector = createSelector(currentUserStateSelector, getCurrentUserInfo);

/**
 * Селектор пространств документов текущего пользователя.
 */
export const currentUserSpacesSelector = createSelector(
    currentUserInfoSelector,
    (user) => user ? user.spaces : null
);

/**
 * Селектор языка текущего пользователя.
 */
export const currentUserLangSelector = createSelector(currentUserInfoSelector, getCurrentUserLang);

/**
 * Селектор баланса клиента пользователя.
 */
export const currentUserClientBalance = createSelector(
    currentUserInfoSelector,
    user => user && user.client && user.client.balance || 0,
);

/**
 * Селектор даты истечения баланса клиента пользователя.
 */
export const currentUserClientBalanceExpiration = createSelector(
    currentUserInfoSelector,
    user => user && user.client && user.client.balanceExpirationDate,
);

/**
 * Селектор прав текущего пользователя в виде объекта.
 */
export const currentUserPermissionsSelector = createSelector(
    currentUserInfoSelector,
    (user: CurrentUserInfo) => user.permissions
        .reduce((p: { [key: string]: boolean }, item: string) => { p[item] = true; return p; }, {})
);

/**
 * Селектор флага успешной загрузки данных текущего пользователя.
 */
export const currentUserLoadedSelector = createSelector(currentUserStateSelector, getCurrentUserLoaded);

/**
 * Селектор флага выполняющейся загрузки данных текущего пользователя.
 */
export const currentUserLoadingSelector = createSelector(currentUserStateSelector, getCurrentUserLoading);

/**
 * Селектор флага была ли ошибка при загрузке данных текущего пользователя.
 */
export const currentUserFailedSelector = createSelector(currentUserStateSelector, getCurrentUserFailed);

/**
 * Селектор списка пространств документов пользователя, в которые у него есть права на приглашение
 */
const spacesForInvitations = createSelector(
    currentUserInfoSelector,
    (user: CurrentUserInfo): Space[] => (user ? user.spaces : [])
        .filter((space: Space): boolean =>
            user.spacePermissions.some((spacePermission: SpacePermission) =>
                spacePermission.spaceId === space.id
                && spacePermission.permissions.indexOf(PermissionItem.INVITE_USERS.value) !== -1
            )
        )
);

/**
 * Селектор списка пространств документов пользователя, в которых у него есть права управлять интеграциями.
 */
const spacesForManagingIntegrations = createSelector(
    currentUserInfoSelector,
    (user: CurrentUserInfo): Space[] => (user ? user.spaces : [])
        .filter((space: Space): boolean =>
            user.spacePermissions.some((spacePermission: SpacePermission) =>
                spacePermission.spaceId === space.id
                && spacePermission.permissions.indexOf(PermissionItem.INTEGRATION.value) !== -1
            )
        )
);

/**
 * Селектор списка пространств документов пользователя, в которых у него есть права управлять уведомлениями
 * пользователей на почту о состоянии задачи на распознавание.
 */
const spacesForManagingEmailNotifications = createSelector(
    currentUserInfoSelector,
    (user: CurrentUserInfo): Space[] => (user ? user.spaces : [])
        .filter((space: Space): boolean =>
            user.spacePermissions.some((spacePermission: SpacePermission) =>
                spacePermission.spaceId === space.id
                && spacePermission.permissions.indexOf(PermissionItem.MANAGE_EMAIL_NOTIFICATIONS.value) !== -1
            )
        )
);

/**
 * Селектор наличия у текущего пользователя права приглашать хотя бы в одно своё пространство документов.
 */
const canInviteInAnySpace = createSelector(
    spacesForInvitations,
    (spaces: Space[]): boolean => spaces.length > 0
);

/**
 * Селектор наличия у текущего пользователя права видеть список доверенных почт.
 */
const canSeeTrustedEmails = createSelector(
    currentUserInfoSelector,
    (user: CurrentUserInfo): boolean => user.permissions.some((permission: string) =>
        PermissionItem.SEE_TRUSTED_EMAILS.value === permission
    )
);

/**
 * Селектор активности подписки на функционал отчёта реализации текущего пользователя.
 */
const salesReportSubscriptionActive = createSelector(
    currentUserInfoSelector,
    (user: CurrentUserInfo): boolean => {

        if (user) {

            return (
                user.permissions.includes(PermissionItem.SEE_ALL_DOCUMENTS.value)
                || user.client.salesReportSubscriptionActive
            );
        }

        return false;
    }
);

/**
 * Селектор того, скоро ли истекает баланс или страницы у клиента.
 */
export const soonExpirationSelector = createSelector(
    currentUserInfoSelector,
    (user: CurrentUserInfo) => !!user
        && !user.permissions.includes(PermissionItem.SEE_ALL_DOCUMENTS.value)
        && user.spaces.every(space => space.paid)
        && !!user.client.expiryNotificationStartDate
);

/**
 * Селектор типа уведомления клиента об истечении.
 */
export const expiryNotificationTypeSelector = createSelector(
    currentUserInfoSelector,
    (user: CurrentUserInfo): ExpiryNotificationType => !!user && user.client.expiryNotificationType || null
);

/**
 * Объект, объединяющий селекторы, которые относятся к состоянию данных текущего пользователя.
 */
export const currentUserSelectors = {
    spacesForInvitations,
    canInviteInAnySpace,
    canSeeTrustedEmails,
    spacesForManagingIntegrations,
    spacesForManagingEmailNotifications,
    salesReportSubscriptionActive,
};
