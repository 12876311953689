import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { currentUserStateSelector } from 'rootStore';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
/**
 * Сервис для добавления виджета Omnidesk на страницу.
 */
var OmnideskWidgetService = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор сервиса для добавления виджета Omnidesk на страницу.
     *
     * @param store Сервис для управления и доступа к состоянию приложения.
     */
    function OmnideskWidgetService(store) {
        this._store = store;
        this._scriptInserted = false;
    }
    //endregion
    //region Public
    /**
     * Добавляет виджет Omnidesk на страницу приложения.
     *
     * Сначала дожидается загрузки данных пользователя, после чего добавляет скрипт виджета на страницу приложения.
     */
    OmnideskWidgetService.prototype.add = function () {
        var _this = this;
        if (!this._scriptInserted) {
            this._userSubscription = this._store
                .pipe(select(currentUserStateSelector), filter(function (state) { return state.loaded; }), map(function (state) { return state.currentUserInfo; }))
                .subscribe(function (currentUser) {
                _this._addWidget(currentUser);
                setTimeout(function () { return _this._userSubscription.unsubscribe(); }, 0);
            });
        }
    };
    //endregion
    //region Private
    /**
     * Добавляет скрипт виджета Omnidesk на страницу.
     */
    OmnideskWidgetService.prototype._addWidget = function (user) {
        var widgetId = environment.omnidesk.widgetId;
        if (!this._scriptInserted && widgetId) {
            var emailConfig = '';
            if (user && user.email) {
                emailConfig = "\n                    window.omni[0].widget = {\n                        ready: function() {\n                            window.omni[0].widget.identify = {\n                                user_email: '" + user.email + "'\n                            };\n                        }\n                    };\n                ";
            }
            var script = document.createElement('script');
            script.text = "\n                !function(e,o) {\n                    !window.omni?window.omni=[]:'';\n                    window.omni.push(o);\n                    o.g_config={widget_id:\"" + widgetId + "\"};\n                    o.email_widget=o.email_widget||{};\n                    var w=o.email_widget;\n                    w.readyQueue=[];\n                    o.config=function(e){this.g_config.user=e};\n                    w.ready=function(e){this.readyQueue.push(e)};\n                    window.omni[0].config({ user_info: true });\n                    " + emailConfig + "\n                    var r = e.getElementsByTagName(\"script\")[0];\n                    c = e.createElement(\"script\");\n                    c.type=\"text/javascript\";\n                    c.async=!0;\n                    c.src=\"https://omnidesk.ru/bundles/acmesite/js/cwidget0.2.min.js\";\n                    r.parentNode.insertBefore(c,r);\n                } (document, [])\n            ";
            document.body.appendChild(script);
            this._scriptInserted = true;
        }
    };
    OmnideskWidgetService.ngInjectableDef = i0.defineInjectable({ factory: function OmnideskWidgetService_Factory() { return new OmnideskWidgetService(i0.inject(i1.Store)); }, token: OmnideskWidgetService, providedIn: "root" });
    return OmnideskWidgetService;
}());
export { OmnideskWidgetService };
