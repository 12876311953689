var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HeaderActionType } from '../actions/header.action';
/**
 * Начальное состояние по данным текущего пользователя.
 */
export var initialState = {
    // Заголовок не виден.
    visible: false,
    // Заголовок скрыт.
    hidden: true,
    // Выбор простарнтсва показывается
    showSpacesInfo: true,
    howToStartButtonVisibility: true,
};
/**
 * Обработка состояния заголовка в ответ на события.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 */
export function headerReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        // На событие скрытия заголовка.
        case HeaderActionType.HIDE: {
            return __assign({}, state, { visible: false, hidden: true });
        }
        // На событие отображения заголовка.
        case HeaderActionType.SHOW: {
            return __assign({}, state, { visible: true, hidden: false });
        }
        case HeaderActionType.HIDE_SPACES_INFO: {
            return __assign({}, state, { showSpacesInfo: false });
        }
        case HeaderActionType.SHOW_SPACES_INFO: {
            return __assign({}, state, { showSpacesInfo: true });
        }
        case HeaderActionType.HIDE_HOW_TO_START_BUTTON: {
            return __assign({}, state, { howToStartButtonVisibility: false });
        }
        case HeaderActionType.SHOW_HOW_TO_START_BUTTON: {
            return __assign({}, state, { howToStartButtonVisibility: true });
        }
    }
    return state;
}
/**
 * Возвращает флаг видимости заголовка сайта.
 *
 * @param state Состояние данных о заголовке сайта.
 */
export var getHeaderVisible = function (state) { return state.visible; };
/**
 * Возвращает флаг скрытости заголовка сайта.
 *
 * @param state Состояние данных о заголовке сайта.
 */
export var getHeaderHidden = function (state) { return state.hidden; };
