/**
 * Состояние интерфейса страницы документа.
 */
export interface DocumentPageInterfaceState {
    //region Fields

    /**
     * Таблица документа должна отображаться?
     */
    readonly tableVisibility: boolean;

    //endregion
}

/**
 * Возвращает состояние интерфейса страницы документа по умолчанию.
 */
export function getDefaultDocumentPageInterfaceState(): DocumentPageInterfaceState {

    return {
        tableVisibility: true,
    };
}