import { createSelector } from '@ngrx/store';
import { newDocumentsCountStateSelector } from '../../reducers';
import { NewDocumentsCountState } from '../../reducers/document';

/**
 * Селектор кол-ва новых документов.
 */
export const newDocumentsCountSelector = createSelector(
    newDocumentsCountStateSelector,
    (state: NewDocumentsCountState) => state.newDocumentsCount
);
