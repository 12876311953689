import { MatchingCompaniesState } from "src/app/spaces/modules/document-matching/store/states/matching-companies.state";
import { requestState } from "src/app/common/models/request-state";
import { MatchingCompaniesAction } from "src/app/spaces/modules/document-matching/store/actions/matching-companies.action";
import { MatchingCompaniesActionType } from "src/app/spaces/modules/document-matching/store/actions/matching-companies.action";

/**
 * Начальное состояние сопоставления компаний.
 */
const initialState: MatchingCompaniesState = {
    customersRequest: requestState.initial(),
    suppliersRequest: requestState.initial(),
};

/**
 * Обработчик событий, связанных с сопоставлением компаний.
 *
 * @param state Текущее состояние сопоставления компаний.
 * @param action Произошедшее событие.
 */
export function matchingCompaniesReducer(
    state = initialState,
    action: MatchingCompaniesAction
): MatchingCompaniesState {
    let result = state;

    switch (action.type) {
        case MatchingCompaniesActionType.GET: {

            result = {
                ...state,
                [`${action.companyType}Request`]: requestState.pending(),
            };

            break;
        }
        case MatchingCompaniesActionType.SUCCESS: {

            result = {
                ...state,
                [`${action.companyType}Request`]: requestState.success(action.companies),
            };
            break;
        }
        case MatchingCompaniesActionType.FAIL: {

            result = {
                ...state,
                [`${action.companyType}Request`]: requestState.fail(action.error),
            };
            break;
        }
    }

    return result;
}
