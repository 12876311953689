import { OnInit } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { select } from "@ngrx/store";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { CurrentUserInfo } from "src/app/common/models/current-user-info";
import { Space } from "src/app/common/models/space";
import { RouterGoAction } from "src/app/root/store/actions/router.action";
import { SelectedSpaceForMobileActions } from "src/app/root/store/actions/selected-space-for-mobile.action";
import { UploadToRecognizeState } from "src/app/root/store/index";
import { ChangeTaskToRecognizeAction } from "src/app/root/store/index";
import { currentUserInfoSelector } from "src/app/root/store/index";
import { RootState } from "src/app/root/store/index";

/**
 * Корневой компонент мобильного интерфейса приложения.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "mobile-main",
    styleUrls: ["./mobile-main.component.scss"],
    templateUrl: "./mobile-main.component.html"
})
export class MobileMainComponent implements OnInit {
    //region Fields

    /**
     * Пространства документов текущего пользователя.
     */
    spaces$: Observable<Space[]>;

    /**
     * Данные текущего аутентифицированного пользователя.
     */
    userInfo$: Observable<CurrentUserInfo>;

    /**
     * Не выбрано пространство документов?
     */
    noSelectedSpace: boolean;

    /**
     * Сервис для доступа и управления состоянием приложения.
     */
    private readonly _store: Store<RootState>;

    //endregion
    //region Ctor

    /**
     * Конструктор корневого компонента мобильного интерфейса приложения.
     *
     * @param store Сервис для доступа и управления состоянием приложения.
     */
    constructor(store: Store<RootState>) {

        this.noSelectedSpace = true;
        this._store = store;
    }

    //endregion
    //region Hooks

    /**
     * Логика при инициализации компонента.
     */
    ngOnInit(): void {

        this.userInfo$ = this._store.pipe(select(currentUserInfoSelector));

        // Подписываемся на события появления/изменения пространств документов пользователя.
        this.spaces$ = this.userInfo$.pipe(
                filter((currentUser: CurrentUserInfo): boolean => !!currentUser),
                map(this.userInfoToSpaces),
            );
    }


    //endregion
    //region Events

    /**
     * Обработчик выбора пространства документов.
     *
     * @param space Выбранное пространство документов.
     */
    spaceSelectHandler(space: Space): void {

        this._store.dispatch(SelectedSpaceForMobileActions.set({ value: space }));
        this.noSelectedSpace = !space;
    }

    /**
     * Переход на страницу сканирования.
     */
    onScanQrClick(): void {

        this._store.dispatch(new RouterGoAction({ path: ["qr"] }));
    }

    /**
     * Переход на страницу загрузки документов и возвращение состояния данных при загрузке и отправке файлов на
     * распознавание к первоначальному состоянию.
     */
    onUploadDocumentsMobile(): void {

        this._store.dispatch(
            new ChangeTaskToRecognizeAction({...new UploadToRecognizeState(), openPostUploadDialog: false})
        );
        this._store.dispatch(new RouterGoAction({path: ["mobile/upload"]}));
    }

    //endregion
    //region Private

    /**
     * Преобразует информацию о клиентов в список его папок.
     *
     * @param userInfo Информация о пользователе.
     */
    private readonly userInfoToSpaces = (userInfo: CurrentUserInfo): Space[] =>

        userInfo.spaces
            .map(
                (space: Space) => ({
                    ...space,
                    balance: (space.balance + (space.useClientBalance ? userInfo.client.balance : 0))
                })
            )

    //endregion
}
