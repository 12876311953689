var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { Actions } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { withLatestFrom } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { map } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { PermissionItem } from "src/app/common/models/index";
import { DlgService } from "src/app/common/services/dlg.service";
import { currentUserInfoSelector } from "src/app/root/store/selectors/current-user.selector";
import { currentUserSpacesSelector } from "src/app/root/store/selectors/current-user.selector";
import { RouterActionType } from "../actions"; /* circular dependency break */
import { RouterGoAction } from "../actions"; /* circular dependency break */
/**
 * Side-эффекты на события, связанные с изменением URL'а.
 */
var RouterEffects = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор Side-эффектов на события, связанные с изменением URL'а.
     * @param _actions$ Экшены.
     * @param _router Сервис для работы с навигацией по приложению.
     * @param _location Сервис для работы с URL.
     * @param _store Сервис для доступа и управления состоянием приложения.
     * @param dlgService Сервис для создания диалоговых окон.
     * @param translateService Сервис для работы с i18n-сообщениями.
     *
     */
    function RouterEffects(_actions$, _router, _location, _store, dlgService, translateService) {
        var _this = this;
        this._actions$ = _actions$;
        this._router = _router;
        this._location = _location;
        this._store = _store;
        //endregion
        //region Public
        /**
         * Обработка события перехода по заданному URL'у.
         */
        this.navigate$ = this._actions$
            .pipe(ofType(RouterActionType.GO), map(function (action) { return action.payload; }), tap(function (_a) {
            var path = _a.path, queryParams = _a.query, extras = _a.extras;
            _this._router.navigate(path, __assign({ queryParams: queryParams }, extras));
        }));
        /**
         * Обработка события перехода по заданному URL'у.
         *
         * В случае отсутствия у текущего пользователя спейс с заданным в URL ID перенаправляет на '/spaces'.
         */
        this.redirectToSpaces$ = this._actions$.pipe(ofType(RouterActionType.GO), map(function (action) { return action.payload; }), map(function (payload) { return payload.path.join("/"); }), map(function (path) { return decodeURI(path); }), filter(function (path) { return RouterEffects.DOCUMENT_REGISTRY_URL_REGEX.test(path); }), map(function (path) { return path.match(RouterEffects.DOCUMENT_REGISTRY_URL_REGEX); }), map(function (regexMatcher) { return regexMatcher[1]; }), withLatestFrom(this._store.select(currentUserSpacesSelector)), filter(function (_a) {
            var spaceId = _a[0], spaces = _a[1];
            return spaces.every(function (space) { return space.id !== spaceId; });
        }), map(function (_a) {
            var spaceId = _a[0], spaces = _a[1];
            return spaceId;
        }), withLatestFrom(this._store.select(currentUserInfoSelector)), map(function (_a) {
            var spaceId = _a[0], currentUser = _a[1];
            return currentUser.permissions;
        }), filter(function (permissions) { return !permissions.includes(PermissionItem.SEE_ALL_DOCUMENTS.value); }), map(function () { return new RouterGoAction({ path: ["/spaces"] }); }));
        /**
         * Обработка события возврата назад по истории URL'ов.
         */
        this.navigateBack$ = this._actions$
            .pipe(ofType(RouterActionType.BACK), tap(function () { return _this._location.back(); }));
        /**
         * Обработка события перехода вперёд по истории URL'ов.
         */
        this.navigateForward$ = this._actions$
            .pipe(ofType(RouterActionType.FORWARD), tap(function () { return _this._location.forward(); }));
        this._dlgService = dlgService;
        this._translateService = translateService;
    }
    //region Constants
    /**
     * Regex-выражение для проверки, является ли URL ссылкой на реестр документов.
     */
    RouterEffects.DOCUMENT_REGISTRY_URL_REGEX = /spaces\/(.+)\/documents/;
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], RouterEffects.prototype, "navigate$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], RouterEffects.prototype, "redirectToSpaces$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], RouterEffects.prototype, "navigateBack$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], RouterEffects.prototype, "navigateForward$", void 0);
    return RouterEffects;
}());
export { RouterEffects };
