var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ofType } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { withLatestFrom } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { mergeMap } from "rxjs/operators";
import { map } from "rxjs/operators";
import { moveDocumentsDlgActions } from "src/app/common/store/actions/move-documents-dlg.action";
import { MoveDocumentsDlgActionType } from "src/app/common/store/actions/move-documents-dlg.action";
import { MovedDocumentsDeleteAction } from "src/app/spaces/modules/documents-registry/store/actions/documents.action";
import { DocumentsMassActionsCleanStateAction } from "src/app/spaces/modules/documents-registry/store/actions/documents.action";
import { newDocumentsSelector } from "src/app/spaces/modules/documents-registry/store/selectors/documents.selector";
import { documentsSelector } from "src/app/spaces/modules/documents-registry/store/selectors/documents.selector";
import { DocumentsService } from "../../services";
import { UserService } from "../../services";
/**
 * Side-эффекты на события, связанные с диалогом перемещения документа.
 */
var MoveDocumentsDlgEffect = /** @class */ (function () {
    //region Ctor
    /**
     * Контруктор эффектов связанных с диалогом перемещения документа.
     *
     * @param _actions$ Поток событий, происходящих в системе.
     * @param _userService Сервис работы с данными пользователя.
     * @param _store Хранилище.
     * @param _documentsService Сервис по работе с документами.
     */
    function MoveDocumentsDlgEffect(_actions$, _userService, _store, _documentsService) {
        var _this = this;
        this._actions$ = _actions$;
        this._userService = _userService;
        this._store = _store;
        this._documentsService = _documentsService;
        //endregion
        //region Public
        /**
         * Перемещение документов в другую папку.
         */
        this.moveDocumentsToAnotherSpace$ = this._actions$.pipe(ofType(MoveDocumentsDlgActionType.MOVE_TO_ANOTHER_SPACE), mergeMap(function (props) {
            return _this._documentsService.moveToAnotherSpace(props)
                .pipe(withLatestFrom(_this._store.select(documentsSelector), _this._store.select(newDocumentsSelector)), map(function (_a) {
                var movedDocumentIdList = _a[0], documents = _a[1], newDocuments = _a[2];
                return documents.concat(newDocuments).filter(function (document) { return movedDocumentIdList.includes(document.id); });
            }), map(function (documents) { return moveDocumentsDlgActions.moveDocumentsToAnotherSpaceSuccess(__assign({}, props, { documents: documents })); }), catchError(function (apiResponse) {
                return of(moveDocumentsDlgActions.moveDocumentsToAnotherSpaceFail({ error: apiResponse }));
            }));
        }));
        /**
         * Успешное перемещение документов в другую папку.
         */
        this.moveDocumentsToAnotherSpaceSuccess$ = this._actions$.pipe(ofType(MoveDocumentsDlgActionType.MOVE_TO_ANOTHER_SPACE_SUCCESS), map(function (props) { return new MovedDocumentsDeleteAction(props); }));
        /**
         * Обработка события очистки стейта массовых действий документов после перемещения документов в другую папку.
         */
        this.cleanStateAfterMassActions$ = this._actions$
            .pipe(ofType(MoveDocumentsDlgActionType.MOVE_TO_ANOTHER_SPACE_SUCCESS), map(function (_) { return new DocumentsMassActionsCleanStateAction(); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], MoveDocumentsDlgEffect.prototype, "moveDocumentsToAnotherSpace$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], MoveDocumentsDlgEffect.prototype, "moveDocumentsToAnotherSpaceSuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], MoveDocumentsDlgEffect.prototype, "cleanStateAfterMassActions$", void 0);
    return MoveDocumentsDlgEffect;
}());
export { MoveDocumentsDlgEffect };
