var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SendToMatchingActionType } from "src/app/spaces/modules/documents-registry/store/actions/send-to-matching.action";
/**
 *  Начальное состояние для отправки документов на сопоставление.
 */
export var initialState = {
    /**
     * Документы отправляются на сопоставление.
     */
    sending: false,
    /**
     * Документы отправлены на сопоставление.
     */
    sent: false,
    /**
     * При отправке документов на сопоставление произошла ошибка.
     */
    error: null,
    /**
     * Сопоставления документов.
     */
    documentMatches: null,
    /**
     * ID интеграции для которой происходит сопоставление.
     */
    integrationId: null,
};
/**
 * Обработчик событий связанный с отправкой документов на сопоставление.
 *
 * @param state Состояние отправки документов.
 * @param action Событие произошедшее в системе.
 */
export function sendToMatchingReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        // Сохранение документов, полученных с сервера в сторе.
        case SendToMatchingActionType.ACCEPT: {
            result = __assign({}, state, { sending: true, sent: false, documentMatches: action.matches, integrationId: action.integrationId });
            break;
        }
        // Уведомление об успешной отправке документов в модуль сопоставления.
        case SendToMatchingActionType.SEND_SUCCESS: {
            result = __assign({}, state, { sending: false, sent: true, documentMatches: null, integrationId: null });
            break;
        }
        // Уведомление об ошибке при отправке документов в модуль сопоставления.
        case SendToMatchingActionType.SEND_FAIL: {
            result = __assign({}, state, { sending: false, sent: false, error: action.error, documentMatches: null, integrationId: null });
            break;
        }
    }
    return result;
}
