<div class="two-inputs-dlg">
    <h2 class="mat-h2 two-inputs-dlg__header">{{ headerKey | translate:headerParams }}</h2>
    <hr class="two-inputs-dlg__divider" />
    <mat-form-field class="two-inputs-dlg__input">
        <textarea
            rows="4"
            *ngIf="!!firstInputData.multiline"
            [(ngModel)]="firstInputValue"
            [required]="firstInputData.required"
            matInput
            placeholder="{{firstInputData.textKey | translate}}"
        >

        </textarea>
        <input
            *ngIf="!firstInputData.multiline"
            [(ngModel)]="firstInputValue"
            [required]="firstInputData.required"
            matInput
            type="{{firstInputData.inputType}}"
            placeholder="{{firstInputData.textKey | translate}}"
        >
        <mat-error *ngIf="firstInputData.required && !firstInputValue">
            {{ "dialogs.validations.required" | translate }}
        </mat-error>
    </mat-form-field>
    <mat-form-field class="two-inputs-dlg__input">
        <textarea
            rows="4"
            *ngIf="!!secondInputData.multiline"
            [(ngModel)]="secondInputValue"
            [required]="secondInputData.required"
            matInput
            placeholder="{{secondInputData.textKey | translate}}"
        >

        </textarea>
        <input
            *ngIf="!secondInputData.multiline"
            [(ngModel)]="secondInputValue"
            [required]="secondInputData.required"
            matInput
            type="{{secondInputData.inputType}}"
            placeholder="{{secondInputData.textKey | translate}}"
        >
        <mat-error *ngIf="secondInputData.required && !secondInputValue">
            {{ "dialogs.validations.required" | translate }}
        </mat-error>
    </mat-form-field>
    <div class="two-inputs-dlg__buttons">
        <button
            class="two-inputs-dlg__button"
            mat-button
            (click)="closeBtnHandler()"
        >
            {{ 'dialogs.close' | translate }}
        </button>
        <button
            class="two-inputs-dlg__button"
            mat-raised-button
            color="primary"
            [disabled]="firstInputData.required && !firstInputValue || secondInputData.required && !secondInputValue"
            (click)="confirmBtnHandler()"

        >
            {{ 'dialogs.confirm' | translate }}
        </button>
    </div>

</div>
