import { HttpClient } from "@angular/common/http";
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Store } from "@ngrx/store";
import { select } from "@ngrx/store";

import { Observable } from "rxjs";
import { of } from "rxjs";
import { throwError } from "rxjs";
import { switchMap } from "rxjs/operators";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { ConfigurationService } from "src/app/common/services/configuration.service";
import { AppEnvironment } from "src/environments/app-enviroment.model";

import { environment } from "../../../environments/environment";
import { RootState } from "../../root/store/reducers"; /* circular dependency break */
import { RouteState } from "../../root/store/reducers/router.reducer";
import { routeStateSelector } from "../../root/store/selectors"; /* circular dependency break */
import { ApiResponse } from "../models";
import { CurrentUserInfo } from "../models";
import { User } from "../models";
import { CurrentUserResponse } from "../models";
import { LoginCheckResponse } from "../models";
import { RegistrationResponse } from "../models";
import { RegistrationFormData } from "../models";

/**
 * Сервис с логикой для работы с пользователем.
 */
@Injectable({
    providedIn: 'root'
})
export class UserService {
    //region Constants

    /**
     * URL API метода установки флага необходимости показывать диалог пользователю после каждой загрузки файлов на
     * распознавание.
     */
    private static readonly SET_FLAG_URL = "/api/v1/currentUser/showTaskCreatedDialog";

    //endregion
    //region Private

    /**
     * HTTP-клиент.
     *
     * @private
     */
    private readonly _http: HttpClient;

    /**
     * Состояние URL'а.
     *
     * @private
     */
    private _routeState: RouteState;

    /**
     * Сервис по работе с конфигурациями окружения приложения.
     */
    private readonly _configService: ConfigurationService;

    //endregion
    //region Ctor

    constructor(
        store: Store<RootState>,
        http: HttpClient,
        configService: ConfigurationService,
    ) {

        this._http = http;
        this._configService = configService;

        // Следим за состоянием URL'а.
        store
            .pipe(
                select(routeStateSelector)
            )
            .subscribe((routeState: RouteState) => this._routeState = routeState);
    }

    //endregion
    //region Public

    /**
     * Получение данных о текущем пользователе.
     */
    getCurrentUser(): Observable<CurrentUserInfo> {

        return this._http
            .get<CurrentUserResponse>('/api/v1/currentUser')
            .pipe(
                map(response => response.user),
                catchError(
                    (response: HttpErrorResponse): Observable<CurrentUserInfo> => {

                        // 401 Unauthorized ошибка воспринимается нормально, как отсутствие пользователя (user == null).
                        // Другие ошибки пробрасываются дальше.
                        if (response.status !== 401) {

                            return throwError(response.error as ApiResponse);
                        }

                        return of(null as CurrentUserInfo);
                    }
                )
            );
    }

    /**
     * Заданный логин доступен в системе?
     *
     * @param login Логин для проверки.
     *
     * @return Да/Нет.
     */
    isLoginAvailable(login: string = ''): Observable<boolean> {

        login = login.replace(/\+/g, '%2B');
        const url = environment.authServerUrl + `/api/v1/login/check?login=${login}`;
        return this._http
            .get<LoginCheckResponse>(url)
            .pipe(
                map((response: LoginCheckResponse): boolean => !response.occupied),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse)),
            );
    }

    /**
     * Регистрация пользователя по заданным данным.
     *
     * @param formData Данные с формы регистрации.
     *
     * @return Данные зарегистрированного пользователя.
     */
    registration(formData: RegistrationFormData): Observable<User | ApiResponse> {

        return this._configService.loadConfigurations().pipe(
            map((env: AppEnvironment) => env.global),
            map((global: boolean) => global ? "en" : "ru"),
            switchMap(
                (lang: string) => this._http
                    .post<RegistrationResponse>(
                        "/api/v1/registration",
                        {
                            login: formData.login,
                            name: formData.userName,
                            inn: formData.inn,
                            password: formData.password,
                            phone: formData.phone,
                            promoCode: formData.promoCode,
                            from: environment.appServerUrl,
                            lang,
                        },
                        {
                            params: { ...this._routeState.queryParams }
                        }
                )
            ),
                map((response: RegistrationResponse): User => response.user),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse)),
        );
    }

    /**
     * Выполнение выхода из системы.
     *
     * @return Успех выполнения операции.
     */
    logout(): Observable<boolean> {

        return this._http
            .post(`${environment.authServerUrl}/api/v1/logout`, null, { withCredentials: true })
            .pipe(
                map(() => true),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse)),
            );
    }

    /**
     * Возвращает полное имя пользователя.
     *
     * @param user Пользователь.
     */
    getFullUserName(user: User): string {

        let userName = '';

        if (user) {

            if (user.surname) {

                userName += user.surname;
            }

            if (user.name) {

                userName += ' ' + user.name;

                if (user.patronymic) {

                    userName += ' ' + user.patronymic;
                }
            }

            if (!userName) {

                userName = user.email;
            }
        }

        return userName;
    }

    /**
     * Возвращает имя пользователя с сокращённым именем и отчеством.
     *
     * @param user Пользователь.
     */
    getUserName(user: User): string {

        let userName = '';

        if (user) {

            if (user.surname) {

                userName += user.surname;
            }

            if (user.name) {

                if (user.surname) {

                    userName += ' ' + user.name.substr(0, 1).toUpperCase() + '.';
                }

                else {

                    userName += user.name;
                }

                if (user.patronymic) {

                    userName += ' ' + user.patronymic.substr(0, 1).toUpperCase() + '.';
                }
            }

            if (!userName) {

                userName = user.email;
            }
        }

        return userName;
    }

    /**
     * Отпарвка СМС с кодом подтверждения телефона на указанный номер.
     *
     * @param phone Телефон для отправки СМС.
     */
    sendConfirmSms(phone: string): Observable<ApiResponse> {

        phone = phone.replace(/\+/, '');

        return this._http
            .post<ApiResponse>(
                environment.authServerUrl + "/api/v1/phone/send",
                "",
                { params: { phone }, withCredentials: true },
            )
            .pipe(
                catchError((response: HttpErrorResponse): Observable<ApiResponse> => {
                    return throwError(response.error as ApiResponse);
                })
            );
    }

    /**
     * Подтверждение телефона кодом из СМС.
     *
     * @param code Код подтверждения телефона.
     */
    confirmPhoneByCode(code: string): Observable<ApiResponse> {

        return this._http
            .post<ApiResponse>(
                environment.authServerUrl + "/api/v1/phone/confirm",
                "",
                { params: {code}, withCredentials: true }
            )
            .pipe(
                catchError((response: HttpErrorResponse): Observable<ApiResponse> =>
                    throwError(response.error as ApiResponse)
                )
            );
    }

    /**
     * Выполняет установку флага необходимости показывать диалог пользователю после каждой загрузки файлов на
     * распознавание.
     *
     * @param value Значение флага для установки.
     */
    setShowTaskCreatedDialogFlag(value: boolean): Observable<boolean> {

        return this._http
            .put<ApiResponse>(UserService.SET_FLAG_URL, { "value" : value })
            .pipe(
                map((response: ApiResponse): boolean => response.result),
                catchError((response: HttpErrorResponse): Observable<never> =>
                    throwError(response.error as ApiResponse)
                ),
            );
    }

    /**
     * Отправляет заявку на покупку страниц.
     */
    sendPurchaseRequest(phoneNumber: string): Observable<boolean> {

        return this._http
            .post("/api/v1/buy", {phoneNumber: phoneNumber})
            .pipe(
                map((response: ApiResponse): boolean => response.result),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse)),
            );
    }

    //endregion
}
