import { createAction } from "@ngrx/store";
import { props } from "@ngrx/store";
import { ValueProps } from "src/app/root/store/actions/props/value-props";
import { ExternalCompany } from "src/app/spaces/modules/document-matching/models/external-company";

/**
 * Типы событий, связанных с редактированием элементов для внешней системы.
 */
export enum PendingExternalElementsActionType {
    ADD_SUPPLIER = "[Pending external element] Add pending external supplier",
    ADD_CUSTOMER = "[Pending external element] Add pending external customer",
    UPDATE_SUPPLIERS = "[Pending external element] Update external supplier list",
    UPDATE_CUSTOMERS = "[Pending external element] Update external customer list",
    CLEAR = "[Pending external element] Clear state",
}

/**
 * Событие обновления коллекции поставщиков.
 */
const updateSuppliers = createAction(
    PendingExternalElementsActionType.UPDATE_SUPPLIERS,
    props<ValueProps<ExternalCompany[]>>()
);

/**
 * Событие обновления коллекции покупателей.
 */
const updateCustomers = createAction(
    PendingExternalElementsActionType.UPDATE_CUSTOMERS,
    props<ValueProps<ExternalCompany[]>>()
);

/**
 * Событие добавления поставщика.
 */
const addSupplier = createAction(
    PendingExternalElementsActionType.ADD_SUPPLIER,
    props<ValueProps<ExternalCompany>>()
);

/**
 * Событие добавления покупателя.
 */
const addCustomer = createAction(
    PendingExternalElementsActionType.ADD_CUSTOMER,
    props<ValueProps<ExternalCompany>>()
);

/**
 * Событие очистки всех коллекций.
 */
const clear = createAction(PendingExternalElementsActionType.CLEAR);

/**
 * Тип, объединяющий все события, связанные с редактированием элементов для внешней системы.
 */
export type PendingExternalElementsAction =
    | ReturnType<typeof addSupplier>
    | ReturnType<typeof addCustomer>
    | ReturnType<typeof updateSuppliers>
    | ReturnType<typeof updateCustomers>
    | ReturnType<typeof clear>
    ;

/**
 * Все события, связанные с редактированием элементов для внешней системы.
 */
export const pendingExternalElementsActions = {
    addSupplier: addSupplier,
    addCustomer: addCustomer,
    updateSuppliers: updateSuppliers,
    updateCustomers: updateCustomers,
    clear: clear,
};
