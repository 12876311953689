var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { filter } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { map } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { NewDocumentsCountIncrementAction } from "../../actions";
import { RecognitionTasksLoadFailAction } from "../../actions";
import { RecognitionTasksLoadSuccessAction } from "../../actions";
import { UploadToRecognizeActionType } from "../../actions";
import { AddRecognitionTaskAction } from "../../actions";
import { RecognitionTaskChangeAction } from "../../actions";
import { RecognitionTaskFinishedAction } from "../../actions";
import { RecognitionTasksActionType } from "../../actions";
import { RecognitionTaskErrorAction } from "../../actions";
import { RecognitionState } from "src/app/common/models/recognition-task";
import { RecognitionTaskService } from "src/app/common/services/recognition-task.service";
import { withLatestFrom } from "rxjs/operators";
import { recognizedDocumentsSelector } from "../../selectors";
import { select } from "@ngrx/store";
import { AddRecognizedDocumentsAction } from "../../actions";
import { LoadInitialDocumentsSuccessAction } from "../../actions";
import { recognitionTasksStateSelector } from "../../reducers";
import { initialDocumentsSelector } from "../../selectors";
/**
 * Side-эффекты на события, связанные задачами на распознавание.
 */
var RecognitionTasksEffects = /** @class */ (function () {
    //endregion
    //region Ctor
    function RecognitionTasksEffects(actions$, store, recognitionTaskService) {
        var _this = this;
        this.actions$ = actions$;
        this.store = store;
        this.recognitionTaskService = recognitionTaskService;
        //region Fields
        /**
         * Частота запросов на состояние выполняющихся задач на распознавание.
         */
        this.POLLING_FREQ_MS = 5 * 1000;
        /**
         * Хранилище индетификаторов задач поллинга.
         */
        this.pollingMap = {};
        //endregion
        //region Public
        /**
         * Обработка события требования загрузки выполняющихся задач на распознавание.
         */
        this.loadRunningTasks$ = this.actions$
            .pipe(ofType(RecognitionTasksActionType.LOAD), map(function (action) { return action.payload; }), switchMap(function (space) {
            return _this.recognitionTaskService.getRunningTasks(space.id)
                .pipe(map(function (tasks) { return new RecognitionTasksLoadSuccessAction(tasks); }), catchError(function (response) { return of(new RecognitionTasksLoadFailAction(response)); }));
        }));
        /**
         * Обработка события удачного создания задачи на распознавание.
         * Добавляет созданную задачу в список задач.
         */
        this.add$ = this.actions$
            .pipe(ofType(UploadToRecognizeActionType.UPLOAD_SUCCESS), map(function (action) { return new AddRecognitionTaskAction(action.payload); }));
        /**
         * Обработка события успешной загрузки выполняющихся задач на распознавание.
         */
        this.pollingMany$ = this.actions$
            .pipe(ofType(RecognitionTasksActionType.LOAD_SUCCESS, RecognitionTasksActionType.ADD_TASK), map(function (action) { return action.payload; }), tap(function (tasks) {
            tasks.forEach(function (task) {
                var _a;
                _this.pollingMap = __assign({}, _this.pollingMap, (_a = {}, _a[task.id] = _this.makePolling(task), _a));
            });
        }));
        /**
         * Обработка события изменения статуса задачи.
         * Перемещает задачу из списка tasks в поле lastRecognized, если все прошло хорошо, или в поле lastError, если
         * распознавание завершилось ошибкой.
         */
        this.change$ = this.actions$
            .pipe(ofType(RecognitionTasksActionType.CHANGE_TASK_STATUS), filter(function (action) {
            return action.payload.state === RecognitionState.RECOGNIZED
                || action.payload.state === RecognitionState.ERROR;
        }), tap(function (action) { return clearInterval(_this.pollingMap[action.payload.id]); }), map(function (action) {
            if (action.payload.state === RecognitionState.RECOGNIZED) {
                return new RecognitionTaskFinishedAction(action.payload);
            }
            else {
                return new RecognitionTaskErrorAction(action.payload);
            }
        }));
        /**
         * Добавление новых распознанных документов.
         */
        this.addRecognizedDocuments$ = this.actions$
            .pipe(ofType(RecognitionTasksActionType.CHANGE_TASK_STATUS), filter(function (action) { return action.payload.state !== RecognitionState.ERROR; }), withLatestFrom(this.store.pipe(select(recognizedDocumentsSelector)), this.store.pipe(select(initialDocumentsSelector))), map(function (_a) {
            var action = _a[0], recognized = _a[1], initial = _a[2];
            var existIds = recognized.concat.apply(recognized, initial).map(function (d) { return d.id; });
            return (action.payload.documents || []).filter(function (document) { return !existIds.includes(document.id); });
        }), map(function (docs) { return docs.filter(function (doc) { return doc.state == "RECOGNIZED"; }); }), filter(function (docs) { return docs.length > 0; }), map(function (newDocuments) { return new AddRecognizedDocumentsAction(newDocuments); }));
        /**
         * Изменение счетчика распознанных документов для новых распознанных документов.
         */
        this.increaseNewDocumentCount$ = this.actions$
            .pipe(ofType(RecognitionTasksActionType.ADD_RECOGNIZED_DOCUMENTS), map(function (action) { return new NewDocumentsCountIncrementAction(action.payload.length); }));
        /**
         * Обработка события остановки всех перидических опросов состояния задач.
         */
        this.clearPolling$ = this.actions$
            .pipe(ofType(RecognitionTasksActionType.CLEAR), tap(function () {
            Object.keys(_this.pollingMap)
                .forEach(function (taskId) { return clearInterval(_this.pollingMap[taskId]); });
            _this.pollingMap = {};
        }));
        /**
         * Запрос обновления распознанных документов.
         */
        this.updateRecognizedDocuments = this.actions$
            .pipe(ofType(RecognitionTasksActionType.LOAD_INITIAL_DOCUMENTS), map(function (action) { return action.payload; }), switchMap(function (space) {
            return _this.recognitionTaskService.getRunningTasks(space.id)
                .pipe(map(_this.mapToDocs), map(function (docs) { return new LoadInitialDocumentsSuccessAction(docs); }), catchError(function (response) { return of(new RecognitionTasksLoadFailAction(response)); }));
        }));
        //endregion
        //region Private
        /**
         * Функция, запускающая периодический опрос сервера о состоянии задачи на распознавание.
         * Ответ учитывается только если статус задач на распознованеи загружен.
         *
         * @param task Задача на распознавания, для котрой запускается опрос.
         */
        this.makePolling = function (task) {
            return setInterval(function () {
                _this.recognitionTaskService.getTask(task.id)
                    .pipe(withLatestFrom(_this.store.pipe(select(recognitionTasksStateSelector))), filter(function (_a) {
                    var response = _a[0], state = _a[1];
                    return state.loaded && state.initialLoaded;
                }), map(function (_a) {
                    var response = _a[0], _ = _a[1];
                    return _this._mergeDocumentSpaceDataWithTaskSpace(response.recognitionTask);
                }))
                    .subscribe(function (response) { return _this.store.dispatch(new RecognitionTaskChangeAction(response)); });
            }, _this.POLLING_FREQ_MS);
        };
        /**
         * Отображенеи списка задача, в список распознанных документов.
         *
         * @param tasks Задачи для отображения.
         */
        this.mapToDocs = function (tasks) {
            return tasks.map(function (task) { return task.documents || []; })
                .map(function (docs) { return docs; })
                .reduce(function (acc, docs) { return acc.concat(docs); }, [])
                .filter(function (doc) { return doc.state == "RECOGNIZED"; });
        };
    }
    /**
     * "Примешивает" данные папки из распознавания в папки документов, которые относятся к распознаванию. Это нужно
     * для того, чтобы при распознавании нового документа, он попал в реестр с наименованием папки для админа.
     * //TODO Сделать передачу наименования папки на документах таски на front-е, чтобы не пришлось тут примешивать
     *
     * @param recognitionTask Задача на распознавание.
     *
     * @return Задача на распознавание.
     */
    RecognitionTasksEffects.prototype._mergeDocumentSpaceDataWithTaskSpace = function (recognitionTask) {
        if (!recognitionTask) {
            return recognitionTask;
        }
        return __assign({}, recognitionTask, { documents: (recognitionTask.documents || []).map(function (taskDocument) { return (__assign({}, taskDocument, { space: __assign({}, taskDocument.space, recognitionTask.space) })); }) });
    };
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], RecognitionTasksEffects.prototype, "loadRunningTasks$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], RecognitionTasksEffects.prototype, "add$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], RecognitionTasksEffects.prototype, "pollingMany$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], RecognitionTasksEffects.prototype, "change$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], RecognitionTasksEffects.prototype, "addRecognizedDocuments$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], RecognitionTasksEffects.prototype, "increaseNewDocumentCount$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], RecognitionTasksEffects.prototype, "clearPolling$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], RecognitionTasksEffects.prototype, "updateRecognizedDocuments", void 0);
    return RecognitionTasksEffects;
}());
export { RecognitionTasksEffects };
