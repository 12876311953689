<div class="entera-header">
    <div class="fluid-container">
        <div class="entera-header__logo entera-header__centered-container">
            <a (click)="logoClick()">
                <img src="/assets/images/logo_with_text.svg" alt="" />
            </a>
        </div>
        <button
            *ngIf="showHowToStartBtn"
            class="entera-header__item entera-header__how-to-start-btn"
            mat-button
            (click)="howToStartHandler()"
        >
            <mat-icon class="entera-header__buy-icon">rocket_launch</mat-icon>
            {{ 'header.howToStart' | translate }}
        </button>
        <button
            *ngIf="space"
            class="entera-header__item entera-header__buy-button"
            [class.entera-header__buy-button_trial]="!space.paid"
            mat-button
            (click)="buyPagesClick()"
        >
            <mat-icon class="entera-header__buy-icon">{{"header.buy.icon" | translate}}</mat-icon>
            {{ 'header.buy.pages' | translate }}
        </button>
        <div *ngIf="space" class="entera-header__item entera-header__centered-container">
            <a class="entera-header__integration" (click)="integrationClick()">
                <mat-icon class="entera-header__integration-icon" fontSet="material-icons-custom-set">
                    archive_outline
                </mat-icon>
                {{ "header.1c" | translate }}
            </a>
        </div>
        <have-questions-menu
            *ngIf="!!user"
            class="entera-header__have-questions entera-header__item"
            (callBack)="callBackHandler()"
            (knowledgeBase)="knowledgeBaseHandler()"
            (howToStart)="howToStartHandler()"
        >
        </have-questions-menu>
        <balance-info-menu *ngIf="space"
            class="entera-header__balance-info entera-header__item"
            [restCreditCount]="creditCount"
            [expirationDate]="user?.client.balanceExpirationDate"
            [foreign]="user.client.foreign"
            [showUserNotification]="showUserNotification"
            [expiryNotificationType]="expiryNotificationType"
            (buy)="buyHandler()"
        >
        </balance-info-menu>
        <spaces-info-menu *ngIf="user && showSpacesInfo"
            class="entera-header__spaces-info entera-header__item"
            [path]="path"
            [space]="space"
            [spaces]="user.spaces"
            [canInviteInAnySpace]="canInviteInAnySpace"
            [canCreateSpace]="canCreateSpace$ | async"
            (createSpace)="createSpaceHandler()"
            (openSpace)="openSpaceHandler($event)"
            (spaceSettings)="spaceSettingsHandler($event)"
            (openInvitationDialog)="openInvitationDialogHandler()"
            (openErrorDialog)="openErrorDialogHandler()"
        >
        </spaces-info-menu>
        <user-info-menu *ngIf="user"
            class="entera-header__user-info entera-header__item"
            [user]="user"
            (userAccount)="userAccountHandler()"
            (logout)="logoutHandler()"
        >
        </user-info-menu>
    </div>
</div>
