import { HttpClient } from "@angular/common/http";
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { throwError } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { ApiResponse } from "../models";
import { PermissionItem } from "../models";
import { HasPermissionResponse } from "../models/api-responses/has-permission-response";

/**
 * Сервис для работы с правами доступа.
 */
@Injectable({ 
    providedIn: "root" 
})
export class PermissionService {
    //region Ctor

    constructor(
        private http: HttpClient
    ) { }

    //endregion
    //region Public

    /**
     * Пользователь имеет заданное право?
     * 
     * @param permission Право.
     * @param spaceId id спейса.
     */
    hasPermission(permission: PermissionItem, spaceId?: string): Observable<boolean> {

        let params: any = {
            permission: permission.value,
        };

        if (spaceId) {
           params = {
               ...params,
               spaceId
           }
        }
        return this.http
            .get<HasPermissionResponse>('/api/v1/currentUser/hasPermission', {
                params: params
            })
            .pipe(
                map(response => response.hasPermission),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse))
            );
    }

    //endregion
}
