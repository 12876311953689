var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * Состояние отображения колонок таблицы реестра документов.
 */
import { environment } from "src/environments/environment";
/**
 * Массив колонок в таблице регистра документов.
 */
export var documentRegistryColumns = [
    "documentState",
    "files",
    "customer",
    "supplier",
    "customerInn",
    "supplierInn",
    "documentType",
    "documentNumber",
    "documentDate",
    "taxRate",
    "documentAmount",
    "createdDate",
    "space",
    "creator",
    "comment",
    "info",
    "source",
    "uploadState",
    "externalDocument",
];
/**
 * На основе данных о состоянии видимости столбцов таблицы создаёт экземпляр класса ColumnVisibilityState.
 *
 * @param state Данные состояния видимости столбцов.
 */
export function documentRegistryColumnsVisibilityStateFrom(state) {
    return __assign({}, getDefaultColumnsVisibilityState(), state);
}
/**
 * Состояния видимости столбцов совпадают?
 *
 * @param state Состояние видимости столбцов.
 * @param anotherState Другое состояние видимости столбцов.
 */
export function areDocumentRegistryColumnsVisibilityStatesSame(state, anotherState) {
    return state
        && anotherState
        && documentRegistryColumns.every(function (column) { return state[column] === anotherState[column]; });
}
/**
 * Возвращает состояние отображения колонок по умолчанию.
 */
export function getDefaultColumnsVisibilityState() {
    return {
        files: false,
        customer: true,
        supplier: true,
        customerInn: false,
        supplierInn: false,
        documentType: true,
        documentNumber: true,
        documentDate: true,
        taxRate: false,
        documentAmount: false,
        createdDate: true,
        creator: false,
        space: false,
        comment: false,
        info: false,
        source: true,
        uploadState: true,
        externalDocument: !!environment.global,
    };
}
/**
 * Возвращает состояние отображения колонок на странице распознаваемых документов по умолчанию.
 */
export function getDefaultRecognizingColumnsVisibilityState() {
    return {
        files: true,
        customer: true,
        supplier: true,
        customerInn: false,
        supplierInn: false,
        documentType: false,
        documentNumber: false,
        documentDate: false,
        taxRate: false,
        documentAmount: false,
        createdDate: false,
        comment: false,
        creator: true,
        space: false,
        info: true,
        source: true,
        uploadState: false,
        externalDocument: false,
    };
}
