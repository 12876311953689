import { SendToMatchingActionType } from "src/app/spaces/modules/documents-registry/store/actions/send-to-matching.action";
import { SendToMatchingAction } from "src/app/spaces/modules/documents-registry/store/actions/send-to-matching.action";
import { SendToMatchingState } from "src/app/spaces/modules/documents-registry/store/states/send-to-matching.state";


/**
 *  Начальное состояние для отправки документов на сопоставление.
 */
export const initialState: SendToMatchingState = {

    /**
     * Документы отправляются на сопоставление.
     */
    sending: false,

    /**
     * Документы отправлены на сопоставление.
     */
    sent: false,

    /**
     * При отправке документов на сопоставление произошла ошибка.
     */
    error: null,

    /**
     * Сопоставления документов.
     */
    documentMatches: null,

    /**
     * ID интеграции для которой происходит сопоставление.
     */
    integrationId: null,
};

/**
 * Обработчик событий связанный с отправкой документов на сопоставление.
 *
 * @param state Состояние отправки документов.
 * @param action Событие произошедшее в системе.
 */
export function sendToMatchingReducer(state = initialState, action: SendToMatchingAction): SendToMatchingState {

    let result = state;

    switch (action.type) {

        // Сохранение документов, полученных с сервера в сторе.
        case SendToMatchingActionType.ACCEPT: {

            result = {
                ...state,
                sending: true,
                sent: false,
                documentMatches: action.matches,
                integrationId: action.integrationId,
            }
            break;
        }

        // Уведомление об успешной отправке документов в модуль сопоставления.
        case SendToMatchingActionType.SEND_SUCCESS: {

            result = {
                ...state,
                sending: false,
                sent: true,
                documentMatches: null,
                integrationId: null,
            };
            break;
        }

        // Уведомление об ошибке при отправке документов в модуль сопоставления.
        case SendToMatchingActionType.SEND_FAIL: {

            result = {
                ...state,
                sending: false,
                sent: false,
                error: action.error,
                documentMatches: null,
                integrationId: null,
            };
            break;
        }
    }

    return result;
}
