import { createAction } from "@ngrx/store";
import { props } from "@ngrx/store";
import { MatchingCompaniesGetProps } from "src/app/spaces/modules/document-matching/store/actions/props/matching-companies-get.action.props";
import { MatchingCompaniesSuccessProps } from "src/app/spaces/modules/document-matching/store/actions/props/matching-companies-success.action.props";
import { ErrorResponseProps } from "src/app/root/store/actions/props/error-response.action-props";
import { CompanyType } from "src/app/spaces/modules/document-matching/models/company-type";

/**
 * Типы событий, связанных с сопоставлением компаний.
 */
export enum MatchingCompaniesActionType {
    GET = "[Matching companies] Get",
    SUCCESS = "[Matching companies] Success",
    FAIL = "[Matching companies] Fail",
}

/**
 * Событие получения списка возможных внешних компаний.
 */
const get = createAction(MatchingCompaniesActionType.GET, props<MatchingCompaniesGetProps>());

/**
 * Событие успешного получения списка возможных внешних компаний.
 */
const success = createAction(MatchingCompaniesActionType.SUCCESS, props<MatchingCompaniesSuccessProps>());

/**
 * Событие провала при получении списка возможных внешних компаний.
 */
const fail = createAction(MatchingCompaniesActionType.FAIL, props<ErrorResponseProps & { companyType: CompanyType }>());

/**
 * Тип, объединяющий события, связанные с сопоставлением компаний.
 */
export type MatchingCompaniesAction = ReturnType<typeof get>
    | ReturnType<typeof success>
    | ReturnType<typeof fail>;

/**
 * Объединение событий, связанных с сопоставлением компаний.
 */
export const matchingCompaniesActions = {
    get,
    success,
    fail: fail
};
