import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { Space } from "src/app/common/models/index";
import { ErrorResponseProps } from "src/app/root/store/actions/props/error-response.action-props";
import { ValueProps } from "src/app/root/store/actions/props/value-props";

/**
 * Типы событий, связанные с созданием пространств документов.
 */
export enum SpaceCreateActionType {
    OPEN_CREATE_SPACE_DIALOG = "[Space create] Open create space dialog",
    CREATE_NEW_SPACE = "[Space create] Create new space",
    CREATE_NEW_SPACE_SUCCESS = "[Space create] New space created successfully",
    CREATE_NEW_SPACE_FAIL = "[Space create] Error while creating new space",
}

/**
 * Событие, открывающее диалог для создания нового пространства документов.
 */
const openCreateSpaceDlg = createAction(SpaceCreateActionType.OPEN_CREATE_SPACE_DIALOG);

/**
 * Событие, требующей создать новое пространство документов.
 */
const createNewSpace = createAction(
    SpaceCreateActionType.CREATE_NEW_SPACE,
    props<ValueProps<string>>()
);

/**
 * Событие успешного создания нового пространства документов.
 */
const createNewSpaceSuccess = createAction(
    SpaceCreateActionType.CREATE_NEW_SPACE_SUCCESS,
    props<ValueProps<Space>>(),
);


/**
 * Событие ошибки при создании нового пространства документов.
 */
const createNewSpaceFail = createAction(
    SpaceCreateActionType.CREATE_NEW_SPACE_FAIL,
    props<ErrorResponseProps<void>>(),
);

/**
 * Тип, объединяющий все события, с созданием пространств документов.
 */
export type CreateSpaceAction =
    ReturnType<typeof openCreateSpaceDlg>
    | ReturnType<typeof createNewSpace>
    | ReturnType<typeof createNewSpaceSuccess>
    | ReturnType<typeof createNewSpaceFail>;

/**
 * Все события, связанные с созданием пространств документов.
 */
export const createSpaceActions = {
    openCreateSpaceDlg : openCreateSpaceDlg,
    createNewSpace: createNewSpace,
    createNewSpaceSuccess: createNewSpaceSuccess,
    createNewSpaceFail: createNewSpaceFail,
};
