import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { Input } from '@angular/core';
import { Inject } from '@angular/core';

import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { ApiResponse } from '../../models';

/**
 * Компонента с деталями произошедших ошибок на сервере. 
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'server-side-error-details',
    templateUrl: './server-side-error-details.component.html',
    styleUrls: ['./server-side-error-details.component.scss'],
})
export class ServerSideErrorDetailsComponent implements OnInit {
    //region Inputs

    /**
     * Входящие данные - произошедшие ошибки.
     */
    @Input()
    errors: ApiResponse[];

    //endregion
    //region Ctor

    constructor(
        public dialogRef: MatDialogRef<ServerSideErrorDetailsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private translate: TranslateService
    ) { 
        if (data && data.errors) {

            this.errors = data.errors;
        }
    }

    //endregion
    //region Hooks

    ngOnInit() { }

    //endregion
    //region Getters and Setters

    /**
     * Возвращает текст ошибки в зависимости от кол-ва произошедших ошибок.
     */
    get errorText$(): Observable<string> {

        if (this.errors.length > 1) {

            return this.translate.get('errorDetails.serverSideErrorsOccurred');
        }
        else {
            
            return this.translate.get('errorDetails.serverSideErrorOccurred');
        }
    }

    //endregion
    //region Events

    /**
     * Обработчик уведомления, что была нажата кнопка перехода на главную страницу.
     */
    goToRootHandler() {

        if (this.dialogRef) {

            this.dialogRef.close();
        }
    }

    //endregion
}
