import { OnInit } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { Input } from "@angular/core";
import { Output } from "@angular/core";
import { InfoMenuItem } from "src/app/common/models/info-menu-item";
import { Space } from "src/app/common/models/space";
import { UrlUtils } from "src/app/common/utils/url.utils";

/**
 * Компонент выпадающего меню пространств документов текущего пользователя.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "spaces-info-menu",
    styleUrls: ["spaces-info-menu.component.scss"],
    templateUrl: "spaces-info-menu.component.html"
})
export class SpacesInfoMenuComponent implements OnInit {
    //region Constants

    /**
     * ID элемента приглашения в меню выбора пространств документов.
     */
    private static readonly _INVITATION_MENU_ITEM_ID: string = "invitationItemId";

    /**
     * ID элемента создания новой папки в меню выбора пространств документов.
     */
    private static readonly _CREATE_SPACE_ITEM_ID: string = "createSpaceItemId";

    //endregion
    //region Inputs

    /**
     * Входящие данные - текущее пространство документов.
     */
    @Input()
    set space(space: Space) {

        this._space = space;
        this._buildMenuItems();
    }

    /**
     * Входящие данные - список пространств документов.
     */
    @Input()
    set spaces(spaces: Space[]) {

        this._spaces = spaces;
        this._buildMenuItems();
    }

    /**
     * Иконка отключена?
     */
    @Input()
    readonly iconDisabled: boolean;

    /**
     * Тема крупного размера меню?
     */
    @Input()
    readonly large: boolean;

    /**
     * Текущий URL без query.
     */
    @Input()
    readonly path: string;

    /**
     * Текущий пользователь может приглашать хотя бы в одно своё пространство документов?
     */
    @Input()
    readonly canInviteInAnySpace: boolean;

    /**
     * У текущего пользователя есть право создавать новые пространства документов?
     */
    @Input()
    readonly canCreateSpace: boolean;

    //endregion
    //region Outputs

    /**
     * Исходящее событие - требование создания нового пространство документов.
     */
    @Output()
    readonly createSpace: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Исходящее событие - требование перехода в выбранное пространство документов.
     */
    @Output()
    readonly openSpace: EventEmitter<Space> = new EventEmitter<Space>();

    /**
     * Исходящее событие - требование перехода в настройки выбранного пространства документов.
     */
    @Output()
    readonly spaceSettings: EventEmitter<Space> = new EventEmitter<Space>();

    /**
     * Исходящее событие - требование открыть диалог для приглашения пользователей в пространства документов.
     */
    @Output()
    readonly openInvitationDialog: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Исходящее событие - требование открыть диалог с неизвестной ошибкой.
     */
    @Output()
    readonly openErrorDialog: EventEmitter<void> = new EventEmitter<void>();

    //endregion
    //region Fields

    /**
     * Элементы выпадающего меню.
     */
    menuItems: InfoMenuItem[];

    /**
     * Поле для хранения текущего пространства документов.
     */
    private _space: Space;

    /**
     * Поле для хранения списка пространств документов.
     */
    private _spaces: Space[];

    //endregion
    //region Hooks

    /**
     * Логика инициализации компонента.
     */
    ngOnInit(): void {

        this._buildMenuItems();
    }

    //endregion
    //region Getters and Setters

    /**
     * Возвращает текущее пространства документов.
     */
    get space(): Space {

        return this._space;
    }

    /**
     * Возвращает список пространств документов.
     */
    get spaces(): Space[] {

        return this._spaces;
    }

    /**
     * Возвращает название текущего пространства документов, если оно есть.
     */
    get spaceName(): string {

        return (this.space ? this.space.name : null);
    }

    //endregion
    //region Events

    /**
     * Обработчик клика по элементу меню.
     */
    menuItemClickHandler(event: Space | string) {

        if (typeof event === "string") {

            if (event === SpacesInfoMenuComponent._INVITATION_MENU_ITEM_ID) {

                this.openInvitationDialog.emit();
            }
            else if (event === SpacesInfoMenuComponent._CREATE_SPACE_ITEM_ID) {

                this.createSpace.emit();
            }
            else {

                this.openErrorDialog.emit();
            }
        }
        else {

            const selectedSpace: Space = event as Space;

            if (
                !this._space
                || selectedSpace.id !== this._space.id
                || (
                    UrlUtils.spaceIntegrationSettingsPageUrl(this._space.id) === this.path
                    || UrlUtils.spaceNotificationPageUrl(this._space.id) === this.path
                )
            ) {
                this.openSpace.emit(event as Space);
            }
        }
    }

    /**
     * Обработчик клика по правой иконке элемента меню.
     */
    menuItemRightIconClickHandler(space: Space) {

        if (
            !this._space
            || space.id !== this._space.id
            || (
                UrlUtils.spaceIntegrationSettingsPageUrl(this._space.id) !== this.path
                && UrlUtils.spaceNotificationPageUrl(this._space.id) !== this.path
            )
        ) {
            this.spaceSettings.emit(space);
        }
    }

    //endregion
    //region Private

    /**
     * Формирует элементы выпадающего меню.
     */
    private _buildMenuItems(): void {

        const newMenuItems: InfoMenuItem[] = [];

        if (this.canInviteInAnySpace) {

            newMenuItems.push(this._getInvitationItem());
        }

        if (this.canCreateSpace) {

            newMenuItems.push(this._getCreateSpaceItem());
        }

        if (this.spaces) {

            for (const space of this.spaces) {

                newMenuItems.push({
                    id: space,
                    icon: " ",
                    rightIcon: "&#xe8b8;",
                    rightIconTitleKey: "header.spaces.settings.icon",
                    rightIconFontSet: "material-icons-custom-set",
                    text: space.name,
                    selected: (space === this.space)
                });
            }
        }

        this.menuItems = newMenuItems;
    }

    /**
     * Формирует и возвращает элемент меню для приглашения пользователей в пространства документов.
     *
     * @return Элемент меню для приглашения пользователей в пространства документов.
     */
    private _getInvitationItem(): InfoMenuItem {

        return {
            id: SpacesInfoMenuComponent._INVITATION_MENU_ITEM_ID,
            icon: "group_add",
            messageKey: "header.spaces.settings.invitationItem",
            green: true,
        };
    }

    /**
     * Формирует и возвращает элемент меню для создания нового пространства документов.
     *
     * @return Элемент меню для создания нового пространства документов.
     */
    private _getCreateSpaceItem(): InfoMenuItem {

        return {
            id: SpacesInfoMenuComponent._CREATE_SPACE_ITEM_ID,
            icon: "add_circle_outline",
            messageKey: "spaces.root.create",
            green: true,
        };
    }

    //endregion
}
