import { OnDestroy } from "@angular/core";
import { OnInit } from "@angular/core";
import { Input } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { Output } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ApiResponse } from "../../models";
import { Company } from "../../models";

/**
 * Компонент компонента диалога создания компании..
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'entera-company-creator-form',
    styleUrls: ['company-creator-form.component.scss'],
    templateUrl: 'company-creator-form.component.html'
})
export class CompanyCreatorFormComponent implements OnInit, OnDestroy {
    //region Input

    /**
     * Сеттер установки состояния формы.
     *
     * @param company Состояние для установки.
     */
    @Input()
    set value(company: Company) {
        this.createOrClearForm();
        if (company) {
            this.formGroup.patchValue({
                name: company.name,
                shortName: company.shortName,
                inn: company.inn,
                kpp: company.kpp
            })
        }
    }

    /**
     * Сетер установки ошибок для полей.
     *
     * @param errorData информация об ошибках.
     */
    @Input()
    errorData: ApiResponse;

    /**
     * Отображать ли заголовок.
     */
    @Input()
    showHeader: boolean = true;

    /**
     * Компонент в состоянии загрузки.
     */
    @Input()
    set loading(loading: boolean) {
        this._loading = loading;
        if (this.formGroup) {
            if (loading) {
                this.formGroup.disable();
            }
            else {
                this.formGroup.enable();
            }
        }
    };

    //endregion
    //region Output

    /**
     * Исходящее событие попытки создания компании.
     */
    @Output()
    public createCompany: EventEmitter<Company> = new EventEmitter();

    //endregion
    //region Fields

    /**
     * Группа формы для полей диалога.
     */
    formGroup: FormGroup;

    /**
     * Состяоние загрузки
     */
    get loading(): boolean {
        return this._loading
    }

    //endregion
    //region Private

    /**
     * Объект глобальной отписки.
     */
    private _subject = new Subject();

    /**
     * Приватное значение загрузки.
     */
    private _loading : boolean = false;

    //endregion
    //region С-tor

    constructor(
        private _formBuilder: FormBuilder,
    ) {

    }

    //endregion
    //region LifeCycle

    ngOnInit(): void {
        if(!this.formGroup) {
            this.createOrClearForm();
        }
    }

    ngOnDestroy(): void {
        this._subject.next();
    }



    //endregion
    //region Public

    /**
     * Обработчик нажатия клавиши создать.
     */
    createHandler() {
        if (this.formGroup.valid) {
            this.createCompany.emit(this.formGroup.value as Company)
        }
    }

    /**
     * Очистка или создание пустой формы если ее еще нет.
     */
    createOrClearForm() {
        if (this.formGroup) {
            this.formGroup.patchValue({
                shortName: null,
                name: null,
                inn: null,
                kpp: null,
            });
        }
        else {
            this.formGroup = this._formBuilder.group({
                shortName: null,
                name: null,
                inn: null,
                kpp: null,
            });
        }

        this.errorData = null;

    }

    /**
     * Полное описание ошибки.
     */
    getErrorMessage(): string {
        let result = null;
        if (this.errorData) {
            result = "" + this.errorData.errorMessage;
            if (this.errorData.details) {
                result = this.errorData.details.reduce((str, error) => str + " " + error.errorMessage, result);
            }
        }
        return result;
    }

    //endregion

}