/**
 * Событие для отправки в Google Analytics.
 */
export class GaEvent {
    //region Fields

    /**
     * Категория события.
     *
     * Например, форма регистрации.
     */
    readonly eventCategory: string;

    /**
     * Действие, вызвавшее событие.
     *
     * Например, заполнение поля.
     */
    readonly eventAction: String;

    /**
     * Ярлык события.
     *
     * Любая текстовая информация.
     */
    readonly eventLabel: string;

    /**
     * Численное значение, связанное с событием.
     *
     * Может использоваться в качестве ценности цели конверсии.
     */
    readonly eventValue: number;

    //endregion
    //region Ctor

    constructor(eventCategory: string, eventAction: String, eventLabel: string = null, eventValue: number = null) {
        this.eventCategory = eventCategory;
        this.eventAction = eventAction;
        this.eventLabel = eventLabel;
        this.eventValue = eventValue;
    }

    //endregion
}