var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SupplyTypesActionType } from "src/app/spaces/modules/document-matching/store/actions/supply-types.action";
import { environment } from "src/environments/environment";
/**
 * Начальное состояние обработчика событий, связанных с состоянием единиц измерения.
 */
var initialState = {
    /**
     * Начальное состояние запроса единиц измерения с сервера.
     */
    supplyTypes: [],
};
/**
 * Обработчик событий, связанных с состоянием единиц измерения.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 *
 * @return Новое состояние.
 */
export function supplyTypesReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        case SupplyTypesActionType.LOAD: {
            var integrationItem = environment.integrations
                .find(function (integrationItem) { return integrationItem.serverType == action.integrationType; });
            var itemSettings = integrationItem ? integrationItem.items : null;
            var supplyTypes = itemSettings ? itemSettings.supplyType : [];
            result = __assign({}, state, { supplyTypes: supplyTypes });
            break;
        }
    }
    return result;
}
