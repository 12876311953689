import { RouterNavigationAction } from "@ngrx/router-store";
import { ROUTER_NAVIGATION } from '@ngrx/router-store';

/**
 * Состояние с информацией о текущем и предыдущем URL.
 */
export interface PreviousUrlState {
    //region Fields

    /**
     * Текущий URL.
     */
    currentUrl: string;

    /**
     * Предыдущий URL.
     */
    previousUrl: string;

    //endregion
}

/**
 * Начальное состояние с информацией о текущем и предыдущем URL.
 */
export const initialState: PreviousUrlState = {
    currentUrl: null,
    previousUrl: null,
};

/**
 * Обработчик изменения состояния с информацией о текущем и предыдущем URL.
 *
 * @param state Текущее состояние.
 * @param action Событие, произошедшее в системе.
 *
 * @return
 */
export function previousUrlReducer(
    state: PreviousUrlState = initialState,
    action: RouterNavigationAction,
): PreviousUrlState {

    let result = state;

    if (action.type == ROUTER_NAVIGATION) {

        result = {
            currentUrl: action.payload.routerState.url,
            previousUrl: state.currentUrl,
        };
    }

    return result;
}

/**
 * Возвращает предыдущий URL из состояния.
 *
 * @param state Состояние с информацией о текущем и предыдущем URL.
 *
 * @return Предыдущий URL.
 */
export function getPreviousUrl(state: PreviousUrlState): string {

    return state.previousUrl
}
