import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { ErrorResponseProps } from "src/app/root/store/actions/props/error-response.action-props";
import { AcceptDocumentMatchesActionProps } from "src/app/spaces/modules/documents-registry/store/actions/props/accept-document-matches-action.props";

/**
 * Типы событий связанные с отправкой документов на сопоставление.
 */
export enum SendToMatchingActionType {
    ACCEPT = "[Send document to matching] Accept matching documents from server",
    SEND_SUCCESS = "[Send document to matching] Document successfully sent to matching",
    SEND_FAIL = "[Send document to matching] Send document to matching fail",
}

/**
 * Событие получения документов на сопоставление.
 */
const accept = createAction(
    SendToMatchingActionType.ACCEPT,
    props<AcceptDocumentMatchesActionProps>(),
);

/**
 * Событие успешной отправки документов на сопоставление.
 */
const sentSuccessfully = createAction(
    SendToMatchingActionType.SEND_SUCCESS
);

/**
 * Событие неудачной отправки документов на сопоставление.
 */
const sendingFailed = createAction(
    SendToMatchingActionType.SEND_FAIL,
    props<ErrorResponseProps<void>>(),
);

/**
 * Тип, объединяющий все события, связанные с отправкой документов на сопоставление.
 */
export type SendToMatchingAction =
    | ReturnType<typeof accept>
    | ReturnType<typeof sentSuccessfully>
    | ReturnType<typeof sendingFailed>;

/**
 * Все события, связанные с отправкой документов на сопоставление.
 */
export const sendToMatchingActions = {
    accept,
    sentSuccessfully,
    sendingFailed,
};
