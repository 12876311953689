import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { ErrorResponseProps } from "src/app/root/store/actions/props/error-response.action-props";
import { ValueProps } from "src/app/root/store/actions/props/value-props";
import { ExternalLocation } from "src/app/spaces/modules/document-matching/models/external-location";
import { IntegrationProps } from "src/app/spaces/modules/documents-registry/store/actions/props/integration.props";

/**
 * Типы событий, связанных со складами из внешней системы.
 */
export enum ExternalLocationsActionType {
    INIT = "[External locations] Init request state",
    LOAD = "[External locations] Load",
    LOAD_SUCCESS = "[External locations] Successfully load",
    LOAD_FAIL = "[External locations] Error occurred while loading",
}

/**
 * События инициализации состояния запроса складов.
 */
const init = createAction(ExternalLocationsActionType.INIT);

/**
 * Событие получения складов из внешней системы с сервера.
 */
const load = createAction(ExternalLocationsActionType.LOAD, props<IntegrationProps>());

/**
 * Событие успешного получения складов из внешней системы с сервера.
 */
const loadSuccess = createAction(ExternalLocationsActionType.LOAD_SUCCESS, props<ValueProps<ExternalLocation[]>>());

/**
 * Событие ошибки при получении складов из внешней системы с сервера.
 */
const loadFail = createAction(ExternalLocationsActionType.LOAD_FAIL, props<ErrorResponseProps<void>>());

/**
 * Тип, объединяющий все события, связанные со складами из внешней системы.
 */
export type ExternalLocationsAction =
    | ReturnType<typeof init>
    | ReturnType<typeof load>
    | ReturnType<typeof loadSuccess>
    | ReturnType<typeof loadFail>;

/**
 * Все события, связанные со складами из внешней системы
 */
export const externalLocationsActions = {
    init,
    load,
    loadSuccess,
    loadFail,
};
