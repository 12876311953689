import { HttpClient } from "@angular/common/http";
import { HttpParams } from "@angular/common/http";
import { throwError } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
/**
 * Сервис для выполнения административных задач.
 */
var AdminService = /** @class */ (function () {
    //region Ctor
    function AdminService(_httpClient) {
        this._httpClient = _httpClient;
    }
    //endregion
    //region Public
    /**
     * Выполняет вход под пользователем.
     *
     * @param login Логин пользователя.
     */
    AdminService.prototype.impersonate = function (login) {
        return this._httpClient
            .post(environment.authServerUrl + "/api/v1/impersonate", { login: login }, { withCredentials: true })
            .pipe(map(function (response) { return response.result; }), catchError(function (response) { return throwError(response.error); }));
    };
    /**
     * Поиск пользователей вместе с их подробной информацией.
     *
     * @param request Пострачниный запрос.
     */
    AdminService.prototype.searchUsers = function (request) {
        if (!request.page) {
            request.page = 1;
        }
        if (!request.pageSize) {
            request.pageSize = 10;
        }
        var params = new HttpParams()
            .set("page", request.page.toFixed())
            .set("pageSize", request.pageSize.toFixed());
        if (request.search) {
            params = params.set("search", request.search);
        }
        if (request.id) {
            params = request.id.reduce(function (params, id) { return params.append("id", id); }, params);
        }
        return this._httpClient
            .get("/api/v1/admin/users", { withCredentials: true, params: params })
            .pipe(map(function (response) { return response.users; }), catchError(function (response) { return throwError(response.error); }));
    };
    /**
     * Поиск списка клиентов по указанному в запросе email-у.
     *
     * @param request Запрос для поиска клиентов по email-у.
     */
    AdminService.prototype.findClientsByEmail = function (request) {
        if (!request.page) {
            request.page = 1;
        }
        if (!request.pageSize) {
            request.pageSize = 10;
        }
        var params = new HttpParams()
            .set("page", request.page.toFixed())
            .set("pageSize", request.pageSize.toFixed());
        if (request.search) {
            params = params.set("search", request.search);
        }
        if (request.id) {
            params = request.id.reduce(function (params, id) { return params.append("id", id); }, params);
        }
        return this._httpClient
            .get("/api/v1/admin/findClientsByEmail", { withCredentials: true, params: params })
            .pipe(map(function (response) { return response.clients; }), catchError(function (response) { return throwError(response.error); }));
    };
    /**
     * Поиск списка компаний в Bitrix24 по Id компании.
     *
     * @param id Id компании с системе Bitrix24.
     * @param foreign Иностранный ли клиент?
     */
    AdminService.prototype.findClientCompany = function (id, foreign) {
        var body = {
            companyId: id,
            foreign: foreign,
        };
        return this._httpClient
            .post("/api/v1/admin/getBitrixCompany", body)
            .pipe(map(function (response) { return response.bitrixCompany; }), catchError(function (response) { return throwError(response.error); }));
    };
    /**
     * Поиск списка клиентов по указанному в запросе имени и/или ИНН.
     *
     * @param request Запрос для поиска клиентов по имени и/или ИНН.
     */
    AdminService.prototype.findClientsByNameInnOrBitrixId = function (request) {
        if (!request.page) {
            request.page = 1;
        }
        if (!request.pageSize) {
            request.pageSize = 10;
        }
        var params = new HttpParams()
            .set("page", request.page.toFixed())
            .set("pageSize", request.pageSize.toFixed());
        if (request.search) {
            params = params.set("search", request.search);
        }
        if (request.id) {
            params = request.id.reduce(function (params, id) { return params.append("id", id); }, params);
        }
        return this._httpClient
            .get("/api/v1/admin/findClientsByNameInnOrBitrixId", { withCredentials: true, params: params })
            .pipe(map(function (response) { return response.clients; }), catchError(function (response) {
            return throwError(response.error);
        }));
    };
    /**
     * Получение информации о клиенте.
     *
     * @param clientId Id клиента.
     */
    AdminService.prototype.getClient = function (clientId) {
        return this._httpClient
            .get("/api/v1/admin/client/" + clientId)
            .pipe(map(function (response) { return response.client; }), catchError(function (response) { return throwError(response.error); }));
    };
    /**
     * Изменение клиента пользователя.
     *
     * @param client информация для изменения данных клиента.
     */
    AdminService.prototype.changeClient = function (client) {
        return this._httpClient
            .put("/api/v1/admin/client/" + client.id, client)
            .pipe(map(function (response) { return response.client; }), catchError(function (response) { return throwError(response.error); }));
    };
    /**
     * Выполняет изменение баланса.
     *
     * @param info Информация для изменения баланса.
     * @param clientId Id клиента.
     */
    AdminService.prototype.updateBalance = function (info, clientId) {
        return this._httpClient
            .post("/api/v1/admin/clients/" + clientId + "/transactions", info)
            .pipe(map(function (response) { return response.result; }), catchError(function (response) { return throwError(response.error); }));
    };
    /**
     * Обновляет TRIAL-приоритет пространства документов.
     *
     * @param trial TRIAL-приоритет пространства документов.
     * @param spaceId ID пространства документа, у которого обновляется TRIAL-приоритет.
     */
    AdminService.prototype.updateTrial = function (trial, spaceId) {
        return this._httpClient
            .put("/api/v1/spaces/" + spaceId + "/trial", { value: trial })
            .pipe(map(function (response) { return response.result; }), catchError(function (response) { return throwError(response.error); }));
    };
    /**
     * Обновляет HIGH-приоритет пространства документов.
     *
     * @param priority Приоритет пространства документов.
     * @param spaceId ID пространства документа, у которого обновляется HIGH-приоритет.
     */
    AdminService.prototype.updateSpacePriority = function (priority, spaceId) {
        return this._httpClient
            .put("/api/v1/spaces/" + spaceId + "/priority", { spacePriority: priority.id, spaceId: spaceId })
            .pipe(map(function (response) { return response.result; }), catchError(function (response) { return throwError(response.error); }));
    };
    /**
     * Обновляет флаг использования баланса клиента пространства документов.
     *
     * @param useClientBalance Значение флага использования баланса клиента пространства документов.
     * @param spaceId ID пространства документа, у которого обновляется HIGH-приоритет.
     */
    AdminService.prototype.updateUseClientBalance = function (useClientBalance, spaceId) {
        return this._httpClient
            .put("/api/v1/spaces/" + spaceId + "/useClientBalance", { value: useClientBalance })
            .pipe(map(function (response) { return response.result; }), catchError(function (response) { return throwError(response.error); }));
    };
    /**
     * Обновляет флаг использования пространства документов для иностранных документов.
     *
     * @param foreign Флаг использования пространства документов для иностранных документов.
     * @param spaceId ID пространства документа, у которого обновляется флаг.
     *
     * @return Флаг успешности выполнения запроса.
     */
    AdminService.prototype.updateForeignSpace = function (foreign, spaceId) {
        return this._httpClient
            .put("/api/v1/spaces/" + spaceId + "/foreign", { value: foreign })
            .pipe(map(function (response) { return response.result; }), catchError(function (response) { return throwError(response.error); }));
    };
    /**
     * Обновляет информацию о пространстве документов.
     *
     * @param space Простанство документов.
     */
    AdminService.prototype.updateSpaceInfo = function (space) {
        var spaceInfo = { id: space.id, name: space.name };
        return this._httpClient
            .put("/api/v1/spaces/" + space.id, spaceInfo)
            .pipe(map(function (response) { return response.result; }), catchError(function (response) { return throwError(response.error); }));
    };
    /**
     * Создаёт новое пространство документов.
     *
     * @param spaceName Имя нового пространства документов.
     * @param clientId Id клиента, у которого необходимо создать пространство документов.
     */
    AdminService.prototype.createNewSpace = function (spaceName, clientId) {
        var newSpaceInfo = { name: spaceName, clientId: clientId };
        return this._httpClient
            .post("/api/v1/spaces", newSpaceInfo)
            .pipe(map(function (response) { return response.space; }), catchError(function (response) { return throwError(response.error); }));
    };
    /**
     * Возвращает транзакции клиента.
     *
     * @param clientId ID клиента, транзакции которого необходимо получить.
     */
    AdminService.prototype.getClientTransactions = function (clientId) {
        return this._httpClient
            .get("/api/v1/admin/clients/" + clientId + "/transactions")
            .pipe(map(function (response) { return response.transactions; }), catchError(function (response) { return throwError(response.error); }));
    };
    /**
     * Приглашает пользователя с указанным email-ом в указанное пространство документов.
     *
     * @param space Пространство документов, в которое приглашается пользователь.
     * @param email Пользователь, приглашаемый в пространство документов.
     */
    AdminService.prototype.inviteUserIntoSpace = function (space, email) {
        return this._httpClient
            .post("/api/v1/invite", { emails: [email], spaceIds: [space.id] })
            .pipe(map(function (response) { return response.result; }), catchError(function (response) { return throwError(response.error); }));
    };
    /**
     * Создает график балансовых начислений.
     *
     * @param clientId Id выбранного клиента.
     * @param transactions Массив графиков начислений.
     */
    AdminService.prototype.changeBalanceSchedule = function (clientId, transactions) {
        return this._httpClient
            .post("/api/v1/admin/clients/" + clientId + "/transactions/schedules", { transactions: transactions })
            .pipe(map(function (response) { return response.result; }), catchError(function (response) { return throwError(response.error); }));
    };
    /**
     * Отменяет подписку клиента.
     *
     * @param clientId ID клиента.
     * @param price Возвращаемая клиенту стоимость подписки.
     * @param comment Комментарий.
     */
    AdminService.prototype.cancelSubscription = function (clientId, price, comment) {
        var url = "/api/v1/admin/clients/" + clientId + "/cancelSubscription?price=" + price;
        if (comment && comment.length) {
            url = url + "&comment=" + comment;
        }
        return this._httpClient
            .post(url, {})
            .pipe(map(function (response) { return response.result; }), catchError(function (response) { return throwError(response.error); }));
    };
    AdminService.ngInjectableDef = i0.defineInjectable({ factory: function AdminService_Factory() { return new AdminService(i0.inject(i1.HttpClient)); }, token: AdminService, providedIn: "root" });
    return AdminService;
}());
export { AdminService };
