import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { ApiResponse } from "src/app/common/models/api-response";
import { SpaceIdsAndEmails } from "src/app/common/models/space-ids-and-emails.model";
import { ValueProps } from "src/app/root/store/actions/props/value-props";

/**
 * Типы событий, связанные с отправкой приглашений пользователей в пространства документов.
 */
export enum UsersInvitationActionType {
    //region Enum values

    /**
     * Тип события, требующего инициализацию начальных данных для приглашения пользователей в пространства
     * документов.
     */
    INITIALIZE = "[Users invitation dialog] Initialize dialog",

    /**
     * Тип события, требующего пригласить пользователей в пространства документов.
     */
    INVITE = "[Users invitation dialog] Invite users in spaces",

    /**
     * Тип события успешного выполнения запроса на отправку приглашения пользователей в пространства документов.
     */
    INVITATION_SUCCESS = "[Users invitation dialog] Invitation finished successfully",

    /**
     * Тип события неудачного выполнения запроса на отправку приглашения пользователей в пространства документов.
     */
    INVITATION_FAILED = "[Users invitation dialog] Invitation failed",

    //endregion
}

/**
 * Событие, требующее инициализацию начальных данных для приглашения пользователей в пространства документов.
 */
const initialize = createAction(UsersInvitationActionType.INITIALIZE);

/**
 * Событие, требующее пригласить пользователей в пространства документов.
 */
const invite = createAction(
    UsersInvitationActionType.INVITE,
    props<ValueProps<SpaceIdsAndEmails>>(),
);

/**
 * Событие успешного выполнения запроса на отправку приглашения пользователей в пространства документов.
 */
const invitationSuccess = createAction(
    UsersInvitationActionType.INVITATION_SUCCESS,
    props<ValueProps<SpaceIdsAndEmails>>(),
);

/**
 * Событие неудачного выполнения запроса на отправку приглашения пользователей в пространства документов.
 */
const invitationFailed = createAction(
    UsersInvitationActionType.INVITATION_FAILED,
    props<ValueProps<ApiResponse>>()
);

/**
 * Тип, объединяющий события, связанные с отправкой приглашений пользователей в пространства документов.
 */
export type UsersInvitationAction =
    ReturnType<typeof initialize>
    | ReturnType<typeof invite>
    | ReturnType<typeof invitationSuccess>
    | ReturnType<typeof invitationFailed>
    ;

/**
 * Все события, связанные с отправкой приглашений пользователей в пространства документов.
 */
export const usersInvitationActions = {
    initialize: initialize,
    invite: invite,
    invitationSuccess: invitationSuccess,
    invitationFailed: invitationFailed,
};
