/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./empty.component";
var styles_EmptyComponent = [];
var RenderType_EmptyComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EmptyComponent, data: {} });
export { RenderType_EmptyComponent as RenderType_EmptyComponent };
export function View_EmptyComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_EmptyComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "empty", [], null, null, null, View_EmptyComponent_0, RenderType_EmptyComponent)), i0.ɵdid(1, 49152, null, 0, i1.EmptyComponent, [], null, null)], null, null); }
var EmptyComponentNgFactory = i0.ɵccf("empty", i1.EmptyComponent, View_EmptyComponent_Host_0, {}, {}, []);
export { EmptyComponentNgFactory as EmptyComponentNgFactory };
