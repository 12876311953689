import { createSelector } from "@ngrx/store";
import { createFeatureSelector } from "@ngrx/store";
import { UsersInvitationState } from "src/app/root/store/states/users-invitation.state";

/**
 * Селектор состояния приглашения пользователей в пространства документов.
 */
const state = createFeatureSelector<UsersInvitationState>("usersInvitation");

/**
 * Объект, объединяющий состояние запроса приглашения пользователей в пространства документов.
 */
export const requestState = createSelector(
    state,
    (state: UsersInvitationState) => state.inviteUsersToSpaceRequest,
)

/**
 * Объект, объединяющий селекторы, которые относятся к состоянию данных текущего пользователя.
 */
export const usersInvitationSelectors = {
    requestState,
};
