var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as moment from "moment";
import { environment } from "../../../../../../../environments/environment";
import { DocumentsArchiveActionType } from "../../actions";
var initialState = {
    spaceId: null,
    badRequest: false,
    count: null,
    loading: false,
    success: false,
    error: null
};
/**
 * Обработка состояния дилога создания архива в ответ на события.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 */
export function documentsArchiveDlgReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        // ...
        case DocumentsArchiveActionType.INIT: {
            result = initialState;
            if (action.payload.ids && action.payload.ids.length) {
                result = __assign({}, result, { spaceId: action.payload.spaceId, filter: null, ids: action.payload.ids, badRequest: action.payload.ids.length > environment.documentsArchive.maxIdCount, count: action.payload.ids.length });
            }
            else {
                result = __assign({}, result, { spaceId: action.payload.spaceId, filter: action.payload.info, ids: null });
            }
            break;
        }
        // ...
        case DocumentsArchiveActionType.LOAD_COUNT: {
            result = __assign({}, result, { success: false, loading: true, error: null });
            break;
        }
        // ...
        case DocumentsArchiveActionType.MAKE_ARCHIVE: {
            result = __assign({}, result, { success: false, loading: true, error: null });
            break;
        }
        // ...
        case DocumentsArchiveActionType.LOAD_COUNT_SUCCESS: {
            result = __assign({}, result, { success: false, loading: false, error: null, count: action.payload, badRequest: !checkFilter(state.filter, action.payload) });
            if (!action.payload) {
                result = __assign({}, result, { badRequest: true });
            }
            break;
        }
        // ...
        case DocumentsArchiveActionType.MAKE_ARCHIVE_SUCCESS: {
            result = __assign({}, result, { success: true, loading: false, error: null });
            break;
        }
        // ...
        case DocumentsArchiveActionType.ERROR: {
            result = __assign({}, result, { success: false, loading: false, error: action.payload });
            break;
        }
    }
    return result;
}
/**
 * Возможно создать архив на основе фильтра?
 *
 * Один из диапазонов дат - дат документов или же дат создания документов - должен быть корректным.
 *
 * @param filter Фильтр запроса.
 * @param count Количество страниц после применения фильтра.
 *
 * @return Да/нет.
 */
function checkFilter(filter, count) {
    var createdFrom = typeof filter.createdDateFrom === "string"
        ? moment(filter.createdDateFrom)
        : filter.createdDateFrom;
    var createdTo = typeof filter.createdDateTo === "string"
        ? moment(filter.createdDateTo)
        : filter.createdDateTo || moment();
    var dateFrom = typeof filter.documentDateFrom === "string"
        ? moment(filter.documentDateFrom)
        : filter.documentDateFrom;
    var dateTo = typeof filter.documentDateTo === "string"
        ? moment(filter.documentDateTo)
        : filter.documentDateTo || moment();
    var correctCreatedDateRange = false;
    var correctDocumentDateRange = false;
    if (createdFrom) {
        var days = createdTo.diff(createdFrom, "days");
        correctCreatedDateRange = days >= 0 && days <= environment.documentsArchive.maxCreatedDateDayDifference;
    }
    if (dateFrom) {
        var days = dateTo.diff(dateFrom, "days");
        correctDocumentDateRange = days >= 0 && days <= environment.documentsArchive.maxCreatedDateDayDifference;
    }
    return correctCreatedDateRange || correctDocumentDateRange;
}
