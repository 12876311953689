import { EventEmitter } from "@angular/core";
import { Output } from "@angular/core";
import { Input } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { ExpiryNotificationType } from "src/app/common/models/expiry-notification-type";
import { CurrentUserInfo } from "src/app/common/models/index";

/**
 * Компонент плашки уведомления пользователя об истечении.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "user-expiry-notification",
    templateUrl: "./user-notification.component.html",
    styleUrls: ["./user-notification.component.scss"],
})
export class UserNotificationComponent {
    //region Inputs

    /**
     * Тип уведомления об истечении.
     */
    @Input()
    readonly notificationType: ExpiryNotificationType;

    //endregion
    //region Output

    /**
     * Требование закрытия плашки уведомления пользователя.
     */
    @Output()
    readonly closeNotification: EventEmitter<void> = new EventEmitter();

    /**
     * Исходящее событие - уведомление о том, что была нажата кнопка перехода на главную страницу.
     */
    @Output()
    readonly connectToManager = new EventEmitter<void>();

    //endregion
    //region Ctor

    /**
     * Конструктор компонента.
     */
    constructor() {}

    //endregion
    //region Events

    /**
     * Обработчик события, требующего закрыть плашку.
     */
    closeHandler(): void {

        this.closeNotification.emit();
    }

    /**
     * Обработчик клика по кнопке перехода на главную страницу.
     */
    connectToManagerHandler() {

        this.connectToManager.emit();
    }

    //endregion
}
