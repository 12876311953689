import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { ErrorResponseProps } from "src/app/root/store/actions/props/error-response.action-props";
import { ValueProps } from "src/app/root/store/actions/props/value-props";
import { IntegrationProps } from "src/app/spaces/modules/documents-registry/store/actions/props/integration.props";
import { EntityExternalDocumentType } from "src/app/spaces/modules/document-matching/models/entity-external-document-type";

/**
 * Типы событий, связанных с типами внешних документов.
 */
export enum EntityExternalDocumentTypesActionType {
    LOAD = "[Entity external types] Load",
    LOAD_SUCCESS = "[Entity external types] Successfully load",
    LOAD_FAIL = "[Entity external types] Error occurred while loading",
}

/**
 * Событие получения типов документов из внешней системы с сервера.
 */
const load = createAction(EntityExternalDocumentTypesActionType.LOAD, props<IntegrationProps>());

/**
 * Событие успешного получения типов документов из внешней системы с сервера.
 */
const loadSuccess = createAction(
    EntityExternalDocumentTypesActionType.LOAD_SUCCESS,
    props<ValueProps<EntityExternalDocumentType[]>>()
);

/**
 * Событие ошибки при получении типов документов из внешней системы с сервера.
 */
const loadFail = createAction(EntityExternalDocumentTypesActionType.LOAD_FAIL, props<ErrorResponseProps<void>>());

/**
 * Тип, объединяющий все события, связанные со типами документов из внешней системы.
 */
export type EntityExternalDocumentTypeAction =
    | ReturnType<typeof load>
    | ReturnType<typeof loadSuccess>
    | ReturnType<typeof loadFail>;

/**
 * Все события, связанные со складами из внешней системы
 */
export const entityExternalDocumentTypesAction = {
    load,
    loadSuccess,
    loadFail,
};
