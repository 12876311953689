import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { HeaderShowAction } from '../store';
import { headerVisibleSelector } from '../store';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
/**
 * Route Guard.
 * Логика обработки попытки входа по пути, для которого требуется видимость заголовка сайта.
 */
var ShowHeaderGuard = /** @class */ (function () {
    //region Ctor
    function ShowHeaderGuard(_store) {
        this._store = _store;
    }
    //endregion
    //region Public
    ShowHeaderGuard.prototype.canActivate = function () {
        var _this = this;
        return this._store
            .pipe(select(headerVisibleSelector), 
        // Если заголовок не виден, то запускаем его отображение.
        tap(function (visible) {
            if (!visible) {
                _this._store.dispatch(new HeaderShowAction());
            }
        }), 
        // Даём разрешение только тогда, когда заголовок станет виден.
        map(function (visible) { return visible; }));
    };
    ShowHeaderGuard.prototype.canActivateChild = function () {
        return this.canActivate();
    };
    ShowHeaderGuard.ngInjectableDef = i0.defineInjectable({ factory: function ShowHeaderGuard_Factory() { return new ShowHeaderGuard(i0.inject(i1.Store)); }, token: ShowHeaderGuard, providedIn: "root" });
    return ShowHeaderGuard;
}());
export { ShowHeaderGuard };
