import { DocumentsRegistryExportActionType } from "src/app/spaces/modules/documents-registry/store/actions/documents-registry-export.action";
import { DocumentsRegistryExportAction } from "src/app/spaces/modules/documents-registry/store/actions/documents-registry-export.action";
import { DocumentsRegistryExportState } from "./documents-registry-export.state";

const initialState: DocumentsRegistryExportState = {
    loading: false,
    success: false,
    error: null,
};

/**
 * Обработка событий, связанных с состоянием выгрузки реестра документов в Excel-файл.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 *
 * @return ...
 */
export function documentsRegistryExportReducer(
    state: DocumentsRegistryExportState = initialState,
    action: DocumentsRegistryExportAction
): DocumentsRegistryExportState {

    let result: DocumentsRegistryExportState = state;

    switch (action.type) {

        case DocumentsRegistryExportActionType.EXPORT_DOCUMENTS_REGISTRY: {

            result = initialState;

            result = {
                ...result,
                success: false,
                loading: true,
                error: null,
            };
            break;
        }

        case DocumentsRegistryExportActionType.EXPORT_DOCUMENTS_REGISTRY_SUCCESS: {

            result = {
                ...result,
                success: true,
                loading: false,
            };
            break;
        }

        case DocumentsRegistryExportActionType.EXPORT_DOCUMENTS_REGISTRY_FAIL: {

            result = {
                ...result,
                success: false,
                loading: false,
                error: action.payload,
            };
            break;
        }
    }

    return result;
}
