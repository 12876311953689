var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { requestState } from "src/app/common/models/request-state";
import { UsersInvitationActionType } from "src/app/root/store/actions/users-invitation.action";
/**
 * Начальное состояние приглашения пользователей в пространства документов.
 */
var initialState = {
    inviteUsersToSpaceRequest: requestState.initial()
};
/**
 * Обработка событий, связанных с состоянием приглашения пользователей в пространства документов.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 *
 * @return Состояние приглашения пользователей в пространстве документов после обработки событий.
 */
export function usersInvitationStateReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        // На событие с требованием инициализировать данные приглашения пользователей в пространства документов
        case UsersInvitationActionType.INITIALIZE: {
            result = initialState;
            break;
        }
        // На событие с требованием пригласить пользователей в пространства документов.
        case UsersInvitationActionType.INVITE: {
            result = __assign({}, state, { inviteUsersToSpaceRequest: requestState.pending() });
            break;
        }
        // На событие успешного выполнения запроса на отправку приглашения пользователей в пространства документов.
        case UsersInvitationActionType.INVITATION_SUCCESS: {
            result = __assign({}, state, { inviteUsersToSpaceRequest: requestState.success(action.value) });
            break;
        }
        // На событие неудачного выполнения запроса на отправку приглашения пользователей в пространства документов.
        case UsersInvitationActionType.INVITATION_FAILED: {
            result = __assign({}, state, { inviteUsersToSpaceRequest: requestState.fail(action.value) });
            break;
        }
    }
    return result;
}
