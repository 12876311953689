var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { PhoneConfirmDlgActionType } from "../actions";
import { PhoneConfirmDlgState } from "./phone-confirm-dlg.state";
/**
 * Начальное состояние диалога подтверждения телефона.
 */
var initialState = new PhoneConfirmDlgState();
/**
 * Обработчик событий, связанных с диалогом подтверждения телефона.
 *
 * @param state Состояние диалога подтверждение телефона.
 * @param action Событие произошедшее в системе.
 */
export function phoneConfirmDlgReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        case PhoneConfirmDlgActionType.INIT_DIALOG: {
            result = new PhoneConfirmDlgState();
            break;
        }
        case PhoneConfirmDlgActionType.SEND_SMS: {
            result = __assign({}, state, { smsSent: false, smsSending: true, smsSentError: null });
            break;
        }
        case PhoneConfirmDlgActionType.SEND_SMS_SUCCESS: {
            result = __assign({}, state, { smsSent: true, smsSending: false, codeConfirmError: null });
            break;
        }
        case PhoneConfirmDlgActionType.SEND_SMS_FAIL: {
            result = __assign({}, state, { smsSending: false, smsSentError: __assign({}, action.payload, { errorMessage: action.payload.errorMessage ? action.payload.errorMessage.slice(0, -1) : null }) });
            break;
        }
        case PhoneConfirmDlgActionType.CONFIRM_CODE: {
            result = __assign({}, state, { codeConfirm: false, codeConfirming: true, codeConfirmError: null });
            break;
        }
        case PhoneConfirmDlgActionType.CONFIRM_CODE_SUCCESS: {
            result = __assign({}, state, { codeConfirming: false, codeConfirm: true });
            break;
        }
        case PhoneConfirmDlgActionType.CONFIRM_CODE_FAIL: {
            result = __assign({}, state, { codeConfirming: false, codeConfirmError: __assign({}, action.payload, { errorMessage: action.payload.errorMessage ? action.payload.errorMessage.slice(0, -1) : null }) });
            break;
        }
    }
    return result;
}
