import { Injectable } from "@angular/core";
import { forkJoin } from "rxjs";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { PermissionItem } from "src/app/common/models";
import { PermissionService } from "src/app/common/services/permission.service";
import { RouteService } from "src/app/common/services/route.service";

/**
 * Route Guard Factory.
 * Общая логика для повторного использования при создания Route Guard основанного на проверке
 * наличия права.
 */
@Injectable({
    providedIn: "root"
})
export class PermissionGuard {
    //region Ctor

    constructor(
        private _permissionService: PermissionService,
        private _routeService: RouteService
    ) {}

    //endregion
    //region Public

    /**
     * Возвращает ожидание проверки наличия заданного права. При этом если права у пользователя нет, то 
     * выполняется редирект на страницу /forbidden, сообщающую пользователю, что у него нет прав на просмотр 
     * запрошенной страницы.
     * 
     * Страница /forbidden отображает URL, куда хотел попасть пользователь. Соответственно данный метод принимает
     * параметр path, который передаёт ему guard.
     * 
     * Но загвоздка в том, что в полном виде получить URL перехода на страницу не всегда представляется возможным.
     * 
     * Есть два варианта перехода на страницу:
     * - через ввод URL в строке браузера
     * - через ссылку внутри приложения
     * 
     * И есть два варианта защиты URL'а
     * - путь до страницы закрыт CanLoad guard'ом
     * - путь до страницы закрыт CanActivate guard'ом
     * 
     * В случае ввода URL страницы в строке бразуера не важно как защищён URL, т.к. полный путь будет доступен 
     * через location. Состояние RouteState при этом ещё будет равно null. Это обрабатывается внутри 
     * RouteService.goToForbidden().
     * 
     * В случае перехода по URL внутри приложения:
     * 
     * - В случае CanLoad guard'а информацию о полном пути можно не получить, если CanLoad стоит в иерархии выше,
     * чем запрашиваемая страница. В этом случае CanLoad получит только чать URL'а, которую он защищает, как
     * входные данные в метод canLoad(route: CanLoadRoute)
     * 
     * - В случае CanActivate guard'а информация о полном пути приходит как входные данные в метод
     * canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot).
     * 
     * @param permission Право.
     * @param path Путь до страницы.
     */
    hasPermission(permission: PermissionItem, path: string = null): Observable<boolean> {

       return this.hasAnyPermission([permission], path);
    }

    /**
     * Возвращает ожидание проверки наличия любого права из заданного списка. При этом если права у пользователя нет, то
     * выполняется редирект на страницу /forbidden, сообщающую пользователю, что у него нет прав на просмотр
     * запрошенной страницы.
     *
     * @param permissions Права.
     * @param path Путь до страницы.
     */
    hasAnyPermission(permissions: PermissionItem[], path: string = null): Observable<boolean> {

        // TODO catchError и редирект на /something-goes-wrong.
        return forkJoin(
            permissions.map((permissionItem: PermissionItem) => this._permissionService.hasPermission(permissionItem))
        ).pipe(
            map((permissionResponses: boolean[]): boolean => permissionResponses.some(Boolean)),
            tap((hasPermission: boolean) => {

                if (!hasPermission) {

                    this._routeService.goToForbidden(path);
                }
            })
        );
    }

    //endregion
}
