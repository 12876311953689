<span mat-dialog-title>
    <span class="mat-dialog-title__text">{{ 'spaces.recognitionTaskErrorDlg.failedHeader' | translate }}</span>
</span>
<mat-dialog-content>
    <div class="recognition-task-error-dlg__row">
        {{ 'spaces.recognitionTaskErrorDlg.text' | translate }}
    </div>
    <div class="recognition-task-error-dlg__row">
        <span class="recognition-task-error-dlg__title">
            {{ 'spaces.recognitionTaskErrorDlg.reason' | translate }}:
        </span>
        {{ recognitionTask.errorDescription }}
    </div>
    <div class="recognition-task-error-dlg__row">
        <span class="recognition-task-error-dlg__title">
            {{ 'spaces.recognitionTaskErrorDlg.files' | translate }}:
        </span>
        <span *ngFor="let file of files; last as last">
            <span class="recognition-task-error-dlg__file-name"
                [class.recognition-task-error-dlg__file-name_break]="isFileNameTooLong(file)"
            >
                {{ file }}<!--
            --></span>
            <span *ngIf="!last">, </span>
        </span>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button class="mat-dialog__action-btn" mat-dialog-close mat-raised-button>
        {{ 'dialogs.close' | translate }}
    </button>
    <button class="mat-dialog__cross-close-btn"
        mat-flat-button
        mat-dialog-close
        title='{{ "dialogs.close" | translate }}'
    >
        <img src="/assets/images/cross-close-btn.svg" alt="">
    </button>
</mat-dialog-actions>
