import { EventEmitter } from "@angular/core";
import { FormControl } from '@angular/forms';
import { MatDialogRef } from "@angular/material";
import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UploadToRecognizeState } from "rootStore";
import { currentUserPermissionsSelector } from 'rootStore';
import { UtilsService } from "../../services";
/**
 * Компонент диалога для загрузки файлов на распознавание.
 */
var UploadToRecognizeDialogComponent = /** @class */ (function () {
    //endregion
    //region Ctor
    function UploadToRecognizeDialogComponent(store, dialogRef, data, utils) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.utils = utils;
        //endregion
        //region Outputs
        /**
         * Исходящее событие - создание задач на распознавание.
         */
        this.upload = new EventEmitter();
        /**
         * Исходящее событие - добвление файла на распознавание.
         */
        this.add = new EventEmitter();
        /**
         * Исходящее событие - удаление файла из задачи.
         */
        this.remove = new EventEmitter();
        /**
         * Исходящее событие - изменение флага необходимости выполнения OCR.
         */
        this.toggleForceOcr = new EventEmitter();
        /**
         * Исходящее событие - изменение флага необходимости выполнения парсинга.
         */
        this.toggleForceProcessing = new EventEmitter();
        /**
         * Исходящее событие - изменение флага необходимости отправки в очередь.
         */
        this.toggleForceQueue = new EventEmitter();
        /**
         * Исходящее событие - изменение комментария к документам на распознования.
         */
        this.commentEmitter = new EventEmitter();
        //endregion
        //region Public fields
        /**
         * Чек-бокс принудительного выполнения OCR.
         */
        this.forceOcr = new FormControl(false);
        /**
         * Чек-бокс принудительного выполнения парсинга.
         */
        this.forceProcessing = new FormControl(false);
        /**
         * Чек-бокс принудительной отправки в очередь.
         */
        this.forceQueue = new FormControl(false);
        /**
         * Комментарий к документам на распознавания.
         */
        this.comment = new FormControl("");
        /**
         * Переключатель, чтобы при выполнении onDestroy произошли отписки от всех подписок.
         *
         * @private
         */
        this._destroyed = new Subject();
        this._store = store;
    }
    Object.defineProperty(UploadToRecognizeDialogComponent.prototype, "uploadState", {
        //endregion
        //region Getters and Setters
        /**
         * Состояние данных для создания задач на распознавание.
         */
        get: function () {
            return this._uploadState;
        },
        //region Inputs
        /**
         *  Входящие данные - состояние данных для создания задач на распознавание.
         */
        set: function (value) {
            this._uploadState = value;
            this.forceOcr.setValue(value.forceOcrRecognition);
            this.forceProcessing.setValue(value.forceProcessing);
            this.forceQueue.setValue(value.forceQueue);
            this.comment.setValue(value.comment);
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Hooks
    UploadToRecognizeDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Следим за правами пользователя.
        this.permissions$ = this._store.pipe(select(currentUserPermissionsSelector));
        // Подписываемся на изменение флага принудительного выполнения OCR.
        this.forceOcr.valueChanges
            .pipe(takeUntil(this._destroyed))
            .subscribe(function (value) {
            if (_this.uploadState.forceOcrRecognition !== value) {
                _this.toggleForceOcr.emit(value);
            }
        });
        // Подписываемся на изменение флага принудительного выполнения парсинга.
        this.forceProcessing.valueChanges
            .pipe(takeUntil(this._destroyed))
            .subscribe(function (value) {
            if (_this.uploadState.forceProcessing !== value) {
                _this.toggleForceProcessing.emit(value);
            }
        });
        // Подписываемся на изменение флага принудительной отправки в очередь.
        this.forceQueue.valueChanges
            .pipe(takeUntil(this._destroyed))
            .subscribe(function (value) {
            if (_this.uploadState.forceQueue !== value) {
                _this.toggleForceQueue.emit(value);
            }
        });
        // Подписываемся на изменение комментария.
        this.comment.valueChanges
            .pipe(takeUntil(this._destroyed))
            .subscribe(function (value) {
            if (_this.uploadState.comment !== value) {
                _this.commentEmitter.emit(value);
            }
        });
    };
    UploadToRecognizeDialogComponent.prototype.ngOnDestroy = function () {
        this._destroyed.next();
        this._destroyed.complete();
    };
    //endregion
    //region Public
    /**
     * Обработчик добавления файлов. Фильтрует файлы которые уже есть в состоянии.
     *
     * @param event Файлы, которые нужно добавить.
     */
    UploadToRecognizeDialogComponent.prototype.addFilesHandler = function (event) {
        var _this = this;
        var newFiles = event.filter(function (fileToAdd) { return _this._uploadState.filesWithMetaData.every(function (file) {
            return file.file.name !== fileToAdd.name
                || file.file.size !== fileToAdd.size
                || file.file.lastModified !== fileToAdd.lastModified;
        }); });
        if (newFiles.length) {
            this.add.emit(newFiles);
        }
    };
    /**
     * Закрытие дилога.
     */
    UploadToRecognizeDialogComponent.prototype.close = function () {
        if (!this.uploadState.loading) {
            this.dialogRef.close();
        }
    };
    return UploadToRecognizeDialogComponent;
}());
export { UploadToRecognizeDialogComponent };
export function isUploadToRecognizeDialog(val) {
    return val.constructor.name === UploadToRecognizeDialogComponent.name;
}
