var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ofType } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { of } from "rxjs";
import { withLatestFrom } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { map } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { DlgService } from "src/app/common/services/dlg.service";
import { BroadcastMessagesService } from "src/app/root/services/broadcast-messages.service";
import { broadcastMessageDlgActions } from "src/app/root/store/actions/broadcast-message-dlg.action";
import { BroadcastMessagesDlgActionType } from "src/app/root/store/actions/broadcast-message-dlg.action";
import { broadcastMessagesDlgSelectors } from "src/app/root/store/selectors/broadcast-message-dlg.selector";
/**
 * Side-эффекты на события, связанные с диалогом для сообщений пользователю.
 */
var BroadcastMessagesDlgEffect = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор side-эффектов на события, связанные с диалогом сообщений пользователю.
     *
     * @param _store Состояние приложения.
     * @param dlgService Сервис для вызова диалога для отображения сообщений пользователю.
     * @param _actions$ Поток событий, происходящих в системе.
     * @param translateService Сервис перевода текста.
     * @param broadcastMessagesService Сервис отправки ответов на сообщения пользователей.
     */
    function BroadcastMessagesDlgEffect(_store, _actions$, dlgService, translateService, broadcastMessagesService) {
        var _this = this;
        this._store = _store;
        this._actions$ = _actions$;
        this.openDialog$ = this._actions$
            .pipe(ofType(BroadcastMessagesDlgActionType.OPEN), tap(function () {
            if (_this._openedDlg) {
                _this._openedDlg.close();
            }
            _this._openedDlg = _this._dlgService.openBroadcastMessagesDialog();
        }));
        /**
         * Обработка события с требованием закрыть диалог.
         */
        this.closeDialog$ = this._actions$
            .pipe(ofType(BroadcastMessagesDlgActionType.CLOSE), filter(function () { return !!_this._openedDlg; }), tap(function () { return _this._openedDlg.close(); }));
        /**
         * Обработка событий отправки ответа пользователя на сообщения.
         */
        this.sendResponse$ = this._actions$
            .pipe(ofType(BroadcastMessagesDlgActionType.SEND_RESPONSE), withLatestFrom(this._store.select(broadcastMessagesDlgSelectors.currentMessage)), switchMap(function (_a) {
            var props = _a[0], message = _a[1];
            return _this._broadcastMessagesService.sendResponse(message.id, props).pipe(map(function () { return broadcastMessageDlgActions.sendResponseSuccess(); }), catchError(function (error) {
                return of(broadcastMessageDlgActions.sendResponseFail({ error: error }));
            }));
        }));
        /**
         * Обработка событий успешной отправки ответа пользователя на сообщения.
         */
        this.sendResponseSuccess$ = this._actions$
            .pipe(ofType(BroadcastMessagesDlgActionType.SEND_RESPONSE_SUCCESS), withLatestFrom(this._store.select(broadcastMessagesDlgSelectors.messageCount)), filter(function () { return !broadcastMessagesDlgSelectors.messageCount; }), map(function () { return broadcastMessageDlgActions.close; }));
        /**
         * Обработка событий неудачной отправки ответа пользователя на сообщения.
         */
        this.sendResponseFail$ = this._actions$
            .pipe(ofType(BroadcastMessagesDlgActionType.SEND_RESPONSE_FAIL), tap(function (error) {
            var errorMessage = error.error.errorMessage || "Unknown error";
            var tryReloadingMessage = _this._translateService
                .instant("broadcastMessages.sendResponse.tryReloadingPage");
            _this._dlgService.openSimpleDlg({
                headerKey: "common.fail",
                text: of(errorMessage + " " + tryReloadingMessage),
            });
        }));
        this._dlgService = dlgService;
        this._translateService = translateService;
        this._broadcastMessagesService = broadcastMessagesService;
    }
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], BroadcastMessagesDlgEffect.prototype, "openDialog$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], BroadcastMessagesDlgEffect.prototype, "closeDialog$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], BroadcastMessagesDlgEffect.prototype, "sendResponse$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], BroadcastMessagesDlgEffect.prototype, "sendResponseSuccess$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], BroadcastMessagesDlgEffect.prototype, "sendResponseFail$", void 0);
    return BroadcastMessagesDlgEffect;
}());
export { BroadcastMessagesDlgEffect };
