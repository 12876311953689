import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { ErrorResponseProps } from "src/app/root/store/actions/props/error-response.action-props";
import { ValueProps } from "src/app/root/store/actions/props/value-props";

/**
 * Типы событий, связанных с заявкой на покупку страниц.
 */
export enum PurchaseDialogActionType {
    OPEN_DIALOG = "[Purchase dialog] Open purchase request dialog",
    REQUEST_PURCHASE = "[Purchase dialog] Send request for purchase",
    REQUEST_SUCCESS = "[Purchase dialog] Request purchase Success",
    REQUEST_FAIL = "[Purchase dialog] Request purchase Fail",
}

/**
 * Событие, требующее открытия диалога об отправке запроса на покупку страниц.
 */
const open = createAction(PurchaseDialogActionType.OPEN_DIALOG);

/**
 * Событие отправки запроса на покупку страниц.
 */
const sendRequestPurchase = createAction(
    PurchaseDialogActionType.REQUEST_PURCHASE,
    props<ValueProps<string>>()
);

/**
 * Событие, требующее обработки успешного результата отправки запроса на покупку страниц.
 */
const sendRequestSuccess = createAction(PurchaseDialogActionType.REQUEST_SUCCESS);

/**
 * Событие, требующее обработки неудачного результата отправки запроса на покупку страниц.
 */
const sendRequestFail = createAction(
    PurchaseDialogActionType.REQUEST_FAIL,
    props<ErrorResponseProps<void>>(),
);

/**
 * Тип, объединяющий все события, связанные с диалогом запроса на покупку страниц.
 */
export type PurchaseDialogAction = ReturnType<typeof open>
    | ReturnType<typeof sendRequestPurchase>
    | ReturnType<typeof sendRequestSuccess>
    | ReturnType<typeof sendRequestFail>
    ;

/**
 * Все события, связанные с диалогом запроса на покупку страниц.
 */
export const purchaseDialogActions = {
    open,
    sendRequestPurchase,
    sendRequestSuccess,
    sendRequestFail,
};

