import { MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
/**
 * Компонента с деталями произошедших ошибок на сервере.
 */
var ServerSideErrorDetailsComponent = /** @class */ (function () {
    //endregion
    //region Ctor
    function ServerSideErrorDetailsComponent(dialogRef, data, translate) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.translate = translate;
        if (data && data.errors) {
            this.errors = data.errors;
        }
    }
    //endregion
    //region Hooks
    ServerSideErrorDetailsComponent.prototype.ngOnInit = function () { };
    Object.defineProperty(ServerSideErrorDetailsComponent.prototype, "errorText$", {
        //endregion
        //region Getters and Setters
        /**
         * Возвращает текст ошибки в зависимости от кол-ва произошедших ошибок.
         */
        get: function () {
            if (this.errors.length > 1) {
                return this.translate.get('errorDetails.serverSideErrorsOccurred');
            }
            else {
                return this.translate.get('errorDetails.serverSideErrorOccurred');
            }
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Events
    /**
     * Обработчик уведомления, что была нажата кнопка перехода на главную страницу.
     */
    ServerSideErrorDetailsComponent.prototype.goToRootHandler = function () {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    };
    return ServerSideErrorDetailsComponent;
}());
export { ServerSideErrorDetailsComponent };
