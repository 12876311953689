var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { requestState } from "src/app/common/models/request-state";
import { ExternalLocationsActionType } from "src/app/spaces/modules/document-matching/store/actions/external-locations.action";
/**
 * Начальное состояние обработчика событий, связанных с состоянием складов из внешней системы.
 */
var initialState = {
    /**
     * Начальное состояние запроса складов с сервера.
     */
    externalLocationRequest: requestState.initial(),
};
/**
 * Обработчик событий, связанных с состоянием складов из внешней системы.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 *
 * @return Новое состояние.
 */
export function externalLocationsReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        case ExternalLocationsActionType.INIT: {
            result = __assign({}, state, { externalLocationRequest: requestState.initial() });
            break;
        }
        case ExternalLocationsActionType.LOAD: {
            result = __assign({}, state, { externalLocationRequest: requestState.pending() });
            break;
        }
        case ExternalLocationsActionType.LOAD_SUCCESS: {
            result = __assign({}, state, { externalLocationRequest: requestState.success(action.value) });
            break;
        }
        case ExternalLocationsActionType.LOAD_FAIL: {
            result = __assign({}, state, { externalLocationRequest: requestState.fail(action.error) });
            break;
        }
    }
    return result;
}
