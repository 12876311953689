/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mobile-document-upload-file-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../mobile-document-upload-file-row/mobile-document-upload-file-row.component.ngfactory";
import * as i3 from "../mobile-document-upload-file-row/mobile-document-upload-file-row.component";
import * as i4 from "@angular/common";
import * as i5 from "./mobile-document-upload-file-list.component";
var styles_MobileDocumentUploadFileListComponent = [i0.styles];
var RenderType_MobileDocumentUploadFileListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MobileDocumentUploadFileListComponent, data: {} });
export { RenderType_MobileDocumentUploadFileListComponent as RenderType_MobileDocumentUploadFileListComponent };
function View_MobileDocumentUploadFileListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mobile-document-upload-file-row", [], null, [[null, "remove"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("remove" === en)) {
        var pd_0 = (_co.removeFile($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MobileDocumentUploadFileRowComponent_0, i2.RenderType_MobileDocumentUploadFileRowComponent)), i1.ɵdid(1, 49152, null, 0, i3.MobileDocumentUploadFileRowComponent, [], { loading: [0, "loading"], error: [1, "error"], fileWithMetaData: [2, "fileWithMetaData"] }, { remove: "remove" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; var currVal_1 = _co.error; var currVal_2 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_MobileDocumentUploadFileListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "mat-document-upload-file-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileDocumentUploadFileListComponent_1)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.uploadState.filesWithMetaData; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MobileDocumentUploadFileListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mobile-document-upload-file-list", [], null, null, null, View_MobileDocumentUploadFileListComponent_0, RenderType_MobileDocumentUploadFileListComponent)), i1.ɵdid(1, 49152, null, 0, i5.MobileDocumentUploadFileListComponent, [], null, null)], null, null); }
var MobileDocumentUploadFileListComponentNgFactory = i1.ɵccf("mobile-document-upload-file-list", i5.MobileDocumentUploadFileListComponent, View_MobileDocumentUploadFileListComponent_Host_0, { uploadState: "uploadState", loading: "loading", error: "error" }, { remove: "remove" }, []);
export { MobileDocumentUploadFileListComponentNgFactory as MobileDocumentUploadFileListComponentNgFactory };
