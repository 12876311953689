import { requestState } from "src/app/common/models/request-state";
import { ExternalLocationsActionType } from "src/app/spaces/modules/document-matching/store/actions/external-locations.action";
import { ExternalLocationsAction } from "src/app/spaces/modules/document-matching/store/actions/external-locations.action";
import { ExternalLocationsState } from "src/app/spaces/modules/document-matching/store/states/external-locations.state";

/**
 * Начальное состояние обработчика событий, связанных с состоянием складов из внешней системы.
 */
const initialState: ExternalLocationsState = {

    /**
     * Начальное состояние запроса складов с сервера.
     */
    externalLocationRequest: requestState.initial(),
};

/**
 * Обработчик событий, связанных с состоянием складов из внешней системы.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 *
 * @return Новое состояние.
 */
export function externalLocationsReducer(
    state: ExternalLocationsState = initialState,
    action: ExternalLocationsAction,
): ExternalLocationsState {
    let result: ExternalLocationsState = state;

    switch (action.type) {

        case ExternalLocationsActionType.INIT: {

            result = {
                ...state,
                externalLocationRequest: requestState.initial(),
            };

            break;
        }
        case ExternalLocationsActionType.LOAD: {

            result = {
                ...state,
                externalLocationRequest: requestState.pending(),
            };

            break;
        }
        case ExternalLocationsActionType.LOAD_SUCCESS: {

            result = {
                ...state,
                externalLocationRequest: requestState.success(action.value),
            };

            break;
        }
        case ExternalLocationsActionType.LOAD_FAIL: {

            result = {
                ...state,
                externalLocationRequest: requestState.fail(action.error),
            };

            break;
        }
    }

    return result;
}
