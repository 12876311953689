/**
 * Тип отображения интеграции.
 */
export enum IntegrationItemType {
    //region Enum values

    /**
     * Как токен.
     */
    TOKEN = "TOKEN",

    /**
     * Как ссылку.
     */
    LINK = "LINK",

    /**
     * Как oAuth 2.0 кнопку.
     */
    OAUTH2 = "OAUTH2",

    /**
     * Как кнопку.
     */
    BUTTON = "BUTTON",

    //endregion
}
