import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { Space } from "src/app/common/models/space";
import { ValueProps } from "src/app/root/store/actions/props/value-props";

/**
 * Типы событий, связанные с установкой пространства документов для мобильной версии.
 */
export enum SelectedSpaceForMobileActionType {
    //region Enum values

    /**
     * Тип события, требующий установки пространства документов.
     */
    SET = "[Selected space for mobile] Set space",

    //endregion
}

/**
 * Событие, требующее установки пространства документов.
 */
const set = createAction(
    SelectedSpaceForMobileActionType.SET,
    props<ValueProps<Space>>(),
);

/**
 * Тип, объединяющий события, связанные с установкой пространства документов для мобильной версии.
 */
export type SelectedSpaceForMobileAction = ReturnType<typeof set>;

/**
 * Все события, связанные с установкой пространства документов для мобильной версии.
 */
export const SelectedSpaceForMobileActions = {
    set,
};
