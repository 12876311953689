<div class="broadcast-message-dlg">

    <button
        class="mat-dialog__cross-close-btn"
        mat-flat-button
        (click)="closeDialog()"
        title='{{ "dialogs.close" | translate }}'
    >
        <mat-icon>close</mat-icon>
    </button>
    <div mat-dialog-title>
        {{currentMessage?.title}}
    </div>

    <broadcast-message-viewer
        class="broadcast-message-dlg__broadcast-message-viewer"
        *ngIf="!!currentMessage"
        [broadcastMessage]="currentMessage"
        [lang]="lang$ | async"
    >
    </broadcast-message-viewer>

    <div class="broadcast-message-dlg__bottom-row-buttons" [formGroup]="formGroup">

        <div class="broadcast-message-dlg__arrow-buttons">

            <button mat-icon-button (click)="selectPreviousMessage()">
                <mat-icon>chevron_left</mat-icon>
            </button>
            <div class="broadcast-message-dlg__message_counter">
                {{(messageIndex$ | async) + 1}}/{{(messagesCount$ | async)}}
            </div>
            <button mat-icon-button (click)="selectNextMessage()">
                <mat-icon>chevron_right</mat-icon>
            </button>

        </div>

        <div class="broadcast-message-dlg__response-buttons" *ngIf="currentMessage?.type === 'POLL'">

            <button
                mat-raised-button class="broadcast-message-dlg__button"
                (click)="closeCurrentMessage()"
            >
                {{'broadcastMessages.buttons.remindLater' | translate}}
            </button>

            <button
                mat-raised-button color="warn"
                class="broadcast-message-dlg__button"
                (click)="sendNotInterestedResponse()"
            >
                {{'broadcastMessages.buttons.notInterested' | translate}}
            </button>

            <button
                mat-raised-button color="primary"
                class="broadcast-message-dlg__button"
                (click)="sendInterestedResponse()"
            >
                {{'broadcastMessages.buttons.interested' | translate}}
            </button>

        </div>

        <mat-checkbox *ngIf="currentMessage?.type === 'ALERT'"
                      [formControlName]="'dontShowAgain'"
                      class="broadcast-message-dlg__checkbox"
                      color="primary"
                      (change)="dontShowAgainFlagToggle()"
        >
            {{ "broadcastMessages.buttons.doNotShowAgain" | translate }}
        </mat-checkbox>

        <button *ngIf="currentMessage?.type === 'ALERT'"
            class="broadcast-message-dlg__button"
            mat-raised-button
            color="primary"
            (click)="closeCurrentMessage()"
        >
            {{ 'broadcastMessages.buttons.close' | translate }}
        </button>

    </div>

</div>
