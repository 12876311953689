import { createFeatureSelector } from "@ngrx/store";
import { ActionReducerMap } from "@ngrx/store";
import { externalDocumentTypesReducer } from "src/app/spaces/modules/document-matching/store/reducers/external-document-types.reducer";
import { externalItemGroupsReducer } from "src/app/spaces/modules/document-matching/store/reducers/external-item-groups.reducer";
import { externalLocationsReducer } from "src/app/spaces/modules/document-matching/store/reducers/external-locations.reducer";
import { externalUnitsReducer } from "src/app/spaces/modules/document-matching/store/reducers/external-units.reducer";
import { integrationReducer } from "src/app/spaces/modules/document-matching/store/reducers/integration.reducer";
import { intermediateReducer } from "src/app/spaces/modules/document-matching/store/reducers/intermediate.reducer";
import { matchSettingsReducer } from "src/app/spaces/modules/document-matching/store/reducers/match-settings.reducer";
import { matchingReducer } from "src/app/spaces/modules/document-matching/store/reducers/matching.reducer";
import { newExternalElementsReducer } from "src/app/spaces/modules/document-matching/store/reducers/new-external-elements.reducer";
import { supplyTypesReducer } from "src/app/spaces/modules/document-matching/store/reducers/supply-types.reducer";
import { DocumentMatchingPageState } from "src/app/spaces/modules/document-matching/store/states/document-matching-page.state";
import { matchingCompaniesReducer } from "src/app/spaces/modules/document-matching/store/reducers/matching-companies.reducer";
import { externalTallyCompanyGSTReducer } from "src/app/spaces/modules/document-matching/store/reducers/external-tally-company-gsts.reducer";
import { externalCostCentresReducer } from "src/app/spaces/modules/document-matching/store/reducers/external-cost-centres.reducer";
import { entityExternalDocumentTypesReducer } from "src/app/spaces/modules/document-matching/store/reducers/entity-external-document-types.reducer";
import { externalLedgerTypesReducer } from "src/app/spaces/modules/document-matching/store/reducers/external-ledger-types.reducer";
import { pendingExternalElementsReducer } from "src/app/spaces/modules/document-matching/store/reducers/pending-external-elements.reducer";

/**
 * Обработчики состояний модуля сопоставления документов.
 */
export const reducers: ActionReducerMap<DocumentMatchingPageState> = {

    matchingState: matchingReducer,
    matchSettingsState: matchSettingsReducer,
    externalUnitsState: externalUnitsReducer,
    externalItemGroupsState: externalItemGroupsReducer,
    externalLocationsState: externalLocationsReducer,
    externalCostCentresState: externalCostCentresReducer,
    externalTallyCompanyGSTsState: externalTallyCompanyGSTReducer,
    externalDocumentTypesState: externalDocumentTypesReducer,
    entityExternalDocumentTypesState: entityExternalDocumentTypesReducer,
    externalLedgerTypesState: externalLedgerTypesReducer,
    supplyTypesState: supplyTypesReducer,
    integrationState: integrationReducer,
    intermediateState: intermediateReducer,
    newExternalElementsState: newExternalElementsReducer,
    pendingExternalElementsState: pendingExternalElementsReducer,
    matchingCompaniesState: matchingCompaniesReducer,
};

/**
 * Селектор состояния модуля сопоставления документов.
 */
export const documentMatchingModuleStateSelector = createFeatureSelector<DocumentMatchingPageState>(
    "documentMatchingModule"
);
