import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { TranslateService } from "@ngx-translate/core";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ExpiryNotificationType } from "src/app/common/models/expiry-notification-type";

import { InfoMenuItem } from "src/app/common/models/info-menu-item";
import { UtilsService } from "../../services/utils.service";

/**
 * ID элементов меню.
 */
const MenuItemId = {

    /**
     * Купить.
     */
    BUY: "buy-credits",
};

/**
 * Компонент выпадающего меню баланса пространства документов.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "balance-info-menu",
    styleUrls: ["balance-info-menu.component.scss"],
    templateUrl: "balance-info-menu.component.html"
})
export class BalanceInfoMenuComponent {
    //region Fields

    /**
     * Входящие данные - кол-во доступных кредитов.
     */
    @Input()
    restCreditCount: number| string;

    /**
     * Дата сгорания баланса страниц клиента.
     */
    @Input()
    expirationDate: string;

    /**
     * Иностранный клиент?
     */
    @Input()
    foreign: boolean;

    /**
     * Показывать плашку с уведомлением об истечении баланса/подписки?
     */
    @Input()
    showUserNotification: boolean;

    /**
     * Тип уведомления клиента об истечении.
     */
    @Input()
    expiryNotificationType: ExpiryNotificationType;

    //endregion
    //region Output

    /**
     * Исходящее событие - требование перейти на страницу оплаты.
     */
    @Output()
    buy = new EventEmitter<void>();

    /**
     * Элементы меню выпадашки.
     */
    menuItems: InfoMenuItem[];
    
    //endregion
    //region Ctor

    constructor(
        private translate: TranslateService,
        private util: UtilsService
    ) { 

        // Конфигурация элементов меню.
        this.menuItems = []; 
        // TODO Вернуть, когда придёт время.
        // [
        //     {
        //         id: MenuItemId.BUY,
        //         icon: 'payment',
        //         messageKey: 'header.buy.credits',
        //         green: true
        //     }
        // ];
    }

    //endregion
    //region Getters and Setters

    /**
     * Возвращает строковое представление баланса.
     */
    get balance$(): Observable<string> {

        const i18nKeyList = [
            "header.credits.word",
            "header.credits.endings",
            "header.credits.unlimited",
            "header.credits.until"
        ];

        return this.translate.get(i18nKeyList)
            .pipe(
                map((translate): string => {

                    let value: string;

                    if ( typeof this.restCreditCount === "string") {

                        value = this.restCreditCount;
                    }
                    else {

                        value = this.restCreditCount + " " + this.util.getWordWithEnding(
                            translate["header.credits.word"],
                            translate["header.credits.endings"],
                            this.restCreditCount
                        );
                    }

                    let date: string;
                    if (this.foreign) {

                        date = this.util.getISODate(this.expirationDate) || "-";
                    }
                    else {

                        date = this.util.formatDate(this.expirationDate, this.translate.getDefaultLang());
                    }

                    return value +  " " + translate["header.credits.until"] + " " + date;
                })
            );
    }

    /**
     * Возвращает текст тултипа о том, что истекает подписка или баланс.
     */
    getExpiryNotificationTooltipText(): string {

        const managerTextEnding = this.translate.instant("header.notification.manager");
        let result = "";

        if (this.expiryNotificationType === "BALANCE") {

            result = this.translate.instant("header.notification.balanceExpiring") + " " + managerTextEnding;
        }
        else if (this.expiryNotificationType === "SUBSCRIPTION" || !this.expiryNotificationType) {

            result = this.translate.instant("header.notification.subscriptionExpiring") + " " + managerTextEnding;
        }

        return result;
    }

    //endregion
    //region Events

    /**
     * Обработчик клика по элементу меню.
     */
    menuItemClickHandler(menuItemId: string) {

        // Бросаем нужное событие выше в зависимости от выбранного элемента меню.
        switch (menuItemId) {

            case MenuItemId.BUY: {
                this.buy.emit();
            }
        }
    }

    //endregion
}
