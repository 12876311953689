<mat-form-field class="mobile-space-select">
    <mat-label>{{ selectorLabel | translate }}</mat-label>
    <select class="mobile-space-select__selector"
        matNativeControl
        [formControl]="valueControl"
    >
        <option *ngIf="spaces.length > 1" [ngValue]="" selected></option>
        <option *ngFor="let space of spaces" [ngValue]="space">
            {{ getSpaceNameAndBalance(space) }}
        </option>
    </select>
</mat-form-field>
