import {createSelector} from "@ngrx/store";
import {uploadToRecognizeSelector} from '../../reducers';
import {getLoadedState, getLoadingState, getRecognitionTaskCreationError} from "../../reducers/recognize";

export const taskForUploadLoadedSelector = createSelector(uploadToRecognizeSelector, getLoadedState);
export const taskForUploadLoadingSelector = createSelector(uploadToRecognizeSelector, getLoadingState);

/**
 * Селектор ошибки, с которой завершилось создание задачи на распознавание.
 */
export const recognitionTaskCreationErrorSelector = createSelector(
    uploadToRecognizeSelector, 
    getRecognitionTaskCreationError
);
