import {UploadToRecognizeEffects} from "./upload-to-recognize.effects";
import {RecognitionTasksEffects} from "./recognition-tasks.effects";

export const effects = [
    UploadToRecognizeEffects,
    RecognitionTasksEffects
];

export * from "./upload-to-recognize.effects"
export * from "./recognition-tasks.effects"
