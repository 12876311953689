import { UploadToRecognizeDialogComponent } from "./upload-to-recognize-dialog.component";
import { FileUploadZoneComponent } from "./components/file-upload-zone/file-upload-zone.component";
import { FileUploadIndicator } from "./components/file-upload-indicator/file-upload-indicator.component";

export const components = [
    UploadToRecognizeDialogComponent,
    FileUploadZoneComponent,
    FileUploadIndicator,
];

export * from './upload-to-recognize-dialog.component'
export * from './components/file-upload-zone/file-upload-zone.component'
export * from './components/file-upload-indicator/file-upload-indicator.component'
