import { Component } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { Inject } from "@angular/core";
import { Input } from "@angular/core";
import { Output } from "@angular/core";
import { OnInit } from '@angular/core';
import { OnDestroy } from '@angular/core';

import { FormControl } from '@angular/forms';

import { MAT_DIALOG_DATA } from "@angular/material";
import { MatDialogRef } from "@angular/material";

import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';

import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UploadToRecognizeState } from "rootStore";
import { RootState } from 'rootStore';
import { currentUserPermissionsSelector } from 'rootStore';

import { UtilsService } from "../../services";
import { UploadBankStatementDialogComponent } from "src/app/common/components/upload-bank-statement-dialog/upload-bank-statement-dialog.component";

/**
 * Входные данные в диалог.
 */
export interface DialogData {

    uploadState: UploadToRecognizeState;
}

/**
 * Компонент диалога для загрузки файлов на распознавание.
 */
@Component({
    styleUrls: ['upload-to-recognize-dialog.component.scss'],
    templateUrl: 'upload-to-recognize-dialog.component.html'
})
export class UploadToRecognizeDialogComponent implements OnInit, OnDestroy {
    //region Inputs

    /**
     *  Входящие данные - состояние данных для создания задач на распознавание.
     */
    @Input()
    set uploadState(value: UploadToRecognizeState) {

        this._uploadState = value;

        this.forceOcr.setValue(value.forceOcrRecognition);
        this.forceProcessing.setValue(value.forceProcessing);
        this.forceQueue.setValue(value.forceQueue);
        this.comment.setValue(value.comment);
    }

    //endregion
    //region Outputs

    /**
     * Исходящее событие - создание задач на распознавание.
     */
    @Output()
    upload = new EventEmitter<UploadToRecognizeState>();

    /**
     * Исходящее событие - добвление файла на распознавание.
     */
    @Output()
    add = new EventEmitter<File[]>();

    /**
     * Исходящее событие - удаление файла из задачи.
     */
    @Output()
    remove = new EventEmitter<File>();

    /**
     * Исходящее событие - изменение флага необходимости выполнения OCR.
     */
    @Output()
    toggleForceOcr = new EventEmitter<boolean>();

    /**
     * Исходящее событие - изменение флага необходимости выполнения парсинга.
     */
    @Output()
    toggleForceProcessing = new EventEmitter<boolean>();

    /**
     * Исходящее событие - изменение флага необходимости отправки в очередь.
     */
    @Output()
    toggleForceQueue = new EventEmitter<boolean>();

    /**
     * Исходящее событие - изменение комментария к документам на распознования.
     */
    @Output()
    commentEmitter = new EventEmitter<string>();

    //endregion
    //region Public fields

    /**
     * Чек-бокс принудительного выполнения OCR.
     */
    forceOcr: FormControl = new FormControl(false);

    /**
     * Чек-бокс принудительного выполнения парсинга.
     */
    forceProcessing: FormControl = new FormControl(false);

    /**
     * Чек-бокс принудительной отправки в очередь.
     */
    forceQueue: FormControl = new FormControl(false);

    /**
     * Комментарий к документам на распознавания.
     */
    comment: FormControl = new FormControl("");

    /**
     * Права текущего пользователя досутпные ему независимо от пространства документов.
     */
    permissions$: Observable<{ [key: string]: boolean }>;

    //endregion
    //region Private fields

    /**
     * Состояние приложения.
     */
    private _store: Store<RootState>;

    /**
     * Состояние данных для создания задач на распознавание.
     *
     * @private
     */
    private _uploadState: UploadToRecognizeState;

    /**
     * Переключатель, чтобы при выполнении onDestroy произошли отписки от всех подписок.
     *
     * @private
     */
    private _destroyed: Subject<void> = new Subject();

    //endregion
    //region Ctor

    constructor(
        store: Store<RootState>,
        public dialogRef: MatDialogRef<UploadToRecognizeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public utils: UtilsService
    ) {
        this._store = store;
    }

    //endregion
    //region Hooks

    ngOnInit(): void {

        // Следим за правами пользователя.
        this.permissions$ = this._store.pipe(
            select(currentUserPermissionsSelector)
        );

        // Подписываемся на изменение флага принудительного выполнения OCR.
        this.forceOcr.valueChanges
            .pipe(
                takeUntil(this._destroyed)
            )
            .subscribe((value: boolean) => {

                if (this.uploadState.forceOcrRecognition !== value) {

                    this.toggleForceOcr.emit(value);
                }
            });

        // Подписываемся на изменение флага принудительного выполнения парсинга.
        this.forceProcessing.valueChanges
            .pipe(
                takeUntil(this._destroyed)
            )
            .subscribe((value: boolean) => {

                if (this.uploadState.forceProcessing !== value) {

                    this.toggleForceProcessing.emit(value);
                }
            });

        // Подписываемся на изменение флага принудительной отправки в очередь.
        this.forceQueue.valueChanges
            .pipe(
                takeUntil(this._destroyed)
            )
            .subscribe((value: boolean) => {

                if (this.uploadState.forceQueue !== value) {

                    this.toggleForceQueue.emit(value);
                }
            });

        // Подписываемся на изменение комментария.
        this.comment.valueChanges
            .pipe(
                takeUntil(this._destroyed)
            )
            .subscribe((value: string) => {

                if (this.uploadState.comment !== value) {

                    this.commentEmitter.emit(value);
                }
            });
    }

    ngOnDestroy(): void {

        this._destroyed.next();
        this._destroyed.complete();
    }

    //endregion
    //region Getters and Setters

    /**
     * Состояние данных для создания задач на распознавание.
     */
    get uploadState(): UploadToRecognizeState {

        return this._uploadState;
    }

    //endregion
    //region Public

    /**
     * Обработчик добавления файлов. Фильтрует файлы которые уже есть в состоянии.
     *
     * @param event Файлы, которые нужно добавить.
     */
    addFilesHandler(event: File[]) {

        let newFiles = event.filter(fileToAdd => this._uploadState.filesWithMetaData.every(file =>
            file.file.name !== fileToAdd.name
            || file.file.size !== fileToAdd.size
            || file.file.lastModified !== fileToAdd.lastModified
        ));

        if (newFiles.length) {

            this.add.emit(newFiles);
        }
    }

    /**
     * Закрытие дилога.
     */
    close(): void {

        if (!this.uploadState.loading) {

            this.dialogRef.close();
        }
    }

    //endregion
}

export function isUploadToRecognizeDialog(
    val: UploadToRecognizeDialogComponent | UploadBankStatementDialogComponent
): val is UploadToRecognizeDialogComponent {

    return val.constructor.name === UploadToRecognizeDialogComponent.name;
}
