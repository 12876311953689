import { requestState } from "src/app/common/models/request-state";
import { ExternalTallyCompanyGSTsState } from "src/app/spaces/modules/document-matching/store/states/external-tally-company-gsts.state";
import { ExternalTallyCompanyGSTAction } from "src/app/spaces/modules/document-matching/store/actions/external-tally-company-gsts.action";
import { ExternalTallyCompanyGSTActionType } from "src/app/spaces/modules/document-matching/store/actions/external-tally-company-gsts.action";

/**
 * Начальное состояние обработчика событий, связанных с состоянием филиалов из Tally.
 */
const initialState: ExternalTallyCompanyGSTsState = {

    /**
     * Начальное состояние запроса филиалов с сервера.
     */
    externalTallyCompanyGSTRequest: requestState.initial(),
};

/**
 * Обработчик событий, связанных с состоянием филиалов из Tally.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 *
 * @return Новое состояние.
 */
export function externalTallyCompanyGSTReducer(
    state: ExternalTallyCompanyGSTsState = initialState,
    action: ExternalTallyCompanyGSTAction,
): ExternalTallyCompanyGSTsState {
    let result: ExternalTallyCompanyGSTsState = state;

    switch (action.type) {

        case ExternalTallyCompanyGSTActionType.INIT: {

            result = {
                ...state,
                externalTallyCompanyGSTRequest: requestState.initial(),
            };

            break;
        }
        case ExternalTallyCompanyGSTActionType.LOAD: {

            result = {
                ...state,
                externalTallyCompanyGSTRequest: requestState.pending(),
            };

            break;
        }
        case ExternalTallyCompanyGSTActionType.LOAD_SUCCESS: {

            result = {
                ...state,
                externalTallyCompanyGSTRequest: requestState.success(action.value),
            };

            break;
        }
        case ExternalTallyCompanyGSTActionType.LOAD_FAIL: {

            result = {
                ...state,
                externalTallyCompanyGSTRequest: requestState.fail(action.error),
            };

            break;
        }
    }

    return result;
}
