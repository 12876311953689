import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Store } from "@ngrx/store";
import { CurrentUserConfirmPhoneSuccessAction } from "rootStore";
import { RootState } from "rootStore";
import { Observable } from "rxjs";
import { of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { ApiResponse } from "../../models";
import { UserService } from "../../services";
import { PhoneConfirmDialogConfirmCodeAction } from "../actions";
import { PhoneConfirmDlgActionType } from "../actions";
import { PhoneConfirmDialogSendSmsAction } from "../actions";
import { PhoneConfirmDialogSendSmsSuccessAction } from "../actions";
import { PhoneConfirmDialogSendSmsFailAction } from "../actions";
import { PhoneConfirmDialogConfirmCodeSuccessAction } from "../actions";
import { PhoneConfirmDialogConfirmCodeFailAction } from "../actions";

/**
 * Side-эффекты на события, связанные с диалогом подтверждения телефона.
 */
@Injectable()
export class PhoneConfirmDlgEffects {

    //region Ctor

    /**
     * Контруктор эффектов связанных с диалогом подтверждения телефона.
     *
     * @param _actions$ Поток событий, происходящих в системе.
     * @param _userService Сервис работы с данными пользователя.
     * @param _store Хранилище.
     */
    constructor(
        private _actions$: Actions,
        private _userService: UserService,
        private _store: Store<RootState>
    ) { }

    //endregion
    //region Public

    /**
     * Обработка события отправки СМС с кодом подтверждения.
     */
    @Effect()
    sendSms$ = this._actions$
        .pipe(
            ofType(PhoneConfirmDlgActionType.SEND_SMS),
            switchMap((action: PhoneConfirmDialogSendSmsAction) =>

                this._userService.sendConfirmSms(action.payload)
                    .pipe(
                        map(() => new PhoneConfirmDialogSendSmsSuccessAction()),
                        catchError((response: ApiResponse) => of(new PhoneConfirmDialogSendSmsFailAction(response)))
                    )
            )
        );

    /**
     * Обработка события подтверждения телефона отправкой кода на сервер.
     */
    @Effect()
    confirmCode$ = this._actions$
        .pipe(
            ofType(PhoneConfirmDlgActionType.CONFIRM_CODE),
            switchMap((action: PhoneConfirmDialogConfirmCodeAction) =>

                this._userService.confirmPhoneByCode(action.payload.code)
                    .pipe(
                        map(() => new PhoneConfirmDialogConfirmCodeSuccessAction({...action.payload, code: null})),
                        catchError((response: ApiResponse) => of(new PhoneConfirmDialogConfirmCodeFailAction(response)))
                    )
            )
        );

    /**
     * Обработка события успешного подтверждения телефона отправкой кода на сервер.
     */
    @Effect()
    confirmCodeSuccess$ = this._actions$
        .pipe(
            ofType(PhoneConfirmDlgActionType.CONFIRM_CODE_SUCCESS),
            switchMap(
                (action: PhoneConfirmDialogConfirmCodeSuccessAction) : Observable<Action> =>
                    of(new CurrentUserConfirmPhoneSuccessAction(action.payload))
            ),
        )

    //endregion
}