<div class="mobile-upload-document" *ngIf="!(error$ | async)" [formGroup]="mobileFormGroup">
    <h3 *ngIf="!(loaded$ | async)" class="mobile-upload-document__space mat-h3">
        {{ "user.space.selector.option" | translate: { spaceName: (space$ | async).name, spaceBalance: (space$ | async).balance } }}
    </h3>
    <mobile-document-upload-file-button
        *ngIf="!(loaded$ | async)"
        class="mobile-upload-document__upload-button"
        [loading]="loading$ | async"
        [error]="error$ | async"
        (addFiles)="addFilesHandler($event)"
    >
    </mobile-document-upload-file-button>
    <mobile-document-upload-file-list
        *ngIf="!(loaded$ | async)"
        class="mobile-upload-document__files-list"
        [uploadState]="(uploadToRecognizeState$ | async)"
        [loading]="loading$ | async"
        [error]="error$ | async"
        (remove)="removeFileHandler($event)"
    >
    </mobile-document-upload-file-list>
    <div
        *ngIf="loaded$ | async"
        class="mobile-upload-document__success"
    >
        {{ "dialogs.recognitionTaskCreatedDialog.filesSentToRecognizingMobile" | translate }}
    </div>
    <mat-form-field
        *ngIf="(uploadToRecognizeState$ | async)
            && (uploadToRecognizeState$ | async).filesWithMetaData.length
            && !(uploadToRecognizeState$ | async).error
            && (uploadToRecognizeState$ | async).progress !== 100"
        class="mobile-upload-document__comment"
    >
         <input
             matInput
             [placeholder]="'common.upload-to-recognize-dialog.comment' | translate"
             formControlName="comment"
             maxlength="250"
         >
         <mat-hint>{{'common.upload-to-recognize-dialog.commentHint' | translate}}</mat-hint>
    </mat-form-field>
    <div class="mobile-upload-document__buttons">
        <button
            mat-raised-button
            color="warn"
            class="mobile-upload-document__button"
            [disabled]="loading$ | async"
            (click)="cancelDocumentUpload()"
        >
            {{ "button.cancel" | translate }}
        </button>

        <button
            *ngIf="!(loaded$ | async)"
            mat-raised-button
            color="primary"
            class="mobile-upload-document__button"
            [disabled]="(loading$ | async) || (uploadToRecognizeState$ | async).filesWithMetaData.length === 0"
            (click)="uploadToRecognize()"
        >
            {{ "button.upload" | translate }}
        </button>
        <button
            *ngIf="(loaded$ | async)"
            mat-raised-button
            color="primary"
            class="mobile-upload-document__button"
            [disabled]="loading$ | async"
            (click)="clearState()"
        >
            {{ "button.uploadMore" | translate }}
        </button>
    </div>
</div>
<error-details
    *ngIf="error$ | async"
    class="mobile-upload-document__error"
    [mobileVersion]="true"
    [errors]="[(error$ | async)]"
    [errorText]="(error$ | async).errorMessage"
    [hideCloseBtn]="true"
    (goToRoot)="clearState()"
>
</error-details>
<div *ngIf="loading$ | async" class="mobile-upload-document__spinner">
    <mat-spinner></mat-spinner>
</div>

