<div class="white-red-green-dlg"  [class.white-red-green-dlg_loading]="loading">
    <h2 class="mat-h2 white-red-green-dlg__header">{{ headerKey | translate:headerParams }}</h2>
    <p *ngIf="!loading && !error" class="white-red-green-dlg__text">{{ text | async }}</p>
    <div *ngIf="!loading && !!error" class="white-red-green-dlg__text">
        <p>{{ 'common.response.error.message' | translate }}</p>
        <p *ngIf="error.errorId">{{ 'common.response.error.id' | translate }} :<br>{{ error.errorId }}</p>
        <p *ngIf="error.errorCode">{{ 'common.response.error.code' | translate }} :<br>{{ error.errorCode }}</p>
    </div>
    <hr *ngIf="!loading" class="white-red-green-dlg__divider"/>
    <div *ngIf="!loading" class="white-red-green-dlg__buttons">
        <button
            mat-button
            matDialogClose
            (click)="whiteClickHandler()"
        >
            {{ whiteBtnKey | translate }}
        </button>
        <button
            mat-button
            color="warn"
            (click)="redClickHandler()"
        >
            {{ redBtnKey | translate }}
        </button>
        <button
            mat-button
            color="primary"
            (click)="greenClickHandler()"
        >
            {{ greenBtnKey | translate }}
        </button>
    </div>
    <mat-spinner [diameter]="64" class="white-red-green-dlg__loading" *ngIf="loading"></mat-spinner>
</div>