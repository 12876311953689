var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { PermissionItem } from "src/app/common/models/permission-item";
import { DocumentsService } from "src/app/common/services/documents.service";
import { DocumentsActionType } from "src/app/spaces/modules/documents-registry/store/actions/index";
/**
 * Начальное состояние документов.
 */
var initialState = {
    /**
     * Документы не загружаются.
     */
    loading: false,
    /**
     * Документы не загружены.
     */
    loaded: false,
    /**
     * Загрузка документов ошибкой не завершалась.
     */
    failed: false,
    /**
     * Все документы не загружены.
     */
    ended: false,
    /**
     * Документов нет.
     */
    documents: undefined,
    /**
     * Новых документов нет.
     */
    newDocuments: [],
    /**
     * Документы не догружаются.
     */
    infinityLoading: false,
    /**
     * Документы не догружены.
     */
    infinityLoaded: false,
    /**
     * Догрузка документов ошибкой не завершалась.
     */
    infinityFailed: false,
    /**
     * Удаление документов не завершилось ошибкой.
     */
    deletionError: null,
    /**
     * Последняя загруженная страница реестра документов.
     */
    lastLoadPage: 0,
    /**
     * Количество удаленных документов между загрузками документов.
     */
    deletedDocumentsCount: 0,
    /**
     * Список выбранных документов.
     */
    selectedDocuments: [],
    /**
     * Были выбраны все документы?
     */
    allDocumentsSelected: false,
    /**
     * Список документов на исключения из общего списка при массовых действиях.
     */
    excludedDocuments: [],
    /**
     * Фильтры отсутствуют.
     */
    filterState: null,
    /**
     * Фильтр поиска по строке отсутствует.
     */
    searchState: null,
    /**
     * Сортировка отсутствует.
     */
    sortState: null,
};
/**
 * Обработчик событий, связанных с документами.
 *
 * @param state Состояние документов.
 * @param action Событие произошедшее в системе.
 */
export function documentsReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        // Требование загрузить документы.
        case DocumentsActionType.LOAD: {
            return __assign({}, state, { documents: [], loading: true, loaded: false, failed: false, ended: false, infinityLoading: false, infinityLoaded: false, infinityFailed: false });
        }
        // Уведомление об успешной загрузке документов.
        case DocumentsActionType.LOAD_SUCCESS: {
            var documents_1 = action.payload;
            return __assign({}, state, { lastLoadPage: 1, deletedDocumentsCount: 0, loading: false, loaded: true, documents: documents_1, newDocuments: (state.newDocuments || []).filter(function (newDoc) { return documents_1.every(function (doc) { return doc.id !== newDoc.id; }); }) });
        }
        // Уведомление о неудачной попытке загрузки документов.
        case DocumentsActionType.LOAD_FAIL: {
            return __assign({}, state, { loading: false, failed: true, documents: undefined });
        }
        // Требование догрузить документы.
        case DocumentsActionType.INFINITY_LOAD: {
            return __assign({}, state, { infinityLoading: true, infinityLoaded: false, infinityFailed: false });
        }
        // Уведомление об успешной догрузке документов.
        case DocumentsActionType.INFINITY_LOAD_SUCCESS: {
            var curDocuments_1 = (state.documents || []);
            // Исключаем дублирование того, что уже загружено.
            var newDocuments = action.payload.filter(function (d) { return curDocuments_1.every(function (cd) { return cd.id !== d.id; }); });
            var documents = curDocuments_1.concat(newDocuments);
            var selectedDocuments = state.selectedDocuments;
            if (state.allDocumentsSelected) {
                selectedDocuments = (state.selectedDocuments || []).concat(newDocuments);
            }
            return __assign({}, state, { lastLoadPage: (state.lastLoadPage - Math.ceil(state.deletedDocumentsCount / DocumentsService.PAGE_SIZE) + 1), deletedDocumentsCount: 0, loaded: true, failed: false, infinityLoading: false, infinityLoaded: true, documents: documents,
                selectedDocuments: selectedDocuments });
        }
        // Уведомление о неудачной попытке догрузки документов.
        case DocumentsActionType.INFINITY_LOAD_FAIL: {
            return __assign({}, state, { infinityLoading: false, infinityFailed: true });
        }
        // Уведомление о полной загрузке документов.
        case DocumentsActionType.END_LOAD: {
            return __assign({}, state, { ended: true });
        }
        // Применение фильтров
        case DocumentsActionType.APPLY_FILTER: {
            return __assign({}, state, { lastLoadPage: 1, deletedDocumentsCount: 0, loading: false, loaded: true, filterState: action.payload, newDocuments: [] });
        }
        // Применение фильтров поиска
        case DocumentsActionType.APPLY_SEARCH_FILTER: {
            return __assign({}, state, { lastLoadPage: 1, deletedDocumentsCount: 0, loading: false, loaded: true, searchState: action.payload, newDocuments: [] });
        }
        // Применение сортировки
        case DocumentsActionType.APPLY_SORT: {
            return __assign({}, state, { lastLoadPage: 1, deletedDocumentsCount: 0, loading: false, loaded: true, sortState: action.payload });
        }
        // Требование обновления определнных документов
        case DocumentsActionType.UPDATE_DOCUMENTS: {
            var mapFn = function (item) { return updateDocument(item, action.payload); };
            var documents = state.documents.map(mapFn);
            return __assign({}, state, { documents: documents });
        }
        case DocumentsActionType.ADD_NEW_DOCUMENTS: {
            var existDocuments_1 = state.newDocuments.concat(state.documents);
            var trulyNewDocuments = (action.documents || [])
                .filter(function (newDocument) { return !existDocuments_1.some(function (existDocument) { return existDocument.id === newDocument.id; }); });
            return __assign({}, state, { newDocuments: trulyNewDocuments.concat(state.newDocuments) });
        }
        case DocumentsActionType.MARK_DOCUMENT: {
            if (state.allDocumentsSelected) {
                var excludedDocuments = state.excludedDocuments.filter(function (element) { return element.id !== action.document.id; });
                return __assign({}, state, { selectedDocuments: state.selectedDocuments.concat([action.document]), excludedDocuments: excludedDocuments });
            }
            return __assign({}, state, { selectedDocuments: state.selectedDocuments.concat([action.document]) });
        }
        case DocumentsActionType.MARK_ALL_DOCUMENTS: {
            return __assign({}, state, { selectedDocuments: action.payload.documents, allDocumentsSelected: true, excludedDocuments: [] });
        }
        case DocumentsActionType.UNMARK_ALL_DOCUMENTS: {
            return __assign({}, state, { selectedDocuments: [], allDocumentsSelected: false, excludedDocuments: [] });
        }
        case DocumentsActionType.UNMARK_DOCUMENT: {
            var selectedDocuments = state.selectedDocuments
                .filter(function (element) { return element.id !== action.document.id; });
            if (state.allDocumentsSelected) {
                return __assign({}, state, { selectedDocuments: selectedDocuments, excludedDocuments: state.excludedDocuments.concat([action.document]) });
            }
            return __assign({}, state, { selectedDocuments: selectedDocuments });
        }
        case DocumentsActionType.DOCUMENTS_MASS_ACTIONS_CLEAN_STATE: {
            return __assign({}, state, { selectedDocuments: [], excludedDocuments: [], allDocumentsSelected: false });
        }
        // Требование удаления перемещенных документов.
        case DocumentsActionType.DELETE_MOVED_DOCUMENTS: {
            var documents = state.documents || [];
            var newDocuments = state.newDocuments || [];
            var adminView = action.payload.initiator.permissions
                .some(function (permission) { return permission === PermissionItem.MOVE_DOCUMENTS.value; });
            if (!adminView) {
                documents = documents.filter(function (document) {
                    return !action.payload.documents.includes(document);
                });
                newDocuments = newDocuments
                    .map(function (document) { return setDeleting(true, document, action.payload.documents.map(function (doc) { return doc.id; })); });
            }
            return __assign({}, state, { documents: documents,
                newDocuments: newDocuments, deletionError: null });
        }
        // Требование удаления определенных документов.
        case DocumentsActionType.DELETE_SELECTED_DOCUMENTS: {
            var documentsIds_1 = getDocumentsIds(action.documents);
            var documents = state.documents
                .map(function (document) { return setDeleting(true, document, documentsIds_1); });
            var newDocuments = state.newDocuments
                .map(function (document) { return setDeleting(true, document, documentsIds_1); });
            return __assign({}, state, { documents: documents,
                newDocuments: newDocuments, deletionError: null, selectedDocuments: [] });
        }
        // Уведомление об удачном удалении документов.
        case DocumentsActionType.DELETE_DOCUMENTS_SUCCESS: {
            var byFilter_1 = action.props.byFilter;
            var documents = state.documents.filter(function (document) {
                return (byFilter_1 && !action.props.documentIds.find(function (documentId) { return documentId === document.id; }))
                    || (!byFilter_1 && !isDeleted(document, action.props.documentIds));
            });
            return __assign({}, state, { deletedDocumentsCount: state.deletedDocumentsCount + action.props.documentIds.length, documents: documents || [] });
        }
        // Уведомление о неудачном удалении документов.
        case DocumentsActionType.DELETE_DOCUMENTS_FAIL: {
            var documentsIds_2 = getDocumentsIds(action.documents);
            var documents = state.documents
                .map(function (document) { return setDeleting(false, document, documentsIds_2); });
            var newDocuments = state.newDocuments
                .map(function (document) { return setDeleting(false, document, documentsIds_2); });
            return __assign({}, state, { documents: documents,
                newDocuments: newDocuments, deletionError: action.apiResponse });
        }
    }
    return state;
}
/**
 * Преобразовывает список документов в список их id.
 *
 * @param documents Список документов.
 *
 * @return Список id документов
 */
function getDocumentsIds(documents) {
    return documents.map(function (document) { return document.id; });
}
/**
 * Документ удален?
 * Документ удален, если у него deleting == true и его id есть в списке на удаление.
 *
 * @param stateDocument Документ из текущего состояния.
 * @param documentsId Список id удаляемых документов.
 *
 * @return Да/Нет.
 */
function isDeleted(stateDocument, documentsId) {
    return (stateDocument.deleting
        && !!documentsId.find(function (documentId) { return documentId === stateDocument.id; }));
}
/**
 * Документ удален?
 * Документ удален, если у него deleting == true и его id есть в списке на удаление.
 *
 * @param stateDocument Документ из текущего состояния.
 * @param documentsId Список id удаляемых документов.
 *
 * @return Да/Нет.
 */
function isFilteredDeleted(stateDocument, documentsId) {
    return !!documentsId.find(function (documentId) { return documentId === stateDocument.id; });
}
/**
 * Обновляет значение deleting документа, если id документа есть в списке.
 *
 * @param deleting Устанавливаемое значение.
 * @param stateDocument Документ из текущего состояния.
 * @param documentsId Список id удаляемых документов.
 *
 * @return Обновленный документ.
 */
function setDeleting(deleting, stateDocument, documentsId) {
    var inDocumentsIds = documentsId.some(function (id) { return id === stateDocument.id; });
    if (inDocumentsIds) {
        stateDocument = __assign({}, stateDocument, { deleting: deleting });
    }
    return stateDocument;
}
/**
 * Функция обновления стейта документа. Либо документ из массива обновленных либо тот который пришел из стейта.
 *
 * @param stateDocument документ из текущего стейта.
 * @param updated обновленные документы.
 */
function updateDocument(stateDocument, updated) {
    var updatedDocument = updated.find(function (item) { return item.id === stateDocument.id; });
    return updatedDocument ? updatedDocument : stateDocument;
}
/**
 * Возвращает документы из состояния документов.
 *
 * @param state Состояние документов.
 */
export var getDocuments = function (state) { return state.documents; };
/**
 * Возвращает новые документы из состояния документов.
 *
 * @param state Состояние новых документов.
 */
export var getNewDocuments = function (state) { return state.newDocuments
    .filter(function (_) { return state.searchState.isEmpty(); })
    .filter(function (newDocument) { return !state.filterState || state.filterState.matchFilterState(newDocument); }); };
/**
 * Возвращает скрытые фильтром новые документы из состояния документов.
 *
 * @param state Состояние новых документов.
 */
export var getHiddenNewDocuments = function (state) { return state.newDocuments
    .filter(function (_) { return state.searchState.isEmpty(); })
    .some(function (newDocument) { return state.filterState && !state.filterState.matchFilterState(newDocument); }); };
/**
 * Возвращает флаг выполняющейся загрузки документов из состояния документов.
 *
 * @param state Состояние документов.
 */
export var getDocumentsLoading = function (state) { return state.loading; };
/**
 * Возвращает флаг успешно выполненной загрузки документов из состояния документов.
 *
 * @param state Состояние документов.
 */
export var getDocumentsLoaded = function (state) { return state.loaded; };
/**
 * Возвращает флаг неудачной попытки загрузки документов из состояния документов.
 *
 * @param state Состояние документов.
 */
export var getDocumentsFailed = function (state) { return state.failed; };
/**
 * Возвращает флаг выполняющейся догрузки документов из состояния документов.
 *
 * @param state Состояние документов.
 */
export var getDocumentsInfinityLoading = function (state) { return state.infinityLoading; };
/**
 * Возвращает флаг успешно выполненной догрузки документов из состояния документов.
 *
 * @param state Состояние документов.
 */
export var getDocumentsInfinityLoaded = function (state) { return state.infinityLoaded; };
/**
 * Возвращает флаг неудачной попытки догрузки документов из состояния документов.
 *
 * @param state Состояние документов.
 */
export var getDocumentsInfinityFailed = function (state) { return state.infinityFailed; };
/**
 * Возвращает флаг полной загрузки документов из состояния документов.
 *
 * @param state Состояние документов.
 */
export var getDocumentsEnded = function (state) { return state.ended; };
/**
 * Возвращает общую часть ответа от API при неудачной попытке удаления документов из состояния документов.
 *
 * @param state Состояние документов.
 */
export var getDeletionError = function (state) { return state.deletionError; };
