import { OnDestroy } from "@angular/core";
import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Store } from "@ngrx/store";
import { select } from "@ngrx/store";
import { Moment } from "moment";
import * as moment from "moment";
import { soonExpirationSelector } from "rootStore";
import { expiryNotificationTypeSelector } from "rootStore";
import { routeStateSelector } from "rootStore";
import { RootState } from "rootStore";
import { RouterGoAction } from "rootStore";
import { CurrentUserLogoutAction } from "rootStore";
import { currentUserInfoSelector } from "rootStore";
import { balanceRestCreditCountSelector } from "rootStore";
import { headerVisibleSelector } from "rootStore";
import { spaceSelector } from "rootStore";
import { headerShowSpacesInfoSelector } from "rootStore";
import { Subject } from "rxjs";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { map } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { CurrentUserInfo } from "src/app/common/models";
import { Space } from "src/app/common/models";
import { ExpiryNotificationType } from "src/app/common/models/expiry-notification-type";
import { LocalStorageService } from "src/app/common/services";
import { UserService } from "src/app/common/services";
import { DlgService } from "src/app/common/services";
import { GoogleAnalyticsService } from "src/app/common/services";
import { LocalStorageKeys } from "src/app/common/services/local-storage.service";
import { FeedbackType } from "src/app/root/models";
import { EmailsAndSpacesDlgService } from "src/app/root/services/emails-and-spaces-dlg.service";
import { purchaseDialogActions } from "src/app/root/store/actions/purchase-dialog.action";
import { RouteState } from "src/app/root/store/reducers/router.reducer";
import { currentUserSelectors } from "src/app/root/store/selectors/current-user.selector";
import { createSpaceActions } from "src/app/spaces/store/actions/space-create.action";
import { environment } from "src/environments/environment";
import { FeedbackDlgService } from "../../services";
import { FacebookPixelService } from "../../services";
import { SupportWidgetService } from "../../services";
import { headerHowToStartButtonVisibilitySelector } from "rootStore";

/**
 * Корневой компонент, с которого начинается приложение.
 */
@Component({
    selector: 'root',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./root.component.scss'],
    templateUrl: './root.component.html'
})
export class RootComponent implements OnInit, OnDestroy {
    //region Constants

    /**
     * URL на базу знаний для русских пользователей.
     */
    private static readonly LOCAL_KNOWLEDGE_BASE_URL = "https://entera.omnidesk.ru/l_rus/knowledge_base/";

    /**
     * URL на базу знаний для зарубежных пользователей.
     */
    private static readonly GLOBAL_KNOWLEDGE_BASE_URL = "https://space-team.atlassian.net/wiki/spaces/EG/overview";

    //endregion
    //region Fields

    /**
     * Текущий URL без query.
     */
    path$: Observable<string>;

    /**
     * Данные текущего пользователя.
     */
    user$: Observable<CurrentUserInfo>;

    /**
     * Текущее пространство документов.
     */
    space$: Observable<Space>;

    /**
     * Доступное количество кредитов.
     */
    restCreditCount$: Observable<number | string>;

    /**
     * Флаг отображения заголовка сайта.
     */
    headerVisible$: Observable<boolean>;

    /**
     * Флаг отображения в заголовке сайта выбора пространств.
     */
    headerShowSpacesInfo$: Observable<boolean>;

    /**
     * Флаг отображения в заголовке сайта кнопки "How to start".
     */
    headerHowToStartButtonVisibility$: Observable<boolean>;

    /**
     * Текущий пользователь имеет право приглашать хотя бы в одно своё пространство документов?
     */
    canInviteInAnySpace$: Observable<boolean>;

    /**
     * Переменные окружения.
     */
    environment = environment;

    /**
     * Данные текущего аутентифицированного пользователя.
     */
    user: CurrentUserInfo;

    /**
     * Уведомление уже было просмотрено и закрыто пользователем.
     */
    viewedNotification$: Observable<boolean>;

    /**
     * У клиента скоро истекает подписка или баланс?
     */
    soonExpiration$: Observable<boolean>;

    /**
     * У клиента скоро истекает подписка?
     */
    soonSubscriptionExpiration$: Observable<boolean>;

    /**
     * У клиента скоро истекает баланс?
     */
    soonBalanceExpiration$: Observable<boolean>;

    /**
     * Тип уведомления клиента об истечении.
     */
    expiryNotificationType$: Observable<ExpiryNotificationType>;

    /**
     * Состояние приложения.
     *
     * @private
     */
    private readonly _store: Store<RootState>;

    /**
     * Сервия для работы с диалогами.
     */
    private readonly _dlgService: DlgService;

    /**
     * Сервис для работы с пользователями.
     *
     * @private
     */
    private readonly _userService: UserService;

    /**
     * ервис для работы с диалогами с почтами и папками.
     *
     * @private
     */
    private readonly _emailsAndSpacesDlgService: EmailsAndSpacesDlgService;

    /**
     * Сервис для работы с Google Analytics.
     */
    private readonly _ga: GoogleAnalyticsService;

    /**
     * Сервис диалога обратной связи.
     */
    private readonly _feedbackService: FeedbackDlgService;

    /**
     * Сервис для работы с LocalStorage.
     */
    private readonly _localStorageService: LocalStorageService;

    /**
     * Объект глобальной отписки.
     */
    private _globalUnsubscribe$: Subject<void> = new Subject();

    //endregion
    //region Ctor

    /**
     * Конструктор корневого компонента.
     *
     * @param store Состояние приложения.
     * @param emailsAndSpacesDlgService Сервис для работы с диалогами с почтами и папками.
     * @param userService Сервис для работы с пользователями.
     * @param ga Сервис для работы с Google Analytics.
     * @param facebookPixelService Сервис для работы с метрикой Facebook Pixel.
     * @param supportWidgetService Сервис для добавления виджета техподдержки на страницу.
     * @param feedbackService Сервис диалога обратной связи.
     * @param dlgService Сервис для работы с диалогами.
     * @param localStorageService Сервис для работы с LocalStorage.
     */
    constructor(
        store: Store<RootState>,
        emailsAndSpacesDlgService: EmailsAndSpacesDlgService,
        userService: UserService,
        ga: GoogleAnalyticsService,
        facebookPixelService: FacebookPixelService,
        supportWidgetService: SupportWidgetService,
        feedbackService: FeedbackDlgService,
        dlgService: DlgService,
        localStorageService: LocalStorageService,
    ) {
        this._store = store;
        this._dlgService = dlgService;
        this._userService = userService;
        this._emailsAndSpacesDlgService = emailsAndSpacesDlgService;
        this._ga = ga;
        this._feedbackService = feedbackService;
        this._localStorageService = localStorageService;

        facebookPixelService.add();
        supportWidgetService.add();
    }

    //endregion
    //region Hooks

    ngOnInit() {

        // На старте приложения добавляем скрипты Google Analytics.
        this._ga.insertScript();

        // Подписываемся на события появления/изменения данных пользователя.
        this.user$ = this._store
            .pipe(
                takeUntil(this._globalUnsubscribe$),
                select(currentUserInfoSelector),
                filter(currentUser => !!currentUser)
            );

        this.user$.subscribe((user: CurrentUserInfo) => this.user = user);

        this.viewedNotification$ = this._localStorageService.get(LocalStorageKeys.USER_NOTIFICATION_VIEW)
            .pipe(
                takeUntil(this._globalUnsubscribe$),
                map((viewDate: Moment) => !!viewDate
                    && moment().diff(moment(viewDate), "hours") < environment.userNotificationInterval
                ),
            );
        this.soonExpiration$ = this._store.select(soonExpirationSelector);
        this.expiryNotificationType$ = this._store.select(expiryNotificationTypeSelector);

        // Подписываемся на изменение текущего пространства документов.
        this.space$ = this._store
            .pipe(
                select(spaceSelector)
            );

        // Подписываемся на изменение баланса текущего пространства документов.
        this.restCreditCount$ = this._store
            .pipe(
                select(balanceRestCreditCountSelector)
            );

        // Подписываемся на события отображения/скрытия заголовка сайта.
        this.headerVisible$ = this._store
            .pipe(
                select(headerVisibleSelector)
            );

        this.headerShowSpacesInfo$ = this._store
            .pipe(
                select(headerShowSpacesInfoSelector)
            );

        this.headerHowToStartButtonVisibility$ = this._store.select(headerHowToStartButtonVisibilitySelector);

        this.path$ = this._store
            .pipe(
                select(routeStateSelector),
                map((state: RouteState): string => state.path),
            );

        this.canInviteInAnySpace$ = this._store
            .pipe(
                select(currentUserSelectors.canInviteInAnySpace),
            );
    }

    ngOnDestroy(): void {

        this._globalUnsubscribe$.next();
        this._globalUnsubscribe$.complete();
    }

    //endregion
    //region Events

    /**
     * Обработчик требования перейти в личный кабинет пользователя.
     */
    userAccountHandler() {

        // TODO Сделать переход в личный кабинет.
        console.log('TODO: Go to user account');
    }

    /**
     * Обработчик события хэдера - выход пользователя из системы
     */
    logoutHandler() {

        this._store.dispatch(new CurrentUserLogoutAction());
    }

    /**
     * Обработчик события хэдера - клик по лого.
     */
    logoHandler() {

        this._store.dispatch(new RouterGoAction({ path: ['/'] }));
    }

    /**
     * Обработчик события с требованием перейти в раздел интеграций заданного пространства документов.
     *
     * @param space Пространство документов.
     */
    integrationHandler(space: Space): void {

        this._store.dispatch(new RouterGoAction({ path: ["/", "spaces", space.id, "settings", "integrations"]}));

    }

    /**
     * Обработчик требования открыть страницу создания нового пространства документов.
     */
    createSpaceHandler() {

        this._store.dispatch(createSpaceActions.openCreateSpaceDlg());
    }

    /**
     * Обработчик события хэдера - выбор пространства документов.
     */
    openSpaceHandler(space: Space) {

        // TODO Хардкод URL
        this._store.dispatch(new RouterGoAction({ path: ['/', 'spaces', space.id, 'documents'] }));
    }

    /**
     * Обработчик требования открыть страницу настроек выбранного пространства документов.
     */
    spaceSettingsHandler(space: Space) {

        this._store.dispatch(new RouterGoAction({ path: ['/', 'spaces', space.id, 'settings'] }));
    }

    /**
     * Обработчик требования открыть диалог для приглашения пользователей в пространства документов.
     */
    openInvitationDialogHandler(): void {

        this._emailsAndSpacesDlgService.openInvitationDlg(false);
    }

    /**
     * Обработчик требования перейти на страницу оплаты для пространства документов.
     */
    spaceBuyHandler(space: Space) {

        // TODO Сделать переход на страницу покупки кредитов для простраства документов.
        console.log('TODO: Go to space credits buying', space);
    }

    /**
     * Обработчик требования открыть диалог обратного звонка.
     */
    callBackHandler() {

        this._feedbackService.open(FeedbackType.CALL_BACK);
    }

    /**
     * Обрабатывает событие перехода на страницу базы знаний.
     */
    knowledgeBaseHandler() {

        if (!this.environment.global) {

            window.open(this.environment.localKnowledgeBaseUrl, "_blank");
        }
        else {

            window.open(this.environment.globalKnowledgeBaseUrl, "_blank");
        }
    }

    /**
     * Обработчик события, требующего открыть диалог инструкции как начать пользоваться Entera.
     */
    howToStartHandler() {

        window.open(environment.howToStart.url, "_blank");
    }

    /**
     * Обрабатывает событие открыть диалог заявки на покупку страниц.
     */
    openPurchaseDialog() {

        this._store.dispatch(purchaseDialogActions.open())
    }

    /**
     * Обрабатывает событие открыть диалог с ошибкой на  не предусмотренного событие.
     */
    openErrorDialog() {

        this._dlgService.openSimpleDlg({
            headerKey: 'common.fail',
            textKey: 'app.errorDlg.message'
        });
    }

    /**
     * Обработчик события, требующего обозначить пользователя как закрывшего уведомление.
     */
    markAsViewedNotificationHandler() {

        this._localStorageService.put(LocalStorageKeys.USER_NOTIFICATION_VIEW, moment());
    }

    //endregion
}
