import { Action } from '@ngrx/store';

import { ApiResponse } from 'src/app/common/models';
import { ApiResponsePayloadAction } from 'src/app/root/store';
import { SendUserPhoneProps } from "src/app/root/store/actions/props/send-user-phone-props";

/**
 * Типы событий, связанные с диалогом подтверждения телефона.
 */
export enum PhoneConfirmDlgActionType {

    INIT_DIALOG = "[Phone confirm dialog] Init dialog",
    SEND_SMS = "[Phone confirm dialog] Send SMS",
    SEND_SMS_SUCCESS = "[Phone confirm dialog] Send SMS success",
    SEND_SMS_FAIL = "[Phone confirm dialog] Send SMS fail",
    CONFIRM_CODE = "[Phone confirm dialog] Confirm code",
    CONFIRM_CODE_SUCCESS = "[Phone confirm dialog] Confirm code success",
    CONFIRM_CODE_FAIL = "[Phone confirm dialog] Confirm code fail",
}

export class PhoneConfirmDialogInitAction implements Action {
    readonly type = PhoneConfirmDlgActionType.INIT_DIALOG;
    constructor() { }
}

export class PhoneConfirmDialogSendSmsAction implements Action {
    readonly type = PhoneConfirmDlgActionType.SEND_SMS;
    constructor(public payload: string) { }
}

export class PhoneConfirmDialogSendSmsSuccessAction implements Action {
    readonly type = PhoneConfirmDlgActionType.SEND_SMS_SUCCESS;
    constructor() { }
}

export class PhoneConfirmDialogSendSmsFailAction implements ApiResponsePayloadAction {
    readonly type = PhoneConfirmDlgActionType.SEND_SMS_FAIL;
    constructor(public payload: ApiResponse) { }
}

export class PhoneConfirmDialogConfirmCodeAction implements Action {
    readonly type = PhoneConfirmDlgActionType.CONFIRM_CODE;
    constructor(public payload: SendUserPhoneProps) { }
}

export class PhoneConfirmDialogConfirmCodeSuccessAction implements Action {
    readonly type = PhoneConfirmDlgActionType.CONFIRM_CODE_SUCCESS;
    constructor(public payload: SendUserPhoneProps) { }
}

export class PhoneConfirmDialogConfirmCodeFailAction implements ApiResponsePayloadAction {
    readonly type = PhoneConfirmDlgActionType.CONFIRM_CODE_FAIL;
    constructor(public payload: ApiResponse) { }
}


/**
 * Тип, объединяющий все события, связанные с диалогом подтверждения телефона.
 */
export type ConfirmPhoneDlgAction =
    | PhoneConfirmDialogInitAction
    | PhoneConfirmDialogSendSmsAction
    | PhoneConfirmDialogSendSmsSuccessAction
    | PhoneConfirmDialogSendSmsFailAction
    | PhoneConfirmDialogConfirmCodeAction
    | PhoneConfirmDialogConfirmCodeSuccessAction
    | PhoneConfirmDialogConfirmCodeFailAction
    ;
