import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { ErrorResponseProps } from "src/app/root/store/actions/props/error-response.action-props";
import { ValueProps } from "src/app/root/store/actions/props/value-props";
import { ExternalItemGroup } from "src/app/spaces/modules/document-matching/models/external-item-group";
import { IntegrationProps } from "src/app/spaces/modules/documents-registry/store/actions/props/integration.props";

/**
 * Типы событий, связанных с группами объектов инвентаризации.
 */
export enum ExternalItemGroupsActionType {
    LOAD = "[External item groups] Load",
    LOAD_SUCCESS = "[External item groups] Successfully load",
    LOAD_FAIL = "[External item groups] Error occurred while loading",
}

/**
 * Событие получения групп объектов инвентаризации с сервера.
 */
const load = createAction(ExternalItemGroupsActionType.LOAD, props<IntegrationProps>());

/**
 * Событие успешного получения групп объектов инвентаризации с сервера.
 */
const loadSuccess = createAction(ExternalItemGroupsActionType.LOAD_SUCCESS, props<ValueProps<ExternalItemGroup[]>>());

/**
 * Событие ошибки при получении групп объектов инвентаризации с сервера.
 */
const loadFail = createAction(ExternalItemGroupsActionType.LOAD_FAIL, props<ErrorResponseProps<void>>());

/**
 * Тип, объединяющий все события, связанные с группами объектов инвентаризации.
 */
export type ExternalItemGroupsAction =
    | ReturnType<typeof load>
    | ReturnType<typeof loadSuccess>
    | ReturnType<typeof loadFail>;

/**
 * Все события, связанные с группами объектов инвентаризации.
 */
export const externalItemGroupsActions = {
    load,
    loadSuccess,
    loadFail,
};
