import { FormControl } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { takeUntil } from "rxjs/operators";
import { matchSettingsActions } from "src/app/spaces/modules/document-matching/store/actions/match-settings.actions";
import { matchSettingsSelector } from "src/app/spaces/modules/document-matching/store/selectors/match-settings.selector";
/**
 * Компонент диалога настроек сопоставления.
 */
var MatchSettingsDlgComponent = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор компонента.
     *
     * @param _store Состояние приложения.
     * @param _dialogRef Компонент диалога.
     * @param data Данные компоненты.
     */
    function MatchSettingsDlgComponent(_store, _dialogRef, data) {
        var _this = this;
        this._store = _store;
        this._dialogRef = _dialogRef;
        /**
         * Объект глобальной отписки.
         */
        this._globalUnsubscribe$ = new Subject();
        this._store.dispatch(matchSettingsActions.updateInit());
        this.matchSettingsGroup = new FormGroup({
            "showGoodCodeColumn": new FormControl(),
        });
        this._integrationId = data.integrationId;
        this._store.select(matchSettingsSelector.matchSettings)
            .pipe(takeUntil(this._globalUnsubscribe$))
            .subscribe(function (matchSettings) {
            _this.matchSettingsGroup.patchValue(matchSettings || {});
            _this._matchSettings = matchSettings;
        });
        this.requestStateType$ = this._store.select(matchSettingsSelector.updateRequestState)
            .pipe(takeUntil(this._globalUnsubscribe$), map(function (requestState) { return requestState.type; }));
        this._store.select(matchSettingsSelector.updateRequestState)
            .pipe(takeUntil(this._globalUnsubscribe$))
            .subscribe(function (requestState) { return _this.error = requestState.error; });
    }
    //endregion
    //region Hooks
    MatchSettingsDlgComponent.prototype.ngOnDestroy = function () {
        this._globalUnsubscribe$.next();
        this._globalUnsubscribe$.complete();
    };
    Object.defineProperty(MatchSettingsDlgComponent.prototype, "isSettingsChanged", {
        //endregion
        //region Getters
        /**
         * Изменились ли настройки?
         */
        get: function () {
            return !this._isSettingsChanged(this.matchSettingsGroup.value);
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Events
    /**
     * Обработчик события закрытия диалога.
     */
    MatchSettingsDlgComponent.prototype.cancelBtnHandler = function () {
        this._dialogRef.close();
    };
    /**
     * Обработчик события нажатия кнопки сохранения настроек.
     *
     * Если произошли изменения, то пытается их сохранить.
     *
     * При успешном сохранении, либо же если состояние инициализированное - то закрывает диалог.
     *
     * При наличии предыдущей ошибки - дает закрыть диалог при нажатии кнопки.
     */
    MatchSettingsDlgComponent.prototype.okBtnHandler = function () {
        var _this = this;
        var previousError = !!this.error;
        if (!previousError && this._isSettingsChanged(this.matchSettingsGroup.value)) {
            this._store.dispatch(matchSettingsActions.update({
                integrationId: this._integrationId,
                matchSettings: this.matchSettingsGroup.value,
            }));
        }
        this.requestStateType$.pipe(takeUntil(this._globalUnsubscribe$), filter(function (requestState) {
            return requestState === "success"
                || requestState === "initial"
                || (requestState === "fail" && previousError);
        }))
            .subscribe(function () { return _this._dialogRef.close(); });
    };
    //endregion
    //region Private
    /**
     * Возвращает флаг того, отличаются ли значения настроек в хранилище с настройками из компоненты.
     *
     * @param value Значение настроек сопоставления из компоненты.
     *
     * @return Да/нет.
     */
    MatchSettingsDlgComponent.prototype._isSettingsChanged = function (value) {
        if (!this._matchSettings && !value) {
            return true;
        }
        else if (this._matchSettings && value) {
            return this._matchSettings.showGoodCodeColumn !== value.showGoodCodeColumn;
        }
        return this._matchSettings !== value;
    };
    return MatchSettingsDlgComponent;
}());
export { MatchSettingsDlgComponent };
