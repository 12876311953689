import { createSelector } from "@ngrx/store";
import { ClientAdminInfo } from "src/app/admin/models/client-admin-info";
import { Transaction } from "src/app/admin/models/transaction";
import { adminStateSelector } from "src/app/admin/store/selectors/admin.selectors";
import { AdminState } from "src/app/admin/store/states/admin.state";
import { ClientControlPageState } from "src/app/admin/store/states/client-control-page.state";
import { Space } from "src/app/common/models/space";
import { environment } from "src/environments/environment";

/**
 * Селектор текущего состояния страницы управления клиентом.
 */
const clientControlPageState = createSelector(
    adminStateSelector,
    (state: AdminState) => state.clientControlPageState
);

/**
 * Селектор текущего выбранного клиента.
 */
const selectedClient = createSelector(
    clientControlPageState,
    (state: ClientControlPageState): ClientAdminInfo => state.selectedClient
);

/**
 * Селектор тегов клиента.
 */
const tagList = createSelector(
    clientControlPageState,
    (state: ClientControlPageState): string[] => state.selectedClient ? environment.tagList : []
);

/**
 * Селектор баланса текущего выбранного клиента.
 */
const selectedClientBalance = createSelector(
    selectedClient,
    (client: ClientAdminInfo): number => client ? client.balance : null
);

/**
 * Селектор списка пространств документов текущего выбранного клиента.
 */
const clientSpaceList = createSelector(
    selectedClient,
    (client: ClientAdminInfo): Space[] => client ? client.spaces : [],
);

/**
 * Селектор отфильтрованного и отсортированного списка пространств документов текущего выбранного клиента.
 *
 * При фильтрации и сортировке все значения приводятся к нижнему регистру.
 */
const filteredAndSortedSpaceList = createSelector(
    clientSpaceList,
    clientControlPageState,
    (spaces: Space[], state: ClientControlPageState): Space[] => (spaces || [])
        .filter((space: Space): boolean =>
            !state.spaceFilter || space.name.toLowerCase().includes(state.spaceFilter.toLowerCase())
        )
        .sort((space1: Space, space2: Space) => space1.name.toLowerCase().localeCompare(space2.name.toLowerCase()))
);

/**
 * Селектор ID текущего выбранного пространства документов.
 */
const selectedSpaceId = createSelector(
    clientControlPageState,
    (clientControlPageState: ClientControlPageState): string => clientControlPageState.selectedSpaceId
);

/**
 * Селектор текущего выбранного пространства документов.
 */
const selectedSpace = createSelector(
    clientSpaceList,
    selectedSpaceId,
    (spaceList: Space[], selectedSpaceId: string): Space => {

        if (spaceList) {

            return spaceList
                .filter((space: Space): boolean => space.id === selectedSpaceId)
                .pop()
        }
        else {

            return null;
        }
    }
);

/**
 * Селектор транзакций текущего выбранного клиента.
 */
const clientTransactions = createSelector(
    selectedClient,
    (client: ClientAdminInfo): Transaction[] => client ? client.transactions : null,
);

/**
 * Селектор состояния загрузки транзакций текущего выбранного клиента.
 */
const clientTransactionsLoading = createSelector(
    clientControlPageState,
    (clientControlPageState: ClientControlPageState): boolean =>
        clientControlPageState.getClientTransactionsRequest.type === "pending",
);

/**
 * Селектор состояния отправки запроса.
 */
const sending = createSelector(
    adminStateSelector,
    (state: AdminState): boolean => pendingClientPageState(state)
        || pendingClientControlState(state)
        || pendingSpaceFlagState(state)
        || pendingScheduleState(state),
);

/**
 * Селектор состояния отправки запроса связынные со страницей клиента.
 */
const pendingClientPageState = (state: AdminState): boolean => !!state.clientControlPageState
    && (state.clientControlPageState.spaceBalanceUpdateRequest.type === "pending"
        || state.clientControlPageState.spaceInfoUpdateRequest.type === "pending"
        || state.clientControlPageState.spaceCreationRequest.type === "pending"
        || state.clientControlPageState.getClientTransactionsRequest.type === "pending"
    );

/**
 * Селектор состояния отправки запроса связынные с клиентом.
 */
const pendingClientControlState = (state: AdminState): boolean => !!state.clientControlState
    && (state.clientControlState.clientUpdateRequest.type === "pending"
        || state.clientControlState.balanceUpdateRequest.type === "pending"
    );

/**
 * Селектор состояния отправки запроса связынные с флагами.
 */
const pendingSpaceFlagState = (state: AdminState): boolean => !!state.spaceFlagState
    && (state.spaceFlagState.updateTrialRequest.type === "pending"
        || state.spaceFlagState.updatePriorityRequest.type === "pending"
        || state.spaceFlagState.updateUseClientBalanceRequest.type === "pending"
        || state.spaceFlagState.updateForeignRequest.type === "pending"
    );

/**
 * Селектор состояния отправки запроса связынные с графиками баланса.
 */
const pendingScheduleState = (state: AdminState): boolean => !!state.scheduleState
    && (state.scheduleState.saveSchedule.type === "pending");

/**
 * Селекторы текущего состояния страницы управления клиентом.
 */
export const ClientControlPageStateSelectors = {
    clientControlPageState,
    selectedClient,
    tagList,
    selectedClientBalance,
    clientSpaceList,
    filteredAndSortedSpaceList,
    selectedSpaceId,
    selectedSpace,
    clientTransactions,
    clientTransactionsLoading,
    sending,
};
