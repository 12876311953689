import { ActionReducerMap } from "@ngrx/store";
import { createFeatureSelector } from "@ngrx/store";
import { moveDocumentsDlgReducer } from "src/app/common/store/reducers/move-documents-dlg.reducer";
import { MoveDocumentsDlgState } from "src/app/common/store/states/move-documents-dlg.state";

import { phoneConfirmDlgReducer } from "./phone-confirm-dlg.reducer";
import { PhoneConfirmDlgState } from "./phone-confirm-dlg.state";

export { PhoneConfirmDlgState } from "./phone-confirm-dlg.state";

/**
 * Состояние модуля commons.
 */
export interface CommonModuleState {

    /**
     * Состояние диалога подтверждения телефона.
     */
    confirmPhoneDlgState: PhoneConfirmDlgState;

    /**
     * Состояние диалога перемещения документов.
     */
    moveDocumentsDlgState: MoveDocumentsDlgState;
}

/**
 * Обратчики каждой части состояния модуля commons.
 */
export const reducers: ActionReducerMap<CommonModuleState> = {

    /**
     * Обработчик состояния диалога подтверждения телефона.
     */
    confirmPhoneDlgState: phoneConfirmDlgReducer,

    /**
     * Обработчик состояния диалога перемещения документов.
     */
    moveDocumentsDlgState: moveDocumentsDlgReducer,
};

/**
 * Селектор состояния модуля commons.
 */
export const commonModuleStateSelector = createFeatureSelector<CommonModuleState>('common');


