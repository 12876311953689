import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { UrlTree } from "@angular/router";
import { CanActivate } from "@angular/router";
import { select } from "@ngrx/store";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Space } from "src/app/common/models/space";
import { UrlUtils } from "src/app/common/utils/url.utils";
import { RootState } from "src/app/root/store/reducers/index";
import { SelectedSpaceForMobileSelectors } from "src/app/root/store/selectors/selected-space-for-mobile.selector";

/**
 * Route Guard.
 * Проверка наличия выбранного пространства документов для мобильного интерфейса.
 */
@Injectable({
    providedIn: "root",
})
export class SelectedSpaceForMobileGuard implements CanActivate {
    //region Fields

    /**
     * Сервис для управления и доступа к состоянию приложения.
     */
    private readonly _store: Store<RootState>;

    /**
     * Сервис для работы с навигацией по приложению.
     */
    private readonly _router: Router;

    //endregion
    //region Ctor

    /**
     * Конструктор Route Guard'а, который выполняет проверку наличия выбранного пространства документов для мобильного
     * интерфейса.
     *
     * @param store Сервис для управления и доступа к состоянию приложения.
     * @param router Сервис для работы с навигацией по приложению.
     */
    constructor(store: Store<RootState>, router: Router) {

        this._store = store;
        this._router = router;
    }

    //endregion
    //region Public

    /**
     * Можно ли осуществлять переход на страницу сканирования QR кода чека?
     *
     * Если отсутствует выбранное пространство документов, то выполнится редирект на корневую страницу мобильного UI.
     */
    canActivate(): Observable<boolean | UrlTree> {

        return this._store.pipe(
            select(SelectedSpaceForMobileSelectors.space),
            map((space: Space): boolean | UrlTree => {

                if (!!space) {

                    return true;
                }
                else {

                    return this._router.parseUrl(UrlUtils.mobilePageUrl());
                }
            }),
        );
    }

    //endregion
}
