import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";
import {UploadToRecognizeState} from "src/app/root/store/reducers/recognize";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'entera-file-upload-indicator',
    templateUrl: 'file-upload-indicator.component.html',
    styleUrls: ['file-upload-indicator.component.scss']
})
export class FileUploadIndicator {


    @Input()
    uploadState: UploadToRecognizeState;

    /**
     * Удаление файла из задачи.
     */
    @Output()
    remove = new EventEmitter<File>();
}
