import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { ErrorResponseProps } from "src/app/root/store/actions/props/error-response.action-props";
import { ValueProps } from "src/app/root/store/actions/props/value-props";
import { MatchSettings } from "src/app/spaces/modules/document-matching/models/match-settings";
import { MatchSettingsProps } from "src/app/spaces/modules/document-matching/store/actions/props/match-settings.action.props";

/**
 * Типы событий настроек сопоставления.
 */
export enum MatchSettingsActionType {
    LOAD = "[Match settings] Load",
    LOAD_SUCCESS = "[Match settings] Successfully loaded",
    LOAD_FAIL = "[Match settings] Error occurred while loading",
    UPDATE_INIT = "[Match settings] Initial update request state",
    UPDATE = "[Match settings] Update",
    UPDATE_SUCCESS = "[Match settings] Successfully updated",
    UPDATE_FAIL = "[Match settings] Error occurred while updating",
}

/**
 * Событие загрузки настроек сопоставления.
 */
const load = createAction(
    MatchSettingsActionType.LOAD,
    props<ValueProps<string>>(),
);

/**
 * Событие успешной загрузки настроек сопоставления.
 */
const loadSuccess = createAction(
    MatchSettingsActionType.LOAD_SUCCESS,
    props<MatchSettings>(),
);

/**
 * Событие ошибки при загрузке настроек сопоставления.
 */
const loadFail = createAction(
    MatchSettingsActionType.LOAD_FAIL,
    props<ErrorResponseProps>(),
);

/**
 * Событие инициализации состояния запроса обновления настроек сопоставления.
 */
const updateInit = createAction(MatchSettingsActionType.UPDATE_INIT);

/**
 * Событие обновления настроек сопоставления.
 */
const update = createAction(
    MatchSettingsActionType.UPDATE,
    props<MatchSettingsProps>(),
);

/**
 * Событие успешного обновления настроек сопоставления.
 */
const updateSuccess = createAction(
    MatchSettingsActionType.UPDATE_SUCCESS,
    props<MatchSettings>(),
);

/**
 * Событие ошибки при обновлении настроек сопоставления.
 */
const updateFail = createAction(
    MatchSettingsActionType.UPDATE_FAIL,
    props<ErrorResponseProps>(),
);



/**
 * Тип, объединяющий события, связанные с настройками сопоставления.
 */
export type MatchSettingsAction = ReturnType<typeof load>
    | ReturnType<typeof loadSuccess>
    | ReturnType<typeof loadFail>
    | ReturnType<typeof updateInit>
    | ReturnType<typeof update>
    | ReturnType<typeof updateSuccess>
    | ReturnType<typeof updateFail>
    ;

/**
 * События, связанные с настройками сопоставления.
 */
export const matchSettingsActions = {
    load: load,
    loadSuccess: loadSuccess,
    loadFail: loadFail,
    updateInit: updateInit,
    update: update,
    updateSuccess: updateSuccess,
    updateFail: updateFail,
};
