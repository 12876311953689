<div class="simple-alert-dlg"  [class.simple-alert-dlg_loading]="loading">
    <h2 class="mat-h2 simple-alert-dlg__header">{{ headerKey | translate:headerParams }}</h2>
    <p *ngIf="!loading && !error" class="simple-alert-dlg__text">{{ text | async }}</p>
    <div *ngIf="!loading && !!error" class="simple-alert-dlg__text">
        <p>{{ 'common.response.error.message' | translate }}</p>
        <p *ngIf="error.errorId">{{ 'common.response.error.id' | translate }} :<br>{{ error.errorId }}</p>
        <p *ngIf="error.errorCode">{{ 'common.response.error.code' | translate }} :<br>{{ error.errorCode }}</p>
    </div>
    <hr *ngIf="!loading" class="simple-alert-dlg__divider"/>
    <div *ngIf="!loading" class="simple-alert-dlg__buttons">
        <button mat-button matDialogClose>
            {{ closeBtnKey | translate }}
        </button>
        <button *ngIf="hasOkButton" mat-button (click)="okClickHandler()">
            {{ okBtnKey | translate }}
        </button>
    </div>
    <mat-spinner [diameter]="64" class="simple-alert-dlg__loading" *ngIf="loading"></mat-spinner>
</div>