/**
 * Утилиты для работы с объектами.
 */
export class ObjectUtils {
    //region Public

    /**
     * Возвращает неизменяемый объект и всех его внутренних объектов.
     *
     * @param object Объект.
     * @param copy Создать глубокую копию объекта?
     *
     * @return Неизменяемый объект.
     */
    static freezeInDeep<T extends any>(object: T, copy: boolean = true): Readonly<T> {

        if (Object.isFrozen(object)) {

            return object;
        }

        let frozenObject: any;

        if (object instanceof Array) {

            frozenObject = [];
        }
        else {

            frozenObject = {};
        }

        for (const propertyName in object) {

            if (object.hasOwnProperty(propertyName)) {

                if (object instanceof Array) {
                    frozenObject = [
                        ...frozenObject,
                        ObjectUtils.freezeInDeep(object[propertyName], copy),
                    ];
                }
                else {

                    frozenObject = {
                        ...frozenObject,
                        [propertyName]: ObjectUtils.freezeInDeep(object[propertyName], copy),
                    };
                }
            }
        }

        if (copy) {

            return Object.freeze(frozenObject);
        }
        else {

            return Object.freeze(object);
        }
    }

    /**
     * Объект пустой?
     *
     * Возвращает true, если:
     * - Объект === null, undefined или подобным;
     * - В объекте нет ни одного ключа;
     * - В объекте нет ни одного ключа, по которому лежит значение !== null, undefined или подобным.
     *
     * @param object Объект.
     *
     * @return Да/нет.
     */
    static isEmptyObject(object: Object): boolean {

        return !object
            || object === {}
            || !Object.keys(object).some((key: string) => !!object[key]);
    }

    //endregion
}
