var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as moment from "moment";
/**
 * Вспомогательные методы для работы с фильтрами и сортировками документов.
 */
var DocumentsFilterUtils = /** @class */ (function () {
    function DocumentsFilterUtils() {
    }
    //region Public static
    /**
     * Собирает фильтры и сортировки в один общий объект для применения.
     *
     * @param spaceId Идентификатор папки.
     * @param state Состояние документов.
     *
     * @return Общий объект для применения фильтров и сортировки.
     */
    DocumentsFilterUtils.extractCommonFilter = function (spaceId, state) {
        return __assign({}, (state.filterState && state.filterState.getStateForRequest() || {}), (state.searchState && state.searchState.getStateForRequest() || {}), (state.sortState && state.sortState.getStateForRequest() || {}), { spaceId: spaceId });
    };
    /**
     * Подготавливает фильтр для запроса.
     *
     * @param registryFilter Фильтр для подготовки.
     *
     * @return Объект с данными фильтра.
     */
    DocumentsFilterUtils.prepareFilter = function (registryFilter) {
        if (!registryFilter) {
            return null;
        }
        return __assign({}, registryFilter, { createdDateFrom: typeof registryFilter.createdDateFrom === "string"
                ? moment(registryFilter.createdDateFrom)
                : registryFilter.createdDateFrom, createdDateTo: typeof registryFilter.createdDateTo === "string"
                ? moment(registryFilter.createdDateTo)
                : registryFilter.createdDateTo, orderBy: null, orderByDesc: null });
    };
    return DocumentsFilterUtils;
}());
export { DocumentsFilterUtils };
