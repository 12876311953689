import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { Routes } from "@angular/router";

import { EmptyComponent } from "src/app/common/components";
import { NotFoundComponent } from "src/app/common/components";
import { UrlUtils } from "src/app/common/utils/url.utils";

import { ForbiddenPageComponent } from "src/app/root/containers";
import { MobileMainComponent } from "src/app/root/containers";
import { VersionPageComponent } from "src/app/root/containers";
import { MobileDocumentUploadComponent } from "src/app/root/containers/mobile-document-upload/mobile-document-upload.component";

import { AdminGuard } from "src/app/root/guards";
import { HideHeaderGuard } from "src/app/root/guards";
import { HideHeaderSpacesInfoGuard } from "src/app/root/guards";
import { NewUserGuard } from "src/app/root/guards";
import { RegistrationPageGuard } from "src/app/root/guards";
import { RootPathGuard } from "src/app/root/guards";
import { ShowHeaderGuard } from "src/app/root/guards";
import { UserGuard } from "src/app/root/guards";
import { DocumentTypesGuard } from "src/app/root/guards/document-types.guard";
import { NoUserGuard } from "src/app/root/guards/no-user-guard.service";
import { SelectedSpaceForMobileGuard } from "src/app/root/guards/selected-space-for-mobile.guard";
import { HideHeaderHowToStartButtonGuard } from "src/app/root/guards/hide-header-how-to-start-button.guard";

const ROUTES: Routes = [
    {
        path: "",
        pathMatch: "full",
        canActivate: [UserGuard, RootPathGuard],
        // Она никогда не будет отрисована, т.к. RootPathGuard всегда сделает редирект.
        // Использовать redirectTo нельзя, т.к. в этом случае guard'ы не отрабатывают.
        component: EmptyComponent
    },
    {
        path: "mobile",
        canActivate: [
            UserGuard,
            NewUserGuard,
            ShowHeaderGuard,
            HideHeaderSpacesInfoGuard,
            HideHeaderHowToStartButtonGuard
        ],
        component: MobileMainComponent
    },
    {
        path: "welcome",
        canActivateChild: [UserGuard, HideHeaderGuard],
        loadChildren: "../welcome/welcome.module#WelcomeModule"
    },
    {
        path: "registration",
        canActivate: [NoUserGuard, RegistrationPageGuard],
        loadChildren: "../registration/registration.module#RegistrationModule",
    },
    {
        path: UrlUtils.spacesPagePath(),
        canActivateChild: [UserGuard, NewUserGuard, ShowHeaderGuard, DocumentTypesGuard],
        loadChildren: "../spaces/spaces.module#SpacesModule"
    },
    {
        path: "something-goes-wrong",
        canActivate: [NoUserGuard],
        canActivateChild: [HideHeaderGuard],
        loadChildren: "../server-side-error/server-side-error.module#ServerSideErrorModule"
    },
    {
        path: "forbidden",
        canActivate: [UserGuard, NewUserGuard, ShowHeaderGuard],
        component: ForbiddenPageComponent
    },
    {
        path: "admin",
        canLoad: [AdminGuard],
        canActivateChild: [UserGuard, NewUserGuard, ShowHeaderGuard],
        loadChildren: "../admin/admin.module#AdminModule"
    },
    {
        path: "qr",
        canActivate: [UserGuard, NewUserGuard, HideHeaderGuard, SelectedSpaceForMobileGuard],
        loadChildren: "../qr/qr.module#QrModule",
    },
    {
        path: "mobile/upload",
        canActivate: [
            UserGuard,
            NewUserGuard,
            ShowHeaderGuard,
            HideHeaderSpacesInfoGuard,
            HideHeaderHowToStartButtonGuard,
            SelectedSpaceForMobileGuard
        ],
        component: MobileDocumentUploadComponent
    },
    {
        path: "version",
        canActivateChild: [UserGuard, NewUserGuard, ShowHeaderGuard],
        component: VersionPageComponent
    },
    {
        path: "**",
        canActivate: [UserGuard, NewUserGuard, ShowHeaderGuard],
        component: NotFoundComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(ROUTES)],
    exports: [RouterModule]
})
export class RootModuleRouting { }
