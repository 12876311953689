var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { UploadToRecognizeActionType } from "../../actions";
/**
 * Состояние отправки файлов на распознавание. Создание задачи на распознавание.
 */
var UploadToRecognizeState = /** @class */ (function () {
    //endregion
    //region Ctor
    function UploadToRecognizeState(files) {
        if (files === void 0) { files = []; }
        //region Fields
        /**
         * Внутренний ID сущности - количетво миллисекунд при создании сущности на клиенте.
         */
        // TODO Перенести счетчки в хранилище
        this.id = new Date().getTime();
        /**
         * Задача на распознавание должна быть создана с флагом, что необходимо принудительно выполнить вызов к
         * удалённому OCR-сервису независимо от имеющегося предыдущего результата вызова?
         */
        this.forceOcrRecognition = false;
        /**
         * Задача на распознавание должна быть создана с флагом, что необходимо принудительно выполнить процесс
         * обработки результата OCR-сервиса независимо от имеющегося предыдущего результата обработки?
         */
        this.forceProcessing = false;
        /**
         * Задача на распознавание должна быть создана с флагом, что необходимо принудительно выполнить отправку
         * результата обработки и выемки данных в очередь к оператору?
         */
        this.forceQueue = false;
        /**
         * Комментария к файлам отправленым на распознавание.
         */
        this.comment = "";
        /**
         * Задача должна быть создана для выписки из банка?
         */
        this.bankStatement = false;
        /**
         * Прогресс загрузки файлов на сервер.
         */
        this.progress = 0;
        /**
         * Файлы с хешами, которые относятся к данной задаче на распознование.
         */
        this.filesWithMetaData = [];
        /**
         * Происходит генерация хэшей и проверка дубликатов?
         */
        this.duplicateCheckingByHash = false;
        /**
         * ?
         */
        this.error = null;
        /**
         * ?
         */
        this.failed = false;
        /**
         * ?
         */
        this.loading = false;
        /**
         * ?
         */
        this.loaded = false;
        /**
         * Нужно ли открывать дилаог после создания отправки файлов на распознавание?
         */
        this.openPostUploadDialog = true;
        /**
         * Загрузка с мобильной версии приложения?
         */
        this.mobile = false;
        this.filesWithMetaData = files;
    }
    return UploadToRecognizeState;
}());
export { UploadToRecognizeState };
/**
 * Начальное состояние.
 */
var initialState = new UploadToRecognizeState();
/**
 * Обработчик состояния отправки файлов на распознование.
 *
 * @param state
 * @param action
 */
export function uploadToRecognizeReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        case UploadToRecognizeActionType.UPLOAD_SUCCESS: {
            result = __assign({}, state, { loaded: true, loading: false });
            break;
        }
        case UploadToRecognizeActionType.UPLOAD_FAIL: {
            result = __assign({}, state, { loaded: false, loading: false, failed: true, error: action.payload });
            break;
        }
        case UploadToRecognizeActionType.UPLOAD_PROGRESS: {
            result = __assign({}, state, { progress: action.payload });
            break;
        }
        case UploadToRecognizeActionType.TASK_CHANGE: {
            result = __assign({}, action.payload);
            break;
        }
        case UploadToRecognizeActionType.ADD_FILES: {
            var filesToAdd = action.payload.filter(function (fileToAdd) {
                return state.filesWithMetaData.every(function (existFile) { return existFile.hash !== fileToAdd.hash; });
            });
            if (filesToAdd.length) {
                result = __assign({}, state, { filesWithMetaData: state.filesWithMetaData.concat(filesToAdd) });
                break;
            }
            break;
        }
        case UploadToRecognizeActionType.GENERATE_HASH_FOR_FILES: {
            result = __assign({}, state, { duplicateCheckingByHash: true });
            break;
        }
        case UploadToRecognizeActionType.MARK_FILES_AS_DUPLICATES: {
            result = __assign({}, state, { filesWithMetaData: state.filesWithMetaData.map(function (existFile) {
                    var fileToMark = action.payload
                        .filter(function (file) { return !!file.fileId; })
                        .find(function (fileToMark) { return fileToMark.hash === existFile.hash; });
                    return !!fileToMark && __assign({}, existFile, { fileId: fileToMark.fileId }) || existFile;
                }), duplicateCheckingByHash: false });
            break;
        }
        case UploadToRecognizeActionType.FILE_EXISTENCE_CHECK_FAILED: {
            result = __assign({}, state, { duplicateCheckingByHash: false, error: action.payload, failed: true });
            break;
        }
        case UploadToRecognizeActionType.REMOVE_FILE: {
            result = __assign({}, state, { filesWithMetaData: state.filesWithMetaData.filter(function (fileWithHash) { return fileWithHash.file !== action.payload; }) });
            break;
        }
        case UploadToRecognizeActionType.UPLOAD: {
            result = __assign({}, action.payload, { loading: true });
            break;
        }
        case UploadToRecognizeActionType.TOGGLE_FORCE_OCR: {
            result = __assign({}, result, { forceOcrRecognition: action.payload });
            break;
        }
        case UploadToRecognizeActionType.TOGGLE_FORCE_PROCESSING: {
            result = __assign({}, result, { forceProcessing: action.payload });
            break;
        }
        case UploadToRecognizeActionType.TOGGLE_FORCE_QUEUE: {
            result = __assign({}, result, { forceQueue: action.payload });
            break;
        }
        case UploadToRecognizeActionType.COMMENT: {
            result = __assign({}, result, { comment: action.payload });
            break;
        }
        case UploadToRecognizeActionType.MOBILE_UPLOAD: {
            result = __assign({}, result, { comment: action.payload });
            break;
        }
    }
    return result;
}
/**
 * Функция выбора поля loaded из состония в хранилище.
 * @param state
 */
export var getLoadedState = function (state) { return state.loaded; };
/**
 * Функция выбора поля loading из состония в хранилище.
 * @param state
 */
export var getLoadingState = function (state) { return state.loading; };
/**
 * Функция получения ошибки, с которой завершилось создание задачи на распознавание.
 *
 * @param state Состояние создания задачи на распознавание.
 */
export var getRecognitionTaskCreationError = function (state) { return state.error; };
