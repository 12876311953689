import { Input } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { BroadcastMessage } from "src/app/common/models/broadcast-message.model";
import { UtilsService } from "src/app/common/services/utils.service";

/**
 * Компонент формы диалога для сообщений пользователям.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "broadcast-message-viewer",
    templateUrl: "./broadcast-message-viewer.component.html",
    styleUrls: ["./broadcast-message-viewer.component.scss"],
})
export class BroadcastMessageViewerComponent {
    //region Inputs

    /**
     * Текущее сообщение для пользователя.
     */
    @Input()
    readonly broadcastMessage: BroadcastMessage;

    /**
     * Входящие данные - язык интерфейса.
     */
    @Input()
    readonly lang: string;

    //endregion
    //region Fields

    /**
     * Сервис утилит.
     */
    private readonly _utilsService: UtilsService;

    //endregion
    //region Ctor

    /**
     * Конструктор формы диалога где отображается текущее сообщение, адресованное пользователю.
     */
    constructor(utilsService: UtilsService) {

       this._utilsService = utilsService;
    }

    //endregion
    //region Public

    /**
     * Форматирует дату в зависимости от языка системы.
     *
     * @param date Дата.
     */
    formatDate(date: string | number) {

        return this._utilsService.formatDate(date, this.lang);
    }

    //endregion
}
