var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import { SourceEnum } from "src/app/common/models/source-enum";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngx-translate/core";
/**
 * Сервис для работы с задачами на распознавание.
 */
var RecognitionTaskService = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор сервиса для работы с задачами на распознавание.
     *
     * @param http HTTP-клиент.
     * @param translateService Сервис переводов.
     */
    function RecognitionTaskService(http, translateService) {
        this._http = http;
        this._translateService = translateService;
    }
    //endregion
    //region Public
    /**
     * Создание задачи на распознование.
     *
     * @param files Файлы для задачи.
     * @param spaceId Пространство документов, в котором создаётся задача.
     * @param forceOcrRecognition Флаг принудительного вызова к удалённому OCR-сервису.
     * @param forceProcessing Флаг принудительного выполнения обработки и выемки данных.
     * @param forceQueue Флаг принудительной отправки результата выемки данных в очередь к оператору.
     * @param comment Комментарий
     * @param mobile Флаг загрузки файлов через мобильную версию приложения.
     * @param bankStatement Флаг создания задачи для выписки из банка.
     *
     * @return Observable от всех событий связанных с загрузкой, включая отправку заголовков, прогресс
     * загрузки и финальный ответ.
     */
    RecognitionTaskService.prototype.createTasks = function (files, spaceId, forceOcrRecognition, forceProcessing, forceQueue, comment, mobile, bankStatement) {
        var _this = this;
        if (forceOcrRecognition === void 0) { forceOcrRecognition = false; }
        if (forceProcessing === void 0) { forceProcessing = false; }
        if (forceQueue === void 0) { forceQueue = false; }
        if (comment === void 0) { comment = null; }
        if (mobile === void 0) { mobile = false; }
        if (bankStatement === void 0) { bankStatement = false; }
        var formData = new FormData();
        if (files.length === 1) {
            formData.append("file", files[0], files[0].name);
        }
        else {
            files.forEach(function (file, index) { return formData.append("file" + index, file, file.name); });
        }
        var requestParam = { spaceId: spaceId };
        if (forceOcrRecognition) {
            requestParam = __assign({}, requestParam, { forceOcrRecognition: forceOcrRecognition });
        }
        if (forceProcessing) {
            requestParam = __assign({}, requestParam, { forceProcessing: forceProcessing });
        }
        if (forceQueue) {
            requestParam = __assign({}, requestParam, { forceQueue: forceQueue });
        }
        if (comment) {
            requestParam = __assign({}, requestParam, { comment: comment });
        }
        requestParam = __assign({}, requestParam, { source: mobile ? SourceEnum.WEB_MOBILE.id : SourceEnum.WEB_APP.id });
        return this._http
            .post(bankStatement && "/api/v1/bankStatements/upload" || "/api/v1/recognitionTasks/multiple", formData, {
            params: requestParam,
            observe: "events",
            reportProgress: true,
            responseType: "json",
        })
            .pipe(catchError(function (response) { return _this.formApiErrorResponse(response); }));
    };
    /**
     * Запрашивает данные задачи на распознавание с сервера.
     *
     * @param taskId ID задачи на распознавание.
     *
     * @return Данные задачи на распознавание.
     */
    RecognitionTaskService.prototype.getTask = function (taskId) {
        return this._http.get("/api/v1/recognitionTasks/" + taskId);
    };
    /**
     * Запрашивает выполняющиеся задачи на распознавание для заданного пространства документов
     * с сервера.
     *
     * @param spaceId ID пространства документов.
     *
     * @return Выполняющиеся задачи на распознавание.
     */
    RecognitionTaskService.prototype.getRunningTasks = function (spaceId) {
        return this._http
            .get("/api/v1/recognitionTasks/running", {
            params: {
                spaceId: spaceId
            }
        })
            .pipe(map(function (response) {
            return response.runningRecognitionTasks;
        }), catchError(function (response) { return throwError(response.error); }));
    };
    /**
     * Возвращает строку, состоящую из всех имен файлов в задаче.
     *
     * @param recognitionTask Задача на распознавание.
     *
     * @return Строка, состоящая из всех имен файлов в задаче.
     */
    RecognitionTaskService.prototype.getFileNames = function (recognitionTask) {
        return recognitionTask.files.reduce(function (str, file) { return str + file.name + ", "; }, "").trim().slice(0, -1);
    };
    /**
     * Возвращает кол-во не обработанных страниц задачи на распознавание.
     *
     * @param recognitionTask Задача на распознавание.
     *
     * @return Кол-во необработанных страниц задачи на распознавание.
     */
    RecognitionTaskService.prototype.getRemainingPageCount = function (recognitionTask) {
        return recognitionTask.documents.reduce(function (sum, document) { return sum - document.pageCount; }, recognitionTask.pages && recognitionTask.pages.length || 0);
    };
    /**
     * Возвращает примерное кол-во минут до завершения обработки задачи на распознавание.
     *
     * Алгоритм расчета:
     *  - Если в задаче было не более 5 страниц изначально, то возвращается 2 * кол-во оставшихся страниц.
     *  - Иначе возвращается кол-во оставшихся страниц.
     *
     * @param recognitionTask Задача на распознавание.
     *
     * @return Примерное кол-во минут до завершения обработки задачи на распознавание.
     */
    RecognitionTaskService.prototype.getRemainingMinutes = function (recognitionTask) {
        var remainingPages = this.getRemainingPageCount(recognitionTask);
        var result = remainingPages;
        if (recognitionTask.pages && recognitionTask.pages.length <= 5) {
            result = remainingPages * 2;
        }
        return result;
    };
    /**
     * Осуществляет запрос проверки, существуют ли файлы на файловом сервере.
     *
     * @param space Текущая папка.
     * @param filesWIthMetaData Файлы.
     */
    RecognitionTaskService.prototype.checkFilesExistence = function (space, filesWIthMetaData) {
        var _this = this;
        var hashList = filesWIthMetaData.map(function (fileWithMetaData) { return fileWithMetaData.hash; });
        var body = { spaceId: space.id, hashList: hashList };
        return this._http.post("/api/v1/checkExistence", body)
            .pipe(map(function (result) { return result; }), catchError(function (response) { return _this.formApiErrorResponse(response); }));
    };
    //endregion
    //region Private
    /**
     * Для сущности HttpErrorResponse возвращает функцию, которая не эмитит никаких событий наблюдателю, но эмитит
     * осмысленную ошибку в виде ApiResponse.
     *
     * Т.к. не все HttpErrorResponse являются результатом неуспешного ответа сервера на запрос (например произошла
     * ошибка на клиенте или не пропустил nginx) то здесь мы можем сформировать различные осмысленные ApiResponse,
     * которые можно отобразить в тех случаях, когда что-то пошло не так на стороне клиента.
     *
     * - Если вернулся ответ-ошибка от сервера - выбрасываем её. Иначе:
     * - Если получили 413 статус код (слишком большое тело запроса) - выкидываем соотв. ошибку.
     * - Если получили 0 статус код и ошибку в виде ProgressEvent - значит файл был удалён.
     * - На все остальные случаи формируем ошибку с общим сообщением об ошибке на стороне клиента.
     */
    RecognitionTaskService.prototype.formApiErrorResponse = function (response) {
        var statusCode = response.status;
        var error = response.error;
        if (!!error.errorMessage) {
            return throwError(error);
        }
        if (statusCode === 413) {
            var errorMessage = this._translateService.instant("common.upload-to-recognize-dialog.filesTooBig");
            error = {
                result: false,
                code: statusCode,
                errorMessage: errorMessage,
            };
        }
        else {
            var errorMessage = this._translateService.instant("common.upload-to-recognize-dialog.clientSideError");
            error = {
                result: false,
                code: statusCode,
                errorMessage: errorMessage,
            };
        }
        return throwError(error);
    };
    RecognitionTaskService.ngInjectableDef = i0.defineInjectable({ factory: function RecognitionTaskService_Factory() { return new RecognitionTaskService(i0.inject(i1.HttpClient), i0.inject(i2.TranslateService)); }, token: RecognitionTaskService, providedIn: "root" });
    return RecognitionTaskService;
}());
export { RecognitionTaskService };
