<div class="move-documents-dlg">
    <h2 mat-dialog-title class="mat-h2 move-documents-dlg__title">
        <span>{{ 'spaces.moveDocumentDialog.move' | translate }}</span>
    </h2>
    <div class="move-documents-dlg__mat" *ngIf="movingDocs$ | async">
        <mat-spinner [diameter]="80"></mat-spinner>
    </div>
    <button
        class="mat-dialog__cross-close-btn"
        mat-flat-button
        matDialogClose
        title='{{ "dialogs.close" | translate }}'
    >
        <mat-icon>close</mat-icon>
    </button>
    <div class="move-documents-dlg__inputs" *ngIf="!(movingDocs$ | async) && !(okState$ | async)">
        <single-select *ngIf="this.adminView"
            [formControl]="clientSelectControl"
            [placeholder]="'spaces.moveDocumentDialog.emailPlaceHolder' | translate"
            [searchEnabled]="true"
            [pagedSearchFn]="findClientsByEmailFn"
            [formatOption]="formatClientEmail"
            [formatResult]="formatClientEmail"
            [required]="true"
            [requiredErrorMessage]="'spaces.moveDocumentDialog.validators.required' | translate"
        >
        </single-select>
        <single-select
            [formControl]="spaceSelectControl"
            [placeholder]="'spaces.moveDocumentDialog.selectSpace' | translate"
            [options]="spaceList$ | async"
            [required]="true"
            [requiredErrorMessage]="'spaces.moveDocumentDialog.validators.required' | translate"
        >
        </single-select>
        <single-select *ngIf="this.adminView"
            [formControl]="userSelectControl"
            [placeholder]="'spaces.moveDocumentDialog.selectUser' | translate"
            [searchEnabled]="true"
            [options]="userList$ | async"
            [required]="true"
            [requiredErrorMessage]="'spaces.moveDocumentDialog.validators.required' | translate"
        >
        </single-select>
    </div>
</div>
<div *ngIf="!(movingDocs$ | async)" class="move-documents-dlg">
    <p mat-dialog-content class="move-documents-dlg__content">
        <span *ngIf="okState$ | async">{{'spaces.moveDocumentDialog.successText' | translate}}</span>
        <span *ngIf="error$ | async" class="move-documents-dlg__row"><!--
        -->{{errorText}}
        </span>
    </p>
    <div class="move-documents-dlg__buttons" mat-dialog-actions>
        <button
            *ngIf="!(okState$ | async)"
            class="move-documents-dlg__btn"
            mat-raised-button
            color="primary"
            [disabled]="!validControls"
            (click)="moveDocuments()"
        >
            <mat-icon>send</mat-icon>
            <span>
                {{ "button.move" | translate }}
            </span>
        </button>
    </div>
</div>
