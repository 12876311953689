import { Action } from "@ngrx/store";
import { DocumentTypesActionType } from "src/app/root/store/actions/document-types.action";

import { CurrentUserActionType } from "./current-user.action";
import { ApiResponse } from "src/app/common/models/api-response";
import { BalanceActionType } from "./balance.action";
import { RecognitionTasksActionType } from "./recognize/recognition-tasks.action";

export * from "./router.action";
export * from "./current-user.action";
export * from "./server-side-error.action";
export * from "./lang.action";
export * from "./header.action";
export * from "./balance.action";
export * from "./recognize";
export * from "./document";
export * from "./feedback.action";
export * from "./recognition-task-created-dlg.action";
export * from "./current-user-settings.action";

/**
 * События, по которым можно понять, что на сервере произошла ошибка.
 * 
 * Эти события слушаются в одном месте, ответ сервера сохраняется в состояние приложения, после чего 
 * выполняется переход на страницу сообщения об ошибке.
 */
export const serverSideErrors: string[] = [
    CurrentUserActionType.LOAD_FAIL,
    CurrentUserActionType.LOGOUT_FAIL,
    BalanceActionType.LOAD_FAIL,
    RecognitionTasksActionType.LOAD_FAIL,
    DocumentTypesActionType.LOAD_FAIL,
];

/**
 * Событие, которое в качестве данных внутри себя несёт ответ от сервера.
 */
export interface ApiResponsePayloadAction extends Action {
    payload: ApiResponse;
}
