import { Injectable } from "@angular/core";

import { Actions } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";

import { Observable } from "rxjs";
import { of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";

import { ApiResponse } from "src/app/common/models";
import { UserService } from "src/app/common/services";

import { CurrentUserSettingsActionType } from "src/app/root/store/actions/current-user-settings.action";
import { SaveShowRecognitionTaskDialogFlagAction } from "src/app/root/store/actions/current-user-settings.action";
import { SavingShowRecognitionTaskDialogFlagFailedAction } from "src/app/root/store/actions/current-user-settings.action";
import { ShowRecognitionTaskDialogFlagSavedSuccessfullyAction } from "src/app/root/store/actions/current-user-settings.action";

/**
 * Side-эффекты на события, связанные с настройками текущего пользователя.
 */
@Injectable()
export class CurrentUserSettingsEffect {
    //region Fields

    /**
     * Сервис с логикой для работы с пользователем
     */
    private readonly _userService: UserService;

    //endregion
    //region Ctor

    /**
     * Конструктор класса side-эффектов на события, связанные с настройками текущего пользователя.
     *
     * @param userService Сервис с логикой для работы с пользователем.
     * @param _actions$ События, происходящие в системе.
     */
    constructor(userService: UserService, private _actions$: Actions) {

        this._userService = userService;
    }

    //endregion
    //region Effects

    /**
     * Обработка события с требованием установить значение флага о необходимости показывать пользователю диалог,
     * в котором можно подписаться на получение уведомления о завершении только что созданной задачи на распознавание.
     */
    @Effect()
    saveShowRecognitionTaskDialogFlag$ = this._actions$
        .pipe(
            ofType(CurrentUserSettingsActionType.SAVE_SHOW_DIALOG_FLAG),
            switchMap((action: SaveShowRecognitionTaskDialogFlagAction): Observable<Action> =>

                this._userService.setShowTaskCreatedDialogFlag(action.flag)
                    .pipe(
                        map((): Action =>
                            new ShowRecognitionTaskDialogFlagSavedSuccessfullyAction(action.flag)
                        ),
                        catchError((response: ApiResponse): Observable<Action> =>
                            of(new SavingShowRecognitionTaskDialogFlagFailedAction(response))
                        ),
                    )
            ),
        );

    //endregion
}
