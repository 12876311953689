var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SendToExternalSystemActionType } from "src/app/spaces/modules/documents-registry/store/actions/send-to-external-system.action";
/**
 *  Начальное состояние для отправки документов во внешнюю систему.
 */
export var initialState = {
    /**
     * Документы отправляются во внешнюю систему.
     */
    sending: false,
    /**
     * Документы успешно отправились во внешнюю систему.
     */
    sent: false,
    /**
     * При отправке документов во внешнюю систему произошла ошибка.
     */
    error: null,
};
/**
 * Обработчик событий связанный с отправкой документов во внешнюю систему.
 *
 * @param state Состояние отправки документов.
 * @param action Событие произошедшее в системе.
 */
export function sendToExternalSystemReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        // Требование отправить документы по интеграции.
        case SendToExternalSystemActionType.SEND_TO_EXTERNAL_SYSTEM: {
            result = __assign({}, state, { sending: true });
            break;
        }
        // Уведомление об успешной отправки документов.
        case SendToExternalSystemActionType.SEND_TO_EXTERNAL_SYSTEM_SUCCESS: {
            result = __assign({}, state, { sent: true, sending: false });
            break;
        }
        // Уведомление об ошибке при отправке документов.
        case SendToExternalSystemActionType.SEND_TO_EXTERNAL_SYSTEM_FAIL: {
            result = __assign({}, state, { sending: false, sent: false, error: action.error });
            break;
        }
    }
    return result;
}
