var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { select } from "@ngrx/store";
import { of } from "rxjs";
import { throwError } from "rxjs";
import { switchMap } from "rxjs/operators";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { ConfigurationService } from "src/app/common/services/configuration.service";
import { environment } from "../../../environments/environment";
import { routeStateSelector } from "../../root/store/selectors"; /* circular dependency break */
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@angular/common/http";
import * as i3 from "./configuration.service";
/**
 * Сервис с логикой для работы с пользователем.
 */
var UserService = /** @class */ (function () {
    //endregion
    //region Ctor
    function UserService(store, http, configService) {
        var _this = this;
        this._http = http;
        this._configService = configService;
        // Следим за состоянием URL'а.
        store
            .pipe(select(routeStateSelector))
            .subscribe(function (routeState) { return _this._routeState = routeState; });
    }
    //endregion
    //region Public
    /**
     * Получение данных о текущем пользователе.
     */
    UserService.prototype.getCurrentUser = function () {
        return this._http
            .get('/api/v1/currentUser')
            .pipe(map(function (response) { return response.user; }), catchError(function (response) {
            // 401 Unauthorized ошибка воспринимается нормально, как отсутствие пользователя (user == null).
            // Другие ошибки пробрасываются дальше.
            if (response.status !== 401) {
                return throwError(response.error);
            }
            return of(null);
        }));
    };
    /**
     * Заданный логин доступен в системе?
     *
     * @param login Логин для проверки.
     *
     * @return Да/Нет.
     */
    UserService.prototype.isLoginAvailable = function (login) {
        if (login === void 0) { login = ''; }
        login = login.replace(/\+/g, '%2B');
        var url = environment.authServerUrl + ("/api/v1/login/check?login=" + login);
        return this._http
            .get(url)
            .pipe(map(function (response) { return !response.occupied; }), catchError(function (response) { return throwError(response.error); }));
    };
    /**
     * Регистрация пользователя по заданным данным.
     *
     * @param formData Данные с формы регистрации.
     *
     * @return Данные зарегистрированного пользователя.
     */
    UserService.prototype.registration = function (formData) {
        var _this = this;
        return this._configService.loadConfigurations().pipe(map(function (env) { return env.global; }), map(function (global) { return global ? "en" : "ru"; }), switchMap(function (lang) { return _this._http
            .post("/api/v1/registration", {
            login: formData.login,
            name: formData.userName,
            inn: formData.inn,
            password: formData.password,
            phone: formData.phone,
            promoCode: formData.promoCode,
            from: environment.appServerUrl,
            lang: lang,
        }, {
            params: __assign({}, _this._routeState.queryParams)
        }); }), map(function (response) { return response.user; }), catchError(function (response) { return throwError(response.error); }));
    };
    /**
     * Выполнение выхода из системы.
     *
     * @return Успех выполнения операции.
     */
    UserService.prototype.logout = function () {
        return this._http
            .post(environment.authServerUrl + "/api/v1/logout", null, { withCredentials: true })
            .pipe(map(function () { return true; }), catchError(function (response) { return throwError(response.error); }));
    };
    /**
     * Возвращает полное имя пользователя.
     *
     * @param user Пользователь.
     */
    UserService.prototype.getFullUserName = function (user) {
        var userName = '';
        if (user) {
            if (user.surname) {
                userName += user.surname;
            }
            if (user.name) {
                userName += ' ' + user.name;
                if (user.patronymic) {
                    userName += ' ' + user.patronymic;
                }
            }
            if (!userName) {
                userName = user.email;
            }
        }
        return userName;
    };
    /**
     * Возвращает имя пользователя с сокращённым именем и отчеством.
     *
     * @param user Пользователь.
     */
    UserService.prototype.getUserName = function (user) {
        var userName = '';
        if (user) {
            if (user.surname) {
                userName += user.surname;
            }
            if (user.name) {
                if (user.surname) {
                    userName += ' ' + user.name.substr(0, 1).toUpperCase() + '.';
                }
                else {
                    userName += user.name;
                }
                if (user.patronymic) {
                    userName += ' ' + user.patronymic.substr(0, 1).toUpperCase() + '.';
                }
            }
            if (!userName) {
                userName = user.email;
            }
        }
        return userName;
    };
    /**
     * Отпарвка СМС с кодом подтверждения телефона на указанный номер.
     *
     * @param phone Телефон для отправки СМС.
     */
    UserService.prototype.sendConfirmSms = function (phone) {
        phone = phone.replace(/\+/, '');
        return this._http
            .post(environment.authServerUrl + "/api/v1/phone/send", "", { params: { phone: phone }, withCredentials: true })
            .pipe(catchError(function (response) {
            return throwError(response.error);
        }));
    };
    /**
     * Подтверждение телефона кодом из СМС.
     *
     * @param code Код подтверждения телефона.
     */
    UserService.prototype.confirmPhoneByCode = function (code) {
        return this._http
            .post(environment.authServerUrl + "/api/v1/phone/confirm", "", { params: { code: code }, withCredentials: true })
            .pipe(catchError(function (response) {
            return throwError(response.error);
        }));
    };
    /**
     * Выполняет установку флага необходимости показывать диалог пользователю после каждой загрузки файлов на
     * распознавание.
     *
     * @param value Значение флага для установки.
     */
    UserService.prototype.setShowTaskCreatedDialogFlag = function (value) {
        return this._http
            .put(UserService.SET_FLAG_URL, { "value": value })
            .pipe(map(function (response) { return response.result; }), catchError(function (response) {
            return throwError(response.error);
        }));
    };
    /**
     * Отправляет заявку на покупку страниц.
     */
    UserService.prototype.sendPurchaseRequest = function (phoneNumber) {
        return this._http
            .post("/api/v1/buy", { phoneNumber: phoneNumber })
            .pipe(map(function (response) { return response.result; }), catchError(function (response) { return throwError(response.error); }));
    };
    //region Constants
    /**
     * URL API метода установки флага необходимости показывать диалог пользователю после каждой загрузки файлов на
     * распознавание.
     */
    UserService.SET_FLAG_URL = "/api/v1/currentUser/showTaskCreatedDialog";
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.Store), i0.inject(i2.HttpClient), i0.inject(i3.ConfigurationService)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
