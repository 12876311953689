import { Action } from "@ngrx/store";
import { ApiResponse } from "src/app/common/models";
import { RecognitionTask } from "src/app/common/models";
import { RecognitionTaskCreatedDlgData } from "src/app/root/models";
import { ApiResponsePayloadAction } from "src/app/root/store/actions/index";

/**
 * Типы событий, связанные с открытием диалога после создания задачи на распознавание.
 */
export enum RecognitionTaskCreatedDlgActionType {
    OPEN_DIALOG = "[Recognition Task Created Dialog] Open",
    APPLY_DIALOG = "[Recognition Task Created Dialog] Apply",
    CLOSE_DIALOG = "[Recognition Task Created Dialog] Close",
}

/**
 * Событие, требующее открытия диалога после создания задачи на распознавание.
 */
export class RecognitionTaskCreatedDlgOpenAction implements Action {
    readonly type = RecognitionTaskCreatedDlgActionType.OPEN_DIALOG;
    constructor(public recognitionTask: RecognitionTask) { }
}

/**
 * Событие, требующее применения изменений после взаимодействия с диалогом после создания задачи на распознавание.
 */
export class RecognitionTaskCreatedDlgApplyAction implements Action {
    readonly type = RecognitionTaskCreatedDlgActionType.APPLY_DIALOG;
    constructor(public data: RecognitionTaskCreatedDlgData, public recognitionTask: RecognitionTask) { }
}

/**
 * Событие, требующее закрытия диалога после создания задачи на распознавание.
 */
export class RecognitionTaskCreatedDlgCloseAction implements Action {
    readonly type = RecognitionTaskCreatedDlgActionType.CLOSE_DIALOG;
    constructor() { }
}

/**
 * Тип, объединяющий все события, связанные с открытием диалога после создания задачи на распознавание.
 */
export type RecognitionTaskCreatedDlgAction =
    RecognitionTaskCreatedDlgOpenAction
    | RecognitionTaskCreatedDlgApplyAction
    | RecognitionTaskCreatedDlgCloseAction
    ;
