import { createSelector } from '@ngrx/store';

import { headerStateSelector } from '../reducers';
import { getHeaderHidden, getHeaderVisible } from '../reducers/header.reducer';

/**
 * Селектор видимости заголовка сайта.
 */
export const headerVisibleSelector = createSelector(headerStateSelector, getHeaderVisible);

/**
 * Селектор скрытости заголовка сайта.
 */
export const headerHiddenSelector = createSelector(headerStateSelector, getHeaderHidden);

/**
 * Селектор видимости выбора пространства в заголовке.
 */
export const headerShowSpacesInfoSelector = createSelector(headerStateSelector, (state) => state.showSpacesInfo);

/**
 * Селектор отображения кнопки "How to start" в заголовке.
 */
export const headerHowToStartButtonVisibilitySelector = createSelector(
    headerStateSelector,
    (state) => state.howToStartButtonVisibility
);
