/**
 * Вариант для выбора в entera-select.
 */
export interface Option {

    /**
     * ID значения.
     */
    id: string;

    /**
     * Текстовое представление значения.
     */
    name: string;
}

/**
 * Объединение типов данных которые можно выбирать в entera-select.
 */
export type SelectableOption = Option | Option[] | string;

/**
 * Является ли значение Option? [Type Guard]
 *
 * @param value Значение.
 *
 * @return Да/Нет.
 */
export function isOption(value: SelectableOption): value is Option {

    return !!value && (value as Option).id !== undefined;
}

/**
 * Является ли значение Option-ом или отсутствует? [Type Guard]
 *
 * @param value Значение.
 */
export function isOptionOrNone(value: SelectableOption): value is Option {

    return !value || (value as Option).id !== undefined;
}

/**
 * Является ли значение массивом Option-ов?  [Type Guard]
 *
 * @param value Значение.
 */
export function isOptionArray(value: SelectableOption): value is Option[] {

    return !!value
        && Array.isArray(value)
        && (
            value.length === 0
            || value.every((option: Option) => isOptionOrNone(option))
        );
}
