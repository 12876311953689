var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Renderer2, RendererFactory2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { HeaderActionType } from '../actions';
import { tap } from 'rxjs/operators';
/**
 * Side-эффекты на события, связанные с заголовком сайта.
 */
var HeaderEffects = /** @class */ (function () {
    //endregion
    //region Ctor
    function HeaderEffects(actions$, document, rendererFactory, store) {
        var _this = this;
        this.actions$ = actions$;
        this.document = document;
        this.rendererFactory = rendererFactory;
        this.store = store;
        //endregion
        //region Ctor
        /**
         * Обработка события отображения заголовка сайта.
         */
        this.showHeaderEffect$ = this.actions$
            .pipe(ofType(HeaderActionType.SHOW), tap(function () {
            _this.renderer.addClass(_this.document.body, 'with-header');
        }));
        /**
         * Обработка события скрытия заголовка сайта.
         */
        this.hideHeaderEffect$ = this.actions$
            .pipe(ofType(HeaderActionType.HIDE), tap(function () {
            _this.renderer.removeClass(_this.document.body, 'with-header');
        }));
        this.renderer = rendererFactory.createRenderer(null, null);
    }
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], HeaderEffects.prototype, "showHeaderEffect$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], HeaderEffects.prototype, "hideHeaderEffect$", void 0);
    return HeaderEffects;
}());
export { HeaderEffects };
