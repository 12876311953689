var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { requestState } from "src/app/common/models/request-state";
import { DocumentTypesActionType } from "src/app/root/store/actions/document-types.action";
/**
 * Начальное состояние типов документов.
 */
var initialState = {
    documentTypesRequest: requestState.initial(),
};
/**
 * Обработчик событий, связанных с типами документов.
 *
 * @param state Состояние типов документов.
 * @param action Событие произошедшее в системе.
 */
export function documentTypesReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        case DocumentTypesActionType.LOAD: {
            result = __assign({}, state, { documentTypesRequest: requestState.pending() });
            break;
        }
        case DocumentTypesActionType.LOAD_SUCCESS: {
            result = __assign({}, state, { documentTypesRequest: requestState.success(action.value) });
            break;
        }
        case DocumentTypesActionType.LOAD_FAIL: {
            return __assign({}, state, { documentTypesRequest: requestState.fail(action.error) });
            break;
        }
    }
    return result;
}
