import { RecognitionTaskType } from "src/app/common/models/recognition-task-type";
import { Space } from "src/app/common/models/space";
import { EnteraDocument } from "../../spaces/modules/document/models/document";
import { IdEntity } from "./id-entity";
import { User } from "./user";

/**
 * Задача на распознавание.
 */
// TODO Есть ещё поля, которые приходят с сервера.
export interface RecognitionTask extends IdEntity {

    /**
     * Состояние задачи на распознавание.
     */
    state: RecognitionState;

    /**
     * Страницы в задаче на распознавание.
     */
    pages?: {id: string}[];

    /**
     * Файлы задачи на распознавание.
     */
    files: {

        /**
         * Имя файла.
         */
        name: string;
    }[];

    /**
     * Описание ошибки, если задача на распознавание завершилась с ошибкой.
     */
    errorDescription?: string;

    /**
     * Список документов, которые были распознаны в этой задаче.
     */
    documents?: EnteraDocument[]

    /**
     * Момент окончания задачи на распознавание.
     */
    finishDate?: string;

    /**
     * Создатель задачи на распознавание.
     */
    creator: User;

    /**
     * Момент создания задачи на распознавание.
     */
    createdDate: string;

    /**
     * Задача ушла на дополнительную обработку.
     */
    additionalProcessing?: boolean;

    /**
     * Источник задачи на распознавание.
     */
    source: string;

    /**
     * Тип задачи на распознавание.
     */
    recognitionTaskType: RecognitionTaskType;

    /**
     * Пространство документов.
     */
    space?: Space;

    /**
     * Триальная задача на распознавание.
     */
    trial?: boolean;
}

/**
 * Статусы задачи на распознавание.
 */
export enum RecognitionState {
    
    /**
     * Задача загружена.
     */    
    UPLOADED = "UPLOADED",

    /**
     * Файлы задачи обработаны и превращены в картинки.
     */
    IMAGE_PROCESSED = "IMAGE_PROCESSED",

    /**
     * Стоимость задачи списана с баланса пространства документов.
     */
    BALANCE_WITHDRAWN = "BALANCE_WITHDRAWN",

    /**
     * Картинки файлов отправлены на OCR-сервер для распознавания.
     */
    RECOGNIZING = "RECOGNIZING",

    /**
     * Задача на распознавание завершена.
     */
    RECOGNIZED = "RECOGNIZED",

    /**
     * Задача на распознавание завершилась с ошибкой.
     */
    ERROR = "ERROR",
}
