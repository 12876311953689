import { Store } from "@ngrx/store";
import { select } from "@ngrx/store";
import { of } from "rxjs";
import { filter } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { RouterGoAction } from "../store";
import { currentUserStateSelector } from "../store";
import { currentUserInfoSelector } from "../store";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
/**
 * Route Guard страницы регистрации.
 *
 * Дает доступ до страницы регистрации только в том случае, если нет залогиневшегося пользователя.
 * Если такой пользователь есть в системе, то перенаправляет на главную, откуда идет перенаправление
 * по правилам главной страницы.
 */
var RegistrationPageGuard = /** @class */ (function () {
    //endregion
    //region Ctor
    function RegistrationPageGuard(_store) {
        var _this = this;
        this._store = _store;
        this._canActivate = this._store
            .pipe(
        // Получаем состояние текущего пользователя.
        select(currentUserStateSelector), 
        // Дожидаемся загрузки данных пользователя.
        filter(function (userState) { return userState.loaded || userState.failed; }), 
        // Получаем пользрователя.
        switchMap(function () { return _this._store.pipe(select(currentUserInfoSelector)); }), 
        // Переанправляем на главную страницу, если есть пользователь.
        tap(function (user) {
            if (user) {
                setTimeout(function () { return _this._store.dispatch(new RouterGoAction({ path: [''] })); });
            }
        }), 
        // Guard проускает, когда нет пользователя.
        switchMap(function (user) { return of(!user); }));
    }
    //endregion
    //region Public
    RegistrationPageGuard.prototype.canActivate = function () {
        return this._canActivate;
    };
    RegistrationPageGuard.prototype.canLoad = function () {
        return this._canActivate;
    };
    RegistrationPageGuard.ngInjectableDef = i0.defineInjectable({ factory: function RegistrationPageGuard_Factory() { return new RegistrationPageGuard(i0.inject(i1.Store)); }, token: RegistrationPageGuard, providedIn: "root" });
    return RegistrationPageGuard;
}());
export { RegistrationPageGuard };
