import {Action} from '@ngrx/store';
import {NavigationExtras, Params} from '@angular/router';

/**
 * Данные для события перехода по заданному URL'у.
 */
export interface RouterActionPayload {
    path: any[];
    query?: Params;
    extras?: NavigationExtras;
}

/**
 * Типы событий, связанные с изменением URL'а.
 */
export enum RouterActionType {
    GO = '[Router] Go',
    BACK = '[Router] Back',
    FORWARD = '[Router] Forward',
}

/**
 * Событие перехода по заданному URL'у.
 */
export class RouterGoAction implements Action {
    readonly type = RouterActionType.GO;
    constructor(public payload: RouterActionPayload) { }
}

/**
 * Событие возврата назад по истории URL'ов.
 */
export class RouterBackAction implements Action {
    readonly type = RouterActionType.BACK;
}

/**
 * Событие перехода вперёд по истории URL'ов.
 */
export class RouterForwardAction implements Action {
    readonly type = RouterActionType.FORWARD;
}

/**
 * Тип, объединяющий все события, связанные с изменением URL'а.
 */
export type RouterAction =
    | RouterGoAction
    | RouterBackAction
    | RouterForwardAction;
