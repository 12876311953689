/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./balance-info-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../info-menu/info-menu.component.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "@angular/material/tooltip";
import * as i5 from "@angular/cdk/overlay";
import * as i6 from "@angular/cdk/scrolling";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/cdk/bidi";
import * as i10 from "@angular/platform-browser";
import * as i11 from "../info-menu/info-menu.component";
import * as i12 from "../../providers/overlay-panel-class.provider";
import * as i13 from "./balance-info-menu.component";
import * as i14 from "@ngx-translate/core";
import * as i15 from "../../services/utils.service";
var styles_BalanceInfoMenuComponent = [i0.styles];
var RenderType_BalanceInfoMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BalanceInfoMenuComponent, data: {} });
export { RenderType_BalanceInfoMenuComponent as RenderType_BalanceInfoMenuComponent };
export function View_BalanceInfoMenuComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "balance-info-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 5, "info-menu", [["hoveredIcon", "description"], ["icon", "description_outline"], ["iconFontSet", "material-icons-custom-set"]], null, [[null, "menuItemClick"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("menuItemClick" === en)) {
        var pd_3 = (_co.menuItemClickHandler($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_InfoMenuComponent_0, i2.RenderType_InfoMenuComponent)), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "balance-info-menu__red": 0 }), i1.ɵdid(4, 147456, null, 0, i4.MatTooltip, [i5.Overlay, i1.ElementRef, i6.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i7.Platform, i8.AriaDescriber, i8.FocusMonitor, i4.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i9.Directionality], [2, i4.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i10.HAMMER_LOADER]], { disabled: [0, "disabled"], message: [1, "message"] }, null), i1.ɵdid(5, 49152, null, 0, i11.InfoMenuComponent, [i12.MAT_MENU_OVERLAY_PANEL_CLASS], { icon: [0, "icon"], iconFontSet: [1, "iconFontSet"], hoveredIcon: [2, "hoveredIcon"], label: [3, "label"], menuItems: [4, "menuItems"] }, { menuItemClick: "menuItemClick" }), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.showUserNotification); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.showUserNotification; var currVal_2 = _co.getExpiryNotificationTooltipText(); _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_3 = "description_outline"; var currVal_4 = "material-icons-custom-set"; var currVal_5 = "description"; var currVal_6 = i1.ɵunv(_v, 5, 3, i1.ɵnov(_v, 6).transform(_co.balance$)); var currVal_7 = _co.menuItems; _ck(_v, 5, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_BalanceInfoMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "balance-info-menu", [], null, null, null, View_BalanceInfoMenuComponent_0, RenderType_BalanceInfoMenuComponent)), i1.ɵdid(1, 49152, null, 0, i13.BalanceInfoMenuComponent, [i14.TranslateService, i15.UtilsService], null, null)], null, null); }
var BalanceInfoMenuComponentNgFactory = i1.ɵccf("balance-info-menu", i13.BalanceInfoMenuComponent, View_BalanceInfoMenuComponent_Host_0, { restCreditCount: "restCreditCount", expirationDate: "expirationDate", foreign: "foreign", showUserNotification: "showUserNotification", expiryNotificationType: "expiryNotificationType" }, { buy: "buy" }, []);
export { BalanceInfoMenuComponentNgFactory as BalanceInfoMenuComponentNgFactory };
