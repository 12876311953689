import { Store } from "@ngrx/store";
import { map } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { documentTypesActions } from "src/app/root/store/actions/document-types.action";
import { documentTypesSelector } from "src/app/root/store/selectors/document-types.selector";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
/**
 * Защита от перехода по пути, проверяющая состояние запроса на загрузку типов документов.
 *
 * Если типы документов ещё не были загружены, диспатчит событие их загрузки.
 * Если загрузка типов ещё не произведена, ожидает её окончания.
 * Если при загрузке произошла ошибка, то запрещает переход по пути.
 * Если типы успешно загружены, то разрешает переход по пути.
 */
var DocumentTypesGuard = /** @class */ (function () {
    //region Ctor
    function DocumentTypesGuard(_store) {
        this._store = _store;
    }
    //endregion
    //region Public
    DocumentTypesGuard.prototype.canActivate = function () {
        var _this = this;
        return this._store.select(documentTypesSelector.requestState).pipe(tap(function (requestState) { return requestState === "initial" && _this._store.dispatch(documentTypesActions.load()); }), filter(function (requestState) { return requestState === "success" || requestState === "fail"; }), map(function (requestState) { return requestState === "success"; }));
    };
    DocumentTypesGuard.prototype.canActivateChild = function () {
        return this.canActivate();
    };
    DocumentTypesGuard.ngInjectableDef = i0.defineInjectable({ factory: function DocumentTypesGuard_Factory() { return new DocumentTypesGuard(i0.inject(i1.Store)); }, token: DocumentTypesGuard, providedIn: "root" });
    return DocumentTypesGuard;
}());
export { DocumentTypesGuard };
