var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";
import { tap } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { map } from "rxjs/operators";
import { RecognitionTaskCreatedDlgService } from "src/app/root/services/recognition-task-created-dlg.service";
import { SaveShowRecognitionTaskDialogFlagAction } from "src/app/root/store/actions";
import { RecognitionTaskCreatedDlgCloseAction } from "src/app/root/store/actions/recognition-task-created-dlg.action"; /* circular dependency break */
import { RecognitionTaskCreatedDlgActionType } from "src/app/root/store/actions/recognition-task-created-dlg.action";
/**
 * Side-эффекты на события, связанные с диалогом, в котором можно подписаться на получение уведомления о завершении
 * только что созданной задачи на распознавание.
 */
var RecognitionTaskCreatedDlgEffect = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор side-эффектов на события, связанные с диалогом, в котором можно подписаться на получение
     * уведомления о завершении только что созданной задачи на распознавание.
     *
     * @param recognitionTaskCreatedDlgService Сервис для работы с диалогом, в котором можно подписаться на
     * получение уведомления о завершении только что созданной задачи на распознавание.
     * @param _actions$ Поток событий, происходящих в системе.
     */
    function RecognitionTaskCreatedDlgEffect(recognitionTaskCreatedDlgService, _actions$) {
        var _this = this;
        this._actions$ = _actions$;
        //endregion
        //region Effects
        /**
         * Обработка события с требованием открыть диалог, в котором можно подписаться на получение уведомления о
         * завершении только что созданной задачи на распознавание.
         */
        this.openRecognitionTaskCreatedDlg$ = this._actions$
            .pipe(ofType(RecognitionTaskCreatedDlgActionType.OPEN_DIALOG), tap(function (action) {
            _this._openedDlg = _this._recognitionTaskCreatedDlgService.open(action.recognitionTask);
        }));
        /**
         * Обработка события с требованием закрыть диалог, в котором можно подписаться на получение уведомления о
         * завершении только что созданной задачи на распознавание.
         */
        this.closeRecognitionTaskCreatedDlg$ = this._actions$
            .pipe(ofType(RecognitionTaskCreatedDlgActionType.CLOSE_DIALOG), tap(function () {
            if (_this._openedDlg) {
                _this._openedDlg.close();
                _this._openedDlg = null;
            }
        }));
        /**
         * Обработка события с требованием обработки формы диалога.
         *
         * Если в диалоге была выставлена, чтобы данный диалог больше не показывался, то генерируется событие для установки
         * значения этому флагу.
         */
        this.applyDlgForShowFlag$ = this._actions$
            .pipe(ofType(RecognitionTaskCreatedDlgActionType.APPLY_DIALOG), filter(function (action) {
            return action.data.dontShowRecognitionTaskCreatedDialog;
        }), map(function (action) {
            return new SaveShowRecognitionTaskDialogFlagAction(false);
        }));
        /**
         * Обработка события с требованием обработки формы диалога, в котором можно подписаться на получение уведомления
         * о завершении только что созданной задачи на распознавание.
         *
         * Если в диалоге не была выставлена галочка на получение уведомления, то генерируется события для закрытия
         * диалога.
         */
        this.applyDlgForClose$ = this._actions$
            .pipe(ofType(RecognitionTaskCreatedDlgActionType.APPLY_DIALOG), map(function () { return new RecognitionTaskCreatedDlgCloseAction(); }));
        this._recognitionTaskCreatedDlgService = recognitionTaskCreatedDlgService;
    }
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], RecognitionTaskCreatedDlgEffect.prototype, "openRecognitionTaskCreatedDlg$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], RecognitionTaskCreatedDlgEffect.prototype, "closeRecognitionTaskCreatedDlg$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], RecognitionTaskCreatedDlgEffect.prototype, "applyDlgForShowFlag$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], RecognitionTaskCreatedDlgEffect.prototype, "applyDlgForClose$", void 0);
    return RecognitionTaskCreatedDlgEffect;
}());
export { RecognitionTaskCreatedDlgEffect };
