/**
 * Точка с координатами (x, y).
 */
var Point = /** @class */ (function () {
    //endregion
    //region Ctor
    function Point(x, y) {
        if (x === void 0) { x = 0; }
        if (y === void 0) { y = 0; }
        //region Private fields
        /**
         * Координата X.
         *
         * @private
         */
        this._x = 0;
        /**
         * Координата Y.
         *
         * @private
         */
        this._y = 0;
        this._x = x;
        this._y = y;
    }
    Object.defineProperty(Point.prototype, "x", {
        //endregion
        //region Getters and Setters
        /**
         * Координата X.
         */
        get: function () {
            return this._x;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Point.prototype, "y", {
        /**
         * Координата Y.
         */
        get: function () {
            return this._y;
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Public
    /**
     * Возвращает новую точку с координатами, полученными на основе текущей точки с заданным сдвигом по X.
     *
     * @param deltaX Сдвиг по координате X.
     */
    Point.prototype.shiftX = function (deltaX) {
        return new Point(this.x + deltaX, this.y);
    };
    /**
     * Возвращает новую точку с координатами, полученными на основе текущей точки с заданным сдвигом по Y.
     *
     * @param deltaY Сдвиг по координате Y.
     */
    Point.prototype.shiftY = function (deltaY) {
        return new Point(this.x, this.y + deltaY);
    };
    /**
     * Текущая точка совпадает с заданной точкой по координатам?
     *
     * @param point Точка.
     *
     * @return Да/Нет.
     */
    Point.prototype.isSame = function (point) {
        return (this.x === point.x && this.y === point.y);
    };
    return Point;
}());
export { Point };
