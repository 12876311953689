import { Injectable } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

import { Actions } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { tap } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { map } from "rxjs/operators";

import { RecognitionTaskCreatedDlgComponent } from "src/app/root/containers";
import { RecognitionTaskCreatedDlgService } from "src/app/root/services/recognition-task-created-dlg.service";
import { SaveShowRecognitionTaskDialogFlagAction } from "src/app/root/store/actions";
import { RecognitionTaskCreatedDlgCloseAction } from "src/app/root/store/actions/recognition-task-created-dlg.action"; /* circular dependency break */
import { RecognitionTaskCreatedDlgActionType } from "src/app/root/store/actions/recognition-task-created-dlg.action";
import { RecognitionTaskCreatedDlgApplyAction } from "src/app/root/store/actions/recognition-task-created-dlg.action";
import { RecognitionTaskCreatedDlgOpenAction } from "src/app/root/store/actions/recognition-task-created-dlg.action";

/**
 * Side-эффекты на события, связанные с диалогом, в котором можно подписаться на получение уведомления о завершении
 * только что созданной задачи на распознавание.
 */
@Injectable()
export class RecognitionTaskCreatedDlgEffect {
    //region Fields

    /**
     * Сервис для работы с диалогом, в котором можно подписаться на получение уведомления о завершении только что
     * созданной задачи на распознавание.
     */
    private readonly _recognitionTaskCreatedDlgService: RecognitionTaskCreatedDlgService;

    /**
     * Экземпляр открытого в данный момент диалога, в котором можно подписаться на получение уведомления о завершении
     * только что созданной задачи на распознавание.
     */
    private _openedDlg: MatDialogRef<RecognitionTaskCreatedDlgComponent>;

    //endregion
    //region Ctor

    /**
     * Конструктор side-эффектов на события, связанные с диалогом, в котором можно подписаться на получение
     * уведомления о завершении только что созданной задачи на распознавание.
     *
     * @param recognitionTaskCreatedDlgService Сервис для работы с диалогом, в котором можно подписаться на
     * получение уведомления о завершении только что созданной задачи на распознавание.
     * @param _actions$ Поток событий, происходящих в системе.
     */
    constructor(
        recognitionTaskCreatedDlgService: RecognitionTaskCreatedDlgService,
        private readonly _actions$: Actions,
    ) {
        this._recognitionTaskCreatedDlgService = recognitionTaskCreatedDlgService;
    }

    //endregion
    //region Effects

    /**
     * Обработка события с требованием открыть диалог, в котором можно подписаться на получение уведомления о
     * завершении только что созданной задачи на распознавание.
     */
    @Effect({ dispatch: false })
    openRecognitionTaskCreatedDlg$ = this._actions$
        .pipe(
            ofType(RecognitionTaskCreatedDlgActionType.OPEN_DIALOG),
            tap((action: RecognitionTaskCreatedDlgOpenAction): void => {

                this._openedDlg = this._recognitionTaskCreatedDlgService.open(action.recognitionTask);
            }),
        );

    /**
     * Обработка события с требованием закрыть диалог, в котором можно подписаться на получение уведомления о
     * завершении только что созданной задачи на распознавание.
     */
    @Effect({ dispatch: false })
    closeRecognitionTaskCreatedDlg$ = this._actions$
        .pipe(
            ofType(RecognitionTaskCreatedDlgActionType.CLOSE_DIALOG),
            tap((): void => {

                if (this._openedDlg) {

                    this._openedDlg.close();
                    this._openedDlg = null;
                }
            }),
        );

    /**
     * Обработка события с требованием обработки формы диалога.
     *
     * Если в диалоге была выставлена, чтобы данный диалог больше не показывался, то генерируется событие для установки
     * значения этому флагу.
     */
    @Effect()
    applyDlgForShowFlag$ = this._actions$
        .pipe(
            ofType(RecognitionTaskCreatedDlgActionType.APPLY_DIALOG),
            filter((action: RecognitionTaskCreatedDlgApplyAction): boolean => {

                return action.data.dontShowRecognitionTaskCreatedDialog;
            }),
            map((action: RecognitionTaskCreatedDlgApplyAction): Action =>
                new SaveShowRecognitionTaskDialogFlagAction(false)
            ),
        );

    /**
     * Обработка события с требованием обработки формы диалога, в котором можно подписаться на получение уведомления
     * о завершении только что созданной задачи на распознавание.
     *
     * Если в диалоге не была выставлена галочка на получение уведомления, то генерируется события для закрытия
     * диалога.
     */
    @Effect()
    applyDlgForClose$ = this._actions$
        .pipe(
            ofType(RecognitionTaskCreatedDlgActionType.APPLY_DIALOG),
            map((): Action => new RecognitionTaskCreatedDlgCloseAction()),
        );

    //endregion
}
