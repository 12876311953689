<div class="recognition-task-created">
    <h2 mat-dialog-title>
        <span class="mat-dialog-title__text">{{ 'dialogs.recognitionTaskCreatedDialog.title' | translate }}</span>
    </h2>
    <mat-dialog-content
        [class.recognition-task-created__content_loading]="loading"
    >
        <div *ngIf="error">
            <p class="recognition-task-created__text">
                {{ 'dialogs.recognitionTaskCreatedDialog.errorDesc' | translate }}
            </p>
            <div>
                <div *ngIf="error.errorId">Error ID: {{ error.errorId }}</div>
                <div *ngIf="error.errorCode">Error code: {{ error.errorCode }}</div>
            </div>
        </div>
        <mat-spinner *ngIf="loading && !error"></mat-spinner>
        <div *ngIf="!loading && !error">
            <p class="recognition-task-created__text">
                {{ 'dialogs.recognitionTaskCreatedDialog.text1' | translate }}
            </p>
            <p class="recognition-task-created__text">
                {{ 'dialogs.recognitionTaskCreatedDialog.text2' | translate }}
            </p>
            <p class="recognition-task-created__text">
                {{ 'dialogs.recognitionTaskCreatedDialog.text3' | translate }}
            </p>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions *ngIf="!loading"
        class="recognition-task-created__actions"
        [class.recognition-task-created__actions_space-between]="!error && belongsToPaidClient"
    >
        <div *ngIf="belongsToPaidClient && !error" class="recognition-task-created__dont-show-checkbox">
            <mat-checkbox [formControl]="dontShowAgainFlagControl">
                {{ 'dialogs.recognitionTaskCreatedDialog.noLongerShow' | translate }}
            </mat-checkbox>
        </div>
        <button *ngIf="!error"
            class="mat-dialog__action-btn"
            mat-raised-button
            color="primary"
            (click)="applyBtnClickHandler()"
        >
            {{ 'dialogs.continue' | translate }}
        </button>
        <button *ngIf="error"
            class="mat-dialog__action-btn"
            mat-raised-button
            mat-dialog-close
        >
            {{ 'dialogs.close' | translate }}
        </button>
    </mat-dialog-actions>
</div>
