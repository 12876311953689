import * as crypto from "crypto-js";
import { from } from "rxjs";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { toArray } from "rxjs/operators";
import { FileWithHash } from "src/app/common/models/file-with-hash";

/**
 * Утилиты для работы с хэшами файлов.
 */
export class FileHashUtils {
    //region Constants

    /**
     * Ограничение количества параллельно выполняемых генераций хешей.
     */
    public static MAX_CONCURRENT_HASH_GENERATING = 4;

    /**
     * Размер части контента файла при чтении файла по частям.
     */
    public static CHUNK_SIZE = 1024 * 1024;

    //endregion
    //region Public

    /**
     * Выполняет генерацию хеша SHA-256 для файла.
     *
     * Читает файл по "кускам".
     *
     * @param file Файл.
     *
     * @return Хеш SHA-256.
     */
    static fileToSHA256Hash(file: File): Promise<FileWithHash> {

        let offset = 0;
        const sha256 = crypto.algo.SHA256.create();

        return new Promise((resolve, reject) => {

            function readNextChunk() {

                const reader = new FileReader();

                reader.onload = function(event) {

                    const result = (event.target as any).result as ArrayBuffer;
                    const wordArray = crypto.lib.WordArray.create(result);
                    sha256.update(wordArray);

                    if (offset < file.size) {

                        readNextChunk();
                    } else {

                        const hash = sha256.finalize();
                        const sha256string = hash.toString(crypto.enc.Base64);
                        resolve({ file: file, hash: sha256string });
                    }
                };

                reader.onerror = function(error) {

                    reject(error);
                };

                const slice = file.slice(offset, offset + FileHashUtils.CHUNK_SIZE);
                reader.readAsArrayBuffer(slice);
                offset += FileHashUtils.CHUNK_SIZE;
            }

            readNextChunk();
        });
    }

    /**
     * Выполняет генерацию хешей SHA-256 для массива файлов.
     *
     * @param files Файлы.
     *
     * @return Список хешей SHA-256.
     */
    static filesToSHA256Hash(files: File[]): Observable<FileWithHash[]> {

        return from(files).pipe(
            mergeMap(file => this.fileToSHA256Hash(file), FileHashUtils.MAX_CONCURRENT_HASH_GENERATING),
            toArray(),
        );
    }

    //endregion
}
