/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-upload-zone.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "@angular/common";
import * as i6 from "../file-upload-indicator/file-upload-indicator.component.ngfactory";
import * as i7 from "../file-upload-indicator/file-upload-indicator.component";
import * as i8 from "./file-upload-zone.component";
var styles_FileUploadZoneComponent = [i0.styles];
var RenderType_FileUploadZoneComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileUploadZoneComponent, data: {} });
export { RenderType_FileUploadZoneComponent as RenderType_FileUploadZoneComponent };
function View_FileUploadZoneComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("common.upload-to-recognize-dialog.buttons.select-file")); _ck(_v, 1, 0, currVal_0); }); }
function View_FileUploadZoneComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("common.upload-to-recognize-dialog.buttons.select-file")); _ck(_v, 1, 0, currVal_0); }); }
function View_FileUploadZoneComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "upload-button-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "span", [["class", "upload-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "mat-icon_green upload-button__icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["add_circle_outline"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadZoneComponent_2)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadZoneComponent_3)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, [[1, 0], ["uploadInput", 1]], null, 0, "input", [["class", "upload-button__input"], ["type", "file"]], [[8, "multiple", 0], [8, "accept", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onChange() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_2 = _co.multipleFileMode; _ck(_v, 6, 0, currVal_2); var currVal_3 = !_co.multipleFileMode; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).inline; var currVal_1 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = _co.multipleFileMode; var currVal_5 = _co.availableFileFormats; _ck(_v, 9, 0, currVal_4, currVal_5); }); }
function View_FileUploadZoneComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "center-horizontal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "entera-file-upload-indicator", [], null, [[null, "remove"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("remove" === en)) {
        var pd_0 = (_co.remove.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_FileUploadIndicator_0, i6.RenderType_FileUploadIndicator)), i1.ɵdid(2, 49152, null, 0, i7.FileUploadIndicator, [], { uploadState: [0, "uploadState"] }, { remove: "remove" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.uploadState; _ck(_v, 2, 0, currVal_0); }, null); }
function View_FileUploadZoneComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "select-file"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "select-file__icon material-icons-custom-set mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["cloud_upload_outline"])), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("common.upload-to-recognize-dialog.drop-zone.text")); _ck(_v, 4, 0, currVal_2); }); }
export function View_FileUploadZoneComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { input: 0 }), i1.ɵqud(402653184, 2, { dropZone: 0 }), (_l()(), i1.ɵeld(2, 0, [[2, 0], ["uploadDropZone", 1]], null, 6, "div", [["class", "upload-drop-zone"]], [[2, "upload-drop-zone_enter", null]], [[null, "dragover"], [null, "drop"], [null, "dragenter"], [null, "dragleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dragover" === en)) {
        var pd_0 = (_co.onDragOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("drop" === en)) {
        var pd_1 = (_co.onDrop($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragenter" === en)) {
        var pd_2 = (_co.onDragEnter($event) !== false);
        ad = (pd_2 && ad);
    } if (("dragleave" === en)) {
        var pd_3 = (_co.onDragLeave($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadZoneComponent_1)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "file-upload-zone"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadZoneComponent_4)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["emptySelection", 2]], null, 0, null, View_FileUploadZoneComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.uploadButtonVisibility; _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.uploadState && _co.uploadState.filesWithMetaData.length); var currVal_3 = i1.ɵnov(_v, 8); _ck(_v, 7, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dropEnter; _ck(_v, 2, 0, currVal_0); }); }
export function View_FileUploadZoneComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "entera-file-upload-zone", [], null, null, null, View_FileUploadZoneComponent_0, RenderType_FileUploadZoneComponent)), i1.ɵdid(1, 49152, null, 0, i8.FileUploadZoneComponent, [], null, null)], null, null); }
var FileUploadZoneComponentNgFactory = i1.ɵccf("entera-file-upload-zone", i8.FileUploadZoneComponent, View_FileUploadZoneComponent_Host_0, { uploadState: "uploadState", multipleFileMode: "multipleFileMode", fileFormats: "fileFormats" }, { addFiles: "addFiles", remove: "remove" }, []);
export { FileUploadZoneComponentNgFactory as FileUploadZoneComponentNgFactory };
