/**
 * Состояние отображения колонок таблицы реестра документов.
 */
import { environment } from "src/environments/environment";
import { ArrayValues } from "src/app/common/models/entera-common-types";

/**
 * Массив колонок в таблице регистра документов.
 */
export const documentRegistryColumns: [
    "documentState",
    "files",
    "customer",
    "supplier",
    "customerInn",
    "supplierInn",
    "documentType",
    "documentNumber",
    "documentDate",
    "taxRate",
    "documentAmount",
    "createdDate",
    "space",
    "creator",
    "comment",
    "info",
    "source",
    "uploadState",
    "externalDocument"
] = [
    "documentState",
    "files",
    "customer",
    "supplier",
    "customerInn",
    "supplierInn",
    "documentType",
    "documentNumber",
    "documentDate",
    "taxRate",
    "documentAmount",
    "createdDate",
    "space",
    "creator",
    "comment",
    "info",
    "source",
    "uploadState",
    "externalDocument",
];

/**
 * Колонка регистра документов.
 */
export type DocumentRegistryColumn = ArrayValues<typeof documentRegistryColumns>;

/**
 * Состояние отображения колонок регистра документов.
 */
export type DocumentRegistryColumnsVisibilityState = {
    readonly [key in Exclude<DocumentRegistryColumn, "documentState">]: boolean
};

/**
 * На основе данных о состоянии видимости столбцов таблицы создаёт экземпляр класса ColumnVisibilityState.
 *
 * @param state Данные состояния видимости столбцов.
 */
export function documentRegistryColumnsVisibilityStateFrom(state: any): DocumentRegistryColumnsVisibilityState {

    return { ...getDefaultColumnsVisibilityState(), ...state };
}

/**
 * Состояния видимости столбцов совпадают?
 *
 * @param state Состояние видимости столбцов.
 * @param anotherState Другое состояние видимости столбцов.
 */
export function areDocumentRegistryColumnsVisibilityStatesSame(
    state: DocumentRegistryColumnsVisibilityState,
    anotherState: DocumentRegistryColumnsVisibilityState
) {
    return state
        && anotherState
        && documentRegistryColumns.every(column => state[column] === anotherState[column]);
}

/**
 * Возвращает состояние отображения колонок по умолчанию.
 */
export function getDefaultColumnsVisibilityState(): DocumentRegistryColumnsVisibilityState {

    return {
        files: false,
        customer: true,
        supplier: true,
        customerInn: false,
        supplierInn: false,
        documentType: true,
        documentNumber: true,
        documentDate: true,
        taxRate: false,
        documentAmount: false,
        createdDate: true,
        creator: false,
        space: false,
        comment: false,
        info: false,
        source: true,
        uploadState: true,
        externalDocument: !!environment.global,
    };
}

/**
 * Возвращает состояние отображения колонок на странице распознаваемых документов по умолчанию.
 */
export function getDefaultRecognizingColumnsVisibilityState(): DocumentRegistryColumnsVisibilityState {

    return {
        files: true,
        customer: true,
        supplier: true,
        customerInn: false,
        supplierInn: false,
        documentType: false,
        documentNumber: false,
        documentDate: false,
        taxRate: false,
        documentAmount: false,
        createdDate: false,
        comment: false,
        creator: true,
        space: false,
        info: true,
        source: true,
        uploadState: false,
        externalDocument: false,
    };
}
