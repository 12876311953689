<div class="single-select">
    <mat-form-field
        class="single-select__mat-form-field"
        [hideRequiredMarker]="true"
        [class.mat-table-compact]="tableCompact"
    >
        <mat-select
            [formControl]="valueControl"
            [placeholder]="placeholder"
            [compareWith]="compareWith"
            [required]="required"
            [errorStateMatcher]="errorStateMatcher"
            (selectionChange)="selectionChangeHandler($event)"
            panelClass="{{ tableCompact ? ' table-compact-select-panel' : 'single-select-panel' }}"
        >
            <mat-select-trigger *ngIf="isSelectedValueExists">
                {{ getResultText(selectedValue) }}
            </mat-select-trigger>

            <ngx-mat-select-search
                *ngIf="searchEnabled"
                [formControl]="searchControl"
                [placeholderLabel]="searchPlaceholder$ | async"
                [noEntriesFoundLabel]="noSearchResultsPlaceholder$ | async"
                [disableScrollToActiveOnOptionsChanged]="true"
                (keydown)="keydownHandler($event)"
            >
            </ngx-mat-select-search>

            <mat-option
                *ngIf="isSelectedValueExists && (!!pagedSearchFn || !!searchFn)"
                [value]="getOptionValue(selectedValue)"
                [disabled]="true"
            >
                <span [innerHTML]="getOptionText(selectedValue)"></span>
            </mat-option>

            <mat-option *ngIf="isStartTypingPlaceholderVisible" [disabled]="true">
                {{ startSearchPlaceholder$ | async }}
            </mat-option>

            <mat-option *ngIf="hasNullOption" [value]="getNullOptionValue()">
                {{ nullOptionText }}
            </mat-option>

            <mat-option *ngFor="let option of (searchOptions$ | async)" [value]="getOptionValue(option)">
                <span [innerHTML]="getOptionText(option)"></span>
            </mat-option>

            <mat-option *ngFor="let option of (filteredOptions$ | async)" [value]="getOptionValue(option)">
                <span [innerHTML]="getOptionText(option)"></span>
            </mat-option>

            <mat-option *ngIf="loading$ | async" [disabled]="true" class="select__loading">
                <mat-spinner [diameter]="25"></mat-spinner>
            </mat-option>

            <mat-option *ngIf="isNoSearchResultsVisible" [disabled]="true">
                {{ noSearchResultsPlaceholder$ | async }}
            </mat-option>

            <mat-option *ngIf="pagedSearchFn" class="select__invisible" [disabled]="true"></mat-option>

        </mat-select>

        <mat-icon 
            *ngIf="valueControl.value && valueControl.enabled && clearBtnEnabled"
            class="single-select__clear-btn"
            [matTooltip]="(clearBtnTitle ? clearBtnTitle : ('clear' | translate))"
            (click)="clearBtnClickHandler($event);"
        >
            clear
        </mat-icon>

        <mat-error *ngIf="valueControl.errors?.required && requiredErrorMessage">{{requiredErrorMessage}}</mat-error>
        <mat-error *ngIf="!valueControl.errors?.required && customError && customErrorMessage">
            {{customErrorMessage}}
        </mat-error>
    </mat-form-field>
</div>
