/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./feedback-dlg.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/feedback/feedback.component.ngfactory";
import * as i3 from "../../components/feedback/feedback.component";
import * as i4 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i5 from "@angular/material/progress-spinner";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/common";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i10 from "@angular/material/button";
import * as i11 from "@angular/cdk/a11y";
import * as i12 from "@angular/material/dialog";
import * as i13 from "@ngx-translate/core";
import * as i14 from "./feedback-dlg.component";
import * as i15 from "@ngrx/store";
import * as i16 from "../../../common/services/dlg.service";
var styles_FeedbackDlgComponent = [i0.styles];
var RenderType_FeedbackDlgComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FeedbackDlgComponent, data: {} });
export { RenderType_FeedbackDlgComponent as RenderType_FeedbackDlgComponent };
function View_FeedbackDlgComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "entera-feedback", [], null, [[null, "send"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("send" === en)) {
        var pd_0 = (_co.sendHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FeedbackComponent_0, i2.RenderType_FeedbackComponent)), i1.ɵdid(1, 49152, null, 0, i3.FeedbackComponent, [], { type: [0, "type"] }, { send: "send" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; _ck(_v, 1, 0, currVal_0); }, null); }
function View_FeedbackDlgComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i4.View_MatSpinner_0, i4.RenderType_MatSpinner)), i1.ɵdid(1, 49152, null, 0, i5.MatSpinner, [i1.ElementRef, i6.Platform, [2, i7.DOCUMENT], [2, i8.ANIMATION_MODULE_TYPE], i5.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var currVal_3 = 80; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_FeedbackDlgComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "mat-dialog__cross-close-btn"], ["mat-dialog-close", ""], ["mat-flat-button", ""], ["type", "button"]], [[8, "title", 0], [8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).dialogRef.close(i1.ɵnov(_v, 2).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_MatButton_0, i9.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i10.MatButton, [i1.ElementRef, i6.Platform, i11.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(2, 606208, null, 0, i12.MatDialogClose, [[2, i12.MatDialogRef], i1.ElementRef, i12.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i1.ɵpid(131072, i13.TranslatePipe, [i13.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, 0, 0, "img", [["alt", ""], ["src", "/assets/images/cross-close-btn.svg"]], null, null, null, null, null))], function (_ck, _v) { var currVal_4 = ""; _ck(_v, 2, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 3).transform("dialogs.close")), ""); var currVal_1 = (i1.ɵnov(_v, 1).disabled || null); var currVal_2 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); var currVal_3 = (i1.ɵnov(_v, 2).ariaLabel || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_FeedbackDlgComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_FeedbackDlgComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_FeedbackDlgComponent_2)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_FeedbackDlgComponent_3)), i1.ɵdid(7, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = !i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.sending$)); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.sending$)); _ck(_v, 4, 0, currVal_1); var currVal_2 = !i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_co.sending$)); _ck(_v, 7, 0, currVal_2); }, null); }
export function View_FeedbackDlgComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "entera-feedback-dlg", [], null, [["window", "keyup.esc"]], function (_v, en, $event) { var ad = true; if (("window:keyup.esc" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onKeyUp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_FeedbackDlgComponent_0, RenderType_FeedbackDlgComponent)), i1.ɵdid(1, 245760, null, 0, i14.FeedbackDlgComponent, [i15.Store, i12.MatDialogRef, i12.MAT_DIALOG_DATA, i16.DlgService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FeedbackDlgComponentNgFactory = i1.ɵccf("entera-feedback-dlg", i14.FeedbackDlgComponent, View_FeedbackDlgComponent_Host_0, {}, {}, []);
export { FeedbackDlgComponentNgFactory as FeedbackDlgComponentNgFactory };
