import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { ErrorResponseProps } from "src/app/root/store/actions/props/error-response.action-props";
import { ValueProps } from "src/app/root/store/actions/props/value-props";
import { IntegrationProps } from "src/app/spaces/modules/documents-registry/store/actions/props/integration.props";

/**
 * Типы событий интеграции.
 */
export enum IntegrationActionType {

    INIT = "[Document matching] Init integration",
    LOAD_INTEGRATION = "[Document matching] Load integration",
    LOAD_INTEGRATION_SUCCESS = "[Document matching] Load integration success",
    LOAD_INTEGRATION_FAILED = "[Document matching] Load integration failed",
    UPDATE_INTEGRATION_SYNC_INFO = "[Document matching] Update integration sync date",
    UPDATE_INTEGRATION_SYNC_INFO_SUCCESS = "[Document matching] Update integration sync date success",
    UPDATE_INTEGRATION_SYNC_INFO_FAILED = "[Document matching] Update integration sync date failed",
}

/**
 * Событие первоначальной инициализации.
 */
const init = createAction(IntegrationActionType.INIT);

/**
 * Событие загрузки интеграции.
 */
const loadIntegration = createAction(
    IntegrationActionType.LOAD_INTEGRATION,
    props<ValueProps<string>>(),
);

/**
 * Событие успешной загрузки интеграции.
 */
const loadIntegrationSuccess = createAction(
    IntegrationActionType.LOAD_INTEGRATION_SUCCESS,
    props<IntegrationProps>(),
);

/**
 * Событие ошибки при загрузке интеграции.
 */
const loadIntegrationFail = createAction(
    IntegrationActionType.LOAD_INTEGRATION_FAILED,
    props<ErrorResponseProps<void>>(),
);

/**
 * Событие обновления даты последней синхронизации интеграции с внешней системой.
 */
const updateIntegrationLastSync = createAction(
    IntegrationActionType.UPDATE_INTEGRATION_SYNC_INFO,
    props<IntegrationProps>(),
);

/**
 * Событие успешного обновления даты последней синхронизации интеграции с внешней системой.
 */
const updateIntegrationLastSyncSuccess = createAction(
    IntegrationActionType.UPDATE_INTEGRATION_SYNC_INFO_SUCCESS,
    props<IntegrationProps>(),
);

/**
 * Событие ошибки при обновлении даты последней синхронизации интеграции с внешней системой.
 */
const updateIntegrationLastSyncFailed = createAction(
    IntegrationActionType.UPDATE_INTEGRATION_SYNC_INFO_FAILED,
    props<ErrorResponseProps<void>>(),
);

/**
 * Тип, объединяющий события, связанные с интеграцией.
 */
export type IntegrationAction =
    | ReturnType<typeof init>
    | ReturnType<typeof loadIntegration>
    | ReturnType<typeof loadIntegrationSuccess>
    | ReturnType<typeof loadIntegrationFail>
    | ReturnType<typeof updateIntegrationLastSync>
    | ReturnType<typeof updateIntegrationLastSyncSuccess>
    | ReturnType<typeof updateIntegrationLastSyncFailed>;

/**
 * События, связанные с интеграцией.
 */
export const integrationActions = {
    init,
    loadIntegration: loadIntegration,
    loadIntegrationSuccess: loadIntegrationSuccess,
    loadIntegrationFail: loadIntegrationFail,
    updateIntegrationLastSync: updateIntegrationLastSync,
    updateIntegrationLastSyncSuccess: updateIntegrationLastSyncSuccess,
    updateIntegrationLastSyncFailed: updateIntegrationLastSyncFailed,
};
