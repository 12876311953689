import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { CanActivateChild } from '@angular/router';

import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';

import { RootState } from '../store';
import { headerHowToStartButtonVisibilitySelector } from "../store";
import { HeaderHideHowToStartButtonAction } from "../store";

/**
 * Route Guard.
 * Логика обработки попытки входа по пути, для которого требуется скрыть кнопку "How to start".
 */
@Injectable({
    providedIn: 'root'
})
export class HideHeaderHowToStartButtonGuard implements CanActivate, CanActivateChild {
    //region Ctor

    constructor(
        private _store: Store<RootState>
    ) {}

    //endregion
    //region Public

    canActivate(): Observable<boolean> {

        return this._store
            .pipe(
                select(headerHowToStartButtonVisibilitySelector),
                tap((show) => {

                    if (show) {

                        this._store.dispatch(new HeaderHideHowToStartButtonAction());
                    }
                }),
                map((show) => !show)
            );
    }

    canActivateChild() {

        return this.canActivate();
    }

    //endregion
}
