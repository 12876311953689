var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * На основе данных об URL из Angular формирует представление RouteState.
 */
var CustomSerializer = /** @class */ (function () {
    function CustomSerializer() {
    }
    CustomSerializer.prototype.serialize = function (routerState) {
        var url = routerState.url;
        var queryParams = routerState.root.queryParams;
        var path = url.split('?')[0];
        var state = routerState.root;
        var params = {};
        while (state.firstChild) {
            params = __assign({}, params, state.params);
            state = state.firstChild;
        }
        params = __assign({}, params, state.params);
        return { url: url, path: path, queryParams: queryParams, params: params };
    };
    return CustomSerializer;
}());
export { CustomSerializer };
/**
 * Возвращает представление текущего URL.
 *
 * @param routerReducerState Состояние данных о текущем URL.
 */
export var getRouteState = function (routerReducerState) {
    return (routerReducerState ? routerReducerState.state : null);
};
