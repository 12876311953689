/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./first-upload-dlg.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@ngx-translate/core";
import * as i8 from "@angular/material/dialog";
import * as i9 from "@angular/common";
import * as i10 from "./first-upload-dlg.component";
import * as i11 from "../../../common/services/configuration.service";
var styles_FirstUploadDlgComponent = [i0.styles];
var RenderType_FirstUploadDlgComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FirstUploadDlgComponent, data: {} });
export { RenderType_FirstUploadDlgComponent as RenderType_FirstUploadDlgComponent };
function View_FirstUploadDlgComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "mat-dialog__action-btn first-upload-dlg__button"], ["color", "primary"], ["mat-raised-button", ""], ["target", "blank"]], [[1, "href", 4], [1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._haltDisabledEvents($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.seeMoreClick() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MatAnchor_0, i2.RenderType_MatAnchor)), i1.ɵdid(1, 180224, null, 0, i3.MatAnchor, [i4.Platform, i5.FocusMonitor, i1.ElementRef, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(2, 0, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_5 = "primary"; _ck(_v, 1, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.seeMoreLink; var currVal_1 = (i1.ɵnov(_v, 1).disabled ? (0 - 1) : (i1.ɵnov(_v, 1).tabIndex || 0)); var currVal_2 = (i1.ɵnov(_v, 1).disabled || null); var currVal_3 = i1.ɵnov(_v, 1).disabled.toString(); var currVal_4 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_6 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("button.seeMore")); _ck(_v, 2, 0, currVal_6); }); }
export function View_FirstUploadDlgComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i8.MatDialogTitle, [[2, i8.MatDialogRef], i1.ElementRef, i8.MatDialog], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "mat-dialog-title__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 4, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i8.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "first-upload-dlg__row"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 14, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(11, 16384, null, 0, i8.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 4, "button", [["class", "mat-dialog__action-btn"], ["mat-dialog-close", ""], ["mat-raised-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).dialogRef.close(i1.ɵnov(_v, 14).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(13, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(14, 606208, null, 0, i8.MatDialogClose, [[2, i8.MatDialogRef], i1.ElementRef, i8.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(15, 0, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_FirstUploadDlgComponent_1)), i1.ɵdid(18, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(20, 0, null, null, 4, "button", [["class", "mat-dialog__action-btn mat-dialog__cross-close-btn"], ["mat-dialog-close", ""], ["mat-flat-button", ""], ["type", "button"]], [[8, "title", 0], [8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 22).dialogRef.close(i1.ɵnov(_v, 22).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(21, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(22, 606208, null, 0, i8.MatDialogClose, [[2, i8.MatDialogRef], i1.ElementRef, i8.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(24, 0, null, 0, 0, "img", [["alt", ""], ["src", "/assets/images/cross-close-btn.svg"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_6 = ""; _ck(_v, 14, 0, currVal_6); var currVal_8 = !i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform(_co.isGlobal$)); _ck(_v, 18, 0, currVal_8); var currVal_13 = ""; _ck(_v, 22, 0, currVal_13); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("dialogs.firstUploadDialog.title")); _ck(_v, 3, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("dialogs.firstUploadDialog.text")); _ck(_v, 8, 0, currVal_2); var currVal_3 = (i1.ɵnov(_v, 13).disabled || null); var currVal_4 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); var currVal_5 = (i1.ɵnov(_v, 14).ariaLabel || null); _ck(_v, 12, 0, currVal_3, currVal_4, currVal_5); var currVal_7 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform("dialogs.close")); _ck(_v, 15, 0, currVal_7); var currVal_9 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 20, 0, i1.ɵnov(_v, 23).transform("dialogs.close")), ""); var currVal_10 = (i1.ɵnov(_v, 21).disabled || null); var currVal_11 = (i1.ɵnov(_v, 21)._animationMode === "NoopAnimations"); var currVal_12 = (i1.ɵnov(_v, 22).ariaLabel || null); _ck(_v, 20, 0, currVal_9, currVal_10, currVal_11, currVal_12); }); }
export function View_FirstUploadDlgComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "first-upload-dlg", [], null, [["window", "keyup.esc"]], function (_v, en, $event) { var ad = true; if (("window:keyup.esc" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onKeyUp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_FirstUploadDlgComponent_0, RenderType_FirstUploadDlgComponent)), i1.ɵdid(1, 49152, null, 0, i10.FirstUploadDlgComponent, [i8.MatDialogRef, i11.ConfigurationService], null, null)], null, null); }
var FirstUploadDlgComponentNgFactory = i1.ɵccf("first-upload-dlg", i10.FirstUploadDlgComponent, View_FirstUploadDlgComponent_Host_0, {}, {}, []);
export { FirstUploadDlgComponentNgFactory as FirstUploadDlgComponentNgFactory };
