import { RouterReducerState } from "@ngrx/router-store";
import { routerReducer } from "@ngrx/router-store";
import { ActionReducerMap } from "@ngrx/store";
import { createFeatureSelector } from "@ngrx/store";
import { broadcastMessageDlgReducer } from "src/app/root/store/reducers/broadcast-message-dlg-reducer";
import { currenciesReducer } from "src/app/root/store/reducers/currencies.reducer";
import { documentTypesReducer } from "src/app/root/store/reducers/document-types.reducers";
import { feedbackReducer } from "src/app/root/store/reducers/feedback/feedback.reducer";
import { FeedbackState } from "src/app/root/store/reducers/feedback/feedback.state";
import { purchaseDialogReducer } from "src/app/root/store/reducers/purchase-dialog.reducer";
import { selectedSpaceForMobileReducer } from "src/app/root/store/reducers/selected-space-for-mobile.reducer";
import { trustedEmailsAdditionStateReducer } from "src/app/root/store/reducers/trusted-emails-addition-state.reducer";
import { usersInvitationStateReducer } from "src/app/root/store/reducers/users-invitation-state.reducer";
import { BroadcastMessagesDlgState } from "src/app/root/store/states/broadcast-messages-dlg.state";
import { CurrenciesState } from "src/app/root/store/states/currencies.state";
import { DocumentTypesState } from "src/app/root/store/states/document-types.state";
import { PurchaseDialogState } from "src/app/root/store/states/purchase-dialog.state";
import { SelectedSpaceForMobileState } from "src/app/root/store/states/selected-space-for-mobile.state";
import { TrustedEmailsAdditionState } from "src/app/root/store/states/trusted-emails-addition.state";
import { UsersInvitationState } from "src/app/root/store/states/users-invitation.state";
import { RecognitionTaskCreatedState } from "../states/recognition-task-created.state";
import { BalanceState } from "./balance.reducer";
import { balanceReducer } from "./balance.reducer";

import { CurrentUserState } from "./current-user.reducer";
import { currentUserReducer } from "./current-user.reducer";
import { NewDocumentsCountState } from "./document";
import { newDocumentsCountReducer } from "./document";
import { HeaderState } from "./header.reducer";
import { headerReducer } from "./header.reducer";
import { previousUrlReducer, PreviousUrlState } from "./previous-url.reducer";
import { recognitionTaskCreatedReducer } from "./recognition-task-created.reducer";
import { UploadToRecognizeState } from "./recognize";
import { uploadToRecognizeReducer } from "./recognize";
import { RecognitionTasksState } from "./recognize";
import { recognitionTasksReducer } from "./recognize";
import { RouteState } from "./router.reducer";
import { ServerSideErrorState } from "./server-side-error.reducer";
import { serverSideErrorReducer } from "./server-side-error.reducer";

/**
 * Состояние приложения, состоящее из состояний каких-то отдельных его частей.
 */
export interface RootState {

    /**
     * Состояние по данным текущего пользователя.
     */
    currentUser: CurrentUserState;

    /**
     * Состояние по данным баланса для пространства документов.
     */
    balance: BalanceState;

    /**
     * Состояние URL'а.
     */
    routerReducer: RouterReducerState<RouteState>;

    /**
     * Состояние данных об ошибке на сервере.
     */
    serverSideError: ServerSideErrorState;

    /**
     * Состояние заголовка сайта.
     */
    headerState: HeaderState;

    // TODO возможно стоит перенести
    /**
     * Состояние загрузки файлов на распознавание.
     */
    uploadToRecognize: UploadToRecognizeState;

    /**
     * Состояние задач на распознавание.
     */
    recognitionsTasks: RecognitionTasksState;

    /**
     * Состояние данных о кол-ве новых документов.
     */
    newDocumentsCount: NewDocumentsCountState;

    /**
     * Состояние предыдущего URL внутри приложения.
     */
    previousUrl: PreviousUrlState;

    /**
     * Состояние диалога обратного отзыва.
     */
    feedback: FeedbackState;

    /**
     * Состояние применения изменений после диалога загрузки задачи на распознавание.
     */
    recognitionTaskCreatedDialog: RecognitionTaskCreatedState;

    /**
     * Состояние для выбранного пространства документов, в контексте которого происходит работа в случае, когда
     * используется мобильная версия UI.
     */
    selectedSpaceForMobile: SelectedSpaceForMobileState;

    /**
     * Состояние диалога приглашения пользователей в пространства документов.
     */
    usersInvitation: UsersInvitationState;

    /**
     * Состояние добавления доверенных почт в пространства документов.
     */
    trustedEmailsAddition: TrustedEmailsAdditionState;

    /**
     * Состояние диалога сообщений пользователю.
     */
    broadcastMessagesDialog: BroadcastMessagesDlgState;

    /**
     * Состояние диалога покупки страниц.
     */
    purchaseDialog: PurchaseDialogState;

    /**
     * Состояние валют.
     */
    currencies: CurrenciesState;

    /**
     * Состояние типов документов.
     */
    documentTypes: DocumentTypesState;
}

/**
 * Обработчики частей состояния приложения.
 */
export const reducers: ActionReducerMap<RootState> = {

    /**
     * Обработчик состояния по данным текущего пользователя.
     */
    currentUser: currentUserReducer,

    /**
     * Обработчик состояния по данным баланса.
     */
    balance: balanceReducer,

    /**
     * Обработчик состояния URL'а.
     */
    routerReducer: routerReducer,

    /**
     * Обработчик состояния данных об ошибке на сервере.
     */
    serverSideError: serverSideErrorReducer,

    /**
     * Обработчик состояния заголовка сайта.
     */
    headerState: headerReducer,

    /**
     * Обработчик состояния загрузки файлов на распознавание.
     */
    uploadToRecognize: uploadToRecognizeReducer,

    /**
     * Обработчик состояния задач на распознавание.
     */
    recognitionsTasks: recognitionTasksReducer,

    /**
     * Обработчик состояния данных о кол-ве новых документов.
     */
    newDocumentsCount: newDocumentsCountReducer,

    /**
     * Обработчик состояния предыдущего URL внутри приложения.
     */
    previousUrl: previousUrlReducer,

    /**
     * Обработчик состояние диалога обратного отзыва.
     */
    feedback: feedbackReducer,

    /**
     * Обработчик состояния приглашения пользователей в пространства документов.
     */
    usersInvitation: usersInvitationStateReducer,

    /**
     * Обработчик состояния добавления доверенных почт в пространства документов.
     */
    trustedEmailsAddition: trustedEmailsAdditionStateReducer,

    /**
     * Обработчик состояния применения изменений после диалога загрузки задачи на распознавание в ответ на событие.
     */
    recognitionTaskCreatedDialog: recognitionTaskCreatedReducer,

    /**
     * Обработчик состояния для выбранного пространства документов, в контексте которого происходит работа в случае,
     * когда используется мобильная версия UI.
     */
    selectedSpaceForMobile: selectedSpaceForMobileReducer,

    /**
     * Обработчик состояния диалога сообщений пользователю.
     */
    broadcastMessagesDialog: broadcastMessageDlgReducer,

    /**
     * Обработчик состояния диалога заявки на покупку страниц.
     */
    purchaseDialog: purchaseDialogReducer,

    /**
     * Обработчик событий, связанных с состоянием валют.
     */
    currencies: currenciesReducer,

    /**
     * Обработчик событий, связанных с типами документов.
     */
    documentTypes: documentTypesReducer,
};

/**
 * Селектор состояния данных текущего пользователя.
 */
export const currentUserStateSelector = createFeatureSelector<CurrentUserState>("currentUser");

/**
 * Селектор состояния данных баланса.
 */
export const balanceStateSelector = createFeatureSelector<BalanceState>("balance");

/**
 * Селектор состояния URL'а.
 */
export const routerReducerStateSelector = createFeatureSelector<RouterReducerState<RouteState>>("routerReducer");

/**
 * Селектор состояния данных об ошибке на сервере.
 */
export const serverSideErrorStateSelector = createFeatureSelector<ServerSideErrorState>("serverSideError");

/**
 * Селектор состояния данных о заголовке сайта.
 */
export const headerStateSelector = createFeatureSelector<HeaderState>("headerState");

/**
 *  Селектор состояния задач на распознавание.
 */
export const recognitionTasksStateSelector = createFeatureSelector<RecognitionTasksState>("recognitionsTasks");

/**
 *  Селектор состояния загрузки файлов на распознавание.
 */
export const uploadToRecognizeSelector = createFeatureSelector<UploadToRecognizeState>("uploadToRecognize");

/**
 *  Селектор состояния создания задачи на распознавание.
 */
export const newDocumentsCountStateSelector = createFeatureSelector<NewDocumentsCountState>("newDocumentsCount");

/**
 *  Селектор состояния создания задачи на распознавание.
 */
export const previousUrlStateSelector = createFeatureSelector<PreviousUrlState>("previousUrl");

/**
 *  Селектор состояния создания задачи на распознавание.
 */
export const feedbackStateSelector = createFeatureSelector<FeedbackState>("feedback");


export { UploadToRecognizeState } from "./recognize";
export { RecognitionTasksState } from "./recognize";
export { CustomSerializer } from "./router.reducer";
