var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { MoveDocumentsDlgActionType } from "src/app/common/store/actions/move-documents-dlg.action";
import { MoveDocumentsDlgState } from "src/app/common/store/states/move-documents-dlg.state";
/**
 * Начальное состояние диалога перемещения документов.
 */
var initialState = new MoveDocumentsDlgState();
/**
 * Обработчик событий, связанных с диалогом перемещения документов.
 *
 * @param state Состояние диалога перемещения документов.
 * @param action Событие произошедшее в системе.
 */
export function moveDocumentsDlgReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        case MoveDocumentsDlgActionType.INIT_DIALOG: {
            result = new MoveDocumentsDlgState();
            break;
        }
        // Требование переместить документ в другую папку
        case MoveDocumentsDlgActionType.MOVE_TO_ANOTHER_SPACE: {
            return __assign({}, state, { movingDocumentsToAnotherSpace: true, movingDocumentsToAnotherSpaceSuccess: false, movingDocumentsToAnotherSpaceError: null });
        }
        // Документ успешно перемещен в другую папку
        case MoveDocumentsDlgActionType.MOVE_TO_ANOTHER_SPACE_SUCCESS: {
            return __assign({}, state, { movingDocumentsToAnotherSpace: false, movingDocumentsToAnotherSpaceSuccess: true, movingDocumentsToAnotherSpaceError: null });
        }
        // Перемещение документа в другую папку завершилось ошибкой
        case MoveDocumentsDlgActionType.MOVE_TO_ANOTHER_SPACE_FAIL: {
            return __assign({}, state, { movingDocumentsToAnotherSpace: false, movingDocumentsToAnotherSpaceSuccess: false, movingDocumentsToAnotherSpaceError: action.error });
        }
    }
    return result;
}
