import { InjectionToken } from "@angular/core";

/**
 * DI-токен для аннотации параметра конструктора.
 */
export const MAT_MENU_OVERLAY_PANEL_CLASS = new InjectionToken<string>('Overlay panel class for mat-menu');

/**
 * Поставщик значения css-класса, которое нужно установить промежуточному контейнеру при отображении 
 * интересующего нас компонента в блоке overlay.
 * 
 * Это единственный нормальный путь кастомизации Material, когда его компоненты выпадают из области
 * стилизации нашего компонента из-за того, что по факту отображаются в overlay'е. 
 */
export const overlayPanelClassProvider = {
    provide: MAT_MENU_OVERLAY_PANEL_CLASS, 
    useValue: 'entera-panel entera-mat-menu-panel'
};