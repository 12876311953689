var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { requestState } from "src/app/common/models/request-state";
import { MatchSettingsActionType } from "src/app/spaces/modules/document-matching/store/actions/match-settings.actions";
/**
 * Начальное состояние настроек сопоставления.
 */
var initialState = {
    /**
     * Начальное состояние запроса настроек сопоставления.
     */
    matchSettingsRequest: requestState.initial(),
    /**
     * Начальное состояние запроса обновления настроек сопоставления.
     */
    matchSettingsUpdateRequest: requestState.initial(),
};
/**
 * Обработчик событий, связанных с изменением состояния настроек сопоставления.
 *
 * @param state Текущее состояние настроек сопоставления.
 * @param action Событие.
 *
 * @return Новое состояние.
 */
export function matchSettingsReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        case MatchSettingsActionType.LOAD: {
            result = __assign({}, state, { matchSettingsRequest: requestState.pending() });
            break;
        }
        case MatchSettingsActionType.LOAD_SUCCESS: {
            result = __assign({}, state, { matchSettingsRequest: requestState.success(action) });
            break;
        }
        case MatchSettingsActionType.LOAD_FAIL: {
            result = __assign({}, state, { matchSettingsRequest: requestState.fail(action.error) });
            break;
        }
        case MatchSettingsActionType.UPDATE_INIT: {
            result = __assign({}, state, { matchSettingsUpdateRequest: requestState.initial() });
            break;
        }
        case MatchSettingsActionType.UPDATE: {
            result = __assign({}, state, { matchSettingsUpdateRequest: requestState.pending() });
            break;
        }
        case MatchSettingsActionType.UPDATE_SUCCESS: {
            result = __assign({}, state, { matchSettingsRequest: requestState.success(action), matchSettingsUpdateRequest: requestState.success(action) });
            break;
        }
        case MatchSettingsActionType.UPDATE_FAIL: {
            result = __assign({}, state, { matchSettingsUpdateRequest: requestState.fail(action.error) });
            break;
        }
    }
    return result;
}
