import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { HeaderHideSpaceSelectAction } from "../store";
import { headerShowSpacesInfoSelector } from "../store";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
/**
 * Route Guard.
 * Логика обработки попытки входа по пути, для которого требуется скрыть заголовка сайта.
 */
var HideHeaderSpacesInfoGuard = /** @class */ (function () {
    //region Ctor
    function HideHeaderSpacesInfoGuard(_store) {
        this._store = _store;
    }
    //endregion
    //region Public
    HideHeaderSpacesInfoGuard.prototype.canActivate = function () {
        var _this = this;
        return this._store
            .pipe(select(headerShowSpacesInfoSelector), 
        // Если заголовок не виден, то запускаем его отображение.
        tap(function (show) {
            if (show) {
                _this._store.dispatch(new HeaderHideSpaceSelectAction());
            }
        }), 
        // Даём разрешение только тогда, когда заголовок станет виден.
        map(function (show) { return !show; }));
    };
    HideHeaderSpacesInfoGuard.prototype.canActivateChild = function () {
        return this.canActivate();
    };
    HideHeaderSpacesInfoGuard.ngInjectableDef = i0.defineInjectable({ factory: function HideHeaderSpacesInfoGuard_Factory() { return new HideHeaderSpacesInfoGuard(i0.inject(i1.Store)); }, token: HideHeaderSpacesInfoGuard, providedIn: "root" });
    return HideHeaderSpacesInfoGuard;
}());
export { HideHeaderSpacesInfoGuard };
