import { Input } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";

/**
 * Компонент блока с иконкой информации.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "notification-with-info",
    templateUrl: "./notification-with-info.component.html",
    styleUrls: ["./notification-with-info.component.scss"],
})
export class NotificationWithInfoComponent {
    //region Inputs

    /**
     * Стиль уведомления с иконкой.
     */
    @Input()
    level: "warn" | "info";

    //endregion
}
