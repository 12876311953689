import { MatDialogRef } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
/**
 * Компонент простого диалога с текстом и 3 разноцветными кнопками.
 */
var WhiteRedGreenButtonsDlgComponent = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор компонента простого диалога с текстом и 3 разноцветными кнопками.
     *
     * @param _dialogRef Компонент диалога.
     * @param _changeDetector Сервис для управления запуском определения angular'ом изменений данных,
     * произошедших в компоненте.
     * @param data Данные для простых диалогов.
     * @param translateService Сервис для работы с i18n-сообщениями.
     */
    function WhiteRedGreenButtonsDlgComponent(_dialogRef, data, translateService) {
        this._dialogRef = _dialogRef;
        /**
         * Подписки компонента.
         */
        this._subscriptions = [];
        this._setData(data);
        this._translateService = translateService;
    }
    //endregion
    //region Hooks
    /**
     * Логика при инициализации компонента.
     */
    WhiteRedGreenButtonsDlgComponent.prototype.ngOnInit = function () {
        // Использование afterClosed() не вызывает cancelCallback
        this._subscriptions.push(this._dialogRef.beforeClose().subscribe(this._data.closeCallback));
    };
    /**
     * Логика при уничтожении компонента.
     */
    WhiteRedGreenButtonsDlgComponent.prototype.ngOnDestroy = function () {
        // Отписываемся от всех подписок.
        this._subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    Object.defineProperty(WhiteRedGreenButtonsDlgComponent.prototype, "whiteBtnKey", {
        //endregion
        //region Getters and Setters
        /**
         * I18n-ключ текста белой кнопки диалога.
         */
        get: function () {
            return this._data.whiteBtnKey;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WhiteRedGreenButtonsDlgComponent.prototype, "redBtnKey", {
        /**
         * I18n-ключ текста красной кнопки диалога.
         */
        get: function () {
            return this._data.redBtnKey;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WhiteRedGreenButtonsDlgComponent.prototype, "greenBtnKey", {
        /**
         * I18n-ключ текста зеленой кнопки диалога.
         */
        get: function () {
            return this._data.greenBtnKey;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WhiteRedGreenButtonsDlgComponent.prototype, "text", {
        /**
         * Текст диалога.
         */
        get: function () {
            var result;
            if (this._data.text) {
                result = this._data.text;
            }
            else {
                result = this._translateService.get(this.textKey, this.textParams);
            }
            return result;
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Events
    /**
     * Обработчик клика по красной кнопке диалога.
     */
    WhiteRedGreenButtonsDlgComponent.prototype.redClickHandler = function () {
        if (this._data.redCallback) {
            this._data.redCallback();
        }
        this._dialogRef.close();
    };
    /**
     * Обработчик клика по зеленой кнопке диалога.
     */
    WhiteRedGreenButtonsDlgComponent.prototype.greenClickHandler = function () {
        if (this._data.greenCallback) {
            this._data.greenCallback();
        }
        this._dialogRef.close();
    };
    /**
     * Обработчик клика по белой кнопке диалога.
     */
    WhiteRedGreenButtonsDlgComponent.prototype.whiteClickHandler = function () {
        if (this._data.whiteCallback) {
            this._data.whiteCallback();
        }
        this._dialogRef.close();
    };
    //endregion
    //region Private
    /**
     * Выполняет обновление данных диалога на основе заданных данных.
     *
     * @param data Данные диалога.
     *
     * @private
     */
    WhiteRedGreenButtonsDlgComponent.prototype._setData = function (data) {
        this._data = data;
        this.headerKey = this._data.headerKey;
        this.headerParams = this._data.headerParams || {};
        this.textKey = this._data.textKey;
        this.textParams = this._data.textParams || {};
        this.loading = this._data.loading;
        this.error = this._data.error;
    };
    return WhiteRedGreenButtonsDlgComponent;
}());
export { WhiteRedGreenButtonsDlgComponent };
