import { OnDestroy } from "@angular/core";
import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Validators } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import { select } from "@ngrx/store";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { map } from "rxjs/operators";
import { ApiResponse } from "src/app/common/models/api-response";
import { Space } from "src/app/common/models/space";
import { GenerateHashForFilesAction } from "src/app/root/store/actions/recognize/upload-to-recognize.action";
import { UploadTaskByMobileToRecognizeAction } from "src/app/root/store/actions/recognize/upload-to-recognize.action";
import { ChangeTaskToRecognizeAction } from "src/app/root/store/actions/recognize/upload-to-recognize.action";
import { RemoveTaskToRecognizeAction } from "src/app/root/store/actions/recognize/upload-to-recognize.action";
import { RouterGoAction } from "src/app/root/store/actions/router.action";
import { uploadToRecognizeSelector } from "src/app/root/store/reducers/index";
import { UploadToRecognizeState } from "src/app/root/store/reducers/index";
import { RootState } from "src/app/root/store/reducers/index";
import { SelectedSpaceForMobileSelectors } from "src/app/root/store/selectors/selected-space-for-mobile.selector";

/**
 * Компонент мобильного интерфейса для загрузки документов на распознавание.
 */
@Component({
    selector: "mobile-upload-document",
    styleUrls: ["./mobile-document-upload.component.scss"],
    templateUrl: "./mobile-document-upload.component.html"
})
export class MobileDocumentUploadComponent implements OnInit, OnDestroy {
    //region Public Fields

    /**
     * Состояние приложения.
     */
    readonly store: Store<RootState>;

    /**
     * Переключатель, чтобы при выполнении onDestroy произошли отписки от всех подписок.
     *
     */
    readonly destroyed: Subject<void> = new Subject();

    /**
     * Состояние данных для отправки файлов на распознавание.
     */
    readonly uploadToRecognizeState$: Observable<UploadToRecognizeState>;

    /**
     * ID пространства выбранного документа.
     */
    readonly space$: Observable<Space>;

    /**
     * Необходимо отображать процесс загрузки в интерфейсе?
     */
    readonly loading$: Observable<boolean>;

    /**
     * Ошибка в процессе отправки документов на распознавание?
     */
    readonly error$: Observable<ApiResponse>;

    /**
     * Процесс отправки документов успешно завершен?
     */
    readonly loaded$: Observable<boolean>;

    /**
     * Группа контролов для отправки документа.
     */
    readonly mobileFormGroup: FormGroup;

    //endregion
    //region Ctor

    /**
     * Конструктор мобильного интерфейса для загрузки документов на распознавание.
     *
     * @param store Сервис для доступа и управления состоянием приложения.
     * @param formBuilder Сервис для создания реактивных форм.
     */
    constructor (store: Store<RootState>, formBuilder: FormBuilder) {

        this.store = store;
        this.loading$ = this.store.select(uploadToRecognizeSelector)
            .pipe(
                takeUntil(this.destroyed),
                map((state: UploadToRecognizeState): boolean => state.loading),
            );
        this.error$ = this.store.select(uploadToRecognizeSelector)
            .pipe(
                takeUntil(this.destroyed),
                map((state: UploadToRecognizeState): ApiResponse => state.error),
            );
        this.loaded$ = this.store.select(uploadToRecognizeSelector)
            .pipe(
                takeUntil(this.destroyed),
                map((state: UploadToRecognizeState): boolean => state.loaded),
            );
        this.space$ = this.store
            .pipe(
                takeUntil(this.destroyed),
                select(SelectedSpaceForMobileSelectors.space),
            );
        this.uploadToRecognizeState$ = this.store.select(uploadToRecognizeSelector)
            .pipe(
                takeUntil(this.destroyed),
            );

        this.mobileFormGroup = formBuilder.group({
            comment: formBuilder.control(null, [Validators.required]),
        });
    }

    //endregion
    //region Hooks

    ngOnInit(): void {

        this.store
            .pipe(
                takeUntil(this.destroyed),
                select(uploadToRecognizeSelector)
            )
    }

    ngOnDestroy(): void {

        this.destroyed.next();
        this.destroyed.complete();
    }

    //endregion

    /**
     * Переход на корневую страницу мобильного интерфейса приложения.
     */
    cancelDocumentUpload(): void {

        this.store.dispatch(new RouterGoAction({path: ["mobile"]}));
    }

    /**
     * Обработчик добавления файлов в массив файлов в состоянии для их отправки на распознавание. Фильтрует файлы,
     * которые уже есть в состоянии.
     *
     * @param files Файлы, которые нужно добавить.
     */
    addFilesHandler(files: File[]) {

        this.store.dispatch(new GenerateHashForFilesAction(files.filter(file => !!file.size)));
    }

    /**
     * Обработчик удаления файлов из массива файлов для отправки на распознавание.
     *
     * @param file Файл, который нужно удалить.
     */
    removeFileHandler(file: File) {

        this.store.dispatch(new RemoveTaskToRecognizeAction(file));
    }

    /**
     * Обработчик возвращения состояния данных при загрузке и отправке файлов на распознавание к первоначальному
     * состоянию.
     */
    clearState() {

        this.store.dispatch(
            new ChangeTaskToRecognizeAction({...new UploadToRecognizeState(), openPostUploadDialog: false})
        );
    }

    /**
     * Обработчик отправки файлов на распознавание.
     */
    uploadToRecognize() {

        this.store.dispatch(
            new UploadTaskByMobileToRecognizeAction(this.mobileFormGroup.get("comment").value)
        );
    }

    //endregion
}
