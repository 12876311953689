/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./forbidden-error-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../error-details/error-details.component.ngfactory";
import * as i3 from "../error-details/error-details.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "./forbidden-error-details.component";
var styles_ForbiddenErrorDetailsComponent = [i0.styles];
var RenderType_ForbiddenErrorDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ForbiddenErrorDetailsComponent, data: {} });
export { RenderType_ForbiddenErrorDetailsComponent as RenderType_ForbiddenErrorDetailsComponent };
function View_ForbiddenErrorDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["."]))], null, null); }
function View_ForbiddenErrorDetailsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "br", [], null, null, null, null, null))], null, null); }
function View_ForbiddenErrorDetailsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "error-details__text_highlighted"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.path; _ck(_v, 1, 0, currVal_0); }); }
export function View_ForbiddenErrorDetailsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "forbidden-error-details"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "error-details", [["errorCode", "403"]], null, null, null, i2.View_ErrorDetailsComponent_0, i2.RenderType_ErrorDetailsComponent)), i1.ɵdid(2, 114688, null, 0, i3.ErrorDetailsComponent, [], { errorCode: [0, "errorCode"], hideCloseBtn: [1, "hideCloseBtn"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 9, "p", [["class", "error-details__text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForbiddenErrorDetailsComponent_1)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForbiddenErrorDetailsComponent_2)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForbiddenErrorDetailsComponent_3)), i1.ɵdid(12, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "403"; var currVal_1 = true; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = !_co.path; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.path; _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.path; _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("errorDetails.pageForbidden")); _ck(_v, 5, 0, currVal_2); }); }
export function View_ForbiddenErrorDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "forbidden-error-details", [], null, null, null, View_ForbiddenErrorDetailsComponent_0, RenderType_ForbiddenErrorDetailsComponent)), i1.ɵdid(1, 114688, null, 0, i6.ForbiddenErrorDetailsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForbiddenErrorDetailsComponentNgFactory = i1.ɵccf("forbidden-error-details", i6.ForbiddenErrorDetailsComponent, View_ForbiddenErrorDetailsComponent_Host_0, { path: "path" }, {}, []);
export { ForbiddenErrorDetailsComponentNgFactory as ForbiddenErrorDetailsComponentNgFactory };
