import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
/**
 * Сервис для работы с Google Analytics.
 */
var GoogleAnalyticsService = /** @class */ (function () {
    //endregion
    //region Ctor
    function GoogleAnalyticsService() {
        //region Fields
        /**
         * Скрипт Google Analytics добавлен на страницу?
         *
         * @private
         */
        this._scriptInserted = false;
        // Сразу создаётся функция, которая называется "очередь команд Google Analytics".
        window['ga'] = window['ga'] || function () { window['ga'].q.push(arguments); };
        this._ga.q = this._ga.q || [];
        this._ga.l = +new Date;
    }
    Object.defineProperty(GoogleAnalyticsService.prototype, "enabled", {
        //endregion
        //region Getters and Setters
        /**
         * Google Analytics включён?
         */
        get: function () {
            return (environment.ga
                && environment.ga.trackers
                && environment.ga.trackers.length
                && environment.ga.enabled);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GoogleAnalyticsService.prototype, "_ga", {
        /**
         * Очередь команд Google Analytics.
         */
        get: function () {
            return window['ga'];
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Public
    /**
     * Выполняет добавление скрипта Google Analytics на страницу, если это необходимо.
     */
    GoogleAnalyticsService.prototype.insertScript = function () {
        if (this.enabled && !this._scriptInserted) {
            var gaScript = document.createElement('script');
            gaScript.async = true;
            gaScript.src = 'https://www.google-analytics.com/analytics.js';
            var firstScript = document.getElementsByTagName('script')[0];
            firstScript.parentNode.insertBefore(gaScript, firstScript);
            this._ga('create', environment.ga.trackers[0].id, 'auto');
            this._scriptInserted = true;
        }
    };
    /**
     * Отправляет в Google Analytics заданное событие.
     *
     * @param event Данные события.
     */
    GoogleAnalyticsService.prototype.fireEvent = function (event) {
        if (this.enabled) {
            var fieldsObject = {
                hitType: 'event',
                eventCategory: event.eventCategory,
                eventAction: event.eventAction,
            };
            if (event.eventLabel) {
                fieldsObject.eventLabel = event.eventLabel;
            }
            if (event.eventValue) {
                fieldsObject.eventValue = event.eventValue;
            }
            this._ga('send', fieldsObject);
        }
    };
    GoogleAnalyticsService.ngInjectableDef = i0.defineInjectable({ factory: function GoogleAnalyticsService_Factory() { return new GoogleAnalyticsService(); }, token: GoogleAnalyticsService, providedIn: "root" });
    return GoogleAnalyticsService;
}());
export { GoogleAnalyticsService };
