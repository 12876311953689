import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';

import { LangActionType, LangChangeAction } from '../actions';

/**
 * Side-эффекты на события, связанные с языком интерфейса.
 */
@Injectable()
export class LangEffects {
    //region Ctor

    constructor(
        private actions$: Actions,
        private translateService: TranslateService
    ) { }

    //endregion
    //region Effects

    /**
     * Обработка события изменения языка.
     */
    @Effect({ dispatch: false })
    changeLangEffect$ = this.actions$
        .pipe(
            ofType(LangActionType.CHANGE),
            map((action: LangChangeAction) => action.payload),
            tap((lang: string) => this.translateService.use(lang))
        );

    //endregion
}
