var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * Утилиты для работы с ошибками.
 */
var ErrorUtils = /** @class */ (function () {
    function ErrorUtils() {
    }
    //region Public
    /**
     * Возвращает данные об ошибке на основе HTTP-ответа.
     *
     * @param httpResponse HTTP-ответ.
     *
     * @return Данные об ошибке.
     */
    ErrorUtils.from = function (httpResponse) {
        var error = null;
        if (httpResponse.status === 0) {
            error = {
                result: false,
                code: httpResponse.status,
            };
        }
        else {
            error = __assign({}, httpResponse.error, { code: httpResponse.status });
        }
        return error;
    };
    return ErrorUtils;
}());
export { ErrorUtils };
