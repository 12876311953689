import { MoveDocumentsDlgAction } from "src/app/common/store/actions/move-documents-dlg.action";
import { MoveDocumentsDlgActionType } from "src/app/common/store/actions/move-documents-dlg.action";
import { MoveDocumentsDlgState } from "src/app/common/store/states/move-documents-dlg.state";

/**
 * Начальное состояние диалога перемещения документов.
 */
const initialState = new MoveDocumentsDlgState();

/**
 * Обработчик событий, связанных с диалогом перемещения документов.
 *
 * @param state Состояние диалога перемещения документов.
 * @param action Событие произошедшее в системе.
 */
export function moveDocumentsDlgReducer(state = initialState, action: MoveDocumentsDlgAction): MoveDocumentsDlgState {

    let result = state;

    switch (action.type) {

        case MoveDocumentsDlgActionType.INIT_DIALOG: {

            result = new MoveDocumentsDlgState();
            break;
        }

        // Требование переместить документ в другую папку
        case MoveDocumentsDlgActionType.MOVE_TO_ANOTHER_SPACE: {

            return {
                ...state,
                movingDocumentsToAnotherSpace: true,
                movingDocumentsToAnotherSpaceSuccess: false,
                movingDocumentsToAnotherSpaceError: null,
            };
        }

        // Документ успешно перемещен в другую папку
        case MoveDocumentsDlgActionType.MOVE_TO_ANOTHER_SPACE_SUCCESS: {

            return {
                ...state,
                movingDocumentsToAnotherSpace: false,
                movingDocumentsToAnotherSpaceSuccess: true,
                movingDocumentsToAnotherSpaceError: null,
            };
        }

        // Перемещение документа в другую папку завершилось ошибкой
        case MoveDocumentsDlgActionType.MOVE_TO_ANOTHER_SPACE_FAIL: {

            return {
                ...state,
                movingDocumentsToAnotherSpace: false,
                movingDocumentsToAnotherSpaceSuccess: false,
                movingDocumentsToAnotherSpaceError: action.error,
            };
        }
    }

    return result;
}
