import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';
import { of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { RouterGoAction } from '../../root/store';
import { currentUserInfoSelector } from '../../root/store';
import { RouteService } from 'src/app/common/services/route.service';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "../../common/services/route.service";
/**
 * Route Guard.
 * Логика обработки попытки входа по рутовому пути '/' в зависимости от количества доступных пользователю
 * пространств документов.
 *
 * Должен использоваться совместо с UserGuard, который инициирует загрузку данных пользователя.
 */
var RootPathGuard = /** @class */ (function () {
    //region Ctor
    function RootPathGuard(store, routeService) {
        this.store = store;
        this.routeService = routeService;
    }
    //endregion
    //region Public
    RootPathGuard.prototype.canActivate = function () {
        var _this = this;
        return this.store
            .pipe(select(currentUserInfoSelector), 
        // Логика будет выполняться только в случае наличия данных о пользователе.
        filter(function (currentUser) { return !!currentUser; }), 
        // В зависимости от количества доступных пользователю пространств документов решается куда 
        // его направить.
        tap(function (currentUser) {
            var actionPayload = _this.routeService.getUserSpacesInitRoute(currentUser);
            setTimeout(function () { return _this.store.dispatch(new RouterGoAction(actionPayload)); });
        }), 
        // По факту в любом варианте будет редирект, поэтому здесь всегда возвращается true.
        switchMap(function () { return of(true); }));
    };
    RootPathGuard.ngInjectableDef = i0.defineInjectable({ factory: function RootPathGuard_Factory() { return new RootPathGuard(i0.inject(i1.Store), i0.inject(i2.RouteService)); }, token: RootPathGuard, providedIn: "root" });
    return RootPathGuard;
}());
export { RootPathGuard };
