import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { CanActivateChild } from '@angular/router';

import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';

import { RootState } from '../store';
import { HeaderShowAction } from '../store';
import { headerVisibleSelector } from '../store';

/**
 * Route Guard.
 * Логика обработки попытки входа по пути, для которого требуется видимость заголовка сайта.
 */
@Injectable({
    providedIn: 'root'
})
export class ShowHeaderGuard implements CanActivate, CanActivateChild {
    //region Ctor

    constructor(
        private _store: Store<RootState>
    ) { }

    //endregion
    //region Public

    canActivate(): Observable<boolean> {

        return this._store
            .pipe(
                select(headerVisibleSelector),

                // Если заголовок не виден, то запускаем его отображение.
                tap((visible) => {

                    if (!visible) {

                        this._store.dispatch(new HeaderShowAction());
                    }
                }),

                // Даём разрешение только тогда, когда заголовок станет виден.
                map((visible) => visible)
            );
    }

    canActivateChild() {

        return this.canActivate();
    }

    //endregion
}
