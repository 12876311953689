/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mobile-document-upload-file-row.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-pipes";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i9 from "@angular/material/icon";
import * as i10 from "./mobile-document-upload-file-row.component";
var styles_MobileDocumentUploadFileRowComponent = [i0.styles];
var RenderType_MobileDocumentUploadFileRowComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MobileDocumentUploadFileRowComponent, data: {} });
export { RenderType_MobileDocumentUploadFileRowComponent as RenderType_MobileDocumentUploadFileRowComponent };
export function View_MobileDocumentUploadFileRowComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.BytesPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "mobile-document-upload-file-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "mobile-document-upload-file-row__file-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "mobile-document-upload-file-row__file-size"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵppd(6, 2), (_l()(), i1.ɵeld(7, 0, null, null, 4, "button", [["class", "mobile-document-upload-file-row__remove-button mat-error"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeFile(_co.fileWithMetaData.file) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(8, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵeld(9, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i1.ɵdid(10, 9158656, null, 0, i9.MatIcon, [i1.ElementRef, i9.MatIconRegistry, [8, null], [2, i9.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["highlight_off"]))], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.loading; _ck(_v, 8, 0, currVal_4); _ck(_v, 10, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fileWithMetaData.file.name; _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 0), _co.fileWithMetaData.file.size, 2)); _ck(_v, 5, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 8).disabled || null); var currVal_3 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵnov(_v, 10).inline; var currVal_6 = (((i1.ɵnov(_v, 10).color !== "primary") && (i1.ɵnov(_v, 10).color !== "accent")) && (i1.ɵnov(_v, 10).color !== "warn")); _ck(_v, 9, 0, currVal_5, currVal_6); }); }
export function View_MobileDocumentUploadFileRowComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mobile-document-upload-file-row", [], null, null, null, View_MobileDocumentUploadFileRowComponent_0, RenderType_MobileDocumentUploadFileRowComponent)), i1.ɵdid(1, 49152, null, 0, i10.MobileDocumentUploadFileRowComponent, [], null, null)], null, null); }
var MobileDocumentUploadFileRowComponentNgFactory = i1.ɵccf("mobile-document-upload-file-row", i10.MobileDocumentUploadFileRowComponent, View_MobileDocumentUploadFileRowComponent_Host_0, { loading: "loading", error: "error", fileWithMetaData: "fileWithMetaData" }, { remove: "remove" }, []);
export { MobileDocumentUploadFileRowComponentNgFactory as MobileDocumentUploadFileRowComponentNgFactory };
