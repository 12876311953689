var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from "@angular/common/http";
import { throwError } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
/**
 * Сервис для работы с правами доступа.
 */
var PermissionService = /** @class */ (function () {
    //region Ctor
    function PermissionService(http) {
        this.http = http;
    }
    //endregion
    //region Public
    /**
     * Пользователь имеет заданное право?
     *
     * @param permission Право.
     * @param spaceId id спейса.
     */
    PermissionService.prototype.hasPermission = function (permission, spaceId) {
        var params = {
            permission: permission.value,
        };
        if (spaceId) {
            params = __assign({}, params, { spaceId: spaceId });
        }
        return this.http
            .get('/api/v1/currentUser/hasPermission', {
            params: params
        })
            .pipe(map(function (response) { return response.hasPermission; }), catchError(function (response) { return throwError(response.error); }));
    };
    PermissionService.ngInjectableDef = i0.defineInjectable({ factory: function PermissionService_Factory() { return new PermissionService(i0.inject(i1.HttpClient)); }, token: PermissionService, providedIn: "root" });
    return PermissionService;
}());
export { PermissionService };
