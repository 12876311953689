import { EventEmitter } from "@angular/core";
import { environment } from "src/environments/environment";
import { currentUserPermissionsSelector } from "rootStore";
import { map } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { PermissionItem } from "src/app/common/models";
/**
 * Компонент фиксированного header'а сайта.
 */
var EnteraHeaderComponent = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор компоненты фиксированного header'а сайта.
     *
     * @param _store Состояние приложения.
     */
    function EnteraHeaderComponent(_store) {
        this._store = _store;
        //endregion
        //region Outputs
        /**
         * Исходящее событие - клик по лого.
         */
        this.logo = new EventEmitter();
        /**
         * Исходящее событие - клик по кнопке интеграций.
         */
        this.integration = new EventEmitter();
        /**
         * Исходящее событие - требование перейти в личный кабинет пользователя.
         */
        this.userAccount = new EventEmitter();
        /**
         * Исходящее событие - требование выйти из системы.
         */
        this.logout = new EventEmitter();
        /**
         * Исходящее событие - требование создания нового пространство документов.
         */
        this.createSpace = new EventEmitter();
        /**
         * Исходящее событие - требование перехода в выбранное пространство документов.
         */
        this.openSpace = new EventEmitter();
        /**
         * Исходящее событие - требование перехода в настройки выбранного пространства документов.
         */
        this.spaceSettings = new EventEmitter();
        /**
         * Исходящее событие - требование открыть диалог для приглашения пользователей в пространства документов.
         */
        this.openInvitationDialog = new EventEmitter();
        /**
         * Исходящее событие - требование открыть диалог с неизвестной ошибкой.
         */
        this.openErrorDialog = new EventEmitter();
        /**
         * Исходящее событие - требование перейти на страницу оплаты для пространства документов.
         */
        this.spaceBuy = new EventEmitter();
        /**
         * Исходящее событие - запрос открытия формы обратного звонка.
         */
        this.callBack = new EventEmitter();
        /**
         * Исходящее событие - запрос открытия страницы базы знаний.
         */
        this.knowledgeBase = new EventEmitter();
        /**
         * Исходящее событие - открыть диалог инструкции как начать пользоваться Entera.
         */
        this.howToStart = new EventEmitter();
        /**
         * Исходящее событие - клик по кнопке "Купить".
         */
        this.buyPages = new EventEmitter();
        this.canCreateSpace$ = this._store.select(currentUserPermissionsSelector)
            .pipe(map(function (value) {
            return !!value[PermissionItem.CREATE_SPACE.value];
        }));
    }
    Object.defineProperty(EnteraHeaderComponent.prototype, "separateBtnOnlyForTrials", {
        /**
         * Отдельная кнопка "Как начать" включена только для триальных клиентов?
         */
        get: function () {
            return environment.howToStart && environment.howToStart.onlyForTrials;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnteraHeaderComponent.prototype, "showHowToStartBtn", {
        /**
         * Отображать ли отдельную кнопку как начать пользоваться Entera?
         */
        get: function () {
            return environment.howToStart
                && (!this.separateBtnOnlyForTrials || (this.user && this.user.spaces.some(function (space) { return !space.paid; })))
                && this.howToStartButtonVisibility;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnteraHeaderComponent.prototype, "creditCount", {
        /**
         * Возвращает кол-во кредитов/страниц доступных в папке.
         */
        get: function () {
            return this.restCreditCount;
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Events
    /**
     * Обработчик клика по лого.
     */
    EnteraHeaderComponent.prototype.logoClick = function () {
        this.logo.emit();
    };
    /**
     * Обработчик клика по кнопке интеграций.
     */
    EnteraHeaderComponent.prototype.integrationClick = function () {
        this.integration.emit(this.space);
    };
    /**
     * Обработчик требования перейти в личный кабинет пользователя.
     */
    EnteraHeaderComponent.prototype.userAccountHandler = function () {
        this.userAccount.emit();
    };
    /**
     * Обработчик требования выйти из системы.
     */
    EnteraHeaderComponent.prototype.logoutHandler = function () {
        this.logout.emit();
    };
    /**
     * Обработчик требования открыть страницу создания нового пространства документов.
     */
    EnteraHeaderComponent.prototype.createSpaceHandler = function () {
        this.createSpace.emit();
    };
    /**
     * Обработчик требования открыть страницу выбранного пространства документов.
     */
    EnteraHeaderComponent.prototype.openSpaceHandler = function (space) {
        this.openSpace.emit(space);
    };
    /**
     * Обработчик требования открыть страницу настроек выбранного пространства документов.
     */
    EnteraHeaderComponent.prototype.spaceSettingsHandler = function (space) {
        this.spaceSettings.emit(space);
    };
    /**
     * Обработчик требования открыть диалог для приглашения пользователей в пространства документов.
     */
    EnteraHeaderComponent.prototype.openInvitationDialogHandler = function () {
        this.openInvitationDialog.emit();
    };
    /**
     * Обработчик требования открыть диалог с неизвестной ошибкой.
     */
    EnteraHeaderComponent.prototype.openErrorDialogHandler = function () {
        this.openErrorDialog.emit();
    };
    /**
     * Обработчик требования перейти на страницу оплаты для пространства документов.
     */
    EnteraHeaderComponent.prototype.buyHandler = function () {
        this.spaceBuy.emit(this.space);
    };
    EnteraHeaderComponent.prototype.callBackHandler = function () {
        this.callBack.emit();
    };
    /**
     * Обработчик события по клику на кнопку "База знаний".
     */
    EnteraHeaderComponent.prototype.knowledgeBaseHandler = function () {
        this.knowledgeBase.emit();
    };
    /**
     * Обработчик события по клику на кнопку "Как начать".
     */
    EnteraHeaderComponent.prototype.howToStartHandler = function () {
        this.howToStart.emit();
    };
    /**
     * Обработчик клика по кнопке "Купить".
     */
    EnteraHeaderComponent.prototype.buyPagesClick = function () {
        this.buyPages.emit();
    };
    /**
     * Высота заголовка.
     */
    EnteraHeaderComponent.HEIGHT = 57;
    return EnteraHeaderComponent;
}());
export { EnteraHeaderComponent };
