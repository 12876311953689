import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { ClientAdminInfo } from "src/app/admin/models/client-admin-info";
import { BalanceUpdateActionProps } from "src/app/admin/store/actions/props/balance-update-action.props";
import { ClientAdminInfoProps } from "src/app/admin/store/actions/props/client-admin-info.action-props";
import { ClientBalanceActionProps } from "src/app/admin/store/actions/props/client-balance.action-props";
import { ClientTransactionsActionProps } from "src/app/admin/store/actions/props/client-transactions.action-props";
import { SpaceIdProps } from "src/app/admin/store/actions/props/space-id.action-props";
import { SpacePriorityProps } from "src/app/admin/store/actions/props/space-priority.action-props";
import { SpaceProps } from "src/app/admin/store/actions/props/space.action-props";
import { SpaceIdsAndEmails } from "src/app/common/models/space-ids-and-emails.model";
import { ErrorResponseProps } from "src/app/root/store/actions/props/error-response.action-props";
import { ValueProps } from "src/app/root/store/actions/props/value-props";

/**
 * Типы событий, связанные с страницей управления клиентом.
 */
export enum ClientControlPageActionType {

    SELECT_CLIENT = "[Client control page] Select client",
    SELECT_SPACE = "[Client control page] Select space",
    UPDATE_SELECTED_SPACE_BALANCE = "[Client control page] Update selected space balance",
    SELECTED_SPACE_BALANCE_UPDATED_SUCCESSFULLY = "[Client control page] Selected space balance updated successfully",
    SELECTED_SPACE_BALANCE_UPDATE_ERROR = "[Client control page] Selected space balance update error",
    TOGGLE_SELECTED_SPACE_TRIAL = "[Client control page] Toggle selected space trial flag",
    UPDATE_SELECTED_SPACE_PRIORITY = "[Client control page] Toggle selected space high priority flag",
    TOGGLE_SELECTED_SPACE_USE_CLIENT_BALANCE = "[Client control page] Toggle selected space use client balance flag",
    TOGGLE_SELECTED_SPACE_FOREIGN = "[Client control page] Toggle selected space foreign flag",
    UPDATE_SELECTED_CLIENT = "[Client control page] Update selected client",
    UPDATE_SELECTED_CLIENT_BALANCE = "[Client control page] Update selected client's balance",
    UPDATE_SELECTED_SPACE = "[Client control page] Update selected space",
    UPDATE_SELECTED_SPACE_SUCCESS = "[Client control page] Selected space updated successfully",
    UPDATE_SELECTED_SPACE_FAIL = "[Client control page] Error while updating selected space",
    CREATE_NEW_SPACE = "[Client control page] Create new space",
    CREATE_NEW_SPACE_SUCCESS = "[Client control page] New space created successfully",
    CREATE_NEW_SPACE_FAIL = "[Client control page] Error while creating new space",
    UPDATE_SPACE_FILTER = "[Client control page] Update space filter",
    GET_SELECTED_CLIENT_TRANSACTIONS = "[Client control page] Get selected client's transactions",
    GET_SELECTED_CLIENT_TRANSACTIONS_SUCCESS = "[Client control page] Get selected client's transactions success",
    GET_SELECTED_CLIENT_TRANSACTIONS_FAIL = "[Client control page] Get selected client's transactions fail",
    UPDATE_USERS_INFO = "[Client control page] Update users info",
    UPDATE_TRUSTED_EMAILS_INFO = "[Client control page] Update trusted emails info",
    CLEAR_STATE = "[Client control page] Clear state",
}

/**
 * Событие выбора клиента.
 */
const selectClient = createAction(
    ClientControlPageActionType.SELECT_CLIENT,
    props<ClientAdminInfoProps>(),
);

/**
 * Событие выбора пространства документов.
 */
const selectSpace = createAction(
    ClientControlPageActionType.SELECT_SPACE,
    props<SpaceIdProps>(),
);

/**
 * Событие обновления баланса выбранного пространства документов.
 */
const updateSelectedSpaceBalance = createAction(
    ClientControlPageActionType.UPDATE_SELECTED_SPACE_BALANCE,
    props<BalanceUpdateActionProps>(),
);

/**
 * Событие успешного обновления баланса выбранного пространства документов.
 */
const selectedSpaceBalanceUpdatedSuccessfully = createAction(
    ClientControlPageActionType.SELECTED_SPACE_BALANCE_UPDATED_SUCCESSFULLY,
    props<BalanceUpdateActionProps>(),
);

/**
 * Событие неудачного обновления баланса выбранного пространства документов.
 */
const selectedSpaceBalanceUpdateError = createAction(
    ClientControlPageActionType.SELECTED_SPACE_BALANCE_UPDATE_ERROR,
    props<ErrorResponseProps<void>>()
);

/**
 * Событие отправки запроса на переключения TRIAL-приоритета пространства документов.
 */
const toggleSelectedSpaceTrial = createAction(
    ClientControlPageActionType.TOGGLE_SELECTED_SPACE_TRIAL,
    props<SpaceIdProps>(),
);

/**
 * Событие отправки запроса на изменение приоритета пространства документов.
 */
const updateSelectedSpacePriority = createAction(
    ClientControlPageActionType.UPDATE_SELECTED_SPACE_PRIORITY,
    props<SpacePriorityProps>(),
);

/**
 * Событие отправки запроса на переключения флага использования баланса клиента пространства документов.
 */
const toggleSelectedSpaceUseClientBalance = createAction(
    ClientControlPageActionType.TOGGLE_SELECTED_SPACE_USE_CLIENT_BALANCE,
    props<SpaceIdProps>(),
);

/**
 * Событие отправки запроса на переключения флага использования баланса клиента пространства документов.
 */
const toggleSelectedSpaceForeign = createAction(
    ClientControlPageActionType.TOGGLE_SELECTED_SPACE_FOREIGN,
    props<SpaceIdProps>(),
);

/**
 * Событие обновления данных выбранного клиента.
 */
const updateSelectedClient = createAction(
    ClientControlPageActionType.UPDATE_SELECTED_CLIENT,
    props<Partial<ClientAdminInfo>>(),
);

/**
 * Событие обновления данных о балансе выбранного клиента.
 */
const updateSelectedClientBalance = createAction(
    ClientControlPageActionType.UPDATE_SELECTED_CLIENT_BALANCE,
    props<ClientBalanceActionProps>(),
);

/**
 * Событие обновления данных выбранного пространства документов.
 */
const updateSelectedSpace = createAction(
    ClientControlPageActionType.UPDATE_SELECTED_SPACE,
    props<SpaceProps>(),
);

/**
 * Событие успешного обновления данных выбранного пространства документов.
 */
const updateSelectedSpaceSuccess = createAction(
    ClientControlPageActionType.UPDATE_SELECTED_SPACE_SUCCESS,
    props<SpaceProps>(),
);

/**
 * Событие неудачного обновления данных выбранного пространства документов.
 */
const updateSelectedSpaceFail = createAction(
    ClientControlPageActionType.UPDATE_SELECTED_SPACE_FAIL,
    props<ErrorResponseProps<void>>(),
);

/**
 * Событие создания нового пространства документов.
 */
const createNewSpace = createAction(
    ClientControlPageActionType.CREATE_NEW_SPACE,
    props<SpaceProps>(),
);

/**
 * Событие успешного создания нового пространства документов.
 */
const createNewSpaceSuccess = createAction(
    ClientControlPageActionType.CREATE_NEW_SPACE_SUCCESS,
    props<SpaceProps>(),
);

/**
 * Событие неудачного создания нового пространства документов.
 */
const createNewSpaceFail = createAction(
    ClientControlPageActionType.CREATE_NEW_SPACE_FAIL,
    props<ErrorResponseProps<void>>(),
);

/**
 * Событие обновления состояния фильтра списка пространств документов.
 */
const updateSpaceFilter = createAction(
    ClientControlPageActionType.UPDATE_SPACE_FILTER,
    props<ValueProps<string>>(),
);

/**
 * Событие получения данных транзакций клиента.
 */
const getSelectedClientTransactions = createAction(
    ClientControlPageActionType.GET_SELECTED_CLIENT_TRANSACTIONS,
    props<Partial<ClientAdminInfoProps>>(),
);

/**
 * Событие успешного получения данных транзакций клиента.
 */
const getSelectedClientTransactionsSuccess = createAction(
    ClientControlPageActionType.GET_SELECTED_CLIENT_TRANSACTIONS_SUCCESS,
    props<ClientTransactionsActionProps>(),
);

/**
 * Событие неудачного получения данных транзакций клиента.
 */
const getSelectedClientTransactionsFail = createAction(
    ClientControlPageActionType.GET_SELECTED_CLIENT_TRANSACTIONS_FAIL,
    props<ErrorResponseProps<void>>(),
);

/**
 * Событие обновления списка пользователей.
 */
const updateUsersInfo = createAction(
    ClientControlPageActionType.UPDATE_USERS_INFO,
    props<SpaceIdsAndEmails>(),
);

/**
 * Событие обновления списка доверенных почт.
 */
const updateTrustedEmailsInfo = createAction(
    ClientControlPageActionType.UPDATE_TRUSTED_EMAILS_INFO,
    props<SpaceIdsAndEmails>(),
);

/**
 * Событие очистки стейта.
 */
const clearState = createAction(
    ClientControlPageActionType.CLEAR_STATE,
);

/**
 * Тип, объединяющий все события, связанные с страницей управления клиентом.
 */
export type ClientControlPageAction =
    | ReturnType<typeof selectClient>
    | ReturnType<typeof selectSpace>
    | ReturnType<typeof updateSelectedSpaceBalance>
    | ReturnType<typeof selectedSpaceBalanceUpdatedSuccessfully>
    | ReturnType<typeof selectedSpaceBalanceUpdateError>
    | ReturnType<typeof toggleSelectedSpaceTrial>
    | ReturnType<typeof updateSelectedSpacePriority>
    | ReturnType<typeof toggleSelectedSpaceUseClientBalance>
    | ReturnType<typeof toggleSelectedSpaceForeign>
    | ReturnType<typeof updateSelectedClient>
    | ReturnType<typeof updateSelectedClientBalance>
    | ReturnType<typeof updateSelectedSpace>
    | ReturnType<typeof updateSelectedSpaceSuccess>
    | ReturnType<typeof updateSelectedSpaceFail>
    | ReturnType<typeof createNewSpace>
    | ReturnType<typeof createNewSpaceSuccess>
    | ReturnType<typeof createNewSpaceFail>
    | ReturnType<typeof updateSpaceFilter>
    | ReturnType<typeof updateSelectedClientBalance>
    | ReturnType<typeof getSelectedClientTransactions>
    | ReturnType<typeof getSelectedClientTransactionsSuccess>
    | ReturnType<typeof getSelectedClientTransactionsFail>
    | ReturnType<typeof updateUsersInfo>
    | ReturnType<typeof clearState>
    | ReturnType<typeof updateTrustedEmailsInfo>;

/**
 * Тип, объединяющий все события ошибок, связанные с страницей управления клиентом.
 */
export type ClientControlPageErrorAction =
    | ReturnType<typeof selectedSpaceBalanceUpdateError>
    | ReturnType<typeof updateSelectedSpaceFail>
    | ReturnType<typeof createNewSpaceFail>
    | ReturnType<typeof getSelectedClientTransactionsFail>;

/**
 * Все события, связанные с страницей управления клиентом.
 */
export const clientControlPageActions = {
    clearState: clearState,
    selectClient: selectClient,
    selectSpace: selectSpace,
    updateSelectedSpaceBalance: updateSelectedSpaceBalance,
    selectedSpaceBalanceUpdatedSuccessfully: selectedSpaceBalanceUpdatedSuccessfully,
    selectedSpaceBalanceUpdateError: selectedSpaceBalanceUpdateError,
    toggleSelectedSpaceTrial: toggleSelectedSpaceTrial,
    updateSelectedSpacePriority: updateSelectedSpacePriority,
    toggleSelectedSpaceUseClientBalance: toggleSelectedSpaceUseClientBalance,
    toggleSelectedSpaceForeign: toggleSelectedSpaceForeign,
    updateSelectedClient: updateSelectedClient,
    updateSelectedClientBalance: updateSelectedClientBalance,
    updateSelectedSpace: updateSelectedSpace,
    updateSelectedSpaceSuccess: updateSelectedSpaceSuccess,
    updateSelectedSpaceFail: updateSelectedSpaceFail,
    createNewSpace: createNewSpace,
    createNewSpaceSuccess: createNewSpaceSuccess,
    createNewSpaceFail: createNewSpaceFail,
    updateSpaceFilter: updateSpaceFilter,
    getSelectedClientTransactions: getSelectedClientTransactions,
    getSelectedClientTransactionsSuccess: getSelectedClientTransactionsSuccess,
    getSelectedClientTransactionsFail: getSelectedClientTransactionsFail,
    updateUsersInfo: updateUsersInfo,
    updateTrustedEmailsInfo: updateTrustedEmailsInfo,
};
