import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { ErrorResponseProps } from "src/app/root/store/actions/props/error-response.action-props";
import { MatchingDocumentProps } from "src/app/spaces/modules/document-matching/store/actions/props/matching-document.action.props";

/**
 * Типы событий обработки промежуточного состояния.
 */
export enum IntermediateActionType {
    SAVE_INTERMEDIATE = "[Document matching] Save intermediate state",
    SAVE_INTERMEDIATE_SUCCESS = "[Document matching] Successfully saved intermediate state",
    SAVE_INTERMEDIATE_FAIL = "[Document matching] Failed saving intermediate state",
    DELETE_MATCHES_IF_SAFE = "[Document matching] Safely delete document matches",
    DELETE_MATCHES_IF_SAFE_SUCCESS = "[Document matching] Successfully safely deleted document matches",
    DELETE_MATCHES_IF_SAFE_FAIL = "[Document matching] Failed to safely delete document matches",
    SAVE_AND_EXIT_MATCHES_IF_SAFE = "[Document matching] Save document and exit from matches",
    SAVE_AND_EXIT_IF_SAFE_SUCCESS = "[Document matching] Successfully save document and exit from matches",
    SAVE_AND_EXIT_IF_SAFE_FAIL = "[Document matching] Failed to save document and exit from matches",
}

/**
 * Событие сохранения промежуточного состояния сопоставления.
 */
const saveIntermediate = createAction(
    IntermediateActionType.SAVE_INTERMEDIATE,
    props<MatchingDocumentProps>(),
);

/**
 * Событие успешного сохранения промежуточного состояния сопоставления.
 */
const saveIntermediateSuccess = createAction(
    IntermediateActionType.SAVE_INTERMEDIATE_SUCCESS,
);

/**
 * Событие ошибки при сохранении промежуточного состояния сопоставления.
 */
const saveIntermediateFail = createAction(
    IntermediateActionType.SAVE_INTERMEDIATE_FAIL,
    props<ErrorResponseProps<void>>(),
);

/**
 * Событие попытки безопасно удалить сопоставления.
 */
const deleteMatchesIfSafe = createAction(
    IntermediateActionType.DELETE_MATCHES_IF_SAFE,
);

/**
 * Событие успешного запроса безопасно удалить сопоставления.
 */
const deleteMatchesIfSafeSuccess = createAction(
    IntermediateActionType.DELETE_MATCHES_IF_SAFE_SUCCESS,
);

/**
 * Событие ошибки при запросе безопасно удалить сопоставления.
 */
const deleteMatchesIfSafeFail = createAction(
    IntermediateActionType.DELETE_MATCHES_IF_SAFE_FAIL,
    props<ErrorResponseProps<void>>(),
);

/**
 * Событие попытки безопасно сохранить и выйти из сопоставления.
 */
const saveAndExitFromMatchesIfSafe = createAction(
    IntermediateActionType.SAVE_AND_EXIT_MATCHES_IF_SAFE,
    props<MatchingDocumentProps>(),
);

/**
 * Событие успешного сохранения и выхода из сопоставления.
 */
const saveAndExitFromMatchesIfSafeSuccess = createAction(
    IntermediateActionType.SAVE_AND_EXIT_IF_SAFE_SUCCESS,
);

/**
 * Событие ошибки при запросе сохранения и выхода из сопоставления сопоставления.
 */
const saveAndExitFromMatchesIfSafeFail = createAction(
    IntermediateActionType.SAVE_AND_EXIT_IF_SAFE_FAIL,
    props<ErrorResponseProps<void>>(),
);

/**
 * Тип, объединяющий события, связанные с обработкой промежуточного состояния.
 */
export type IntermediateAction =
    | ReturnType<typeof saveIntermediate>
    | ReturnType<typeof saveIntermediateSuccess>
    | ReturnType<typeof saveIntermediateFail>
    | ReturnType<typeof deleteMatchesIfSafe>
    | ReturnType<typeof deleteMatchesIfSafeSuccess>
    | ReturnType<typeof saveAndExitFromMatchesIfSafe>
    | ReturnType<typeof saveAndExitFromMatchesIfSafeSuccess>
    | ReturnType<typeof saveAndExitFromMatchesIfSafeFail>
    | ReturnType<typeof deleteMatchesIfSafeFail>;

/**
 * События, связанные с обработкой промежуточного состояния.
 */
export const intermediateActions = {
    saveIntermediate,
    saveIntermediateSuccess,
    saveIntermediateFail,
    deleteMatchesIfSafe: deleteMatchesIfSafe,
    deleteMatchesIfSafeSuccess: deleteMatchesIfSafeSuccess,
    deleteMatchesIfSafeFail: deleteMatchesIfSafeFail,
    saveAndExitFromMatchesIfSafe: saveAndExitFromMatchesIfSafe,
    saveAndExitFromMatchesIfSafeSuccess: saveAndExitFromMatchesIfSafeSuccess,
    saveAndExitFromMatchesIfSafeFail: saveAndExitFromMatchesIfSafeFail,
};
