/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./forbidden-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/components/forbidden-error-details/forbidden-error-details.component.ngfactory";
import * as i3 from "../../../common/components/forbidden-error-details/forbidden-error-details.component";
import * as i4 from "./forbidden-page.component";
import * as i5 from "@angular/router";
var styles_ForbiddenPageComponent = [i0.styles];
var RenderType_ForbiddenPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ForbiddenPageComponent, data: {} });
export { RenderType_ForbiddenPageComponent as RenderType_ForbiddenPageComponent };
export function View_ForbiddenPageComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "forbidden-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "forbidden-error-details", [["class", "forbidden-page__error-details"]], null, null, null, i2.View_ForbiddenErrorDetailsComponent_0, i2.RenderType_ForbiddenErrorDetailsComponent)), i1.ɵdid(2, 114688, null, 0, i3.ForbiddenErrorDetailsComponent, [], { path: [0, "path"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.path; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ForbiddenPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "forbidden-page", [], null, null, null, View_ForbiddenPageComponent_0, RenderType_ForbiddenPageComponent)), i1.ɵdid(1, 114688, null, 0, i4.ForbiddenPageComponent, [i5.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForbiddenPageComponentNgFactory = i1.ɵccf("forbidden-page", i4.ForbiddenPageComponent, View_ForbiddenPageComponent_Host_0, {}, {}, []);
export { ForbiddenPageComponentNgFactory as ForbiddenPageComponentNgFactory };
