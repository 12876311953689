var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FeedbackActionType } from "../../actions";
var initialState = {
    sent: false,
    sending: false,
    error: null
};
/**
 * Обработка состояния обратнйо связи в ответ на события.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 */
export function feedbackReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = __assign({}, state);
    switch (action.type) {
        case FeedbackActionType.INIT: {
            result = __assign({ type: action.payload.type, documentId: action.payload.documentId }, initialState);
            break;
        }
        case FeedbackActionType.SEND: {
            result = __assign({}, result, { sending: true, sent: false, error: null });
            break;
        }
        case FeedbackActionType.SEND_SUCCESS: {
            result = __assign({}, result, { sending: false, sent: true, error: null });
            break;
        }
        case FeedbackActionType.SEND_FAIL: {
            result = __assign({}, result, { sending: false, sent: false, error: action.payload });
            break;
        }
    }
    return result;
}
