/**
 * Данные, которые собираются с формы регистрации.
 */
export class RegistrationFormData {
    //region Public

    /**
     * Логин.
     */
    readonly login: string;

    /**
     * Пароль.
     */
    readonly password: string;

    /**
     * Телефон пользователя.
     */
    readonly phone: string;

    /**
     * Имя пользователя.
     */
    readonly userName: string;

    /**
     * ИНН пользователя.
     */
    readonly inn: string;

    /**
     * Промокод.
     */
    readonly promoCode: string;

    //endregion
    //region Ctor

    constructor(login: string, password: string, phone: string, name: string, inn: string, promoCode: string) {
        this.login = login;
        this.phone = phone;
        this.password = password;
        this.userName = name;
        this.inn = inn;
        this.promoCode = promoCode;
    }

    //endregion
}
