import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { GaEvent } from '../models/google-analytics';

/**
 * Сервис для работы с Google Analytics.
 */
@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsService {
    //region Fields

    /**
     * Скрипт Google Analytics добавлен на страницу?
     *
     * @private
     */
    private _scriptInserted: boolean = false;

    //endregion
    //region Ctor

    constructor() {

        // Сразу создаётся функция, которая называется "очередь команд Google Analytics".
        window['ga'] = window['ga'] || function() { window['ga'].q.push(arguments); };
        this._ga.q = this._ga.q || [];
        this._ga.l = +new Date;
    }

    //endregion
    //region Getters and Setters

    /**
     * Google Analytics включён?
     */
    get enabled(): boolean {

        return (
            environment.ga
            && environment.ga.trackers
            && environment.ga.trackers.length
            && environment.ga.enabled
        );
    }

    /**
     * Очередь команд Google Analytics.
     */
    private get _ga(): any {

        return window['ga'];
    }

    //endregion
    //region Public

    /**
     * Выполняет добавление скрипта Google Analytics на страницу, если это необходимо.
     */
    insertScript(): void {

        if (this.enabled && !this._scriptInserted) {

            const gaScript: HTMLScriptElement = document.createElement('script');
            gaScript.async = true;
            gaScript.src = 'https://www.google-analytics.com/analytics.js';

            const firstScript = document.getElementsByTagName('script')[0];
            firstScript.parentNode.insertBefore(gaScript, firstScript);

            this._ga('create', environment.ga.trackers[0].id, 'auto');

            this._scriptInserted = true;
        }
    }

    /**
     * Отправляет в Google Analytics заданное событие.
     *
     * @param event Данные события.
     */
    fireEvent(event: GaEvent): void {

        if (this.enabled) {

            const fieldsObject: any = {
                hitType: 'event',
                eventCategory: event.eventCategory,
                eventAction: event.eventAction,
            };

            if (event.eventLabel) {

                fieldsObject.eventLabel = event.eventLabel;
            }

            if (event.eventValue) {

                fieldsObject.eventValue = event.eventValue;
            }

            this._ga('send', fieldsObject);
        }
    }

    //endregion
}