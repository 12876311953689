import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material";
import { RecognitionTaskCreatedDlgComponent } from "src/app/root/containers/recognition-task-created-dlg/recognition-task-created-dlg.component"; /* circular dependency break */
import { Constants } from "../../common/models";
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@angular/common/http";
/**
 * Сервис для вызова диалога после создания задачи на распознавание.
 */
var RecognitionTaskCreatedDlgService = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор сервиса для вызова диалога после создания задачи на распознавание.
     *
     * @param dialog Сервис для работы с диалогами.
     * @param httpClient HTTP-клиент.
     */
    function RecognitionTaskCreatedDlgService(dialog, httpClient) {
        this._dialog = dialog;
        this._http = httpClient;
    }
    //endregion
    //region Public
    /**
     * Открывает диалог после создания задачи на распознавание.
     */
    RecognitionTaskCreatedDlgService.prototype.open = function (recognitionTask) {
        return this._dialog.open(RecognitionTaskCreatedDlgComponent, {
            data: recognitionTask,
            panelClass: [Constants.DIALOG_PANE_CLASS, "recognition-task-created-dlg-pane"],
            autoFocus: false,
            disableClose: true,
        });
    };
    RecognitionTaskCreatedDlgService.ngInjectableDef = i0.defineInjectable({ factory: function RecognitionTaskCreatedDlgService_Factory() { return new RecognitionTaskCreatedDlgService(i0.inject(i1.MatDialog), i0.inject(i2.HttpClient)); }, token: RecognitionTaskCreatedDlgService, providedIn: "root" });
    return RecognitionTaskCreatedDlgService;
}());
export { RecognitionTaskCreatedDlgService };
