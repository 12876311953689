import { HttpClient } from "@angular/common/http";
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { Observable } from "rxjs";
import { throwError } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { SpaceIdsAndEmails } from "src/app/common/models/space-ids-and-emails.model";
import { UrlUtils } from "src/app/common/utils/url.utils";
import { Space } from "../models";
import { ApiResponse } from "../models";
import { SpaceApiResponse } from "../models/api-responses";
import { NewDocumentsCountApiResponse } from "../models/api-responses";
import { SpaceBalanceApiResponse } from "../models/api-responses";

/**
 * Сервис для работы с пространствами документов.
 */
@Injectable({ 
    providedIn: "root" 
})
export class SpaceService {
    //region Fields

    /**
     * HTTP-клиент.
     */
    private readonly _http: HttpClient;

    //endregion
    //region Ctor

    /**
     * Конструктор сервиса для работы с пространствами документов.
     *
     * @param http HTTP-клиент.
     */
    constructor(http: HttpClient) {

        this._http = http;
    }

    //endregion
    //region Public

    /**
     * Создание нового пространства документов.
     * 
     * @param space Данные новое пространства документов.
     */
    create(space: Space): Observable<Space> {

        return this._http
            .post<SpaceApiResponse>("/api/v1/spaces", space)
            .pipe(
                map(response => response.space)
            );
    }

    /**
     * Обновление данных пространства документов.
     * 
     * @param space Обновлённые данные пространства документов.
     */
    update(space: Space): Observable<Space> {

        return this._http
            .put<SpaceApiResponse>(`/api/v1/spaces/${space.id}`, space)
            .pipe(
                map(response => response.space)
            );
    }

    /**
     * Возвращает кол-во новых документов для заданного пространства документов.
     * 
     * @param space Пространство документов.
     */
    getNewDocumentsCount(space: Space): Observable<number> {

        return this._http
            .get<NewDocumentsCountApiResponse>("/api/v1/documents/count/new", { params: { spaceId: space.id } })
            .pipe(
                map(response => response.newDocumentsCount),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse))
            );
    }

    /**
     * Возвращает баланс заданного пространства документов на распознавание документов.
     * 
     * @param space Пространство документов.
     */
    getBalance(space: Space): Observable<number> {

        if (!space) {
            return of(0);
        }
        return this._http
            .get<SpaceBalanceApiResponse>(`/api/v1/spaces/${space.id}/balance`)
            .pipe(
                map(response => response.balance),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse))
            );
    }

    /**
     * Приглашает пользователей в пространства документов.
     *
     * @param invitationsData Данные для приглашений пользователей в пространства документов.
     *
     * @return Успех выполнения операции.
     */
    invite(invitationsData: SpaceIdsAndEmails): Observable<boolean> {

        return this._http
            .post<ApiResponse>(UrlUtils.inviteApiUrl(), invitationsData)
            .pipe(
                map((response: ApiResponse): boolean => response.result),
                catchError((response: HttpErrorResponse): Observable<never> =>
                    throwError(response.error as ApiResponse)
                )
            );
    }

    /**
     * Приглашает пользователей в пространства документов.
     *
     * @param additionData Данные для приглашений пользователей в пространства документов.
     *
     * @return Успех выполнения операции.
     */
    addTrustedEmails(additionData: SpaceIdsAndEmails): Observable<boolean> {

        return this._http
            .post<ApiResponse>(UrlUtils.addTrustedEmailsApiUrl(), additionData)
            .pipe(
                map((response: ApiResponse): boolean => response.result),
                catchError((response: HttpErrorResponse): Observable<never> =>
                    throwError(response.error as ApiResponse)
                )
            );
    }

    //endregion
}
