import { ApiResponse } from 'src/app/common/models';

/**
 * Состояние диалога подтверждения телефона.
 */
export class PhoneConfirmDlgState {

    /**
     * Происходит запрос отпраки СМС?
     */
    smsSending: boolean =  false;

    /**
     * СМС отправлено?
     */
    smsSent: boolean = false;

    /**
     * Ошибка при отправке СМС.
     */
    smsSentError: ApiResponse = null;

    /**
     * Происходит запрос подтверждения кода?
     */
    codeConfirming: boolean =  false;

    /**
     * Код подтвержден?
     */
    codeConfirm: boolean = false;

    /**
     * Ошибка подтверждения кода.
     */
    codeConfirmError: ApiResponse = null;
}
