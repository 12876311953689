<div class="forbidden-error-details">
    <error-details
        errorCode="403"
        [hideCloseBtn]="true"
    >
        <p class="error-details__text">
            <span>{{ 'errorDetails.pageForbidden' | translate }}<span *ngIf="!path">.</span></span>
            <br *ngIf="path" />
            <span *ngIf="path"
                class="error-details__text_highlighted"
            >{{ path }}</span>
        </p>
    </error-details>
</div>