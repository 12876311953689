import { HttpClient } from "@angular/common/http";
import { of } from "rxjs";
import { throwError } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { UrlUtils } from "src/app/common/utils/url.utils";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
/**
 * Сервис для работы с пространствами документов.
 */
var SpaceService = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор сервиса для работы с пространствами документов.
     *
     * @param http HTTP-клиент.
     */
    function SpaceService(http) {
        this._http = http;
    }
    //endregion
    //region Public
    /**
     * Создание нового пространства документов.
     *
     * @param space Данные новое пространства документов.
     */
    SpaceService.prototype.create = function (space) {
        return this._http
            .post("/api/v1/spaces", space)
            .pipe(map(function (response) { return response.space; }));
    };
    /**
     * Обновление данных пространства документов.
     *
     * @param space Обновлённые данные пространства документов.
     */
    SpaceService.prototype.update = function (space) {
        return this._http
            .put("/api/v1/spaces/" + space.id, space)
            .pipe(map(function (response) { return response.space; }));
    };
    /**
     * Возвращает кол-во новых документов для заданного пространства документов.
     *
     * @param space Пространство документов.
     */
    SpaceService.prototype.getNewDocumentsCount = function (space) {
        return this._http
            .get("/api/v1/documents/count/new", { params: { spaceId: space.id } })
            .pipe(map(function (response) { return response.newDocumentsCount; }), catchError(function (response) { return throwError(response.error); }));
    };
    /**
     * Возвращает баланс заданного пространства документов на распознавание документов.
     *
     * @param space Пространство документов.
     */
    SpaceService.prototype.getBalance = function (space) {
        if (!space) {
            return of(0);
        }
        return this._http
            .get("/api/v1/spaces/" + space.id + "/balance")
            .pipe(map(function (response) { return response.balance; }), catchError(function (response) { return throwError(response.error); }));
    };
    /**
     * Приглашает пользователей в пространства документов.
     *
     * @param invitationsData Данные для приглашений пользователей в пространства документов.
     *
     * @return Успех выполнения операции.
     */
    SpaceService.prototype.invite = function (invitationsData) {
        return this._http
            .post(UrlUtils.inviteApiUrl(), invitationsData)
            .pipe(map(function (response) { return response.result; }), catchError(function (response) {
            return throwError(response.error);
        }));
    };
    /**
     * Приглашает пользователей в пространства документов.
     *
     * @param additionData Данные для приглашений пользователей в пространства документов.
     *
     * @return Успех выполнения операции.
     */
    SpaceService.prototype.addTrustedEmails = function (additionData) {
        return this._http
            .post(UrlUtils.addTrustedEmailsApiUrl(), additionData)
            .pipe(map(function (response) { return response.result; }), catchError(function (response) {
            return throwError(response.error);
        }));
    };
    SpaceService.ngInjectableDef = i0.defineInjectable({ factory: function SpaceService_Factory() { return new SpaceService(i0.inject(i1.HttpClient)); }, token: SpaceService, providedIn: "root" });
    return SpaceService;
}());
export { SpaceService };
