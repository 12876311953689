import { Action } from "@ngrx/store";
import { ApiResponse } from "src/app/common/models";
import { RecognitionTask } from "src/app/common/models";
import { Space } from "src/app/common/models";
import { ApiResponsePayloadAction } from "..";
import { EnteraDocument } from "src/app/common/models";

/**
 * Типы событий, связанные с задачами на распознование.
 */
export enum RecognitionTasksActionType {
    LOAD = '[Recognition tasks] Load running recognition tasks',
    LOAD_SUCCESS = '[Recognition tasks] Load running recognition tasks Success',
    LOAD_FAIL = '[Recognition tasks] Load running recognition tasks Fail',
    ADD_TASK = "[Recognition tasks] Add to active task",
    CHANGE_TASK_STATUS = "[Recognition tasks] Task status change",
    TASK_FINISHED = "[Recognition tasks] Remove task from upload list: task finish",
    TASK_ERROR = "[Recognition tasks] Remove task from upload list: task error",
    CLEAR = "[Recognition tasks] Clear recognition tasks",
    ADD_RECOGNIZED_DOCUMENTS = "[Recognition tasks] Add new recognized documents",
    LOAD_INITIAL_DOCUMENTS_SUCCESS = "[Recognition tasks] Load initial recognized documents Success",
    LOAD_INITIAL_DOCUMENTS = "[Recognition tasks] Load initial recognized documents"
}

export interface ChangeStatusPayload {
    taskId: string,
    state: string
}

/**
 * Событие, требующее загрузки выполняющихся задач на распознавание для пространства документов.
 */
export class RecognitionTasksLoadAction implements Action {
    readonly type = RecognitionTasksActionType.LOAD;
    constructor(public payload: Space) { }
}

/**
 * Событие успешной загрузки выполняющихся задач на распознавание для пространства документов.
 */
export class RecognitionTasksLoadSuccessAction implements Action {
    readonly type = RecognitionTasksActionType.LOAD_SUCCESS;
    constructor(public payload: RecognitionTask[]) { }
}

/**
 * Событие неудачной загрузки выполняющихся задач на распознавание для пространства документов.
 */
export class RecognitionTasksLoadFailAction implements ApiResponsePayloadAction {
    readonly type = RecognitionTasksActionType.LOAD_FAIL;
    constructor(public payload: ApiResponse) { }
}

/**
 * Событие добавления задачи на распознавание.
 */
export class AddRecognitionTaskAction implements Action {
    readonly type = RecognitionTasksActionType.ADD_TASK;
    constructor(public payload: RecognitionTask[]) { };
}

/**
 * Событие изменения статуса задачи на распознавание.
 */
export class RecognitionTaskChangeAction implements Action {
    readonly type = RecognitionTasksActionType.CHANGE_TASK_STATUS;
    constructor(public payload: RecognitionTask) { };
}

/**
 * Событие удачного завершения задачи на распознавание.
 */
export class RecognitionTaskFinishedAction implements Action {
    readonly type = RecognitionTasksActionType.TASK_FINISHED;
    constructor(public payload: RecognitionTask) { };
}

/**
 * Событие неудачного завершения задачи на распознавание.
 */
export class RecognitionTaskErrorAction implements Action {
    readonly type = RecognitionTasksActionType.TASK_ERROR;
    constructor(public payload: RecognitionTask) { };
}

/**
 * Событие, требующее очистки состояния задач на распознавание.
 * Сюда входит и остановка периодического опроса сервера о состоянии задач на распознавание.
 */
export class RecognitionTasksClearAction implements Action {
    readonly type = RecognitionTasksActionType.CLEAR;
    constructor() { }
}

/**
 * Событие добавления новых распознанных документов.
 */
export class AddRecognizedDocumentsAction implements Action {
    readonly type = RecognitionTasksActionType.ADD_RECOGNIZED_DOCUMENTS;
    constructor(public payload: EnteraDocument[]) { };
}

/**
 * Событие запроса обновления данных по распознанным документам.
 */
export class LoadInitialDocumentsAction implements Action {
    readonly type = RecognitionTasksActionType.LOAD_INITIAL_DOCUMENTS;
    constructor(public payload: Space) { };
}

/**
 * Событие обновления распознанных документов.
 */
export class LoadInitialDocumentsSuccessAction implements Action {
    readonly type = RecognitionTasksActionType.LOAD_INITIAL_DOCUMENTS_SUCCESS;
    constructor(public payload: EnteraDocument[]) { };
}

/**
 * Обощенный тип события.
 */
export type RecognitionTasksAction =
    | RecognitionTasksLoadAction
    | RecognitionTasksLoadSuccessAction
    | RecognitionTasksLoadFailAction
    | AddRecognitionTaskAction
    | RecognitionTaskChangeAction
    | RecognitionTaskFinishedAction
    | RecognitionTaskErrorAction
    | RecognitionTasksClearAction
    | AddRecognizedDocumentsAction
    | LoadInitialDocumentsAction
    | LoadInitialDocumentsSuccessAction
    ;
