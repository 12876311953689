/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./spaces-info-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../info-menu/info-menu.component.ngfactory";
import * as i3 from "../info-menu/info-menu.component";
import * as i4 from "../../providers/overlay-panel-class.provider";
import * as i5 from "./spaces-info-menu.component";
var styles_SpacesInfoMenuComponent = [i0.styles];
var RenderType_SpacesInfoMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SpacesInfoMenuComponent, data: {} });
export { RenderType_SpacesInfoMenuComponent as RenderType_SpacesInfoMenuComponent };
export function View_SpacesInfoMenuComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "spaces-info-menu"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "info-menu", [["class", "spaces-info-menu_menu"], ["hoveredIcon", "account_balance"], ["icon", "account_balance_outline"], ["iconFontSet", "material-icons-custom-set"], ["labelKey", "header.spaces.select.space"]], null, [[null, "menuItemClick"], [null, "rightItemMenuItemClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("menuItemClick" === en)) {
        var pd_0 = (_co.menuItemClickHandler($event) !== false);
        ad = (pd_0 && ad);
    } if (("rightItemMenuItemClick" === en)) {
        var pd_1 = (_co.menuItemRightIconClickHandler($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_InfoMenuComponent_0, i2.RenderType_InfoMenuComponent)), i1.ɵdid(2, 49152, null, 0, i3.InfoMenuComponent, [i4.MAT_MENU_OVERLAY_PANEL_CLASS], { icon: [0, "icon"], iconFontSet: [1, "iconFontSet"], hoveredIcon: [2, "hoveredIcon"], iconDisabled: [3, "iconDisabled"], label: [4, "label"], labelKey: [5, "labelKey"], menuItems: [6, "menuItems"], large: [7, "large"] }, { menuItemClick: "menuItemClick", rightItemMenuItemClick: "rightItemMenuItemClick" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "account_balance_outline"; var currVal_2 = "material-icons-custom-set"; var currVal_3 = "account_balance"; var currVal_4 = _co.iconDisabled; var currVal_5 = _co.spaceName; var currVal_6 = "header.spaces.select.space"; var currVal_7 = _co.menuItems; var currVal_8 = _co.large; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.spaceName, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_SpacesInfoMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "spaces-info-menu", [], null, null, null, View_SpacesInfoMenuComponent_0, RenderType_SpacesInfoMenuComponent)), i1.ɵdid(1, 114688, null, 0, i5.SpacesInfoMenuComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SpacesInfoMenuComponentNgFactory = i1.ɵccf("spaces-info-menu", i5.SpacesInfoMenuComponent, View_SpacesInfoMenuComponent_Host_0, { space: "space", spaces: "spaces", iconDisabled: "iconDisabled", large: "large", path: "path", canInviteInAnySpace: "canInviteInAnySpace", canCreateSpace: "canCreateSpace" }, { createSpace: "createSpace", openSpace: "openSpace", spaceSettings: "spaceSettings", openInvitationDialog: "openInvitationDialog", openErrorDialog: "openErrorDialog" }, []);
export { SpacesInfoMenuComponentNgFactory as SpacesInfoMenuComponentNgFactory };
