var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SelectedSpaceForMobileActionType } from "src/app/root/store/actions/selected-space-for-mobile.action";
/**
 * Начальное состояние для выбранного пространства документов, которое относится к мобильной версии.
 */
var initialState = {
    // Изначально пространство документов, которое относится к мобильной версии не выбрано.
    space: null,
};
/**
 * Обработчик событий, связанных с выбором пространства документов, в контексте которого происходит работа в случае,
 * когда используется мобильная версия UI.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 */
export function selectedSpaceForMobileReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        case SelectedSpaceForMobileActionType.SET: {
            result = __assign({}, state, { space: action.value });
            break;
        }
    }
    return result;
}
