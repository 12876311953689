import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';
import { currentUserStateSelector } from 'rootStore';
import { filter } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { UserService } from '../../common/services';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "../../common/services/user.service";
/**
 * Сервис для добавления виджета Битрикс24 на страницу.
 */
var BitrixWidgetService = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор сервиса для добавления виджета Битрикс24 на страницу.
     *
     * @param store Сервис для управления и доступа к состоянию приложения.
     * @param userService Сервис для работы с пользователями.
     */
    function BitrixWidgetService(store, userService) {
        this._store = store;
        this._userService = userService;
        this._scriptInserted = false;
    }
    //endregion
    //region Public
    /**
     * Добавляет виджет Битрикс24 на страницу приложения.
     *
     * Сначала дожидается загрузки данных пользователя, после чего добавляет скрипт виджета на страницу приложения.
     */
    BitrixWidgetService.prototype.add = function () {
        var _this = this;
        if (!this._scriptInserted && environment.bitrix24 && environment.bitrix24.enable) {
            this._userSubscription = this._store
                .pipe(select(currentUserStateSelector), filter(function (state) { return state.loaded; }), map(function (state) { return state.currentUserInfo; }))
                .subscribe(function (currentUser) {
                if (currentUser) {
                    window.addEventListener('onBitrixLiveChat', function (event) {
                        _this._bitrixLiveChatHandler(event, currentUser);
                    });
                }
                _this._addWidget();
                setTimeout(function () { return _this._userSubscription.unsubscribe(); }, 0);
            });
        }
    };
    //endregion
    //region Private
    /**
     * Обработчик загрузки виджета Битрикс24.
     *
     * @param event Событие загрузки виджета Битрикса.
     * @param user Данные текущего пользователя.
     */
    BitrixWidgetService.prototype._bitrixLiveChatHandler = function (event, user) {
        var widget = event.detail.widget;
        widget.setCustomData([
            {
                'USER': {
                    'NAME': this._userService.getFullUserName(user)
                }
            },
            {
                'GRID': [
                    {
                        'NAME': 'E-mail',
                        'VALUE': user.email,
                        'DISPLAY': 'LINE',
                    },
                    {
                        'NAME': 'Сайт',
                        'VALUE': location.hostname,
                        'DISPLAY': 'LINE'
                    },
                    {
                        'NAME': 'Страница',
                        'VALUE': '[url=' + location.href + ']' + (document.title || location.href) + '[/url]',
                        'DISPLAY': 'LINE'
                    },
                ]
            }
        ]);
    };
    /**
     * Добавляет скрипт виджета Битрикс24 на страницу.
     */
    BitrixWidgetService.prototype._addWidget = function () {
        if (!this._scriptInserted) {
            var script = document.createElement("script");
            script.text = "\n                (function(w,d,u){\n                    var s=d.createElement('script');\n                    s.async=true;\n                    s.src=u+'?'+(Date.now()/60000|0);\n                    var h=d.getElementsByTagName('script')[0];\n                    h.parentNode.insertBefore(s,h);\n                })(window,document,'" + environment.bitrix24.scriptUrl + "');\n            ";
            document.body.appendChild(script);
            "(function(w,d,u){\n                var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/60000|0);\n                var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);\n        })(window,document,'https://cdn.bitrix24.com/b20760055/crm/site_button/loader_1_6i0oi9.js');";
            this._scriptInserted = true;
        }
    };
    BitrixWidgetService.ngInjectableDef = i0.defineInjectable({ factory: function BitrixWidgetService_Factory() { return new BitrixWidgetService(i0.inject(i1.Store), i0.inject(i2.UserService)); }, token: BitrixWidgetService, providedIn: "root" });
    return BitrixWidgetService;
}());
export { BitrixWidgetService };
