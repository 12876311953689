var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ofType } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { Actions } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { map } from "rxjs/operators";
import { SpaceService } from "src/app/common/services/space.service";
import { usersInvitationActions } from "src/app/root/store/actions/users-invitation.action";
import { UsersInvitationActionType } from "src/app/root/store/actions/users-invitation.action";
/**
 * Side-эффекты на события, связанные с приглашением пользователей в пространства документов.
 */
var UsersInvitationDlgEffect = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор side-эффектов на события, связанные с приглашением пользователей в пространства документов.
     *
     * @param _actions$ Поток событий, происходящих в системе.
     * @param spaceService Сервис для работы с пространствами документов.
     */
    function UsersInvitationDlgEffect(_actions$, spaceService) {
        var _this = this;
        this._actions$ = _actions$;
        //endregion
        //region Effects
        /**
         * Обработка события с требованием пригласить пользователей в пространства документов.
         */
        this.applyDialog$ = this._actions$
            .pipe(ofType(UsersInvitationActionType.INVITE), map(function (valueProps) { return valueProps.value; }), switchMap(function (invitationData) {
            return _this._spaceService.invite(invitationData)
                .pipe(map(function () { return usersInvitationActions.invitationSuccess({ value: invitationData }); }), catchError(function (response) { return of(usersInvitationActions.invitationFailed({ value: response })); }));
        }));
        this._spaceService = spaceService;
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UsersInvitationDlgEffect.prototype, "applyDialog$", void 0);
    return UsersInvitationDlgEffect;
}());
export { UsersInvitationDlgEffect };
