<div class="have-questions-menu">
    <info-menu
        class="have-questions__menu"
        icon="live_help_outline"
        iconFontSet="material-icons-custom-set"
        hoveredIcon='live_help'
        [label]="'common.haveQuestions.title' | translate"
        [menuItems]='menuItems'
        (menuItemClick)="menuItemClickHandler($event)"
    >
    </info-menu>
</div>