import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { Input } from "@angular/core";
import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { FormControl } from "@angular/forms";

import { ApiResponse } from "src/app/common/models";
import { RecognitionTaskCreatedDlgData } from "src/app/root/models";

/**
 * Компонент формы диалога после создания задачи на распознавание.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "recognition-task-created",
    templateUrl: "./recognition-task-created.component.html",
    styleUrls: ["./recognition-task-created.component.scss"],
})
export class RecognitionTaskCreatedComponent {
    //region Inputs

    /**
     * Показывать пользователю индикацию выполнения операции?
     */
    @Input()
    loading: boolean;

    /**
     * Ошибка, возникшая в процессе выполнения операции, инициированной диалогом.
     */
    @Input()
    error: ApiResponse;

    /**
     * Пользователь принадлежит клиенту, которой произвёл оплату?
     */
    @Input()
    set belongsToPaidClient(value: boolean) {

        this._belongsToPaidClient = value;
    }

    //endregion
    //region Outputs

    /**
     * Исходящее событие с требованием обработать закрытие диалога с указанными данными.
     */
    @Output()
    applyDialog: EventEmitter<RecognitionTaskCreatedDlgData>;

    //endregion
    //region Fields

    /**
     * Контрол формы для флага нужно ли в дальнейшем прекратить показывать данный диалог.
     */
    dontShowAgainFlagControl: FormControl;

    /**
     * Пользователь принадлежит клиенту, которой произвёл оплату?
     */
    private _belongsToPaidClient: boolean;

    //endregion
    //region Ctor

    /**
     * Конструктор компонента формы диалога после создания задачи на распознавание.
     */
    constructor() {

        this.loading = false;
        this.error = null;
        this.applyDialog = new EventEmitter<RecognitionTaskCreatedDlgData>();
        this.dontShowAgainFlagControl = new FormControl(false);
        this._belongsToPaidClient = false;
    }

    //endregion
    //region Getters and Setters

    /**
     * Пользователь принадлежит клиенту, которой произвёл оплату?
     */
    get belongsToPaidClient(): boolean {

        return this._belongsToPaidClient;
    }

    //endregion
    //region Events

    /**
     * Обработчик клика на кнопку "Продолжить".
     *
     * Генерируется событие с требованием обработать закрытие диалога с указанными данными.
     */
    applyBtnClickHandler(): void {

        this.applyDialog.emit({ dontShowRecognitionTaskCreatedDialog: this.dontShowAgainFlagControl.value});
    }

    //endregion
}
