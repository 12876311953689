import { OnDestroy } from "@angular/core";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { select } from "@ngrx/store";
import { BehaviorSubject } from "rxjs";
import { Subject } from "rxjs";
import { Observable } from "rxjs";
import { withLatestFrom } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { map } from "rxjs/operators";
import { takeUntil } from "rxjs/operators";
import { User } from "src/app/common/models/user";
import { currentUserStateSelector } from "src/app/root/store/reducers";
import { RootState } from "src/app/root/store/reducers";
import { CurrentUserState } from "src/app/root/store/reducers/current-user.reducer";
import { getDefaultDocumentPageInterfaceState } from "src/app/spaces/modules/document/store/states/document-page-interface.state";
import { getDefaultRecognizingColumnsVisibilityState } from "src/app/spaces/modules/documents-registry/models/documents-registry-columns-visibility-state";
import { getDefaultColumnsVisibilityState } from "src/app/spaces/modules/documents-registry/models/documents-registry-columns-visibility-state";

/**
 * Сервис для работы с LocalStorage.
 *
 */
@Injectable({
    providedIn: "root"
})
export class LocalStorageService implements OnDestroy {
    //region Private fields

    /**
     * Текущий пользователь.
     */
    private readonly _user$: Observable<User>;

    /**
     * Объекты в локальном хранилище.
     */
    private readonly _localStorageObjects: Map<string, BehaviorSubject<InnerState<any>>>;

    /**
     * Сабж остановки подписок. Работает на основе takeUntil.
     */
    private readonly _stopSubscriptions$: Subject<void>;

    //endregion
    //region Ctor

    constructor(private _store: Store<RootState>) {

        this._stopSubscriptions$ = new Subject();
        this._localStorageObjects = new Map<string, BehaviorSubject<any>>();

        this._user$ = this._store.pipe(
            takeUntil(this._stopSubscriptions$),
            select(currentUserStateSelector),
            filter((state: CurrentUserState) => state.failed || state.loaded),
            map((state: CurrentUserState) => state.currentUserInfo),
        );
    }

    //endregion
    //region Hooks

    /**
     * Хук на уничтожение компонента, в котором происходитчт отписки.
     */
    ngOnDestroy() {

        this._stopSubscriptions$.next();
        this._stopSubscriptions$.complete();
    }

    //endregion
    //region Public

    /**
     * Возвращает значение из локального хранилища.
     *
     * @param key Ключ значения в локальном хранилище.
     */
    get<Type>(key: string): Observable<Type> {

        if (!this._localStorageObjects.has(key)) {

            this._initializeSubject<Type>(key);
        }

        return this._localStorageObjects.get(key).asObservable().pipe(
            map((state: InnerState<Type>) => state.value)
        );
    }

    /**
     * Кладёт значение в локальное хранилище по указанному ключу.
     *
     * @param key Ключ.
     * @param value Значение.
     */
    put<Type>(key: string, value: Type): void {

        this._localStorageObjects.get(key).next({update: true, value});
    }

    //endregion
    //region Private

    /**
     * Возвращает результат парсинга строки, если оно не равно null, в противном случае возвращает значение по
     * умолчанию.
     *
     * @param value Строка для парсинга.
     * @param defaultValue Значение по умолчанию.
     *
     * @return Результат парсинга или значение по умолчанию.
     */
    private _parseOrDefault<Type>(value: string, defaultValue: Type): Type {

        if (value !== null) {

            return JSON.parse(value);
        }
        return defaultValue;
    }

    /**
     * Возвращает ключ данных относительно текущего пользователя.
     *
     * @param key Ключ данных.
     * @param user Пользователь.
     */
    private _getKey(key: string, user: User): string {

        return (user && user.email || "") + key;
    }

    /**
     * Инициализирует субъект для объекта в локальном хранилище.
     *
     * Создаёт субъект и присваивает ему значение из локального хранилища, либо значение по умолчанию. Создаёт подписку
     * на обновление значения в локальном хранилище.
     *
     * @param key Ключ.
     */
    private _initializeSubject<Type>(key: string): void {

        const newBehaviorSubject = new BehaviorSubject<InnerState<Type>>({value: localStorageDefaultValues[key]});
        this._localStorageObjects.set(key, newBehaviorSubject);

        this._user$
            .pipe(
                takeUntil(this._stopSubscriptions$),
                map((user: User) => this._getKey(key, user)),
                map((fullKey: string) => localStorage.getItem(fullKey)),
                map((item: string) => this._parseOrDefault(item, localStorageDefaultValues[key])),
            )
            .subscribe((value: Type) => newBehaviorSubject.next({ value }));

        newBehaviorSubject.pipe(
            takeUntil(this._stopSubscriptions$),
            withLatestFrom(this._user$),
            filter(([_, user]: [InnerState<Type>, User]) =>  Boolean(user)),
            filter(([state, _]: [InnerState<Type>, User]) =>  Boolean(state.update))
        )
        .subscribe(([state, user]: [InnerState<Type>, User]) =>
                localStorage.setItem(this._getKey(key, user), JSON.stringify(state.value))
        );
    }

    //endregion
}

/**
 * Внутреннее состояние BehaviorSubject, которое используется для внутренних механик в LocalStorage.
 */
interface InnerState<T> {

    /**
     * Значения в LocalStorage.
     */
    value: T;

    /**
     * Физически обновить данные в LocalStorage?
     */
    update?: boolean;
}

/**
 * Ключи для доступа к объектам в локальном хранилище.
 */
export enum LocalStorageKeys {

    DOCUMENT_REGISTRY_COLUMNS_VISIBILITY_STATE = "DocumentRegistryColumnsVisibilityState",
    RECOGNIZING_REGISTRY_COLUMNS_VISIBILITY_STATE = "RecognizingDocumentRegistryColumnsVisibilityState",
    DOCUMENT_PAGE_INTERFACE_STATE = "DocumentPageInterfaceState",
    USER_NOTIFICATION_VIEW = "UserNotificationView",
    LAST_USED_CAMERA = "QrScannerLastCamera",
}

/**
 * Значения по умолчанию для объектов в локальном хранилище.
 */
const localStorageDefaultValues = {
    [LocalStorageKeys.DOCUMENT_REGISTRY_COLUMNS_VISIBILITY_STATE]: getDefaultColumnsVisibilityState(),
    [LocalStorageKeys.RECOGNIZING_REGISTRY_COLUMNS_VISIBILITY_STATE]: getDefaultRecognizingColumnsVisibilityState(),
    [LocalStorageKeys.DOCUMENT_PAGE_INTERFACE_STATE]: getDefaultDocumentPageInterfaceState(),
    [LocalStorageKeys.USER_NOTIFICATION_VIEW]: null,
    [LocalStorageKeys.LAST_USED_CAMERA]: null,
};
