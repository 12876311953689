var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Validators } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import { select } from "@ngrx/store";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { map } from "rxjs/operators";
import { GenerateHashForFilesAction } from "src/app/root/store/actions/recognize/upload-to-recognize.action";
import { UploadTaskByMobileToRecognizeAction } from "src/app/root/store/actions/recognize/upload-to-recognize.action";
import { ChangeTaskToRecognizeAction } from "src/app/root/store/actions/recognize/upload-to-recognize.action";
import { RemoveTaskToRecognizeAction } from "src/app/root/store/actions/recognize/upload-to-recognize.action";
import { RouterGoAction } from "src/app/root/store/actions/router.action";
import { uploadToRecognizeSelector } from "src/app/root/store/reducers/index";
import { UploadToRecognizeState } from "src/app/root/store/reducers/index";
import { SelectedSpaceForMobileSelectors } from "src/app/root/store/selectors/selected-space-for-mobile.selector";
/**
 * Компонент мобильного интерфейса для загрузки документов на распознавание.
 */
var MobileDocumentUploadComponent = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор мобильного интерфейса для загрузки документов на распознавание.
     *
     * @param store Сервис для доступа и управления состоянием приложения.
     * @param formBuilder Сервис для создания реактивных форм.
     */
    function MobileDocumentUploadComponent(store, formBuilder) {
        /**
         * Переключатель, чтобы при выполнении onDestroy произошли отписки от всех подписок.
         *
         */
        this.destroyed = new Subject();
        this.store = store;
        this.loading$ = this.store.select(uploadToRecognizeSelector)
            .pipe(takeUntil(this.destroyed), map(function (state) { return state.loading; }));
        this.error$ = this.store.select(uploadToRecognizeSelector)
            .pipe(takeUntil(this.destroyed), map(function (state) { return state.error; }));
        this.loaded$ = this.store.select(uploadToRecognizeSelector)
            .pipe(takeUntil(this.destroyed), map(function (state) { return state.loaded; }));
        this.space$ = this.store
            .pipe(takeUntil(this.destroyed), select(SelectedSpaceForMobileSelectors.space));
        this.uploadToRecognizeState$ = this.store.select(uploadToRecognizeSelector)
            .pipe(takeUntil(this.destroyed));
        this.mobileFormGroup = formBuilder.group({
            comment: formBuilder.control(null, [Validators.required]),
        });
    }
    //endregion
    //region Hooks
    MobileDocumentUploadComponent.prototype.ngOnInit = function () {
        this.store
            .pipe(takeUntil(this.destroyed), select(uploadToRecognizeSelector));
    };
    MobileDocumentUploadComponent.prototype.ngOnDestroy = function () {
        this.destroyed.next();
        this.destroyed.complete();
    };
    //endregion
    /**
     * Переход на корневую страницу мобильного интерфейса приложения.
     */
    MobileDocumentUploadComponent.prototype.cancelDocumentUpload = function () {
        this.store.dispatch(new RouterGoAction({ path: ["mobile"] }));
    };
    /**
     * Обработчик добавления файлов в массив файлов в состоянии для их отправки на распознавание. Фильтрует файлы,
     * которые уже есть в состоянии.
     *
     * @param files Файлы, которые нужно добавить.
     */
    MobileDocumentUploadComponent.prototype.addFilesHandler = function (files) {
        this.store.dispatch(new GenerateHashForFilesAction(files.filter(function (file) { return !!file.size; })));
    };
    /**
     * Обработчик удаления файлов из массива файлов для отправки на распознавание.
     *
     * @param file Файл, который нужно удалить.
     */
    MobileDocumentUploadComponent.prototype.removeFileHandler = function (file) {
        this.store.dispatch(new RemoveTaskToRecognizeAction(file));
    };
    /**
     * Обработчик возвращения состояния данных при загрузке и отправке файлов на распознавание к первоначальному
     * состоянию.
     */
    MobileDocumentUploadComponent.prototype.clearState = function () {
        this.store.dispatch(new ChangeTaskToRecognizeAction(__assign({}, new UploadToRecognizeState(), { openPostUploadDialog: false })));
    };
    /**
     * Обработчик отправки файлов на распознавание.
     */
    MobileDocumentUploadComponent.prototype.uploadToRecognize = function () {
        this.store.dispatch(new UploadTaskByMobileToRecognizeAction(this.mobileFormGroup.get("comment").value));
    };
    return MobileDocumentUploadComponent;
}());
export { MobileDocumentUploadComponent };
