import { Injectable } from "@angular/core";
import { ofType } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { Actions } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { of } from "rxjs";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { map } from "rxjs/operators";
import { ApiResponse } from "src/app/common/models/api-response";
import { SpaceIdsAndEmails } from "src/app/common/models/space-ids-and-emails.model";
import { SpaceService } from "src/app/common/services/space.service";
import { ValueProps } from "src/app/root/store/actions/props/value-props";
import { trustedEmailsAdditionActions } from "src/app/root/store/actions/trusted-emails-addition.action";
import { TrustedEmailsAdditionActionType } from "src/app/root/store/actions/trusted-emails-addition.action";

/**
 * Side-эффекты на события, связанные с добавлением доверенных почт в пространства документов.
 */
@Injectable()
export class TrustedEmailsAdditionEffect {
    //region Fields

    /**
     * Сервис для работы с пространствами документов.
     */
    private readonly _spaceService: SpaceService;

    //endregion
    //region Ctor

    /**
     * Конструктор side-эффектов на события, связанные с диалогом для добавления доверенных почт в пространства
     * документов.
     *
     * @param _actions$ Поток событий, происходящих в системе.
     * @param spaceService Сервис для работы с пространствами документов.
     */
    constructor(private readonly _actions$: Actions, spaceService: SpaceService) {

        this._spaceService = spaceService;
    }

    //endregion
    //region Effects

    /**
     * Обработка события с требованием добавить доверенные почты в пространства документов.
     */
    @Effect()
    addTrustedEmails$ = this._actions$
        .pipe(
            ofType(TrustedEmailsAdditionActionType.ADD),
            map((valueProps: ValueProps<SpaceIdsAndEmails>): SpaceIdsAndEmails => valueProps.value),
            switchMap((invitationData: SpaceIdsAndEmails): Observable<Action> =>
                this._spaceService.addTrustedEmails(invitationData)
                    .pipe(
                        map((): Action => trustedEmailsAdditionActions.additionSuccess({value: invitationData})),
                        catchError(
                            (response: ApiResponse): Observable<Action> => of(
                                trustedEmailsAdditionActions.additionFailed({ value: response })
                            )
                        ),
                    ),
            ),
        );

    //endregion
}
