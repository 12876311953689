var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { RecognitionTaskSourcesActionType } from "src/app/spaces/modules/documents-registry/store/actions/recognition-task-sources.action";
/**
 * Начальное состояние источников задач на распознавание.
 */
var initialState = {
    loading: false,
    loaded: false,
    failed: false,
    recognitionTaskSources: [],
};
/**
 * Обработчик событий, связанных с источниками задач на распознавание.
 *
 * @param state Состояние источников задач на распознавание.
 * @param action Событие произошедшее в системе.
 */
export function recognitionTaskSourcesReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        // Требование загрузить источники задач на распознавание.
        case RecognitionTaskSourcesActionType.LOAD: {
            return __assign({}, state, { loading: true, loaded: false, failed: false });
        }
        // Уведомление об успешной загрузке источников задач на распознавание.
        case RecognitionTaskSourcesActionType.LOAD_SUCCESS: {
            return __assign({}, state, { loading: false, loaded: true, recognitionTaskSources: action.sources });
        }
        // Увдомление о неудачной попытке загрузки источников задач на распознавание.
        case RecognitionTaskSourcesActionType.LOAD_FAIL: {
            return __assign({}, state, { loading: false, failed: true, recognitionTaskSources: [] });
        }
    }
    return state;
}
/**
 * Возвращает источники задач на распознавание из состояния источников задач на распознавание.
 *
 * @param state Состояние источников задач на распознавание.
 */
export var getRecognitionTaskSources = function (state) { return state.recognitionTaskSources; };
/**
 * Возвращает флаг успешно выполненной загрузки источников задач на распознавание из состояния источников задач на
 * распознавание.
 *
 * @param state Состояние источников задач на распознавание.
 */
export var getRecognitionTaskSourcesLoaded = function (state) { return state.loaded; };
