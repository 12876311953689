var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { RecognitionTasksActionType } from "../../actions";
/**
 * Состояние задач на распознавание для текущего пространства документов.
 */
var RecognitionTasksState = /** @class */ (function () {
    function RecognitionTasksState() {
        /**
         * Выполняется загрузка задач на распознавание?
         */
        this.loading = false;
        /**
         * Загрузка задач на распознавание завершилась успешно?
         */
        this.loaded = false;
        /**
         * Загрузка задач на распознавание завершилась неудачно?
         */
        this.failed = false;
        /**
         * Выполняющиеся задачи на распознавание.
         */
        this.tasks = {};
        /**
         * Последняя успешно выполненная задача на распознавание.
         */
        this.lastRecognized = null;
        /**
         * Последняя неудачно выполненная задача на распознавание.
         */
        this.lastError = null;
        /**
         * Расознанные документы во время работы.
         */
        this.recognizedDocuments = [];
        /**
         * Распознанные документы (статус распознан), которые будут является как начальные документы. Загружаются со всех
         * активных задач распознавания при открытии реестра. Выполняют роль фильтра, чтобы предовратить добавление
         * распознанных ранее документов рамках активных задач распознавания.
         */
        this.initialDocuments = [];
    }
    return RecognitionTasksState;
}());
export { RecognitionTasksState };
/**
 * Начальное состояние задач на распознавание.
 */
var initialState = new RecognitionTasksState();
/**
 * Обработка состояния задач на распознавание в ответ на события.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 */
export function recognitionTasksReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var _a;
    var result = state;
    switch (action.type) {
        case RecognitionTasksActionType.LOAD: {
            result = __assign({}, state, { tasks: {}, loading: true, loaded: false, failed: false });
            break;
        }
        case RecognitionTasksActionType.LOAD_SUCCESS: {
            var tasks_1 = __assign({}, state.tasks);
            action.payload.forEach(function (task) { return tasks_1[task.id] = task; });
            result = __assign({}, state, { tasks: tasks_1, loading: false, loaded: true, failed: false, recognizedDocuments: [] });
            break;
        }
        case RecognitionTasksActionType.LOAD_FAIL: {
            result = __assign({}, state, { loading: false, loaded: false, failed: true });
            break;
        }
        case RecognitionTasksActionType.ADD_TASK: {
            var newTasks_1 = {};
            action.payload.forEach(function (task) { return newTasks_1[task.id] = task; });
            result = __assign({}, state, { tasks: __assign({}, newTasks_1, state.tasks) });
            break;
        }
        case RecognitionTasksActionType.CHANGE_TASK_STATUS: {
            result = __assign({}, state, { tasks: __assign({}, state.tasks, (_a = {}, _a[action.payload.id] = action.payload, _a)) });
            break;
        }
        case RecognitionTasksActionType.TASK_FINISHED: {
            var tasks = __assign({}, state.tasks);
            delete tasks[action.payload.id];
            result = __assign({}, state, { tasks: tasks, lastRecognized: action.payload });
            break;
        }
        case RecognitionTasksActionType.TASK_ERROR: {
            var tasks = __assign({}, state.tasks);
            delete tasks[action.payload.id];
            result = __assign({}, state, { tasks: tasks, lastError: action.payload });
            break;
        }
        case RecognitionTasksActionType.CLEAR: {
            result = initialState;
            break;
        }
        case RecognitionTasksActionType.ADD_RECOGNIZED_DOCUMENTS: {
            result = __assign({}, state, { recognizedDocuments: state.recognizedDocuments.concat(action.payload) });
            break;
        }
        case RecognitionTasksActionType.LOAD_INITIAL_DOCUMENTS: {
            result = __assign({}, state, { loading: true, initialLoaded: false, recognizedDocuments: [], initialDocuments: [] });
            break;
        }
        case RecognitionTasksActionType.LOAD_INITIAL_DOCUMENTS_SUCCESS: {
            result = __assign({}, state, { loading: false, initialLoaded: true, failed: false, initialDocuments: action.payload ? action.payload : [] });
            break;
        }
    }
    return result;
}
/**
 * Функция получения списка задач распозновния из сотсотояния.
 * @param state состоние хранилища.
 */
export var getTasks = function (state) { return state ? state.tasks : []; };
/**
 * Возвращает флаг успешного выполнения загрузки задач на распознавание из состояния задач на распознавание.
 * @param state Состояние хранилища.
 */
export var getTasksLoaded = function (state) { return state.loaded; };
/**
 * Функция получения последей распознанной задачи из хранилища.
 * @param state состоние хранилища.
 */
export var getLastTask = function (state) { return state.lastRecognized; };
/**
 * Функция получения последей неудачной задачи из хранилища.
 * @param state состоние хранилища.
 */
export var getLastError = function (state) { return state.lastError; };
/**
 * Функция получения списка распознанных документов.
 * @param state состоние хранилища.
 */
export var getRecognizedDocuments = function (state) { return state.recognizedDocuments; };
/**
 * Функция получения списка начальных распознанных документов.
 * @param state Состоние хранилища.
 */
export var getInitialDocuments = function (state) { return state.initialDocuments; };
