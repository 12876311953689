/**
 * Тип обратной связи.
 */
export class FeedbackType {
    //region Values

    /**
     * Проблемы документа.
     */
    public static readonly DOCUMENT_PROBLEMS = new FeedbackType("DOCUMENT_PROBLEMS");

    /**
     * Обратный звонок для пользователя.
     */
    public static readonly CALL_BACK = new FeedbackType("CALL_BACK");

    //endregion
    //region Fields

    /**
     * ID типа.
     */
    public readonly id: string;

    //endregion
    //region Ctor

    /**
     * Конструктор типа обратной свзязи пользователя.
     *
     * @param id Идентификатор типа обратной связи.
     */
    private constructor(id: string) {

        this.id = id;
    }

    //endregion
    //region Getter/Setters

    /**
     * Возвращает ключ интернализации для заголовка диалога по этому типу обратной связи.
     */
    get titleKey(): string {

        return "feedback.title." + this.id;
    }

    /**
     * Возвращает ключ интернализации для подсказки в диалоге по этому типу обратной связи.
     */
    get commentKey(): string {

        return "feedback.comment." + this.id;
    }


    /**
     * Возвращает ключ интернализации для подсказки в поле комментария в диалоге по этому типу обратной связи.
     */
    get placeholderKey(): string {

        return "feedback.placeholder." + this.id;
    }

    //endregion
}
