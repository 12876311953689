<div *ngFor="let fileWithMetaData of uploadState.filesWithMetaData">
    <div #fileElement
         class="file"
    >
        <div class="file__name">{{fileWithMetaData.file.name}}</div>
        <div
            *ngIf="!fileWithMetaData.fileId"
            class="file__new-label"
            matTooltip="{{'common.upload-to-recognize-dialog.newDocumentTooltip' | translate}}"
        >
            {{"common.upload-to-recognize-dialog.newDocument" | translate}}
        </div>
        <div
            *ngIf="!!fileWithMetaData.fileId"
            class="file__duplicate-label"
            matTooltip="{{'common.upload-to-recognize-dialog.duplicateTooltip' | translate}}"
        >
            {{"common.upload-to-recognize-dialog.duplicate" | translate}}
        </div>
        <div class="file__size">{{fileWithMetaData.file.size | bytes:2}}</div>
        <div
            *ngIf="!uploadState.loading && !uploadState.loaded"
            class="file__delete"
            (click)="remove.emit(fileWithMetaData.file)"
        >
            {{"common.upload-to-recognize-dialog.buttons.remove" | translate}}
        </div>
    </div>

    <div *ngIf="!uploadState.failed">
        <mat-progress-bar class="mat-progress-bar_thin" mode="determinate" [value]="uploadState.progress">
        </mat-progress-bar>
    </div>
</div>
