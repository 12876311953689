<span mat-dialog-title>
    <span class="mat-dialog-title__text">{{ 'dialogs.firstUploadDialog.title' | translate }}</span>
</span>
<mat-dialog-content>
    <div class="first-upload-dlg__row"><!--
        -->{{ 'dialogs.firstUploadDialog.text' | translate }}
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button class="mat-dialog__action-btn" mat-dialog-close mat-raised-button>
        {{ 'dialogs.close' | translate }}
    </button>
    <a
        *ngIf="!(isGlobal$ | async)"
        class="mat-dialog__action-btn first-upload-dlg__button"
        mat-raised-button
        target="blank"
        color="primary"
        [attr.href]="seeMoreLink"
        (click)="seeMoreClick()"
    >
        {{ 'button.seeMore' | translate }}
    </a>
    <button class="mat-dialog__action-btn mat-dialog__cross-close-btn"
        mat-flat-button
        mat-dialog-close
        title='{{ "dialogs.close" | translate }}'
    >
        <img src="/assets/images/cross-close-btn.svg" alt="">
    </button>
</mat-dialog-actions>
