import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import * as LogRocket from "logrocket";
import { UserService } from "src/app/common/services/user.service";
import { RootState } from "src/app/root/store/reducers/index";
import { environment } from "src/environments/environment";

/**
 * Сервис для инициализации Logrocket'а.
 */
@Injectable({
    providedIn: "root"
})
export class LogrocketService {
    //region Fields

    /**
     * Сервис для управления и доступа к состоянию приложения.
     */
    private readonly _store: Store<RootState>;

    /**
     * Сервис с логикой для работы с пользователем.
     */
    private readonly _userService: UserService;

    //endregion
    //region Ctor

    /**
     * Конструктор сервиса для инициализации Logrocket'а.
     *
     * @param store Сервис для управления и доступа к состоянию приложения.
     * @param userService Сервис с логикой для работы с пользователем.
     */
    constructor(store: Store<RootState>, userService: UserService) {

        this._store = store;
        this._userService = userService;
    }

    //endregion
    //region Public

    /**
     * Инициализирует Logrocket.
     *
     * В процессе инициализации передаёт App ID LogRocket'а и идентифицирует пользователя.
     */
    init() {

        if (environment.logRocket && environment.logRocket.enabled) {

            LogRocket.init(environment.logRocket.appId);
        }
    }
}
