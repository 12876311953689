<div class="">
    <recognition-task-created
        [belongsToPaidClient]="belongsToPaidClient$ | async"
        [loading]="loading$ | async"
        [error]="error$ | async"
        (applyDialog)="applyDialog($event)"
    >
    </recognition-task-created>
    <button class="mat-dialog__cross-close-btn"
        mat-flat-button
        mat-dialog-close
        title='{{ "dialogs.close" | translate }}'
    >
        <mat-icon>close</mat-icon>
    </button>
</div>
