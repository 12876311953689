import {createSelector} from '@ngrx/store';

import {serverSideErrorStateSelector} from '../reducers';
import {getServerSideErrorResponse} from '../reducers/server-side-error.reducer';

/**
 * Селектор ответа сервера на запрос, вызвавший ошибку на сервере.
 */
export const serverSideErrorResponseSelector = createSelector(
    serverSideErrorStateSelector, getServerSideErrorResponse
);
