import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { headerHowToStartButtonVisibilitySelector } from "../store";
import { HeaderHideHowToStartButtonAction } from "../store";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
/**
 * Route Guard.
 * Логика обработки попытки входа по пути, для которого требуется скрыть кнопку "How to start".
 */
var HideHeaderHowToStartButtonGuard = /** @class */ (function () {
    //region Ctor
    function HideHeaderHowToStartButtonGuard(_store) {
        this._store = _store;
    }
    //endregion
    //region Public
    HideHeaderHowToStartButtonGuard.prototype.canActivate = function () {
        var _this = this;
        return this._store
            .pipe(select(headerHowToStartButtonVisibilitySelector), tap(function (show) {
            if (show) {
                _this._store.dispatch(new HeaderHideHowToStartButtonAction());
            }
        }), map(function (show) { return !show; }));
    };
    HideHeaderHowToStartButtonGuard.prototype.canActivateChild = function () {
        return this.canActivate();
    };
    HideHeaderHowToStartButtonGuard.ngInjectableDef = i0.defineInjectable({ factory: function HideHeaderHowToStartButtonGuard_Factory() { return new HideHeaderHowToStartButtonGuard(i0.inject(i1.Store)); }, token: HideHeaderHowToStartButtonGuard, providedIn: "root" });
    return HideHeaderHowToStartButtonGuard;
}());
export { HideHeaderHowToStartButtonGuard };
