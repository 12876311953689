import { EventEmitter } from "@angular/core";
import { FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { UtilsService } from "src/app/common/services";
/**
 * Компонент выпадашки для выбора пространства документов.
 */
var MobileSpaceSelectComponent = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор компонента выпадашки для выбора пространства документов.
     */
    function MobileSpaceSelectComponent(_translateService, _util) {
        var _this = this;
        this._translateService = _translateService;
        this._util = _util;
        this.select = new EventEmitter();
        this.valueControl = new FormControl(this.emptySpaceValue);
        this._valueControlSubscription = this.valueControl.valueChanges
            .subscribe(function (space) {
            if (space) {
                _this.select.emit(space);
            }
            else {
                _this.select.emit(null);
            }
            _this._isSpaceSelected = !!space;
        });
        this._isSpaceSelected = false;
    }
    Object.defineProperty(MobileSpaceSelectComponent.prototype, "spaces", {
        /**
         * Пространства документов.
         */
        get: function () {
            return this._spaces;
        },
        //endregion
        //region Inputs
        /**
         * Пространства документов.
         *
         * При изменении массива пространств документов учитываются варианты, что текущее выбранное значение может
         * отсутствовать в новом массиве пространств документов. Также учитывается случай, когда новый массив пространств
         * документов содержит только один элемент, который сразу выбирается.
         *
         */
        set: function (value) {
            this._spaces = value;
            var selectedSpace = this.valueControl.value;
            var foundSpace = null;
            if (selectedSpace) {
                foundSpace = this._spaces.filter(function (space) { return space.id === selectedSpace.id; })[0];
            }
            if (this.spaces.length === 1) {
                if (!foundSpace || selectedSpace !== foundSpace) {
                    this.valueControl.setValue(this.spaces[0]);
                }
            }
            else if (foundSpace) {
                if (selectedSpace !== foundSpace) {
                    this.valueControl.setValue(foundSpace);
                }
            }
            else if (selectedSpace) {
                this.valueControl.setValue(this.emptySpaceValue);
            }
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Hooks
    /**
     * Выполняет логику при уничтожении компонента.
     */
    MobileSpaceSelectComponent.prototype.ngOnDestroy = function () {
        this._valueControlSubscription.unsubscribe();
    };
    Object.defineProperty(MobileSpaceSelectComponent.prototype, "selectorLabel", {
        //endregion
        //region Getters and Setters
        /**
         * I18n ключ метки для выпадашки пространства документов.
         */
        get: function () {
            var label;
            if (this._isSpaceSelected) {
                label = MobileSpaceSelectComponent._SELECTED_LABEL_I18N;
            }
            else {
                label = MobileSpaceSelectComponent._UNSELECTED_LABEL_I18N;
            }
            return label;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MobileSpaceSelectComponent.prototype, "emptySpaceValue", {
        /**
         * Значение пространства документов, когда в выпадашке ничего не выбрано.
         */
        get: function () {
            return MobileSpaceSelectComponent._EMPTY_SPACE_VALUE;
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Public
    /**
     * Возвращает имя и баланс папки.
     *
     * @param space Папка.
     *
     * @return Имя и баланс папки.
     */
    MobileSpaceSelectComponent.prototype.getSpaceNameAndBalance = function (space) {
        var result;
        if (space.unlimitedBalance) {
            var date = void 0;
            if (space.foreign) {
                date = this._util.getISODate(space.balanceExpirationDate);
            }
            else {
                date = this._util.formatDate(space.balanceExpirationDate, this._translateService.getDefaultLang());
            }
            result = this._translateService.instant("user.space.selector.optionUnlimited", {
                spaceName: space.name,
                expirationDate: date,
            });
        }
        else {
            result = this._translateService.instant("user.space.selector.option", {
                spaceName: space.name,
                spaceBalance: space.balance,
            });
        }
        return result;
    };
    //region Constants
    /**
     * I18n ключ метки для выпадашки пространства документов при наличии выбранного пространства документов.
     */
    MobileSpaceSelectComponent._SELECTED_LABEL_I18N = "user.space.selector.selectedLabel";
    /**
     * I18n ключ метки для выпадашки пространства документов при отсутствии выбранного пространства
     * документов.
     */
    MobileSpaceSelectComponent._UNSELECTED_LABEL_I18N = "user.space.selector.unselectedLabel";
    /**
     * Значение пространства документов, когда в выпадашке ничего не выбрано.
     */
    MobileSpaceSelectComponent._EMPTY_SPACE_VALUE = "";
    return MobileSpaceSelectComponent;
}());
export { MobileSpaceSelectComponent };
