import { DocumentStatesActionType, DocumentStatesAction } from "../actions";
import { EnteraDocumentState } from "src/app/common/models/document-state";

/**
 * Состояние статусов документов.
 */
export interface DocumentStatesState {

    /**
     * Статусы документов загружаются?
     */
    loading: boolean;

    /**
     * Статусы документов загружены?
     */
    loaded: boolean;

    /**
     * Загрузка статусов документов завершилась ошибкой?
     */
    failed: boolean;

    /**
     * Статусы документов.
     */
    documentStates: EnteraDocumentState[];
}

/**
 * Начальное состояние статусов документов.
 */
const initialState: DocumentStatesState = {

    /**
     * Статусы документов не загружаются.
     */
    loading: false,

    /**
     * Статусы документов не загружены.
     */
    loaded: false,

    /**
     * Загрузка статусов документов ошибкой не завершалась.
     */
    failed: false,

    /**
     * Статусов документов нет.
     */
    documentStates: undefined
}

/**
 * Обработчик событий, связанных со статусами документов.
 * 
 * @param state Состояние статусов документов.
 * @param action Событие произошедшее в системе.
 */
export function documentStatesReducer(state = initialState, action: DocumentStatesAction): DocumentStatesState {

    switch(action.type) {

        // Требование загрузить статусы документов.
        case DocumentStatesActionType.LOAD: {

            return {
                ...state,
                loading: true,
                loaded: false,
                failed: false
            };
        }

        // Уведомление об успешной загрузке статусов документов.
        case DocumentStatesActionType.LOAD_SUCCESS: {

            const documentStates = action.payload;
            return {
                ...state,
                loading: false,
                loaded: true,
                documentStates
            };
        }

        // Увдомление о неудачной попытке загрузки статусов документов.
        case DocumentStatesActionType.LOAD_FAIL: {

            return {
                ...state,
                loading: false,
                failed: true,
                documentStates: undefined
            };
        }
    }

    return state;
}

/**
 * Возвращает статусы документов из состояния статусов документов.
 * 
 * @param state Состояние статусов документов.
 */
export const getDocumentStates = (state: DocumentStatesState) => state.documentStates;

/**
 * Возвращает флаг выполняющейся загрузки статусов документов из состояния статусов документов.
 * 
 * @param state Состояние статусов документов.
 */
export const getDocumentStatesLoading = (state: DocumentStatesState) => state.loading;

/**
 * Возвращает флаг успешно выполненной загрузки статусов документов из состояния статусов документов.
 * 
 * @param state Состояние статусов документов.
 */
export const getDocumentStatesLoaded = (state: DocumentStatesState) => state.loaded;

/**
 * Возвращает флаг неудачной попытки загрузки статусов документов из состояния статусов документов.
 * 
 * @param state Состояние статусов документов.
 */
export const getDocumentStatesFailed = (state: DocumentStatesState) => state.failed;
