import { requestState } from "src/app/common/models/request-state";
import { MatchSettingsActionType } from "src/app/spaces/modules/document-matching/store/actions/match-settings.actions";
import { MatchSettingsAction } from "src/app/spaces/modules/document-matching/store/actions/match-settings.actions";
import { MatchSettingsState } from "src/app/spaces/modules/document-matching/store/states/match-settings.state";

/**
 * Начальное состояние настроек сопоставления.
 */
const initialState: MatchSettingsState = {

    /**
     * Начальное состояние запроса настроек сопоставления.
     */
    matchSettingsRequest: requestState.initial(),

    /**
     * Начальное состояние запроса обновления настроек сопоставления.
     */
    matchSettingsUpdateRequest: requestState.initial(),
};

/**
 * Обработчик событий, связанных с изменением состояния настроек сопоставления.
 *
 * @param state Текущее состояние настроек сопоставления.
 * @param action Событие.
 *
 * @return Новое состояние.
 */
export function matchSettingsReducer(
    state = initialState,
    action: MatchSettingsAction,
): MatchSettingsState {
    let result = state;

    switch (action.type) {

        case MatchSettingsActionType.LOAD: {

            result = {
                ...state,
                matchSettingsRequest: requestState.pending(),
            };
            break;
        }

        case MatchSettingsActionType.LOAD_SUCCESS: {

            result = {
                ...state,
                matchSettingsRequest: requestState.success(action),
            };
            break;
        }

        case MatchSettingsActionType.LOAD_FAIL: {

            result = {
                ...state,
                matchSettingsRequest: requestState.fail(action.error),
            };
            break;
        }

        case MatchSettingsActionType.UPDATE_INIT: {

            result = {
                ...state,
                matchSettingsUpdateRequest: requestState.initial(),
            };
            break;
        }

        case MatchSettingsActionType.UPDATE: {

            result = {
                ...state,
                matchSettingsUpdateRequest: requestState.pending(),
            };
            break;
        }

        case MatchSettingsActionType.UPDATE_SUCCESS: {

            result = {
                ...state,
                matchSettingsRequest: requestState.success(action),
                matchSettingsUpdateRequest: requestState.success(action),
            };
            break;
        }

        case MatchSettingsActionType.UPDATE_FAIL: {

            result = {
                ...state,
                matchSettingsUpdateRequest: requestState.fail(action.error),
            };
            break;
        }
    }

    return result;
}
