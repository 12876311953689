import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { CanActivateChild } from '@angular/router';

import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';

import { RootState } from '../store';
import { HeaderHideAction } from '../store';
import { headerHiddenSelector } from '../store';
import { HeaderHideSpaceSelectAction } from "../store";
import { headerShowSpacesInfoSelector } from "../store";

/**
 * Route Guard.
 * Логика обработки попытки входа по пути, для которого требуется скрыть заголовка сайта.
 */
@Injectable({
    providedIn: 'root'
})
export class HideHeaderSpacesInfoGuard implements CanActivate, CanActivateChild {
    //region Ctor

    constructor(
        private _store: Store<RootState>
    ) {}

    //endregion
    //region Public

    canActivate(): Observable<boolean> {

        return this._store
            .pipe(
                select(headerShowSpacesInfoSelector),
                
                // Если заголовок не виден, то запускаем его отображение.
                tap((show) => {

                    if (show) {

                        this._store.dispatch(new HeaderHideSpaceSelectAction());
                    }
                }),
                
                // Даём разрешение только тогда, когда заголовок станет виден.
                map((show) => !show)
            );
    }

    canActivateChild() {

        return this.canActivate();
    }

    //endregion
}
