import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { EnteraDocumentType } from 'src/app/common/models/document-type';
import { ApiResponse } from 'src/app/common/models/api-response';
import { catchError, map } from 'rxjs/operators';

/**
 * Ответ от метода API по загрузке типов документов.
 */
interface DocumentTypesResponse extends ApiResponse {

    /**
     * Типы документов.
     */
    documentTypes: EnteraDocumentType[];
}

/**
 * Сервис для работы с типами документов.
 */
@Injectable({
    providedIn: 'root'
})
export class DocumentTypesService {
    //region Ctor

    constructor(
        private httpClient: HttpClient
    ) {}

    //endregion
    //region Public

    /**
     * Загрузка типов документов с сервера.
     */
    get(): Observable<EnteraDocumentType[]> {

        return this.httpClient.get('/api/v1/documentTypes')
            .pipe(
                map((response: DocumentTypesResponse) => response.documentTypes),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse))
            );
    }

    //endregion
}