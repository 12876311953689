import { version } from "src/environments/version";
import { UtilsService } from "../../../common/services";
import { TitleService } from "../../../common/services";
/**
 * Комонент страницы с версией UI-приложения.
 */
var VersionPageComponent = /** @class */ (function () {
    //region Ctor
    function VersionPageComponent(_utilService, _titleService) {
        this._utilService = _utilService;
        this._titleService = _titleService;
    }
    //endregion
    //region Hooks
    VersionPageComponent.prototype.ngOnInit = function () {
        this._utilService.hideSupportWidget();
        this._titleService.setTitle({ titleKey: 'version' });
    };
    VersionPageComponent.prototype.ngOnDestroy = function () {
        this._utilService.showSupportWidget();
        this._titleService.clearTitle();
    };
    Object.defineProperty(VersionPageComponent.prototype, "version", {
        //endregion
        //region Getters and Setter
        /**
         * Возвращает версию UI приложения.
         */
        get: function () {
            return version;
        },
        enumerable: true,
        configurable: true
    });
    return VersionPageComponent;
}());
export { VersionPageComponent };
