<div class="mat-document-upload-file-list">
    <mobile-document-upload-file-row
        *ngFor="let fileWithMetaData of uploadState.filesWithMetaData"
        [fileWithMetaData]="fileWithMetaData"
        [loading]="loading"
        [error]="error"
        (remove)="removeFile($event)"
    >
    </mobile-document-upload-file-row>
</div>
