import { Action } from "@ngrx/store";
import { ApiResponse } from "src/app/common/models";
import { ApiResponsePayloadAction } from "src/app/root/store/actions/index";

/**
 * События, связанные с настройками пользователя.
 */
export enum CurrentUserSettingsActionType {
    SAVE_SHOW_DIALOG_FLAG = "[Current User Settings] Save show recognition task created dialog flag",
    SHOW_DIALOG_FLAG_SAVED_SUCCESSFULLY = "[Current User Settings] Show dialog flag saved successfully",
    SAVING_SHOW_DIALOG_FLAG_FAILED = "[Current User Settings] Saving show dialog flag failed",
}

/**
 * Событие, требующее сохранения флага необходимости показывать пользователю диалог о завершении задачи на
 * распознавание.
 */
export class SaveShowRecognitionTaskDialogFlagAction implements Action {
    readonly type = CurrentUserSettingsActionType.SAVE_SHOW_DIALOG_FLAG;
    constructor(public flag: boolean) { }
}

/**
 * Событие об успешном сохранении флага необходимости показывать пользователю диалог о завершении задачи на
 * распознавание.
 */
export class ShowRecognitionTaskDialogFlagSavedSuccessfullyAction implements Action {
    readonly type = CurrentUserSettingsActionType.SHOW_DIALOG_FLAG_SAVED_SUCCESSFULLY;
    constructor(public flag: boolean) { }
}

/**
 * Событие о неуспешном сохранении флага необходимости показывать пользователю диалог о завершении задачи на
 * распознавание.
 */
export class SavingShowRecognitionTaskDialogFlagFailedAction implements ApiResponsePayloadAction {
    readonly type = CurrentUserSettingsActionType.SAVING_SHOW_DIALOG_FLAG_FAILED;
    constructor(public payload: ApiResponse) { }
}

/**
 * Тип, объединяющий все события, связанные с настройками пользователя.
 */
export type CurrentUserSettingsAction =
    | SaveShowRecognitionTaskDialogFlagAction
    | ShowRecognitionTaskDialogFlagSavedSuccessfullyAction
    | SavingShowRecognitionTaskDialogFlagFailedAction
    ;
