import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ApiResponse } from 'src/app/common/models/api-response';
import { EnteraDocumentType } from 'src/app/common/models/document-type';
import { DocumentTypesService } from "src/app/spaces/modules/documents-registry/services/document-types.service";
import { documentTypesActions } from "src/app/root/store/actions/document-types.action";
import { DocumentTypesActionType } from "src/app/root/store/actions/document-types.action";

/**
 * Side-эффекты на события, связанные с типами документов.
 */
@Injectable()
export class DocumentTypesEffects {
    //region Ctor

    constructor(
        private actions$: Actions,
        private documentTypesService: DocumentTypesService
    ) {}

    //endregion
    //region Public

    /**
     * Выполнение загрузки типов документов.
     */
    @Effect()
    loadDocumentTypes$ = this.actions$
        .pipe(
            ofType(DocumentTypesActionType.LOAD),
            switchMap(() => this.documentTypesService.get()
                .pipe(
                    map((documentTypes: EnteraDocumentType[]) =>
                        documentTypesActions.loadSuccess({ value: documentTypes })
                    ),
                    catchError((apiResponse: ApiResponse) => of(documentTypesActions.loadFail({ error: apiResponse }))),
                )
            )
        );

    //endregion
}
