import { requestState } from "src/app/common/models/request-state";
import { ExternalItemGroupsActionType } from "src/app/spaces/modules/document-matching/store/actions/external-item-groups.action";
import { ExternalItemGroupsAction } from "src/app/spaces/modules/document-matching/store/actions/external-item-groups.action";
import { ExternalItemGroupsState } from "src/app/spaces/modules/document-matching/store/states/external-item-groups.state";

/**
 * Начальное состояние обработчика событий, связанных с состоянием групп объектов инвентаризации.
 */
const initialState: ExternalItemGroupsState = {

    /**
     * Начальное состояние запроса групп объектов инвентаризации с сервера.
     */
    externalItemGroupRequest: requestState.initial(),
};

/**
 * Обработчик событий, связанных с состоянием групп объектов инвентаризации.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 *
 * @return Новое состояние.
 */
export function externalItemGroupsReducer(
    state: ExternalItemGroupsState = initialState,
    action: ExternalItemGroupsAction,
): ExternalItemGroupsState {
    let result: ExternalItemGroupsState = state;

    switch (action.type) {

        case ExternalItemGroupsActionType.LOAD: {

            result = {
                ...state,
                externalItemGroupRequest: requestState.pending(),
            };

            break;
        }
        case ExternalItemGroupsActionType.LOAD_SUCCESS: {

            result = {
                ...state,
                externalItemGroupRequest: requestState.success(action.value),
            };

            break;
        }
        case ExternalItemGroupsActionType.LOAD_FAIL: {

            result = {
                ...state,
                externalItemGroupRequest: requestState.fail(action.error),
            };

            break;
        }
    }

    return result;
}
