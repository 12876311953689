/**
 * Комонент диалога обратной связи пользователя.
 */
import { Input } from "@angular/core";
import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FeedbackType } from "src/app/root/models";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "entera-feedback",
    templateUrl: "./feedback.component.html",
    styleUrls: ["./feedback.component.scss"],
})
export class FeedbackComponent {
    //region Inputs

    @Input()
    type: FeedbackType;

    //endregion
    //region Outputs

    @Output()
    readonly send: EventEmitter<string> = new EventEmitter<string>();

    //endregion

    /**
     * Контрол сообщения в диалоге.
     */
    messageControl: FormControl = new FormControl(null);

    //region Events

    /**
     * Обработчик кнопки отправить.
     */
    sendHandler() {

        this.send.emit(this.messageControl.value);
    }

    //endregion
}
