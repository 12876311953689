import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { CanActivateChild } from '@angular/router';

import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { tap } from 'rxjs/operators';

import { RootState } from '../../root/store';
import { RouterGoAction } from '../../root/store';
import { currentUserInfoSelector } from '../../root/store';
import { CurrentUserInfo } from '../../common/models';

/**
 * Route Guard.
 * Только что зарегистрировавшийся пользователь (кроме того, которого пригласили), у которого ещё нет
 * ни одного пространства документов, должен всегда попадать на страницу создания первого пространства
 * документов.
 *
 * Должен использоваться совместо с UserGuard, который инициирует загрузку данных пользователя.
 */
@Injectable({
    providedIn: 'root'
})
export class NewUserGuard implements CanActivate, CanActivateChild {
    //region Ctor

    constructor(
        private _store: Store<RootState>
    ) { }

    //endregion
    //region Public

    canActivate(): Observable<boolean> {

        return this._store
            .pipe(
                select(currentUserInfoSelector),

                // Логика будет выполняться только в случае наличия данных о пользователе.
                filter(currentUser => !!currentUser),

                // Новых пользователей редиректим на страницу создания первого пространства документов.
                tap((currentUser: CurrentUserInfo) => {

                    if (currentUser.isNew) {

                        // TODO Хардкод URL
                        setTimeout(() => this._store.dispatch(new RouterGoAction({ path: ['welcome'] })));
                    }
                }),

                switchMap((currentUser) => of(!currentUser.isNew))
            );
    }

    canActivateChild(): Observable<boolean> {

        return this.canActivate();
    }

    //endregion
}
