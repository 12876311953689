import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { ApiResponse } from "src/app/common/models/api-response";
import { SpaceIdsAndEmails } from "src/app/common/models/space-ids-and-emails.model";
import { ValueProps } from "src/app/root/store/actions/props/value-props";

/**
 * Типы событий, связанные с добавлением пользователей в пространства документов.
 */
export enum TrustedEmailsAdditionActionType {
    //region Enum values

    /**
     * Тип события, требующего инициализацию начальных данных для добавления доверенных почт в пространства документов.
     */
    INITIALIZE = "[Trusted emails addition] Initialize",

    /**
     * Тип события, требующего добавить доверенные почты в пространства документов.
     */
    ADD = "[Trusted emails addition] Invite users in spaces",

    /**
     * Тип события успешного выполнения запроса на добавление доверенных почт в пространства документов.
     */
    ADDITION_SUCCESS = "[Trusted emails addition] Addition finished successfully",

    /**
     * Тип события неудачного выполнения запроса на добавление доверенных почт в пространства документов.
     */
    ADDITION_FAILED = "[Trusted emails addition] Addition failed",

    //endregion
}

/**
 * Событие, требующее инициализацию начальных данных для добавления доверенных почт в пространства документов.
 */
const initialize = createAction(TrustedEmailsAdditionActionType.INITIALIZE);

/**
 * Событие, требующее добавить доверенные почты в пространства документов.
 */
const add = createAction(
    TrustedEmailsAdditionActionType.ADD,
    props<ValueProps<SpaceIdsAndEmails>>(),
);

/**
 * Событие успешного выполнения запроса на добавление доверенных почт в пространства документов.
 */
const additionSuccess = createAction(
    TrustedEmailsAdditionActionType.ADDITION_SUCCESS,
    props<ValueProps<SpaceIdsAndEmails>>(),
);

/**
 * Событие неудачного выполнения запроса на добавление доверенных почт в пространства документов.
 */
const additionFailed = createAction(
    TrustedEmailsAdditionActionType.ADDITION_FAILED,
    props<ValueProps<ApiResponse>>()
);

/**
 * Тип, объединяющий события, связанные с добавлением доверенных почт в пространства документов.
 */
export type TrustedEmailsAdditionAction =
    ReturnType<typeof initialize>
    | ReturnType<typeof add>
    | ReturnType<typeof additionSuccess>
    | ReturnType<typeof additionFailed>
    ;

/**
 * Все события, связанные с добавлением доверенных почт в пространства документов.
 */
export const trustedEmailsAdditionActions = {
    initialize: initialize,
    add: add,
    additionSuccess: additionSuccess,
    additionFailed: additionFailed,
};
