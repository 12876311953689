import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { OnInit } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';

/**
 * Компонент информации о нехватке прав на доступ к странице/данным.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'forbidden-error-details',
    styleUrls: ['forbidden-error-details.component.scss'],
    templateUrl: 'forbidden-error-details.component.html'
})
export class ForbiddenErrorDetailsComponent implements OnInit {
    //region Inputs

    /**
     * Входящие данные - URL страницы, для доступа к которой не хватает прав.  
     */
    @Input()
    path: string = '';

    //endregion
    //region Ctor

    constructor() { }

    //endregion
    //region Hooks

    ngOnInit() { }

    //endregion
}
