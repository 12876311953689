var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { select } from "@ngrx/store";
import { Store } from "@ngrx/store";
import { map } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { RouterGoAction } from "src/app/root/store/actions/router.action";
import { SelectedSpaceForMobileActions } from "src/app/root/store/actions/selected-space-for-mobile.action";
import { UploadToRecognizeState } from "src/app/root/store/index";
import { ChangeTaskToRecognizeAction } from "src/app/root/store/index";
import { currentUserInfoSelector } from "src/app/root/store/index";
/**
 * Корневой компонент мобильного интерфейса приложения.
 */
var MobileMainComponent = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор корневого компонента мобильного интерфейса приложения.
     *
     * @param store Сервис для доступа и управления состоянием приложения.
     */
    function MobileMainComponent(store) {
        //endregion
        //region Private
        /**
         * Преобразует информацию о клиентов в список его папок.
         *
         * @param userInfo Информация о пользователе.
         */
        this.userInfoToSpaces = function (userInfo) {
            return userInfo.spaces
                .map(function (space) { return (__assign({}, space, { balance: (space.balance + (space.useClientBalance ? userInfo.client.balance : 0)) })); });
        };
        this.noSelectedSpace = true;
        this._store = store;
    }
    //endregion
    //region Hooks
    /**
     * Логика при инициализации компонента.
     */
    MobileMainComponent.prototype.ngOnInit = function () {
        this.userInfo$ = this._store.pipe(select(currentUserInfoSelector));
        // Подписываемся на события появления/изменения пространств документов пользователя.
        this.spaces$ = this.userInfo$.pipe(filter(function (currentUser) { return !!currentUser; }), map(this.userInfoToSpaces));
    };
    //endregion
    //region Events
    /**
     * Обработчик выбора пространства документов.
     *
     * @param space Выбранное пространство документов.
     */
    MobileMainComponent.prototype.spaceSelectHandler = function (space) {
        this._store.dispatch(SelectedSpaceForMobileActions.set({ value: space }));
        this.noSelectedSpace = !space;
    };
    /**
     * Переход на страницу сканирования.
     */
    MobileMainComponent.prototype.onScanQrClick = function () {
        this._store.dispatch(new RouterGoAction({ path: ["qr"] }));
    };
    /**
     * Переход на страницу загрузки документов и возвращение состояния данных при загрузке и отправке файлов на
     * распознавание к первоначальному состоянию.
     */
    MobileMainComponent.prototype.onUploadDocumentsMobile = function () {
        this._store.dispatch(new ChangeTaskToRecognizeAction(__assign({}, new UploadToRecognizeState(), { openPostUploadDialog: false })));
        this._store.dispatch(new RouterGoAction({ path: ["mobile/upload"] }));
    };
    return MobileMainComponent;
}());
export { MobileMainComponent };
