<div class="simple-prompt-dlg">
    <h2 class="mat-h2 simple-prompt-dlg__header">{{ headerKey | translate:headerParams }}</h2>
    <hr class="simple-prompt-dlg__divider" />
    <mat-form-field class="simple-prompt-dlg__input">
        <textarea
            rows="4"
            *ngIf="!!multiline"
            [(ngModel)]="value"
            required="required"
            matInput
            placeholder="{{textKey | translate:textParams}}"
        >

        </textarea>
        <input
            *ngIf="!multiline"
            [(ngModel)]="value"
            required="required"
            matInput
            type="{{inputType}}"
            placeholder="{{textKey | translate:textParams}}"
        >
    </mat-form-field>
    <div class="simple-prompt-dlg__buttons">
        <button
                class="simple-prompt-dlg__button"
                mat-button
                (click)="onClose()"
        >
            {{ 'dialogs.close' | translate }}
        </button>
        <button
            class="simple-prompt-dlg__button"
            mat-raised-button
            color="primary"
            [disabled]="required && !value"
            (click)="onConfirm()"

        >
            {{ 'dialogs.confirm' | translate }}
        </button>
    </div>

</div>
