/**
 * Утилиты для работы с regex выражениями.
 */
export class RegexConstants {
    //region Constants

    /**
     * Паттерн - все что угодно, кроме цифр.
     */
    public static readonly NOT_DIGIT: RegExp = /\D/g;

    /**
     * Паттерн - точка.
     */
    public static readonly POINT: RegExp = /\./g;

    /**
     * Паттерн - запятая.
     */
    public static readonly COMMA: RegExp = /,/g;

    /**
     * Паттерн - минус.
     */
    public static readonly MINUS: RegExp = /-/g;

    /**
     * Паттерн - все что угодно, кроме цифр и точек.
     */
    public static readonly NOT_DIGIT_AND_NOT_POINT: RegExp = /[^\d|.]/g;

    //endregion
}
