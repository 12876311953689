import { requestState } from "src/app/common/models/request-state";
import { EntityExternalDocumentTypesState } from "src/app/spaces/modules/document-matching/store/states/entity-external-document-types.state";
import { EntityExternalDocumentTypesActionType } from "src/app/spaces/modules/document-matching/store/actions/entity-external-document-types.action";
import { EntityExternalDocumentTypeAction } from "src/app/spaces/modules/document-matching/store/actions/entity-external-document-types.action";

/**
 * Начальное состояние обработчика событий, связанных с состоянием типов внешних документов.
 */
const initialState: EntityExternalDocumentTypesState = {

    /**
     * Начальное состояние запроса внешних типов документов.
     */
    entityExternalTypesRequest: requestState.initial(),
};

/**
 * Обработчик событий, связанных с состоянием типов внешних документов.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 *
 * @return Новое состояние.
 */
export function entityExternalDocumentTypesReducer(
    state: EntityExternalDocumentTypesState = initialState,
    action: EntityExternalDocumentTypeAction,
): EntityExternalDocumentTypesState {
    let result: EntityExternalDocumentTypesState = state;

    switch (action.type) {

        case EntityExternalDocumentTypesActionType.LOAD: {

            result = {
                ...state,
                entityExternalTypesRequest: requestState.pending(),
            };

            break;
        }
        case EntityExternalDocumentTypesActionType.LOAD_SUCCESS: {

            result = {
                ...state,
                entityExternalTypesRequest: requestState.success(action.value),
            };

            break;
        }
        case EntityExternalDocumentTypesActionType.LOAD_FAIL: {

            result = {
                ...state,
                entityExternalTypesRequest: requestState.fail(action.error),
            };

            break;
        }
    }

    return result;
}
