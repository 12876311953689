import { Action } from '@ngrx/store';
import { Space, EnteraDocument } from 'src/app/common/models';
import { ApiResponse } from 'src/app/common/models';
import { ApiResponsePayloadAction } from '..';

/**
 * Типы событий, связанные с количеством новых документов.
 */
export enum NewDocumentsCountActionType {
    
    /**
     * Требование загрузки кол-ва.
     */
    LOAD = '[Root] Load new documents count for space',

    /**
     * Успешное выполнение загрузки кол-ва.
     */
    LOAD_SUCCESS = '[Root] Load new documents count for space Success',
    
    /**
     * Неудачное выполнение загрузки кол-ва.
     */
    LOAD_FAIL = '[Root] Load new documents count for space Fail',

    /**
     * Пометка документа как просмотренного.
     */
    MARK_AS_SEEN = '[Root] Mark document as seen',

    /**
     * Увеличение кол-ва на 1.
     */
    INCREMENT = '[Root] Increment new documents count',

    /**
     * Уменьшение кол-ва на 1.
     */
    DECREMENT = '[Root] Decrement new documents count',

    /**
     * Очистка состояния данных о кол-ве новых документов.
     */
    CLEAR = '[Root] Clear new documents count',
}

/**
 * Событие, требующее загрузки кол-ва новых документов для пространства документов.
 */
export class NewDocumentsCountLoadAction implements Action {
    readonly type = NewDocumentsCountActionType.LOAD;
    constructor(public payload: Space) { }
}

/**
 * Событие успешной загрузки кол-ва новых документов для пространства документов.
 */
export class NewDocumentsCountLoadSuccessAction implements Action {
    readonly type = NewDocumentsCountActionType.LOAD_SUCCESS;
    constructor(public payload: number) { }
}

/**
 * Событие неудачной загрузки кол-ва новых документов для пространства документов.
 */
export class NewDocumentsCountLoadFailAction implements ApiResponsePayloadAction {
    readonly type = NewDocumentsCountActionType.LOAD_FAIL;
    constructor(public payload: ApiResponse) { }
}

/**
 * Событие, требующее пометить документ как просмотренный.
 */
export class NewDocumentsCountMarkAsSeenAction implements Action {
    readonly type = NewDocumentsCountActionType.MARK_AS_SEEN;
    constructor(public payload: EnteraDocument) { }
}

/**
 * Событие, требующее увеличения кол-ва новых документов для пространства документов на 1.
 */
export class NewDocumentsCountIncrementAction implements Action {
    readonly type = NewDocumentsCountActionType.INCREMENT;
    constructor(public payload: number = 1) { }
}

/**
 * Событие, требующее уменьшения кол-ва новых документов для пространства документов на 1.
 */
export class NewDocumentsCountDecrementAction implements Action {
    readonly type = NewDocumentsCountActionType.DECREMENT;
    constructor(public payload: number = 1) { }
}

/**
 * Событие, требующее очистки состояния данных о кол-ве новых документов.
 */
export class NewDocumentsCountClearAction implements Action {
    readonly type = NewDocumentsCountActionType.CLEAR;
    constructor() { }
}

/**
 * Тип, объединяющий все события, связанные с кол-вом новых документов.
 */
export type NewDocumentsCountAction =
    | NewDocumentsCountLoadAction
    | NewDocumentsCountLoadFailAction
    | NewDocumentsCountMarkAsSeenAction
    | NewDocumentsCountLoadSuccessAction
    | NewDocumentsCountIncrementAction
    | NewDocumentsCountDecrementAction;