var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { distinct } from "rxjs/operators";
import { map } from "rxjs/operators";
import { DlgService } from "src/app/common/services";
import { FeedbackInitAction } from "src/app/root/store/actions/feedback.action";
import { FeedbackSendAction } from "src/app/root/store/actions/feedback.action";
import { feedbackStateSelector } from "src/app/root/store/reducers";
/**
 * Комонент диалога обратной связи пользователя.
 */
var FeedbackDlgComponent = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор компонента диалога обратной связи пользователя.
     *
     * @param store Сервис для управления и доступа к состоянию приложения.
     * @param dialogRef Диалог, в котором открывается текущий компонент.
     * @param data Тип обратной связи.
     * @param dlgService Сервис простых далогов.
     */
    function FeedbackDlgComponent(store, dialogRef, data, dlgService) {
        /**
         * Контроль подписок.
         */
        this._subscriptionControl$ = new Subject();
        this._store = store;
        this._dialogRef = dialogRef;
        this.type = data.type;
        this._dlgService = dlgService;
        this.sending$ = this._store.select(feedbackStateSelector)
            .pipe(map(function (state) { return state.sending; }), distinct());
        this._store.dispatch(new FeedbackInitAction(data));
    }
    //endregion
    //region Hooks
    FeedbackDlgComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._dialogRef.disableClose = true;
        this._store.select(feedbackStateSelector)
            .pipe(map(function (state) { return state.sent; }), distinct(), filter(Boolean), takeUntil(this._subscriptionControl$))
            .subscribe(function () { return _this._dialogRef.close(); });
        this._store.select(feedbackStateSelector)
            .pipe(map(function (state) { return state.error; }), distinct(), filter(Boolean), takeUntil(this._subscriptionControl$))
            .subscribe(function (error) {
            if (!error.errorCode) {
                error = __assign({}, error, { errorCode: "-" });
            }
            _this._dialogRef.close();
            _this._dlgService.openSimpleDlg({
                headerKey: "errorDetails.somethingGoesWrong",
                textKey: "feedback.error",
                textParams: error
            });
        });
    };
    FeedbackDlgComponent.prototype.ngOnDestroy = function () {
        this._subscriptionControl$.complete();
    };
    //endregion
    //region Events
    /**
     * Обработчик нажатия на Esc.
     *
     * Закрывает диалог.
     */
    FeedbackDlgComponent.prototype.onKeyUp = function () {
        this._dialogRef.close();
    };
    /**
     * Обработчик события отправки обратной связи.
     *
     * @param comment Комметарий пользователя.
     */
    FeedbackDlgComponent.prototype.sendHandler = function (comment) {
        this._store.dispatch(new FeedbackSendAction(comment));
    };
    return FeedbackDlgComponent;
}());
export { FeedbackDlgComponent };
