import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { CanLoad } from "@angular/router";

import { Store } from "@ngrx/store";
import { select } from "@ngrx/store";
import { Observable } from "rxjs";
import { of } from "rxjs";
import { filter } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { tap } from "rxjs/operators";

import { RootState } from "../store";
import { RouterGoAction } from "../store";
import { currentUserStateSelector } from "../store";
import { currentUserInfoSelector } from "../store";

/**
 * Route Guard страницы регистрации. 
 * 
 * Дает доступ до страницы регистрации только в том случае, если нет залогиневшегося пользователя. 
 * Если такой пользователь есть в системе, то перенаправляет на главную, откуда идет перенаправление 
 * по правилам главной страницы.
 */
@Injectable({
    providedIn: 'root'
})
export class RegistrationPageGuard implements CanActivate, CanLoad {
    //region Private fields

    private readonly _canActivate: Observable<boolean>;

    //endregion
    //region Ctor

    constructor(private _store: Store<RootState>) {

        this._canActivate = this._store
            .pipe(
                // Получаем состояние текущего пользователя.
                select(currentUserStateSelector),

                // Дожидаемся загрузки данных пользователя.
                filter(userState => userState.loaded || userState.failed),

                // Получаем пользрователя.
                switchMap(() => this._store.pipe(select(currentUserInfoSelector))),

                // Переанправляем на главную страницу, если есть пользователь.
                tap((user) => {

                    if (user) {

                        setTimeout(() => this._store.dispatch(new RouterGoAction({ path: [''] })));
                    }
                }),
                
                // Guard проускает, когда нет пользователя.
                switchMap((user) => of(!user)),
            );
    }

    //endregion
    //region Public

    canActivate(): Observable<boolean> {

        return this._canActivate;
    }


    canLoad(): Observable<boolean> {

        return this._canActivate;
    }

    //endregion
}
