import { ChangeDetectorRef } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { OnDestroy } from "@angular/core";
import { OnInit } from "@angular/core";
import { Input } from "@angular/core";
import { HostListener } from "@angular/core";
import { Inject } from "@angular/core";
import { Component } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material";
import { DlgCreationData } from "src/app/common/models/dlg-creation-data";
import { Space } from "src/app/common/models/space";
import { SpaceIdsAndEmails } from "src/app/common/models/space-ids-and-emails.model";

/**
 * Компонент отображения диалога для работы со списком доступных папок пользователя.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "user-spaces-dlg",
    templateUrl: "./user-spaces-dlg.component.html",
    styleUrls: ["./user-spaces-dlg.component.scss"],
})
export class UserSpacesDlgComponent implements OnInit, OnDestroy {
    //region Inputs

    /**
     * Список папок текущего пользователя.
     */
    @Input()
    spaces: Space[];

    /**
     * Список предварительно выбранных папок текущего пользователя.
     */
    @Input()
    preSelectedSpaces: Space[];

    /**
     * Состояние запроса на сервер.
     */
    @Input()
    set requestState(state: any) {

        this._requestState = state;
        this._detectionRef.markForCheck();
    };

    //endregion
    //region Fields

    /**
     * i18n-ключ заголовка диалога.
     */
    headerKey: string;

    /**
     * i18n-ключ кнопки подтверждения (применения).
     */
    okBtnKey: string;

    /**
     * i18n-ключ описания ошибки.
     */
    errorDescriptionKey: string;

    /**
     * i18n-ключ описания успешного запроса.
     */
    successDescriptionKey: string;

    /**
     * Функция, срабатывающая при открытии диалога.
     */
    private readonly _openCallback: () => void;

    /**
     * Функция, срабатывающая после нажатия кнопки подтверждения (применения).
     */
    private readonly _confirmCallback: (_: SpaceIdsAndEmails) => void;

    /**
     * Функция, срабатывающая после нажатия кнопки закрытия диалога.
     */
    private readonly _closeCallback: () => void;

    /**
     * Функция, срабатывающая при уничтожении компонента диалога.
     */
    private readonly _destroyCallback: () => void;

    /**
     * Состояние запроса на сервер.
     */
    private _requestState: any;

    //endregion
    //region Ctor

    /**
     * Конструктор компонента отображения диалога для работы со списком доступных папок пользователя.
     *
     * @param _detectionRef Сервис для управления запуском определения angular'ом изменений данных, произошедших в
     * компоненте.
     * @param data Данные для диалога.
     */
    constructor(
        private _detectionRef: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) data: DlgCreationData,
    ) {
        this._openCallback = data && data.openCallback || (() => {});
        this._confirmCallback = data && data.confirmCallback || (() => {});
        this._closeCallback = data && data.closeCallback || (() => {});
        this._destroyCallback = data && data.destroyCallback || (() => {});
        this.headerKey = data ? data.headerKey : "";
        this.okBtnKey = data ? data.okBtnKey : "";
        this.errorDescriptionKey = data ? data.errorDescriptionKey : "";
        this.successDescriptionKey = data ? data.successDescriptionKey : "";
    }

    //endregion
    //region Hooks

    ngOnInit(): void {

        this._openCallback();
    }

    ngOnDestroy(): void {

        this._destroyCallback();
    }

    //endregion
    //region Getters

    /**
     * Состояние запроса приглашения пользователей.
     */
    get requestState() {

        return this._requestState;
    }

    //endregion
    //region Public

    /**
     * Закрывает текущий диалог.
     */
    closeDialog(): void {

        if (!this.requestState || this.requestState.type !== 'pending') {

            this._closeCallback();
        }
    }

    //endregion
    //region Events

    /**
     * Обрабатывает событие нажатия кнопки отправки запроса на сервер.
     *
     * @param invitationData Данные для запроса на сервер.
     */
    confirmDlgDataHandler(invitationData: SpaceIdsAndEmails): void {

        this._confirmCallback(invitationData);
    }

    /**
     * Обработчик нажатия кнопки закрытия диалога.
     */
    closeDialogClickHandler(): void {

        this.closeDialog();
    }

    /**
     * Обработчик нажатия на Esc.
     *
     * Закрывает диалог.
     */
    @HostListener("window:keyup.esc")
    onKeyUp(): void {

        this.closeDialog();
    }

    //endregion
}
