var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { requestState } from "src/app/common/models/request-state";
import { IntermediateActionType } from "src/app/spaces/modules/document-matching/store/actions/intermediate.actions";
/**
 * Начальное состояние обработки промежуточного состояния.
 */
var initialState = {
    /**
     * Состояние промежуточного сохранения документов.
     */
    savingIntermediateState: requestState.initial(),
    /**
     * Состояние сброса (удаления) сопоставлений.
     */
    discardingDocumentMatches: requestState.initial(),
};
/**
 * Обработчик событий, связанных с изменением состояния обработки промежуточного состояния.
 *
 * @param state Текущее состояние обработки промежуточного состояния.
 * @param action Событие.
 *
 * @return Новое состояние.
 */
export function intermediateReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        case IntermediateActionType.SAVE_INTERMEDIATE: {
            result = __assign({}, state, { savingIntermediateState: requestState.pending() });
            break;
        }
        case IntermediateActionType.SAVE_INTERMEDIATE_SUCCESS: {
            result = __assign({}, state, { savingIntermediateState: requestState.success() });
            break;
        }
        case IntermediateActionType.SAVE_INTERMEDIATE_FAIL: {
            result = __assign({}, state, { savingIntermediateState: requestState.fail(action.error) });
            break;
        }
        case IntermediateActionType.DELETE_MATCHES_IF_SAFE: {
            result = __assign({}, state, { discardingDocumentMatches: requestState.pending() });
            break;
        }
        case IntermediateActionType.DELETE_MATCHES_IF_SAFE_SUCCESS: {
            result = __assign({}, state, { discardingDocumentMatches: requestState.success() });
            break;
        }
        case IntermediateActionType.DELETE_MATCHES_IF_SAFE_FAIL: {
            result = __assign({}, state, { discardingDocumentMatches: requestState.fail(action.error) });
            break;
        }
        case IntermediateActionType.SAVE_AND_EXIT_MATCHES_IF_SAFE: {
            result = __assign({}, state, { discardingDocumentMatches: requestState.pending() });
            break;
        }
        case IntermediateActionType.SAVE_AND_EXIT_IF_SAFE_SUCCESS: {
            result = __assign({}, state, { discardingDocumentMatches: requestState.success() });
            break;
        }
        case IntermediateActionType.SAVE_AND_EXIT_IF_SAFE_FAIL: {
            result = __assign({}, state, { discardingDocumentMatches: requestState.fail(action.error) });
            break;
        }
    }
    return result;
}
