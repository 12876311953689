import { Action } from "@ngrx/store";
import { ApiResponse } from "src/app/common/models";
import { DocumentsRegistryExportType } from "src/app/spaces/modules/documents-registry/models/documents-registry-export-type";
import { FilterAndSortAndSearchState } from "src/app/spaces/modules/documents-registry/models/filter-and-sort-and-search-state.model";

/**
 * Типы событий, связанные с выгрузкой реестра документов в Excel-файл.
 */
export enum DocumentsRegistryExportActionType {
    EXPORT_DOCUMENTS_REGISTRY = "[Documents registry export] Export",
    EXPORT_DOCUMENTS_REGISTRY_SUCCESS = "[Documents registry export] Export Success",
    EXPORT_DOCUMENTS_REGISTRY_FAIL = "[Documents registry export] Export Fail",
}

/**
 * Событие с требованием выгрузить реестр документов в Excel-файл.
 */
export class DocumentsRegistryExportExportAction implements Action {
    readonly type = DocumentsRegistryExportActionType.EXPORT_DOCUMENTS_REGISTRY;
    constructor(
        public payload: {
            filter: FilterAndSortAndSearchState,
            ids: string[],
            spaceId: string,
            type: DocumentsRegistryExportType
        }
    ) { }
}

/**
 * Событие успешной выгрузки реестра документов в Excel-файл.
 */
export class DocumentsRegistryExportSuccessAction implements Action {
    readonly type = DocumentsRegistryExportActionType.EXPORT_DOCUMENTS_REGISTRY_SUCCESS;
}

/**
 * Событие неудачной выгрузки реестра документов в Excel-файл.
 */
export class DocumentsRegistryExportFailAction implements Action {
    readonly type = DocumentsRegistryExportActionType.EXPORT_DOCUMENTS_REGISTRY_FAIL;
    constructor(public payload: ApiResponse) { }
}

/**
 * Тип, объединяющий все события, связанные с выгрузкой реестра документов в Excel-файл.
 */
export type DocumentsRegistryExportAction =
    | DocumentsRegistryExportExportAction
    | DocumentsRegistryExportSuccessAction
    | DocumentsRegistryExportFailAction
    ;
