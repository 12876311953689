import { createSelector } from '@ngrx/store';

import { currentUserSpacesSelector } from './current-user.selector';
import { routeStateSelector } from './router.selector';

import { RouteState } from '../reducers/router.reducer';
import { Space } from 'src/app/common/models/space';
import { recognitionTasksCountSelector } from './recognize';
import { newDocumentsCountSelector } from './document';

/**
 * Пара из текущего пространства документов и текущего URL'а.
 */
export interface SpaceAndRoute {
    space: Space;
    routeState: RouteState
}

/**
 * Набор данных для навигации по пространству документов.
 */
export interface SpaceNavData {

    /**
     * Текущее пространство документов.
     */
    space: Space;

    /**
     * Количество выполняющихся задач на распознавание.
     */
    recognitionTasksCount: number;

    /**
     * Количество новых документов.
     */
    newDocumentsCount: number;
}

/**
 * Селектор текущего пространства документов.
 */
export const spaceSelector = createSelector(
    routeStateSelector,
    currentUserSpacesSelector,
    (routeState: RouteState, spaces: Space[]): Space => {

        let space: Space = null;

        let spaceId: string = null;
        if (routeState) {

            spaceId = routeState.params.spaceId;
        }

        if (spaces && spaceId) {

            space = spaces.find((space: Space): boolean => space.id === spaceId);
        }

        return space;
    }
);

/**
 * Селектор текущего пространства документов и текущего URL'а.
 */
export const spaceAndRouteSelector = createSelector(
    spaceSelector,
    routeStateSelector,
    (space, routeState): SpaceAndRoute => {
        return { space, routeState };
    }
);

/**
 * Селектор данных для навигации по пространству документов.
 */
export const spaceNavDataSelector = createSelector(
    spaceSelector,
    recognitionTasksCountSelector,
    newDocumentsCountSelector,
    (space, recognitionTasksCount, newDocumentsCount): SpaceNavData => {

        return { space, recognitionTasksCount, newDocumentsCount };
    }
);
