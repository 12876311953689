<button
    class="mobile-document-upload-file-button"
    mat-button
    [disabled]="loading"
    (click)="uploadInput.click()"
>
    <mat-icon >add_circle_outline</mat-icon>
    {{ "common.upload-to-recognize-dialog.buttons.select-file" | translate }}
</button>
<input
    #uploadInput
    class="mobile-document-upload-file-button__input"
    type="file"
    multiple
    accept=".jpeg,.jpg,.png,.tiff,.tif,.bmp,.pdf,.doc,.docx,.odt,.xls,.xlsx,.rtf,.ods"
    (change)="onChange()"
/>
