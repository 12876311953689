/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-upload-indicator.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/tooltip";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "@angular/cdk/scrolling";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "@angular/platform-browser";
import * as i9 from "@ngx-translate/core";
import * as i10 from "../../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i11 from "@angular/material/progress-bar";
import * as i12 from "@angular/platform-browser/animations";
import * as i13 from "@angular/common";
import * as i14 from "ngx-pipes";
import * as i15 from "./file-upload-indicator.component";
var styles_FileUploadIndicator = [i0.styles];
var RenderType_FileUploadIndicator = i1.ɵcrt({ encapsulation: 0, styles: styles_FileUploadIndicator, data: {} });
export { RenderType_FileUploadIndicator as RenderType_FileUploadIndicator };
function View_FileUploadIndicator_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "div", [["class", "file__new-label"]], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i8.HAMMER_LOADER]], { message: [0, "message"] }, null), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("common.upload-to-recognize-dialog.newDocumentTooltip")), ""); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("common.upload-to-recognize-dialog.newDocument")); _ck(_v, 3, 0, currVal_1); }); }
function View_FileUploadIndicator_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "div", [["class", "file__duplicate-label"]], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i8.HAMMER_LOADER]], { message: [0, "message"] }, null), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("common.upload-to-recognize-dialog.duplicateTooltip")), ""); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("common.upload-to-recognize-dialog.duplicate")); _ck(_v, 3, 0, currVal_1); }); }
function View_FileUploadIndicator_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "file__delete"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove.emit(_v.parent.context.$implicit.file) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("common.upload-to-recognize-dialog.buttons.remove")); _ck(_v, 1, 0, currVal_0); }); }
function View_FileUploadIndicator_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar_thin mat-progress-bar"], ["mode", "determinate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i10.View_MatProgressBar_0, i10.RenderType_MatProgressBar)), i1.ɵdid(2, 4374528, null, 0, i11.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i12.ANIMATION_MODULE_TYPE], [2, i11.MAT_PROGRESS_BAR_LOCATION]], { value: [0, "value"], mode: [1, "mode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.uploadState.progress; var currVal_4 = "determinate"; _ck(_v, 2, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).value; var currVal_1 = i1.ɵnov(_v, 2).mode; var currVal_2 = i1.ɵnov(_v, 2)._isNoopAnimation; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_FileUploadIndicator_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [["fileElement", 1]], null, 11, "div", [["class", "file"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "file__name"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadIndicator_2)), i1.ɵdid(5, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadIndicator_3)), i1.ɵdid(7, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "file__size"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵppd(10, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadIndicator_4)), i1.ɵdid(12, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadIndicator_5)), i1.ɵdid(14, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_v.context.$implicit.fileId; _ck(_v, 5, 0, currVal_1); var currVal_2 = !!_v.context.$implicit.fileId; _ck(_v, 7, 0, currVal_2); var currVal_4 = (!_co.uploadState.loading && !_co.uploadState.loaded); _ck(_v, 12, 0, currVal_4); var currVal_5 = !_co.uploadState.failed; _ck(_v, 14, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.file.name; _ck(_v, 3, 0, currVal_0); var currVal_3 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.file.size, 2)); _ck(_v, 9, 0, currVal_3); }); }
export function View_FileUploadIndicator_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i14.BytesPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadIndicator_1)), i1.ɵdid(2, 278528, null, 0, i13.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.uploadState.filesWithMetaData; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FileUploadIndicator_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "entera-file-upload-indicator", [], null, null, null, View_FileUploadIndicator_0, RenderType_FileUploadIndicator)), i1.ɵdid(1, 49152, null, 0, i15.FileUploadIndicator, [], null, null)], null, null); }
var FileUploadIndicatorNgFactory = i1.ɵccf("entera-file-upload-indicator", i15.FileUploadIndicator, View_FileUploadIndicator_Host_0, { uploadState: "uploadState" }, { remove: "remove" }, []);
export { FileUploadIndicatorNgFactory as FileUploadIndicatorNgFactory };
