<div class="phone-confirm-dlg" >

    <h2 class="mat-h2 phone-confirm-dlg__header">{{ "common.phone-confirm-dlg.header" | translate }}</h2>
    <div class="phone-confirm-dlg__info">
        {{ "common.phone-confirm-dlg.info" | translate }}
        <span *ngIf="phonePreset$ | async">{{ "common.phone-confirm-dlg.infoPhonePreset" | translate}}</span>
    </div>

    <div class="phone-confirm-dlg__inputs">
        <mat-form-field class="phone-confirm-dlg__input">
            <ngx-mat-intl-tel-input
                [preferredCountries]="countryCode"
                [enablePlaceholder]="true"
                [formControl]="phoneControl"
                placeholder="{{ 'common.phone-confirm-dlg.phone' | translate }}"
                (keydown)="sendSmsByEnterHandler($event)"
                [required]="true"
            >
            </ngx-mat-intl-tel-input>
            <span
                *ngIf="(smsOnceSent$ | async) && !(smsSending$ | async)"
                class="phone-confirm-dlg__timer"
                matSuffix>{{ timer$ | async }}
            </span>
            <span
                *ngIf="(smsOnceSent$ | async) && !(timer$ | async) && !(smsSending$ | async)"
                class="phone-confirm-dlg__send-again"
                [class.phone-confirm-dlg__send-again_not-allowed]="phoneControl.invalid || phoneControl.disabled"
                matSuffix
                (click)="sendSmsAgainHandler($event)"
            >
                {{ "common.phone-confirm-dlg.sendAgain" | translate }}
            </span>
            <mat-spinner *ngIf="smsSending$ | async" matSuffix [diameter]="14"></mat-spinner>
            <mat-error *ngIf="phoneControl?.errors?.pattern || phoneControl?.errors?.validatePhoneNumber">
                {{ 'common.phone-confirm-dlg.error.wrongNumberFormat' | translate }}
            </mat-error>
            <mat-error *ngIf="phoneControl?.errors?.server && (smsSendError$ | async)?.errorCode!='10007.10016'" >
                {{ (smsSendError$ | async)?.errorMessage }}
                <span *ngIf="!((smsSendError$ | async)?.errorMessage)">
                    {{ "common.phone-confirm-dlg.error.unknown" | translate }}
                </span>
            </mat-error>
            <mat-error *ngIf="
                (smsSendError$ | async)?.errorCode=='10007.10016'
                 && !phoneControl?.errors?.pattern
                 && !phoneControl?.errors?.validatePhoneNumber
            ">
                {{'common.phone-confirm-dlg.error.phoneExists.FirstPart' | translate}}

                <a class="phone-confirm-dlg__input-error-link" href="tel:{{'common.phone-confirm-dlg.error.phoneExists.SupportNumber' | translate}}">
                    {{'common.phone-confirm-dlg.error.phoneExists.SupportNumber' | translate}}
                </a>

                {{'common.phone-confirm-dlg.error.phoneExists.SecondPart' | translate}}

                <a class="phone-confirm-dlg__input-error-link" href="mailto:{{'common.phone-confirm-dlg.error.phoneExists.SupportMail' | translate}}">
                    {{'common.phone-confirm-dlg.error.phoneExists.SupportMail' | translate}}
                </a>
            </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="smsOnceSent$ | async" class="phone-confirm-dlg__input">
            <input
                [formControl]="codeControl"
                (keydown)="confirmCodeByEnterHandler($event)"
                matInput
                placeholder="{{ 'common.phone-confirm-dlg.code' | translate }}"
            >
            <mat-spinner *ngIf="codeConfirming$ | async" matSuffix [diameter]="14"></mat-spinner>
            <mat-error *ngIf="codeControl?.errors?.server">
                {{ (codeConfirmError$ | async)?.errorMessage }}
                <span *ngIf="!((codeConfirmError$ | async)?.errorMessage)">
                    {{ "common.phone-confirm-dlg.error.unknown" | translate }}
                </span>
            </mat-error>
            <mat-error *ngIf="codeControl?.errors?.required">{{ "common.phone-confirm-dlg.error.emptyCode" | translate }}</mat-error>
            <mat-error *ngIf="codeControl?.errors?.maxlength">{{ "common.phone-confirm-dlg.error.maxLength" | translate }}</mat-error>
        </mat-form-field>
    </div>
    <div class="phone-confirm-dlg__buttons">
        <button
            class="phone-confirm-dlg__button"
            mat-button
            (click)="cancelHandler($event)"
        >
            {{ "button.cancel" | translate }}
        </button>
        <button
            *ngIf="!(smsOnceSent$ | async)"
            (click)="sendSmsHandler()"
            [disabled]="phoneControl.invalid || (smsSending$ | async)"
            class="phone-confirm-dlg__button"
            mat-raised-button
            color="primary"
        >
            {{ "common.phone-confirm-dlg.sendCode" | translate }}
        </button>
        <button
            *ngIf="smsOnceSent$ | async"
            [disabled]="codeControl.invalid || (codeConfirming$ | async)"
            (click)="confirmCodeHandler()"
            class="phone-confirm-dlg__button"
            mat-raised-button
            color="primary"
        >
            {{ "button.confirm" | translate }}
        </button>
    </div>
</div>
