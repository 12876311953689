var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as moment from "moment";
import * as _ from "underscore";
import { VatRate } from "src/app/spaces/modules/document/models/vat-rate";
/**
 * Состояние фильтров в реестре документов.
 */
var DocumentRegistryFiltersState = /** @class */ (function () {
    //endregion
    //region Ctor
    function DocumentRegistryFiltersState() {
        //endregion
        //region Public fields
        /**
         * Типы документов.
         */
        this.documentType = null;
        /**
         * ID покупателей по документам.
         */
        this.customer = null;
        /**
         * ID поставщиков по документам.
         */
        this.supplier = null;
        /**
         * ID создателей документов.
         */
        this.creator = null;
        /**
         * Дата создания документа от.
         */
        this.createdDateFrom = null;
        /**
         * Дата создания документа до.
         */
        this.createdDateTo = null;
        /**
         * Дата документа от.
         */
        this.documentDateFrom = null;
        /**
         * Дата документа до.
         */
        this.documentDateTo = null;
        /**
         * Состояния документов.
         */
        this.documentState = null;
        /**
         * Фильтр выгрузки документа.
         */
        this.uploaded = null;
        /**
         * Фильтр выгрузки документа для иностранцев.
         */
        this.uploadedState = null;
        /**
         * Фильтр иностранных документов.
         */
        this.foreign = null;
        /**
         * Источник задачи на распознавание.
         */
        this.source = null;
        /**
         * Фильтр по налоговой ставке.
         */
        this.taxRate = null;
        /**
         * Значения фильтра по умолчанию.
         */
        this.defaults = null;
        /**
         * Подсчет документов вызван принудительно по кнопке для админа?
         */
        this.isForcedCounting = false;
    }
    //endregion
    //region Public
    /**
     * Возвращает состояние фильтров для передачи их на сервер.
     *
     * @return Состояние фильтров для передачи их на сервер.
     */
    DocumentRegistryFiltersState.prototype.getStateForRequest = function () {
        var state = new DocumentRegistryFiltersState();
        if (this.documentType) {
            state.documentType = [this.documentType.id];
        }
        if (this.documentState) {
            state.documentState = [this.documentState.id];
        }
        if (this.customer) {
            state.customer = this.customer.map(function (cus) { return cus.id; });
        }
        if (this.supplier) {
            state.supplier = this.supplier.map(function (sup) { return sup.id; });
        }
        if (this.creator) {
            state.creator = [this.creator.id];
        }
        if (this.createdDateFrom) {
            // К значению фильтра "дата загрузки от" добавляем время начала дня, чтобы оно покрывало целый день
            // выбранной даты, а также добавляем часовой пояс.
            var date = this.createdDateFrom.format(DocumentRegistryFiltersState._DATE_FORMAT);
            state.createdDateFrom = date + 'T00:00:00.000' + DocumentRegistryFiltersState._getOffsetFromUtc();
        }
        if (this.createdDateTo) {
            // К значению фильтра "дата загрузки до" добавляем время конца дня, чтобы оно покрывало целый день
            // выбранной даты, а также добавляем часовой пояс.
            var date = this.createdDateTo.format(DocumentRegistryFiltersState._DATE_FORMAT);
            state.createdDateTo = date + 'T23:59:59.999' + DocumentRegistryFiltersState._getOffsetFromUtc();
        }
        if (this.documentDateFrom) {
            state.documentDateFrom = this.documentDateFrom
                .format(DocumentRegistryFiltersState._DATE_FORMAT);
        }
        if (this.documentDateTo) {
            state.documentDateTo = this.documentDateTo
                .format(DocumentRegistryFiltersState._DATE_FORMAT);
        }
        if (this.uploaded !== null) {
            state.uploaded = this.uploaded.id;
        }
        if (this.uploadedState !== null) {
            state.uploadedState = this.uploadedState.id;
        }
        if (this.foreign !== null) {
            state.foreign = this.foreign.id;
        }
        if (this.isForcedCounting !== null) {
            state.isForcedCounting = this.isForcedCounting;
        }
        if (this.source) {
            state.source = [this.source.id];
        }
        if (this.taxRate) {
            if (typeof this.taxRate === "string") {
                state.taxRate = this.taxRate;
            }
            else {
                state.taxRate = this.taxRate.value;
            }
        }
        // Убираем null-значения.
        Object.keys(state).forEach(function (key) {
            if (state[key] === null) {
                delete state[key];
            }
        });
        return state;
    };
    /**
     * Состояние фильтров пустое?
     *
     * @return Да/Нет.
     */
    DocumentRegistryFiltersState.prototype.isEmpty = function () {
        var _this = this;
        return Object
            .keys(this)
            .filter(function (key) { return key !== 'defaults'; })
            .every(function (key) { return !_this[key] || (_this.defaults && _this.defaults[key] === _this[key]); });
    };
    /**
     * Проверяет, соответствует ли документ фильтру.
     *
     * @param document Документ для проверки.
     *
     * @return Возвращает true, если документ соответствует фильтру, иначе false.
     */
    DocumentRegistryFiltersState.prototype.matchFilterState = function (document) {
        if (!DocumentRegistryFiltersState.checkIfStringMatchFilter(document.type, this.documentType)) {
            return false;
        }
        if (this.customer && this.customer.length) {
            var included = void 0;
            if (typeof this.customer[0] === "string") {
                included = this.customer.some(function (cust) {
                    return cust === document.customer || document.customer && cust === document.customer.name;
                });
            }
            else {
                included = this.customer.some(function (cust) {
                    return cust === document.customer || document.customer && cust && cust.id === document.customer.id;
                });
            }
            if (!included) {
                return false;
            }
        }
        if (this.supplier && this.supplier.length) {
            var included = void 0;
            if (typeof this.supplier[0] === "string") {
                included = this.supplier.some(function (cust) {
                    return cust === document.supplier
                        || document.supplier && cust === document.supplier.name;
                });
            }
            else {
                included = this.supplier.some(function (cust) {
                    return cust === document.supplier
                        || document.supplier && cust && cust.id === document.supplier.id;
                });
            }
            if (!included) {
                return false;
            }
        }
        if (!DocumentRegistryFiltersState.checkIfStringMatchFilter(document.foreign.toString(), this.foreign)) {
            return false;
        }
        if (!DocumentRegistryFiltersState.checkIfStringMatchFilter(document.creator.id, this.creator)) {
            return false;
        }
        if (!DocumentRegistryFiltersState.checkIfStringMatchFilter(document.state, this.documentState)) {
            return false;
        }
        if (!DocumentRegistryFiltersState.checkIfStringMatchFilter(document.uploaded.toString(), this.uploaded)) {
            return false;
        }
        if (!DocumentRegistryFiltersState.checkIfStringMatchFilter(document.recognitionTask.source, this.source)) {
            return false;
        }
        if (this.taxRate != null) {
            if (typeof this.taxRate === "string") {
                if (!(document.taxRates || []).includes(this.taxRate)) {
                    return false;
                }
            }
            else if (!(document.taxRates || []).includes(this.taxRate.value)) {
                return false;
            }
        }
        var createdDateFromMatch = DocumentRegistryFiltersState.checkIfMomentMatchFilter(document.createdDate, this.createdDateFrom, function (docCreatedDate, filterCreatedDate) { return docCreatedDate.isSameOrAfter(filterCreatedDate); });
        if (!createdDateFromMatch) {
            return false;
        }
        var createdDateToMatch = DocumentRegistryFiltersState.checkIfMomentMatchFilter(document.createdDate, this.createdDateTo, function (docCreatedDate, filterCreatedDate) { return docCreatedDate.isSameOrBefore(filterCreatedDate); });
        if (!createdDateToMatch) {
            return false;
        }
        var docDateFromMatch = DocumentRegistryFiltersState.checkIfMomentMatchFilter(document.createdDate, this.documentDateFrom, function (docDate, filterDateFrom) { return docDate.isSameOrAfter(filterDateFrom); });
        if (!docDateFromMatch) {
            return false;
        }
        var docDateToMatch = DocumentRegistryFiltersState.checkIfMomentMatchFilter(document.createdDate, this.createdDateTo, function (docDate, filterDateFrom) { return docDate.isSameOrBefore(filterDateFrom); });
        if (!docDateToMatch) {
            return false;
        }
        return true;
    };
    /**
     * Возращает кол-во не пустых фильтров.
     *
     * @return Кол-во фильтров.
     */
    DocumentRegistryFiltersState.prototype.getSize = function () {
        var _this = this;
        return Object
            .keys(this)
            .filter(function (key) { return !(key === "defaults" || (!_this[key] || (_this.defaults && _this.defaults[key] === _this[key]))); })
            .length;
    };
    /**
     * Возвращает состояние фильтров пригодное для установки в форму.
     *
     * @return Состояние фильтров пригодное для установки в форму.
     */
    DocumentRegistryFiltersState.prototype.getFormValue = function () {
        var formValue = Object.assign({}, this);
        delete formValue.defaults;
        return formValue;
    };
    //endregion
    //region Public static
    /**
     * На основе данных состояния URL создаёт экземпляр класса DocumentRegistryFiltersState.
     *
     * @param routeState Состояние URL.
     *
     * @return Состояние фильтров.
     */
    DocumentRegistryFiltersState.fromUrl = function (routeState) {
        var state = DocumentRegistryFiltersState.fromForm(routeState.queryParams);
        // TODO Хардкод части URL'а.
        if (routeState.path.endsWith('completed')) {
            state.documentState = {
                id: 'RECOGNIZED'
            };
            state.defaults = {};
            state.defaults.documentState = state.documentState;
        }
        return state;
    };
    /**
     * На основе данных с формы фильтров создаёт экземпляр класса DocumentRegistryFiltersState.
     *
     * @param formState Данные с формы фильтров.
     *
     * @return Состояние фильтров.
     */
    DocumentRegistryFiltersState.fromForm = function (formState) {
        var state = new DocumentRegistryFiltersState();
        DocumentRegistryFiltersState._fromForArray(formState, state, "source");
        DocumentRegistryFiltersState._fromForBoolean(formState, state, "foreign");
        DocumentRegistryFiltersState._fromForArray(formState, state, "uploadedState");
        DocumentRegistryFiltersState._fromForBoolean(formState, state, "uploaded");
        DocumentRegistryFiltersState._fromForArray(formState, state, "documentType");
        DocumentRegistryFiltersState._fromForArray(formState, state, "documentState");
        DocumentRegistryFiltersState._fromForArrayAsArray(formState, state, "customer");
        DocumentRegistryFiltersState._fromForArrayAsArray(formState, state, "supplier");
        DocumentRegistryFiltersState._fromForArray(formState, state, "creator");
        DocumentRegistryFiltersState._fromForDate(formState, state, "createdDateFrom");
        DocumentRegistryFiltersState._fromForDate(formState, state, "createdDateTo");
        DocumentRegistryFiltersState._fromForDate(formState, state, "documentDateFrom");
        DocumentRegistryFiltersState._fromForDate(formState, state, "documentDateTo");
        DocumentRegistryFiltersState._fromForDate(formState, state, "documentDateTo");
        state.taxRate = DocumentRegistryFiltersState._extractVatRate(formState);
        if (formState["isForcedCounting"] != null) {
            state.isForcedCounting = formState["isForcedCounting"];
        }
        return state;
    };
    /**
     * Состояния фильтров совпадают?
     *
     * @param state Состояние фильтров.
     * @param anotherState Другое состояние фильтров.
     *
     * @return Да/Нет.
     */
    DocumentRegistryFiltersState.isSameStates = function (state, anotherState) {
        return (state
            && anotherState
            && DocumentRegistryFiltersState._isObjectsEquals(state.documentType, anotherState.documentType)
            && DocumentRegistryFiltersState._isObjectsEquals(state.documentState, anotherState.documentState)
            && DocumentRegistryFiltersState._isArraysEquals(state.customer, anotherState.customer)
            && DocumentRegistryFiltersState._isArraysEquals(state.supplier, anotherState.supplier)
            && DocumentRegistryFiltersState._isObjectsEquals(state.customer, anotherState.customer)
            && DocumentRegistryFiltersState._isObjectsEquals(state.creator, anotherState.creator)
            && DocumentRegistryFiltersState._isObjectsEquals(state.source, anotherState.source)
            && state.foreign === anotherState.foreign
            && state.uploaded === anotherState.uploaded
            && state.uploadedState === anotherState.uploadedState
            && DocumentRegistryFiltersState._isDatesEquals(state.createdDateFrom, anotherState.createdDateFrom)
            && DocumentRegistryFiltersState._isDatesEquals(state.createdDateTo, anotherState.createdDateTo)
            && DocumentRegistryFiltersState._isDatesEquals(state.documentDateFrom, anotherState.documentDateFrom)
            && DocumentRegistryFiltersState._isDatesEquals(state.documentDateTo, anotherState.documentDateTo)
            && state.taxRate === anotherState.taxRate);
    };
    //endregion
    //region Private static
    /**
     * Два заданных значения равны?
     *
     * @param a Первое значение.
     * @param b Второе значение.
     *
     * @return Да/Нет.
     *
     * @private
     */
    DocumentRegistryFiltersState._isObjectsEquals = function (a, b) {
        if (!a && !b) {
            return true;
        }
        if (a && !b || !a && b) {
            return false;
        }
        return (a.id === b.id);
    };
    /**
     * Два заданных массива равны?
     *
     * @param a Первый массив.
     * @param b Второй массив.
     *
     * @return Да/Нет.
     *
     * @private
     */
    DocumentRegistryFiltersState._isArraysEquals = function (a, b) {
        if (!a && !b) {
            return true;
        }
        if (a && !b || !a && b) {
            return false;
        }
        return _.isEqual(_.sortBy(a, _.identity), _.sortBy(b, _.identity));
    };
    /**
     * Две заданные даты равны?
     *
     * @param a Первая дата.
     * @param b Вторая дата.
     *
     * @return Да/Нет.
     *
     * @private
     */
    DocumentRegistryFiltersState._isDatesEquals = function (a, b) {
        if (!a && !b) {
            return true;
        }
        if (a && !b || !a && b) {
            return false;
        }
        return moment(a.format(DocumentRegistryFiltersState._DATE_FORMAT))
            .isSame(moment(b.format(DocumentRegistryFiltersState._DATE_FORMAT)));
    };
    /**
     * Берёт true/false данные от объекта и кладёт их в состояние фильтров;
     * somestate[field] может содержать нужное состояние фильтра, а может содержать
     * объект {id : value}, где id и является нужным состоянием true/false. Поэтому
     * в методе есть проверка, которая смотрит что именно в него приходит.
     *
     * @param someState Объект, содержащий состояние фильтров.
     * @param state Состояние фильтров.
     * @param field Поле состояния фильтров.
     *
     * @private
     */
    DocumentRegistryFiltersState._fromForBoolean = function (someState, state, field) {
        if (!!someState[field]) {
            if (typeof someState[field] === "string") {
                state[field] = { id: someState[field].toLowerCase() };
            }
            else {
                state[field] = __assign({}, someState[field]);
            }
        }
    };
    /**
     * Берёт данные от объекта и кладёт их в состояние фильтров.
     *
     * Если данные - это массив - кладет объект с id со значением первого элемента массива.
     *
     * @param someState Объект, содержащий состояние фильтров.
     * @param state Состояние фильтров.
     * @param field Поле состояния фильтров.
     *
     * @private
     */
    DocumentRegistryFiltersState._fromForArray = function (someState, state, field) {
        if (someState[field]) {
            if (typeof someState[field] === "string") {
                state[field] = { id: someState[field] };
            }
            else if (someState[field] instanceof Array) {
                state[field] = { id: someState[field][0] };
            }
            else {
                state[field] = __assign({}, someState[field]);
            }
        }
    };
    /**
     * Берёт данные от объекта и кладёт их в состояние фильтров.
     *
     * Если данные - это массив - создает массив объектов с id равными элементам массива и кладет его в состояние
     * фильтров.
     *
     * @param someState Объект, содержащий состояние фильтров.
     * @param state Состояние фильтров.
     * @param field Поле состояния фильтров.
     */
    DocumentRegistryFiltersState._fromForArrayAsArray = function (someState, state, field) {
        if (someState[field]) {
            if (typeof someState[field] === "string") {
                state[field] = [{ id: someState[field] }];
            }
            else if (someState[field] instanceof Array) {
                var options_1 = [];
                someState[field].forEach(function (opt) {
                    if (typeof opt === "string") {
                        options_1 = options_1.concat([{ id: opt }]);
                    }
                    else {
                        options_1 = someState[field];
                    }
                });
                state[field] = options_1;
            }
            else {
                state[field] = __assign({}, someState[field]);
            }
        }
    };
    /**
     * Берёт данные даты от объекта и кладёт их в состояние фильтров.
     *
     * @param someState Объект, содержащий состояние фильтров.
     * @param state Состояние фильтров.
     * @param field Поле состояния фильтров.
     *
     * @private
     */
    DocumentRegistryFiltersState._fromForDate = function (someState, state, field) {
        if (someState[field]) {
            if (typeof someState[field] === "string") {
                state[field] = moment(someState[field], DocumentRegistryFiltersState._DATE_FORMAT);
            }
            else if (typeof someState[field].calendar === "function") {
                state[field] = moment(someState[field]);
            }
            if (state[field] && !state[field].isValid()) {
                state[field] = null;
            }
        }
    };
    /**
     * Извлекает налоговую ставку из состояния фильтров.
     *
     * @param state Состояние фильтров.
     *
     * @return Налоговая ставка.
     */
    DocumentRegistryFiltersState._extractVatRate = function (state) {
        if (state.taxRate) {
            if (typeof state.taxRate === "string") {
                return VatRate.get(state.taxRate);
            }
            else {
                return state.taxRate;
            }
        }
        return null;
    };
    /**
     * Возвращает разницу между временем клиента и UTC в часах.
     *
     * Например, для московского времени результатом будет строка '+03'.
     *
     * @return Разница между временем клиента и UTC в часах.
     *
     * @private
     */
    DocumentRegistryFiltersState._getOffsetFromUtc = function () {
        var offset = '';
        var minutes = moment().utcOffset();
        if (minutes >= 0) {
            offset = '+';
        }
        else {
            offset = '-';
        }
        var hours = Math.round(Math.abs(minutes) / 60);
        if (hours < 10) {
            offset += '0';
        }
        offset += hours;
        return offset;
    };
    /**
     * Проверяет, входит ли значение в перечень допустимых по значению фильтра.
     *
     * @param value Значение.
     * @param filterValue Значение фильтра.
     */
    DocumentRegistryFiltersState.checkIfStringMatchFilter = function (value, filterValue) {
        if (filterValue != null) {
            if (Array.isArray(filterValue)) {
                if (!filterValue.includes(value)) {
                    return false;
                }
            }
            else if (typeof filterValue === "string") {
                if (filterValue !== value) {
                    return false;
                }
            }
            else if (filterValue.id !== value) {
                return false;
            }
        }
        return true;
    };
    /**
     * Проверяет, входит ли значение момента в перечень допустимых по значению фильтра.
     *
     * @param value Значение.
     * @param filterValue Значение фильтра.
     * @param matchFn Функция для сравнения моментов.
     *
     * @return Да/нет.
     */
    DocumentRegistryFiltersState.checkIfMomentMatchFilter = function (value, filterValue, matchFn) {
        if (filterValue != null) {
            var momentValue = moment(value);
            if (typeof filterValue === "string") {
                if (!matchFn(momentValue, moment(filterValue))) {
                    return false;
                }
            }
            else {
                if (!matchFn.apply(momentValue, filterValue)) {
                    return false;
                }
            }
        }
        return true;
    };
    //Private constants
    /**
     * Формат даты для передачи состояния фильтра на сервер.
     */
    DocumentRegistryFiltersState._DATE_FORMAT = 'YYYY-MM-DD';
    return DocumentRegistryFiltersState;
}());
export { DocumentRegistryFiltersState };
