import { RecognitionTask } from "src/app/common/models";
import { EnteraDocument } from "src/app/common/models";
import { RecognitionTasksAction, RecognitionTasksActionType } from "../../actions";

/**
 * Состояние задач на распознавание для текущего пространства документов.
 */
export class RecognitionTasksState {

    /**
     * Выполняется загрузка задач на распознавание?
     */
    loading: boolean = false;

    /**
     * Загрузка задач на распознавание завершилась успешно?
     */
    loaded: boolean = false;

    /**
     * Загрузка задач на распознавание завершилась неудачно?
     */
    failed: boolean = false;

    /**
     * Выполняющиеся задачи на распознавание.
     */
    tasks: { [id: string]: RecognitionTask } = {};
    
    /**
     * Последняя успешно выполненная задача на распознавание.
     */
    lastRecognized: RecognitionTask = null;
    
    /**
     * Последняя неудачно выполненная задача на распознавание.
     */
    lastError: RecognitionTask = null;

    /**
     * Расознанные документы во время работы.
     */
    recognizedDocuments: EnteraDocument[] = [];

    /**
     * Распознанные документы (статус распознан), которые будут является как начальные документы. Загружаются со всех
     * активных задач распознавания при открытии реестра. Выполняют роль фильтра, чтобы предовратить добавление
     * распознанных ранее документов рамках активных задач распознавания.
     */
    initialDocuments: EnteraDocument[] = [];

    /**
     * Инициализированные документы загружены?
     */
    initialLoaded: boolean;
}

/**
 * Начальное состояние задач на распознавание.
 */
const initialState: RecognitionTasksState = new RecognitionTasksState();

/**
 * Обработка состояния задач на распознавание в ответ на события.
 * 
 * @param state Текущее состояние.
 * @param action Событие.
 */
export function recognitionTasksReducer(
    state = initialState,
    action: RecognitionTasksAction
): RecognitionTasksState {

    let result = state;

    switch (action.type) {

        case RecognitionTasksActionType.LOAD: {

            result = {
                ...state,
                tasks: {},
                loading: true,
                loaded: false,
                failed: false
            };
            break;
        }

        case RecognitionTasksActionType.LOAD_SUCCESS: {

            let tasks = {
                 ...state.tasks
            };

            action.payload.forEach(task => tasks[task.id] = task);

            result = {
                ...state,
                tasks,
                loading: false,
                loaded: true,
                failed: false,
                recognizedDocuments: []
            };
            break;
        }

        case RecognitionTasksActionType.LOAD_FAIL: {

            result = {
                ...state,
                loading: false,
                loaded: false,
                failed: true
            };
            break;
        }

        case RecognitionTasksActionType.ADD_TASK: {

            const newTasks = {};
            action.payload.forEach(task => newTasks[task.id] = task);

            result = {
                ...state,
                tasks: {
                    ...newTasks,
                    ...state.tasks,
                }
            };
            break;
        }

        case RecognitionTasksActionType.CHANGE_TASK_STATUS: {

            result = {
                ...state,
                tasks: {
                    ...state.tasks,
                    [action.payload.id]: action.payload
                },
            };

            break;
        }

        case RecognitionTasksActionType.TASK_FINISHED: {

            let tasks = {
                ...state.tasks
            };
            delete tasks[action.payload.id];

            result = {
                ...state,
                tasks,
                lastRecognized: action.payload
            };
            break;
        }

        case RecognitionTasksActionType.TASK_ERROR: {

            let tasks = {
                ...state.tasks
            };
            delete tasks[action.payload.id];

            result = {
                ...state,
                tasks,
                lastError: action.payload
            };
            break;
        }

        case RecognitionTasksActionType.CLEAR: {

            result = initialState;
            break;
        }

        case RecognitionTasksActionType.ADD_RECOGNIZED_DOCUMENTS: {

            result = {
                ...state,
                recognizedDocuments : [
                    ... state.recognizedDocuments,
                    ... action.payload
                ]
            };

            break;
        }

        case RecognitionTasksActionType.LOAD_INITIAL_DOCUMENTS: {

            result = {
                ...state,
                loading: true,
                initialLoaded: false,
                recognizedDocuments: [],
                initialDocuments: []
            };
            break;
        }

        case RecognitionTasksActionType.LOAD_INITIAL_DOCUMENTS_SUCCESS: {

            result = {
                ...state,
                loading: false,
                initialLoaded: true,
                failed: false,
                initialDocuments: action.payload ? action.payload : []
            };
            break;
        }
    }

    return result;
}

/**
 * Функция получения списка задач распозновния из сотсотояния.
 * @param state состоние хранилища.
 */
export const getTasks = (state: RecognitionTasksState) => state ? state.tasks : [];


/**
 * Возвращает флаг успешного выполнения загрузки задач на распознавание из состояния задач на распознавание.
 * @param state Состояние хранилища.
 */
export const getTasksLoaded = (state: RecognitionTasksState) => state.loaded;
/**
 * Функция получения последей распознанной задачи из хранилища.
 * @param state состоние хранилища.
 */
export const getLastTask = (state: RecognitionTasksState) => state.lastRecognized;

/**
 * Функция получения последей неудачной задачи из хранилища.
 * @param state состоние хранилища.
 */
export const getLastError = (state: RecognitionTasksState) => state.lastError;

/**
 * Функция получения списка распознанных документов.
 * @param state состоние хранилища.
 */
export const getRecognizedDocuments = (state: RecognitionTasksState) => state.recognizedDocuments;

/**
 * Функция получения списка начальных распознанных документов.
 * @param state Состоние хранилища.
 */
export const getInitialDocuments = (state: RecognitionTasksState) => state.initialDocuments;
