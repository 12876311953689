import { Store } from "@ngrx/store";
import * as LogRocket from "logrocket";
import { UserService } from "src/app/common/services/user.service";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "../../common/services/user.service";
/**
 * Сервис для инициализации Logrocket'а.
 */
var LogrocketService = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор сервиса для инициализации Logrocket'а.
     *
     * @param store Сервис для управления и доступа к состоянию приложения.
     * @param userService Сервис с логикой для работы с пользователем.
     */
    function LogrocketService(store, userService) {
        this._store = store;
        this._userService = userService;
    }
    //endregion
    //region Public
    /**
     * Инициализирует Logrocket.
     *
     * В процессе инициализации передаёт App ID LogRocket'а и идентифицирует пользователя.
     */
    LogrocketService.prototype.init = function () {
        if (environment.logRocket && environment.logRocket.enabled) {
            LogRocket.init(environment.logRocket.appId);
        }
    };
    LogrocketService.ngInjectableDef = i0.defineInjectable({ factory: function LogrocketService_Factory() { return new LogrocketService(i0.inject(i1.Store), i0.inject(i2.UserService)); }, token: LogrocketService, providedIn: "root" });
    return LogrocketService;
}());
export { LogrocketService };
