<div class="purchase-dlg">
    <h2 class="mat-h2 purchase-dlg__header">{{ "dialogs.purchase.header" | translate }}</h2>
    <div class="phone-confirm-dlg__info" *ngIf="!(responseError$ | async) && !(success$ | async)">
        {{ "dialogs.purchase.initial.message" | translate }}
    </div>
    <div class="phone-confirm-dlg__info" *ngIf="!(responseError$ | async) && success$ | async">
        {{ "dialogs.purchase.success.message" | translate }}
    </div>
    <div class="phone-confirm-dlg__info" *ngIf="(responseError$ | async) && !(success$ | async)">
        <span>{{ "common.response.error.message" | translate }}</span>
        <span>{{ (responseError$ | async)?.errorCode }}</span>
    </div>
    <mat-form-field class="purchase-dlg__input" *ngIf="!(responseError$ | async) && !(success$ | async)">
        <input
            [formControl]="phoneControl"
            type="tel"
            onlyDigits
            matInput
            placeholder="{{ 'common.phone-confirm-dlg.phone' | translate }}"
        >
        <mat-error *ngIf="phoneControl?.errors?.pattern">
            {{ 'common.phone-confirm-dlg.error.wrongNumberFormat' | translate }}
        </mat-error>
        <mat-spinner *ngIf="loading$ | async" matSuffix [diameter]="14"></mat-spinner>
    </mat-form-field>
    <div class="purchase-dlg__buttons">
        <button
            class="purchase-dlg__button"
            mat-raised-button
            mat-dialog-close
            [class.purchase-dlg__button_primary-color] = "success$ | async"
            (click)="onClose()"
        >
            <span *ngIf="!(responseError$ | async) && !(success$ | async)">{{ 'button.cancel' | translate }}</span>
            <span *ngIf="(responseError$ | async) || (success$ | async)">{{ 'button.close' | translate }}</span>
        </button>
        <button
            *ngIf="!(responseError$ | async) && !(success$ | async)"
            class="purchase-dlg__button"
            mat-raised-button
            color="primary"
            (click)="onConfirm()"
            [disabled]="!phoneControl.value || phoneControl.invalid || (loading$ | async)"
        >
            {{ 'dialogs.purchase.sendButton' | translate }}
        </button>
    </div>

</div>