import { Component } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { Inject } from "@angular/core";
import { Input } from "@angular/core";
import { Output } from "@angular/core";
import { OnInit } from '@angular/core';
import { OnDestroy } from '@angular/core';

import { MAT_DIALOG_DATA } from "@angular/material";
import { MatDialogRef } from "@angular/material";

import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';

import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { Space } from "src/app/common/models/index";
import { spaceSelector } from "src/app/root/store/index";

import { UploadToRecognizeState } from "src/app/root/store/index";
import { RootState } from 'src/app/root/store/index';
import { currentUserPermissionsSelector } from 'src/app/root/store/index';

import { UtilsService } from "src/app/common/services/index";

/**
 * Входные данные в диалог.
 */
export interface DialogData {

    uploadState: UploadToRecognizeState;
}

/**
 * Компонент диалога для загрузки файлов на распознавание.
 */
@Component({
    styleUrls: ['upload-bank-statement-dialog.component.scss'],
    templateUrl: 'upload-bank-statement-dialog.component.html'
})
export class UploadBankStatementDialogComponent implements OnInit, OnDestroy {
    //region Inputs

    /**
     *  Входящие данные - состояние данных для создания задач на распознавание.
     */
    @Input()
    set uploadState(value: UploadToRecognizeState) {

        this._uploadState = value;
    }

    //endregion
    //region Outputs

    /**
     * Исходящее событие - создание задач на распознавание.
     */
    @Output()
    upload = new EventEmitter<UploadToRecognizeState>();

    /**
     * Исходящее событие - добвление файла на распознавание.
     */
    @Output()
    add = new EventEmitter<File[]>();

    /**
     * Исходящее событие - удаление файла из задачи.
     */
    @Output()
    remove = new EventEmitter<File>();

    //endregion
    //region Public fields

    /**
     * Права текущего пользователя досутпные ему независимо от пространства документов.
     */
    permissions$: Observable<{ [key: string]: boolean }>;

    /**
     * Текущая папка.
     */
    currentSpace$: Observable<Space>;

    //endregion
    //region Private fields

    /**
     * Состояние приложения.
     */
    private _store: Store<RootState>;

    /**
     * Состояние данных для создания задач на распознавание.
     *
     * @private
     */
    private _uploadState: UploadToRecognizeState;

    /**
     * Переключатель, чтобы при выполнении onDestroy произошли отписки от всех подписок.
     *
     * @private
     */
    private _destroyed: Subject<void> = new Subject();

    //endregion
    //region Ctor

    constructor(
        store: Store<RootState>,
        public dialogRef: MatDialogRef<UploadBankStatementDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public utils: UtilsService
    ) {
        this._store = store;
    }

    //endregion
    //region Hooks

    ngOnInit(): void {

        // Следим за правами пользователя.
        this.permissions$ = this._store.pipe(
            select(currentUserPermissionsSelector)
        );
        this.currentSpace$ = this._store.pipe(
            select(spaceSelector),
        )
    }

    ngOnDestroy(): void {

        this._destroyed.next();
        this._destroyed.complete();
    }

    //endregion
    //region Getters and Setters

    /**
     * Состояние данных для создания задач на распознавание.
     */
    get uploadState(): UploadToRecognizeState {

        return this._uploadState;
    }

    //endregion
    //region Events

    /**
     * Обработчик добавления файлов. Фильтрует файлы которые уже есть в состоянии.
     *
     * @param event Файлы, которые нужно добавить.
     */
    addFilesHandler(event: File[]) {

        let newFiles = event.filter(fileToAdd => this._uploadState.filesWithMetaData.every(file =>
            file.file.name !== fileToAdd.name
            || file.file.size !== fileToAdd.size
            || file.file.lastModified !== fileToAdd.lastModified
        ));

        if (newFiles.length) {

            this.add.emit(newFiles);
        }
    }

    /**
     * Закрытие дилога.
     */
    closeButtonClickHandler(): void {

        if (!this.uploadState.loading) {

            this.dialogRef.close();
        }
    }

    /**
     * Обрабатывает событие нажатия кнопки отправки на сервер.
     */
    sendButtonClickHandler() {

        this.upload.emit({ ...this.uploadState, bankStatement: true });
    }

    //endregion
}
