import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { InfoMenuItem } from "src/app/common/models/info-menu-item";

import { CurrentUserInfo } from '../../models/current-user-info';
import { UserService } from '../../services/user.service';

/**
 * ID элементов меню.
 */
const MenuItemId = {

    /**
     * Личный кабинет.
     */
    USER_ACCOUNT: 'user-account',

    /**
     * Выход.
     */
    LOGOUT: 'logout',
};

/**
 * Компонент выпадающего меню текущего пользователя.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'user-info-menu',
    styleUrls: ['./user-info-menu.component.scss'],
    templateUrl: './user-info-menu.component.html'
})
export class UserInfoMenuComponent {
    //region Fields

    /**
     * Входящие данные - данные текущего пользователя.
     */
    @Input()
    user: CurrentUserInfo;

    /**
     * Исходящее событие - требование перейти в личный кабинет пользователя.
     */
    @Output()
    userAccount = new EventEmitter<void>();

    /**
     * Исходящее событие - требование выйти из системы.
     */
    @Output()
    logout = new EventEmitter<void>();

    /**
     * Элементы меню выпадашки.
     */
    menuItems: InfoMenuItem[];

    /**
     * Флаг, что на компонент навели мышку.
     */
    hovered: boolean;

    //endregion
    //region Ctor

    constructor(
        private userService: UserService
    ) {

        // Конфигурация элементов меню: личный кабинет и выход.
        this.menuItems = [
            // TODO Вернуть, когда придёт время.
            // {
            //     id: MenuItemId.USER_ACCOUNT,
            //     icon: 'account_circle',
            //     messageKey: 'user.account.cabinet'
            // },
            {
                id: MenuItemId.LOGOUT,
                icon: 'exit_to_app',
                messageKey: 'logout'
            }
        ];
    }

    //endregion
    //region Getters and Setters

    /**
     * Возвращает имя текущего пользователя.
     */
    get userName() {

        return this.userService.getUserName(this.user);
    }

    //endregion
    //region Events

    /**
     * Обработчик клика по элементу меню.
     */
    menuItemClickHandler(menuItemId: string) {

        // Бросаем нужное событие выше в зависимости от выбранного элемента меню.
        switch (menuItemId) {

            case MenuItemId.LOGOUT: {
                this.logout.emit();
            }

            case MenuItemId.USER_ACCOUNT: {
                this.userAccount.emit();
            }
        }
    }

    //endregion
}
