import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

/**
 * Компонент индикатора загрузки.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'entera-progress-bar',
    template: '',
    styleUrls: ['./entera-progress-bar.component.scss'],
})
export class EnteraProgressBarComponent implements OnInit {
    //region Ctor

    constructor() { }

    //endregion
    //region Hooks

    ngOnInit() { }

    //endregion
}
