var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DocumentTypesService } from "src/app/spaces/modules/documents-registry/services/document-types.service";
import { documentTypesActions } from "src/app/root/store/actions/document-types.action";
import { DocumentTypesActionType } from "src/app/root/store/actions/document-types.action";
/**
 * Side-эффекты на события, связанные с типами документов.
 */
var DocumentTypesEffects = /** @class */ (function () {
    //region Ctor
    function DocumentTypesEffects(actions$, documentTypesService) {
        var _this = this;
        this.actions$ = actions$;
        this.documentTypesService = documentTypesService;
        //endregion
        //region Public
        /**
         * Выполнение загрузки типов документов.
         */
        this.loadDocumentTypes$ = this.actions$
            .pipe(ofType(DocumentTypesActionType.LOAD), switchMap(function () { return _this.documentTypesService.get()
            .pipe(map(function (documentTypes) {
            return documentTypesActions.loadSuccess({ value: documentTypes });
        }), catchError(function (apiResponse) { return of(documentTypesActions.loadFail({ error: apiResponse })); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DocumentTypesEffects.prototype, "loadDocumentTypes$", void 0);
    return DocumentTypesEffects;
}());
export { DocumentTypesEffects };
