import { createSelector } from "@ngrx/store";
import { MoveDocumentsDlgState } from "src/app/common/store/states/move-documents-dlg.state";
import { commonModuleStateSelector } from "../reducers";
import { CommonModuleState } from "../reducers";

/**
 * Селектор состояния диалога перемещения документов.
 */
export const moveDocumentsDlgStateSelector = createSelector(
    commonModuleStateSelector,
    (state: CommonModuleState) => state.moveDocumentsDlgState,
);

/**
 * Селектор состояния успешного перемещения документов.
 */
export const documentsMoveToAnotherSpaceSelector = createSelector(
    moveDocumentsDlgStateSelector,
    (state: MoveDocumentsDlgState) => state.movingDocumentsToAnotherSpace,
);

/**
 * Селектор состояния перемещения документов в данный момент.
 */
export const documentsMoveToAnotherSpaceSuccessSelector = createSelector(
    moveDocumentsDlgStateSelector,
    (state: MoveDocumentsDlgState) => state.movingDocumentsToAnotherSpaceSuccess,
);

/**
 * Селектор состояния ошибки перемещения документов.
 */
export const documentsMoveToAnotherSpaceFailedSelector = createSelector(
    moveDocumentsDlgStateSelector,
    (state: MoveDocumentsDlgState) => state.movingDocumentsToAnotherSpaceError,
);

/**
 * Все селекторы, связанные с диалоговым окном перемещения документов.
 */
export const moveDocumentsDlgSelectors = {
    moveDocumentsDlgStateSelector,
    documentsMoveToAnotherSpaceSelector,
    documentsMoveToAnotherSpaceSuccessSelector,
    documentsMoveToAnotherSpaceFailedSelector,
};
