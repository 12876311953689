import { Store } from "@ngrx/store";
import { select } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { filter } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { LogrocketService } from "src/app/root/services/logrocket.service";
import { environment } from "src/environments/environment";
import { LangService } from "../common/services";
import { appInitializerFactory } from "../yandex-metrika";
import { countersFactory } from "../yandex-metrika";
import { defaultCounterIdFactory } from "../yandex-metrika";
import { langs } from "./i18n";
import { LangChangeAction } from "./store";
import { currentUserLangSelector } from "./store";
import { CurrentUserLoadAction } from "./store";
var ɵ0 = environment.yandexCounters["main"].id, ɵ1 = Object.keys(environment.yandexCounters).map(function (key) { return environment.yandexCounters[key]; }), ɵ2 = defaultCounterIdFactory, ɵ3 = countersFactory, ɵ4 = appInitializerFactory;
var RootModule = /** @class */ (function () {
    //region Ctor
    function RootModule(store, translateService, langService, logrocketService) {
        var _this = this;
        this.store = store;
        this.translateService = translateService;
        this.langService = langService;
        this.logrocketService = logrocketService;
        // На старте модуля загружаем его i18n-сообщения.
        this.langService.loadTranslations(this.translateService, langs);
        // Язык по умолчанию.
        // TODO Нужно в будущем ещё обращать внимание на куку ENTERA_LANG.
        this.store.dispatch(new LangChangeAction("ru"));
        // Вызываем загрузку пользователя.
        this.store.dispatch(new CurrentUserLoadAction());
        // Подписываемся на изменение языка пользователя, чтобы сигнилизировать его изменение.
        this.store
            .pipe(select(currentUserLangSelector), filter(function (lang) { return !!lang; }), tap(function (lang) { return _this.store.dispatch(new LangChangeAction(lang)); }))
            .subscribe();
        var gtagEnabled = environment.gtag && environment.gtag.enabled;
        var windowAsAny = window;
        windowAsAny.dataLayer = windowAsAny.dataLayer || [];
        windowAsAny.gtag = function () {
            if (gtagEnabled) {
                windowAsAny.dataLayer.push(arguments);
            }
        };
        if (gtagEnabled) {
            var gtagScript = document.createElement("script");
            gtagScript.src = "https://www.googletagmanager.com/gtag/js?id=" + environment.gtag.stream;
            gtagScript.async = true;
            document.body.appendChild(gtagScript);
            windowAsAny.gtag("js", new Date());
            windowAsAny.gtag("config", environment.gtag.stream, { send_page_view: false, debug_mode: gtagEnabled && environment.gtag.debug });
        }
        this.logrocketService.init();
    }
    return RootModule;
}());
export { RootModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
