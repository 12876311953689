import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

/**
 * Комонент страницы 403 о нехватке прав.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'forbidden-page',
    templateUrl: './forbidden-page.component.html',
    styleUrls: ['./forbidden-page.component.scss'],
})
export class ForbiddenPageComponent implements OnInit {
    //region Fields

    /**
     * URL страницы, для доступа к которой не хватает прав.
     * Извлекается из GET-параметра текущего URL.
     */
    path: string = '';

    //endregion
    //region Ctor

    constructor(
        private _activatedRoute: ActivatedRoute
    ) { 
        this.path = this._activatedRoute.snapshot.queryParams.path;
    }

    //endregion
    //region Public

    ngOnInit() { }

    //endregion
}
