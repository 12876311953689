/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mobile-main.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../components/mobile-space-select/mobile-space-select.component.ngfactory";
import * as i9 from "../../components/mobile-space-select/mobile-space-select.component";
import * as i10 from "../../../common/services/utils.service";
import * as i11 from "@angular/common";
import * as i12 from "./mobile-main.component";
import * as i13 from "@ngrx/store";
var styles_MobileMainComponent = [i0.styles];
var RenderType_MobileMainComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MobileMainComponent, data: {} });
export { RenderType_MobileMainComponent as RenderType_MobileMainComponent };
function View_MobileMainComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "mobile-main__button"], ["color", "primary"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onScanQrClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵted(2, 0, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.noSelectedSpace; var currVal_3 = "primary"; _ck(_v, 1, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("mobile.scanQr")); _ck(_v, 2, 0, currVal_4); }); }
export function View_MobileMainComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "mobile-main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mobile-space-select", [["class", "mobile-main__space-selector"]], null, [[null, "select"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("select" === en)) {
        var pd_0 = (_co.spaceSelectHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MobileSpaceSelectComponent_0, i8.RenderType_MobileSpaceSelectComponent)), i1.ɵdid(2, 180224, null, 0, i9.MobileSpaceSelectComponent, [i7.TranslateService, i10.UtilsService], { spaces: [0, "spaces"] }, { select: "select" }), i1.ɵpid(131072, i11.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "mobile-main__buttons"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_MobileMainComponent_1)), i1.ɵdid(6, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i11.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["class", "mobile-main__button"], ["color", "primary"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onUploadDocumentsMobile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵted(10, 0, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.spaces$)); _ck(_v, 2, 0, currVal_0); var currVal_1 = !i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.userInfo$)).client.foreign; _ck(_v, 6, 0, currVal_1); var currVal_4 = _co.noSelectedSpace; var currVal_5 = "primary"; _ck(_v, 9, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 9).disabled || null); var currVal_3 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_6 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("mobile.uploadDocuments")); _ck(_v, 10, 0, currVal_6); }); }
export function View_MobileMainComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mobile-main", [], null, null, null, View_MobileMainComponent_0, RenderType_MobileMainComponent)), i1.ɵdid(1, 114688, null, 0, i12.MobileMainComponent, [i13.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MobileMainComponentNgFactory = i1.ɵccf("mobile-main", i12.MobileMainComponent, View_MobileMainComponent_Host_0, {}, {}, []);
export { MobileMainComponentNgFactory as MobileMainComponentNgFactory };
