/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./broadcast-message-viewer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./broadcast-message-viewer.component";
import * as i3 from "../../../common/services/utils.service";
var styles_BroadcastMessageViewerComponent = [i0.styles];
var RenderType_BroadcastMessageViewerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BroadcastMessageViewerComponent, data: {} });
export { RenderType_BroadcastMessageViewerComponent as RenderType_BroadcastMessageViewerComponent };
export function View_BroadcastMessageViewerComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "broadcast-messages"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "broadcast-messages__message-content broadcast-message-viewer-content"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.broadcastMessage.content; _ck(_v, 1, 0, currVal_0); }); }
export function View_BroadcastMessageViewerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "broadcast-message-viewer", [], null, null, null, View_BroadcastMessageViewerComponent_0, RenderType_BroadcastMessageViewerComponent)), i1.ɵdid(1, 49152, null, 0, i2.BroadcastMessageViewerComponent, [i3.UtilsService], null, null)], null, null); }
var BroadcastMessageViewerComponentNgFactory = i1.ɵccf("broadcast-message-viewer", i2.BroadcastMessageViewerComponent, View_BroadcastMessageViewerComponent_Host_0, { broadcastMessage: "broadcastMessage", lang: "lang" }, {}, []);
export { BroadcastMessageViewerComponentNgFactory as BroadcastMessageViewerComponentNgFactory };
