<div [formGroup]="formGroup" class="company-creator">
    <h1 *ngIf="showHeader" class="mat-h1 company-creator__header">
        {{'common.companyCreator.header' | translate}}
    </h1>
    <mat-form-field class="company-creator__field">
        <input
            matInput
            formControlName="name"
            required
            [placeholder]="'common.companyCreator.name'| translate"
        />
    </mat-form-field>
    <br/>
    <mat-form-field class="company-creator__field">
        <input
            matInput
            formControlName="shortName"
            [placeholder]="'common.companyCreator.shortName'| translate"
        />
    </mat-form-field>
    <br/>
    <mat-form-field class="company-creator__field">
        <input
            matInput
            formControlName="inn"
            [placeholder]="'common.companyCreator.inn'| translate"
        />
    </mat-form-field>
    <br/>
    <mat-form-field class="company-creator__field">
        <input
            matInput
            formControlName="kpp"
            [placeholder]="'common.companyCreator.kpp'| translate"
        />
    </mat-form-field>
    <p class="company-creator__error-message">
        <span *ngIf="errorData">{{getErrorMessage()}}</span>
        <span *ngIf="!errorData">&nbsp;</span>
    </p>
    <div class="company-creator__button-wrapper">
        <button
            class="company-creator__create-button"
            mat-flat-button
            (click)="createHandler()"
            [disabled]="formGroup.invalid || loading"
        >
            {{'button.create' | translate}}
        </button>
    </div>
    <mat-spinner
        *ngIf="loading"
        class="company-creator__button-spinner"
        diameter="16"
    ></mat-spinner>
</div>