<div class="spaces-info-menu" title="{{spaceName}}">
    <info-menu
        class="spaces-info-menu_menu"
        icon="account_balance_outline"
        iconFontSet="material-icons-custom-set"
        hoveredIcon="account_balance"
        [iconDisabled]="iconDisabled"
        [large]="large"
        [label]="spaceName"
        labelKey="header.spaces.select.space"
        [menuItems]="menuItems"
        (menuItemClick)="menuItemClickHandler($event)"
        (rightItemMenuItemClick)="menuItemRightIconClickHandler($event)"
    >
    </info-menu>
</div>
