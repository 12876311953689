import { RecognitionTaskSourcesAction } from "src/app/spaces/modules/documents-registry/store/actions/recognition-task-sources.action";
import { RecognitionTaskSourcesActionType } from "src/app/spaces/modules/documents-registry/store/actions/recognition-task-sources.action";
import { RecognitionTaskSourcesState } from "src/app/spaces/modules/documents-registry/store/states/recognition-task-sources.state";

/**
 * Начальное состояние источников задач на распознавание.
 */
const initialState: RecognitionTaskSourcesState = {
    loading: false,
    loaded: false,
    failed: false,
    recognitionTaskSources: [],
};

/**
 * Обработчик событий, связанных с источниками задач на распознавание.
 * 
 * @param state Состояние источников задач на распознавание.
 * @param action Событие произошедшее в системе.
 */
export function recognitionTaskSourcesReducer(
    state = initialState,
    action: RecognitionTaskSourcesAction,
): RecognitionTaskSourcesState {
    switch (action.type) {

        // Требование загрузить источники задач на распознавание.
        case RecognitionTaskSourcesActionType.LOAD: {

            return {
                ...state,
                loading: true,
                loaded: false,
                failed: false,
            };
        }

        // Уведомление об успешной загрузке источников задач на распознавание.
        case RecognitionTaskSourcesActionType.LOAD_SUCCESS: {

            return {
                ...state,
                loading: false,
                loaded: true,
                recognitionTaskSources: action.sources,
            };
        }

        // Увдомление о неудачной попытке загрузки источников задач на распознавание.
        case RecognitionTaskSourcesActionType.LOAD_FAIL: {

            return {
                ...state,
                loading: false,
                failed: true,
                recognitionTaskSources: [],
            };
        }
    }

    return state;
}

/**
 * Возвращает источники задач на распознавание из состояния источников задач на распознавание.
 *
 * @param state Состояние источников задач на распознавание.
 */
export const getRecognitionTaskSources = (state: RecognitionTaskSourcesState) => state.recognitionTaskSources;

/**
 * Возвращает флаг успешно выполненной загрузки источников задач на распознавание из состояния источников задач на
 * распознавание.
 *
 * @param state Состояние источников задач на распознавание.
 */
export const getRecognitionTaskSourcesLoaded = (state: RecognitionTaskSourcesState) => state.loaded;
