import { Action } from "@ngrx/store";

/**
 * Типы событий, связанные языком интерфейса.
 */
export enum LangActionType {
    CHANGE = '[Root] Lang changed',
}

/**
 * Событие, требующее смены языка интерфейса.
 */
export class LangChangeAction implements Action {
    readonly type = LangActionType.CHANGE;
    constructor (public payload: string) {}
}

/**
 * Тип, объединяющий все события, связанные с языком интерфейса.
 */
export type LangAction =
    | LangChangeAction;