var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DocumentStatesActionType } from "../actions";
/**
 * Начальное состояние статусов документов.
 */
var initialState = {
    /**
     * Статусы документов не загружаются.
     */
    loading: false,
    /**
     * Статусы документов не загружены.
     */
    loaded: false,
    /**
     * Загрузка статусов документов ошибкой не завершалась.
     */
    failed: false,
    /**
     * Статусов документов нет.
     */
    documentStates: undefined
};
/**
 * Обработчик событий, связанных со статусами документов.
 *
 * @param state Состояние статусов документов.
 * @param action Событие произошедшее в системе.
 */
export function documentStatesReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        // Требование загрузить статусы документов.
        case DocumentStatesActionType.LOAD: {
            return __assign({}, state, { loading: true, loaded: false, failed: false });
        }
        // Уведомление об успешной загрузке статусов документов.
        case DocumentStatesActionType.LOAD_SUCCESS: {
            var documentStates = action.payload;
            return __assign({}, state, { loading: false, loaded: true, documentStates: documentStates });
        }
        // Увдомление о неудачной попытке загрузки статусов документов.
        case DocumentStatesActionType.LOAD_FAIL: {
            return __assign({}, state, { loading: false, failed: true, documentStates: undefined });
        }
    }
    return state;
}
/**
 * Возвращает статусы документов из состояния статусов документов.
 *
 * @param state Состояние статусов документов.
 */
export var getDocumentStates = function (state) { return state.documentStates; };
/**
 * Возвращает флаг выполняющейся загрузки статусов документов из состояния статусов документов.
 *
 * @param state Состояние статусов документов.
 */
export var getDocumentStatesLoading = function (state) { return state.loading; };
/**
 * Возвращает флаг успешно выполненной загрузки статусов документов из состояния статусов документов.
 *
 * @param state Состояние статусов документов.
 */
export var getDocumentStatesLoaded = function (state) { return state.loaded; };
/**
 * Возвращает флаг неудачной попытки загрузки статусов документов из состояния статусов документов.
 *
 * @param state Состояние статусов документов.
 */
export var getDocumentStatesFailed = function (state) { return state.failed; };
