import { createSelector } from "@ngrx/store";
import { DocumentsRegistryState, documentsRegistryStateSelector } from "src/app/spaces/modules/documents-registry/store/reducers/index";
import { SendToExternalSystemState } from "src/app/spaces/modules/documents-registry/store/states/send-to-external-system.state";

/**
 * Селектор отправки документов во внешнюю систему.
 */
export const sendToExternalSystemStateSelector = createSelector(
    documentsRegistryStateSelector,
    (state: DocumentsRegistryState): SendToExternalSystemState => state.sendToExternalSystemState,
);
