import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { ApiResponse } from "src/app/common/models/api-response";
import { RecognitionTaskSourcesActionProps } from "src/app/spaces/modules/documents-registry/store/actions/props/recognition-task-sources.action-props";

/**
 * Типы событий, связанные с источниками задач на распознавание.
 */
export enum RecognitionTaskSourcesActionType {
    LOAD = "[Documents registry] Load recognition task sources",
    LOAD_SUCCESS = "[Documents registry] Load recognition task sources Success",
    LOAD_FAIL = "[Documents registry] Load recognition task sources Fail",
}

/**
 * Событие, требующее загрузки источников задач на распознавание.
 */
const recognitionTaskSourcesLoad = createAction(RecognitionTaskSourcesActionType.LOAD);

/**
 * Событие успешной загрузки источников задач на распознавание.
 */
const recognitionTaskSourcesLoadSuccess = createAction(
    RecognitionTaskSourcesActionType.LOAD_SUCCESS,
    props<RecognitionTaskSourcesActionProps>(),
);

/**
 * Событие неудачной загрузки источников задач на распознавание.
 */
const recognitionTaskSourcesLoadFail = createAction(
    RecognitionTaskSourcesActionType.LOAD_FAIL,
    props<ApiResponse>(),
);

/**
 * Тип, объединяющий все события, связанные с источниками задач на распознавание.
 */
export type RecognitionTaskSourcesAction =
    | ReturnType<typeof recognitionTaskSourcesLoad>
    | ReturnType<typeof recognitionTaskSourcesLoadSuccess>
    | ReturnType<typeof recognitionTaskSourcesLoadFail>;

/**
 * Все события, связанные с страницей управления клиентом.
 */
export const recognitionTaskSourcesActions = {
    recognitionTaskSourcesLoad: recognitionTaskSourcesLoad,
    recognitionTaskSourcesLoadSuccess: recognitionTaskSourcesLoadSuccess,
    recognitionTaskSourcesLoadFail: recognitionTaskSourcesLoadFail,
};
