import { requestState } from "src/app/common/models/request-state";
import { IntermediateActionType } from "src/app/spaces/modules/document-matching/store/actions/intermediate.actions";
import { IntermediateAction } from "src/app/spaces/modules/document-matching/store/actions/intermediate.actions";
import { IntermediateState } from "src/app/spaces/modules/document-matching/store/states/intermediate.state";

/**
 * Начальное состояние обработки промежуточного состояния.
 */
const initialState: IntermediateState = {

    /**
     * Состояние промежуточного сохранения документов.
     */
    savingIntermediateState: requestState.initial(),
    /**
     * Состояние сброса (удаления) сопоставлений.
     */
    discardingDocumentMatches: requestState.initial(),
};

/**
 * Обработчик событий, связанных с изменением состояния обработки промежуточного состояния.
 *
 * @param state Текущее состояние обработки промежуточного состояния.
 * @param action Событие.
 *
 * @return Новое состояние.
 */
export function intermediateReducer(
    state = initialState,
    action: IntermediateAction,
): IntermediateState {
    let result = state;

    switch (action.type) {

        case IntermediateActionType.SAVE_INTERMEDIATE: {

            result = {
                ...state,
                savingIntermediateState: requestState.pending(),
            };
            break;
        }

        case IntermediateActionType.SAVE_INTERMEDIATE_SUCCESS: {

            result = {
                ...state,
                savingIntermediateState: requestState.success(),
            };
            break;
        }

        case IntermediateActionType.SAVE_INTERMEDIATE_FAIL: {

            result = {
                ...state,
                savingIntermediateState: requestState.fail(action.error),
            };
            break;
        }

        case IntermediateActionType.DELETE_MATCHES_IF_SAFE: {

            result = {
                ...state,
                discardingDocumentMatches: requestState.pending(),
            };
            break;
        }

        case IntermediateActionType.DELETE_MATCHES_IF_SAFE_SUCCESS: {

            result = {
                ...state,
                discardingDocumentMatches: requestState.success(),
            };
            break;
        }

        case IntermediateActionType.DELETE_MATCHES_IF_SAFE_FAIL: {

            result = {
                ...state,
                discardingDocumentMatches: requestState.fail(action.error),
            };
            break;
        }

        case IntermediateActionType.SAVE_AND_EXIT_MATCHES_IF_SAFE: {

            result = {
                ...state,
                discardingDocumentMatches: requestState.pending(),
            };
            break;
        }

        case IntermediateActionType.SAVE_AND_EXIT_IF_SAFE_SUCCESS: {

            result = {
                ...state,
                discardingDocumentMatches: requestState.success(),
            };
            break;
        }

        case IntermediateActionType.SAVE_AND_EXIT_IF_SAFE_FAIL: {

            result = {
                ...state,
                discardingDocumentMatches: requestState.fail(action.error),
            };
            break;
        }
    }

    return result;
}
