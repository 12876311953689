import { RecognitionTaskCreatedDlgAction } from "../actions/recognition-task-created-dlg.action";
import { RecognitionTaskCreatedDlgActionType } from "../actions/recognition-task-created-dlg.action";
import { RecognitionTaskCreatedState } from "../states/recognition-task-created.state";

/**
 * Начальное состояние применения изменений после диалога загрузки задачи на распознавание.
 */
export const initialState: RecognitionTaskCreatedState = {

    // Загрузка не происходит.
    loading: false,

    // Ошибки при загрузке нет.
    error: null,
};

/**
 * Обработка состояния применения изменений после диалога загрузки задачи на распознавание в ответ на событие.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 */
export function recognitionTaskCreatedReducer(
    state = initialState,
    action: RecognitionTaskCreatedDlgAction,
): RecognitionTaskCreatedState {

    switch (action.type) {

        // На событие с требованием открыть диалог.
        case RecognitionTaskCreatedDlgActionType.OPEN_DIALOG: {

            return initialState;
        }
    }

    return state;
}
