import { Moment } from "moment";
import * as moment from "moment";
import { Option } from "src/app/common/components/single-select/single-select.component";
import { IdEntity } from "src/app/common/models";
import { EnteraDocumentType } from "src/app/common/models";
import { EnteraDocumentState } from "src/app/common/models";
import { Company } from "src/app/common/models";
import { User } from "src/app/common/models";
import { RecognitionTaskSource } from "src/app/common/models/recognition-task-source";

import { RouteState } from "src/app/root/store/reducers/router.reducer";
import { DocumentRegistryFilterFields } from "src/app/spaces/modules/documents-registry/models/document-registry-filter-fields.model";

import * as _ from "underscore";
import { VatRate } from "src/app/spaces/modules/document/models/vat-rate";

/**
 * Состояние фильтров в реестре документов.
 */
export class DocumentRegistryFiltersState implements DocumentRegistryFilterFields {
    //Private constants

    /**
     * Формат даты для передачи состояния фильтра на сервер.
     */
    private static readonly _DATE_FORMAT: string = 'YYYY-MM-DD';

    //endregion
    //region Public fields

    /**
     * Типы документов.
     */
    documentType: EnteraDocumentType | string[] = null;

    /**
     * ID покупателей по документам.
     */
    customer: Company[] | string[] = null;

    /**
     * ID поставщиков по документам.
     */
    supplier: Company[] | string[] = null;

    /**
     * ID создателей документов.
     */
    creator: User | string[] = null;

    /**
     * Дата создания документа от.
     */
    createdDateFrom: Moment | string = null;

    /**
     * Дата создания документа до.
     */
    createdDateTo: Moment | string = null;

    /**
     * Дата документа от.
     */
    documentDateFrom: Moment | string = null;

    /**
     * Дата документа до.
     */
    documentDateTo: Moment | string = null;

    /**
     * Состояния документов.
     */
    documentState: EnteraDocumentState | string[] = null;

    /**
     * Фильтр выгрузки документа.
     */
    uploaded: Option | string = null;

    /**
     * Фильтр выгрузки документа для иностранцев.
     */
    uploadedState: Option | string = null;

    /**
     * Фильтр иностранных документов.
     */
    foreign: Option  | string = null;

    /**
     * Источник задачи на распознавание.
     */
    source: RecognitionTaskSource | string[] = null;

    /**
     * Фильтр по налоговой ставке.
     */
    taxRate: VatRate | string = null;

    /**
     * Значения фильтра по умолчанию.
     */
    defaults: { [key:string]: any } = null;

    /**
     * Подсчет документов вызван принудительно по кнопке для админа?
     */
    isForcedCounting: boolean = false;

    /**
     * Идентификатор папки.
     */
    spaceId?: string;

    /**
     * Идентификаторы документов, которые нужно исключить из фильтра.
     */
    excludedDocumentIds?: string[];

    //endregion
    //region Ctor

    constructor() { }

    //endregion
    //region Public

    /**
     * Возвращает состояние фильтров для передачи их на сервер.
     *
     * @return Состояние фильтров для передачи их на сервер.
     */
    getStateForRequest(): DocumentRegistryFiltersState {

        const state = new DocumentRegistryFiltersState();

        if (this.documentType) {

            state.documentType = [ (this.documentType as EnteraDocumentType).id ];
        }

        if (this.documentState) {

            state.documentState = [ (this.documentState as EnteraDocumentState).id ];
        }

        if (this.customer) {

            state.customer = (this.customer as Company[]).map((cus: Company): string => cus.id);
        }

        if (this.supplier) {

            state.supplier = (this.supplier as Company[]).map((sup: Company): string => sup.id);
        }

        if (this.creator) {

            state.creator = [ (this.creator as User).id ];
        }

        if (this.createdDateFrom) {

            // К значению фильтра "дата загрузки от" добавляем время начала дня, чтобы оно покрывало целый день
            // выбранной даты, а также добавляем часовой пояс.
            const date: string =(this.createdDateFrom as Moment).format(DocumentRegistryFiltersState._DATE_FORMAT);
            state.createdDateFrom = date + 'T00:00:00.000' + DocumentRegistryFiltersState._getOffsetFromUtc();
        }

        if (this.createdDateTo) {

            // К значению фильтра "дата загрузки до" добавляем время конца дня, чтобы оно покрывало целый день
            // выбранной даты, а также добавляем часовой пояс.
            const date: string =(this.createdDateTo as Moment).format(DocumentRegistryFiltersState._DATE_FORMAT);
            state.createdDateTo = date + 'T23:59:59.999' + DocumentRegistryFiltersState._getOffsetFromUtc();
        }

        if (this.documentDateFrom) {

            state.documentDateFrom = (this.documentDateFrom as Moment)
                .format(DocumentRegistryFiltersState._DATE_FORMAT);
        }

        if (this.documentDateTo) {

            state.documentDateTo = (this.documentDateTo as Moment)
                .format(DocumentRegistryFiltersState._DATE_FORMAT);
        }

        if (this.uploaded !== null) {

            state.uploaded = (this.uploaded as Option).id;
        }

        if (this.uploadedState !== null) {

            state.uploadedState = (this.uploadedState as Option).id;
        }

        if (this.foreign !== null) {

            state.foreign = (this.foreign as Option).id;
        }

        if (this.isForcedCounting !== null) {

            state.isForcedCounting = this.isForcedCounting;
        }

        if (this.source) {

            state.source = [ (this.source as RecognitionTaskSource).id ];
        }

        if (this.taxRate) {

            if (typeof this.taxRate === "string") {

                state.taxRate = this.taxRate;
            }
            else {

                state.taxRate = this.taxRate.value;
            }
        }

        // Убираем null-значения.
        Object.keys(state).forEach(key => {

            if (state[key] === null) {

                delete state[key];
            }
        });

        return state;
    }

    /**
     * Состояние фильтров пустое?
     *
     * @return Да/Нет.
     */
    isEmpty(): boolean {

        return Object
            .keys(this)
            .filter(key => key !== 'defaults')
            .every(key => !this[key] || (this.defaults && this.defaults[key] === this[key]));
    }

    /**
     * Проверяет, соответствует ли документ фильтру.
     *
     * @param document Документ для проверки.
     *
     * @return Возвращает true, если документ соответствует фильтру, иначе false.
     */
    matchFilterState(document: any): boolean {

        if (!DocumentRegistryFiltersState.checkIfStringMatchFilter(document.type, this.documentType)) {

            return false;
        }

        if (this.customer && this.customer.length) {

            let included;
            if (typeof this.customer[0] === "string") {

                included = (this.customer as string[]).some(cust =>
                    cust === document.customer || document.customer && cust === document.customer.name
                );
            }
            else {

                included = (this.customer as Company[]).some(cust =>
                    cust === document.customer || document.customer && cust && cust.id === document.customer.id
                );
            }
            if (!included) {

                return false;
            }
        }

        if (this.supplier && this.supplier.length) {

            let included;
            if (typeof this.supplier[0] === "string") {

                included = (this.supplier as string[]).some(cust =>
                    cust === document.supplier
                    || document.supplier && cust === document.supplier.name
                );
            }
            else {

                included = (this.supplier as Company[]).some(cust =>
                    cust === document.supplier
                    || document.supplier && cust && cust.id === document.supplier.id
                );
            }
            if (!included) {

                return false;
            }
        }

        if (!DocumentRegistryFiltersState.checkIfStringMatchFilter(document.foreign.toString(), this.foreign)) {

            return false;
        }

        if (!DocumentRegistryFiltersState.checkIfStringMatchFilter(document.creator.id, this.creator)) {

            return false;
        }

        if (!DocumentRegistryFiltersState.checkIfStringMatchFilter(document.state, this.documentState)) {

            return false;
        }

        if (!DocumentRegistryFiltersState.checkIfStringMatchFilter(document.uploaded.toString(), this.uploaded)) {

            return false;
        }

        if (!DocumentRegistryFiltersState.checkIfStringMatchFilter(document.recognitionTask.source, this.source)) {

            return false;
        }

        if (this.taxRate != null) {

            if (typeof this.taxRate === "string") {

                if (!(document.taxRates || []).includes(this.taxRate)) {

                    return false;
                }
            }
            else if (!(document.taxRates || []).includes(this.taxRate.value)) {

                return false;
            }
        }

        let createdDateFromMatch = DocumentRegistryFiltersState.checkIfMomentMatchFilter(
            document.createdDate,
            this.createdDateFrom,
            (docCreatedDate, filterCreatedDate) => docCreatedDate.isSameOrAfter(filterCreatedDate),
        );
        if (!createdDateFromMatch) {

            return false;
        }

        let createdDateToMatch = DocumentRegistryFiltersState.checkIfMomentMatchFilter(
            document.createdDate,
            this.createdDateTo,
            (docCreatedDate, filterCreatedDate) => docCreatedDate.isSameOrBefore(filterCreatedDate),
        );
        if (!createdDateToMatch) {

            return false;
        }

        let docDateFromMatch = DocumentRegistryFiltersState.checkIfMomentMatchFilter(
            document.createdDate,
            this.documentDateFrom,
            (docDate, filterDateFrom) => docDate.isSameOrAfter(filterDateFrom),
        );
        if (!docDateFromMatch) {

            return false;
        }

        let docDateToMatch = DocumentRegistryFiltersState.checkIfMomentMatchFilter(
            document.createdDate,
            this.createdDateTo,
            (docDate, filterDateFrom) => docDate.isSameOrBefore(filterDateFrom),
        );

        if (!docDateToMatch) {

            return false;
        }

        return true;
    }

    /**
     * Возращает кол-во не пустых фильтров.
     *
     * @return Кол-во фильтров.
     */
    getSize() {

        return Object
            .keys(this)
            .filter(key => !(key === "defaults" || (!this[key] || (this.defaults && this.defaults[key] === this[key]))))
            .length;
    }

    /**
     * Возвращает состояние фильтров пригодное для установки в форму.
     *
     * @return Состояние фильтров пригодное для установки в форму.
     */
    getFormValue(): DocumentRegistryFiltersState {

        let formValue = Object.assign({}, this);
        delete formValue.defaults;

        return formValue;
    }

    //endregion
    //region Public static

    /**
     * На основе данных состояния URL создаёт экземпляр класса DocumentRegistryFiltersState.
     *
     * @param routeState Состояние URL.
     *
     * @return Состояние фильтров.
     */
    static fromUrl(routeState: RouteState): DocumentRegistryFiltersState {

        const state = DocumentRegistryFiltersState.fromForm(routeState.queryParams);

        // TODO Хардкод части URL'а.
        if (routeState.path.endsWith('completed')) {

            state.documentState = {
                id: 'RECOGNIZED'
            } as EnteraDocumentState;
            state.defaults = {};
            state.defaults.documentState = state.documentState;
        }

        return state;
    }

    /**
     * На основе данных с формы фильтров создаёт экземпляр класса DocumentRegistryFiltersState.
     *
     * @param formState Данные с формы фильтров.
     *
     * @return Состояние фильтров.
     */
    static fromForm(formState: any): DocumentRegistryFiltersState {

        const state = new DocumentRegistryFiltersState();

        DocumentRegistryFiltersState._fromForArray(formState, state, "source");
        DocumentRegistryFiltersState._fromForBoolean(formState, state, "foreign");
        DocumentRegistryFiltersState._fromForArray(formState, state, "uploadedState");
        DocumentRegistryFiltersState._fromForBoolean(formState, state, "uploaded");
        DocumentRegistryFiltersState._fromForArray(formState, state, "documentType");
        DocumentRegistryFiltersState._fromForArray(formState, state, "documentState");
        DocumentRegistryFiltersState._fromForArrayAsArray(formState, state, "customer");
        DocumentRegistryFiltersState._fromForArrayAsArray(formState, state, "supplier");
        DocumentRegistryFiltersState._fromForArray(formState, state, "creator");
        DocumentRegistryFiltersState._fromForDate(formState, state, "createdDateFrom");
        DocumentRegistryFiltersState._fromForDate(formState, state, "createdDateTo");
        DocumentRegistryFiltersState._fromForDate(formState, state, "documentDateFrom");
        DocumentRegistryFiltersState._fromForDate(formState, state, "documentDateTo");
        DocumentRegistryFiltersState._fromForDate(formState, state, "documentDateTo");

        state.taxRate = DocumentRegistryFiltersState._extractVatRate(formState);

        if (formState["isForcedCounting"] != null) {

            state.isForcedCounting = formState["isForcedCounting"];
        }

        return state;
    }

    /**
     * Состояния фильтров совпадают?
     *
     * @param state Состояние фильтров.
     * @param anotherState Другое состояние фильтров.
     *
     * @return Да/Нет.
     */
    static isSameStates(state: DocumentRegistryFiltersState, anotherState: DocumentRegistryFiltersState): boolean {

        return (
            state
            && anotherState
            && DocumentRegistryFiltersState._isObjectsEquals(state.documentType, anotherState.documentType)
            && DocumentRegistryFiltersState._isObjectsEquals(state.documentState, anotherState.documentState)
            && DocumentRegistryFiltersState._isArraysEquals(state.customer, anotherState.customer)
            && DocumentRegistryFiltersState._isArraysEquals(state.supplier, anotherState.supplier)
            && DocumentRegistryFiltersState._isObjectsEquals(state.customer, anotherState.customer)
            && DocumentRegistryFiltersState._isObjectsEquals(state.creator, anotherState.creator)
            && DocumentRegistryFiltersState._isObjectsEquals(state.source, anotherState.source)
            && state.foreign === anotherState.foreign
            && state.uploaded === anotherState.uploaded
            && state.uploadedState === anotherState.uploadedState
            && DocumentRegistryFiltersState._isDatesEquals(
                state.createdDateFrom as Moment,
                anotherState.createdDateFrom as Moment
            )
            && DocumentRegistryFiltersState._isDatesEquals(
                state.createdDateTo as Moment,
                anotherState.createdDateTo as Moment
            )
            && DocumentRegistryFiltersState._isDatesEquals(
                state.documentDateFrom as Moment,
                anotherState.documentDateFrom as Moment
            )
            && DocumentRegistryFiltersState._isDatesEquals(
                state.documentDateTo as Moment,
                anotherState.documentDateTo as Moment
            )
            && state.taxRate === anotherState.taxRate
        );
    }

    //endregion
    //region Private static

    /**
     * Два заданных значения равны?
     *
     * @param a Первое значение.
     * @param b Второе значение.
     *
     * @return Да/Нет.
     *
     * @private
     */
    private static _isObjectsEquals(a: any, b: any): boolean {

        if (!a && !b) {

            return true;
        }

        if (a && !b || !a && b) {

            return false;
        }

        return (a.id === b.id);
    }

    /**
     * Два заданных массива равны?
     *
     * @param a Первый массив.
     * @param b Второй массив.
     *
     * @return Да/Нет.
     *
     * @private
     */
    private static _isArraysEquals(a: any[], b: any[]): boolean {

        if (!a && !b) {

            return true;
        }

        if (a && !b || !a && b) {

            return false;
        }

        return _.isEqual(
            _.sortBy(a, _.identity),
            _.sortBy(b, _.identity)
        );
    }

    /**
     * Две заданные даты равны?
     *
     * @param a Первая дата.
     * @param b Вторая дата.
     *
     * @return Да/Нет.
     *
     * @private
     */
    private static _isDatesEquals(a: Moment, b: Moment): boolean {

        if (!a && !b) {

            return true;
        }

        if (a && !b || !a && b) {

            return false;
        }

        return moment(a.format(DocumentRegistryFiltersState._DATE_FORMAT))
            .isSame(moment(b.format(DocumentRegistryFiltersState._DATE_FORMAT)));
    }

    /**
     * Берёт true/false данные от объекта и кладёт их в состояние фильтров;
     * somestate[field] может содержать нужное состояние фильтра, а может содержать
     * объект {id : value}, где id и является нужным состоянием true/false. Поэтому
     * в методе есть проверка, которая смотрит что именно в него приходит.
     *
     * @param someState Объект, содержащий состояние фильтров.
     * @param state Состояние фильтров.
     * @param field Поле состояния фильтров.
     *
     * @private
     */
    private static _fromForBoolean(someState: any, state: DocumentRegistryFiltersState, field: string) {

        if (!!someState[field]) {

            if (typeof someState[field] === "string") {

                state[field] = { id: someState[field].toLowerCase() };
            }
            else {

                state[field] = { ...someState[field] };
            }
        }
    }

    /**
     * Берёт данные от объекта и кладёт их в состояние фильтров.
     *
     * Если данные - это массив - кладет объект с id со значением первого элемента массива.
     *
     * @param someState Объект, содержащий состояние фильтров.
     * @param state Состояние фильтров.
     * @param field Поле состояния фильтров.
     *
     * @private
     */
    private static _fromForArray(someState: any, state: DocumentRegistryFiltersState, field: string) {

        if (someState[field]) {

            if (typeof someState[field] === "string") {

                state[field] = { id: someState[field] };
            }
            else if (someState[field] instanceof Array) {

                state[field] = { id: someState[field][0] };
            }
            else {

                state[field] = { ...someState[field] };
            }
        }
    }

    /**
     * Берёт данные от объекта и кладёт их в состояние фильтров.
     *
     * Если данные - это массив - создает массив объектов с id равными элементам массива и кладет его в состояние
     * фильтров.
     *
     * @param someState Объект, содержащий состояние фильтров.
     * @param state Состояние фильтров.
     * @param field Поле состояния фильтров.
     */
    private static _fromForArrayAsArray(someState: Object, state: DocumentRegistryFiltersState, field: string): void {

        if (someState[field]) {

            if (typeof someState[field] === "string") {

                state[field] = [{ id: someState[field] }];
            }
            else if (someState[field] instanceof Array) {

                let options: any[] = [];

                someState[field].forEach((opt: any) => {

                    if (typeof opt === "string") {

                        options = [ ...options, { id: opt } ];
                    }
                    else {

                        options = someState[field];
                    }
                });

                state[field] = options;
            }
            else {

                state[field] = { ...someState[field] };
            }
        }
    }

    /**
     * Берёт данные даты от объекта и кладёт их в состояние фильтров.
     *
     * @param someState Объект, содержащий состояние фильтров.
     * @param state Состояние фильтров.
     * @param field Поле состояния фильтров.
     *
     * @private
     */
    private static _fromForDate(someState: any, state: DocumentRegistryFiltersState, field: string): void {

        if (someState[field]) {

            if (typeof someState[field] === "string") {

                state[field] = moment(someState[field], DocumentRegistryFiltersState._DATE_FORMAT);
            }
            else if (typeof someState[field].calendar === "function") {

                state[field] = moment(someState[field]);
            }

            if (state[field] && !state[field].isValid()) {

                state[field] = null;
            }
        }
    }

    /**
     * Извлекает налоговую ставку из состояния фильтров.
     *
     * @param state Состояние фильтров.
     *
     * @return Налоговая ставка.
     */
    private static _extractVatRate(state: any) {

        if (state.taxRate) {

            if (typeof state.taxRate === "string") {

                return VatRate.get(state.taxRate);
            }
            else {

                return state.taxRate;
            }
        }
        return null;
    }

    /**
     * Возвращает разницу между временем клиента и UTC в часах.
     *
     * Например, для московского времени результатом будет строка '+03'.
     *
     * @return Разница между временем клиента и UTC в часах.
     *
     * @private
     */
    public static _getOffsetFromUtc(): string {

        let offset: string = '';

        const minutes: number = moment().utcOffset();
        if (minutes >= 0) {

            offset = '+';
        }
        else {

            offset = '-';
        }

        const hours: number = Math.round(Math.abs(minutes) / 60);
        if (hours < 10) {

            offset += '0';
        }
        offset += hours;

        return offset;
    }

    /**
     * Проверяет, входит ли значение в перечень допустимых по значению фильтра.
     *
     * @param value Значение.
     * @param filterValue Значение фильтра.
     */
    private static checkIfStringMatchFilter(value: string, filterValue: IdEntity | string | string[]): boolean {

        if (filterValue != null) {

            if (Array.isArray(filterValue)) {

                if (!filterValue.includes(value)) {

                    return false;
                }
            }
            else if (typeof filterValue === "string") {

                if (filterValue !== value) {

                    return false;
                }
            }
            else if ((filterValue as IdEntity).id !== value) {

                return false;
            }
        }

        return true;
    }

    /**
     * Проверяет, входит ли значение момента в перечень допустимых по значению фильтра.
     *
     * @param value Значение.
     * @param filterValue Значение фильтра.
     * @param matchFn Функция для сравнения моментов.
     *
     * @return Да/нет.
     */
    private static checkIfMomentMatchFilter(
        value: string,
        filterValue: Moment | string | string[],
        matchFn: (moment1: Moment, moment2: Moment) => boolean,
    ): boolean {

        if (filterValue != null) {

            let momentValue = moment(value);
            if (typeof filterValue === "string") {

                if (!matchFn(momentValue, moment(filterValue))) {

                    return false;
                }
            }
            else {

                if (!matchFn.apply(momentValue, filterValue as Moment)) {

                    return false;
                }
            }
        }

        return true;
    }

    //endregion
}
