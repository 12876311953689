/**
 * Точка с координатами (x, y).
 */
export class Point {
    //region Private fields

    /**
     * Координата X.
     *
     * @private
     */
    private readonly _x: number = 0;

    /**
     * Координата Y.
     *
     * @private
     */
    private readonly _y: number = 0;

    //endregion
    //region Ctor

    constructor(x: number = 0, y: number = 0) {
        this._x = x;
        this._y = y;
    }

    //endregion
    //region Getters and Setters

    /**
     * Координата X.
     */
    get x(): number {

        return this._x;
    }

    /**
     * Координата Y.
     */
    get y(): number {

        return this._y;
    }

    //endregion
    //region Public

    /**
     * Возвращает новую точку с координатами, полученными на основе текущей точки с заданным сдвигом по X.
     *
     * @param deltaX Сдвиг по координате X.
     */
    shiftX(deltaX: number): Point {

        return new Point(this.x + deltaX, this.y);
    }

    /**
     * Возвращает новую точку с координатами, полученными на основе текущей точки с заданным сдвигом по Y.
     *
     * @param deltaY Сдвиг по координате Y.
     */
    shiftY(deltaY: number): Point {

        return new Point(this.x, this.y + deltaY);
    }

    /**
     * Текущая точка совпадает с заданной точкой по координатам?
     *
     * @param point Точка.
     *
     * @return Да/Нет.
     */
    isSame(point: Point): boolean {

        return (this.x === point.x && this.y === point.y);
    }

    //endregion
}