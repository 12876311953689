import { DocumentRegistrySortsState } from "./documents-registry-sort-state.model";

/**
 * Возможные значения ключей сортировок в URL.
 */
const ORDER_BY_KEYS = [
    'customer',
    'supplier',
    'creator',
    'documentState',
    'documentType',
    'documentNumber',
    'createdDate',
    'documentDate',
]

/**
 * Состояние сортировки в колонках таблицы реестра документов.
 * 
 * Флаги сортировки имеют следующее значение:
 * true - ASC, false - DESC, иначе нет сортировки.
 * 
 * Числа, соответствующие флагам, определяют порядок сортировки по колонкам.
 */
export class DocumentRegistryColumnsSortState {
    //region Ctor

    constructor(
        /**
         * Сортировка в колонке статус документа.
         */
        public documentState: boolean = null,

        /**
         * Порядок сортировки в колонке статус документа.
         */
        public documentStateSortNumber: number = null,

        /**
         * Сортировка в колонке покупателя/плательщика.
         */
        public customer: boolean = null,

        /**
         * Порядок сортировки в колонке покупателя/плательщика.
         */
        public customerSortNumber: number = null,
        
        /**
         * Сортировка в колонке продавца/поставщика.
         */
        public supplier: boolean = null,

        /**
         * Порядок сортировки в колонке продавца/поставщика.
         */
        public supplierSortNumber: number = null,
        
        /**
         * Сортировка в колонке типа документа.
         */
        public documentType: boolean = null,

        /**
         * Порядок сортировки в колонке типа документа.
         */
        public documentTypeSortNumber: number = null,

        /**
         * Сортировка в колонке номера документа.
         */
        public documentNumber: boolean = null,

        /**
         * Порядок сортировки в колонке номера документа.
         */
        public documentNumberSortNumber: number = null,
        
        /**
         * Сортировка в колонке создателя документа.
         */
        public creator: boolean = null,

        /**
         * Порядок сортировки в колонке создателя документа.
         */
        public creatorSortNumber: number = null,

        /**
         * Сортировка в колонке даты загрузки документа.
         */
        public createdDate: boolean = null,

        /**
         * Порядок сортировки в колонке даты загрузки документа.
         */
        public createdDateSortNumber: number = null,

        /**
         * Сортировка в колонке даты документа.
         */
        public documentDate: boolean = null,

        /**
         * Порядок сортировки в колонке даты документа.
         */
        public documentDateSortNumber: number = null,
    ) { }

    //endregion
    //region Public

    /**
     * Переключение/включение сортировки по столбцу.
     * 
     * @param column Столбец таблицы.
     * @param multi Множественная сортировка?
     */
    toggle(column: string, multi: boolean) {

        const prevValue = this[column];
        if (!multi) {
            
            this.clear();
        }

        if (typeof prevValue === "boolean") {

            this[column] = !prevValue;

            if (!multi) {

                this[column + 'SortNumber'] = 0;
            }
        }
        else {

            this[column] = (column !== 'createdDate' && column !== 'documentDate');

            if (multi) {

                this[column + 'SortNumber'] = this.getNextSortNumber();
            }
            else {

                this[column + 'SortNumber'] = 0;
            }
        }
    }

    /**
     * Сброс состояния сортировки.
     */
    clear() {

        Object.keys(this).forEach(key => this[key] = null);
    }

    /**
     * Сброс состояния сортировки до состояния по умолчанию.
     */
    default(): DocumentRegistryColumnsSortState {

        Object.keys(this).forEach(key => this[key] = null);
        this.createdDate = false;
        this.createdDateSortNumber = 0;
        return this;
    }

    /**
     * Состояние сортировки находится в состоянии по умолчанию?
     */
    isDefault(): boolean {

        let result = (this.createdDate === false && this.createdDateSortNumber === 0);

        result = result && Object.keys(this)
            .filter(key => key !== 'createdDate' && key !== 'createdDateSortNumber')
            .every(key => this[key] === null);
        
        return result;
    }

    /**
     * Возвращает следующий порядковый номер для сортировки.
     */
    getNextSortNumber(): number {

        return Object.keys(this)
            .filter(key => typeof this[key] === "number")
            .map(key => this[key])
            .reduce((prev, cur) => Math.max(prev, cur), -1) + 1;
    }

    /**
     * Сортировок в состоянии нет?
     */
    isEmpty(): boolean {

        return Object.keys(this).every(key => !this.hasSort(key));
    }

    /**
     * Есть ли сортировка в заданном столбце?
     * 
     * @param column Столбец таблицы.
     */
    hasSort(column: string) {

        return (typeof this[column] === "boolean");
    }

    /**
     * Сортировка в заданном столбце по возрастанию?
     * 
     * @param column Столбец таблицы.
     */
    isAsc(column: string) {

        return (this.hasSort(column) && this[column]);
    }

    /**
     * Сортировка в заданном столбце по убыванию?
     * 
     * @param column Столбец таблицы.
     */
    isDesc(column: string) {

        return (this.hasSort(column) && !this[column]);
    }

    /**
     * Возвращает состояние сортировки для HTTP-запроса данных с сервера.
     */
    getStateForRequest(): DocumentRegistrySortsState {

        const state = {};

        const sorts = Object.keys(this)
            .filter(key => typeof this[key] === "boolean")
            .filter(key => typeof this[key + 'SortNumber'] === "number")
            .map(key => {

                return {
                    column: key,
                    index: this[key + 'SortNumber'],
                    desc: this.isDesc(key)
                }
             })
            .sort((s1, s2) => s1.index - s2.index);

        const sortKeys = [ 'orderBy#', 'thenBy#', 'thenBy#_' ];
        let index = 0;
        sorts.forEach(sort => {

            let sortKey = sortKeys[Math.min(index, 2)]
                .replace('#', (sort.desc ? 'Desc' : ''))
                .replace('_', index + '');
            state[sortKey] = sort.column;

            index += 1;
        });

        // Убираем null-значения.
        Object.keys(state).forEach(key => {

            if (state[key] === null) {

                delete state[key];
            }
        });

        return state as DocumentRegistrySortsState;
    }

    //endregion
    //region Static

    /**
     * На основе данных о состоянии сортировки из GET-параметров URL создаёт экземпляр класса 
     * DocumentRegistryColumnsSortState.
     * 
     * @param query Данные состояния сортировки из GET-параметров URL.
     */
    static fromQuery(query: any): DocumentRegistryColumnsSortState {

        const sortState = new DocumentRegistryColumnsSortState();

        let next: boolean = DocumentRegistryColumnsSortState.fromForKey(query, sortState, 'orderBy', 0);
        next = next && DocumentRegistryColumnsSortState.fromForKey(query, sortState, 'thenBy', 1);
        next = next && DocumentRegistryColumnsSortState.fromForKey(query, sortState, 'thenBy', 2);
        next = next && DocumentRegistryColumnsSortState.fromForKey(query, sortState, 'thenBy', 3);
        next = next && DocumentRegistryColumnsSortState.fromForKey(query, sortState, 'thenBy', 4);
        next = next && DocumentRegistryColumnsSortState.fromForKey(query, sortState, 'thenBy', 5);
        next = next && DocumentRegistryColumnsSortState.fromForKey(query, sortState, 'thenBy', 6);
        next = next && DocumentRegistryColumnsSortState.fromForKey(query, sortState, 'thenBy', 7);
        next = next && DocumentRegistryColumnsSortState.fromForKey(query, sortState, 'thenBy', 8);
        next = next && DocumentRegistryColumnsSortState.fromForKey(query, sortState, 'thenBy', 9);
        next = next && DocumentRegistryColumnsSortState.fromForKey(query, sortState, 'thenBy', 10);

        if (sortState.isEmpty()) {

            sortState.default();
        }

        return sortState;
    }

    /**
     * Делает копию заданного состояния.
     * 
     * @param state Состояние, копию которого нужно сделать.
     */
    static copy(state: DocumentRegistryColumnsSortState): DocumentRegistryColumnsSortState {

        const copy = new DocumentRegistryColumnsSortState();
        Object.keys(state).forEach(key => copy[key] = state[key]);

        return copy;
    }

    /**
     * Состояния сортировки столбцов совпадают?
     * 
     * @param state Состояние сортировки столбцов.
     * @param state Другое состояние сортировки столбцов.
     */
    static isSameStates(state: DocumentRegistryColumnsSortState, anotherState: DocumentRegistryColumnsSortState) {
        
        return (
            state
            && anotherState
            && state.documentState === anotherState.documentState
            && state.documentStateSortNumber === anotherState.documentStateSortNumber
            && state.customer === anotherState.customer
            && state.customerSortNumber === anotherState.customerSortNumber
            && state.supplier === anotherState.supplier
            && state.supplierSortNumber === anotherState.supplierSortNumber
            && state.documentType === anotherState.documentType
            && state.documentTypeSortNumber === anotherState.documentTypeSortNumber
            && state.documentNumber === anotherState.documentNumber
            && state.documentNumberSortNumber === anotherState.documentNumberSortNumber
            && state.creator === anotherState.creator
            && state.creatorSortNumber === anotherState.creatorSortNumber
            && state.createdDate === anotherState.createdDate
            && state.createdDateSortNumber === anotherState.createdDateSortNumber
            && state.documentDate === anotherState.documentDate
            && state.documentDateSortNumber === anotherState.documentDateSortNumber
        );
    }

    /**
     * Выполняет попытку извлечения данных о сортировке из GET-параметров URL в состояние сортировки.
     * 
     * @param query Данные состояния сортировки из GET-параметров URL.
     * @param state Состояние сортировки, которое строится на основе данных из URL.
     * @param key Ключ порядка сортировки (orderBy или thenBy).
     * @param sortNumber Порядковый номер сортировки.
     */
    private static fromForKey(
        query: any, 
        state: DocumentRegistryColumnsSortState, 
        key: string, 
        sortNumber: number
    ): boolean {

        let sortNumberStr: string = (sortNumber > 1 ? sortNumber + '' : '');
        const orderByKey = query[`${key}${sortNumberStr}`] || query[`${key}Desc${sortNumberStr}`];

        if (ORDER_BY_KEYS.includes(orderByKey)) {

            state[orderByKey] = !!query[`${key}${sortNumberStr}`];
            state[`${orderByKey}SortNumber`] = sortNumber;
        }

        return (typeof state[orderByKey] === 'boolean');
    }

    //endregion
}