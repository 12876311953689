import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ApiResponse } from '../../models/api-response';
import { ServerSideErrorDetailsComponent } from '../server-side-error-details/server-side-error-details.component';

/**
 * Компонент диалога с деталями произошедших на сервере ошибок.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'server-side-error-details-dlg',
    template: '',
    styleUrls: [],
})
export class ServerSideErrorDetailsDlgComponent implements OnInit {
    //region Inputs

    /**
     * Входящие данные - ошибки, произошедшие на сервере.
     */
    @Input()
    errors: ApiResponse[] = [];

    //endregion
    //region Ctor

    constructor(
        public dialog: MatDialog
    ) { }

    //endregion
    //region Hooks

    ngOnInit() {

        setTimeout(() => {

            this.dialog.open(ServerSideErrorDetailsComponent, {
                data: { errors: this.errors },
                panelClass: 'entera-error-details-panel'
            });
        })
    }

    //endregion
}
