import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { ErrorResponseProps } from "src/app/root/store/actions/props/error-response.action-props";
import { ValueProps } from "src/app/root/store/actions/props/value-props";
import { DocumentMatch } from "src/app/spaces/modules/document-matching/models/document-match";
import { DocumentMatchAmountModifier } from "src/app/spaces/modules/document-matching/models/document-match-amount-modifier";
import { MatchTableSelectedElement } from "src/app/spaces/modules/document-matching/models/match-table-selected-element";
import { DeleteMatchModifierProps } from "src/app/spaces/modules/document-matching/store/actions/props/delete-modifier.action.props";
import { MatchingCompaniesSuccessProps } from "src/app/spaces/modules/document-matching/store/actions/props/matching-companies-success.action.props";
import { MatchingDocumentProps } from "src/app/spaces/modules/document-matching/store/actions/props/matching-document.action.props";
import { MatchTableRow } from "src/app/spaces/modules/document-matching/data-sources/matching-row-types/matching-row-types";
import { BillClosedAmount } from "src/app/spaces/modules/document-matching/models/bill-closed-amount";
import { CreateOrEditLedgerProps } from "src/app/spaces/modules/document-matching/store/actions/props/create-or-edit-ledger.props";
import { CrossEntity } from "src/app/spaces/modules/document-matching/models/cross-entity-search-fn";

/**
 * Типы событий сопоставления.
 */
export enum MatchingActionType {

    START = "[Document matching] Start",
    SELECT_ELEMENT = "[Document matching] Select element",
    SELECT_ROW = "[Document matching] Select row",
    SWAP_COMPANIES = "[Document matching] Swap customer an supplier",
    SET_COMPANIES_AFTER_SWAP = "[Document matching] Set new possible companies after swapping customer and supplier",
    MARK_ELEMENT = "[Document matching] Mark element with checkbox and add to marked elements list",
    CHANGE_INVENTORY = "[Document matching] Change inventory in marked elements ",
    UNMARK_DOCUMENT_ELEMENT = "[Document matching] Unmark document element and remove from marked elements list",
    UNMARK_DOCUMENT_ITEM_ELEMENT = "[Document matching] Unmark item element and remove from marked elements list",
    MARK_ALL_ELEMENTS = "[Document matching] Mark all elements",
    UNMARK_ALL_ELEMENTS = "[Document matching] Unmark all elements",
    UPDATE_LEDGER_BILL_CLOSED_AMOUNT = "[Document matching] Updates selected bill's closed amount",
    OPEN_CREATE_LEDGER_DIALOG = "[Document matching] Open dialog for create ledger",
    CREATE_LEDGER = "[Document matching] Create new ledger",
    EDIT_LEDGER = "[Document matching] Edit new ledger",
    CANCEL_LEDGER_DIALOG = "[Document matching] Cancel ledger dialog",
    SET_MARKED_ROWS = "[Document matching] Set marked rows",
    CREATE_DOCUMENTS = "[Document matching] Create documents",
    CREATE_DOCUMENTS_SUCCESS = "[Document matching] Successfully created documents",
    CREATE_DOCUMENTS_FAIL = "[Document matching] Failed creating documents",
    ADD_MATCH_AMOUNT_MODIFIER = "[Document matching] Add amount modifier",
    DELETE_MATCH_AMOUNT_MODIFIER = "[Document matching] Delete amount modifier",
    ASK_CANCEL = "[Document matching] Ask user about cancelling",
    OPEN_SCAN_VIEWER = "[Document matching] Open scan viewer",
    CLOSE_SCAN_VIEWER = "[Document matching] Close scan viewer",
    DELETE_MATCH = "[Document matching] Delete match",
    DELETE_MATCHES = "[Document matching] Delete matches",
    CANCEL = "[Document matching] Cancel",
}

/**
 * Событие начала сопоставления.
 */
const start = createAction(
    MatchingActionType.START,
    props<MatchingDocumentProps>(),
);

/**
 * Событие выбора элемента в таблице сопоставления.
 */
const selectElement = createAction(
    MatchingActionType.SELECT_ELEMENT,
    props<ValueProps<MatchTableSelectedElement>>(),
);

/**
 * Событие выбора строки в таблице сопоставления.
 */
const selectRow = createAction(
    MatchingActionType.SELECT_ROW,
    props<ValueProps<MatchTableRow>>(),
);

/**
 * Событие перемены мест поставщика и покупателя.
 */
const swapCompanies = createAction(
    MatchingActionType.SWAP_COMPANIES,
    props<ValueProps<number>>(),
);

/**
 * Событие установки новых компаний после перемены мест поставщика и покупателя.
 */
const setCompaniesAfterSwap = createAction(
    MatchingActionType.SET_COMPANIES_AFTER_SWAP,
    props<MatchingCompaniesSuccessProps>(),
);

/**
 * Событие добавления элемента в список элементов с заполненным чекбоксом.
 */
const markElement = createAction(
    MatchingActionType.MARK_ELEMENT,
    props<ValueProps<MatchTableSelectedElement>>(),
);

/**
 * Событие изменения флага инвентаризации у элементов в списке с заполненным чекбоксом.
 */
const changeInventory = createAction(
    MatchingActionType.CHANGE_INVENTORY,
    props<ValueProps<MatchTableSelectedElement>>(),
);

/**
 * Событие удаления элемента документа из списка элементов с заполненным чекбоксом.
 */
const unmarkDocumentElement = createAction(
    MatchingActionType.UNMARK_DOCUMENT_ELEMENT,
    props<ValueProps<MatchTableSelectedElement>>(),
);

/**
 * Событие удаления элемента позиций из списка элементов с заполненным чекбоксом.
 */
const unmarkDocumentItemElement = createAction(
    MatchingActionType.UNMARK_DOCUMENT_ITEM_ELEMENT,
    props<ValueProps<MatchTableSelectedElement>>(),
);

/**
 * Событие добавления всех элементов в список элементов с заполненным чекбоксом.
 */
const markAllElements = createAction(
    MatchingActionType.MARK_ALL_ELEMENTS,
    props<ValueProps<MatchTableSelectedElement[]>>(),
)

/**
 * Событие удаления всех элементов из списка элементов с заполненным чекбоксом.
 */
const unmarkAllElements = createAction(
    MatchingActionType.UNMARK_ALL_ELEMENTS,
)

/**
 * Событие установки списка элементов, отмеченных чекбоксом.
 */
const setMarkedRows = createAction(
    MatchingActionType.SET_MARKED_ROWS,
    props<ValueProps<MatchTableRow[]>>()
);

/**
 * Событие обновления закрытой суммы по счёту.
 */
const updateLedgerBillClosedAmount = createAction(
    MatchingActionType.UPDATE_LEDGER_BILL_CLOSED_AMOUNT,
    props<ValueProps<BillClosedAmount>>(),
);

/**
 * Событие открытия диалога на создания леджера
 */
const openCreateLedgerDialog = createAction(
    MatchingActionType.OPEN_CREATE_LEDGER_DIALOG,
    props<CreateOrEditLedgerProps>(),
);

/**
 * Событие создания нового леджера
 */
const createLedger = createAction(
    MatchingActionType.CREATE_LEDGER,
    props<ValueProps<CrossEntity>>(),
);

/**
 * Событие отмены создания нового леджера
 */
const cancelLedgerDialog = createAction(
    MatchingActionType.CANCEL_LEDGER_DIALOG
);

/**
 * Событие редактирования нового леджера
 */
const editLedger =  createAction(
    MatchingActionType.EDIT_LEDGER,
    props<ValueProps<CrossEntity>>(),
);

/**
 * Событие создания документов по результатам сопоставления.
 */
const createDocuments = createAction(
    MatchingActionType.CREATE_DOCUMENTS,
    props<MatchingDocumentProps>(),
);

/**
 * Событие успешного создания документов по результатам сопоставления.
 */
const createDocumentsSuccess = createAction(
    MatchingActionType.CREATE_DOCUMENTS_SUCCESS,
);

/**
 * Событие неудачного создания документов по результатам сопоставления.
 */
const createDocumentsFail = createAction(
    MatchingActionType.CREATE_DOCUMENTS_FAIL,
    props<ErrorResponseProps<void>>(),
);

/**
 * Событие добавления модификатора документу сопоставления.
 */
const addModifier = createAction(
    MatchingActionType.ADD_MATCH_AMOUNT_MODIFIER,
    props<DocumentMatchAmountModifier>(),
);

/**
 * Событие удаления модификатора у документа сопоставления.
 */
const deleteModifier = createAction(
    MatchingActionType.DELETE_MATCH_AMOUNT_MODIFIER,
    props<DeleteMatchModifierProps>(),
);

/**
 * Событие открытия просмотрщика сканов документов.
 */
const openScan = createAction(
    MatchingActionType.OPEN_SCAN_VIEWER,
    props<ValueProps<[Element, Element]>>(),
);

/**
 * Событие закрытия просмотрщика сканов документов.
 */
const closeScan = createAction(
    MatchingActionType.CLOSE_SCAN_VIEWER,
);

/**
 * Событие запроса пользователем отмены сопоставления документов.
 */
const askCancel = createAction(
    MatchingActionType.ASK_CANCEL,
    props<ValueProps<DocumentMatch[]>>(),
);

/**
 * Событие удаления сопоставления документа.
 */
const deleteMatch = createAction(
    MatchingActionType.DELETE_MATCH,
    props<DocumentMatch>(),
);

/**
 * Событие удаления документов из сопоставления.
 */
const deleteMatches = createAction(
    MatchingActionType.DELETE_MATCHES,
    props<ValueProps<DocumentMatch[]>>(),
);

/**
 * Событие отмены сопоставления документов.
 */
const cancel = createAction(
    MatchingActionType.CANCEL,
);

/**
 * Тип, объединяющий события, связанные с сопоставлением документов.
 */
export type MatchingAction =
    | ReturnType<typeof start>
    | ReturnType<typeof selectElement>
    | ReturnType<typeof selectRow>
    | ReturnType<typeof swapCompanies>
    | ReturnType<typeof setCompaniesAfterSwap>
    | ReturnType<typeof markElement>
    | ReturnType<typeof changeInventory>
    | ReturnType<typeof unmarkDocumentElement>
    | ReturnType<typeof unmarkDocumentItemElement>
    | ReturnType<typeof markAllElements>
    | ReturnType<typeof unmarkAllElements>
    | ReturnType<typeof setMarkedRows>
    | ReturnType<typeof updateLedgerBillClosedAmount>
    | ReturnType<typeof createDocuments>
    | ReturnType<typeof createDocumentsSuccess>
    | ReturnType<typeof createDocumentsFail>
    | ReturnType<typeof addModifier>
    | ReturnType<typeof deleteModifier>
    | ReturnType<typeof askCancel>
    | ReturnType<typeof openScan>
    | ReturnType<typeof closeScan>
    | ReturnType<typeof deleteMatch>
    | ReturnType<typeof deleteMatches>
    | ReturnType<typeof openCreateLedgerDialog>
    | ReturnType<typeof createLedger>
    | ReturnType<typeof editLedger>
    | ReturnType<typeof cancelLedgerDialog>
    | ReturnType<typeof cancel>;

/**
 * События, связанные с сопоставлением документов.
 */
export const matchingActions = {
    start,
    selectElement,
    selectRow,
    swapCompanies,
    setCompaniesAfterSwap,
    markElement,
    changeInventory,
    unmarkDocumentElement,
    unmarkDocumentItemElement,
    markAllElements,
    unmarkAllElements,
    setMarkedRows,
    updateLedgerBillClosedAmount,
    openCreateLedgerDialog,
    createLedger,
    editLedger,
    cancelLedgerDialog,
    createDocuments,
    createDocumentsSuccess,
    createDocumentsFail,
    addModifier,
    deleteModifier,
    askCancel,
    openScan,
    closeScan,
    deleteMatch,
    deleteMatches,
    cancel,
};
