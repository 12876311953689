import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { ErrorResponseProps } from "src/app/root/store/actions/props/error-response.action-props";
import { ValueProps } from "src/app/root/store/actions/props/value-props";
import { IntegrationProps } from "src/app/spaces/modules/documents-registry/store/actions/props/integration.props";
import { ExternalLedgerType } from "src/app/common/models/external-ledger-type";

/**
 * Типы событий, связанных с типами контрагентов.
 */
export enum ExternalLedgerTypesActionType {
    LOAD = "[External ledger types] Load",
    LOAD_SUCCESS = "[External ledger types] Successfully load",
    LOAD_FAIL = "[External ledger types] Error occurred while loading",
}

/**
 * Событие получения типов контрагентов из внешней системы с сервера.
 */
const load = createAction(ExternalLedgerTypesActionType.LOAD, props<IntegrationProps>());

/**
 * Событие успешного получения типов контрагентов из внешней системы с сервера.
 */
const loadSuccess = createAction(
    ExternalLedgerTypesActionType.LOAD_SUCCESS,
    props<ValueProps<ExternalLedgerType[]>>()
);

/**
 * Событие ошибки при получении типов контрагентов из внешней системы с сервера.
 */
const loadFail = createAction(ExternalLedgerTypesActionType.LOAD_FAIL, props<ErrorResponseProps<void>>());

/**
 * Тип, объединяющий все события, связанные со типами контрагентов из внешней системы.
 */
export type ExternalLedgerTypesAction =
    | ReturnType<typeof load>
    | ReturnType<typeof loadSuccess>
    | ReturnType<typeof loadFail>;

/**
 * Все события, связанные с типами контрагентов из внешней системы
 */
export const externalLedgerTypesAction = {
    load,
    loadSuccess,
    loadFail,
};
