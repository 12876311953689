/**
 * Глобальные константы.
 */
export class Constants {
    //region Constants

    /**
     * Имя css-класса для всех диалогов.
     */
    public static readonly DIALOG_PANE_CLASS: string = "dlg-pane";

    /**
     * Конец строки, если она была укорочена.
     */
    public static readonly ELLIPSIS: string = "...";

    /**
     * Ссылка на инструкцию по интеграции нашего сервиса c 1C.
     */
    public static readonly MODULE_1C_BLOG_POST_URL: string = "https://entera.pro/"
        + "zagruzka-dannih-v-1c-zapusk-modulya-vneshney-obrabotki/";

    /**
     * Регулярное выражение для валидации одного email'а в строке.
     */
    // tslint:disable-next-line:max-line-length - При переносе перестаёт выдавать правильный результат.
    public static readonly EMAIL_REGEXP: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    /**
     * Регулярное выражение для валидации множества email'ов в строке.
     *
     * Собирает значения в именованную группу "email".
     */
    // tslint:disable-next-line:max-line-length - При переносе перестаёт выдавать правильный результат.
    public static readonly MULTI_EMAIL_REGEXP: RegExp = /((([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))/;

    //endregion
}
