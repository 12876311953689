import { ExternalCompany } from "src/app/spaces/modules/document-matching/models/external-company";
import { ExternalItem } from "src/app/spaces/modules/document-matching/models/external-item";
import { NewExternalElementsActionType } from "src/app/spaces/modules/document-matching/store/actions/new-external-elements.actions";
import { NewExternalElementsAction } from "src/app/spaces/modules/document-matching/store/actions/new-external-elements.actions";
import { NewExternalElementsState } from "src/app/spaces/modules/document-matching/store/states/new-external-elements.state";

/**
 * Начальное состояние создаваемых элементов для внешней системы.
 */
const initialState: NewExternalElementsState = {

    /**
     * Состояние создаваемых позиций.
     */
    items: [],

    /**
     * Состояние создаваемых компаний-поставщиков.
     */
    suppliers: [],

    /**
     * Состояние создаваемых компаний-покупателей.
     */
    customers: [],

    /**
     * Информация об использовании создаваемых элементов в UI.
     *
     * В данной структуре ключ - временный ID элемента, значение - количество использований в данный момент на UI.
     * Если элемент не используется, мы удаляем его из соответствующего состояния.
     */
    elementsUsageInfo: {},
};

/**
 * Обработчик событий, связанных создаваемыми элементами во внешней системе.
 *
 * @param state состояние создаваемых элементов для внешней системы.
 * @param action Событие.
 *
 * @return Новое состояние.
 */
export function newExternalElementsReducer(
    state = initialState,
    action: NewExternalElementsAction,
): NewExternalElementsState {
    let result = state;

    switch (action.type) {

        case NewExternalElementsActionType.ADD_NEW_ITEM: {

            const updatedUsageInfo = {...state.elementsUsageInfo};
            const elementUsage: number = updatedUsageInfo[action.value.id] || 0;
            let updatedItems: ExternalItem[] = [...state.items];

            if (!elementUsage) {

                updatedItems = [action.value, ...state.items];
            }
            updatedUsageInfo[action.value.id] = elementUsage + 1;

            result = {
                ...state,
                items: updatedItems,
                elementsUsageInfo: updatedUsageInfo,
            };
            break;
        }

        case NewExternalElementsActionType.UPDATE_ITEM: {

            result = {
                ...state,
                items: [action.value, ...state.items.filter(item => item.id !== action.value.id)]
            };
            break;
        }

        case NewExternalElementsActionType.REMOVE_ITEM: {

            const updatedUsageInfo = {...state.elementsUsageInfo};
            let elementUsage: number = updatedUsageInfo[action.value.id] || 0;

            if (elementUsage) {

                elementUsage--;
            }

            updatedUsageInfo[action.value.id] = elementUsage;

            let items = state.items;

            if (!elementUsage) {

                delete updatedUsageInfo[action.value.id];
                items = items.filter(item => item.id !== action.value.id);
            }

            result = {
                ...state,
                items: [...items],
                elementsUsageInfo: {...updatedUsageInfo},
            };
            break;
        }

        case NewExternalElementsActionType.ADD_NEW_SUPPLIER: {

            const updatedUsageInfo = {...state.elementsUsageInfo};
            const elementUsage: number = updatedUsageInfo[action.value.id] || 0;
            let updatedSuppliers: ExternalCompany[] = [...state.suppliers];

            if (!elementUsage) {

                updatedSuppliers = [action.value, ...state.suppliers];
            }
            updatedUsageInfo[action.value.id] = elementUsage + 1;

            result = {
                ...state,
                suppliers: updatedSuppliers,
                elementsUsageInfo: updatedUsageInfo,
            };
            break;
        }

        case NewExternalElementsActionType.UPDATE_SUPPLIER: {

            result = {
                ...state,
                suppliers: [
                    action.value,
                    ...state.suppliers.filter(company => company.id !== action.value.id)
                ]
            };
            break;
        }

        case NewExternalElementsActionType.REMOVE_SUPPLIER: {

            const updatedUsageInfo = {...state.elementsUsageInfo};
            let elementUsage: number = updatedUsageInfo[action.value.id] || 0;

            if (elementUsage) {

                elementUsage--;
            }

            updatedUsageInfo[action.value.id] = elementUsage;

            let companies = state.suppliers;

            if (!elementUsage) {

                delete updatedUsageInfo[action.value.id];
                companies = companies.filter(item => item.id !== action.value.id);
            }

            result = {
                ...state,
                suppliers: [...companies],
                elementsUsageInfo: {...updatedUsageInfo},
            };
            break;
        }

        case NewExternalElementsActionType.ADD_NEW_CUSTOMER: {

            const updatedUsageInfo = {...state.elementsUsageInfo};
            const elementUsage: number = updatedUsageInfo[action.value.id] || 0;
            let updatedCustomers: ExternalCompany[] = [...state.customers];

            if (!elementUsage) {

                updatedCustomers = [action.value, ...state.customers];
            }
            updatedUsageInfo[action.value.id] = elementUsage + 1;

            result = {
                ...state,
                customers: updatedCustomers,
                elementsUsageInfo: updatedUsageInfo,
            };
            break;
        }

        case NewExternalElementsActionType.UPDATE_CUSTOMER: {

            result = {
                ...state,
                customers: [
                    action.value,
                    ...state.customers.filter(company => company.id !== action.value.id)
                ]
            };
            break;
        }

        case NewExternalElementsActionType.REMOVE_CUSTOMER: {

            const updatedUsageInfo = {...state.elementsUsageInfo};
            let elementUsage: number = updatedUsageInfo[action.value.id] || 0;

            if (elementUsage) {

                elementUsage--;
            }

            updatedUsageInfo[action.value.id] = elementUsage;

            let customers = state.customers;

            if (!elementUsage) {

                delete updatedUsageInfo[action.value.id];
                customers = customers.filter(item => item.id !== action.value.id);
            }

            result = {
                ...state,
                customers: [...customers],
                elementsUsageInfo: {...updatedUsageInfo},
            };
            break;
        }

        case NewExternalElementsActionType.CLEAR_ALL: {

            result = initialState;
            break;
        }
    }

    return result;
}
