import { SendToExternalSystemActionType } from "src/app/spaces/modules/documents-registry/store/actions/send-to-external-system.action";
import { SendToExternalSystemAction } from "src/app/spaces/modules/documents-registry/store/actions/send-to-external-system.action";
import { SendToExternalSystemState } from "src/app/spaces/modules/documents-registry/store/states/send-to-external-system.state";


/**
 *  Начальное состояние для отправки документов во внешнюю систему.
 */
export const initialState: SendToExternalSystemState = {

    /**
     * Документы отправляются во внешнюю систему.
     */
    sending: false,

    /**
     * Документы успешно отправились во внешнюю систему.
     */
    sent: false,

    /**
     * При отправке документов во внешнюю систему произошла ошибка.
     */
    error: null,
};

/**
 * Обработчик событий связанный с отправкой документов во внешнюю систему.
 *
 * @param state Состояние отправки документов.
 * @param action Событие произошедшее в системе.
 */
export function sendToExternalSystemReducer(state = initialState, action: SendToExternalSystemAction): SendToExternalSystemState {

    let result = state;

    switch (action.type) {

        // Требование отправить документы по интеграции.
        case SendToExternalSystemActionType.SEND_TO_EXTERNAL_SYSTEM: {

            result = {
                ...state,
                sending: true,
            };
            break;
        }

        // Уведомление об успешной отправки документов.
        case SendToExternalSystemActionType.SEND_TO_EXTERNAL_SYSTEM_SUCCESS: {

            result = {
                ...state,
                sent: true,
                sending: false,
            };
            break;
        }

        // Уведомление об ошибке при отправке документов.
        case SendToExternalSystemActionType.SEND_TO_EXTERNAL_SYSTEM_FAIL: {

            result = {
                ...state,
                sending: false,
                sent: false,
                error: action.error,
            };
            break;
        }

    }

    return result;
}
