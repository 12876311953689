import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { ExternalDocumentType } from "src/app/common/models/integration/external-type.model";
import { ErrorResponseProps } from "src/app/root/store/actions/props/error-response.action-props";
import { ValueProps } from "src/app/root/store/actions/props/value-props";
import { IntegrationProps } from "src/app/spaces/modules/documents-registry/store/actions/props/integration.props";

/**
 * Типы событий, связанных с типами внешних документов.
 */
export enum ExternalDocumentTypesActionType {
    LOAD = "[External types] Load",
    LOAD_SUCCESS = "[External types] Successfully load",
    LOAD_FAIL = "[External types] Error occurred while loading",
}

/**
 * Событие получения типов документов из внешней системы с сервера.
 */
const load = createAction(ExternalDocumentTypesActionType.LOAD, props<IntegrationProps>());

/**
 * Событие успешного получения типов документов из внешней системы с сервера.
 */
const loadSuccess = createAction(
    ExternalDocumentTypesActionType.LOAD_SUCCESS,
    props<ValueProps<ExternalDocumentType[]>>()
);

/**
 * Событие ошибки при получении типов документов из внешней системы с сервера.
 */
const loadFail = createAction(ExternalDocumentTypesActionType.LOAD_FAIL, props<ErrorResponseProps<void>>());

/**
 * Тип, объединяющий все события, связанные со типами документов из внешней системы.
 */
export type ExternalDocumentTypeAction =
    | ReturnType<typeof load>
    | ReturnType<typeof loadSuccess>
    | ReturnType<typeof loadFail>;

/**
 * Все события, связанные со складами из внешней системы
 */
export const externalDocumentTypesAction = {
    load,
    loadSuccess,
    loadFail,
};
