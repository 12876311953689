import { HttpEvent } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { HttpInterceptor } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpResponse } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { delay } from 'rxjs/operators';

import { RouteService } from '../services';

/**
 * Глобальный перехватчик всех HTTP-запросов.
 */
@Injectable()
export class EnteraInterceptor implements HttpInterceptor {
    //region Ctor

    constructor(
        private _routeService: RouteService
    ) { }

    //endregion
    //region Public

    /**
     * Перехватчик всех запросов.
     * 
     * @param request Запрос.
     * @param next ?
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request)
            .pipe(
                catchError((error: any) => {

                    // Если перехватили 401 ошибку и это не метод получения текущего пользователя, 
                    // то выполняем редирект на форму логина.
                    if (
                        error instanceof HttpErrorResponse 
                        && error.status === 401
                        && !request.url.endsWith('currentUser')
                    ) {
                        this._routeService.goToLogin();

                        // Здесь хитрость, что мы подменяем ошибку успешным ответом и откладываем оповещение
                        // на 5 минут, что гарантирует нам то, что браузер уже выполнит переход на другую 
                        // страницу. И никакой наш код, который обрабатывает ошибочные ответы не успеет вывести
                        // диалоги или сделать свои редиректы.
                        return of(new HttpResponse()).pipe(delay(5 * 60 * 1000));
                    }

                    // Если не 401, то прокидываем ошибку дальше.
                    return throwError(error);
                })
            );
    }

    //endregion
}

/** 
 * Provider для нашей реализации HTTP-перехватчика.  
 */
export const enteraHttpInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: EnteraInterceptor,
    multi: true
};
