/**
 * Тпа источника задачи на распознавание.
 */
export class SourceEnum {
    //region Enum values

    /**
     * Загрузка с веб-приложения.
     */
    static readonly WEB_APP: SourceEnum = new SourceEnum("WEB_APP");

    /**
     * Загрузка с мобильной версии веб-приложения.
     */
    static readonly WEB_MOBILE: SourceEnum = new SourceEnum("WEB_MOBILE");

    //endregion
    //region Public fields

    /**
     * ID типа источника задачи на распознавание.
     */
    readonly id: string;

    //endregion
    //region Ctor

    /**
     * Конструктор типа источника задачи на распознавание.
     *
     * @param id ID типа источника задачи на распознавание.
     */
    private constructor(id: string) {

        this.id = id;
    }

    //endregion
}
