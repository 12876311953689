import { createSelector } from "@ngrx/store";
import { createFeatureSelector } from "@ngrx/store";
import { BroadcastMessage } from "src/app/common/models/broadcast-message.model";
import { BroadcastMessagesDlgState } from "src/app/root/store/states/broadcast-messages-dlg.state";

/**
 * Селектор состояния диалога сообщения пользователям.
 */
const state = createFeatureSelector<BroadcastMessagesDlgState>("broadcastMessagesDialog");

/**
 * Селектор текущего сообщения.
 */
const currentMessage = createSelector(state, extractCurrentMessage);

/**
 * Селектор индекса текущего сообщения.
 */
const currentMessageIndex = createSelector(state, extractCurrentMessageIndex);

/**
 * Селектор общего количества сообщений.
 */
const messageCount = createSelector(state, extractCurrentMessageCount);

/**
 * Объект, объединяющий состояния диалога сообщения пользователю.
 */
export const broadcastMessagesDlgSelectors = {
    state,
    currentMessage,
    currentMessageIndex,
    messageCount,
};

/**
 * Функция поиска текущего сообщения для отображения.
 */
function extractCurrentMessage(currentState: BroadcastMessagesDlgState): BroadcastMessage {

    if (!!currentState.broadcastMessages && !!currentState.broadcastMessages.length) {

        return currentState.broadcastMessages[currentState.currentMessageIndex];
    }
    else {

        return null;
    }
}

/**
 * Функция поиска номера текущего сообщения.
 */
function extractCurrentMessageIndex(currentState: BroadcastMessagesDlgState): number {

    return currentState.currentMessageIndex;
}

/**
 * Функция поиска всех неотвеченных сообщений.
 */
function extractCurrentMessageCount(currentState: BroadcastMessagesDlgState): number {

    return currentState.broadcastMessages.length;
}
