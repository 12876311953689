import { EventEmitter } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Subject } from "rxjs";
/**
 * Компонент компонента диалога создания компании..
 */
var CompanyCreatorFormComponent = /** @class */ (function () {
    //endregion
    //region С-tor
    function CompanyCreatorFormComponent(_formBuilder) {
        this._formBuilder = _formBuilder;
        /**
         * Отображать ли заголовок.
         */
        this.showHeader = true;
        //endregion
        //region Output
        /**
         * Исходящее событие попытки создания компании.
         */
        this.createCompany = new EventEmitter();
        //endregion
        //region Private
        /**
         * Объект глобальной отписки.
         */
        this._subject = new Subject();
        /**
         * Приватное значение загрузки.
         */
        this._loading = false;
    }
    Object.defineProperty(CompanyCreatorFormComponent.prototype, "value", {
        //region Input
        /**
         * Сеттер установки состояния формы.
         *
         * @param company Состояние для установки.
         */
        set: function (company) {
            this.createOrClearForm();
            if (company) {
                this.formGroup.patchValue({
                    name: company.name,
                    shortName: company.shortName,
                    inn: company.inn,
                    kpp: company.kpp
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyCreatorFormComponent.prototype, "loading", {
        /**
         * Состяоние загрузки
         */
        get: function () {
            return this._loading;
        },
        /**
         * Компонент в состоянии загрузки.
         */
        set: function (loading) {
            this._loading = loading;
            if (this.formGroup) {
                if (loading) {
                    this.formGroup.disable();
                }
                else {
                    this.formGroup.enable();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    ;
    //endregion
    //region LifeCycle
    CompanyCreatorFormComponent.prototype.ngOnInit = function () {
        if (!this.formGroup) {
            this.createOrClearForm();
        }
    };
    CompanyCreatorFormComponent.prototype.ngOnDestroy = function () {
        this._subject.next();
    };
    //endregion
    //region Public
    /**
     * Обработчик нажатия клавиши создать.
     */
    CompanyCreatorFormComponent.prototype.createHandler = function () {
        if (this.formGroup.valid) {
            this.createCompany.emit(this.formGroup.value);
        }
    };
    /**
     * Очистка или создание пустой формы если ее еще нет.
     */
    CompanyCreatorFormComponent.prototype.createOrClearForm = function () {
        if (this.formGroup) {
            this.formGroup.patchValue({
                shortName: null,
                name: null,
                inn: null,
                kpp: null,
            });
        }
        else {
            this.formGroup = this._formBuilder.group({
                shortName: null,
                name: null,
                inn: null,
                kpp: null,
            });
        }
        this.errorData = null;
    };
    /**
     * Полное описание ошибки.
     */
    CompanyCreatorFormComponent.prototype.getErrorMessage = function () {
        var result = null;
        if (this.errorData) {
            result = "" + this.errorData.errorMessage;
            if (this.errorData.details) {
                result = this.errorData.details.reduce(function (str, error) { return str + " " + error.errorMessage; }, result);
            }
        }
        return result;
    };
    return CompanyCreatorFormComponent;
}());
export { CompanyCreatorFormComponent };
