var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from "@angular/common/http";
import { throwError } from "rxjs";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { ErrorUtils } from "src/app/common/utils/error.utils";
import { UrlUtils } from "src/app/common/utils/url.utils";
import { DocumentRegistryFiltersState } from "src/app/spaces/modules/documents-registry/models/index";
import { DocumentsFilterUtils } from "src/app/spaces/modules/documents-registry/utils/documents-filter.utils";
import { PermissionItem } from "../models";
import { PermissionService } from "./permission.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./permission.service";
/**
 * Запрос на пометку документов как прочитанных.
 */
var MarkAsSeenRequest = /** @class */ (function () {
    function MarkAsSeenRequest() {
        /**
         * Документы для пометки как прочитанных.
         */
        this.documents = [];
    }
    return MarkAsSeenRequest;
}());
/**
 * Сервис для работы с документами.
 */
var DocumentsService = /** @class */ (function () {
    //endregion
    //region Ctor
    function DocumentsService(httpClient, _permissionService) {
        this.httpClient = httpClient;
        this._permissionService = _permissionService;
    }
    //endregion
    //region Public
    /**
     * Загрузка документов пространства с сервера.
     *
     * @param request Фильтры запроса.
     */
    // TODO Определить интерфейс запроса документов.
    DocumentsService.prototype.get = function (request) {
        var queryParams = __assign({}, request);
        return this.getDocuments(queryParams);
    };
    /**
     * Загрузка документов пространства с сервера с учетом фильтров массовых действий.
     *
     * @param request Фильтры запроса.
     */
    DocumentsService.prototype.getFilteredDocuments = function (request) {
        var excludedDocumentIds = (request.excludedDocuments || []).map(function (doc) { return doc.id; });
        var allDocumentsSelected = request.allDocumentsSelected;
        var queryParams = __assign({}, request.props.filter, { excludedDocumentIds: excludedDocumentIds,
            allDocumentsSelected: allDocumentsSelected });
        return this.getDocuments(queryParams);
    };
    /**
     * Загрузка документов пространства с сервера.
     *
     * @param request Фильтры запроса.
     */
    DocumentsService.prototype.getDocuments = function (queryParams) {
        var _this = this;
        return this._permissionService
            .hasPermission(PermissionItem.SEE_ALL_DOCUMENTS)
            .pipe(switchMap(function (access) {
            if (access) {
                queryParams = __assign({}, queryParams, { spaceId: null });
            }
            return _this.httpClient.get("/api/v1/documents" + UrlUtils.getQueryString(queryParams));
        }), map(function (response) { return response.documents; }), catchError(function (response) { return throwError(response.error); }));
    };
    /**
     * Загрузка кол-ва документов, подходящих под фильтры реестра документов.
     *
     * @param request Фильтры запроса.
     */
    // TODO Определить интерфейс запроса документов.
    DocumentsService.prototype.getCount = function (request) {
        var _this = this;
        var queryParams = __assign({}, request);
        return this._permissionService
            .hasPermission(PermissionItem.SEE_ALL_DOCUMENTS)
            .pipe(switchMap(function (access) {
            if (access) {
                queryParams = __assign({}, request, { spaceId: null });
            }
            if (!access || request.isForcedCounting || request.allDocumentsSelected) {
                return _this.httpClient.get("/api/v1/documents/count" + UrlUtils.getQueryString(queryParams));
            }
            return of({ documentsCount: -1 });
        }), map(function (response) { return response.documentsCount; }), catchError(function (response) { return throwError(response.error); }));
    };
    /**
     * Помечает документ как просмотренный.
     *
     * @param document Документ.
     */
    DocumentsService.prototype.markAsSeen = function (document) {
        var request = new MarkAsSeenRequest();
        request.documents.push({ id: document.id });
        return this.httpClient
            .post('/api/v1/documents/markAsSeen', request)
            .pipe(map(function (response) { return response.result; }), catchError(function (response) { return throwError(response.error); }));
    };
    /**
     * Перемещает документы в другую папку.
     *
     * @param props Идентификаторы документов и папки, в которую их нужно переместить.
     */
    DocumentsService.prototype.moveToAnotherSpace = function (props) {
        var filter = DocumentsFilterUtils.prepareFilter(props.filter);
        var excludedDocumentIds = (props.excludedDocuments || []).map(function (doc) { return doc.id; });
        filter = Object.assign(new DocumentRegistryFiltersState(), __assign({}, filter, { excludedDocumentIds: excludedDocumentIds }));
        var requestBody = {
            filter: filter,
            toSpaceId: props.toSpaceId,
            documentIdList: (props.documents || []).map(function (doc) { return doc.id; }),
            creatorId: props.creatorId,
        };
        return this.httpClient
            .post("/api/v1/documents/move", requestBody)
            .pipe(map(function (response) { return response.movedDocumentIdList; }), catchError(function (response) { return throwError(ErrorUtils.from(response)); }));
    };
    /**
     * Отправляет запрос на удаление документов по списку id документов.
     *
     * @param documents Список документов.
     */
    DocumentsService.prototype.deleteDocuments = function (documents) {
        var ids = documents.map(function (document) { return document.id; });
        return this.httpClient
            .request('delete', "/api/v1/documents", {
            body: { ids: ids }
        })
            .pipe(map(function (response) { return response.result; }), catchError(function (response) { return throwError(response.error); }));
    };
    /**
     * Отправляет запрос на удаление всех документов, подходящих под фильтр.
     *
     * @param request Фильтр.
     */
    DocumentsService.prototype.deleteDocumentsByFilter = function (request) {
        var excludedDocumentIds = (request.excludedDocuments || []).map(function (doc) { return doc.id; });
        var preparedFilter = DocumentsFilterUtils.prepareFilter(request.props.filter);
        var filter = Object.assign(new DocumentRegistryFiltersState(), __assign({}, preparedFilter, { excludedDocumentIds: excludedDocumentIds }));
        return this.httpClient
            .request('delete', "/api/v2/documents", { body: { filter: filter } })
            .pipe(map(function (response) { return ({ documentIds: response.documentIds, byFilter: true }); }), catchError(function (response) { return throwError(response.error); }));
    };
    //region Fields
    /**
     * Кол-во запрашиваемых документов.
     */
    DocumentsService.PAGE_SIZE = 10;
    DocumentsService.ngInjectableDef = i0.defineInjectable({ factory: function DocumentsService_Factory() { return new DocumentsService(i0.inject(i1.HttpClient), i0.inject(i2.PermissionService)); }, token: DocumentsService, providedIn: "root" });
    return DocumentsService;
}());
export { DocumentsService };
