import { Overlay } from "@angular/cdk/overlay";
import { TemplateRef } from "@angular/core";
import { Injector } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FormControlDirective } from "@angular/forms";
import { FormGroupDirective } from "@angular/forms";
import { FormControlName } from "@angular/forms";
import { NgModel } from "@angular/forms";
import { NgControl } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material";
import { MatFormFieldControl } from "@angular/material";
import { MatSelect } from "@angular/material";
import { timer } from "rxjs";
import { combineLatest } from "rxjs";
import { merge } from "rxjs";
import { of } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { Subject } from "rxjs";
import { fromEvent } from "rxjs";
import { delayWhen } from "rxjs/operators";
import { startWith } from "rxjs/operators";
import { map } from "rxjs/operators";
import { withLatestFrom } from "rxjs/operators";
import { debounceTime } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { finalize } from "rxjs/operators";
import { shareReplay } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { takeUntil } from "rxjs/operators";
import { scan } from "rxjs/operators";
/**
 * Type guard для проверки типа функции поиска.
 *
 * @param value Функция поиска.
 */
function isPagedSearchFunction(value) {
    return !!value && value.length === 3;
}
/**
 * Компонента обложка для MatSelect
 */
var EnteraMatSelectWrapperComponent = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор врапера.
     *
     * @param _injector Инжектор.
     * @param _overlay Сервис по созданию Overlay.
     */
    function EnteraMatSelectWrapperComponent(_injector, _overlay) {
        var _this = this;
        this._injector = _injector;
        this._overlay = _overlay;
        //region Inputs
        //region Wrapper
        /**
         * Функция отображения начального списка.
         */
        this.loadFn = (function () { return of([]); });
        /**
         * Функция поиска.
         */
        this.searchFn = null;
        /**
         * Размер страницы для постраничного поиска.
         *
         * Минимальное значение должно быть 6, иначе не будет работать событие подгрузки следующей страницы при скролле.
         */
        this.searchPageSize = 10;
        /**
         * Минимальная длинна для поиска
         */
        this.searchMinLength = 1;
        /**
         * Время откладывание запроса поиска между вводом строки для поиска.
         */
        this.searchDebounce = 300;
        /**
         * Показывать выбранный элемент в списке?
         */
        this.showSelectedOption = true;
        this.fixPositionAfterLoad = true;
        this.compareFn = function (o1, o2) { return (o1 && o1.id || o1) === (o2 && o2.id || o2); };
        //endregion
        //endregion
        //region Fields
        /**
         * Оригинальный форм контрол.
         *
         * Дефолтное значение для избегания накладок с поздней инициализацией целевого контрола.
         */
        this.formControl = new FormControl();
        /**
         * Контрол поиска.
         */
        this.searchFormControl = new FormControl();
        /**
         * Номер страницы поиска.
         */
        this.searchPage$ = new BehaviorSubject(1);
        /**
         * Сабж для отображения процесса загрузки.
         */
        this.loading$ = new BehaviorSubject(false);
        /**
         * Сабж для отображения процесса загрузки.
         */
        this.loadingPage$ = new BehaviorSubject(false);
        /**
         * Фейковое выбранное значение.
         *
         * Необходимо для того чтобы отображать значение в поле контрола при отсутствии элемента в списке.
         */
        this.fakeSelectedValue = {};
        /**
         * Загружаемое значение в селекте при поиске.
         */
        this.loadingValue = {};
        /**
         * Информационное сообщение.
         */
        this.infoOptionValue = {};
        /**
         * Информационное сообщение отсутствия результата при поиске.
         */
        this.noResultValue = {};
        /**
         * Subject для глобальной отписки.
         */
        this._unsubscribe$ = new Subject();
        /**
         * Функция для сравнения значений параметра с выбранными значениями.
         *
         * @param o1 Аргумент — это значение из опции.
         * @param o2 Второй - это значение из выбора.
         */
        this.compareWith = function (o1, o2) {
            return (o1 === _this.fakeSelectedValue && !!o2) || _this.compareFn && _this.compareFn(o1, o2);
        };
        this.loading$.pipe(takeUntil(this._unsubscribe$), filter(function () {
            return _this.matSelect
                && _this.matSelect.overlayDir != null
                && _this.matSelect.overlayDir.overlayRef != null;
        }), filter(function () { return _this.fixPositionAfterLoad; })).subscribe(function (_) {
            return setTimeout(function () {
                return _this.matSelect.overlayDir.overlayRef.updatePositionStrategy(_this.reCreatePosition());
            }, 100);
        });
    }
    Object.defineProperty(EnteraMatSelectWrapperComponent.prototype, "stateChanges", {
        get: function () {
            return this.matSelect.stateChanges;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnteraMatSelectWrapperComponent.prototype, "errorState", {
        get: function () {
            return this.matSelect.errorState || !!this.formControl.errors;
        },
        set: function (value) {
            this.matSelect.errorState = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnteraMatSelectWrapperComponent.prototype, "controlType", {
        get: function () {
            return this.matSelect.controlType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnteraMatSelectWrapperComponent.prototype, "empty", {
        get: function () {
            return this.matSelect.empty;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnteraMatSelectWrapperComponent.prototype, "focused", {
        get: function () {
            return this.matSelect.focused;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnteraMatSelectWrapperComponent.prototype, "id", {
        get: function () {
            return this.matSelect.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnteraMatSelectWrapperComponent.prototype, "ngControl", {
        get: function () {
            return this.matSelect.ngControl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnteraMatSelectWrapperComponent.prototype, "shouldLabelFloat", {
        get: function () {
            return this.matSelect.shouldLabelFloat;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnteraMatSelectWrapperComponent.prototype, "value", {
        get: function () {
            return this.matSelect.value;
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region LifeCycle hooks
    EnteraMatSelectWrapperComponent.prototype.ngDoCheck = function () {
        if (this.ngControl) {
            this.updateErrorState();
        }
    };
    EnteraMatSelectWrapperComponent.prototype.ngAfterViewInit = function () {
        this.matSelect.ngControl = this.findNgControl();
        this.setupWrapper();
    };
    EnteraMatSelectWrapperComponent.prototype.ngOnDestroy = function () {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    };
    //endregion
    //region ControlValueAccessor impl
    EnteraMatSelectWrapperComponent.prototype.registerOnChange = function (fn) {
        this.matSelect.registerOnChange(fn);
    };
    EnteraMatSelectWrapperComponent.prototype.registerOnTouched = function (fn) {
        this.matSelect.registerOnTouched(fn);
    };
    EnteraMatSelectWrapperComponent.prototype.setDisabledState = function (isDisabled) {
        this.matSelect.setDisabledState(isDisabled);
    };
    EnteraMatSelectWrapperComponent.prototype.writeValue = function (obj) {
        this.matSelect.writeValue(obj);
    };
    //endregion
    //region MatSelect proxy methods
    EnteraMatSelectWrapperComponent.prototype.updateErrorState = function () {
        this.matSelect.updateErrorState();
    };
    EnteraMatSelectWrapperComponent.prototype.onContainerClick = function (event) {
        this.matSelect.onContainerClick();
    };
    EnteraMatSelectWrapperComponent.prototype.setDescribedByIds = function (ids) {
        this.matSelect.setDescribedByIds(ids);
    };
    //endregion
    //region Public
    /**
     * Обработчик нажатия кнопок в поле поиска.
     *
     * Останавливает кнопки HOME и END от всплытия, так как иначе mat-select сделает event.preventDefault() для них,
     * что не даст в поле поиска перемещаться в начало или конец поля.
     *
     * @param event Событие нажатия кнопок в поле поиска.
     */
    EnteraMatSelectWrapperComponent.prototype.keydownHandler = function (event) {
        if (event.code === "Home" || event.code === "End") {
            event.stopPropagation();
        }
    };
    /**
     * Обработчик скролла окошка результатов.
     *
     * Если поиск делается постранично, в данный момент не происходит загрузка и пользователь доскролил список до дна,
     * то переключает номер страницы на следующий.
     */
    EnteraMatSelectWrapperComponent.prototype.scrollHandler = function () {
        var loading = this.loadingPage$.value || this.loading$.value;
        if (isPagedSearchFunction(this.searchFn) && !loading) {
            this.searchPage$.next(this.searchPage$.getValue() + 1);
        }
    };
    //endregion
    //region Private
    /**
     * Выполняет поиск NgControl на домашнем элементе компоненты.
     *
     * Присваивает внутреннее значение FormControl исходя из реализации NgControl.
     *
     * @return найденный NgControl.
     */
    EnteraMatSelectWrapperComponent.prototype.findNgControl = function () {
        var ngControl = this._injector.get(NgControl);
        switch (ngControl.constructor) {
            case NgModel: {
                var _a = ngControl, control = _a.control, update_1 = _a.update;
                this.formControl = control;
                this.formControl.valueChanges
                    .pipe(tap(function (value) { return update_1.emit(value); }), takeUntil(this._unsubscribe$))
                    .subscribe();
                break;
            }
            case FormControlName: {
                var formGroupDirective = this._injector.get(FormGroupDirective);
                this.formControl = formGroupDirective.getControl(ngControl);
                break;
            }
            case FormControlDirective: {
                this.formControl = ngControl.form;
                break;
            }
            default: {
                throw new Error("Can't find NgControl implementation");
            }
        }
        if (!this.formControl) {
            throw new Error("FormControl not present for " + ngControl);
        }
        return ngControl;
    };
    /**
     * Выполняет инициализацию логики враппера.
     */
    EnteraMatSelectWrapperComponent.prototype.setupWrapper = function () {
        var _this = this;
        this.searchLength$ = this.searchFormControl.valueChanges.pipe(map(function (search) { return search.length; }));
        this.maxSelectCountReached$ = this.formControl.valueChanges.pipe(startWith(this.formControl.value || []), map(function (selectedOptions) { return _this.multiple
            && isFinite(_this.maxSelectCount)
            && selectedOptions.length >= _this.maxSelectCount; }));
        var getPreload = function (open) {
            if (open === void 0) { open = true; }
            if (open) {
                return _this.loadFn().pipe(takeUntil(_this.searchLength$.pipe(filter(Boolean))), delayWhen(function (value) { return value.length > 10 && timer(50) || timer(0); }), finalize(function () {
                    _this.loading$.next(false);
                    _this.loadingPage$.next(false);
                }));
            }
            return of([]);
        };
        var getSearch = function (search, page, pageSize) {
            if (pageSize === void 0) { pageSize = _this.searchPageSize; }
            var searchResult = isPagedSearchFunction(_this.searchFn)
                ? _this.searchFn(search, page, pageSize)
                : _this.searchFn(search);
            return searchResult.pipe(takeUntil(_this.searchFormControl.valueChanges), finalize(function () {
                _this.loading$.next(false);
                _this.loadingPage$.next(false);
            }));
        };
        var preloaded$ = this.matSelect.openedChange.pipe(startWith(false), tap(function (open) { return _this.loading$.next(open); }), switchMap(getPreload), tap(function (open) { return open && _this.loading$.next(false); }), tap(function (open) { return open && _this.loadingPage$.next(false); }));
        var search$ = this.searchFormControl.valueChanges.pipe(filter(function (search) { return search.length >= _this.searchMinLength; }), tap(function () { return _this.loading$.next(true); }), debounceTime(this.searchDebounce), withLatestFrom(this.searchLength$), filter(function (_a) {
            var _ = _a[0], length = _a[1];
            return length >= _this.searchMinLength;
        }), map(function (_a) {
            var search = _a[0], _ = _a[1];
            return search;
        })).pipe(switchMap(function (search) { return getSearch(search, 0); }), tap(function () { return _this.loading$.next(false); }), tap(function () { return _this.loadingPage$.next(false); }), shareReplay(1));
        var cleanSearch$ = this.searchFormControl.valueChanges.pipe(filter(function (search) { return !search; }), withLatestFrom(this.matSelect.openedChange), filter(function (_a) {
            var _ = _a[0], open = _a[1];
            return open;
        }), tap(function () { return _this.loading$.next(true); }), switchMap(function () { return getPreload(); }), tap(function () { return _this.loading$.next(false); }), tap(function () { return _this.loadingPage$.next(false); }));
        var intermediateSearch$ = this.searchLength$.pipe(filter(function (length) { return length < _this.searchMinLength && length > 0; }), switchMap(function () { return of([]); }));
        // если страницы закончились, то больше не надо делать запрос
        var pagesEnded = new BehaviorSubject(false);
        var optionWithPagedSearch$ = merge(preloaded$, search$, cleanSearch$, intermediateSearch$).pipe(startWith([]), shareReplay(1));
        // постраничный поиск накапливает результаты всех запросов. при эмите любого другого обзервабла надо
        // сбрасывать накопленное. поэтому такая сложная херня тут
        var pagedSearch$ = merge(optionWithPagedSearch$.pipe(map(function () { return null; })), this.searchPage$
            .pipe(filter(function (_) { return !pagesEnded.value; }), filter(function (page) { return page > 1; }), withLatestFrom(this.searchLength$), filter(function (_a) {
            var _ = _a[0], length = _a[1];
            return length >= _this.searchMinLength;
        }), map(function (_a) {
            var page = _a[0], _ = _a[1];
            return page;
        }), tap(function () { return _this.loadingPage$.next(true); }), switchMap(function (page) { return page === 2
            ? merge(getSearch(_this.searchFormControl.value, 1), getSearch(_this.searchFormControl.value, 2))
            : getSearch(_this.searchFormControl.value, page); }), tap(function (val) { return pagesEnded.next(val.length < _this.searchPageSize); }), tap(function () { return _this.loading$.next(false); }), tap(function () { return _this.loadingPage$.next(false); })))
            .pipe(scan(function (accum, newVal) { return newVal ? accum.concat(newVal) : []; }, []), filter(function (val) { return val.length > 0; }));
        // обнуляем номер страницы и флаг того, что страницы кончились, если изменилось значение поиска
        optionWithPagedSearch$.pipe(takeUntil(this._unsubscribe$))
            .subscribe(function () {
            _this.searchPage$.next(1);
            pagesEnded.next(false);
        });
        // при открытии селекта подписываемся на скролл, чтобы менять номер страницы при постраничном запросе
        this.matSelect.openedChange
            .pipe(takeUntil(this._unsubscribe$), filter(Boolean), switchMap(function () { return fromEvent(_this.matSelect.panel.nativeElement, "scroll"); }), debounceTime(50), map(function (event) { return event.target; }), filter(function (panel) { return panel.scrollTop + panel.clientHeight === panel.scrollHeight; }), withLatestFrom(this.maxSelectCountReached$), filter(function (_a) {
            var _ = _a[0], maxCount = _a[1];
            return !maxCount;
        }), filter(function () { return _this.matSelect.panel && _this.matSelect.panel.nativeElement; })).subscribe(function () { return _this.scrollHandler(); });
        var notEqual = function (o1) { return function (o2) { return !_this.compareWith(o1, o2); }; };
        var filterSelected = function (options) { return _this.multiple
            ? options.filter(function (option) { return (_this.formControl.value || []).every(notEqual(option)); })
            : options.filter(notEqual(_this.formControl.value)); };
        var allOptions$ = merge(optionWithPagedSearch$, pagedSearch$).pipe(startWith([]), shareReplay(1));
        // formControl.valueChanges добавлен, чтобы при мультиселекте убирались строки после выбора
        var allValuesAndMaxCount = combineLatest([
            allOptions$,
            this.maxSelectCountReached$,
            this.formControl.valueChanges.pipe(startWith(null)),
        ]);
        this.optionList$ = allValuesAndMaxCount.pipe(map(function (_a) {
            var unselectedOptions = _a[0], _ = _a[1], __ = _a[2];
            return [filterSelected(unselectedOptions), _];
        }), map(function (_a) {
            var unselectedOptions = _a[0], maxCountReached = _a[1], _ = _a[2];
            return maxCountReached ? [] : unselectedOptions;
        }));
        this.noResult$ = combineLatest([
            this.loading$,
            this.loadingPage$,
            this.optionList$.pipe(map(function (arr) { return !arr.length; })),
            this.maxSelectCountReached$,
        ]).pipe(map(function (_a) {
            var loading = _a[0], loadingPage = _a[1], empty = _a[2], maxCount = _a[3];
            return !loading && !loadingPage && empty && !maxCount;
        }));
    };
    /**
     * Пересоздает и возвращает стратегию позиции mat-select.
     */
    EnteraMatSelectWrapperComponent.prototype.reCreatePosition = function () {
        return this._overlay.position()
            .flexibleConnectedTo(this.matSelect._elementRef)
            .withPositions([{
                originX: "start",
                originY: "bottom",
                overlayX: "start",
                overlayY: "top",
            }]);
    };
    return EnteraMatSelectWrapperComponent;
}());
export { EnteraMatSelectWrapperComponent };
