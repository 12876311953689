var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { BalanceActionType } from '../actions';
/**
 * Состояние по данным баланса для пространства документов.
 */
var BalanceState = /** @class */ (function () {
    function BalanceState() {
        // Данные баланса.
        this.balanceInfo = undefined;
        // Данные успешно загружены?
        this.loaded = false;
        // Данные загружаются?
        this.loading = false;
        // Загрузка завершилась ошибкой?
        this.failed = false;
    }
    return BalanceState;
}());
export { BalanceState };
/**
 * Начальное состояние по данным баланса для пространства документов.
 */
var initialState = new BalanceState();
/**
 * Обработка состояния баланса в ответ на события.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 */
export function balanceReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        // На событие требования загрузки данных.
        case BalanceActionType.LOAD: {
            result = __assign({}, state, { loading: true, loaded: false, failed: false });
            break;
        }
        // На событие успешной загрузки данных.
        case BalanceActionType.LOAD_SUCCESS: {
            var balanceInfo = action.payload;
            result = __assign({}, state, { balanceInfo: balanceInfo, loaded: true, loading: false });
            break;
        }
        // На событие неудачной загрузки данных.
        case BalanceActionType.LOAD_FAIL: {
            result = __assign({}, state, { balanceInfo: undefined, loaded: false, loading: false, failed: true });
            break;
        }
        // На событие требования списания с баланса.
        case BalanceActionType.WITHDRAW: {
            var cost = action.payload;
            var balanceInfo = state.balanceInfo;
            if (balanceInfo && typeof balanceInfo.restCreditCount === "number") {
                balanceInfo = __assign({}, balanceInfo, { restCreditCount: (balanceInfo.restCreditCount - cost) });
            }
            result = __assign({}, state, { balanceInfo: balanceInfo });
            break;
        }
        // На событие требования очистки данных.
        case BalanceActionType.CLEAR: {
            result = initialState;
            break;
        }
    }
    return result;
}
/**
 * Возвращает данные баланса из состояния данных о балансе.
 *
 * @param state Состояние данных о балансе.
 */
export var getBalanceInfo = function (state) { return state.balanceInfo; };
/**
 * Возвращает доступное количество кредитов.
 *
 * @param balance Данные баланса.
 */
export var getBalanceRestCreditCount = function (balance) { return balance ? balance.restCreditCount : null; };
/**
 * Возвращает флаг загружены ли данные баланса из состояния данных о балансе.
 *
 * @param state Состояние данных о балансе.
 */
export var getBalanceLoaded = function (state) { return state.loaded; };
/**
 * Возвращает флаг выполнения загрузки данных баланса из состояния данных о балансе.
 *
 * @param state Состояние данных о балансе.
 */
export var getBalanceLoading = function (state) { return state.loading; };
/**
 * Возвращает флаг была ли ошибка при попытке загрузки данных баланса из состояния данных о балансе.
 *
 * @param state Состояние данных о балансе.
 */
export var getBalanceFailed = function (state) { return state.failed; };
