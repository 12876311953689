import { EventEmitter } from "@angular/core";
import { FormControl } from "@angular/forms";
/**
 * Компонент формы диалога после создания задачи на распознавание.
 */
var RecognitionTaskCreatedComponent = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор компонента формы диалога после создания задачи на распознавание.
     */
    function RecognitionTaskCreatedComponent() {
        this.loading = false;
        this.error = null;
        this.applyDialog = new EventEmitter();
        this.dontShowAgainFlagControl = new FormControl(false);
        this._belongsToPaidClient = false;
    }
    Object.defineProperty(RecognitionTaskCreatedComponent.prototype, "belongsToPaidClient", {
        //endregion
        //region Getters and Setters
        /**
         * Пользователь принадлежит клиенту, которой произвёл оплату?
         */
        get: function () {
            return this._belongsToPaidClient;
        },
        /**
         * Пользователь принадлежит клиенту, которой произвёл оплату?
         */
        set: function (value) {
            this._belongsToPaidClient = value;
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Events
    /**
     * Обработчик клика на кнопку "Продолжить".
     *
     * Генерируется событие с требованием обработать закрытие диалога с указанными данными.
     */
    RecognitionTaskCreatedComponent.prototype.applyBtnClickHandler = function () {
        this.applyDialog.emit({ dontShowRecognitionTaskCreatedDialog: this.dontShowAgainFlagControl.value });
    };
    return RecognitionTaskCreatedComponent;
}());
export { RecognitionTaskCreatedComponent };
