import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { ValueProps } from "src/app/root/store/actions/props/value-props";
import { ExternalCompany } from "src/app/spaces/modules/document-matching/models/external-company";
import { ExternalItem } from "src/app/spaces/modules/document-matching/models/external-item";

/**
 * Типы событий, связанные с созданием новых элементов для внешней системы.
 */
export enum NewExternalElementsActionType {
    ADD_NEW_ITEM = "[Create external element] Add new external item",
    ADD_NEW_SUPPLIER = "[Create external element] Add new external supplier",
    ADD_NEW_CUSTOMER = "[Create external element] Add new external customer",
    UPDATE_ITEM = "[Create external element] Update external item",
    UPDATE_SUPPLIER = "[Create external element] Update external supplier",
    UPDATE_CUSTOMER = "[Create external element] Update external customer",
    REMOVE_ITEM = "[Create external element] Remove external item",
    REMOVE_SUPPLIER = "[Create external element] Remove external supplier",
    REMOVE_CUSTOMER = "[Create external element] Remove external customer",
    CLEAR_ALL = "[Create external element] Clear state",
}

/**
 * Событие создания позиции из распознанных данных.
 */
const addNewItem = createAction(NewExternalElementsActionType.ADD_NEW_ITEM, props<ValueProps<ExternalItem>>());

/**
 * Событие создания поставщика из распознанных данных.
 */
const addNewSupplier = createAction(NewExternalElementsActionType.ADD_NEW_SUPPLIER, props<ValueProps<ExternalCompany>>());

/**
 * Событие создания покупателя из распознанных данных.
 */
const addNewCustomer = createAction(NewExternalElementsActionType.ADD_NEW_CUSTOMER, props<ValueProps<ExternalCompany>>());

/**
 * Событие обновления позиции из распознанных данных.
 */
const updateItem = createAction(NewExternalElementsActionType.UPDATE_ITEM, props<ValueProps<ExternalItem>>());

/**
 * Событие обновления поставщика из распознанных данных.
 */
const updateSupplier = createAction(NewExternalElementsActionType.UPDATE_SUPPLIER, props<ValueProps<ExternalCompany>>());

/**
 * Событие обновления покупателя из распознанных данных.
 */
const updateCustomer = createAction(NewExternalElementsActionType.UPDATE_CUSTOMER, props<ValueProps<ExternalCompany>>());

/**
 * Событие сброса позиции из распознанных данных из выбора.
 */
const removeItem = createAction(NewExternalElementsActionType.REMOVE_ITEM, props<ValueProps<ExternalItem>>());

/**
 * Событие сброса поставщика из распознанных данных из выбора.
 */
const removeSupplier = createAction(NewExternalElementsActionType.REMOVE_SUPPLIER, props<ValueProps<ExternalCompany>>());

/**
 * Событие сброса покупателя из распознанных данных из выбора.
 */
const removeCustomer = createAction(NewExternalElementsActionType.REMOVE_CUSTOMER, props<ValueProps<ExternalCompany>>());

/**
 * Событие очистки стейта.
 */
const clearAll = createAction(NewExternalElementsActionType.CLEAR_ALL);

/**
 * Тип, объединяющий все события, связанные с созданием новых элементов для внешней системы.
 */
export type NewExternalElementsAction =
    | ReturnType<typeof addNewItem>
    | ReturnType<typeof addNewSupplier>
    | ReturnType<typeof addNewCustomer>
    | ReturnType<typeof updateItem>
    | ReturnType<typeof updateSupplier>
    | ReturnType<typeof updateCustomer>
    | ReturnType<typeof removeItem>
    | ReturnType<typeof removeSupplier>
    | ReturnType<typeof removeCustomer>
    | ReturnType<typeof clearAll>
    ;

/**
 * Все события, связанные с созданием новых элементов для внешней системы.
 */
export const newExternalElementsActions = {
    addNewItem: addNewItem,
    addNewSupplier: addNewSupplier,
    addNewCustomer: addNewCustomer,
    updateItem: updateItem,
    updateSupplier: updateSupplier,
    updateCustomer: updateCustomer,
    removeItem: removeItem,
    removeSupplier: removeSupplier,
    removeCustomer: removeCustomer,
    clearAll: clearAll,
};
