<div class="users-and-spaces">
    <h2 mat-dialog-title>
        <span class="mat-dialog-title__text">{{ headerKey | translate }}</span>
    </h2>
    <mat-dialog-content
        class="users-and-spaces__content"
        [class.users-and-spaces__content_small]="requestLoading || requestError || requestSuccess"
    >
        <div *ngIf="requestError" class="users-and-spaces__errors">
            <p class="users-and-spaces__text">
                {{ errorDescriptionKey | translate }}
            </p>
            <div>
                <div *ngIf="requestError.errorId">Error ID: {{ requestError.errorId }}</div>
                <div *ngIf="requestError.errorCode">Error code: {{ requestError.errorCode }}</div>
            </div>
        </div>
        <mat-spinner class="users-and-spaces__spinner" *ngIf="requestLoading && !requestError"></mat-spinner>
        <mat-form-field *ngIf="!requestLoading && !requestError && !requestSuccess"
            class="users-and-spaces__email-chip-list"
        >
            <mat-chip-list #emailChipList [formControl]="emailListControl">
                <mat-chip *ngFor="let email of emailListControl.value"
                    [class.users-and-spaces__incorrect-email-chip]="!isEmailValid(email)"
                    [selectable]="false"
                    [removable]="true"
                    (removed)="removeEmailHandler(email)"
                >
                    {{ email }}
                    <mat-icon
                        matChipRemove
                        [class.users-and-spaces__incorrect-email-chip-remove-btn]="!isEmailValid(email)"
                    >
                        cancel
                    </mat-icon>
                </mat-chip>
                <input placeholder="{{ 'dialogs.usersAndSpaces.emailPlaceholder' | translate }}"
                    [matChipInputFor]="emailChipList"
                    [matChipInputAddOnBlur]="true"
                    [matChipInputSeparatorKeyCodes]="chipsSeparatorKeysCodes"
                    (paste)="emailPasteHandler($event)"
                    (matChipInputTokenEnd)="addEmailHandler($event)"
                />
            </mat-chip-list>
            <mat-error>
                {{'dialogs.usersAndSpaces.incorrectEmailMessage' | translate:{email: emailListControl.errors?.incorrectEmail} }}
            </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="!requestLoading && !requestError && !requestSuccess"
            class="users-and-spaces__space-chip-list"
        >
            <mat-chip-list [disabled]="isAllSpacesSelect" #spaceChipList>
                <mat-chip *ngFor="let selectedSpace of selectedSpaces"
                    (removed)="removeSpaceHandler(selectedSpace)"
                >
                    {{ selectedSpace.name }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input placeholder="{{ 'dialogs.usersAndSpaces.folderPlaceholder' | translate }}"
                    #spaceInput
                    [matChipInputFor]="spaceChipList"
                    [formControl]="spaceCtrl"
                    [matAutocomplete]="auto"
                    [matChipInputSeparatorKeyCodes]="chipsSeparatorKeysCodes"
                    (matChipInputTokenEnd)="addSpaceHandler($event)"
                />
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectionHandler($event)">
                <mat-option *ngFor="let space of filteredSpaces | async" [value]="space.name">
                    {{ space.name }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-checkbox *ngIf="!requestLoading && !requestError && !requestSuccess" [formControl]="selectAllSpacesCtrl">
            {{ 'dialogs.usersAndSpaces.addToAllSpaces' | translate }}
        </mat-checkbox>
        <div *ngIf="requestSuccess" class="users-and-spaces__success-message">
            <p class="users-and-spaces__text">
                {{ successDescriptionKey | translate }}
            </p>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button *ngIf="!requestLoading"
            class="mat-dialog__action-btn"
            mat-raised-button
            (click)="closeDialogButtonHandler()"
        >
            {{ 'dialogs.close' | translate }}
        </button>
        <button *ngIf="!requestLoading && !requestError && !requestSuccess"
            class="mat-dialog__action-btn"
            mat-raised-button
            color="primary"
            [disabled]="!areDataValid()"
            (click)="applyBtnClickHandler()"
        >
            {{ okBtnKey | translate }}
        </button>
    </mat-dialog-actions>
</div>
