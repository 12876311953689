var _a;
import { Store } from "@ngrx/store";
import { select } from "@ngrx/store";
import { BehaviorSubject } from "rxjs";
import { Subject } from "rxjs";
import { withLatestFrom } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { map } from "rxjs/operators";
import { takeUntil } from "rxjs/operators";
import { currentUserStateSelector } from "src/app/root/store/reducers";
import { getDefaultDocumentPageInterfaceState } from "src/app/spaces/modules/document/store/states/document-page-interface.state";
import { getDefaultRecognizingColumnsVisibilityState } from "src/app/spaces/modules/documents-registry/models/documents-registry-columns-visibility-state";
import { getDefaultColumnsVisibilityState } from "src/app/spaces/modules/documents-registry/models/documents-registry-columns-visibility-state";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
/**
 * Сервис для работы с LocalStorage.
 *
 */
var LocalStorageService = /** @class */ (function () {
    //endregion
    //region Ctor
    function LocalStorageService(_store) {
        this._store = _store;
        this._stopSubscriptions$ = new Subject();
        this._localStorageObjects = new Map();
        this._user$ = this._store.pipe(takeUntil(this._stopSubscriptions$), select(currentUserStateSelector), filter(function (state) { return state.failed || state.loaded; }), map(function (state) { return state.currentUserInfo; }));
    }
    //endregion
    //region Hooks
    /**
     * Хук на уничтожение компонента, в котором происходитчт отписки.
     */
    LocalStorageService.prototype.ngOnDestroy = function () {
        this._stopSubscriptions$.next();
        this._stopSubscriptions$.complete();
    };
    //endregion
    //region Public
    /**
     * Возвращает значение из локального хранилища.
     *
     * @param key Ключ значения в локальном хранилище.
     */
    LocalStorageService.prototype.get = function (key) {
        if (!this._localStorageObjects.has(key)) {
            this._initializeSubject(key);
        }
        return this._localStorageObjects.get(key).asObservable().pipe(map(function (state) { return state.value; }));
    };
    /**
     * Кладёт значение в локальное хранилище по указанному ключу.
     *
     * @param key Ключ.
     * @param value Значение.
     */
    LocalStorageService.prototype.put = function (key, value) {
        this._localStorageObjects.get(key).next({ update: true, value: value });
    };
    //endregion
    //region Private
    /**
     * Возвращает результат парсинга строки, если оно не равно null, в противном случае возвращает значение по
     * умолчанию.
     *
     * @param value Строка для парсинга.
     * @param defaultValue Значение по умолчанию.
     *
     * @return Результат парсинга или значение по умолчанию.
     */
    LocalStorageService.prototype._parseOrDefault = function (value, defaultValue) {
        if (value !== null) {
            return JSON.parse(value);
        }
        return defaultValue;
    };
    /**
     * Возвращает ключ данных относительно текущего пользователя.
     *
     * @param key Ключ данных.
     * @param user Пользователь.
     */
    LocalStorageService.prototype._getKey = function (key, user) {
        return (user && user.email || "") + key;
    };
    /**
     * Инициализирует субъект для объекта в локальном хранилище.
     *
     * Создаёт субъект и присваивает ему значение из локального хранилища, либо значение по умолчанию. Создаёт подписку
     * на обновление значения в локальном хранилище.
     *
     * @param key Ключ.
     */
    LocalStorageService.prototype._initializeSubject = function (key) {
        var _this = this;
        var newBehaviorSubject = new BehaviorSubject({ value: localStorageDefaultValues[key] });
        this._localStorageObjects.set(key, newBehaviorSubject);
        this._user$
            .pipe(takeUntil(this._stopSubscriptions$), map(function (user) { return _this._getKey(key, user); }), map(function (fullKey) { return localStorage.getItem(fullKey); }), map(function (item) { return _this._parseOrDefault(item, localStorageDefaultValues[key]); }))
            .subscribe(function (value) { return newBehaviorSubject.next({ value: value }); });
        newBehaviorSubject.pipe(takeUntil(this._stopSubscriptions$), withLatestFrom(this._user$), filter(function (_a) {
            var _ = _a[0], user = _a[1];
            return Boolean(user);
        }), filter(function (_a) {
            var state = _a[0], _ = _a[1];
            return Boolean(state.update);
        }))
            .subscribe(function (_a) {
            var state = _a[0], user = _a[1];
            return localStorage.setItem(_this._getKey(key, user), JSON.stringify(state.value));
        });
    };
    LocalStorageService.ngInjectableDef = i0.defineInjectable({ factory: function LocalStorageService_Factory() { return new LocalStorageService(i0.inject(i1.Store)); }, token: LocalStorageService, providedIn: "root" });
    return LocalStorageService;
}());
export { LocalStorageService };
/**
 * Ключи для доступа к объектам в локальном хранилище.
 */
export var LocalStorageKeys;
(function (LocalStorageKeys) {
    LocalStorageKeys["DOCUMENT_REGISTRY_COLUMNS_VISIBILITY_STATE"] = "DocumentRegistryColumnsVisibilityState";
    LocalStorageKeys["RECOGNIZING_REGISTRY_COLUMNS_VISIBILITY_STATE"] = "RecognizingDocumentRegistryColumnsVisibilityState";
    LocalStorageKeys["DOCUMENT_PAGE_INTERFACE_STATE"] = "DocumentPageInterfaceState";
    LocalStorageKeys["USER_NOTIFICATION_VIEW"] = "UserNotificationView";
    LocalStorageKeys["LAST_USED_CAMERA"] = "QrScannerLastCamera";
})(LocalStorageKeys || (LocalStorageKeys = {}));
/**
 * Значения по умолчанию для объектов в локальном хранилище.
 */
var localStorageDefaultValues = (_a = {},
    _a[LocalStorageKeys.DOCUMENT_REGISTRY_COLUMNS_VISIBILITY_STATE] = getDefaultColumnsVisibilityState(),
    _a[LocalStorageKeys.RECOGNIZING_REGISTRY_COLUMNS_VISIBILITY_STATE] = getDefaultRecognizingColumnsVisibilityState(),
    _a[LocalStorageKeys.DOCUMENT_PAGE_INTERFACE_STATE] = getDefaultDocumentPageInterfaceState(),
    _a[LocalStorageKeys.USER_NOTIFICATION_VIEW] = null,
    _a[LocalStorageKeys.LAST_USED_CAMERA] = null,
    _a);
