import { ChangeDetectorRef } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
/**
 * Компонент простого диалога с текстом.
 */
var SimpleAlertDlgComponent = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор компонента простого диалога с текстом.
     *
     * @param _dialogRef Компонент диалога.
     * @param _changeDetector Сервис для управления запуском определения angular'ом изменений данных,
     * произошедших в компоненте.
     * @param data Данные для простых диалогов.
     * @param translateService Сервис для работы с i18n-сообщениями.
     */
    function SimpleAlertDlgComponent(_dialogRef, _changeDetector, data, translateService) {
        this._dialogRef = _dialogRef;
        this._changeDetector = _changeDetector;
        /**
         * Подписки компонента.
         */
        this._subscriptions = [];
        this._setData(data);
        this._translateService = translateService;
    }
    //endregion
    //region Hooks
    SimpleAlertDlgComponent.prototype.ngOnInit = function () {
        // Использование afterClosed() не вызывает cancelCallback
        this._subscriptions.push(this._dialogRef.beforeClose().subscribe(this._data.closeCallback));
    };
    SimpleAlertDlgComponent.prototype.ngOnDestroy = function () {
        // Отписываемся от всех подписок.
        this._subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    Object.defineProperty(SimpleAlertDlgComponent.prototype, "hasOkButton", {
        //endregion
        //region Getters and Setters
        /**
         * У диалога есть кнопка принятия?
         */
        get: function () {
            return !!(this._data.okCallback);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SimpleAlertDlgComponent.prototype, "okBtnKey", {
        /**
         * I18n-ключ текста кнопки принятия диалога.
         */
        get: function () {
            return (this._data.okBtnKey || 'dialogs.ok');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SimpleAlertDlgComponent.prototype, "closeBtnKey", {
        /**
         * I18n-ключ текста кнопки закрытия диалога.
         */
        get: function () {
            return (this._data.closeBtnKey || 'dialogs.close');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SimpleAlertDlgComponent.prototype, "text", {
        /**
         * Текст диалога.
         */
        get: function () {
            var result;
            if (this._data.text) {
                result = this._data.text;
            }
            else {
                result = this._translateService.get(this.textKey, this.textParams);
            }
            return result;
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Public
    /**
     * Обновление содержимого дилога.
     *
     * @param data новые данные диалога.
     */
    SimpleAlertDlgComponent.prototype.updateData = function (data) {
        this._setData(data);
        this._changeDetector.markForCheck();
    };
    //endregion
    //region Events
    /**
     * Обработчик клика по кнопке принятия диалога.
     */
    SimpleAlertDlgComponent.prototype.okClickHandler = function () {
        if (this._data.okCallback) {
            this._data.okCallback();
        }
        this._dialogRef.close();
    };
    //endregion
    //region Private
    /**
     * Выполняет обновление данных диалога на основе заданных данных.
     *
     * @param data Данные диалога.
     *
     * @private
     */
    SimpleAlertDlgComponent.prototype._setData = function (data) {
        this._data = data;
        this.headerKey = this._data.headerKey;
        this.headerParams = this._data.headerParams || {};
        this.textKey = this._data.textKey;
        this.textParams = this._data.textParams || {};
        this.loading = this._data.loading;
        this.error = this._data.error;
    };
    return SimpleAlertDlgComponent;
}());
export { SimpleAlertDlgComponent };
