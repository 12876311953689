import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { HeaderHideAction } from '../store';
import { headerHiddenSelector } from '../store';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
/**
 * Route Guard.
 * Логика обработки попытки входа по пути, для которого требуется скрыть заголовка сайта.
 */
var HideHeaderGuard = /** @class */ (function () {
    //region Ctor
    function HideHeaderGuard(_store) {
        this._store = _store;
    }
    //endregion
    //region Public
    HideHeaderGuard.prototype.canActivate = function () {
        var _this = this;
        return this._store
            .pipe(select(headerHiddenSelector), 
        // Если заголовок не виден, то запускаем его отображение.
        tap(function (hidden) {
            if (!hidden) {
                _this._store.dispatch(new HeaderHideAction());
            }
        }), 
        // Даём разрешение только тогда, когда заголовок станет виден.
        map(function (hidden) { return hidden; }));
    };
    HideHeaderGuard.prototype.canActivateChild = function () {
        return this.canActivate();
    };
    HideHeaderGuard.ngInjectableDef = i0.defineInjectable({ factory: function HideHeaderGuard_Factory() { return new HideHeaderGuard(i0.inject(i1.Store)); }, token: HideHeaderGuard, providedIn: "root" });
    return HideHeaderGuard;
}());
export { HideHeaderGuard };
