import { EventEmitter } from "@angular/core";
import { ElementRef } from "@angular/core";
import { UploadToRecognizeState } from "src/app/root/store/index";
var FileUploadZoneComponent = /** @class */ (function () {
    function FileUploadZoneComponent() {
        //region Inputs
        /**
         * Режим выбора нескольких файлов.
         */
        this.multipleFileMode = true;
        /**
         * Выбираемые типы файлов.
         */
        this.fileFormats = [
            ".jpeg",
            ".jpg",
            ".png",
            ".tiff",
            ".tif",
            ".bmp",
            ".pdf",
            ".doc",
            ".docx",
            ".odt",
            ".xls",
            ".xlsx",
            ".rtf",
            ".ods"
        ];
        //endregion
        //region Outputs
        /**
         * Добавления файла к задаче.
         */
        this.addFiles = new EventEmitter();
        /**
         * Удаление файла из задачи.
         */
        this.remove = new EventEmitter();
        /**
         * Счетчик dragover события.
         */
        this._counter = 0;
        //endregion
    }
    Object.defineProperty(FileUploadZoneComponent.prototype, "availableFileFormats", {
        //endregion
        //region Getters and Setters
        /**
         * Возвращает доступные форматы файлов одной строкой.
         */
        get: function () {
            return this.fileFormats.join(",");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileUploadZoneComponent.prototype, "uploadButtonVisibility", {
        get: function () {
            return this.multipleFileMode || this.uploadState.filesWithMetaData.length === 0;
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Events
    /**
     * Изменение инпута файлов.
     */
    FileUploadZoneComponent.prototype.onChange = function () {
        var files = Array.from(this.input.nativeElement.files);
        this._emitAddFiles(files);
        this.input.nativeElement.value = '';
    };
    FileUploadZoneComponent.prototype.onDragOver = function (event) {
        if (this.uploadButtonVisibility) {
            event.preventDefault();
        }
    };
    FileUploadZoneComponent.prototype.onDragEnter = function (event) {
        if (this.uploadButtonVisibility) {
            this._counter++;
            if (this._counter) {
                this.dropEnter = true;
            }
        }
    };
    FileUploadZoneComponent.prototype.onDragLeave = function (event) {
        if (this.uploadButtonVisibility) {
            this._counter--;
            if (!this._counter) {
                this.dropEnter = false;
            }
        }
    };
    FileUploadZoneComponent.prototype.onDrop = function (event) {
        var _this = this;
        if (this.uploadButtonVisibility) {
            var files = Array.from(event.dataTransfer.files)
                .filter(function (file) { return _this.fileFormats.some(function (format) { return file.name.toLocaleLowerCase().endsWith(format); }); })
                .map(function (file) { return file; });
            this._emitAddFiles(files);
            event.preventDefault();
            event.stopPropagation();
            this.dropZone.nativeElement.value = '';
            this._counter = 0;
            this.dropEnter = false;
        }
    };
    //endregion
    //region Private
    /**
     * Обработчик требования добавить файлы в список для загрузки.
     *
     * - Если включена возможность выбора нескольких файлов - добавляет их.
     * - Иначе, добавляет первый файл, если еще нет файлов в списке для загрузки.
     *
     * @param files Файлы.
     */
    FileUploadZoneComponent.prototype._emitAddFiles = function (files) {
        var nonEmptyFiles = files.filter(function (file) { return !!file.size; });
        if (nonEmptyFiles.length) {
            if (this.multipleFileMode) {
                this.addFiles.emit(nonEmptyFiles);
            }
            else if (!this.uploadState || !this.uploadState.filesWithMetaData.length) {
                this.addFiles.emit([nonEmptyFiles[0]]);
            }
        }
    };
    return FileUploadZoneComponent;
}());
export { FileUploadZoneComponent };
