import { BroadcastMessagesDlgEffect } from "src/app/root/store/effects/broadcast-messages-dlg.effect";
import { CurrenciesEffects } from "src/app/root/store/effects/currencies.effect";
import { DocumentTypesEffects } from "src/app/root/store/effects/document-types.effect";
import { FeedbackEffect } from "src/app/root/store/effects/feedback.effect";
import { PurchaseDialogEffect } from "src/app/root/store/effects/purchase-dialog.effect";
import { TrustedEmailsAdditionEffect } from "src/app/root/store/effects/trusted-emails-addition-effect";
import { UsersInvitationDlgEffect } from "src/app/root/store/effects/users-invitation-dlg-effect";
import { BalanceEffects } from "./balance.effect";
import { CurrentUserSettingsEffect } from "./current-user-settings.effect";
import { CurrentUserEffects } from "./current-user.effect";
import { effects as documentEffects } from "./document";
import { HeaderEffects } from "./header.effect";
import { LangEffects } from "./lang.effects";
import { RecognitionTaskCreatedDlgEffect } from "./recognition-task-created-dlg.effect";
import { effects as recognizeEffects } from "./recognize";
import { RouterEffects } from "./router.effect";
import { ServerSideErrorsEffects } from "./server-side-errors.effect";

/**
 * Все side-эффекты, определённые в этой папке.
 */
export const effects: any[] = [
    RouterEffects, 
    CurrentUserEffects,
    CurrenciesEffects,
    DocumentTypesEffects,
    ServerSideErrorsEffects,
    LangEffects,
    HeaderEffects,
    BalanceEffects,
    FeedbackEffect,
    RecognitionTaskCreatedDlgEffect,
    CurrentUserSettingsEffect,
    UsersInvitationDlgEffect,
    TrustedEmailsAdditionEffect,
    BroadcastMessagesDlgEffect,
    PurchaseDialogEffect,
    ... recognizeEffects,
    ... documentEffects
];

export * from "./router.effect";
