import { requestState } from "src/app/common/models/request-state";
import { ExternalCostCentresState } from "src/app/spaces/modules/document-matching/store/states/external-cost-centres.state";
import { ExternalCostCentresAction } from "src/app/spaces/modules/document-matching/store/actions/external-cost-centres.action";
import { ExternalCostCentresActionType } from "src/app/spaces/modules/document-matching/store/actions/external-cost-centres.action";

/**
 * Начальное состояние обработчика событий, связанных с состоянием центров расходов из внешней системы.
 */
const initialState: ExternalCostCentresState = {

    /**
     * Начальное состояние запроса центров расходов с сервера.
     */
    externalCostCentresRequest: requestState.initial(),
};

/**
 * Обработчик событий, связанных с состоянием центров расходов из внешней системы.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 *
 * @return Новое состояние.
 */
export function externalCostCentresReducer(
    state: ExternalCostCentresState = initialState,
    action: ExternalCostCentresAction,
): ExternalCostCentresState {
    let result: ExternalCostCentresState = state;

    switch (action.type) {

        case ExternalCostCentresActionType.INIT: {

            result = {
                ...state,
                externalCostCentresRequest: requestState.initial(),
            };

            break;
        }
        case ExternalCostCentresActionType.LOAD: {

            result = {
                ...state,
                externalCostCentresRequest: requestState.pending(),
            };

            break;
        }
        case ExternalCostCentresActionType.LOAD_SUCCESS: {

            result = {
                ...state,
                externalCostCentresRequest: requestState.success(action.value),
            };

            break;
        }
        case ExternalCostCentresActionType.LOAD_FAIL: {

            result = {
                ...state,
                externalCostCentresRequest: requestState.fail(action.error),
            };

            break;
        }
    }

    return result;
}
