/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./entera-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i8 from "@angular/material/icon";
import * as i9 from "@ngx-translate/core";
import * as i10 from "../have-questions-menu/have-questions-menu.component.ngfactory";
import * as i11 from "../have-questions-menu/have-questions-menu.component";
import * as i12 from "../balance-info-menu/balance-info-menu.component.ngfactory";
import * as i13 from "../balance-info-menu/balance-info-menu.component";
import * as i14 from "../../services/utils.service";
import * as i15 from "../spaces-info-menu/spaces-info-menu.component.ngfactory";
import * as i16 from "../spaces-info-menu/spaces-info-menu.component";
import * as i17 from "@angular/common";
import * as i18 from "../user-info-menu/user-info-menu.component.ngfactory";
import * as i19 from "../user-info-menu/user-info-menu.component";
import * as i20 from "../../services/user.service";
import * as i21 from "./entera-header.component";
import * as i22 from "@ngrx/store";
var styles_EnteraHeaderComponent = [i0.styles];
var RenderType_EnteraHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EnteraHeaderComponent, data: {} });
export { RenderType_EnteraHeaderComponent as RenderType_EnteraHeaderComponent };
function View_EnteraHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["class", "entera-header__item entera-header__how-to-start-btn"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.howToStartHandler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "mat-icon", [["class", "entera-header__buy-icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null], [2, i8.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["rocket_launch"])), (_l()(), i1.ɵted(5, 0, [" ", " "])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 3).inline; var currVal_3 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("header.howToStart")); _ck(_v, 5, 0, currVal_4); }); }
function View_EnteraHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "button", [["class", "entera-header__item entera-header__buy-button"], ["mat-button", ""]], [[2, "entera-header__buy-button_trial", null], [8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.buyPagesClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "mat-icon", [["class", "entera-header__buy-icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null], [2, i8.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(4, 0, ["", ""])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(6, 0, [" ", " "])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.space.paid; var currVal_1 = (i1.ɵnov(_v, 1).disabled || null); var currVal_2 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵnov(_v, 3).inline; var currVal_4 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("header.buy.icon")); _ck(_v, 4, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("header.buy.pages")); _ck(_v, 6, 0, currVal_6); }); }
function View_EnteraHeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "entera-header__item entera-header__centered-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "a", [["class", "entera-header__integration"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.integrationClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "entera-header__integration-icon mat-icon notranslate"], ["fontSet", "material-icons-custom-set"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null], [2, i8.MAT_ICON_LOCATION]], { fontSet: [0, "fontSet"] }, null), (_l()(), i1.ɵted(-1, 0, [" archive_outline "])), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = "material-icons-custom-set"; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; var currVal_1 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("header.1c")); _ck(_v, 5, 0, currVal_3); }); }
function View_EnteraHeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "have-questions-menu", [["class", "entera-header__have-questions entera-header__item"]], null, [[null, "callBack"], [null, "knowledgeBase"], [null, "howToStart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("callBack" === en)) {
        var pd_0 = (_co.callBackHandler() !== false);
        ad = (pd_0 && ad);
    } if (("knowledgeBase" === en)) {
        var pd_1 = (_co.knowledgeBaseHandler() !== false);
        ad = (pd_1 && ad);
    } if (("howToStart" === en)) {
        var pd_2 = (_co.howToStartHandler() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i10.View_HaveQuestionsMenuComponent_0, i10.RenderType_HaveQuestionsMenuComponent)), i1.ɵdid(1, 49152, null, 0, i11.HaveQuestionsMenuComponent, [], null, { callBack: "callBack", knowledgeBase: "knowledgeBase", howToStart: "howToStart" })], null, null); }
function View_EnteraHeaderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "balance-info-menu", [["class", "entera-header__balance-info entera-header__item"]], null, [[null, "buy"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buy" === en)) {
        var pd_0 = (_co.buyHandler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_BalanceInfoMenuComponent_0, i12.RenderType_BalanceInfoMenuComponent)), i1.ɵdid(1, 49152, null, 0, i13.BalanceInfoMenuComponent, [i9.TranslateService, i14.UtilsService], { restCreditCount: [0, "restCreditCount"], expirationDate: [1, "expirationDate"], foreign: [2, "foreign"], showUserNotification: [3, "showUserNotification"], expiryNotificationType: [4, "expiryNotificationType"] }, { buy: "buy" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.creditCount; var currVal_1 = ((_co.user == null) ? null : _co.user.client.balanceExpirationDate); var currVal_2 = _co.user.client.foreign; var currVal_3 = _co.showUserNotification; var currVal_4 = _co.expiryNotificationType; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_EnteraHeaderComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "spaces-info-menu", [["class", "entera-header__spaces-info entera-header__item"]], null, [[null, "createSpace"], [null, "openSpace"], [null, "spaceSettings"], [null, "openInvitationDialog"], [null, "openErrorDialog"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("createSpace" === en)) {
        var pd_0 = (_co.createSpaceHandler() !== false);
        ad = (pd_0 && ad);
    } if (("openSpace" === en)) {
        var pd_1 = (_co.openSpaceHandler($event) !== false);
        ad = (pd_1 && ad);
    } if (("spaceSettings" === en)) {
        var pd_2 = (_co.spaceSettingsHandler($event) !== false);
        ad = (pd_2 && ad);
    } if (("openInvitationDialog" === en)) {
        var pd_3 = (_co.openInvitationDialogHandler() !== false);
        ad = (pd_3 && ad);
    } if (("openErrorDialog" === en)) {
        var pd_4 = (_co.openErrorDialogHandler() !== false);
        ad = (pd_4 && ad);
    } return ad; }, i15.View_SpacesInfoMenuComponent_0, i15.RenderType_SpacesInfoMenuComponent)), i1.ɵdid(1, 114688, null, 0, i16.SpacesInfoMenuComponent, [], { space: [0, "space"], spaces: [1, "spaces"], path: [2, "path"], canInviteInAnySpace: [3, "canInviteInAnySpace"], canCreateSpace: [4, "canCreateSpace"] }, { createSpace: "createSpace", openSpace: "openSpace", spaceSettings: "spaceSettings", openInvitationDialog: "openInvitationDialog", openErrorDialog: "openErrorDialog" }), i1.ɵpid(131072, i17.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.space; var currVal_1 = _co.user.spaces; var currVal_2 = _co.path; var currVal_3 = _co.canInviteInAnySpace; var currVal_4 = i1.ɵunv(_v, 1, 4, i1.ɵnov(_v, 2).transform(_co.canCreateSpace$)); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_EnteraHeaderComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "user-info-menu", [["class", "entera-header__user-info entera-header__item"]], null, [[null, "userAccount"], [null, "logout"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("userAccount" === en)) {
        var pd_0 = (_co.userAccountHandler() !== false);
        ad = (pd_0 && ad);
    } if (("logout" === en)) {
        var pd_1 = (_co.logoutHandler() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i18.View_UserInfoMenuComponent_0, i18.RenderType_UserInfoMenuComponent)), i1.ɵdid(1, 49152, null, 0, i19.UserInfoMenuComponent, [i20.UserService], { user: [0, "user"] }, { userAccount: "userAccount", logout: "logout" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_EnteraHeaderComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "entera-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "fluid-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "entera-header__logo entera-header__centered-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logoClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", ""], ["src", "/assets/images/logo_with_text.svg"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnteraHeaderComponent_1)), i1.ɵdid(6, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnteraHeaderComponent_2)), i1.ɵdid(8, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnteraHeaderComponent_3)), i1.ɵdid(10, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnteraHeaderComponent_4)), i1.ɵdid(12, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnteraHeaderComponent_5)), i1.ɵdid(14, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnteraHeaderComponent_6)), i1.ɵdid(16, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnteraHeaderComponent_7)), i1.ɵdid(18, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showHowToStartBtn; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.space; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.space; _ck(_v, 10, 0, currVal_2); var currVal_3 = !!_co.user; _ck(_v, 12, 0, currVal_3); var currVal_4 = _co.space; _ck(_v, 14, 0, currVal_4); var currVal_5 = (_co.user && _co.showSpacesInfo); _ck(_v, 16, 0, currVal_5); var currVal_6 = _co.user; _ck(_v, 18, 0, currVal_6); }, null); }
export function View_EnteraHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "entera-header", [], null, null, null, View_EnteraHeaderComponent_0, RenderType_EnteraHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i21.EnteraHeaderComponent, [i22.Store], null, null)], null, null); }
var EnteraHeaderComponentNgFactory = i1.ɵccf("entera-header", i21.EnteraHeaderComponent, View_EnteraHeaderComponent_Host_0, { path: "path", user: "user", space: "space", restCreditCount: "restCreditCount", showSpacesInfo: "showSpacesInfo", howToStartButtonVisibility: "howToStartButtonVisibility", canInviteInAnySpace: "canInviteInAnySpace", showUserNotification: "showUserNotification", expiryNotificationType: "expiryNotificationType" }, { logo: "logo", integration: "integration", userAccount: "userAccount", logout: "logout", createSpace: "createSpace", openSpace: "openSpace", spaceSettings: "spaceSettings", openInvitationDialog: "openInvitationDialog", openErrorDialog: "openErrorDialog", spaceBuy: "spaceBuy", callBack: "callBack", knowledgeBase: "knowledgeBase", howToStart: "howToStart", buyPages: "buyPages" }, []);
export { EnteraHeaderComponentNgFactory as EnteraHeaderComponentNgFactory };
