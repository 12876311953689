import { ChangeDetectorRef } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Validators } from "@angular/forms";
import { MatDialogRef } from '@angular/material';
import { Store } from "@ngrx/store";
import { select } from "@ngrx/store";
import { Subject } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { filter } from "rxjs/operators";
import { takeUntil } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { withLatestFrom } from "rxjs/operators";
import { skip } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { UserService } from "../../services/user.service";
import { PhoneConfirmDialogInitAction } from "../../store/actions";
import { PhoneConfirmDialogSendSmsAction } from "../../store/actions";
import { PhoneConfirmDialogConfirmCodeAction } from "../../store/actions";
import { phoneConfirmDlgSmsSentSelector } from "../../store/selectors";
import { phoneConfirmDlgSmsSendingSelector } from "../../store/selectors";
import { phoneConfirmDlgSmsSentErrorSelector } from "../../store/selectors";
import { phoneConfirmDlgCodeConfirmSelector } from "../../store/selectors";
import { phoneConfirmDlgCodeConfirmingSelector } from "../../store/selectors";
import { phoneConfirmDlgCodeConfirmErrorSelector } from "../../store/selectors";
/**
 * Компонент диалога подтвержднения телефона.
 */
var PhoneConfirmDlgComponent = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор компонента диалога подтверждения телефона.
     *
     * @param _dialogRef Компонент диалога.
     * @param data Информация о пользователе и действия на удачное завершение подтверждения.
     * @param _cd Севрис проверки изменения в компоненте.
     * @param _store Хранилище.
     * @param _userService Сервсис для работы с пользователем.
     */
    function PhoneConfirmDlgComponent(_dialogRef, data, _cd, _store, _userService) {
        this._dialogRef = _dialogRef;
        this._cd = _cd;
        this._store = _store;
        this._userService = _userService;
        //region Public fields
        /**
         * Контрол ввода телефона.
         */
        this.phoneControl = new FormControl("");
        /**
         * Контрол ввода кода подтверждения.
         */
        this.codeControl = new FormControl("");
        /**
         * Телефон был установлен у пользователя?
         */
        this.phonePreset$ = new BehaviorSubject(false);
        /**
         * Смс отослана хотя бы раз?
         */
        this.smsOnceSent$ = new BehaviorSubject(false);
        /**
         * Таймер обратного отсчета. Излучает готовую строку для представления. Когда таймер заканчиавется излучает null.
         */
        this.timer$ = new BehaviorSubject(null);
        //endregion
        //region Private fields
        /**
         * Сабж остановки подписок. Работает на освнове takeUntil.
         */
        this._stopSubscriptions$ = new Subject();
        /**
         * Флаг блокировки контрола телефона, при отправке кода на проверку.
         */
        this._phoneDisableTag = false;
        this._user = data.user;
        this._successCallback = data.callback;
    }
    //endregion
    //region Hooks
    PhoneConfirmDlgComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._store.dispatch(new PhoneConfirmDialogInitAction());
        this.smsSent$ = this._store
            .pipe(takeUntil(this._stopSubscriptions$), select(phoneConfirmDlgSmsSentSelector), tap(function (success) {
            if (success) {
                _this._startTimer();
            }
        }));
        this.smsSent$
            .pipe(filter(Boolean))
            .subscribe(function () { return _this.smsOnceSent$.next(true); });
        this.smsSending$ = this._store
            .pipe(takeUntil(this._stopSubscriptions$), select(phoneConfirmDlgSmsSendingSelector));
        this.smsSendError$ = this._store
            .pipe(takeUntil(this._stopSubscriptions$), select(phoneConfirmDlgSmsSentErrorSelector));
        this.smsSendError$
            .subscribe(function (error) {
            if (error) {
                setTimeout(function () {
                    _this.phoneControl.enable();
                    _this.phoneControl.setErrors({ 'server': true });
                    _this._cd.markForCheck();
                });
            }
        });
        this.codeConfirming$ = this._store
            .pipe(takeUntil(this._stopSubscriptions$), select(phoneConfirmDlgCodeConfirmingSelector), tap(function (progress) {
            if (progress) {
                if (_this.phoneControl.enabled) {
                    _this._phoneDisableTag = true;
                    _this.phoneControl.disable();
                }
                _this.codeControl.disable();
            }
            else {
                _this.codeControl.enable();
                if (_this._phoneDisableTag) {
                    _this.phoneControl.enable();
                    _this._phoneDisableTag = false;
                }
            }
        }));
        this.codeConfirmError$ = this._store
            .pipe(takeUntil(this._stopSubscriptions$), select(phoneConfirmDlgCodeConfirmErrorSelector));
        this.codeConfirmError$
            .subscribe(function (error) {
            setTimeout(function () {
                if (error) {
                    _this.codeControl.setErrors({ 'server': true });
                }
                else {
                    _this.codeControl.setErrors(null);
                }
                _this._cd.markForCheck();
            });
        });
        this._store
            .pipe(takeUntil(this._stopSubscriptions$), select(phoneConfirmDlgCodeConfirmSelector), filter(Boolean))
            .subscribe(function () {
            _this._dialogRef.close();
        });
        this.timer$
            .pipe(filter(function (value) { return !value; }), skip(1), withLatestFrom(this.codeConfirming$))
            .subscribe(function (_a) {
            var _ = _a[0], codeProgress = _a[1];
            if (!codeProgress) {
                _this.phoneControl.enable();
            }
            else {
                _this._phoneDisableTag = true;
            }
        });
        this.codeControl.setValidators([
            Validators.required,
            Validators.maxLength(4)
        ]);
        if (this._user) {
            if (this._user.phoneConfirm) {
                this._dialogRef.close();
            }
        }
        this.phoneControl.markAsTouched();
        if (this._user.phone) {
            // Вернуть когда сможем починить
            // this.phoneControl.setValue("+77058822859");
            // this.phonePreset$.next(true);
        }
    };
    PhoneConfirmDlgComponent.prototype.ngOnDestroy = function () {
        this._stopSubscriptions$.next();
        this._stopSubscriptions$.complete();
        this.timer$.complete();
        this.phonePreset$.complete();
        this.smsOnceSent$.complete();
    };
    Object.defineProperty(PhoneConfirmDlgComponent.prototype, "countryCode", {
        //endregion
        //region Getters
        /**
         * Возвращает список кодов стран для определения кода номера телефона.
         */
        get: function () {
            if (environment.global) {
                return ["in", "ae"];
            }
            else {
                return ["ru", "kz"];
            }
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Events
    /**
     * Закрытие диалогового окна.
     *
     * @param event Событие клика.
     */
    PhoneConfirmDlgComponent.prototype.cancelHandler = function (event) {
        this._dialogRef.close();
    };
    /**
     * Хендлер отправки СМС.
     *
     */
    PhoneConfirmDlgComponent.prototype.sendSmsHandler = function () {
        if (this.phoneControl.enabled && this.phoneControl.valid) {
            this._store.dispatch(new PhoneConfirmDialogSendSmsAction(this.phoneControl.value));
            this.phoneControl.disable();
        }
    };
    /**
     * Хендлер повторной отправки СМС.
     *
     * @param event Событие клика.
     */
    PhoneConfirmDlgComponent.prototype.sendSmsAgainHandler = function (event) {
        event.stopPropagation();
        this.sendSmsHandler();
    };
    /**
     * Хендлер отправки кода подтверждения.
     */
    PhoneConfirmDlgComponent.prototype.confirmCodeHandler = function () {
        if (this.codeControl.valid) {
            var actionValue = {
                phone: this.phoneControl.value.trim(),
                code: this.codeControl.value.trim(),
                callback: this._successCallback,
            };
            this._store.dispatch(new PhoneConfirmDialogConfirmCodeAction(actionValue));
        }
    };
    /**
     * Хендлер отправки телефона по клавише enter.
     *
     * @param $event
     */
    PhoneConfirmDlgComponent.prototype.sendSmsByEnterHandler = function ($event) {
        if ($event.key == "Enter") {
            this.sendSmsHandler();
        }
    };
    /**
     * Хендлер подтверждения кода по клавише enter.
     *
     * @param $event
     */
    PhoneConfirmDlgComponent.prototype.confirmCodeByEnterHandler = function ($event) {
        if ($event.key == "Enter") {
            this.confirmCodeHandler();
        }
    };
    //endregion
    //region Private
    /**
     * Функция запуска таймера.
     */
    PhoneConfirmDlgComponent.prototype._startTimer = function () {
        var _this = this;
        var count = 30;
        var seconds = ("0" + count).slice(-2);
        this.timer$.next("0:" + seconds);
        var interval = setInterval(function () {
            count--;
            if (count < 0) {
                _this.timer$.next(null);
                clearInterval(interval);
            }
            else {
                var seconds_1 = ("0" + count).slice(-2);
                _this.timer$.next("0:" + seconds_1);
            }
        }, 1000);
    };
    return PhoneConfirmDlgComponent;
}());
export { PhoneConfirmDlgComponent };
