<div *ngIf="loadingDocs$ | async">
    <h2 mat-dialog-title class="mat-h2 documents-integration-dlg__title">
        <span *ngIf="loadingDocs$ | async">
            {{'spaces.settings.tabs.integrations.dialog.title' | translate}}
        </span>
    </h2>
    <mat-spinner [diameter]="80" class="documents-integration-dlg__mat">
    </mat-spinner>
</div>
<div
    *ngIf="!(loadingDocs$ | async)"
    class="documents-integration-dlg"
>
    <h2 mat-dialog-title class="mat-h2 documents-integration-dlg__title">
        <span *ngIf="okState$ | async">
            {{'spaces.settings.tabs.integrations.dialog.title' | translate}}
        </span>
        <span *ngIf="error$ | async"
        >
            {{'spaces.settings.tabs.integrations.dialog.errorTitle' | translate}}
        </span>
    </h2>
    <p mat-dialog-content
       class="documents-integration-dlg__text"
    >
        <span *ngIf="okState$ | async">{{'spaces.settings.tabs.integrations.dialog.successText' | translate}}</span>
        <span *ngIf="error$ | async" class="documents-integration-dlg__row"><!--
        -->{{
                'spaces.settings.tabs.integrations.dialog.errorText' | translate:
                {
                        id: (error$ | async).errorId || "-",
                        code: (error$ | async).errorCode || "-",
                        message: (error$ | async).errorMessage || "-"
                }
            }}
        </span>
    </p>
    <div class="documents-integration-dlg__buttons" mat-dialog-actions>
        <button class="documents-integration-dlg__btn"
                mat-raised-button
                matDialogClose
        >
            <span *ngIf="!(okState$ | async)">
                {{ "button.close" | translate }}
            </span>
        </button>

        <button
            *ngIf="okState$ | async"
            class="documents-integration-dlg__btn"
            mat-raised-button
            matDialogClose
            color="primary"
        >
            {{ "button.close" | translate }}
        </button>

    </div>
</div>
