<div class="mobile-document-upload-file-row">
    <div class="mobile-document-upload-file-row__file-name">
        {{fileWithMetaData.file.name}}
    </div>
    <div class="mobile-document-upload-file-row__file-size">
        {{fileWithMetaData.file.size | bytes: 2 }}
    </div>
    <button
        mat-button
        class="mobile-document-upload-file-row__remove-button mat-error"
        [disabled]="loading"
        (click)="removeFile(fileWithMetaData.file)"
    >
        <mat-icon >highlight_off</mat-icon>
    </button>
</div>
