var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';
import { RouterGoAction } from 'src/app/root/store/actions';
import { routeStateSelector } from '../../root/store/selectors/router.selector';
import { environment } from "../../../environments/environment";
import { UtilsService } from "./utils.service";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "./utils.service";
import * as i3 from "@angular/common";
/**
 * Сервис, связанный с логикой перемещения по URL'ам внутри приложения.
 */
var RouteService = /** @class */ (function () {
    //endregion
    //region Ctor
    function RouteService(_store, _utilsService, _document) {
        var _this = this;
        this._store = _store;
        this._utilsService = _utilsService;
        this._document = _document;
        //region Private fields
        /**
         * Состояние URL'а.
         */
        this._routeState = null;
        // Следим за состоянием URL'а.
        this._store
            .pipe(select(routeStateSelector))
            .subscribe(function (routeState) { return _this._routeState = routeState; });
    }
    //endregion
    //region Public
    /**
     * Возвращает данные начального URL'а для пользователя.
     *
     * Если это мобильное устройство, то будет старница для мобильного устройства.
     *
     * Иначе:
     *
     * URL будет вести на страницу с их списком.
     *
     * @param user Данные текущего пользователя.
     */
    RouteService.prototype.getUserSpacesInitRoute = function (user) {
        var path = [];
        if (this._utilsService.isMobileBrowser()) {
            path.push("mobile");
        }
        else {
            path.push("spaces");
        }
        return { path: path };
    };
    /**
     * Выполняет переход на форму логина с сохранением обратной ссылки, по которой хотел пройти пользователь.
     */
    RouteService.prototype.goToLogin = function () {
        var redirectTo;
        if (this._routeState === null) {
            redirectTo = encodeURIComponent("" + this._document.location.href);
        }
        else {
            redirectTo = encodeURIComponent("" + this._document.location.origin + this._routeState.url);
        }
        // TODO Хардкод URL
        this._document.location.href = environment.authServerUrl + "/login?redirectTo=" + redirectTo;
    };
    /**
     * Выполняет переход на страницу, сообщающую пользователю, что у него нет прав на просмотр текущей страницы.
     *
     * Переход на страницу выполняется двумя возможными путями:
     * - жёсткая подстановка в location
     * - навигация внутри Angular приложения
     *
     * Причина тут в том, что если было выполнено открытие страницы сразу вводом в строку браузера, и guard,
     * который защищает URL привёл к вызову этого метода, то на этот момент редирект средствами Angular не
     * отработает. Нужен жёсткий редирект подстановкой в location. Но это нормально, т.к. для пользователя всё
     * это выглядит как единый процесс загрузки страницы.
     *
     * Если же метод был вызван guard'ом, который отработал в процессе обычной навигации в Angular, то можно делать
     * редирект средствами Angular.
     *
     * @param path URL страницы, для просмотра которой не хватает прав.
     */
    RouteService.prototype.goToForbidden = function (path) {
        if (!path.startsWith('/')) {
            path = "/" + path;
        }
        // Иногда guard не получает полного URL, на который пытается зайти пользователь (см. описание в 
        // PermissionGuard). Тогда надо проверить, что возможно полный URL можно получить через location.
        var documentPath = this._document.location.pathname;
        if (documentPath.startsWith(path) && documentPath !== path) {
            path = this._document.location.pathname;
        }
        if (this._routeState) {
            // TODO Хардкод URL'а.
            var goAction = { path: ['forbidden'] };
            if (path) {
                goAction = __assign({}, goAction, { query: { path: path } });
            }
            this._store.dispatch(new RouterGoAction(goAction));
        }
        else {
            // TODO Хардкод URL'а.
            this._document.location.href = '/forbidden' + (path ? "?path=" + path : '');
        }
    };
    RouteService.ngInjectableDef = i0.defineInjectable({ factory: function RouteService_Factory() { return new RouteService(i0.inject(i1.Store), i0.inject(i2.UtilsService), i0.inject(i3.DOCUMENT)); }, token: RouteService, providedIn: "root" });
    return RouteService;
}());
export { RouteService };
