import { SelectedSpaceForMobileActionType } from "src/app/root/store/actions/selected-space-for-mobile.action";
import { SelectedSpaceForMobileAction } from "src/app/root/store/actions/selected-space-for-mobile.action";
import { SelectedSpaceForMobileState } from "src/app/root/store/states/selected-space-for-mobile.state";

/**
 * Начальное состояние для выбранного пространства документов, которое относится к мобильной версии.
 */
const initialState: SelectedSpaceForMobileState = {

    // Изначально пространство документов, которое относится к мобильной версии не выбрано.
    space: null,
};

/**
 * Обработчик событий, связанных с выбором пространства документов, в контексте которого происходит работа в случае,
 * когда используется мобильная версия UI.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 */
export function selectedSpaceForMobileReducer(
    state: SelectedSpaceForMobileState = initialState,
    action: SelectedSpaceForMobileAction
): SelectedSpaceForMobileState {

    let result: SelectedSpaceForMobileState = state;

    switch (action.type) {

        case SelectedSpaceForMobileActionType.SET: {

            result = {
                ...state,
                space: action.value,
            };
            break;
        }
    }

    return result;
}
