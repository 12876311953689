import { Router } from "@angular/router";
import { select } from "@ngrx/store";
import { Store } from "@ngrx/store";
import { map } from "rxjs/operators";
import { UrlUtils } from "src/app/common/utils/url.utils";
import { SelectedSpaceForMobileSelectors } from "src/app/root/store/selectors/selected-space-for-mobile.selector";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@angular/router";
/**
 * Route Guard.
 * Проверка наличия выбранного пространства документов для мобильного интерфейса.
 */
var SelectedSpaceForMobileGuard = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор Route Guard'а, который выполняет проверку наличия выбранного пространства документов для мобильного
     * интерфейса.
     *
     * @param store Сервис для управления и доступа к состоянию приложения.
     * @param router Сервис для работы с навигацией по приложению.
     */
    function SelectedSpaceForMobileGuard(store, router) {
        this._store = store;
        this._router = router;
    }
    //endregion
    //region Public
    /**
     * Можно ли осуществлять переход на страницу сканирования QR кода чека?
     *
     * Если отсутствует выбранное пространство документов, то выполнится редирект на корневую страницу мобильного UI.
     */
    SelectedSpaceForMobileGuard.prototype.canActivate = function () {
        var _this = this;
        return this._store.pipe(select(SelectedSpaceForMobileSelectors.space), map(function (space) {
            if (!!space) {
                return true;
            }
            else {
                return _this._router.parseUrl(UrlUtils.mobilePageUrl());
            }
        }));
    };
    SelectedSpaceForMobileGuard.ngInjectableDef = i0.defineInjectable({ factory: function SelectedSpaceForMobileGuard_Factory() { return new SelectedSpaceForMobileGuard(i0.inject(i1.Store), i0.inject(i2.Router)); }, token: SelectedSpaceForMobileGuard, providedIn: "root" });
    return SelectedSpaceForMobileGuard;
}());
export { SelectedSpaceForMobileGuard };
