var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EventEmitter } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { spaceSelector } from "src/app/root/store/index";
import { UploadToRecognizeState } from "src/app/root/store/index";
import { currentUserPermissionsSelector } from 'src/app/root/store/index';
import { UtilsService } from "src/app/common/services/index";
/**
 * Компонент диалога для загрузки файлов на распознавание.
 */
var UploadBankStatementDialogComponent = /** @class */ (function () {
    //endregion
    //region Ctor
    function UploadBankStatementDialogComponent(store, dialogRef, data, utils) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.utils = utils;
        //endregion
        //region Outputs
        /**
         * Исходящее событие - создание задач на распознавание.
         */
        this.upload = new EventEmitter();
        /**
         * Исходящее событие - добвление файла на распознавание.
         */
        this.add = new EventEmitter();
        /**
         * Исходящее событие - удаление файла из задачи.
         */
        this.remove = new EventEmitter();
        /**
         * Переключатель, чтобы при выполнении onDestroy произошли отписки от всех подписок.
         *
         * @private
         */
        this._destroyed = new Subject();
        this._store = store;
    }
    Object.defineProperty(UploadBankStatementDialogComponent.prototype, "uploadState", {
        //endregion
        //region Getters and Setters
        /**
         * Состояние данных для создания задач на распознавание.
         */
        get: function () {
            return this._uploadState;
        },
        //region Inputs
        /**
         *  Входящие данные - состояние данных для создания задач на распознавание.
         */
        set: function (value) {
            this._uploadState = value;
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Hooks
    UploadBankStatementDialogComponent.prototype.ngOnInit = function () {
        // Следим за правами пользователя.
        this.permissions$ = this._store.pipe(select(currentUserPermissionsSelector));
        this.currentSpace$ = this._store.pipe(select(spaceSelector));
    };
    UploadBankStatementDialogComponent.prototype.ngOnDestroy = function () {
        this._destroyed.next();
        this._destroyed.complete();
    };
    //endregion
    //region Events
    /**
     * Обработчик добавления файлов. Фильтрует файлы которые уже есть в состоянии.
     *
     * @param event Файлы, которые нужно добавить.
     */
    UploadBankStatementDialogComponent.prototype.addFilesHandler = function (event) {
        var _this = this;
        var newFiles = event.filter(function (fileToAdd) { return _this._uploadState.filesWithMetaData.every(function (file) {
            return file.file.name !== fileToAdd.name
                || file.file.size !== fileToAdd.size
                || file.file.lastModified !== fileToAdd.lastModified;
        }); });
        if (newFiles.length) {
            this.add.emit(newFiles);
        }
    };
    /**
     * Закрытие дилога.
     */
    UploadBankStatementDialogComponent.prototype.closeButtonClickHandler = function () {
        if (!this.uploadState.loading) {
            this.dialogRef.close();
        }
    };
    /**
     * Обрабатывает событие нажатия кнопки отправки на сервер.
     */
    UploadBankStatementDialogComponent.prototype.sendButtonClickHandler = function () {
        this.upload.emit(__assign({}, this.uploadState, { bankStatement: true }));
    };
    return UploadBankStatementDialogComponent;
}());
export { UploadBankStatementDialogComponent };
