import { createSelector } from "@ngrx/store";
import { RecognitionTask } from "src/app/common/models";
import { recognitionTasksStateSelector } from "../../reducers";
import { getTasksLoaded } from "../../reducers/recognize";
import { getLastError, getLastTask, getTasks } from "../../reducers/recognize";
import { getRecognizedDocuments } from "../../reducers/recognize";
import { getInitialDocuments } from "../../reducers/recognize";

/**
 * Селектор объекта, содержащего в себе выполняющиеся в данный момент задачи на распознавание.
 */
export const recognitionTasksSelector = createSelector(recognitionTasksStateSelector, getTasks);

/**
 * Селектор массива выполняющихся в данный момент задач на распознавание.
 */
export const recognitionTasksArraySelector = createSelector(
    recognitionTasksSelector, 
    (tasks: { [id: string]: RecognitionTask }) => tasks ? Object.keys(tasks).map(key => tasks[key]) : []
);

/**
 * Селектор количества выполняющихся в данный момент задач на распознавание.
 */
export const recognitionTasksCountSelector = createSelector(recognitionTasksArraySelector, (tasks) => tasks.length);

/**
 * Селектор последнего результата успешного выполнения задачи на распознавание.
 */
export const lastFinishedRecognitionTaskSelector = createSelector(recognitionTasksStateSelector, getLastTask);

/**
 * Селектор последнего результата неудачного выполнения задачи на распознавание.
 */
export const lastErrorTaskSelector = createSelector(recognitionTasksStateSelector, getLastError);

/**
 * Селектор распознанных документов.
 */
export const recognizedDocumentsSelector = createSelector(recognitionTasksStateSelector, getRecognizedDocuments);

/**
 * Селектор изначальных документов.
 */
export const initialDocumentsSelector = createSelector(recognitionTasksStateSelector, getInitialDocuments);

/**
 * Селектор флага успешного выполнения загрузки задач на распознавание из состояния задач на распознавание.
 */
export const recognitionTasksStateLoaded = createSelector(recognitionTasksStateSelector, getTasksLoaded);
