var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { BroadcastMessagesDlgActionType } from "src/app/root/store/actions/broadcast-message-dlg.action";
/**
 * Начальное состояние диалога сообщений пользователям.
 */
var initialState = {
    broadcastMessages: [],
    currentMessageIndex: 0,
    sendingResponse: false,
    sendingResponseSuccess: false,
    sendingResponseError: null,
};
/**
 * Обработка событий, связанных с состоянием диалога сообщений для пользователей.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 *
 * @return Состояние диалога сообщения пользователям.
 */
export function broadcastMessageDlgReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        case BroadcastMessagesDlgActionType.OPEN: {
            var broadcastMessages = action.value.map(function (msg) {
                return (__assign({}, msg, { dontShowAgain: false }));
            });
            result = __assign({}, state, { broadcastMessages: broadcastMessages });
            break;
        }
        case BroadcastMessagesDlgActionType.REMIND_LATER_CLOSE: {
            var newMessageIndex = state.currentMessageIndex;
            if (newMessageIndex > 0 && newMessageIndex === (state.broadcastMessages.length - 1)) {
                newMessageIndex--;
            }
            var broadcastMessages = state.broadcastMessages
                .filter(function (message) { return message.id !== action.message.id; });
            result = __assign({}, state, { broadcastMessages: broadcastMessages, currentMessageIndex: newMessageIndex });
            break;
        }
        case BroadcastMessagesDlgActionType.TOGGLE_DONT_SHOW_AGAIN_FLAG: {
            var broadcastMessages = state.broadcastMessages
                .map(function (message) {
                if (message.id === action.message.id) {
                    return __assign({}, message, { dontShowAgain: !message.dontShowAgain });
                }
                else {
                    return message;
                }
            });
            result = __assign({}, state, { broadcastMessages: broadcastMessages });
            break;
        }
        case BroadcastMessagesDlgActionType.SELECT_PREVIOUS: {
            var newIndex = state.currentMessageIndex - 1;
            if (newIndex < 0) {
                newIndex = state.broadcastMessages.length - 1;
            }
            result = __assign({}, state, { currentMessageIndex: newIndex, sendingResponseError: null });
            break;
        }
        case BroadcastMessagesDlgActionType.SELECT_NEXT: {
            var newIndex = state.currentMessageIndex + 1;
            if (newIndex === state.broadcastMessages.length) {
                newIndex = 0;
            }
            result = __assign({}, state, { currentMessageIndex: newIndex, sendingResponseError: null });
            break;
        }
        case BroadcastMessagesDlgActionType.SEND_RESPONSE: {
            result = __assign({}, state, { sendingResponse: true, sendingResponseError: null, sendingResponseSuccess: false });
            break;
        }
        case BroadcastMessagesDlgActionType.SEND_RESPONSE_SUCCESS: {
            var newMessageIndex = state.currentMessageIndex;
            if (state.currentMessageIndex === (state.broadcastMessages.length - 1)) {
                newMessageIndex--;
            }
            result = __assign({}, state, { sendingResponse: false, sendingResponseError: null, sendingResponseSuccess: true, broadcastMessages: state.broadcastMessages.filter(function (broadcastMessage) {
                    return broadcastMessage !== state.broadcastMessages[state.currentMessageIndex];
                }), currentMessageIndex: newMessageIndex });
            break;
        }
        case BroadcastMessagesDlgActionType.SEND_RESPONSE_FAIL: {
            result = __assign({}, state, { sendingResponse: false, sendingResponseError: action.error, sendingResponseSuccess: false });
            break;
        }
    }
    return result;
}
