import { Action } from '@ngrx/store';

import { ApiResponsePayloadAction } from 'src/app/root/store';
import { ApiResponse } from 'src/app/common/models/api-response';
import { DocumentCountActionProps } from "src/app/spaces/modules/documents-registry/store/actions/props/document-count-action.props";

/**
 * Типы событий, связанные с кол-вом документов, подходящих под фильтр реестра документов.
 */
export enum DocumentsCountActionType {
    LOAD = '[Documents registry] Load documents count',
    LOAD_SUCCESS = '[Documents registry] Load documents count Success',
    LOAD_FAIL = '[Documents registry] Load documents count Fail',
    DECREMENT = '[Documents registry] Decrement documents count',
}

/**
 * Событие, требующее загрузки кол-ва документов, подходящих под фильтр реестра документов.
 */
export class DocumentsCountLoadAction implements Action {
    readonly type = DocumentsCountActionType.LOAD;
    // TODO Определить интерфейс запроса кол-ва документов, подходящих под фильтр реестра документов.
    constructor(public payload: DocumentCountActionProps) { }
}

/**
 * Событие успешной загрузки кол-ва документов, подходящих под фильтр реестра документов.
 */
export class DocumentsCountLoadSuccessAction implements Action {
    readonly type = DocumentsCountActionType.LOAD_SUCCESS;
    constructor(public payload: number) { }
}

/**
 * Событие неудачной загрузки кол-ва документов, подходящих под фильтр реестра документов.
 */
export class DocumentsCountLoadFailAction implements ApiResponsePayloadAction {
    readonly type = DocumentsCountActionType.LOAD_FAIL;
    constructor(public payload: ApiResponse) { }
}

/**
 * Событие уменьшения кол-ва документов, подходящих под фильтр реестра документов.
 */
export class DocumentsCountDecrementAction implements Action {
    readonly type = DocumentsCountActionType.DECREMENT;
    constructor(public documentsNumber: number) { }
}

/**
 * Тип, объединяющий все события, связанные с кол-вом документов, подходящих под фильтр реестра документов.
 */
export type DocumentsCountAction =
    | DocumentsCountLoadAction
    | DocumentsCountLoadFailAction
    | DocumentsCountLoadSuccessAction
    | DocumentsCountDecrementAction;
