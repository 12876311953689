import { Store } from "@ngrx/store";
import { tap } from "rxjs/operators";
import { map } from "rxjs/operators";
import { ConfigurationService } from "src/app/common/services/configuration.service";
import { LangChangeAction } from "src/app/root/store/index";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "../../common/services/configuration.service";
/**
 * Route Guard для страниц без пользователя.
 * - Проверяет флаг global в окружении. Если он true, то выставляет английский язык. Иначе - русский.
 */
var NoUserGuard = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор Route Guard-а.
     *
     * @param _store Сервис для управления и доступа к состоянию приложения.
     * @param configService Сервис по работе с конфигурациями окружения приложения.
     */
    function NoUserGuard(_store, configService) {
        this._store = _store;
        this._configService = configService;
    }
    //endregion
    //region Public
    /**
     * Проверяет, можно ли осуществлять переход по текущему URL.
     *
     * - В данном случае, переход возможен всегда. Задача гуарда - преобразование данных.
     * - Проверяет флаг global в окружении. Если он true, то выставляет английский язык. Иначе - русский.
     *
     * @return Да/нет.
     */
    NoUserGuard.prototype.canActivate = function () {
        var _this = this;
        return this._configService.loadConfigurations()
            .pipe(map(function (env) { return env.global ? "en" : "ru"; }), tap(function (lang) { return _this._store.dispatch(new LangChangeAction(lang)); }), map(function () { return true; }));
    };
    NoUserGuard.ngInjectableDef = i0.defineInjectable({ factory: function NoUserGuard_Factory() { return new NoUserGuard(i0.inject(i1.Store), i0.inject(i2.ConfigurationService)); }, token: NoUserGuard, providedIn: "root" });
    return NoUserGuard;
}());
export { NoUserGuard };
