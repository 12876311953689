import { FormControl } from "@angular/forms";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { of } from "rxjs";
import { throwError } from "rxjs";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { shareReplay } from "rxjs/operators";
import { startWith } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import { AdminService } from "src/app/admin/services/admin.service";
import { PermissionItem } from "src/app/common/models/permission-item";
import { moveDocumentsDlgActions } from "src/app/common/store/actions/move-documents-dlg.action";
import { moveDocumentsDlgSelectors } from "src/app/common/store/selectors/move-documents-dlg.selectors";
/**
 * Компонент диалога перемещения документов в другую папку.
 */
var MoveDocumentsDlgComponent = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * <p>Конструктор компонента создания диалога перемещения документов в другую папку.</p>
     *
     * @param store Сервис для управления и доступа к состоянию приложения.
     * @param adminService Сервис для выполнения административных задач.
     * @param data Данные для диалога.
     * @param translateService Сервис для работы с i18n-сообщениями.
     */
    function MoveDocumentsDlgComponent(store, adminService, data, translateService) {
        var _this = this;
        /**
         * Контрол элементов формы для поиска клиента.
         */
        this.clientSelectControl = new FormControl();
        /**
         * Контрол элементов формы для поиска папки.
         */
        this.spaceSelectControl = new FormControl();
        /**
         * Контрол элементов формы для поиска пользователя.
         */
        this.userSelectControl = new FormControl();
        /**
         * Объект глобальной отписки.
         */
        this._globalUnsubscribe$ = new Subject();
        //endregion
        //region Public
        /**
         * Поиск пользователя.
         *
         * @param search Строка поиска.
         * @param page Страница списка.
         */
        this.findClientsByEmailFn = function (search, page) {
            return _this._adminService.findClientsByEmail({ search: search, page: page })
                .pipe(map(function (clients) { return clients; }), catchError(function (error) {
                return throwError(error);
            }));
        };
        this._store = store;
        this._translateService = translateService;
        this.movingDocs$ = this._store.select(moveDocumentsDlgSelectors.documentsMoveToAnotherSpaceSelector);
        this.okState$ = this._store.select(moveDocumentsDlgSelectors.documentsMoveToAnotherSpaceSuccessSelector);
        this.error$ = this._store.select(moveDocumentsDlgSelectors.documentsMoveToAnotherSpaceFailedSelector);
        this.error$.pipe(takeUntil(this._globalUnsubscribe$))
            .subscribe(function (error) {
            _this.error = error;
        });
        this._adminService = adminService;
        this.selectedDocuments = data.documents;
        this.currentUser = data.user;
        this.filter = data.filter;
        this.excludedDocuments = data.excludedDocuments;
        this.adminView = this.currentUser.permissions.some(function (perm) {
            return perm === PermissionItem.MOVE_DOCUMENTS.value;
        });
        this.spaceList$ = of([]).pipe(switchMap(function () {
            if (_this.adminView) {
                return _this.clientSelectControl.valueChanges
                    .pipe(tap(function () {
                    _this.spaceSelectControl.reset();
                    _this.userSelectControl.reset();
                }), map(function (client) { return (client && client.spaces) || []; }));
            }
            else {
                return of((_this.currentUser && _this.currentUser.spaces) || []);
            }
        }), startWith([]), shareReplay(1));
        this.userList$ = this.spaceSelectControl.valueChanges
            .pipe(tap(function () {
            _this.userSelectControl.reset();
        }), map(function (space) { return (space && space.userList) || []; }), map(function (userList) {
            return userList.map(function (user) { return ({ id: user.id, name: user.email }); });
        }), startWith([]), shareReplay(1));
    }
    //endregion
    //region Hooks
    /**
     * Логика, выполняющаяся при инициализации компоненты.
     */
    MoveDocumentsDlgComponent.prototype.ngOnInit = function () {
        this._store.dispatch(moveDocumentsDlgActions.moveDocumentsDlgInitialize());
    };
    /**
     * Логика, выполняющаяся при уничтожении компоненты.
     */
    MoveDocumentsDlgComponent.prototype.ngOnDestroy = function () {
        this._resetControls();
        this._globalUnsubscribe$.complete();
    };
    Object.defineProperty(MoveDocumentsDlgComponent.prototype, "validControls", {
        //endregion
        //region Getters
        /**
         * Возвращает валидность всех контролов.
         */
        get: function () {
            return this.clientSelectControl.valid && this.spaceSelectControl.valid && this.userSelectControl.valid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MoveDocumentsDlgComponent.prototype, "errorText", {
        /**
         * Возвращает текст ошибки переноса документа для отображения в диалоге.
         */
        get: function () {
            return this._translateService.instant("spaces.moveDocumentDialog.errorText", {
                id: (this.error && this.error.errorId) || "-",
                code: (this.error && this.error.errorCode) || "-",
                message: (this.error && this.error.errorMessage) || "-",
            });
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Возвращает отформатированное предстление email-а клиента.
     *
     * Если есть email, по которому производился поиск, то возвращает его, иначе email создателя пространства
     * документов. Если и его нет, то возвращает строку "N/A".
     *
     * @param option Информация о клиенте для администратора.
     *
     * @return Отформатированное представление email-а клиента.
     */
    MoveDocumentsDlgComponent.prototype.formatClientEmail = function (option) {
        var client = option;
        return client.searchEmail || client.creatorEmail || "N/A";
    };
    //endregion
    //region Events
    /**
     * Обработка события нажатия по кнопке "Перенести". Испускает событие перемещения документа.
     */
    MoveDocumentsDlgComponent.prototype.moveDocuments = function () {
        var props = {
            filter: this.filter,
            excludedDocuments: this.excludedDocuments,
            toSpaceId: this.spaceSelectControl.value ? this.spaceSelectControl.value.id : null,
            documents: this.selectedDocuments,
            creatorId: this.userSelectControl.value ? this.userSelectControl.value.id : this.currentUser.id,
            initiator: this.currentUser,
        };
        this._store.dispatch(moveDocumentsDlgActions.moveDocumentsToAnotherSpace(props));
    };
    //endregion
    //region Private
    /**
     * Сбрасывает состояния контролов.
     */
    MoveDocumentsDlgComponent.prototype._resetControls = function () {
        this.userSelectControl.reset();
        this.spaceSelectControl.reset();
        this.clientSelectControl.reset();
    };
    return MoveDocumentsDlgComponent;
}());
export { MoveDocumentsDlgComponent };
