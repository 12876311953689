import { OnDestroy } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { Output } from "@angular/core";
import { Input } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { Space } from "src/app/common/models/space";
import { TranslateService } from "@ngx-translate/core";
import { UtilsService } from "src/app/common/services";

/**
 * Компонент выпадашки для выбора пространства документов.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "mobile-space-select",
    styleUrls: ["./mobile-space-select.component.scss"],
    templateUrl: "./mobile-space-select.component.html"
})
export class MobileSpaceSelectComponent implements OnDestroy {
    //region Constants

    /**
     * I18n ключ метки для выпадашки пространства документов при наличии выбранного пространства документов.
     */
    private static readonly _SELECTED_LABEL_I18N: string = "user.space.selector.selectedLabel";

    /**
     * I18n ключ метки для выпадашки пространства документов при отсутствии выбранного пространства
     * документов.
     */
    private static readonly _UNSELECTED_LABEL_I18N: string = "user.space.selector.unselectedLabel";

    /**
     * Значение пространства документов, когда в выпадашке ничего не выбрано.
     */
    private static readonly _EMPTY_SPACE_VALUE: string = "";

    //endregion
    //region Inputs

    /**
     * Пространства документов.
     *
     * При изменении массива пространств документов учитываются варианты, что текущее выбранное значение может
     * отсутствовать в новом массиве пространств документов. Также учитывается случай, когда новый массив пространств
     * документов содержит только один элемент, который сразу выбирается.
     *
     */
    @Input()
    set spaces(value: Space[]) {

        this._spaces = value;

        const selectedSpace: Space = this.valueControl.value;
        let foundSpace: Space = null;
        if (selectedSpace) {

            foundSpace = this._spaces.filter((space: Space): boolean => space.id === selectedSpace.id)[0];
        }

        if (this.spaces.length === 1) {

            if (!foundSpace || selectedSpace !== foundSpace) {

                this.valueControl.setValue(this.spaces[0]);
            }
        }
        else if (foundSpace) {

            if (selectedSpace !== foundSpace) {

                this.valueControl.setValue(foundSpace);
            }
        }
        else if (selectedSpace) {

            this.valueControl.setValue(this.emptySpaceValue);
        }
    }

    //endregion
    //region Outputs

    /**
     * Событие выбора пространства документов.
     */
    @Output()
    readonly select: EventEmitter<Space>;

    //endregion
    //region Fields

    /**
     * FormControl, привязанный к выпадашке.
     */
    readonly valueControl: FormControl;

    /**
     * В выпадашке выбрано какое-нибудь пространство документов?
     */
    private _isSpaceSelected: boolean;

    /**
     * Подписка на изменения выбранного значения выпадашки.
     */
    private _valueControlSubscription: Subscription;

    /**
     * Пространства документов.
     */
    private _spaces: Space[];

    //endregion
    //region Ctor

    /**
     * Конструктор компонента выпадашки для выбора пространства документов.
     */
    constructor(private _translateService: TranslateService, private _util: UtilsService) {

        this.select = new EventEmitter<Space>();
        this.valueControl = new FormControl(this.emptySpaceValue);
        this._valueControlSubscription = this.valueControl.valueChanges
            .subscribe((space: Space): void => {

                if (space) {

                    this.select.emit(space);
                }
                else {

                    this.select.emit(null);
                }

                this._isSpaceSelected = !!space;
            });
        this._isSpaceSelected = false;
    }

    //endregion
    //region Hooks

    /**
     * Выполняет логику при уничтожении компонента.
     */
    ngOnDestroy(): void {

        this._valueControlSubscription.unsubscribe();
    }

    //endregion
    //region Getters and Setters

    /**
     * I18n ключ метки для выпадашки пространства документов.
     */
    get selectorLabel(): string {

        let label: string;

        if (this._isSpaceSelected) {

            label = MobileSpaceSelectComponent._SELECTED_LABEL_I18N;
        }
        else {

            label = MobileSpaceSelectComponent._UNSELECTED_LABEL_I18N;
        }

        return label;
    }

    /**
     * Пространства документов.
     */
    get spaces(): Space[] {

        return this._spaces;
    }

    /**
     * Значение пространства документов, когда в выпадашке ничего не выбрано.
     */
    get emptySpaceValue(): string {

        return MobileSpaceSelectComponent._EMPTY_SPACE_VALUE;
    }

    //endregion
    //region Public

    /**
     * Возвращает имя и баланс папки.
     *
     * @param space Папка.
     *
     * @return Имя и баланс папки.
     */
    getSpaceNameAndBalance(space: Space): string {

        let result: string;

        if (space.unlimitedBalance) {

            let date: string;

            if (space.foreign) {

                date = this._util.getISODate(space.balanceExpirationDate);
            }
            else {

                date = this._util.formatDate(space.balanceExpirationDate, this._translateService.getDefaultLang());
            }

            result = this._translateService.instant(
                "user.space.selector.optionUnlimited",
                {
                    spaceName: space.name,
                    expirationDate: date,
                }
            );
        }
        else {

            result = this._translateService.instant(
                "user.space.selector.option",
                {
                    spaceName: space.name,
                    spaceBalance: space.balance,
                }
            );
        }

        return result;
    }

    //endregion
}
