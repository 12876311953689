var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DocumentsCountActionType } from "../actions";
/**
 * Начальное состояние кол-ва документов, подходящих под фильтры реестра.
 */
var initialState = {
    /**
     * Кол-во документов не загружается.
     */
    loading: false,
    /**
     * Кол-во документов не загружено.
     */
    loaded: false,
    /**
     * Загрузка кол-ва документов, подходящих под фильтры реестра, ошибкой не завершалась.
     */
    failed: false,
    /**
     * Кол-ва документов, подходящих под фильтры реестра, нет.
     */
    documentsCount: undefined,
};
/**
 * Обработчик событий, связанных с кол-вом документов, подходящих под фильтры реестра.
 *
 * @param state Состояние кол-ва документов, подходящих под фильтры реестра.
 * @param action Событие произошедшее в системе.
 */
export function documentsCountReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        // Требование загрузить кол-во документов, подходящих под фильтры реестра.
        case DocumentsCountActionType.LOAD: {
            return __assign({}, state, { loading: true, loaded: false, failed: false });
        }
        // Уведомление об успешной загрузке кол-ва документов, подходящих под фильтры реестра.
        case DocumentsCountActionType.LOAD_SUCCESS: {
            var documentsCount = action.payload;
            return __assign({}, state, { loading: false, loaded: true, documentsCount: documentsCount });
        }
        // Уведомление о неудачной попытке загрузки кол-ва документов, подходящих под фильтры реестра.
        case DocumentsCountActionType.LOAD_FAIL: {
            return __assign({}, state, { loading: false, failed: true, documentsCount: undefined });
        }
        // Требование уменьшения количества документов, подходящих под фильтры реестра.
        case DocumentsCountActionType.DECREMENT: {
            var documentsCount = state.documentsCount - action.documentsNumber;
            return __assign({}, state, { documentsCount: documentsCount });
        }
    }
    return state;
}
/**
 * Возвращает кол-во документов из состояния кол-ва документов, подходящих под фильтры реестра.
 *
 * @param state Состояние кол-ва документов, подходящих под фильтры реестра.
 */
export var getDocumentsCount = function (state) { return state.documentsCount; };
/**
 * Возвращает флаг выполняющейся загрузки кол-ва документов из состояния кол-ва документов, подходящих под
 * фильтры реестра.
 *
 * @param state Состояние кол-ва документов, подходящих под фильтры реестра.
 */
export var getDocumentsCountLoading = function (state) { return state.loading; };
/**
 * Возвращает флаг успешно выполненной загрузки кол-ва документов из состояния кол-ва документов, подходящих под
 * фильтры реестра.
 *
 * @param state Состояние кол-ва документов, подходящих под фильтры реестра.
 */
export var getDocumentsCountLoaded = function (state) { return state.loaded; };
/**
 * Возвращает флаг неудачной попытки загрузки кол-ва документов из состояния кол-ва документов, подходящих под
 * фильтры реестра.
 *
 * @param state Состояние кол-ва документов, подходящих под фильтры реестра.
 */
export var getDocumentsCountFailed = function (state) { return state.failed; };
