/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./recognition-task-created-dlg.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/recognition-task-created/recognition-task-created.component.ngfactory";
import * as i3 from "../../components/recognition-task-created/recognition-task-created.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "@angular/material/dialog";
import * as i11 from "@ngx-translate/core";
import * as i12 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i13 from "@angular/material/icon";
import * as i14 from "./recognition-task-created-dlg.component";
import * as i15 from "@ngrx/store";
var styles_RecognitionTaskCreatedDlgComponent = [i0.styles];
var RenderType_RecognitionTaskCreatedDlgComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RecognitionTaskCreatedDlgComponent, data: {} });
export { RenderType_RecognitionTaskCreatedDlgComponent as RenderType_RecognitionTaskCreatedDlgComponent };
export function View_RecognitionTaskCreatedDlgComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "recognition-task-created", [], null, [[null, "applyDialog"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("applyDialog" === en)) {
        var pd_0 = (_co.applyDialog($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_RecognitionTaskCreatedComponent_0, i2.RenderType_RecognitionTaskCreatedComponent)), i1.ɵdid(2, 49152, null, 0, i3.RecognitionTaskCreatedComponent, [], { loading: [0, "loading"], error: [1, "error"], belongsToPaidClient: [2, "belongsToPaidClient"] }, { applyDialog: "applyDialog" }), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 6, "button", [["class", "mat-dialog__cross-close-btn"], ["mat-dialog-close", ""], ["mat-flat-button", ""], ["type", "button"]], [[8, "title", 0], [8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).dialogRef.close(i1.ɵnov(_v, 8).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(7, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(8, 606208, null, 0, i10.MatDialogClose, [[2, i10.MatDialogRef], i1.ElementRef, i10.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i1.ɵpid(131072, i11.TranslatePipe, [i11.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i12.View_MatIcon_0, i12.RenderType_MatIcon)), i1.ɵdid(11, 9158656, null, 0, i13.MatIcon, [i1.ElementRef, i13.MatIconRegistry, [8, null], [2, i13.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.loading$)); var currVal_1 = i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 4).transform(_co.error$)); var currVal_2 = i1.ɵunv(_v, 2, 2, i1.ɵnov(_v, 5).transform(_co.belongsToPaidClient$)); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_7 = ""; _ck(_v, 8, 0, currVal_7); _ck(_v, 11, 0); }, function (_ck, _v) { var currVal_3 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 9).transform("dialogs.close")), ""); var currVal_4 = (i1.ɵnov(_v, 7).disabled || null); var currVal_5 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); var currVal_6 = (i1.ɵnov(_v, 8).ariaLabel || null); _ck(_v, 6, 0, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = i1.ɵnov(_v, 11).inline; var currVal_9 = (((i1.ɵnov(_v, 11).color !== "primary") && (i1.ɵnov(_v, 11).color !== "accent")) && (i1.ɵnov(_v, 11).color !== "warn")); _ck(_v, 10, 0, currVal_8, currVal_9); }); }
export function View_RecognitionTaskCreatedDlgComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "recognition-task-created-dlg", [], null, [["window", "keyup.esc"]], function (_v, en, $event) { var ad = true; if (("window:keyup.esc" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onKeyUp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_RecognitionTaskCreatedDlgComponent_0, RenderType_RecognitionTaskCreatedDlgComponent)), i1.ɵdid(1, 49152, null, 0, i14.RecognitionTaskCreatedDlgComponent, [i10.MAT_DIALOG_DATA, i10.MatDialogRef, i15.Store], null, null)], null, null); }
var RecognitionTaskCreatedDlgComponentNgFactory = i1.ɵccf("recognition-task-created-dlg", i14.RecognitionTaskCreatedDlgComponent, View_RecognitionTaskCreatedDlgComponent_Host_0, {}, {}, []);
export { RecognitionTaskCreatedDlgComponentNgFactory as RecognitionTaskCreatedDlgComponentNgFactory };
