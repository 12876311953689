var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSelector } from "@ngrx/store";
import { spaceSelector } from "rootStore";
import { documentsRegistryStateSelector } from "../reducers";
import { getHiddenNewDocuments } from "../reducers/documents.reducers";
import { getNewDocuments } from "../reducers/documents.reducers";
import { getDocuments, getDocumentsEnded } from "../reducers/documents.reducers";
import { getDocumentsLoading } from "../reducers/documents.reducers";
import { getDocumentsLoaded } from "../reducers/documents.reducers";
import { getDocumentsFailed } from "../reducers/documents.reducers";
import { getDocumentsInfinityLoading } from "../reducers/documents.reducers";
import { getDocumentsInfinityLoaded } from "../reducers/documents.reducers";
import { getDocumentsInfinityFailed } from "../reducers/documents.reducers";
import { getDeletionError } from "../reducers/documents.reducers";
import { documentsCountSelector } from "src/app/spaces/modules/documents-registry/store/selectors/documents-count.selector";
import { recognizedDocumentsSelector } from "rootStore";
/**
 * Селектор состояния документов.
 */
export var documentsStateSelector = createSelector(documentsRegistryStateSelector, function (state) { return state.documentsState; });
/**
 * Селектор документов.
 */
export var documentsSelector = createSelector(documentsStateSelector, getDocuments);
/**
 * Селектор новых документов.
 */
export var newDocumentsSelector = createSelector(documentsStateSelector, getNewDocuments);
/**
 * Селектор флага наличия скрытых фильтром новых документов.
 */
export var hiddenNewDocumentsSelector = createSelector(documentsStateSelector, getHiddenNewDocuments);
/**
 * Селектор флага выполняющейся загрузки документов.
 */
export var documentsLoadingSelector = createSelector(documentsStateSelector, getDocumentsLoading);
/**
 * Селектор флага успешной загрузки документов.
 */
export var documentsLoadedSelector = createSelector(documentsStateSelector, getDocumentsLoaded);
/**
 * Селектор флага неудачной загрузки документов.
 */
export var documentsFailedSelector = createSelector(documentsStateSelector, getDocumentsFailed);
/**
 * Селектор флага выполняющейся догрузки документов.
 */
export var documentsInfinityLoadingSelector = createSelector(documentsStateSelector, getDocumentsInfinityLoading);
/**
 * Селектор флага успешной догрузки документов.
 */
export var documentsInfinityLoadedSelector = createSelector(documentsStateSelector, getDocumentsInfinityLoaded);
/**
 * Селектор флага неудачной догрузки документов.
 */
export var documentsInfinityFailedSelector = createSelector(documentsStateSelector, getDocumentsInfinityFailed);
/**
 * Селектор флага полной загрузки документов из состояния документов.
 */
export var documentsEndedSelector = createSelector(documentsStateSelector, getDocumentsEnded);
/**
 * Селектор ответа от API об ошибке при удалении.
 */
export var documentsDeletionErrorSelector = createSelector(documentsStateSelector, getDeletionError);
/**
 * Выбранные документы в рееестре документов.
 */
export var selectedDocuments = createSelector(documentsStateSelector, recognizedDocumentsSelector, function (state, recDocs) {
    if (state.allDocumentsSelected && recDocs.length > 0) {
        return state.selectedDocuments.concat(recDocs);
    }
    else {
        return state.selectedDocuments;
    }
});
/**
 * Колличество выбранных документов в рееестре документов.
 */
export var selectedDocumentsCount = createSelector(documentsStateSelector, documentsCountSelector, function (state, count) {
    if (!state.allDocumentsSelected) {
        return state.selectedDocuments.length;
    }
    else {
        return count - state.excludedDocuments.length;
    }
});
/**
 * Были выбраны все документы?
 */
export var allDocumentsSelected = createSelector(documentsStateSelector, function (state) { return state.allDocumentsSelected; });
/**
 * Документы на исключения из массовых действий.
 */
export var excludedDocuments = createSelector(documentsStateSelector, function (state) { return state.excludedDocuments; });
/**
 * Возвращает данные массовых действий с документами.
 */
export var batchDocumentsProps = createSelector(allDocumentsSelected, selectedDocuments, excludedDocuments, function (allSelected, selected, excluded) { return ({
    allDocumentsSelected: allSelected,
    selectedDocuments: selected,
    excludedDocuments: excluded,
}); });
/**
 * Возвращает данные массовых действий для документов.
 */
export var batchRegistryFilterProps = createSelector(batchDocumentsProps, spaceSelector, documentsStateSelector, function (props, space, documentsState) { return (__assign({}, props, { space: space, documentsState: documentsState })); });
