import { requestState } from "src/app/common/models/request-state";
import { ExternalDocumentTypeAction } from "src/app/spaces/modules/document-matching/store/actions/external-document-types.action";
import { ExternalDocumentTypesActionType } from "src/app/spaces/modules/document-matching/store/actions/external-document-types.action";
import { ExternalDocumentTypesState } from "src/app/spaces/modules/document-matching/store/states/external-document-types.state";

/**
 * Начальное состояние обработчика событий, связанных с состоянием типов внешних документов.
 */
const initialState: ExternalDocumentTypesState = {

    /**
     * Начальное состояние запроса внешних типов документов.
     */
    externalTypesRequest: requestState.initial(),
};

/**
 * Обработчик событий, связанных с состоянием типов внешних документов.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 *
 * @return Новое состояние.
 */
export function externalDocumentTypesReducer(
    state: ExternalDocumentTypesState = initialState,
    action: ExternalDocumentTypeAction,
): ExternalDocumentTypesState {
    let result: ExternalDocumentTypesState = state;

    switch (action.type) {

        case ExternalDocumentTypesActionType.LOAD: {

            result = {
                ...state,
                externalTypesRequest: requestState.pending(),
            };

            break;
        }
        case ExternalDocumentTypesActionType.LOAD_SUCCESS: {

            result = {
                ...state,
                externalTypesRequest: requestState.success(action.value),
            };

            break;
        }
        case ExternalDocumentTypesActionType.LOAD_FAIL: {

            result = {
                ...state,
                externalTypesRequest: requestState.fail(action.error),
            };

            break;
        }
    }

    return result;
}
