import { select } from "@ngrx/store";
import { Store } from "@ngrx/store";
import { currentUserStateSelector } from "rootStore";
import { tap } from "rxjs/operators";
import { take } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { map } from "rxjs/operators";
import { ConfigurationService } from "src/app/common/services/configuration.service";
import { BitrixWidgetService } from "./bitrix-widget.service";
import { OmnideskWidgetService } from "./omnidesk-widget.service";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "./bitrix-widget.service";
import * as i3 from "./omnidesk-widget.service";
import * as i4 from "../../common/services/configuration.service";
/**
 * Сервис для добавления виджета техподдержки на страницу.
 */
var SupportWidgetService = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор сервиса для добавления виджета техподдержки на страницу.
     *
     * @param store Сервис для управления и доступа к состоянию приложения.
     * @param bitrixWidgetService Сервис для добавления виджета Битрикс24 на страницу.
     * @param omnideskWidgetService Сервис для добавления виджета Omnidesk на страницу.
     * @param configServcie Сервис конфигураций.
     */
    function SupportWidgetService(store, bitrixWidgetService, omnideskWidgetService, configServcie) {
        this._store = store;
        this._bitrixWidgetService = bitrixWidgetService;
        this._omnideskWidgetService = omnideskWidgetService;
        this._configService = configServcie;
    }
    //endregion
    //region Public
    /**
     * Добавляет виджет техподдержки на страницу приложения.
     *
     * Дожидается загрузки данных пользователя, после чего решает чей виджет добавлять. Если у пользователя первое
     * пространство документов имеет флаг, что это клиент, то добавляется виджет Omnidesk'а. В противном случае
     * добавляется виджет Битрикс24.
     */
    SupportWidgetService.prototype.add = function () {
        var _this = this;
        this._store
            .pipe(select(currentUserStateSelector), filter(function (state) { return state.loaded; }), map(function (state) { return state.currentUserInfo; }), tap(function () { return _this._configService.loadConfigurations(); }), take(1))
            .subscribe(function (currentUser) {
            if (currentUser && currentUser.belongsToPaidClient) {
                _this._omnideskWidgetService.add();
            }
            else {
                _this._bitrixWidgetService.add();
            }
        });
    };
    SupportWidgetService.ngInjectableDef = i0.defineInjectable({ factory: function SupportWidgetService_Factory() { return new SupportWidgetService(i0.inject(i1.Store), i0.inject(i2.BitrixWidgetService), i0.inject(i3.OmnideskWidgetService), i0.inject(i4.ConfigurationService)); }, token: SupportWidgetService, providedIn: "root" });
    return SupportWidgetService;
}());
export { SupportWidgetService };
