import { createFeatureSelector } from "@ngrx/store";
import { PurchaseDialogState } from "../../../root/store/states/purchase-dialog.state";

/**
 * Селектор состояния диалога заявки на покупку страниц.
 */
const state = createFeatureSelector<PurchaseDialogState>("purchaseDialog");

export const purchaseDlgSelectors = {
    state,
};
