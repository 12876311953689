var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CurrentUserSettingsActionType } from "../actions/current-user-settings.action";
import { CurrentUserActionType } from "../actions/current-user.action";
import { PermissionItem } from "src/app/common/models/index";
/**
 * Начальное состояние по данным текущего пользователя.
 */
export var initialState = {
    // Данных нет.
    currentUserInfo: undefined,
    // Данные не загружены.
    loaded: false,
    // Данные не загружаются в данный момент.
    loading: false,
    // Ошибки загрузки нет.
    failed: false,
};
/**
 * Обработка состояния о текущем пользователе в ответ на события.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 */
export function currentUserReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        // На событие загрузки данных меняем флаг загрузки.
        case CurrentUserActionType.LOAD: {
            return __assign({}, state, { loading: true, loaded: false, failed: false });
        }
        // На событие успешной загрузки данных меняем флаги и меняем данные пользователя.
        case CurrentUserActionType.LOAD_SUCCESS: {
            var currentUserInfo = action.payload;
            return __assign({}, state, { currentUserInfo: currentUserInfo, loaded: true, loading: false });
        }
        // На событие неудачной загрузки данных меняем флаги.
        case CurrentUserActionType.LOAD_FAIL: {
            return __assign({}, state, { currentUserInfo: undefined, loaded: false, loading: false, failed: true });
        }
        // На событие успешной загрузки задачи на распознавание меняем флаг о наличии у пользователя задач на
        // распознавание.
        case CurrentUserActionType.RECOGNITION_TASK_UPLOADED: {
            return __assign({}, state, { currentUserInfo: __assign({}, state.currentUserInfo, { hasRecognitionTasks: true }) });
        }
        // На событие успешного сохранения флага необходимости показывать пользователю диалог после создания задачи на
        // распознавание.
        case CurrentUserSettingsActionType.SHOW_DIALOG_FLAG_SAVED_SUCCESSFULLY: {
            return __assign({}, state, { currentUserInfo: __assign({}, state.currentUserInfo, { settings: __assign({}, state.currentUserInfo.settings, { showRecognitionTaskCreatedDialog: action.flag }) }) });
        }
        // На событие успешного подтверждения номера телефона пользователем
        case CurrentUserActionType.PHONE_CONFIRM_SUCCESS: {
            return __assign({}, state, { currentUserInfo: __assign({}, state.currentUserInfo, { phoneConfirm: true, phone: action.payload.phone }) });
        }
        // На событие обновления данных пользователя после создания папки.
        case CurrentUserActionType.UPDATE_AND_REDIRECT: {
            return __assign({}, state, { currentUserInfo: __assign({}, state.currentUserInfo, { spaces: state.currentUserInfo.spaces.concat([action.payload]).sort(sortSpaceFn), spacePermissions: state.currentUserInfo.spacePermissions.concat([
                        {
                            spaceId: action.payload.id,
                            permissions: [
                                PermissionItem.INTEGRATION.value,
                                PermissionItem.MANAGE_EMAIL_NOTIFICATIONS.value
                            ],
                        }
                    ]) }) });
        }
        // На событие обновления данных пользователя после изменения папки.
        case CurrentUserActionType.UPDATE_SPACE: {
            var updatedSpaceList = state.currentUserInfo.spaces
                .map(function (space) { return space.id === action.payload.id && action.payload || space; })
                .sort(sortSpaceFn);
            return __assign({}, state, { currentUserInfo: __assign({}, state.currentUserInfo, { spaces: updatedSpaceList }) });
        }
    }
    return state;
}
/**
 * Сортирует пространства документов по наименованию.
 *
 * @param space1 Пространство документов.
 * @param space2 Пространство документов.
 */
export var sortSpaceFn = function (space1, space2) {
    if (space1.name.toLowerCase() > space2.name.toLowerCase()) {
        return 1;
    }
    else {
        return -1;
    }
};
/**
 * Возвращает данные пользователя из состояния данных о текущем пользователе.
 *
 * @param state Состояние данных о текущем пользователе.
 */
export var getCurrentUserInfo = function (state) { return state.currentUserInfo; };
/**
 * Возвращает язык текущего пользователя.
 *
 * @param user Данные о текущего пользователя.
 */
export var getCurrentUserLang = function (user) { return user ? user.lang : null; };
/**
 * Возвращает флаг загружены ли данные пользователя из состояния данных о текущем пользователе.
 *
 * @param state Состояние данных о текущем пользователе.
 */
export var getCurrentUserLoaded = function (state) { return state.loaded; };
/**
 * Возвращает флаг выполнения загрузки данных пользователя из состояния данных о текущем пользователе.
 *
 * @param state Состояние данных о текущем пользователе.
 */
export var getCurrentUserLoading = function (state) { return state.loading; };
/**
 * Возвращает флаг была ли ошибка при попытке загрузки данных пользователя из состояния данных о текущем
 * пользователе.
 *
 * @param state Состояние данных о текущем пользователе.
 */
export var getCurrentUserFailed = function (state) { return state.failed; };
