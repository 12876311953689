import { ChangeDetectorRef } from "@angular/core";
/**
 * Компонент отображения диалога для работы со списком доступных папок пользователя.
 */
var UserSpacesDlgComponent = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор компонента отображения диалога для работы со списком доступных папок пользователя.
     *
     * @param _detectionRef Сервис для управления запуском определения angular'ом изменений данных, произошедших в
     * компоненте.
     * @param data Данные для диалога.
     */
    function UserSpacesDlgComponent(_detectionRef, data) {
        this._detectionRef = _detectionRef;
        this._openCallback = data && data.openCallback || (function () { });
        this._confirmCallback = data && data.confirmCallback || (function () { });
        this._closeCallback = data && data.closeCallback || (function () { });
        this._destroyCallback = data && data.destroyCallback || (function () { });
        this.headerKey = data ? data.headerKey : "";
        this.okBtnKey = data ? data.okBtnKey : "";
        this.errorDescriptionKey = data ? data.errorDescriptionKey : "";
        this.successDescriptionKey = data ? data.successDescriptionKey : "";
    }
    Object.defineProperty(UserSpacesDlgComponent.prototype, "requestState", {
        //endregion
        //region Getters
        /**
         * Состояние запроса приглашения пользователей.
         */
        get: function () {
            return this._requestState;
        },
        /**
         * Состояние запроса на сервер.
         */
        set: function (state) {
            this._requestState = state;
            this._detectionRef.markForCheck();
        },
        enumerable: true,
        configurable: true
    });
    ;
    //endregion
    //region Hooks
    UserSpacesDlgComponent.prototype.ngOnInit = function () {
        this._openCallback();
    };
    UserSpacesDlgComponent.prototype.ngOnDestroy = function () {
        this._destroyCallback();
    };
    //endregion
    //region Public
    /**
     * Закрывает текущий диалог.
     */
    UserSpacesDlgComponent.prototype.closeDialog = function () {
        if (!this.requestState || this.requestState.type !== 'pending') {
            this._closeCallback();
        }
    };
    //endregion
    //region Events
    /**
     * Обрабатывает событие нажатия кнопки отправки запроса на сервер.
     *
     * @param invitationData Данные для запроса на сервер.
     */
    UserSpacesDlgComponent.prototype.confirmDlgDataHandler = function (invitationData) {
        this._confirmCallback(invitationData);
    };
    /**
     * Обработчик нажатия кнопки закрытия диалога.
     */
    UserSpacesDlgComponent.prototype.closeDialogClickHandler = function () {
        this.closeDialog();
    };
    /**
     * Обработчик нажатия на Esc.
     *
     * Закрывает диалог.
     */
    UserSpacesDlgComponent.prototype.onKeyUp = function () {
        this.closeDialog();
    };
    return UserSpacesDlgComponent;
}());
export { UserSpacesDlgComponent };
