import { Action } from "@ngrx/store";

/**
 * Типы событий, связанные заголовком сайта.
 */
export enum HeaderActionType {
    HIDE = '[Root] Hide header',
    SHOW = '[Root] Show header',
    HIDE_SPACES_INFO = '[Root] Hide header spaces info',
    SHOW_SPACES_INFO = '[Root] Show header spaces info',
    HIDE_HOW_TO_START_BUTTON = '[Root] Hide header how to start button',
    SHOW_HOW_TO_START_BUTTON = '[Root] Show header how to start button',
}

/**
 * Событие, требующее скрытие заголовка сайта.
 */
export class HeaderHideAction implements Action {
    readonly type = HeaderActionType.HIDE;
}

/**
 * Событие, требующее отображения заголовка сайта.
 */
export class HeaderShowAction implements Action {
    readonly type = HeaderActionType.SHOW;
}

/**
 * Событие, требующее скрытие выбора пространств в заголовке.
 */
export class HeaderHideSpaceSelectAction implements Action {
    readonly type = HeaderActionType.HIDE_SPACES_INFO;
}

/**
 * Событие, требующее скрытие выбора пространств в заголовке.
 */
export class HeaderShowSpaceSelectAction implements Action {
    readonly type = HeaderActionType.SHOW_SPACES_INFO;
}

/**
 * Событие, требующее скрыть кнопку "How to start".
 */
export class HeaderHideHowToStartButtonAction implements Action {
    readonly type = HeaderActionType.HIDE_HOW_TO_START_BUTTON;
}

/**
 * Событие, требующее отобразить кнопку "How to start".
 */
export class HeaderShowHowToStartButtonAction implements Action {
    readonly type = HeaderActionType.SHOW_HOW_TO_START_BUTTON;
}

/**
 * Тип, объединяющий все события, связанные с заголовком сайта.
 */
export type HeaderAction =
    | HeaderHideAction
    | HeaderShowAction
    | HeaderHideSpaceSelectAction
    | HeaderShowSpaceSelectAction
    | HeaderHideHowToStartButtonAction
    | HeaderShowHowToStartButtonAction
    ;
