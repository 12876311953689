import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { MoveDocumentsActionProps } from "src/app/common/store/actions/props/move-documents.action-props";
import { ErrorResponseProps } from "src/app/root/store/actions/props/error-response.action-props";

/**
 * Типы событий, связанные с диалогом перемещения конкретных документов в конкретную папку.
 */
export enum MoveDocumentsDlgActionType {
    INIT_DIALOG = "[Move documents dlg] Initialize",
    MOVE_TO_ANOTHER_SPACE = "[Move documents dlg] Move",
    MOVE_TO_ANOTHER_SPACE_SUCCESS = "[Move documents dlg] Success",
    MOVE_TO_ANOTHER_SPACE_FAIL = "[Move documents dlg] Fail",
}

/**
 * Событие инициализации диалога перемещения конкретных документов в конкретную папку.
 */
const moveDocumentsDlgInitialize = createAction(MoveDocumentsDlgActionType.INIT_DIALOG);

/**
 * Событие перемещения конкретных документов в конкретную папку.
 */
const moveDocumentsToAnotherSpace = createAction(
    MoveDocumentsDlgActionType.MOVE_TO_ANOTHER_SPACE,
    props<MoveDocumentsActionProps>(),
);

/**
 * Событие успешного перемещения конкретных документов в конкретную папку.
 */
const moveDocumentsToAnotherSpaceSuccess = createAction(
    MoveDocumentsDlgActionType.MOVE_TO_ANOTHER_SPACE_SUCCESS,
    props<MoveDocumentsActionProps>(),
);

/**
 * Событие неудачного перемещения конкретных документов в конкретную папку.
 */
const moveDocumentsToAnotherSpaceFail = createAction(
    MoveDocumentsDlgActionType.MOVE_TO_ANOTHER_SPACE_FAIL,
    props<ErrorResponseProps<void>>(),
);

export type MoveDocumentsDlgAction =
    | ReturnType<typeof moveDocumentsDlgInitialize>
    | ReturnType<typeof moveDocumentsToAnotherSpace>
    | ReturnType<typeof moveDocumentsToAnotherSpaceSuccess>
    | ReturnType<typeof moveDocumentsToAnotherSpaceFail>
    ;

/**
 * Все события, связанные с диалогом перемещения документов.
 */
export const moveDocumentsDlgActions = {
    moveDocumentsDlgInitialize,
    moveDocumentsToAnotherSpace,
    moveDocumentsToAnotherSpaceSuccess,
    moveDocumentsToAnotherSpaceFail,
};
