import { Injectable } from '@angular/core';

/**
 * Fake-реализация сервиса Яндекс.Метрика, которая ничего не делает.
 *
 * Данная реализация используется, когда Яндекс.Метрика отключена.
 */
@Injectable()
export class FakeMetrika {
    //region Ctor

    constructor() {}

    //endregion
    //region Static

    static getCounterNameById(id: string | number) {

        return '';
    }

    static getCounterById(id: any) {

        return {};
    }

    //endregion
    //region Public

    addFileExtension(extensions: string | string[], counterPosition?: number) { }

    extLink(url: string, options: any = {}, counterPosition?: number): any {
        
        return {};
    }

    file(url: string, options: any = {}, counterPosition?: number): any {
        
        return {};
    }

    getClientID(counterPosition?: number): string {
        
        return '';
    }

    setUserID(userId: string, counterPosition?: number): any {
        
        return {};
    }

    userParams(params: any, counterPosition?: number): any {
        
        return {};
    }

    params(params: any, counterPosition?: number): any {
        
        return {};
    }

    replacePhones(counterPosition?: number): any {
        
        return {};
    }

    notBounce(options: any = {}, counterPosition?: number): any {
        
        return {};
    }

    fireEvent(type: string, options: any = {}, counterPosition?: number): any {
        
        return {};
    }

    hit(url: string, options: any = {}, counterPosition?: number): any {
        
        return {};        
    }

    //endregion
}
