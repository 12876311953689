import { requestState } from "src/app/common/models/request-state";
import { ExternalLedgerTypesState } from "src/app/spaces/modules/document-matching/store/states/external-ledger-types.state";
import { ExternalLedgerTypesActionType } from "src/app/spaces/modules/document-matching/store/actions/external-ledger-types.action";
import { ExternalLedgerTypesAction } from "src/app/spaces/modules/document-matching/store/actions/external-ledger-types.action";

/**
 * Начальное состояние обработчика событий, связанных с состоянием типов контрагентов.
 */
const initialState: ExternalLedgerTypesState = {

    /**
     * Начальное состояние запроса контрагентов.
     */
    externalLedgerTypesRequest: requestState.initial(),
};

/**
 * Обработчик событий, связанных с состоянием типов контрагентов.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 *
 * @return Новое состояние.
 */
export function externalLedgerTypesReducer(
    state: ExternalLedgerTypesState = initialState,
    action: ExternalLedgerTypesAction,
): ExternalLedgerTypesState {
    let result: ExternalLedgerTypesState = state;

    switch (action.type) {

        case ExternalLedgerTypesActionType.LOAD: {

            result = {
                ...state,
                externalLedgerTypesRequest: requestState.pending(),
            };

            break;
        }
        case ExternalLedgerTypesActionType.LOAD_SUCCESS: {

            result = {
                ...state,
                externalLedgerTypesRequest: requestState.success(action.value),
            };

            break;
        }
        case ExternalLedgerTypesActionType.LOAD_FAIL: {

            result = {
                ...state,
                externalLedgerTypesRequest: requestState.fail(action.error),
            };

            break;
        }
    }

    return result;
}
