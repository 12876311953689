import { BalanceInfo } from 'src/app/common/models/balance-info';
import { BalanceAction, BalanceActionType } from '../actions';

/**
 * Состояние по данным баланса для пространства документов.
 */
export class BalanceState {

    // Данные баланса.
    balanceInfo: BalanceInfo = undefined;

    // Данные успешно загружены?
    loaded: boolean = false;

    // Данные загружаются?
    loading: boolean = false;

    // Загрузка завершилась ошибкой?
    failed: boolean = false;
}

/**
 * Начальное состояние по данным баланса для пространства документов.
 */
const initialState = new BalanceState();

/**
 * Обработка состояния баланса в ответ на события.
 * 
 * @param state Текущее состояние.
 * @param action Событие.
 */
export function balanceReducer(state = initialState, action: BalanceAction): BalanceState {
    
    let result = state;

    switch (action.type) {

        // На событие требования загрузки данных.
        case BalanceActionType.LOAD: {

            result = {
                ...state,
                loading: true,
                loaded: false,
                failed: false,
            };
            break;
        }

        // На событие успешной загрузки данных.
        case BalanceActionType.LOAD_SUCCESS: {

            const balanceInfo = action.payload;

            result = {
                ...state,
                balanceInfo,
                loaded: true,
                loading: false,
            };
            break;
        }

        // На событие неудачной загрузки данных.
        case BalanceActionType.LOAD_FAIL: {

            result = {
                ...state,
                balanceInfo: undefined,
                loaded: false,
                loading: false,
                failed: true,
            };
            break;
        }

        // На событие требования списания с баланса.
        case BalanceActionType.WITHDRAW: {

            const cost = action.payload;
            let balanceInfo = state.balanceInfo;

            if (balanceInfo && typeof balanceInfo.restCreditCount === "number") {

                balanceInfo = {
                    ...balanceInfo,
                    restCreditCount: (balanceInfo.restCreditCount - cost)
                };
            }

            result = {
                ...state,
                balanceInfo,
            };
            break;
        }

        // На событие требования очистки данных.
        case BalanceActionType.CLEAR: {

            result = initialState;
            break;
        }
    }

    return result;
}

/**
 * Возвращает данные баланса из состояния данных о балансе.
 * 
 * @param state Состояние данных о балансе.
 */
export const getBalanceInfo = (state: BalanceState) => state.balanceInfo;

/**
 * Возвращает доступное количество кредитов.
 * 
 * @param balance Данные баланса.
 */
export const getBalanceRestCreditCount = (balance: BalanceInfo) => balance ? balance.restCreditCount : null;

/**
 * Возвращает флаг загружены ли данные баланса из состояния данных о балансе.
 * 
 * @param state Состояние данных о балансе.
 */
export const getBalanceLoaded = (state: BalanceState) => state.loaded;

/**
 * Возвращает флаг выполнения загрузки данных баланса из состояния данных о балансе.
 * 
 * @param state Состояние данных о балансе.
 */
export const getBalanceLoading = (state: BalanceState) => state.loading;

/**
 * Возвращает флаг была ли ошибка при попытке загрузки данных баланса из состояния данных о балансе.
 * 
 * @param state Состояние данных о балансе.
 */
export const getBalanceFailed = (state: BalanceState) => state.failed;
