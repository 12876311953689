import {
    AfterContentInit,
    Component,
    ElementRef, EventEmitter,
    Inject,
    OnDestroy,
    Output,
    Renderer2,
    ViewEncapsulation
} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {ResizeSensor} from "css-element-queries";

@Component({
    selector: 'entera-fixed-footer',
    templateUrl: './fixed-footer.component.html',
    styleUrls: ['./fixed-footer.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FixedFooterComponent implements AfterContentInit, OnDestroy {
    
    private sensor: ResizeSensor;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private component: ElementRef,
        private renderer: Renderer2
    ) {
    }

    /**
     * Информация о высоте футера в пикселях. Излучается каждый раз когда меняется футер.
     */
    @Output()
    heightChange = new EventEmitter<number>();

    ngAfterContentInit(): void {
        this.setDocumentPadding();
        this.sensor = new ResizeSensor(this.component.nativeElement, this.setDocumentPadding);
    }

    ngOnDestroy(): void {
        this.sensor.detach(this.component.nativeElement);
        this.renderer.setStyle(this.document.body, "padding-bottom", '0px');
    }

    private setDocumentPadding = () => {

        this.renderer.setStyle(
            this.document.body, "padding-bottom", `${this.component.nativeElement.clientHeight}px`
        );
        this.heightChange.emit(this.component.nativeElement.clientHeight);
    };
}
