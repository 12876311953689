/**
 * Семантика столбца.
 */
export class ColumnSemantic {
    //region Enum values

    /**
     * Столбец с порядковыми номерами строк.
     */
    static readonly SERIAL_NUMBER: ColumnSemantic = new ColumnSemantic('SERIAL_NUMBER');

    /**
     * Столбец с кодами товаров/артикулами.
     */
    static readonly GOOD_CODE: ColumnSemantic = new ColumnSemantic('GOOD_CODE');

    /**
     * Столбец с названиями товаров.
     */
    static readonly NAME: ColumnSemantic = new ColumnSemantic('NAME');

    /**
     * Столбец с кодами видов товаров.
     */
    static readonly GOOD_TYPE_CODE: ColumnSemantic = new ColumnSemantic('GOOD_TYPE_CODE');

    /**
     * Столбец с кодом ед.изм. по позициям.
     */
    static readonly UNIT_CODE: ColumnSemantic = new ColumnSemantic('UNIT_CODE');

    /**
     * Столбец с ед.изм. по позициям.
     */
    static readonly UNIT: ColumnSemantic = new ColumnSemantic('UNIT');

    /**
     * Столбец с количествами по позициям.
     */
    static readonly QUANTITY: ColumnSemantic = new ColumnSemantic('QUANTITY');

    /**
     * Столбец со стоимостями по позициям без НДС.
     */
    static readonly PRICE: ColumnSemantic = new ColumnSemantic('PRICE');

    /**
     * Столбец с итоговыми суммами по позициям без НДС.
     */
    static readonly AMOUNT_WITHOUT_VAT: ColumnSemantic = new ColumnSemantic('AMOUNT_WITHOUT_VAT');

    /**
     * Столбец с акцизом по позициям.
     */
    static readonly EXCISE: ColumnSemantic = new ColumnSemantic('EXCISE');

    /**
     * Столбец со ставкой НДС.
     */
    static readonly VAT_RATE: ColumnSemantic = new ColumnSemantic('VAT_RATE');

    /**
     * Столбец с суммами НДС.
     */
    static readonly VAT: ColumnSemantic = new ColumnSemantic('VAT');

    /**
     * Столбец с итоговыми суммами по позициям, включая НДС.
     */
    static readonly AMOUNT: ColumnSemantic = new ColumnSemantic('AMOUNT');

    /**
     * Столбец с кодом страны.
     */
    static readonly COUNTRY_CODE: ColumnSemantic = new ColumnSemantic('COUNTRY_CODE');

    /**
     * Столбец с названием страны.
     */
    static readonly COUNTRY_NAME: ColumnSemantic = new ColumnSemantic('COUNTRY_NAME');

    /**
     * Столбец с ГТД.
     */
    static readonly CUSTOMS_DECLARATION: ColumnSemantic = new ColumnSemantic('CUSTOMS_DECLARATION');

    //endregion
    //region Public fields

    /**
     * ID семантики.
     */
    readonly id: string;

    //endregion
    //region Ctor

    private constructor(id: string) {
        this.id = id;
    }

    //endregion
}
