import { ApiResponse } from "src/app/common/models";

/**
 * Состояние диалога перемещения документов.
 */
export class MoveDocumentsDlgState {
    //region Fields

    /**
     * Документы перемещаются в другую папку?
     */
    movingDocumentsToAnotherSpace: boolean = false;

    /**
     * Документы переместились в другую папку?
     */
    movingDocumentsToAnotherSpaceSuccess: boolean = false;

    /**
     * Перемещение документов в другую папку завершилось ошибкой?
     */
    movingDocumentsToAnotherSpaceError: ApiResponse = null;

    //endregion
}
