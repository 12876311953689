import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { OnDestroy } from "@angular/core";

import { version } from "src/environments/version";
import { UtilsService } from "../../../common/services";
import { TitleService } from "../../../common/services";

/**
 * Комонент страницы с версией UI-приложения.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'version-page',
    styleUrls: ['./version-page.component.scss'],
    templateUrl: './version-page.component.html',
})
export class VersionPageComponent implements OnInit, OnDestroy {
    //region Ctor

    constructor(
        private _utilService: UtilsService,
        private _titleService: TitleService
    ) { }

    //endregion
    //region Hooks

    ngOnInit(): void {

        this._utilService.hideSupportWidget();
        this._titleService.setTitle({ titleKey: 'version' });
    }

    ngOnDestroy(): void {

        this._utilService.showSupportWidget();
        this._titleService.clearTitle();
    }

    //endregion
    //region Getters and Setter

    /**
     * Возвращает версию UI приложения.
     */
    get version(): string {

        return version;
    }

    //endregion
}