import { IntegrationAction } from "src/app/spaces/modules/document-matching/store/actions/integration.actions";
import { IntegrationActionType } from "src/app/spaces/modules/document-matching/store/actions/integration.actions";
import { IntegrationState } from "src/app/spaces/modules/document-matching/store/states/integration.state";

/**
 * Начальное состояние интеграции.
 */
const initialState: IntegrationState = {

    /**
     * Интеграция.
     */
    integration: null,

    /**
     * Не загружается.
     */
    loading: false,

    /**
     * Не загружена.
     */
    loaded: false,

    /**
     * Последняя загрузка интеграции не завершалась ошибкой.
     */
    error: null,

};

/**
 * Обработчик событий, связанных с изменением состояния интеграции.
 *
 * @param state Текущее состояние интеграции.
 * @param action Событие.
 *
 * @return Новое состояние.
 */
export function integrationReducer(state = initialState, action: IntegrationAction): IntegrationState {

    let result = state;

    switch (action.type) {

        case IntegrationActionType.INIT: {

            result = initialState;
            break;
        }

        case IntegrationActionType.LOAD_INTEGRATION: {

            result = {
                ...state,
                loading: true,
                loaded: false,
                error: null,
            };
            break;
        }

        case IntegrationActionType.LOAD_INTEGRATION_SUCCESS: {

            result = {
                ...state,
                integration: action.integration,
                loading: false,
                loaded: true,
            };
            break;
        }

        case IntegrationActionType.LOAD_INTEGRATION_FAILED: {

            result = {
                ...state,
                loading: false,
                error: action.error,
            };
            break;
        }

        case IntegrationActionType.UPDATE_INTEGRATION_SYNC_INFO: {

            result = {
                ...state,
                loading: true,
                loaded: false,
                error: null,
            };
            break;
        }

        case IntegrationActionType.UPDATE_INTEGRATION_SYNC_INFO_SUCCESS: {

            result = {
                ...state,
                integration: {
                    ...state.integration,
                    lastSyncDate: action.integration && action.integration.lastSyncDate || null,
                    settings: action.integration && action.integration.settings || null,
                },
                loading: false,
                loaded: true,
            };
            break;
        }

        case IntegrationActionType.UPDATE_INTEGRATION_SYNC_INFO_FAILED: {

            result = {
                ...state,
                loading: false,
                error: action.error,
            };
            break;
        }
    }

    return result;
}
