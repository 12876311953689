var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { PurchaseDialogActionType } from "src/app/root/store/actions/purchase-dialog.action";
/**
 * Начальное состояние диалога о покупке страниц.
 */
export var initialState = {
    // Запрос не был успешно отправлен.
    requested: false,
    //В данный момент запрос не выполняется
    requesting: false,
    // Ошибки при загрузке нет.
    error: null,
};
/**
 * Обработка событий, связанных с состоянием диалога покупки страниц.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 */
export function purchaseDialogReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        // На событие открытия диалога.
        case PurchaseDialogActionType.OPEN_DIALOG: {
            return initialState;
        }
        // На событие отправки запроса на покупку страниц.
        case PurchaseDialogActionType.REQUEST_PURCHASE: {
            return __assign({}, state, { requesting: true, error: null });
        }
        // На событие успешной отправки запроса на покупку страниц.
        case PurchaseDialogActionType.REQUEST_SUCCESS: {
            return __assign({}, state, { requesting: false, requested: true });
        }
        // На событие не успешного запроса на покупку страниц
        case PurchaseDialogActionType.REQUEST_FAIL: {
            return __assign({}, state, { requesting: false, error: action.error });
        }
    }
    return state;
}
