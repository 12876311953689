<div class="match-settings-dlg" [formGroup]="matchSettingsGroup">
    <h2 class="mat-h2 match-settings-dlg__header">{{ "matcher.settingsDlg.title" | translate }}</h2>
    <mat-spinner *ngIf="(requestStateType$ | async) === 'pending'" [diameter]="80" class="match-settings-dlg__loading"></mat-spinner>
    <div *ngIf="!error && (requestStateType$ | async) !== 'pending'" class="match-settings-dlg__block">
        <p class="mat-h3 match-settings-dlg__text">{{ "matcher.settingsDlg.showAdditionalColumns" | translate }}</p>
        <div>
            <mat-checkbox [formControlName]="'showGoodCodeColumn'">
                {{ "matcher.table.label.goodCode" | translate }}
            </mat-checkbox>
        </div>
    </div>
    <div *ngIf="!!error" class="match-settings-dlg__block">
        <p>{{ 'common.response.error.message' | translate }}</p>
        <p *ngIf="error.errorId">{{ 'common.response.error.id' | translate }} :<br>{{ error.errorId }}</p>
        <p *ngIf="error.errorCode">{{ 'common.response.error.code' | translate }} :<br>{{ error.errorCode }}</p>
    </div>
    <div *ngIf="(requestStateType$ | async) !== 'pending'" class="match-settings-dlg__buttons">
        <button
            class="match-settings-dlg__btn"
            mat-raised-button
            color="primary"
            (click)="okBtnHandler()"
        >
            {{ "dialogs.ok" | translate }}
        </button>
    </div>
</div>

