import { Injectable } from '@angular/core';

import { ofType } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from "rxjs";
import { withLatestFrom } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";

import { ApiResponse } from "../../../common/models";
import { FeedbackDlgService } from "../../services/feedback-dlg.service";
import { FeedbackSendFailAction } from "../actions";
import { FeedbackSendSuccessAction } from "../actions";
import { FeedbackSendAction } from "../actions";
import { FeedbackActionType } from "../actions";
import { feedbackStateSelector } from "../reducers";
import { RootState } from '../reducers';
import { FeedbackState } from "../reducers/feedback/feedback.state";

/**
 * Side-эффекты на события, связанные с обратной связью клиента.
 */
@Injectable()
export class FeedbackEffect {
    //region Fields

    /**
     * Сервис для работы с обратной связи от клиентов.
     */
    private readonly _feedbackService: FeedbackDlgService;

    //endregion
    //region Ctor

    /**
     * Констрцктор  объекта обработки side-эффектов на события, связанные с обратной связью клиента.
     *
     * @param _actions$ Поток событий в системе.
     * @param _store Хранилище.
     * @param feedbackService Сервис для работы с обратной связи от клиентов.
     */
    constructor(private _actions$: Actions, private _store: Store<RootState>, feedbackService: FeedbackDlgService) {

        this._feedbackService = feedbackService;
    }

    //endregion
    //region Ctor

    /**
     * Обработка события отправки данных обратной связи.
     */
    @Effect()
    send$ = this._actions$
        .pipe(
            ofType(FeedbackActionType.SEND),
            withLatestFrom(this._store.select(feedbackStateSelector)),
            switchMap(([action , state]: [FeedbackSendAction, FeedbackState]) =>
                this._feedbackService.send({
                    type: state.type.id,
                    documentId: state.documentId,
                    comment: action.payload
                })
                    .pipe(
                        map((_: boolean) => new FeedbackSendSuccessAction()),
                        catchError((response: ApiResponse) => of(new FeedbackSendFailAction(response)))
                    )
            )
        );

    //endregion
}
