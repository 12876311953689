import { HttpErrorResponse } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";

import { RootState } from "rootStore";
import { throwError } from "rxjs";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";

import { ApiResponse } from "../../common/models";
import { FeedbackDlgComponent } from "../containers/feedback-dlg/feedback-dlg.component";
import { Feedback } from "../models";
import { FeedbackType } from "../models";

/**
 * Сервис для работы с диалогом обратной связи.
 */
@Injectable({
    providedIn: "root"
})
export class FeedbackDlgService {
//region Fields

    /**
     * Сервис для работы с диалогами.
     */
    private readonly _dialog: MatDialog;

    /**
     * HTTP клиент.
     */
    private readonly _http: HttpClient;

    /**
     * Хранилище.
     */
    private readonly _store: Store<RootState>;

    //endregion
    //region Ctor

    /**
     * Конструктор сервиса для работы с диалогом обратной связи.
     *
     * @param dialog Сервис для работы с диалогами.
     * @param http HTTP клиент.
     * @param store Хранилище.
     */
    constructor(dialog: MatDialog, http: HttpClient, store: Store<RootState>) {

        this._dialog = dialog;
        this._http = http;
        this._store = store;
    }

    //endregion
    //region Public

    /**
     * Открывает диалог обратной связи.
     *
     * @return Объект для управления диалогом и компонентом, который находится в диалоге.
     */
    open(type: FeedbackType, documentId?: string): MatDialogRef<FeedbackDlgComponent> {

        this._dialog.closeAll();
        return this._dialog.open(FeedbackDlgComponent, {
            data: {type, documentId} as Feedback,
            autoFocus: true,
        });
    }

    /**
     * Отправка обратной связи на сервер.
     *
     * @param feedback Данные для отправки.
     */
    send(feedback: Feedback): Observable<boolean> {

        return this._http.post("/api/v1/feedback", feedback)
            .pipe(
                map((response: ApiResponse) => response.result),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse))
            );
    }

    //endregion
}
