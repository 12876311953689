import { HttpErrorResponse } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { ApiResponse } from "src/app/common/models/api-response";
import { Currency } from "src/app/common/models/currency";

/**
 * Сервис для работы с валютами.
 */
@Injectable({
    providedIn: "root",
})
export class CurrencyService {
    //region Fields

    /**
     * HTTP клиент.
     */
    private readonly _httpClient: HttpClient;

    //endregion
    //region Ctor

    /**
     * Конструктор сервиса для работы с валютами.
     *
     * @param httpClient HTTP клиент.
     */
    constructor(httpClient: HttpClient) {

        this._httpClient = httpClient;
    }

    //endregion
    //region Public

    /**
     * Запрос на получение списка валют.
     *
     * @return Список валют.
     */
    getCurrencies(): Observable<Currency[]> {

        return this._httpClient.get<Currency[]>(`/api/v1/currencies`)
            .pipe(
                map((result: any) => result.currencies),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse)),
            );
    }

    //endregion
}