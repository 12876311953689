<div class="feedback">
    <h2 mat-dialog-title class="mat-h2 feedback__title">
        {{type.titleKey | translate}}
    </h2>
    <div class="feedback__content" mat-dialog-content>
        <p class="feedback__comment">
            {{type.commentKey | translate}}
        </p>
        <mat-form-field
            class="feedback__textarea"
        >
            <mat-label>{{type.placeholderKey | translate}}</mat-label>
            <textarea
                tabindex="1"
                matInput
                [formControl]="messageControl"
                [maxLength]="2048"
                cdkTextareaAutosize
                cdkAutosizeMinRows="5"
                cdkAutosizeMaxRows="5"
            >
            </textarea>
        </mat-form-field>
    </div>
    <div class="feedback__buttons" mat-dialog-actions>
        <button
            class="feedback__btn"
            mat-raised-button
            matDialogClose
        >
            {{ "button.cancel" | translate }}
        </button>
        <button
            class="feedback__btn"
            mat-raised-button
            color="primary"
            (click)="sendHandler()"
        >
            {{ "button.send" | translate }}
        </button>
    </div>
</div>
