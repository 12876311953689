import { Injectable } from '@angular/core';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import {
    ApiResponsePayloadAction,
    RouterGoAction,
    ServerSideErrorActionType,
    ServerSideErrorOccurredAction,
    serverSideErrors,
} from "../actions";
import { ApiResponse } from '../../../common/models/api-response';

/**
 * Side-эффекты, связанные с ошибками, произошедшими на сервере, которые ведут на отдельную страницу об ошибке.
 * 
 * Эти ошибки явно показывают, что продолжать работу невозможно, при этом пользователь ещё ничего не сделал,
 * и показывать их через всплывашку не имеет смысла.
 */
@Injectable()
export class ServerSideErrorsEffects {
    //region Ctor

    constructor(
        private actions$: Actions
    ) { }

    //endregion
    //region Public

    /**
     * Обработка всех событий в модуле, сигнализирующих ошибку на сервере, и превращение их в единое событие о 
     * произошедшей ошибке на сервере, которое вызовет обновление данных об ошибке в состоянии приложения и 
     * приведёт к переходу на странцу об ошибке.
     */
    @Effect()
    serverSideErrors$ = this.actions$
        .pipe(
            ofType(... serverSideErrors),
            map((action: ApiResponsePayloadAction) => action.payload),
            map((response: ApiResponse) => new ServerSideErrorOccurredAction(response))
        );

    /**
     * Обработка события, что произошла ошибка на сервере. Переход на страницу об ошибке.
     */
    @Effect()
    goToErrorPage$ = this.actions$
        .pipe(
            ofType(ServerSideErrorActionType.OCCURRED),
            // TODO Хардкод URL
            map(() => new RouterGoAction({ path: ['something-goes-wrong']}))
        );

    //endregion
}
