import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { EnteraDocumentType } from "src/app/common/models/document-type";
import { ErrorResponseProps } from "src/app/root/store/actions/props/error-response.action-props";
import { ValueProps } from "src/app/root/store/actions/props/value-props";

/**
 * Типы событий, связанные с типами документов.
 */
export enum DocumentTypesActionType {
    LOAD = '[Documents registry] Load document types',
    LOAD_SUCCESS = '[Documents registry] Load document types Success',
    LOAD_FAIL = '[Documents registry] Load document types Fail',
}

/**
 * Событие, требующее загрузки типов документов.
 */
const load = createAction(DocumentTypesActionType.LOAD);

/**
 * Событие успешной загрузки типов документов.
 */
const loadSuccess = createAction(
    DocumentTypesActionType.LOAD_SUCCESS,
    props<ValueProps<EnteraDocumentType[]>>(),
);

/**
 * Событие неудачной загрузки типов документов.
 */
const loadFail = createAction(
    DocumentTypesActionType.LOAD_FAIL,
    props<ErrorResponseProps<never>>(),
);

/**
 * Тип, объединяющий все события, связанные с типами документов.
 */
export type DocumentTypesAction =
    | ReturnType<typeof load>
    | ReturnType<typeof loadSuccess>
    | ReturnType<typeof loadFail>;

/**
 * Все события, связанные с типами документов.
 */
export const documentTypesActions = {
    load,
    loadSuccess,
    loadFail,
}