import { FileWithHash } from "src/app/common/models/file-with-hash";
import { UploadToRecognizeAction } from "../../actions";
import { UploadToRecognizeActionType } from "../../actions";
import { ApiResponse } from "src/app/common/models";

/**
 * Состояние отправки файлов на распознавание. Создание задачи на распознавание.
 */
export class UploadToRecognizeState {
    //region Fields

    /**
     * Внутренний ID сущности - количетво миллисекунд при создании сущности на клиенте.
     */
    // TODO Перенести счетчки в хранилище
    readonly id: number = new Date().getTime();

    /**
     * ID пространства документов, в который отправляют задачу.
     */
    spaceId: string;

    /**
     * Задача на распознавание должна быть создана с флагом, что необходимо принудительно выполнить вызов к
     * удалённому OCR-сервису независимо от имеющегося предыдущего результата вызова?
     */
    forceOcrRecognition: boolean = false;

    /**
     * Задача на распознавание должна быть создана с флагом, что необходимо принудительно выполнить процесс
     * обработки результата OCR-сервиса независимо от имеющегося предыдущего результата обработки?
     */
    forceProcessing: boolean = false;

    /**
     * Задача на распознавание должна быть создана с флагом, что необходимо принудительно выполнить отправку
     * результата обработки и выемки данных в очередь к оператору?
     */
    forceQueue: boolean = false;

    /**
     * Комментария к файлам отправленым на распознавание.
     */
    comment: string = "";

    /**
     * Задача должна быть создана для выписки из банка?
     */
    bankStatement: boolean = false;

    /**
     * Прогресс загрузки файлов на сервер.
     */
    progress: number = 0;

    /**
     * Файлы с хешами, которые относятся к данной задаче на распознование.
     */
    filesWithMetaData: FileWithHash[] = [];

    /**
     * Происходит генерация хэшей и проверка дубликатов?
     */
    duplicateCheckingByHash: boolean = false;

    /**
     * ?
     */
    error: ApiResponse = null;

    /**
     * ?
     */
    failed: boolean = false;

    /**
     * ?
     */
    readonly loading: boolean = false;

    /**
     * ?
     */
    loaded: boolean = false;

    /**
     * Нужно ли открывать дилаог после создания отправки файлов на распознавание?
     */
    readonly openPostUploadDialog: boolean = true;

    /**
     * Загрузка с мобильной версии приложения?
     */
    readonly mobile: boolean = false;

    //endregion
    //region Ctor

    constructor(files: FileWithHash[] = []) {
        this.filesWithMetaData = files;
    }

    //endregion
}

/**
 * Начальное состояние.
 */
const initialState: UploadToRecognizeState = new UploadToRecognizeState();

/**
 * Обработчик состояния отправки файлов на распознование.
 * 
 * @param state
 * @param action
 */
export function uploadToRecognizeReducer(
    state = initialState,
    action: UploadToRecognizeAction
): UploadToRecognizeState {

    let result: UploadToRecognizeState = state;

    switch (action.type) {

        case UploadToRecognizeActionType.UPLOAD_SUCCESS: {

            result = {
                ...state,
                loaded: true,
                loading: false,
            };
            break;
        }

        case UploadToRecognizeActionType.UPLOAD_FAIL: {

            result = {
                ...state,
                loaded: false,
                loading: false,
                failed: true,
                error: action.payload
            };
            break;
        }

        case UploadToRecognizeActionType.UPLOAD_PROGRESS: {

            result = {
                ...state,
                progress: action.payload
            };
            break;
        }

        case UploadToRecognizeActionType.TASK_CHANGE: {

            result = {
                ...action.payload
            };
            break;
        }

        case UploadToRecognizeActionType.ADD_FILES: {

            let filesToAdd: FileWithHash[] = action.payload.filter(fileToAdd =>
                state.filesWithMetaData.every(existFile => existFile.hash !== fileToAdd.hash)
            );

            if (filesToAdd.length) {

                result = {
                    ...state,
                    filesWithMetaData: [ ...state.filesWithMetaData, ...filesToAdd],
                };
                break;
            }

            break;
        }

        case UploadToRecognizeActionType.GENERATE_HASH_FOR_FILES: {

            result = {
                ...state,
                duplicateCheckingByHash: true,
            };
            break;
        }

        case UploadToRecognizeActionType.MARK_FILES_AS_DUPLICATES: {

            result = {
                ...state,
                filesWithMetaData: state.filesWithMetaData.map(existFile => {

                    let fileToMark = action.payload
                        .filter(file => !!file.fileId)
                        .find(fileToMark => fileToMark.hash === existFile.hash);

                    return !!fileToMark && {...existFile, fileId: fileToMark.fileId } || existFile;
                }),
                duplicateCheckingByHash: false,
            };

            break;
        }

        case UploadToRecognizeActionType.FILE_EXISTENCE_CHECK_FAILED: {

            result = {
                ...state,
                duplicateCheckingByHash: false,
                error: action.payload,
                failed: true,
            }
            break;
        }

        case UploadToRecognizeActionType.REMOVE_FILE: {

            result = {
                ...state,
                filesWithMetaData: state.filesWithMetaData.filter((fileWithHash) => fileWithHash.file !== action.payload)
            };
            break;
        }

        case UploadToRecognizeActionType.UPLOAD: {

            result = {
                ...action.payload,
                loading: true,
            };
            break;
        }

        case UploadToRecognizeActionType.TOGGLE_FORCE_OCR: {

            result = {
                ...result,
                forceOcrRecognition: action.payload
            };
            break;
        }

        case UploadToRecognizeActionType.TOGGLE_FORCE_PROCESSING: {

            result = {
                ...result,
                forceProcessing: action.payload
            };
            break;
        }

        case UploadToRecognizeActionType.TOGGLE_FORCE_QUEUE: {

            result = {
                ...result,
                forceQueue: action.payload
            };
            break;
        }

        case UploadToRecognizeActionType.COMMENT: {

            result = {
                ...result,
                comment: action.payload,
            };
            break;
        }

        case UploadToRecognizeActionType.MOBILE_UPLOAD: {

            result = {
                ...result,
                comment: action.payload,
            };
            break;
        }
    }

    return result;
}

/**
 * Функция выбора поля loaded из состония в хранилище.
 * @param state
 */
export const getLoadedState = (state: UploadToRecognizeState) => state.loaded;

/**
 * Функция выбора поля loading из состония в хранилище.
 * @param state
 */
export const getLoadingState = (state: UploadToRecognizeState) => state.loading;

/**
 * Функция получения ошибки, с которой завершилось создание задачи на распознавание.
 * 
 * @param state Состояние создания задачи на распознавание.
 */
export const getRecognitionTaskCreationError = (state: UploadToRecognizeState) => state.error;
