import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { Currency } from "src/app/common/models/currency";
import { ErrorResponseProps } from "src/app/root/store/actions/props/error-response.action-props";
import { ValueProps } from "src/app/root/store/actions/props/value-props";

/**
 * Типы событий, связанных с валютами.
 */
export enum CurrenciesActionType {
    LOAD = "[Currencies] Load currencies",
    LOAD_SUCCESS = "[Currencies] Successfully load currencies",
    LOAD_FAIL = "[Currencies] Error occurred while loading currencies",
}

/**
 * Событие получения валют с сервера.
 */
const load = createAction(CurrenciesActionType.LOAD);

/**
 * Событие успешного получения валют с сервера.
 */
const loadSuccess = createAction(CurrenciesActionType.LOAD_SUCCESS, props<ValueProps<Currency[]>>());

/**
 * Событие ошибки при получении валют с сервера.
 */
const loadFail = createAction(CurrenciesActionType.LOAD_FAIL, props<ErrorResponseProps<void>>());

/**
 * Тип, объединяющий все события, связанные с валютами.
 */
export type CurrenciesAction =
    | ReturnType<typeof load>
    | ReturnType<typeof loadSuccess>
    | ReturnType<typeof loadFail>;

/**
 * Все события, связанные с валютами.
 */
export const currenciesActions = {
    load,
    loadSuccess,
    loadFail,
};