import { SPACE } from "@angular/cdk/keycodes";
import { COMMA } from "@angular/cdk/keycodes";
import { ENTER } from "@angular/cdk/keycodes";
import { ElementRef } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Subject } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { filter } from "rxjs/operators";
import { takeUntil } from "rxjs/operators";
import { map } from "rxjs/operators";
import { startWith } from "rxjs/operators";
import { Constants } from "src/app/common/models/constants.model";
/**
 * Компонент формы диалога для ввода списка email-ов и выбора списка пространств документов.
 */
var UsersAndSpacesInputComponent = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор компонента формы диалога для ввода списка email-ов и выбора списка пространств документов.
     */
    function UsersAndSpacesInputComponent() {
        var _this = this;
        /**
         * Контрол списка email'ов пользователей.
         */
        this.emailListControl = new FormControl([], [function (control) { return _this._emailListValidator(control); }]);
        /**
         * Поле чекбокса выбора всех папок.
         */
        this.selectAllSpacesCtrl = new FormControl(false);
        /**
         * Объект глобальной отписки.
         */
        this._globalUnsubscribe$ = new Subject();
        this._spaces = [];
        this.confirmData = new EventEmitter();
        this.closeDialog = new EventEmitter();
        this.selectedSpaces = [];
        this.chipsSeparatorKeysCodes = [ENTER, COMMA, SPACE];
        this.spaceCtrl = new FormControl();
        this.filteredSpaces = new BehaviorSubject([]);
        this.spaceCtrl.valueChanges
            .pipe(takeUntil(this._globalUnsubscribe$), startWith(""), map(function (spaceName) { return _this._filter(spaceName); }))
            .subscribe(function (spaces) { return _this.filteredSpaces.next(spaces); });
        this.selectAllSpacesCtrl.valueChanges
            .pipe(takeUntil(this._globalUnsubscribe$), filter(Boolean))
            .subscribe(function () {
            if (_this.spaceInput) {
                _this.spaceInput.nativeElement.value = "";
            }
            _this.spaceCtrl.setValue(null);
            _this.selectedSpaces = [];
        });
    }
    Object.defineProperty(UsersAndSpacesInputComponent.prototype, "spaces", {
        //region Inputs
        /**
         * Пространства документов, которые доступны пользователю.
         */
        set: function (spaces) {
            this._spaces = spaces.filter(function (space) { return !space.deleted; });
            this.filteredSpaces.next(spaces.filter(function (space) { return !space.deleted; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UsersAndSpacesInputComponent.prototype, "preSelectedSpaces", {
        /**
         * Предварительно выбранные пространства документов, которые доступны пользователю.
         */
        set: function (spaces) {
            var _this = this;
            if (spaces) {
                this.selectedSpaces = spaces.filter(function (preSpace) { return _this._spaces.find(function (space) { return space.id === preSpace.id; }); }) || [];
            }
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Hooks
    /**
     * Выполняет логику при уничтожении компоненты.
     */
    UsersAndSpacesInputComponent.prototype.ngOnDestroy = function () {
        this._globalUnsubscribe$.complete();
    };
    Object.defineProperty(UsersAndSpacesInputComponent.prototype, "isAllSpacesSelect", {
        //endregion
        //region Getters
        /**
         * Выставлен ли чекбокс выбора всех папок?
         */
        get: function () {
            return this.selectAllSpacesCtrl.value;
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Public
    /**
     * Email корректен?
     *
     * @param email Проверяемый на корректность email.
     *
     * @return Да/Нет.
     */
    UsersAndSpacesInputComponent.prototype.isEmailValid = function (email) {
        return Constants.EMAIL_REGEXP.test(email);
    };
    /**
     * Введенные данные пользователем корректны?
     *
     * @return Да/Нет.
     */
    UsersAndSpacesInputComponent.prototype.areDataValid = function () {
        var _this = this;
        var emailsValid = this.emailListControl.value
            .every(function (email) { return _this.isEmailValid(email); });
        var emailExists = (this.emailListControl.value.length > 0);
        var spaceExists = !!this.selectedSpaces.length || (this.isAllSpacesSelect && !!this._spaces.length);
        return (emailExists && spaceExists && emailsValid);
    };
    //endregion
    //region Event
    /**
     * Обработчик нажатия на кнопку для отправки запроса.
     */
    UsersAndSpacesInputComponent.prototype.applyBtnClickHandler = function () {
        var selectedSpaces = this.isAllSpacesSelect ? this._spaces : this.selectedSpaces;
        var spaceIdsAndEmails = {
            emails: this.emailListControl.value,
            spaceIds: selectedSpaces.map(function (space) { return space.id; }),
        };
        this.confirmData.emit(spaceIdsAndEmails);
    };
    /**
     * Обработчик нажатия на кнопку закрытия текущего диалога.
     */
    UsersAndSpacesInputComponent.prototype.closeDialogButtonHandler = function () {
        this.closeDialog.emit();
    };
    /**
     * Обработка выбора пространства документов из подсказывающего выпадающего списка.
     *
     * @param event Событие для обработки.
     */
    UsersAndSpacesInputComponent.prototype.selectionHandler = function (event) {
        var _this = this;
        var selectedSpaceName = event.option.value;
        var removableSpaceIndex = this._spaces.findIndex(function (space) {
            return space.name === selectedSpaceName
                && !_this.selectedSpaces.some(function (spaceInSelection) { return spaceInSelection.id === space.id; });
        });
        this.selectedSpaces = this.selectedSpaces.concat([
            this._spaces[removableSpaceIndex],
        ]);
        this.filteredSpaces.next(this._filter());
        this.spaceInput.nativeElement.value = "";
        this.spaceCtrl.setValue(null);
    };
    /**
     * Добавляет email в список в случае, если в списке еще нет такого email'а, и очищает строку ввода.
     *
     * @param event Событие для обработки.
     */
    UsersAndSpacesInputComponent.prototype.addEmailHandler = function (event) {
        var input = event.input;
        var value = event.value;
        this.emailListControl.markAsTouched();
        if ((value || '').trim() && this.emailListControl.value.indexOf(value.trim()) === -1) {
            this.emailListControl.patchValue(this.emailListControl.value.concat([value.trim()]));
        }
        if (input) {
            input.value = '';
        }
    };
    /**
     * Обрабатывает текст вставки в поле ввода email так, что все подстроки, которые соответствуют валидному email,
     * попадут в chip-list. Использует для выделения подстрок паттерн валидного email.
     *
     * @param event Событие для обработки.
     */
    UsersAndSpacesInputComponent.prototype.emailPasteHandler = function (event) {
        var value = event.clipboardData.getData('text/plain');
        var input = event.currentTarget;
        var regEx = new RegExp(Constants.MULTI_EMAIL_REGEXP.source, 'g');
        var match = regEx.exec(value);
        while (match != null) {
            this.emailListControl.patchValue(this.emailListControl.value.concat([match[0]]));
            match = regEx.exec(value);
        }
        event.preventDefault();
        event.stopPropagation();
    };
    /**
     * Удаляет email из списка email'ов.
     *
     * @param email Удаляемый email.
     */
    UsersAndSpacesInputComponent.prototype.removeEmailHandler = function (email) {
        var index = this.emailListControl.value.indexOf(email);
        if (index >= 0) {
            var emails = this.emailListControl.value;
            emails.splice(index, 1);
            this.emailListControl.patchValue(emails);
        }
    };
    /**
     * Добавляет пространство документов и очищает строку ввода.
     *
     * @param event Событие для обработки.
     */
    UsersAndSpacesInputComponent.prototype.addSpaceHandler = function (event) {
        if ((event.value || "").trim()) {
            var addedSpaceIndex = this._spaces.findIndex(function (space) { return space.name === event.value; });
            var selected = this.selectedSpaces.some(function (space) { return space.name === event.value; });
            if (!selected && addedSpaceIndex >= 0) {
                this.selectedSpaces = this.selectedSpaces.concat([
                    this._spaces[addedSpaceIndex]
                ]);
                this.filteredSpaces.next(this._filter());
            }
        }
        if (this.spaceInput) {
            this.spaceInput.nativeElement.value = "";
        }
        this.spaceCtrl.setValue(null);
    };
    /**
     * Удаляет пространство документов из списка.
     *
     * @param space Удаляемое пространство документов.
     */
    UsersAndSpacesInputComponent.prototype.removeSpaceHandler = function (space) {
        var removableSpaceIndex = this.selectedSpaces.findIndex(function (selectedSpace) { return space.name === selectedSpace.name; });
        this.selectedSpaces.splice(removableSpaceIndex, 1);
        this.filteredSpaces.next(this._filter());
    };
    //endregion
    //region Private
    /**
     * Фильтрует и возвращает пространства документов по введенной части имени пространства документов.
     *
     * @param spaceName Введенная пользователем часть имени пространства документов.
     *
     * @return Пространства документов по введенной части имени пространства документов.
     */
    UsersAndSpacesInputComponent.prototype._filter = function (spaceName) {
        var _this = this;
        if (spaceName === void 0) { spaceName = ""; }
        var filterValue = "";
        if (spaceName) {
            filterValue = spaceName.toLowerCase();
        }
        var result = this._spaces
            .filter(function (space) { return !space.deleted; })
            .filter(function (space) { return _this.selectedSpaces.indexOf(space) === -1; })
            .filter(function (space) { return space.name.toLowerCase().indexOf(filterValue) !== -1; });
        return result;
    };
    /**
     * Перебирает список email'ов и возвращает ошибку валидации с первым некорректным email-ом. Если все email'ы
     * корректны - возвращает null.
     *
     * @param control Контрол для валидации.
     *
     * @return ValidationErrors Ошибка валидации.
     */
    UsersAndSpacesInputComponent.prototype._emailListValidator = function (control) {
        if (control.value === undefined) {
            return null;
        }
        for (var _i = 0, _a = control.value; _i < _a.length; _i++) {
            var email = _a[_i];
            if (!Constants.EMAIL_REGEXP.test(email)) {
                return { incorrectEmail: email };
            }
        }
        return null;
    };
    return UsersAndSpacesInputComponent;
}());
export { UsersAndSpacesInputComponent };
