var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { TranslateService } from '@ngx-translate/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { LangActionType } from '../actions';
/**
 * Side-эффекты на события, связанные с языком интерфейса.
 */
var LangEffects = /** @class */ (function () {
    //region Ctor
    function LangEffects(actions$, translateService) {
        var _this = this;
        this.actions$ = actions$;
        this.translateService = translateService;
        //endregion
        //region Effects
        /**
         * Обработка события изменения языка.
         */
        this.changeLangEffect$ = this.actions$
            .pipe(ofType(LangActionType.CHANGE), map(function (action) { return action.payload; }), tap(function (lang) { return _this.translateService.use(lang); }));
    }
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], LangEffects.prototype, "changeLangEffect$", void 0);
    return LangEffects;
}());
export { LangEffects };
