import { BroadcastMessagesDlgComponent } from "src/app/root/containers/broadcast-messages-dlg/broadcast-messages-dlg.component";
import { SendToExternalSystemDlgComponent } from "src/app/root/containers/documents-external-system-dlg/send-to-external-system-dlg.component";
import { MobileDocumentUploadComponent } from "src/app/root/containers/mobile-document-upload/mobile-document-upload.component";
import { MoveDocumentsDlgComponent } from "src/app/root/containers/move-document-to-another-space-dlg/move-documents-dlg.component";
import { UserSpacesDlgComponent } from "src/app/root/containers/users-spaces-dlg/user-spaces-dlg.component";
import { FeedbackDlgComponent } from "./feedback-dlg/feedback-dlg.component";
import { FirstUploadDlgComponent } from "./first-upload-dlg/first-upload-dlg.component";
import { ForbiddenPageComponent } from "./forbidden-page/forbidden-page.component";
import { MobileMainComponent } from "./mobile-main/mobile-main.component";
import { RecognitionTaskCreatedDlgComponent } from "./recognition-task-created-dlg/recognition-task-created-dlg.component";
import { RecognitionTaskErrorDlgComponent } from "./recognition-task-error-dlg/recognition-task-error-dlg.component";
import { RootComponent } from "./root/root.component";
import { VersionPageComponent } from "./version-page/version-page.component";
export { FirstUploadDlgComponent } from "./first-upload-dlg/first-upload-dlg.component";
export { FeedbackDlgComponent } from "./feedback-dlg/feedback-dlg.component";
export { ForbiddenPageComponent } from "./forbidden-page/forbidden-page.component";
export { MobileMainComponent } from "./mobile-main/mobile-main.component";
export { RecognitionTaskErrorDlgComponent } from "./recognition-task-error-dlg/recognition-task-error-dlg.component";
export { RootComponent } from "./root/root.component";
export { VersionPageComponent } from "./version-page/version-page.component";
export { RecognitionTaskCreatedDlgComponent } from "./recognition-task-created-dlg/recognition-task-created-dlg.component";
/**
 * Умные компоненты, которые создаются в runtime'е.
 */
export var entryComponents = [
    FeedbackDlgComponent,
    FirstUploadDlgComponent,
    RecognitionTaskErrorDlgComponent,
    RecognitionTaskCreatedDlgComponent,
    UserSpacesDlgComponent,
    BroadcastMessagesDlgComponent,
    SendToExternalSystemDlgComponent,
    MoveDocumentsDlgComponent,
];
/**
 * Все "умные" компоненты корневого модуля.
 */
export var containers = [
    ForbiddenPageComponent,
    MobileMainComponent,
    MobileDocumentUploadComponent,
    RootComponent,
    VersionPageComponent
].concat(entryComponents);
