var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ServerSideErrorActionType } from '../actions';
/**
 * Начальное состояние по данным произошедшей ошибки.
 */
export var initialState = {
    // Данных нет.
    response: undefined
};
/**
 * Функция, отвечающая за обновление состояния данных об ошибке на сервере в ответ на происходящие в
 * приложении события.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 */
export function serverSideErrorReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        // На событие возникновения ошибки на сервере сохраняем ответ от сервера.
        case ServerSideErrorActionType.OCCURRED: {
            var response = action.payload;
            return __assign({}, state, { response: response });
        }
    }
    return state;
}
/**
 * Возвращает ответ от сервера на произошедшую ошибку на сервере.
 *
 * @param state Состояние данных об ошибке на сервере.
 */
export var getServerSideErrorResponse = function (state) { return state.response; };
