import { NgModule } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { directives } from "src/app/common/directivies/directives";
import { components, entryComponents } from "./components";
import { langs } from "./i18n";
import { modules } from "./modules";
import { modulesForExport } from "./modules";
import { pipes } from "./pipes";
import { providers } from "./providers";
import { LangService } from "./services/lang.service";
import { validators } from "./validators";

@NgModule({
    imports: [
        ...modules
    ],
    declarations: [
        ...components,
        ...directives,
        ...pipes,
        ...validators,
    ],
    providers: [
        ...providers,
    ],
    exports: [
        ...modulesForExport,
        ...components,
        ...directives,
        ...pipes,
        ...validators,
    ],
    entryComponents: [
        ...entryComponents
    ]
})
export class EnteraCommonModule {

    constructor(
        private translateService: TranslateService,
        private langService: LangService
    ) {
        // На старте модуля загружаем его i18n-сообщения.
        this.langService.loadTranslations(this.translateService, langs);
    }
}
