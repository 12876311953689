import { Component } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { Inject } from "@angular/core";
import { OnInit } from "@angular/core";
import { ChangeDetectorRef } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Validators } from "@angular/forms";
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from "@angular/material";
import { Store } from "@ngrx/store";
import { select } from "@ngrx/store";

import { RootState } from "rootStore";
import { Subject } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { takeUntil } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { withLatestFrom } from "rxjs/operators";
import { skip } from "rxjs/operators";
import { SendUserPhoneProps } from "src/app/root/store/actions/props/send-user-phone-props";
import { environment } from "src/environments/environment";
import { CurrentUserInfo } from "../../models";
import { ApiResponse } from "../../models";
import { UserService } from "../../services/user.service";
import { PhoneConfirmDialogInitAction } from "../../store/actions";
import { PhoneConfirmDialogSendSmsAction } from "../../store/actions";
import { PhoneConfirmDialogConfirmCodeAction } from "../../store/actions";
import { phoneConfirmDlgSmsSentSelector } from "../../store/selectors";
import { phoneConfirmDlgSmsSendingSelector } from "../../store/selectors";
import { phoneConfirmDlgSmsSentErrorSelector } from "../../store/selectors";
import { phoneConfirmDlgCodeConfirmSelector } from "../../store/selectors";
import { phoneConfirmDlgCodeConfirmingSelector } from "../../store/selectors";
import { phoneConfirmDlgCodeConfirmErrorSelector } from "../../store/selectors";

/**
 * Компонент диалога подтвержднения телефона.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'phone-confirm-dlg',
    templateUrl: './phone-confirm-dlg.component.html',
    styleUrls: ['./phone-confirm-dlg.component.scss'],
})
export class PhoneConfirmDlgComponent implements OnInit {
    //region Public fields

    /**
     * Контрол ввода телефона.
     */
    phoneControl: FormControl = new FormControl("");

    /**
     * Контрол ввода кода подтверждения.
     */
    codeControl: FormControl = new FormControl("");

    /**
     * Телефон был установлен у пользователя?
     */
    phonePreset$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    /**
     * Смс отправлена?
     */
    smsSent$: Observable<boolean>;

    /**
     * Смс отослана хотя бы раз?
     */
    smsOnceSent$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    /**
     * В диалоге выполянется запрос на отправку sms?
     */
    smsSending$: Observable<boolean>;

    /**
     * Ошибка при отпраке смс.
     */
    smsSendError$: Observable<ApiResponse>;

    /**
     * В диалоге выполянется запрос на подтверждение кода?
     */
    codeConfirming$: Observable<boolean>;

    /**
     * Ошибка при подтверждении кода.
     */
    codeConfirmError$: Observable<ApiResponse>;

    /**
     * Таймер обратного отсчета. Излучает готовую строку для представления. Когда таймер заканчиавется излучает null.
     */
    timer$: BehaviorSubject<string> = new BehaviorSubject(null);

    //endregion
    //region Private fields

    /**
     * Сабж остановки подписок. Работает на освнове takeUntil.
     */
    private _stopSubscriptions$: Subject<void> = new Subject();

    /**
     * Флаг блокировки контрола телефона, при отправке кода на проверку.
     */
    private _phoneDisableTag: boolean = false;

    /**
     * Информация о пользователе.
     */
    private readonly _user: CurrentUserInfo;

    /**
     * Дейтвие при удачном подтверждении телефона.
     */
    private readonly _successCallback: Function;

    //endregion
    //region Ctor

    /**
     * Конструктор компонента диалога подтверждения телефона.
     *
     * @param _dialogRef Компонент диалога.
     * @param data Информация о пользователе и действия на удачное завершение подтверждения.
     * @param _cd Севрис проверки изменения в компоненте.
     * @param _store Хранилище.
     * @param _userService Сервсис для работы с пользователем.
     */
    constructor(
        private _dialogRef: MatDialogRef<PhoneConfirmDlgComponent>,
        @Inject(MAT_DIALOG_DATA) data: {user: CurrentUserInfo, callback: Function},
        private _cd: ChangeDetectorRef,
        private _store: Store<RootState>,
        private _userService: UserService
    ) {

        this._user = data.user;
        this._successCallback = data.callback;
    }

    //endregion
    //region Hooks

    ngOnInit(): void {

        this._store.dispatch(new PhoneConfirmDialogInitAction());

        this.smsSent$ = this._store
            .pipe(
                takeUntil(this._stopSubscriptions$),
                select(phoneConfirmDlgSmsSentSelector),
                tap(success => {

                    if (success) {

                        this._startTimer();
                    }
                })
            );

        this.smsSent$
            .pipe(
                filter(Boolean),
            )
            .subscribe(() => this.smsOnceSent$.next(true));

        this.smsSending$ = this._store
            .pipe(
                takeUntil(this._stopSubscriptions$),
                select(phoneConfirmDlgSmsSendingSelector),
            );

        this.smsSendError$ = this._store
            .pipe(
                takeUntil(this._stopSubscriptions$),
                select(phoneConfirmDlgSmsSentErrorSelector),
            );

        this.smsSendError$
            .subscribe(error => {

                if (error) {

                    setTimeout(() => {

                        this.phoneControl.enable();
                        this.phoneControl.setErrors({'server': true});
                        this._cd.markForCheck();
                    });
                }
            });

        this.codeConfirming$ = this._store
            .pipe(
                takeUntil(this._stopSubscriptions$),
                select(phoneConfirmDlgCodeConfirmingSelector),
                tap((progress) => {

                    if (progress) {

                        if (this.phoneControl.enabled) {

                            this._phoneDisableTag = true;
                            this.phoneControl.disable();
                        }
                        this.codeControl.disable();
                    }
                    else {

                        this.codeControl.enable();
                        if (this._phoneDisableTag) {

                            this.phoneControl.enable();
                            this._phoneDisableTag = false;
                        }
                    }
                })
            );

        this.codeConfirmError$ = this._store
            .pipe(
                takeUntil(this._stopSubscriptions$),
                select(phoneConfirmDlgCodeConfirmErrorSelector),
            );

        this.codeConfirmError$
            .subscribe(error => {

                setTimeout(() => {

                    if (error) {

                        this.codeControl.setErrors({'server': true});
                    }
                    else {

                        this.codeControl.setErrors(null);
                    }
                    this._cd.markForCheck();
                });
            });

        this._store
            .pipe(
                takeUntil(this._stopSubscriptions$),
                select(phoneConfirmDlgCodeConfirmSelector),
                filter(Boolean)
            )
            .subscribe(() => {

                this._dialogRef.close();
            });

        this.timer$
            .pipe(
                filter(value => !value),
                skip(1),
                withLatestFrom(this.codeConfirming$)
            )
            .subscribe(([_, codeProgress]) => {

                if (!codeProgress) {

                    this.phoneControl.enable();
                } else {

                    this._phoneDisableTag = true;
                }
            });

        this.codeControl.setValidators([
            Validators.required,
            Validators.maxLength(4)
        ]);

        if (this._user) {

            if (this._user.phoneConfirm) {

                this._dialogRef.close();
            }
        }

        this.phoneControl.markAsTouched();

        if (this._user.phone) {

            // Вернуть когда сможем починить
            // this.phoneControl.setValue("+77058822859");
            // this.phonePreset$.next(true);
        }
    }

    ngOnDestroy(): void {

        this._stopSubscriptions$.next();
        this._stopSubscriptions$.complete();
        this.timer$.complete();
        this.phonePreset$.complete();
        this.smsOnceSent$.complete();
    }

    //endregion
    //region Getters

    /**
     * Возвращает список кодов стран для определения кода номера телефона.
     */
    get countryCode() {

        if (environment.global) {

            return ["in", "ae"];
        }
        else {

            return ["ru", "kz"];
        }
    }

    //endregion
    //region Events

    /**
     * Закрытие диалогового окна.
     *
     * @param event Событие клика.
     */
    cancelHandler(event: any) {

        this._dialogRef.close();
    }

    /**
     * Хендлер отправки СМС.
     *
     */
    sendSmsHandler() {

        if (this.phoneControl.enabled && this.phoneControl.valid) {

            this._store.dispatch(new PhoneConfirmDialogSendSmsAction(this.phoneControl.value));
            this.phoneControl.disable();
        }
    }

    /**
     * Хендлер повторной отправки СМС.
     *
     * @param event Событие клика.
     */
    sendSmsAgainHandler(event: Event) {

        event.stopPropagation();
        this.sendSmsHandler();
    }

    /**
     * Хендлер отправки кода подтверждения.
     */
    confirmCodeHandler() {

        if (this.codeControl.valid) {

            const actionValue : SendUserPhoneProps = {
                phone: this.phoneControl.value.trim(),
                code: this.codeControl.value.trim(),
                callback: this._successCallback,
            }

            this._store.dispatch(new PhoneConfirmDialogConfirmCodeAction(actionValue))
        }
    }

    /**
     * Хендлер отправки телефона по клавише enter.
     *
     * @param $event
     */
    sendSmsByEnterHandler($event: KeyboardEvent) {

        if ($event.key == "Enter") {

            this.sendSmsHandler();
        }
    }

    /**
     * Хендлер подтверждения кода по клавише enter.
     *
     * @param $event
     */
    confirmCodeByEnterHandler($event: KeyboardEvent) {

        if ($event.key == "Enter") {

            this.confirmCodeHandler();
        }
    }

    //endregion
    //region Private

    /**
     * Функция запуска таймера.
     */
    private _startTimer(): void {

        let count = 30;
        let seconds = ("0" + count).slice(-2);
        this.timer$.next("0:" + seconds);
        let interval = setInterval(() => {

            count--;

            if (count < 0) {

                this.timer$.next(null);
                clearInterval(interval);
            }

            else {

                let seconds = ("0" + count).slice(-2);
                this.timer$.next("0:" + seconds);
            }

        }, 1000);

    }

    //endregion
}
