var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EventEmitter } from "@angular/core";
import { NgControl } from "@angular/forms";
import { FormControl } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material";
import { MatSelect } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { MatSelectSearchComponent } from "ngx-mat-select-search";
import { Subject } from "rxjs";
import { combineLatest } from "rxjs";
import { Observable } from "rxjs";
import { of } from "rxjs";
import { fromEvent } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { take } from "rxjs/operators";
import { takeUntil } from "rxjs/operators";
import { startWith } from "rxjs/operators";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { debounceTime } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { mergeMap } from "rxjs/operators";
import { withLatestFrom } from "rxjs/operators";
import { isOptionArray } from "src/app/common/models/option";
import { isOption } from "src/app/common/models/option";
import { UtilsService } from "src/app/common/services/utils.service";
/**
 * Компонент поля формы для выбора из списка значений с возможностью поиска.
 */
var EnteraSelectComponent = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор компоненты поля формы.
     *
     * @param _ngControl Директива для биндинга контрола к DOM-дереву.
     * @param _translateService Сервис для работы с i18n-сообщениями.
     * @param _utilService Утилиты.
     */
    function EnteraSelectComponent(_ngControl, _translateService, _utilService) {
        var _this = this;
        this._ngControl = _ngControl;
        this._translateService = _translateService;
        this._utilService = _utilService;
        //endregion
        //region Inputs
        /**
         * Входящие данные - возможность выбора нескольких значений включена?
         */
        this.multiselect = false;
        /**
         * Входящие данные - функция постраничной динамической ajax-загрузки значения для выбора.
         */
        this.pagedSearchFn = null;
        /**
         * Входящие данные - функция динамической ajax-загрузки значения для выбора.
         */
        this.searchFn = null;
        /**
         * Входящие данные - функция предварительной загрузки значений для выбора.
         *
         * Функция вызывается в случае, если поисковая строка пуста.
         */
        this.preloadSearchFn = null;
        /**
         * Входящие данные - задержка перед выполнением поиска после последнего нажатия на клавиши.
         */
        this.searchDebounce = 170;
        /**
         * Входящие данные - кнопка очистки выбранного значения включена?
         */
        this.clearBtnEnabled = true;
        /**
         * Входящие данные - поле обязательно для заполнения?
         */
        this.required = false;
        /**
         * Входящие данные - отображать custom ошибку для поля?
         */
        this.customError = false;
        /**
         * Размер страницы поиска.
         */
        this.pageSize = 10;
        /**
         * Прелоадинг значения выпадашки при асинхронном поиске. Ищется только по id.
         */
        this.preloading = false;
        /**
         * Компактное отображение для таблицы включено?
         */
        this.tableCompact = false;
        /**
         * Минимальная длина поискового запроса.
         */
        this.minLengthToSearch = 3;
        //endregion
        //region Outputs
        /**
         * Исходящие данные - событие изменения выбранного элемента.
         */
        this.selectionChange = new EventEmitter();
        //endregion
        //region Fields
        /**
         * Максимальная длина поискового запроса.
         */
        this.maxLengthToSearch = 1000;
        /**
         * Флаг, что длина поискового запроса достигла нужной длины для выполнения запроса.
         */
        this.searchLengthReached = false;
        /**
         * Флаг, что количество выбранных элементов достигла максимально разрешенного количества выбранных элементов.
         */
        this.maxSelectedReached = false;
        /**
         * FormControl привязанный к выпадашке.
         */
        this.valueControl = new FormControl();
        /**
         * FormControl привязанный к полю поиска.
         */
        this.searchControl = new FormControl();
        /**
         * Динамически загруженные варианты для выбора согласно поисковому запросу.
         */
        this.searchOptions$ = new BehaviorSubject([]);
        /**
         * Динамически загруженные варианты для выбора без выбранных вариантов.
         */
        this.searchOptionsWithoutSelected$ = new Observable();
        /**
         * Флаг выполняющейся загрузки динамических вариантов выбора.
         */
        this.loading$ = new BehaviorSubject(false);
        /**
         * Флаг того, что часть динамических вариантов выбора загружена.
         */
        this.loaded = false;
        /**
         * Флаг того, что среди выбранных значений есть вариант, обозначающий 'null'-значение.
         */
        this.isNullSelected = false;
        /**
         * Селект открыт?
         */
        this.opened$ = new Subject();
        /**
         * Выбранное значение.
         */
        this.selected$ = new BehaviorSubject(null);
        /**
         * Поле для хранения значений для выбора.
         */
        this._options = [];
        /**
         * Поле для хранения флага отключения контрола при отсутствии вариантов для выбора.
         */
        this._disableOnEmptyOptions = true;
        /**
         * Поле для хранения флага отключения контрола.
         */
        this._selectDisabled = false;
        /**
         * Флаг выполняющейся загрузки динамических вариантов выбора. Внутренее отображение.
         */
        this._loading = false;
        /**
         * Объект глобальной отписки.
         */
        this._globalUnsubscribe$ = new Subject();
        /**
         * Текущая страница для AJAX запроса поиска. Становится -1, если нет больше страниц для поиска.
         */
        this._currentSearchPage = 1;
        /**
         * Callback, когда выбранное в выпадашке значение изменилось.
         */
        this._changeCallback = function (_) { };
        /**
         * Callback, когда пользователь начал взаимодействовать с выпадашкой.
         */
        this._touchCallback = function (_) { };
        /**
         * Функция для сравнения вариантов выбора по id.
         */
        this._compareWith = function (o1, o2) { return o1 && o2 && o1["id"] === o2["id"]; };
        /**
         * Функция для сравнения вариантов выбора по заданному полю.
         */
        this._compareWithField = function (o1, o2) { return o1 && o2 && o1[_this.valueField] === o2[_this.valueField]; };
        this._ngControl.valueAccessor = this;
    }
    Object.defineProperty(EnteraSelectComponent.prototype, "optionField", {
        /**
         * Возвращает поле варианта выбора, которое отображается в выпадашке. По умолчанию "name".
         */
        get: function () {
            return (this._optionField ? this._optionField : "name");
        },
        /**
         * Входящие данные - какое поле варианта выбора отображать в выпадашке.
         */
        set: function (optionField) {
            this._optionField = optionField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnteraSelectComponent.prototype, "resultField", {
        /**
         * Возвращает поле выбранного варианта, которое отображается. По умолчанию "name".
         */
        get: function () {
            return (this._resultField ? this._resultField : "name");
        },
        /**
         * Входящие данные - какое поле выбранного варианта отображать.
         */
        set: function (resultField) {
            this._resultField = resultField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnteraSelectComponent.prototype, "options", {
        /**
         * Возвращает значения для выбора.
         */
        get: function () {
            return this._options;
        },
        /**
         * Входящие данные - статические значения для выбора.
         */
        set: function (options) {
            this._options = options;
            this._disableOrEnableControl();
            this.searchControl.setValue("");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnteraSelectComponent.prototype, "disableOnEmptyOptions", {
        /**
         * Входящие данные - отключать выпадашку, когда вариантов для выбора нет?
         */
        set: function (disableOnEmptyOptions) {
            this._disableOnEmptyOptions = disableOnEmptyOptions;
            this._disableOrEnableControl();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnteraSelectComponent.prototype, "selectDisabled", {
        /**
         * Входящие данные - отключение контрола.
         */
        set: function (disabled) {
            this._selectDisabled = disabled;
            this._disableOrEnableControl();
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Hooks
    /**
     * Логика, выполняющаяся при инициализации компоненты после установки всех свойств компоненты.
     */
    EnteraSelectComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._updateControlState();
        // Поиск среди статически заданных вариантов выбора.
        this.filteredOptions$ = this.searchControl.valueChanges
            .pipe(startWith(""), map(function (search) { return _this._filterStaticOptionsBySearch(search); }));
        this.searchOptionsWithoutSelected$ = combineLatest([this.searchOptions$, this.selected$]).pipe(map(function (_a) {
            var options = _a[0], selected = _a[1];
            return _this._getNotSelectedOptions(options, selected);
        }));
        this.valueControl.valueChanges
            .pipe(takeUntil(this._globalUnsubscribe$))
            .subscribe(function (value) { return _this._setSelected(value); });
        if (!!this._ngControl.value) {
            this.valueControl.setValue(this._ngControl.value);
        }
        this._ngControl.statusChanges
            .pipe(takeUntil(this._globalUnsubscribe$))
            .subscribe(function () { return _this._updateControlState(); });
        this.loading$
            .pipe(takeUntil(this._globalUnsubscribe$))
            .subscribe(function (value) { return _this._loading = value; });
    };
    /**
     * Логика, вызывающаяся после инициализации представления компонента.
     */
    EnteraSelectComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        // Поиск динамически подгружаемых вариантов выбора.
        if (this.pagedSearchFn) {
            // Постраничный запрос активен
            var inProgress_1 = false;
            var oldSearch_1;
            this.searchControl.valueChanges
                .pipe(takeUntil(this._globalUnsubscribe$), debounceTime(this.searchDebounce), startWith(""), filter(function (search) { return oldSearch_1 !== search; }), filter(function (search) { return _this._maxLengthReachedOpenAndSearch(search); }), switchMap(function (search) {
                oldSearch_1 = search;
                return _this._pagedSearchFnAfterSearchChange(search);
            }))
                .subscribe(function (options) {
                if (options.length < _this.pageSize) {
                    _this._currentSearchPage = -1;
                }
                _this.searchOptions$.next(options);
            });
            // Подписываемся на скролл панели
            this.matSelect.openedChange.pipe(takeUntil(this._globalUnsubscribe$), 
            // Панель открыта
            filter(Boolean), 
            // Переключаемся на событие скролла
            mergeMap(function () {
                return fromEvent(_this.matSelect.panel.nativeElement, "scroll");
            }), debounceTime(50), map(function (event) { return event.target; }), map(function (srcElement) {
                return srcElement.scrollHeight - (srcElement.scrollTop + srcElement.clientHeight);
            }), filter(function (offset) { return offset < 10; }), 
            // Нет больше страниц для поиска
            filter(function () { return _this._currentSearchPage !== -1; }), withLatestFrom(this.searchControl.valueChanges), filter(function () { return !inProgress_1; }), mergeMap(function (_a) {
                var _ = _a[0], search = _a[1];
                inProgress_1 = true;
                return _this._pagedSearchFnAfterScroll(search);
            }), map(function (newOptions) {
                inProgress_1 = false;
                return _this._addNewSearchPageOptions(newOptions);
            }))
                .subscribe(function (options) { return _this.searchOptions$.next(options); });
        }
        else if (this.searchFn) {
            var oldSearch_2;
            this.searchControl.valueChanges
                .pipe(takeUntil(this._globalUnsubscribe$), debounceTime(this.searchDebounce), startWith(""), filter(function (search) { return oldSearch_2 !== search; }), filter(function (search) {
                var result = search.length <= 1000;
                if (!result) {
                    _this.openAndSearch(search.substring(0, 1000));
                }
                return result;
            }), switchMap(function (search) {
                _this.searchOptions$.next([]);
                if (search.length < _this.minLengthToSearch) {
                    if (_this.preloadSearchFn && oldSearch_2 && search.length === 0) {
                        oldSearch_2 = search;
                        _this.loading$.next(true);
                        return _this.preloadSearchFn();
                    }
                    else {
                        oldSearch_2 = search;
                        _this.searchLengthReached = false;
                        return of([]);
                    }
                }
                else {
                    oldSearch_2 = search;
                    _this.loading$.next(true);
                    _this.searchLengthReached = false;
                    return _this.searchFn(search);
                }
            }))
                .subscribe(function (options) {
                if (options.length < _this.pageSize) {
                    _this._currentSearchPage = -1;
                }
                _this.searchOptions$.next(options);
            });
        }
        // Подписываемся на изменение набора динамически загруженных вариантов выбора.
        // Это тот момент, когда загрузка значений завершилась.
        this.searchOptions$
            .pipe(takeUntil(this._globalUnsubscribe$))
            .subscribe(function (options) {
            _this.loading$.next(false);
            _this.loaded = !!(options && options.length);
        });
        this.opened$
            .pipe(takeUntil(this._globalUnsubscribe$), switchMap(function (opened) {
            _this.searchLengthReached = false;
            if (_this.preloadSearchFn && opened) {
                _this.loading$.next(true);
                return _this.preloadSearchFn();
            }
            else {
                return of([]);
            }
        }))
            .subscribe(function (option) {
            _this.searchOptions$.next(option);
        });
    };
    /**
     * Логика, выполняющаяся при уничтожении компоненты.
     */
    EnteraSelectComponent.prototype.ngOnDestroy = function () {
        this._globalUnsubscribe$.complete();
    };
    Object.defineProperty(EnteraSelectComponent.prototype, "compareWith", {
        //endregion
        //region Getters and Setters
        /**
         * Возвращает функцию для сравнения вариантов выбора для mat-select.
         */
        get: function () {
            if (this.userCompareWith) {
                return this.userCompareWith;
            }
            if (this.valueField) {
                return this._compareWithField;
            }
            return this._compareWith;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnteraSelectComponent.prototype, "searchPlaceholder$", {
        /**
         * Возвращает Observable placeholder'а, пока строка поиска пуста.
         */
        get: function () {
            if (this.searchPlaceholder) {
                return of(this.searchPlaceholder);
            }
            else {
                return this._translateService.get("search.label");
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnteraSelectComponent.prototype, "startSearchPlaceholder$", {
        /**
         * Возвращает Observable placeholder'а, что пользователю нужно начать вводить, чтобы начался поиск.
         */
        get: function () {
            if (this.startSearchPlaceholder) {
                return of(this.startSearchPlaceholder);
            }
            else {
                return this._translateService.get("search.startTyping");
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnteraSelectComponent.prototype, "noSearchResultsPlaceholder$", {
        /**
         * Возвращает Observable placeholder'а, когда поиск не вернул результатов.
         */
        get: function () {
            if (this.noSearchResultsPlaceholder) {
                return of(this.noSearchResultsPlaceholder);
            }
            else {
                return this._translateService.get("search.empty.result");
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnteraSelectComponent.prototype, "isStartTypingPlaceholderVisible", {
        /**
         * Placeholder для приглашения ввода для начала поиска виден?
         */
        get: function () {
            return (!this.options || this.options.length === 0)
                && (!!this.pagedSearchFn || !!this.searchFn)
                && !this._loading
                && !this.loaded
                && (!this.searchControl.value || !this.searchLengthReached)
                && !this.searching;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnteraSelectComponent.prototype, "isSelectedValueExists", {
        /**
         * Варианты с выбранными значениями нужно отображать?
         */
        get: function () {
            var result;
            if (this.multiselect && this.selected$.getValue() instanceof Array) {
                result = this.selected$.getValue().length > 0;
            }
            else if (this.selected$.getValue() instanceof Object) {
                result = !!this.selected$.getValue().id;
            }
            else {
                result = !!this.selected$.getValue();
            }
            return result;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnteraSelectComponent.prototype, "isNoSearchResultsVisible", {
        /**
         * Текст, что ничего не найдено виден?
         */
        get: function () {
            return (!this.isStartTypingPlaceholderVisible
                && !this._loading
                && !this.loaded
                && !!(this.pagedSearchFn || this.searchFn)
                && !!this.searchControl.value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnteraSelectComponent.prototype, "isNullOptionVisible", {
        /**
         * Должна ли отображаться опция "NULL"?
         */
        get: function () {
            return this.hasNullOption
                && (!this.pagedSearchFn && !this.searchFn
                    || !this.isNullSelected && !!this.pagedSearchFn
                    || !this.isNullSelected && !!this.searchFn);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnteraSelectComponent.prototype, "searchText", {
        /**
         * Текст поиска.
         */
        get: function () {
            return this.selectSearch
                && this.selectSearch.searchSelectInput
                && this.selectSearch.searchSelectInput.nativeElement
                && this.selectSearch.searchSelectInput.nativeElement.value
                || null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnteraSelectComponent.prototype, "searching", {
        /**
         * В данный момент выполняется поиск?
         */
        get: function () {
            return this.searchEnabled
                && (!!this.searchFn || !!this.pagedSearchFn)
                && !!this.searchText
                && this.searchText.length >= this.minLengthToSearch;
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Public
    /**
     * Программная установка значения.
     *
     * Тут выполняется асинхронная подгрузка значения, если это необходимо.
     *
     * @param value Значение для установки.
     */
    EnteraSelectComponent.prototype.writeValue = function (value) {
        var _this = this;
        if (this.valueControl.value !== value) {
            if (value) {
                if (value instanceof Array) {
                    this.writeValues(value);
                }
                else {
                    this.writeSingleValue(value);
                }
            }
            else {
                this.valueControl.setValue(null);
            }
        }
        setTimeout(function () { return _this.valueControl.markAsTouched(); });
    };
    /**
     * Программная установка значения.
     *
     * Тут выполняется асинхронная подгрузка одного значения, если это необходимо.
     *
     * @param value Значение для установки.
     */
    EnteraSelectComponent.prototype.writeSingleValue = function (value) {
        var _this = this;
        if (value && value.id && value.id != 'null' && (this.pagedSearchFn || this.searchFn) && this.preloading) {
            if (this.pagedSearchFn) {
                var subs_1 = this.pagedSearchFn(null, null, null, [value.id])
                    .subscribe(function (opts) {
                    if (opts.length) {
                        _this.valueControl.setValue(opts[0]);
                    }
                    else {
                        _this.valueControl.setValue(value);
                    }
                    subs_1.unsubscribe();
                });
            }
            else {
                this.searchFn(null, [value.id])
                    .pipe(take(1))
                    .subscribe(function (opts) {
                    if (opts.length) {
                        _this.valueControl.setValue(opts[0]);
                    }
                    else {
                        _this.valueControl.setValue(value);
                    }
                });
            }
        }
        else if (value && value.id && value.id != 'null' && this.options.length) {
            var original = this.options.filter(function (option) { return option.id === value.id; })[0];
            if (original) {
                this.valueControl.setValue(__assign({}, original, value));
            }
            else {
                this.valueControl.setValue(value);
            }
        }
        else {
            this.valueControl.setValue(value);
            this._options = this.options.concat([
                value
            ]);
        }
    };
    /**
     * Программная установка значения.
     *
     * Тут выполняется асинхронная подгрузка массива значений, если это необходимо.
     *
     * Если значение для установки отлично от значения контрола, то:
     *
     *  - Если есть функция динамической подгрузки и включен прелоадинг, то ищет значения с переданным массивом id
     *  после подгрузки значений.
     *  - Иначе, если есть статические значения для выбора - ищет по id каждого элемента значения для установки среди
     *  статических значений. Для найденных значений происходит "слитие" объектов, для остальных - просто
     *  устанавливается значение элемента.
     *  - При наличии вариантов с id 'null', добавляет первое такое значение в массив значений.
     *
     * @param values Значения для установки.
     */
    EnteraSelectComponent.prototype.writeValues = function (values) {
        var _this = this;
        var valuesWithId = values.filter(function (value) { return value && value.id && value.id !== "null"; });
        var valuesWithoutId = values.filter(function (value) { return !value || !value.id || value.id === "null"; });
        var nullValueToSet = valuesWithoutId.length ? [valuesWithoutId[0]] : [];
        var valuesToSet = [];
        if ((this.pagedSearchFn || this.searchFn) && this.preloading && valuesWithId.length) {
            if (this.pagedSearchFn) {
                var subs_2 = this.pagedSearchFn(null, null, this.maxSelected ? this.maxSelected : EnteraSelectComponent.FIRST_LOAD_PAGE_SIZE, valuesWithId.map(function (val) { return val.id; }))
                    .subscribe(function (opts) {
                    if (opts.length) {
                        valuesToSet = valuesToSet.concat(opts);
                    }
                    else {
                        valuesToSet = valuesToSet.concat(valuesWithId);
                    }
                    _this.valueControl.setValue(valuesToSet.concat(nullValueToSet));
                    subs_2.unsubscribe();
                });
            }
            else {
                var subs_3 = this.searchFn(null, valuesWithId.map(function (val) { return val.id; }))
                    .subscribe(function (opts) {
                    if (opts.length) {
                        valuesToSet = valuesToSet.concat(opts);
                    }
                    else {
                        valuesToSet = valuesToSet.concat(valuesWithId);
                    }
                    _this.valueControl.setValue(valuesToSet.concat(nullValueToSet));
                    subs_3.unsubscribe();
                });
            }
        }
        else if (this.options.length) {
            var original_1;
            valuesWithId.forEach(function (value) {
                original_1 = _this.options
                    .filter(function (option) { return option.id === value.id; })
                    .find(function () { return true; });
                if (original_1) {
                    valuesToSet = valuesToSet.concat([__assign({}, original_1, value)]);
                }
                else {
                    valuesToSet = valuesToSet.concat([value]);
                }
            });
            this.valueControl.setValue(valuesToSet.concat(nullValueToSet));
        }
        else {
            this.valueControl.setValue(valuesWithId.concat(nullValueToSet));
        }
    };
    EnteraSelectComponent.prototype.registerOnChange = function (fn) {
        this._changeCallback = fn;
    };
    EnteraSelectComponent.prototype.registerOnTouched = function (fn) {
        this._touchCallback = fn;
    };
    /**
     * Возвращает текстовое представление варианта для выбора.
     *
     * @param option Вариант для выбора.
     *
     * @return Текстовое представление варианта для выбора.
     */
    EnteraSelectComponent.prototype.getOptionText = function (option) {
        var value = option;
        var text;
        if (this.formatOption) {
            text = this.formatOption(value);
        }
        else {
            text = value[this.optionField];
        }
        if (this.searchControl.value && text) {
            text = this._utilService.highlight(text, this.searchControl.value, "highlight");
        }
        if ((!value || !value.id || value.id === "null") && this.hasNullOption && this.nullOptionText) {
            text = this._translateService.instant(this.nullOptionText);
        }
        return text;
    };
    /**
     * Возвращает текстовое представление выбранных вариантов.
     *
     * @param selected Выбранные варианты.
     *
     * @return Текстовое представление выбранных вариантов.
     */
    EnteraSelectComponent.prototype.getResultText = function (selected) {
        var text = "";
        if (!selected || selected === "null") {
            text = this.nullOptionText;
        }
        else {
            if (isOption(selected)) {
                text = this.getOptionResultText(selected);
            }
            else if (isOptionArray(selected)) {
                text = this.getOptionResultText(selected[0]);
            }
            else {
                text = selected;
            }
        }
        return text;
    };
    /**
     * Возвращает вспомогательный текст для указания количества выбранных вариантов.
     *
     * @param selected Выбранные варианты.
     *
     * @return Вспомогательный текст для указания количества выбранных вариантов.
     */
    EnteraSelectComponent.prototype.getAdditionalResultText = function (selected) {
        if (selected && isOptionArray(selected)) {
            return this._translateService.instant("enteraSelect.multiSelect", { count: (selected.length - 1) });
        }
        else {
            return "";
        }
    };
    /**
     * Возвращает текстовое представление выбранного варианта.
     *
     * @param selected Выбранный вариант.
     *
     * @return Текстовое представление выбранного варианта.
     */
    EnteraSelectComponent.prototype.getOptionResultText = function (selected) {
        var text;
        if (!selected || !selected.id || selected.id === "null") {
            text = this.nullOptionText;
        }
        else if (this.formatResult) {
            text = this.formatResult(selected);
        }
        else {
            text = selected[this.resultField];
        }
        return text;
    };
    /**
     * Возвращает значение варианта для выбора, которое будет использоваться как значение всего контрола.
     *
     * @param option Вариант для выбора.
     *
     * @return Значение варианта для выбора.
     */
    EnteraSelectComponent.prototype.getOptionValue = function (option) {
        var value = option;
        if (this.valueField) {
            value = option[this.valueField];
        }
        return value;
    };
    /**
     * Возвращает значение для варианта выбора обозначающего 'null'-значение.
     *
     * @return Значение для варианта выбора обозначающего 'null'-значение.
     */
    EnteraSelectComponent.prototype.getNullOptionValue = function () {
        if (this.valueField) {
            return "null";
        }
        else {
            return { id: "null" };
        }
    };
    /**
     * Открывает выпадашку, подставляет заданную поисковую строку и запускает поиск.
     *
     * @param search Поисковая строка.
     */
    EnteraSelectComponent.prototype.openAndSearch = function (search) {
        if (!this.matSelect.panelOpen) {
            this.matSelect.open();
        }
        this.selectSearch.searchSelectInput.nativeElement.value = search;
        this.searchControl.setValue(search);
    };
    //endregion
    //region Events
    /**
     * Обработчик клика очистки значений, выбранных в выпадашке.
     *
     * Предотвращает дальнейшее вслытие, так как иначе выпадашка откроется.
     *
     * @param event Событие клика очистки значений, выбранных в выпадашке.
     */
    EnteraSelectComponent.prototype.clearBtnClickHandler = function (event) {
        this.valueControl.reset(null);
        event.stopPropagation();
    };
    /**
     * Обработчик нажатия кнопок в поле поиска.
     *
     * Останавливает кнопки HOME и END от всплытия, так как иначе mat-select сделает event.preventDefault() для них,
     * что не даст в поле поиска перемещаться в начало или конец поля.
     *
     * @param event Событие нажатия кнопок в поле поиска.
     */
    EnteraSelectComponent.prototype.keydownHandler = function (event) {
        if (event.keyCode === 35 || event.keyCode === 36) {
            event.stopPropagation();
        }
    };
    /**
     * Обработчик открытия выпадающего списка.
     */
    EnteraSelectComponent.prototype.openSelectHandle = function (opened) {
        this.opened$.next(opened);
    };
    /**
     * Обработчик события изменения выбранного элемента.
     *
     * @param event Событие выбор значения в выпадашке.
     */
    EnteraSelectComponent.prototype.selectionChangeHandler = function (event) {
        this.selectionChange.next(event);
    };
    //endregion
    //region Private
    /**
     * Фильтрует варианты для выбора согласно поисковой строке.
     *
     * @param search Поисковая строка.
     *
     * @return Отфильтрованные варианты для выбора.
     */
    EnteraSelectComponent.prototype._filterStaticOptionsBySearch = function (search) {
        var _this = this;
        search = search.toLowerCase();
        return this.options.filter(function (option) {
            return _this.getOptionText(option).toLowerCase().includes(search);
        });
    };
    /**
     * Возвращает список вариантов для выбора без выбранных вариантов.
     *
     * Также, проверяет, нет ли среди выбранных значений вариант, обозначающий 'null'-значение, и в зависимости от этого
     * меняет соответствующий флаг.
     *
     * @param options Список всех вариантов.
     * @param selected Список выбранных вариантов.
     *
     * @return Список вариантов для выбора без выбранных вариантов.
     */
    EnteraSelectComponent.prototype._getNotSelectedOptions = function (options, selected) {
        if (isOptionArray(selected)) {
            this.isNullSelected = selected.some(function (selectedOpt) { return !!selectedOpt.id && selectedOpt.id === "null"; });
            return options.filter(function (opt) {
                return !selected.some(function (selectedOpt) { return selectedOpt.id === opt.id; });
            });
        }
        else if (isOption(selected)) {
            this.isNullSelected = !!selected.id && selected.id === "null";
            return options.filter(function (opt) { return opt.id !== selected.id; });
        }
        else {
            this.isNullSelected = false;
            return options;
        }
    };
    /**
     * Включает или отключает контрол в зависимости от входящих данных.
     */
    EnteraSelectComponent.prototype._disableOrEnableControl = function () {
        if (this._selectDisabled) {
            this.valueControl.disable();
        }
        else {
            if (this._disableOnEmptyOptions
                && (!this.options || this.options.length === 0)
                && !this.pagedSearchFn
                && !this.searchFn) {
                this.valueControl.disable();
            }
            else {
                this.valueControl.enable();
            }
        }
    };
    /**
     * Обновляет значение выбранных вариантов у контрола.
     *
     * Если передается пустой массив, то присваивает контролу null-значение.
     *
     * @param value Значение выбранных вариантов у контрола.
     */
    EnteraSelectComponent.prototype._setSelected = function (value) {
        if (value instanceof Array) {
            if (value.length) {
                this.selected$.next(value);
                this.maxSelectedReached = value && this.maxSelected && this.maxSelected <= value.length;
            }
            else {
                this.valueControl.reset(null);
                this.maxSelectedReached = false;
            }
        }
        else {
            this.selected$.next(value);
        }
        this._updateControlState();
        this._changeCallback(value);
    };
    /**
     * Если превышено максимальное количество символов для поиска, открывает выпадашку подставляет заданную поисковую
     * строку и запускает поиск.
     *
     * @param search Строка для поиска.
     *
     * @return true, если количество символов строки для поиска не превысило максимального. Иначе - false.
     */
    EnteraSelectComponent.prototype._maxLengthReachedOpenAndSearch = function (search) {
        var result = search.length <= this.maxLengthToSearch;
        if (!result) {
            this.openAndSearch(search.substring(0, this.maxLengthToSearch));
        }
        return result;
    };
    /**
     * Очищает массив динамически подгруженных элементов при каждом изменении поисковой строки.
     *
     * Проверяет, имеет ли передаваемая строка минимальную длину для поиска:
     * - Если да, то осуществляет поиск с помощью функции поиска, и возращает результат.
     * - Иначе вовращает пустой массив.
     *
     * @param search Строка для поиска.
     *
     * @return Список вариантов, соответствующих поисковой строке.
     */
    EnteraSelectComponent.prototype._pagedSearchFnAfterSearchChange = function (search) {
        this.searchOptions$.next([]);
        if (search.length < this.minLengthToSearch) {
            this.searchLengthReached = false;
            return of([]);
        }
        else {
            this._currentSearchPage = 1;
            this.loading$.next(true);
            this.searchLengthReached = true;
            return this.pagedSearchFn(search, this._currentSearchPage, this.pageSize);
        }
    };
    /**
     * Осуществляет поиск с помощью функции поиска при скролле.
     *
     * @param search Строка для поиска.
     *
     * @return Список вариантов, соответствующих поисковой строке.
     */
    EnteraSelectComponent.prototype._pagedSearchFnAfterScroll = function (search) {
        this._currentSearchPage++;
        this.loading$.next(true);
        return this.pagedSearchFn("" + search, this._currentSearchPage, this.pageSize);
    };
    /**
     * Добавляет список динамически подгруженных вариантов к подгруженным ранее вариантам.
     *
     * Если список подгруженных вариантов меньше чем количество вариантов на одной странице поиска, считается, что это
     * последняя страница поиска (currentSearchPage присваивается значение -1).
     *
     * @param newOptions Список динамически подгруженных вариантов для текущей страницы.
     *
     * @return Список всех динамически подгруженных вариантов, соответствующих строке.
     */
    EnteraSelectComponent.prototype._addNewSearchPageOptions = function (newOptions) {
        if (newOptions.length < this.pageSize) {
            this._currentSearchPage = -1;
        }
        return this.searchOptions$.getValue().concat(newOptions);
    };
    /**
     * Обновляет состояние внутреннего контрола.
     */
    EnteraSelectComponent.prototype._updateControlState = function () {
        if (this._ngControl.enabled) {
            this.valueControl.enabled || this.valueControl.enable();
        }
        else {
            this.valueControl.disabled || this.valueControl.disable();
        }
        if (this._ngControl.touched) {
            this.valueControl.touched || this.valueControl.markAsTouched();
        }
        else {
            this.valueControl.untouched || this.valueControl.markAsUntouched();
        }
        this.valueControl.setErrors(this._ngControl.errors);
    };
    //region Constants
    /**
     * Количество элементов в прогружаемом списке для начальной загрузки списка выбранных элементов для мультиселекта.
     *
     * Нужно для прогрузки всех элементов списка мультиселекта. Если же не задавать это значение, то возьмет количество
     * по умолчанию, из-за чего может ограничиться количество выбранных элементов списка.
     */
    EnteraSelectComponent.FIRST_LOAD_PAGE_SIZE = 40;
    return EnteraSelectComponent;
}());
export { EnteraSelectComponent };
