import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';

import { RootState } from '../../root/store/reducers';
import { RouterActionPayload } from 'src/app/root/store/actions';
import { RouterGoAction } from 'src/app/root/store/actions';
import { routeStateSelector } from '../../root/store/selectors/router.selector';
import { RouteState } from 'src/app/root/store/reducers/router.reducer';

import { CurrentUserInfo } from '../models';

import { environment } from "../../../environments/environment";
import { UtilsService } from "./utils.service";

/**
 * Сервис, связанный с логикой перемещения по URL'ам внутри приложения.
 */
@Injectable({
    providedIn: 'root'
})
export class RouteService {
    //region Private fields

    /**
     * Состояние URL'а.
     */
    private _routeState: RouteState = null;

    //endregion
    //region Ctor

    constructor(
        private _store: Store<RootState>,
        private _utilsService: UtilsService,
        @Inject(DOCUMENT) private _document: Document
    ) {
        // Следим за состоянием URL'а.
        this._store
            .pipe(
                select(routeStateSelector)
            )
            .subscribe((routeState: RouteState) => this._routeState = routeState);
    }

    //endregion
    //region Public

    /**
     * Возвращает данные начального URL'а для пользователя.
     *
     * Если это мобильное устройство, то будет старница для мобильного устройства.
     *
     * Иначе:
     *
     * URL будет вести на страницу с их списком.
     *
     * @param user Данные текущего пользователя.
     */
    getUserSpacesInitRoute(user: CurrentUserInfo): RouterActionPayload {

        const path = [];

        if (this._utilsService.isMobileBrowser()) {

            path.push("mobile");
        }

        else {

            path.push("spaces");
        }

        return {path};
    }

    /**
     * Выполняет переход на форму логина с сохранением обратной ссылки, по которой хотел пройти пользователь.
     */
    goToLogin() {

        let redirectTo: string;
        if (this._routeState === null) {

            redirectTo = encodeURIComponent(`${this._document.location.href}`);
        } else {

            redirectTo = encodeURIComponent(`${this._document.location.origin}${this._routeState.url}`);
        }

        // TODO Хардкод URL
        this._document.location.href = `${environment.authServerUrl}/login?redirectTo=${redirectTo}`;
    }

    /**
     * Выполняет переход на страницу, сообщающую пользователю, что у него нет прав на просмотр текущей страницы.
     *
     * Переход на страницу выполняется двумя возможными путями:
     * - жёсткая подстановка в location
     * - навигация внутри Angular приложения
     *
     * Причина тут в том, что если было выполнено открытие страницы сразу вводом в строку браузера, и guard,
     * который защищает URL привёл к вызову этого метода, то на этот момент редирект средствами Angular не
     * отработает. Нужен жёсткий редирект подстановкой в location. Но это нормально, т.к. для пользователя всё
     * это выглядит как единый процесс загрузки страницы.
     *
     * Если же метод был вызван guard'ом, который отработал в процессе обычной навигации в Angular, то можно делать
     * редирект средствами Angular.
     *
     * @param path URL страницы, для просмотра которой не хватает прав.
     */
    goToForbidden(path: string) {

        if (!path.startsWith('/')) {

            path = `/${path}`;
        }

        // Иногда guard не получает полного URL, на который пытается зайти пользователь (см. описание в 
        // PermissionGuard). Тогда надо проверить, что возможно полный URL можно получить через location.
        const documentPath = this._document.location.pathname;
        if (documentPath.startsWith(path) && documentPath !== path) {

            path = this._document.location.pathname;
        }

        if (this._routeState) {

            // TODO Хардкод URL'а.
            let goAction: RouterActionPayload = {path: ['forbidden']};
            if (path) {

                goAction = {
                    ...goAction,
                    query: {path}
                };
            }

            this._store.dispatch(new RouterGoAction(goAction));
        } else {

            // TODO Хардкод URL'а.
            this._document.location.href = '/forbidden' + (path ? `?path=${path}` : '');
        }
    }

    //endregion
}
