import { Action } from '@ngrx/store';
import { ApiResponse } from 'src/app/common/models';
import { FilterAndSortAndSearchState } from "src/app/spaces/modules/documents-registry/models/filter-and-sort-and-search-state.model";

/**
 * Типы событий, связанные c дилогом создания архива.
 */
export enum DocumentsArchiveActionType {
    INIT = "[Archive] Init dialog",
    LOAD_COUNT = "[Archive] Load count by filter",
    LOAD_COUNT_SUCCESS = "[Archive] Load count by filter Success",
    MAKE_ARCHIVE = "[Archive] Documents archive creation request send",
    MAKE_ARCHIVE_SUCCESS = "[Archive] Documents archive creation request Success",
    ERROR = "[Archive] Some error occurred in dialog",
}

/**
 * Событие инициализации диалога создания архива.
 */
export class DocumentsArchiveInitAction implements Action {
    readonly type = DocumentsArchiveActionType.INIT;
    constructor(public payload: {info: FilterAndSortAndSearchState, ids: string[], spaceId: string}) { }
}

/**
 * Событие загрузки кол-ва документов по фильтру.
 */
export class DocumentsArchiveLoadCountAction implements Action {
    readonly type = DocumentsArchiveActionType.LOAD_COUNT;
    constructor(public payload: FilterAndSortAndSearchState) { }
}

/**
 * Событие удачной загрузки кол-ва документов по фильтру.
 */
export class DocumentsArchiveLoadCountSuccessAction implements Action {
    readonly type = DocumentsArchiveActionType.LOAD_COUNT_SUCCESS;
    constructor(public payload: number) { }
}

/**
 * Событие запроса создания архива.
 */
export class DocumentsArchiveMakeAction implements Action {
    readonly type = DocumentsArchiveActionType.MAKE_ARCHIVE;
}

/**
 * Событие успешной отправка запроса создания архива.
 */
export class DocumentsArchiveMakeSuccessAction implements Action {
    readonly type = DocumentsArchiveActionType.MAKE_ARCHIVE_SUCCESS;
}

/**
 * Событие ошибки получения/отправки данных.
 */
export class DocumentsArchiveErrorAction implements Action {
    readonly type = DocumentsArchiveActionType.ERROR;
    constructor(public payload: ApiResponse) { }
}

/**
 * Тип, объединяющий все события, связанные диалогом создания архива.
 */
export type DocumentsArchiveDlgAction =
    | DocumentsArchiveInitAction
    | DocumentsArchiveLoadCountAction
    | DocumentsArchiveLoadCountSuccessAction
    | DocumentsArchiveMakeAction
    | DocumentsArchiveMakeSuccessAction
    | DocumentsArchiveErrorAction
    ;
