import { requestState } from "src/app/common/models/request-state";
import { UsersInvitationActionType } from "src/app/root/store/actions/users-invitation.action";
import { UsersInvitationAction } from "src/app/root/store/actions/users-invitation.action";
import { UsersInvitationState } from "src/app/root/store/states/users-invitation.state";

/**
 * Начальное состояние приглашения пользователей в пространства документов.
 */
const initialState: UsersInvitationState = {
    inviteUsersToSpaceRequest: requestState.initial()
};

/**
 * Обработка событий, связанных с состоянием приглашения пользователей в пространства документов.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 *
 * @return Состояние приглашения пользователей в пространстве документов после обработки событий.
 */
export function usersInvitationStateReducer(
    state: UsersInvitationState = initialState,
    action: UsersInvitationAction
): UsersInvitationState {

    let result: UsersInvitationState = state;

    switch (action.type) {

        // На событие с требованием инициализировать данные приглашения пользователей в пространства документов
        case UsersInvitationActionType.INITIALIZE: {

            result = initialState;
            break;
        }

        // На событие с требованием пригласить пользователей в пространства документов.
        case UsersInvitationActionType.INVITE: {

            result = {
                ...state,
                inviteUsersToSpaceRequest: requestState.pending(),
            };
            break;
        }

        // На событие успешного выполнения запроса на отправку приглашения пользователей в пространства документов.
        case UsersInvitationActionType.INVITATION_SUCCESS: {

            result = {
                ...state,
                inviteUsersToSpaceRequest: requestState.success(action.value),
            };
            break;
        }

        // На событие неудачного выполнения запроса на отправку приглашения пользователей в пространства документов.
        case UsersInvitationActionType.INVITATION_FAILED: {

            result = {
                ...state,
                inviteUsersToSpaceRequest: requestState.fail(action.value),
            };
            break;
        }
    }

    return result;
}
