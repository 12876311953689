import * as moment from "moment";
import { DocumentRegistryFilterFields } from "src/app/spaces/modules/documents-registry/models/document-registry-filter-fields.model";
import { FilterAndSortAndSearchState } from "src/app/spaces/modules/documents-registry/models/filter-and-sort-and-search-state.model";
import { DocumentRegistryFiltersState } from "src/app/spaces/modules/documents-registry/models/index";
import { DocumentsState } from "src/app/spaces/modules/documents-registry/store/reducers/documents.reducers";

/**
 * Вспомогательные методы для работы с фильтрами и сортировками документов.
 */
export class DocumentsFilterUtils {
    //region Public static

    /**
     * Собирает фильтры и сортировки в один общий объект для применения.
     *
     * @param spaceId Идентификатор папки.
     * @param state Состояние документов.
     *
     * @return Общий объект для применения фильтров и сортировки.
     */
    static extractCommonFilter(spaceId: string, state: DocumentsState): FilterAndSortAndSearchState {

        return {
            ...(state.filterState && state.filterState.getStateForRequest() || {}),
            ...(state.searchState && state.searchState.getStateForRequest() || {}),
            ...(state.sortState && state.sortState.getStateForRequest() || {}),
            spaceId,
        };
    }

    /**
     * Подготавливает фильтр для запроса.
     *
     * @param registryFilter Фильтр для подготовки.
     *
     * @return Объект с данными фильтра.
     */
    static prepareFilter(registryFilter: FilterAndSortAndSearchState): FilterAndSortAndSearchState {

        if (!registryFilter) {

            return null;
        }

        return  {
            ...registryFilter,
            createdDateFrom: typeof registryFilter.createdDateFrom === "string"
                ? moment(registryFilter.createdDateFrom)
                : registryFilter.createdDateFrom,
            createdDateTo: typeof registryFilter.createdDateTo === "string"
                ? moment(registryFilter.createdDateTo)
                : registryFilter.createdDateTo,
            orderBy: null,
            orderByDesc: null,
        };
    }

    //endregion
}
