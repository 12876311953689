<user-expiry-notification
    *ngIf="(soonExpiration$ | async) && !(viewedNotification$ | async)"
    [notificationType]="expiryNotificationType$ | async"
    (closeNotification)="markAsViewedNotificationHandler()"
    (connectToManager)="openPurchaseDialog()"
>
</user-expiry-notification>
<entera-header
    class="root__header"
    [ngClass]="{ 'root__header__under-notification': (soonExpiration$ | async) && !(viewedNotification$ | async) }"
    *ngIf="(headerVisible$ | async)"
    [path]="path$ | async"
    [user]="user$ | async"
    [space]="space$ | async"
    [restCreditCount]="restCreditCount$ | async"
    [showSpacesInfo]="headerShowSpacesInfo$ | async"
    [howToStartButtonVisibility]="headerHowToStartButtonVisibility$ | async"
    [canInviteInAnySpace]="canInviteInAnySpace$ | async"
    [showUserNotification]="(soonExpiration$ | async) && !(viewedNotification$ | async)"
    [expiryNotificationType]="expiryNotificationType$ | async"
    (logo)="logoHandler()"
    (integration)="integrationHandler($event)"
    (userAccount)="userAccountHandler()"
    (logout)="logoutHandler()"
    (createSpace)="createSpaceHandler()"
    (openSpace)="openSpaceHandler($event)"
    (spaceSettings)="spaceSettingsHandler($event)"
    (openInvitationDialog)="openInvitationDialogHandler()"
    (openErrorDialog)="openErrorDialog()"
    (spaceBuy)="spaceBuyHandler($event)"
    (callBack)="callBackHandler()"
    (knowledgeBase)="knowledgeBaseHandler()"
    (howToStart)="howToStartHandler()"
    (buyPages)="openPurchaseDialog()"
></entera-header>
<router-outlet
    class="root__content"
    [ngClass]="{ 'root__content__shifted': (soonExpiration$ | async) && !(viewedNotification$ | async) }"
>
</router-outlet>
