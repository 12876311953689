import { PhoneConfirmDlgActionType } from "../actions";
import { ConfirmPhoneDlgAction } from "../actions";
import { PhoneConfirmDlgState } from "./phone-confirm-dlg.state";

/**
 * Начальное состояние диалога подтверждения телефона.
 */
const initialState = new PhoneConfirmDlgState();

/**
 * Обработчик событий, связанных с диалогом подтверждения телефона.
 *
 * @param state Состояние диалога подтверждение телефона.
 * @param action Событие произошедшее в системе.
 */
export function phoneConfirmDlgReducer(state = initialState, action: ConfirmPhoneDlgAction): PhoneConfirmDlgState {

    let result = state;

    switch (action.type) {

        case PhoneConfirmDlgActionType.INIT_DIALOG: {

            result = new PhoneConfirmDlgState();
            break;
        }

        case PhoneConfirmDlgActionType.SEND_SMS: {

            result = {
                ...state,
                smsSent: false,
                smsSending: true,
                smsSentError: null
            };
            break;
        }

        case PhoneConfirmDlgActionType.SEND_SMS_SUCCESS: {

            result = {
                ...state,
                smsSent: true,
                smsSending: false,
                codeConfirmError: null
            };

            break;
        }

        case PhoneConfirmDlgActionType.SEND_SMS_FAIL: {

            result = {
                ...state,
                smsSending: false,
                smsSentError: {
                    ...action.payload,
                    errorMessage: action.payload.errorMessage ? action.payload.errorMessage.slice(0, -1) : null
                }
            };
            break;
        }

        case PhoneConfirmDlgActionType.CONFIRM_CODE: {

            result = {
                ...state,
                codeConfirm: false,
                codeConfirming: true,
                codeConfirmError: null
            };
            break;
        }

        case PhoneConfirmDlgActionType.CONFIRM_CODE_SUCCESS: {

            result = {
                ...state,
                codeConfirming: false,
                codeConfirm: true
            };
            break;
        }

        case PhoneConfirmDlgActionType.CONFIRM_CODE_FAIL: {

            result = {
                ...state,
                codeConfirming: false,
                codeConfirmError: {
                    ...action.payload,
                    errorMessage: action.payload.errorMessage ? action.payload.errorMessage.slice(0, -1) : null
                }
            };
            break;
        }
    }

    return result;
}