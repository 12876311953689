import { MatDialogRef } from "@angular/material";
import { map } from "rxjs/operators";
import { Constants } from "../../../common/models";
import { ConfigurationService } from "../../../common/services/configuration.service";
/**
 * Компонент диалога для отображения подсказки после первой загрузки файла на распознавание.
 */
var FirstUploadDlgComponent = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор компонента диалога для отображения подсказки после первой загрузки файла на распознавание.
     *
     * @param dialogRef Диалог, в котором открывается текущий компонент.
     * @param configService Сервис по работе с конфигурациями окружения приложения.
     */
    function FirstUploadDlgComponent(dialogRef, configService) {
        this._dialogRef = dialogRef;
        this.isGlobal$ = configService.loadConfigurations().pipe(map(function (env) { return env.global; }));
    }
    Object.defineProperty(FirstUploadDlgComponent.prototype, "seeMoreLink", {
        //endregion
        //region Getters
        /**
         * Ссылка на инструкцию по интеграции нашего сервиса c 1C.
         */
        get: function () {
            return Constants.MODULE_1C_BLOG_POST_URL;
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Public
    /**
     * Обработчик клика по копке "Посмотреть" с переходом на страницу интеграций.
     */
    FirstUploadDlgComponent.prototype.seeMoreClick = function () {
        this._dialogRef.close();
    };
    //endregion
    //region Events
    /**
     * Обработчик нажатия на Esc.
     *
     * Закрывает диалог.
     */
    FirstUploadDlgComponent.prototype.onKeyUp = function () {
        this._dialogRef.close();
    };
    return FirstUploadDlgComponent;
}());
export { FirstUploadDlgComponent };
