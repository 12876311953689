var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { NewExternalElementsActionType } from "src/app/spaces/modules/document-matching/store/actions/new-external-elements.actions";
/**
 * Начальное состояние создаваемых элементов для внешней системы.
 */
var initialState = {
    /**
     * Состояние создаваемых позиций.
     */
    items: [],
    /**
     * Состояние создаваемых компаний-поставщиков.
     */
    suppliers: [],
    /**
     * Состояние создаваемых компаний-покупателей.
     */
    customers: [],
    /**
     * Информация об использовании создаваемых элементов в UI.
     *
     * В данной структуре ключ - временный ID элемента, значение - количество использований в данный момент на UI.
     * Если элемент не используется, мы удаляем его из соответствующего состояния.
     */
    elementsUsageInfo: {},
};
/**
 * Обработчик событий, связанных создаваемыми элементами во внешней системе.
 *
 * @param state состояние создаваемых элементов для внешней системы.
 * @param action Событие.
 *
 * @return Новое состояние.
 */
export function newExternalElementsReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        case NewExternalElementsActionType.ADD_NEW_ITEM: {
            var updatedUsageInfo = __assign({}, state.elementsUsageInfo);
            var elementUsage = updatedUsageInfo[action.value.id] || 0;
            var updatedItems = state.items.slice();
            if (!elementUsage) {
                updatedItems = [action.value].concat(state.items);
            }
            updatedUsageInfo[action.value.id] = elementUsage + 1;
            result = __assign({}, state, { items: updatedItems, elementsUsageInfo: updatedUsageInfo });
            break;
        }
        case NewExternalElementsActionType.UPDATE_ITEM: {
            result = __assign({}, state, { items: [action.value].concat(state.items.filter(function (item) { return item.id !== action.value.id; })) });
            break;
        }
        case NewExternalElementsActionType.REMOVE_ITEM: {
            var updatedUsageInfo = __assign({}, state.elementsUsageInfo);
            var elementUsage = updatedUsageInfo[action.value.id] || 0;
            if (elementUsage) {
                elementUsage--;
            }
            updatedUsageInfo[action.value.id] = elementUsage;
            var items = state.items;
            if (!elementUsage) {
                delete updatedUsageInfo[action.value.id];
                items = items.filter(function (item) { return item.id !== action.value.id; });
            }
            result = __assign({}, state, { items: items.slice(), elementsUsageInfo: __assign({}, updatedUsageInfo) });
            break;
        }
        case NewExternalElementsActionType.ADD_NEW_SUPPLIER: {
            var updatedUsageInfo = __assign({}, state.elementsUsageInfo);
            var elementUsage = updatedUsageInfo[action.value.id] || 0;
            var updatedSuppliers = state.suppliers.slice();
            if (!elementUsage) {
                updatedSuppliers = [action.value].concat(state.suppliers);
            }
            updatedUsageInfo[action.value.id] = elementUsage + 1;
            result = __assign({}, state, { suppliers: updatedSuppliers, elementsUsageInfo: updatedUsageInfo });
            break;
        }
        case NewExternalElementsActionType.UPDATE_SUPPLIER: {
            result = __assign({}, state, { suppliers: [
                    action.value
                ].concat(state.suppliers.filter(function (company) { return company.id !== action.value.id; })) });
            break;
        }
        case NewExternalElementsActionType.REMOVE_SUPPLIER: {
            var updatedUsageInfo = __assign({}, state.elementsUsageInfo);
            var elementUsage = updatedUsageInfo[action.value.id] || 0;
            if (elementUsage) {
                elementUsage--;
            }
            updatedUsageInfo[action.value.id] = elementUsage;
            var companies = state.suppliers;
            if (!elementUsage) {
                delete updatedUsageInfo[action.value.id];
                companies = companies.filter(function (item) { return item.id !== action.value.id; });
            }
            result = __assign({}, state, { suppliers: companies.slice(), elementsUsageInfo: __assign({}, updatedUsageInfo) });
            break;
        }
        case NewExternalElementsActionType.ADD_NEW_CUSTOMER: {
            var updatedUsageInfo = __assign({}, state.elementsUsageInfo);
            var elementUsage = updatedUsageInfo[action.value.id] || 0;
            var updatedCustomers = state.customers.slice();
            if (!elementUsage) {
                updatedCustomers = [action.value].concat(state.customers);
            }
            updatedUsageInfo[action.value.id] = elementUsage + 1;
            result = __assign({}, state, { customers: updatedCustomers, elementsUsageInfo: updatedUsageInfo });
            break;
        }
        case NewExternalElementsActionType.UPDATE_CUSTOMER: {
            result = __assign({}, state, { customers: [
                    action.value
                ].concat(state.customers.filter(function (company) { return company.id !== action.value.id; })) });
            break;
        }
        case NewExternalElementsActionType.REMOVE_CUSTOMER: {
            var updatedUsageInfo = __assign({}, state.elementsUsageInfo);
            var elementUsage = updatedUsageInfo[action.value.id] || 0;
            if (elementUsage) {
                elementUsage--;
            }
            updatedUsageInfo[action.value.id] = elementUsage;
            var customers = state.customers;
            if (!elementUsage) {
                delete updatedUsageInfo[action.value.id];
                customers = customers.filter(function (item) { return item.id !== action.value.id; });
            }
            result = __assign({}, state, { customers: customers.slice(), elementsUsageInfo: __assign({}, updatedUsageInfo) });
            break;
        }
        case NewExternalElementsActionType.CLEAR_ALL: {
            result = initialState;
            break;
        }
    }
    return result;
}
