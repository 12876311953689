import { HttpClient } from "@angular/common/http";
import { HttpErrorResponse } from "@angular/common/http";
import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { throwError } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { ChangeBalanceTransaction } from "src/app/admin/models/change-balance-transaction";
import { ClientAdminInfo } from "src/app/admin/models/client-admin-info";
import { Transaction } from "src/app/admin/models/transaction";
import { Space } from "src/app/common/models";
import { Client } from "src/app/common/models";
import { ApiResponse } from "src/app/common/models";
import { User } from "src/app/common/models";
import { PagedRequest } from "src/app/common/models";
import { SpacePriority } from "src/app/common/models/space-priority";
import { environment } from "src/environments/environment";
import { ScheduleSaveActionProps } from "src/app/admin/store/actions/props/schedule-save-action.props";

/**
 * Сервис для выполнения административных задач.
 */
@Injectable({
    providedIn: "root"
})
export class AdminService {
    //region Ctor

    constructor(
        private _httpClient: HttpClient
    ) { }

    //endregion
    //region Public

    /**
     * Выполняет вход под пользователем.
     * 
     * @param login Логин пользователя.
     */
    public impersonate(login: string): Observable<boolean> {

        return this._httpClient
            .post(`${environment.authServerUrl}/api/v1/impersonate`, { login }, { withCredentials: true })
            .pipe(
                map((response: ApiResponse) => response.result),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse))
            );
    }

    /**
     * Поиск пользователей вместе с их подробной информацией.
     *
     * @param request Пострачниный запрос.
     */
    public searchUsers(request: PagedRequest): Observable<User[]> {

        if (!request.page) {

            request.page = 1;
        }

        if (!request.pageSize) {

            request.pageSize = 10;
        }

        let params: HttpParams = new HttpParams()
            .set("page", request.page.toFixed())
            .set("pageSize", request.pageSize.toFixed());

        if (request.search) {

            params = params.set("search", request.search);
        }

        if (request.id) {

            params = request.id.reduce((params, id) => params.append("id", id), params);
        }

        return this._httpClient
            .get("/api/v1/admin/users", {withCredentials: true, params})
            .pipe(
                map((response: any) => response.users),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse))
            );
    }

    /**
     * Поиск списка клиентов по указанному в запросе email-у.
     *
     * @param request Запрос для поиска клиентов по email-у.
     */
    public findClientsByEmail(request: PagedRequest): Observable<Client[]> {

        if (!request.page) {

            request.page = 1;
        }

        if (!request.pageSize) {

            request.pageSize = 10;
        }

        let params: HttpParams = new HttpParams()
            .set("page", request.page.toFixed())
            .set("pageSize", request.pageSize.toFixed());

        if (request.search) {

            params = params.set("search", request.search);
        }

        if (request.id) {

            params = request.id.reduce((params, id) => params.append("id", id), params);
        }

        return this._httpClient
            .get("/api/v1/admin/findClientsByEmail", {withCredentials: true, params})
            .pipe(
                map((response: any) => response.clients),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse))
            );
    }

    /**
     * Поиск списка компаний в Bitrix24 по Id компании.
     *
     * @param id Id компании с системе Bitrix24.
     * @param foreign Иностранный ли клиент?
     */
    public findClientCompany(id: string, foreign: boolean) {

        const body = {
            companyId: id,
            foreign: foreign,
        };

        return this._httpClient
            .post("/api/v1/admin/getBitrixCompany", body)
            .pipe(
                map((response: any) => response.bitrixCompany),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse))
            );
    }

    /**
     * Поиск списка клиентов по указанному в запросе имени и/или ИНН.
     *
     * @param request Запрос для поиска клиентов по имени и/или ИНН.
     */
    public findClientsByNameInnOrBitrixId(request: PagedRequest): Observable<Client[]> {

        if (!request.page) {

            request.page = 1;
        }

        if (!request.pageSize) {

            request.pageSize = 10;
        }

        let params: HttpParams = new HttpParams()
            .set("page", request.page.toFixed())
            .set("pageSize", request.pageSize.toFixed());

        if (request.search) {

            params = params.set("search", request.search);
        }

        if (request.id) {

            params = request.id.reduce((params, id) => params.append("id", id), params);
        }

        return this._httpClient
            .get("/api/v1/admin/findClientsByNameInnOrBitrixId", {withCredentials: true, params})
            .pipe(
                map((response: any) => response.clients),
                catchError((response: HttpErrorResponse): Observable<never> =>
                    throwError(response.error as ApiResponse))
            );
    }

    /**
     * Получение информации о клиенте.
     *
     * @param clientId Id клиента.
     */
    public getClient(clientId: string): Observable<ClientAdminInfo> {

        return this._httpClient
            .get(`/api/v1/admin/client/${clientId}`)
            .pipe(
                map((response: any): ClientAdminInfo => response.client),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse))
            );
    }

    /**
     * Изменение клиента пользователя.
     *
     * @param client информация для изменения данных клиента.
     */
    public changeClient(client: Client): Observable<ClientAdminInfo> {

        return this._httpClient
            .put(`/api/v1/admin/client/${client.id}`, client)
            .pipe(
                map((response: any): ClientAdminInfo => response.client),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse))
            );

    }

    /**
     * Выполняет изменение баланса.
     *
     * @param info Информация для изменения баланса.
     * @param clientId Id клиента.
     */
    public updateBalance(info: ChangeBalanceTransaction, clientId: string): Observable<boolean> {

        return this._httpClient
            .post(`/api/v1/admin/clients/${clientId}/transactions`, info)
            .pipe(
                map((response: ApiResponse) => response.result),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse)),
            );
    }

    /**
     * Обновляет TRIAL-приоритет пространства документов.
     *
     * @param trial TRIAL-приоритет пространства документов.
     * @param spaceId ID пространства документа, у которого обновляется TRIAL-приоритет.
     */
    public updateTrial(trial: boolean, spaceId: string): Observable<boolean> {

        return this._httpClient
            .put(
                `/api/v1/spaces/${spaceId}/trial`,
                { value: trial },
            )
            .pipe(
                map((response: ApiResponse): boolean => response.result),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse)),
            );
    }

    /**
     * Обновляет HIGH-приоритет пространства документов.
     *
     * @param priority Приоритет пространства документов.
     * @param spaceId ID пространства документа, у которого обновляется HIGH-приоритет.
     */
    public updateSpacePriority(priority: SpacePriority, spaceId: string): Observable<boolean> {

        return this._httpClient
            .put(
                `/api/v1/spaces/${spaceId}/priority`,
                { spacePriority: priority.id, spaceId: spaceId },
            )
            .pipe(
                map((response: ApiResponse): boolean => response.result),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse)),
            );
    }

    /**
     * Обновляет флаг использования баланса клиента пространства документов.
     *
     * @param useClientBalance Значение флага использования баланса клиента пространства документов.
     * @param spaceId ID пространства документа, у которого обновляется HIGH-приоритет.
     */
    public updateUseClientBalance(useClientBalance: boolean, spaceId: string): Observable<boolean> {

        return this._httpClient
            .put(
                `/api/v1/spaces/${spaceId}/useClientBalance`,
                { value: useClientBalance },
            )
            .pipe(
                map((response: ApiResponse): boolean => response.result),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse)),
            );
    }

    /**
     * Обновляет флаг использования пространства документов для иностранных документов.
     *
     * @param foreign Флаг использования пространства документов для иностранных документов.
     * @param spaceId ID пространства документа, у которого обновляется флаг.
     *
     * @return Флаг успешности выполнения запроса.
     */
    public updateForeignSpace(foreign: boolean, spaceId: string): Observable<boolean> {

        return this._httpClient
            .put(
                `/api/v1/spaces/${spaceId}/foreign`,
                { value: foreign },
            )
            .pipe(
                map((response: ApiResponse): boolean => response.result),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse)),
            );
    }

    /**
     * Обновляет информацию о пространстве документов.
     *
     * @param space Простанство документов.
     */
    public updateSpaceInfo(space: Space): Observable<boolean> {

        const spaceInfo = { id: space.id, name: space.name };

        return this._httpClient
            .put(`/api/v1/spaces/${space.id}`, spaceInfo)
            .pipe(
                map((response: ApiResponse): boolean => response.result),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse)),
            );
    }

    /**
     * Создаёт новое пространство документов.
     *
     * @param spaceName Имя нового пространства документов.
     * @param clientId Id клиента, у которого необходимо создать пространство документов.
     */
    public createNewSpace(spaceName: string, clientId: string): Observable<Space> {

        const newSpaceInfo = { name: spaceName, clientId: clientId };

        return this._httpClient
            .post("/api/v1/spaces", newSpaceInfo)
            .pipe(
                map((response: any): Space => response.space),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse)),
            );
    }

    /**
     * Возвращает транзакции клиента.
     *
     * @param clientId ID клиента, транзакции которого необходимо получить.
     */
    public getClientTransactions(clientId: string): Observable<Transaction[]> {

        return this._httpClient
            .get(`/api/v1/admin/clients/${clientId}/transactions`)
            .pipe(
                map((response: any): Transaction[] => response.transactions),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse)),
            );
    }

    /**
     * Приглашает пользователя с указанным email-ом в указанное пространство документов.
     *
     * @param space Пространство документов, в которое приглашается пользователь.
     * @param email Пользователь, приглашаемый в пространство документов.
     */
    public inviteUserIntoSpace(space: Space, email: string): Observable<boolean> {

        return this._httpClient
            .post("/api/v1/invite", { emails: [ email ], spaceIds: [ space.id ] })
            .pipe(
                map((response: ApiResponse): boolean => response.result),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse)),
            );
    }

    /**
     * Создает график балансовых начислений.
     *
     * @param clientId Id выбранного клиента.
     * @param transactions Массив графиков начислений.
     */
    public changeBalanceSchedule(clientId: string, transactions: ScheduleSaveActionProps[]): Observable<boolean> {

        return this._httpClient
            .post(`/api/v1/admin/clients/${clientId}/transactions/schedules`, {transactions:  transactions })
            .pipe(
                map((response: ApiResponse): boolean => response.result),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse))
            );
    }

    /**
     * Отменяет подписку клиента.
     *
     * @param clientId ID клиента.
     * @param price Возвращаемая клиенту стоимость подписки.
     * @param comment Комментарий.
     */
    public cancelSubscription(clientId: string, price: string, comment: string): Observable<boolean> {

        let url = `/api/v1/admin/clients/${clientId}/cancelSubscription?price=${price}`;

        if (comment && comment.length) {

            url = `${url}&comment=${comment}`;
        }

        return this._httpClient
            .post(url, {})
            .pipe(
                map((response: ApiResponse): boolean => response.result),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse)),
            );
    }

    //endregion
}
