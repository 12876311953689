import { ArrayValues } from "src/app/common/models/entera-common-types";

/**
 * Счёт леджера.
 */
export interface LedgerBill {
    //region Fields

    /**
     * Номер.
     */
    readonly number?: string;

    /**
     * Дата.
     */
    readonly date?: string;

    /**
     * Количество.
     */
    readonly amount?: number;

    /**
     * Остаток.
     */
    readonly remainAmount?: number;

    //endregion
}

/**
 * Типы детализации счёта.
 */
export const billWiseDetailTypes: ["On Account"] = ["On Account"];

/**
 * Тип детализации счёта.
 */
export type BillWiseDetailType = ArrayValues<typeof billWiseDetailTypes>;

/**
 * Type guard типа детализации счёта
 *
 * @param val Проверяемое значение.
 */
export function isBillWiseDetailType(val: any): val is BillWiseDetailType {

    return typeof val === "string" && (billWiseDetailTypes as string[]).includes(val);
}

/**
 * Функция сравнения счетов. (Сравнивает их по номеру)
 *
 * @param o1 Первый счёт.
 * @param o2 Второй счёт.
 */
export function ledgerBillEquals(o1: LedgerBill, o2: LedgerBill) {

    return (o1 && o1.number || o1) === (o2 && o2.number || o2);
}
