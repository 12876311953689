import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { ErrorResponseProps } from "src/app/root/store/actions/props/error-response.action-props";
import { ValueProps } from "src/app/root/store/actions/props/value-props";
import { IntegrationProps } from "src/app/spaces/modules/documents-registry/store/actions/props/integration.props";
import { ExternalCostCentre } from "src/app/spaces/modules/document-matching/models/external-cost-centre";

/**
 * Типы событий, связанных с центрами расходов из внешней системы.
 */
export enum ExternalCostCentresActionType {
    INIT = "[External cost centers] Init request state",
    LOAD = "[External cost centers] Load",
    LOAD_SUCCESS = "[External cost centers] Successfully load",
    LOAD_FAIL = "[External cost centers] Error occurred while loading",
}

/**
 * События инициализации состояния запроса центров расходов.
 */
const init = createAction(ExternalCostCentresActionType.INIT);

/**
 * Событие получения центров расходов из внешней системы с сервера.
 */
const load = createAction(ExternalCostCentresActionType.LOAD, props<IntegrationProps>());

/**
 * Событие успешного получения центров расходов из внешней системы с сервера.
 */
const loadSuccess = createAction(ExternalCostCentresActionType.LOAD_SUCCESS, props<ValueProps<ExternalCostCentre[]>>());

/**
 * Событие ошибки при получении центров расходов из внешней системы с сервера.
 */
const loadFail = createAction(ExternalCostCentresActionType.LOAD_FAIL, props<ErrorResponseProps<void>>());

/**
 * Тип, объединяющий все события, связанные со центрами расходов из внешней системы.
 */
export type ExternalCostCentresAction =
    | ReturnType<typeof init>
    | ReturnType<typeof load>
    | ReturnType<typeof loadSuccess>
    | ReturnType<typeof loadFail>;

/**
 * Все события, связанные со центрами расходов из внешней системы
 */
export const externalCostCentresActions = {
    init,
    load,
    loadSuccess,
    loadFail,
};
