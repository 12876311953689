import { EventEmitter } from "@angular/core";
import { Output } from "@angular/core";
import { Component } from "@angular/core";
import { Input } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { OnInit } from "@angular/core";
import { OnDestroy } from "@angular/core";
import { ViewChild } from "@angular/core";
import { AfterViewInit } from "@angular/core";
import { NgControl } from "@angular/forms";
import { FormControl } from "@angular/forms";
import { ControlValueAccessor } from "@angular/forms";
import { MatSelectChange } from "@angular/material";
import { ErrorStateMatcher } from "@angular/material";
import { MatSelect } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { MatSelectSearchComponent } from "ngx-mat-select-search";
import { Subject } from "rxjs";
import { combineLatest } from "rxjs";
import { Observable } from "rxjs";
import { of } from "rxjs";
import { fromEvent } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { take } from "rxjs/operators";
import { takeUntil } from "rxjs/operators";
import { startWith } from "rxjs/operators";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { debounceTime } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { mergeMap } from "rxjs/operators";
import { withLatestFrom } from "rxjs/operators";
import { SelectableOption } from "src/app/common/models/option";
import { isOptionArray } from "src/app/common/models/option";
import { isOption } from "src/app/common/models/option";
import { Option } from "src/app/common/models/option";
import { PagedSearchFn } from "src/app/common/models/paged-search-function.model";
import { PreloadSearchFn } from "src/app/common/models/preload-search-function.model";
import { SearchFn } from "src/app/common/models/search-function.model";
import { UtilsService } from "src/app/common/services/utils.service";

/**
 * Компонент поля формы для выбора из списка значений с возможностью поиска.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "entera-select",
    styleUrls: ["entera-select.component.scss"],
    templateUrl: "entera-select.component.html",
})
export class EnteraSelectComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {
    //region Constants

    /**
     * Количество элементов в прогружаемом списке для начальной загрузки списка выбранных элементов для мультиселекта.
     *
     * Нужно для прогрузки всех элементов списка мультиселекта. Если же не задавать это значение, то возьмет количество
     * по умолчанию, из-за чего может ограничиться количество выбранных элементов списка.
     */
    public static readonly FIRST_LOAD_PAGE_SIZE: number = 40;

    //endregion
    //region Inputs

    /**
     * Входящие данные - возможность выбора нескольких значений включена?
     */
    @Input()
    multiselect: boolean = false;

    /**
     * Максимальное количество выбранных значений.
     */
    @Input()
    maxSelected: number;

    /**
     * Входящие данные - placeholder поля.
     */
    @Input()
    placeholder: string;

    /**
     * Входящие данные - какое поле варианта выбора отображать в выпадашке.
     */
    @Input()
    set optionField(optionField: string) {

        this._optionField = optionField;
    }

    /**
     * Входящие данные - функция для получения текстового представления варианта выбора в вариантах выбора.
     */
    @Input()
    formatOption: (option: Option) => string;

    /**
     * Входящие данные - какое поле выбранного варианта отображать.
     */
    @Input()
    set resultField(resultField: string) {

        this._resultField = resultField;
    }

    /**
     * Входящие данные - функция для получения текстового представления выбранного варианта.
     */
    @Input()
    formatResult: (option: Option) => string;

    /**
     * Входящие данные - какое поле варианта выбора использовать в качестве значения для контрола.
     * По умолчанию, если это поле не задано, вариант выбора и будет являться значением контрола.
     */
    @Input()
    valueField: string;

    /**
     * Входящие данные - функция для сравнения вариантов выбора, которая будет использоваться в mat-select.
     * По умолчанию в mat-select попадает функция, выполняющая сравнение по полю id.
     * При установке valueField, по умолчанию будет функция, выполняющая сравнение по заданному полю valueField.
     */
    @Input("compareWith")
    userCompareWith: (o1: any, o2: any) => boolean;

    /**
     * Входящие данные - статические значения для выбора.
     */
    @Input()
    set options(options: Option[]) {

        this._options = options;
        this._disableOrEnableControl();
        this.searchControl.setValue("");
    }

    /**
     * Входящие данные - функция постраничной динамической ajax-загрузки значения для выбора.
     */
    @Input()
    pagedSearchFn: PagedSearchFn<Option> = null;

    /**
     * Входящие данные - функция динамической ajax-загрузки значения для выбора.
     */
    @Input()
    searchFn: SearchFn<Option> = null;

    /**
     * Входящие данные - функция предварительной загрузки значений для выбора.
     *
     * Функция вызывается в случае, если поисковая строка пуста.
     */
    @Input()
    preloadSearchFn: PreloadSearchFn<Option> = null;

    /**
     * Входящие данные - задержка перед выполнением поиска после последнего нажатия на клавиши.
     */
    @Input()
    searchDebounce: number = 170;

    /**
     * Входящие данные - отключать выпадашку, когда вариантов для выбора нет?
     */
    @Input()
    set disableOnEmptyOptions(disableOnEmptyOptions: boolean) {

        this._disableOnEmptyOptions = disableOnEmptyOptions;
        this._disableOrEnableControl();
    }

    /**
     * Входящие данные - поиск по вариантам включён?
     */
    @Input()
    searchEnabled: boolean;

    /**
     * Входящие данные - placeholder, пока строка поиска пуста.
     */
    @Input()
    searchPlaceholder: string;

    /**
     * Входящие данные - placeholder, что пользователю нужно начать вводить, чтобы начался поиск.
     */
    @Input()
    startSearchPlaceholder: string;

    /**
     * Входящие данные - placeholder, когда поиск не вернул результатов.
     */
    @Input()
    noSearchResultsPlaceholder: string;

    /**
     * Входящие данные - отображать вариант выбора, который имеет значение "null"?
     */
    @Input()
    hasNullOption: boolean;

    /**
     * Входящие данные - текст вариант выбора, который имеет значение "null".
     */
    @Input()
    nullOptionText: string;

    /**
     * Входящие данные - подсказка при наведении на кнопку очистки выбранного значения.
     */
    @Input()
    clearBtnTitle: string;

    /**
     * Входящие данные - кнопка очистки выбранного значения включена?
     */
    @Input()
    clearBtnEnabled: boolean = true;

    /**
     * Входящие данные - отключение контрола.
     */
    @Input()
    set selectDisabled(disabled: boolean) {

        this._selectDisabled = disabled;
        this._disableOrEnableControl();
    }

    /**
     * Входящие данные - поле обязательно для заполнения?
     */
    @Input()
    required: boolean = false;

    /**
     * Входящие данные - текст ошибки, когда поле не заполнено, но является обязательным.
     */
    @Input()
    requiredErrorMessage: string;

    /**
     * Входящие данные - отображать custom ошибку для поля?
     */
    @Input()
    customError: boolean = false;

    /**
     * Входящие данные - текст custom-ошибки.
     */
    @Input()
    customErrorMessage: string;

    /**
     * Входящие данные - логика контрола, которая определяет, когда отображать контрол в состоянии ошибки.
     */
    @Input()
    errorStateMatcher: ErrorStateMatcher;

    /**
     * Размер страницы поиска.
     */
    @Input()
    pageSize: number = 10;

    /**
     * Прелоадинг значения выпадашки при асинхронном поиске. Ищется только по id.
     */
    @Input()
    preloading: boolean = false;

    /**
     * Компактное отображение для таблицы включено?
     */
    @Input()
    tableCompact: boolean = false;

    /**
     * Выделение селекта включено?
     */
    @Input()
    marked: boolean;

    /**
     * Минимальная длина поискового запроса.
     */
    @Input()
    minLengthToSearch: number = 3;

    /**
     * Входящие данные - имя поля с категорией опции.
     */
    @Input()
    optionCategoryField: string;

    //endregion
    //region Outputs

    /**
     * Исходящие данные - событие изменения выбранного элемента.
     */
    @Output()
    selectionChange: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();

    //endregion
    //region Fields

    /**
     * Максимальная длина поискового запроса.
     */
    maxLengthToSearch: number = 1000;

    /**
     * Флаг, что длина поискового запроса достигла нужной длины для выполнения запроса.
     */
    searchLengthReached: boolean = false;

    /**
     * Флаг, что количество выбранных элементов достигла максимально разрешенного количества выбранных элементов.
     */
    maxSelectedReached: boolean = false;

    /**
     * FormControl привязанный к выпадашке.
     */
    valueControl = new FormControl();

    /**
     * FormControl привязанный к полю поиска.
     */
    searchControl = new FormControl();

    /**
     * Отфильтрованные статические значения для выбора согласно поисковому запросу.
     */
    filteredOptions$: Observable<Option[]>;

    /**
     * Динамически загруженные варианты для выбора согласно поисковому запросу.
     */
    searchOptions$: BehaviorSubject<Option[]> = new BehaviorSubject([]);

    /**
     * Динамически загруженные варианты для выбора без выбранных вариантов.
     */
    searchOptionsWithoutSelected$: Observable<Option[]> = new Observable();

    /**
     * Флаг выполняющейся загрузки динамических вариантов выбора.
     */
    loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    /**
     * Флаг того, что часть динамических вариантов выбора загружена.
     */
    loaded: boolean = false;

    /**
     * Флаг того, что среди выбранных значений есть вариант, обозначающий 'null'-значение.
     */
    isNullSelected: boolean = false;

    /**
     * Селект открыт?
     */
    opened$: Subject<boolean> = new Subject();

    /**
     * Выбранное значение.
     */
    selected$: BehaviorSubject<Option | Option[]> = new BehaviorSubject(null);

    /**
     * Компонент выпадающего списка.
     */
    @ViewChild(MatSelect)
    matSelect: MatSelect;

    /**
     * Компонент поля поиска для выпадающего списка.
     */
    @ViewChild(MatSelectSearchComponent)
    selectSearch: MatSelectSearchComponent;

    /**
     * Поле для хранения значений для выбора.
     */
    private _options: Option[] = [];

    /**
     * Поле для хранения флага отключения контрола при отсутствии вариантов для выбора.
     */
    private _disableOnEmptyOptions: boolean = true;

    /**
     * Поле для хранения поля объекта, которое отображается в выпадашке.
     */
    private _optionField: string;

    /**
     * Поле для хранения поля объекта, которое отображается для выбранного значения.
     */
    private _resultField: string;

    /**
     * Поле для хранения флага отключения контрола.
     */
    private _selectDisabled: boolean = false;

    /**
     * Флаг выполняющейся загрузки динамических вариантов выбора. Внутренее отображение.
     */
    private _loading: boolean = false;

    /**
     * Объект глобальной отписки.
     */
    private _globalUnsubscribe$: Subject<void> = new Subject();

    /**
     * Текущая страница для AJAX запроса поиска. Становится -1, если нет больше страниц для поиска.
     */
    private _currentSearchPage: number = 1;

    /**
     * Callback, когда выбранное в выпадашке значение изменилось.
     */
    private _changeCallback: Function = (_: any) => {};

    /**
     * Callback, когда пользователь начал взаимодействовать с выпадашкой.
     */
    private _touchCallback: Function = (_: any) => {};

    /**
     * Функция для сравнения вариантов выбора по id.
     */
    private _compareWith = (o1: any, o2: any) => o1 && o2 && o1["id"] === o2["id"];

    /**
     * Функция для сравнения вариантов выбора по заданному полю.
     */
    private _compareWithField = (o1: any, o2: any) => o1 && o2 && o1[this.valueField] === o2[this.valueField];

    //endregion
    //region Ctor

    /**
     * Конструктор компоненты поля формы.
     *
     * @param _ngControl Директива для биндинга контрола к DOM-дереву.
     * @param _translateService Сервис для работы с i18n-сообщениями.
     * @param _utilService Утилиты.
     */
    constructor(
        private _ngControl: NgControl,
        private _translateService: TranslateService,
        private _utilService: UtilsService,
    ) {
        this._ngControl.valueAccessor = this;
    }

    //endregion
    //region Hooks

    /**
     * Логика, выполняющаяся при инициализации компоненты после установки всех свойств компоненты.
     */
    ngOnInit(): void {

        this._updateControlState();

        // Поиск среди статически заданных вариантов выбора.
        this.filteredOptions$ = this.searchControl.valueChanges
            .pipe(
                startWith(""),
                map(search => this._filterStaticOptionsBySearch(search)),
            );

        this.searchOptionsWithoutSelected$ = combineLatest([this.searchOptions$, this.selected$]).pipe(
            map(([options, selected]: [Option[], Option | Option[]]): Option[] =>
                this._getNotSelectedOptions(options, selected)
            ),
        );

        this.valueControl.valueChanges
            .pipe(takeUntil(this._globalUnsubscribe$))
            .subscribe((value: Option | Option[]): void => this._setSelected(value));

        if (!!this._ngControl.value) {

            this.valueControl.setValue(this._ngControl.value);
        }

        this._ngControl.statusChanges
            .pipe(takeUntil(this._globalUnsubscribe$))
            .subscribe(() => this._updateControlState());

        this.loading$
            .pipe(takeUntil(this._globalUnsubscribe$))
            .subscribe((value: boolean): boolean => this._loading = value);
    }

    /**
     * Логика, вызывающаяся после инициализации представления компонента.
     */
    ngAfterViewInit(): void {

        // Поиск динамически подгружаемых вариантов выбора.
        if (this.pagedSearchFn) {

            // Постраничный запрос активен
            let inProgress: boolean = false;

            let oldSearch;

            this.searchControl.valueChanges
                .pipe(
                    takeUntil(this._globalUnsubscribe$),
                    debounceTime(this.searchDebounce),
                    startWith(""),
                    filter((search: string): boolean => oldSearch !== search),
                    filter((search: string): boolean => this._maxLengthReachedOpenAndSearch(search)),
                    switchMap((search: string): Observable<Option[]> => {

                        oldSearch = search;

                        return this._pagedSearchFnAfterSearchChange(search);
                    }),
                )
                .subscribe((options: Option[]): void => {

                    if (options.length < this.pageSize) {

                        this._currentSearchPage = -1;
                    }

                    this.searchOptions$.next(options);
                });

            // Подписываемся на скролл панели
            this.matSelect.openedChange.pipe(
                takeUntil(this._globalUnsubscribe$),
                // Панель открыта
                filter(Boolean),
                // Переключаемся на событие скролла
                mergeMap((): Observable<Event> =>
                    fromEvent<Event>(this.matSelect.panel.nativeElement , "scroll")
                ),
                debounceTime(50),
                map((event: Event): Element => <Element>event.target),
                map((srcElement: Element): number =>
                    srcElement.scrollHeight - (srcElement.scrollTop + srcElement.clientHeight)
                ),
                filter((offset: number): boolean => offset < 10),
                // Нет больше страниц для поиска
                filter((): boolean => this._currentSearchPage !== -1),
                withLatestFrom(this.searchControl.valueChanges),
                filter((): boolean => !inProgress),
                mergeMap(([_, search]: [never, any]): Observable<Option[]> => {

                    inProgress = true;

                    return this._pagedSearchFnAfterScroll(search);
                }),
                map((newOptions: Option[]): Option[] => {

                    inProgress = false;

                    return this._addNewSearchPageOptions(newOptions);
                })
            )
                .subscribe((options: Option[]): void => this.searchOptions$.next(options));
        }
        else if (this.searchFn) {

            let oldSearch;

            this.searchControl.valueChanges
                .pipe(
                    takeUntil(this._globalUnsubscribe$),
                    debounceTime(this.searchDebounce),
                    startWith(""),
                    filter(search => oldSearch !== search),
                    filter((search: string): boolean => {

                        const result: boolean = search.length <= 1000;

                        if (!result) {

                            this.openAndSearch(search.substring(0, 1000));
                        }

                        return result;
                    }),
                    switchMap((search: string): Observable<Option[]> => {

                        this.searchOptions$.next([]);

                        if (search.length < this.minLengthToSearch) {

                            if (this.preloadSearchFn && oldSearch && search.length === 0) {

                                oldSearch = search;
                                this.loading$.next(true);

                                return this.preloadSearchFn();
                            }
                            else {

                                oldSearch = search;
                                this.searchLengthReached = false;
                                return of([] as Option[]);
                            }
                        }
                        else {

                            oldSearch = search;
                            this.loading$.next(true);
                            this.searchLengthReached = false;

                            return this.searchFn(search);
                        }
                    }),
                )
                .subscribe((options: Option[]): void => {

                    if (options.length < this.pageSize) {

                        this._currentSearchPage = -1;
                    }
                    this.searchOptions$.next(options);
                });
        }

        // Подписываемся на изменение набора динамически загруженных вариантов выбора.
        // Это тот момент, когда загрузка значений завершилась.
        this.searchOptions$
            .pipe(takeUntil(this._globalUnsubscribe$))
            .subscribe((options: Option[]): void => {
                this.loading$.next(false);
                this.loaded = !!(options && options.length);
            });

        this.opened$
            .pipe(
                takeUntil(this._globalUnsubscribe$),
                switchMap((opened: boolean): Observable<Option[]> => {

                    this.searchLengthReached = false;

                    if (this.preloadSearchFn && opened) {

                        this.loading$.next(true);

                        return this.preloadSearchFn();
                    }
                    else {

                        return of([] as Option[]);
                    }
                }),
            )
            .subscribe((option: Option[]): void => {

                this.searchOptions$.next(option);
            });
    }

    /**
     * Логика, выполняющаяся при уничтожении компоненты.
     */
    ngOnDestroy(): void {

        this._globalUnsubscribe$.complete();
    }

    //endregion
    //region Getters and Setters

    /**
     * Возвращает функцию для сравнения вариантов выбора для mat-select.
     */
    get compareWith(): (o1: any, o2: any) => boolean {

        if (this.userCompareWith) {

            return this.userCompareWith;
        }

        if (this.valueField) {

            return this._compareWithField;
        }

        return this._compareWith;
    }

    /**
     * Возвращает Observable placeholder'а, пока строка поиска пуста.
     */
    get searchPlaceholder$(): Observable<string> {

        if (this.searchPlaceholder) {

            return of(this.searchPlaceholder);
        }
        else {

            return this._translateService.get("search.label");
        }
    }

    /**
     * Возвращает Observable placeholder'а, что пользователю нужно начать вводить, чтобы начался поиск.
     */
    get startSearchPlaceholder$(): Observable<string> {

        if (this.startSearchPlaceholder) {

            return of(this.startSearchPlaceholder);
        }
        else {

            return this._translateService.get("search.startTyping");
        }
    }

    /**
     * Возвращает Observable placeholder'а, когда поиск не вернул результатов.
     */
    get noSearchResultsPlaceholder$(): Observable<string> {

        if (this.noSearchResultsPlaceholder) {

            return of(this.noSearchResultsPlaceholder);
        }
        else {

            return this._translateService.get("search.empty.result");
        }
    }

    /**
     * Возвращает значения для выбора.
     */
    get options(): Option[] {

        return this._options;
    }

    /**
     * Возвращает поле варианта выбора, которое отображается в выпадашке. По умолчанию "name".
     */
    get optionField(): string {

        return (this._optionField ? this._optionField : "name");
    }

    /**
     * Возвращает поле выбранного варианта, которое отображается. По умолчанию "name".
     */
    get resultField(): string {

        return (this._resultField ? this._resultField : "name");
    }

    /**
     * Placeholder для приглашения ввода для начала поиска виден?
     */
    get isStartTypingPlaceholderVisible(): boolean {

        return (!this.options || this.options.length === 0)
            && (!!this.pagedSearchFn || !!this.searchFn)
            && !this._loading
            && !this.loaded
            && (!this.searchControl.value || !this.searchLengthReached)
            && !this.searching
        ;
    }

    /**
     * Варианты с выбранными значениями нужно отображать?
     */
    get isSelectedValueExists(): boolean {

        let result: boolean;

        if (this.multiselect && this.selected$.getValue() instanceof Array) {

            result = (this.selected$.getValue() as Option[]).length > 0;
        }
        else if (this.selected$.getValue() instanceof Object) {

            result = !!(this.selected$.getValue() as Option).id;
        }
        else {

            result = !!this.selected$.getValue();
        }

        return result;
    }

    /**
     * Текст, что ничего не найдено виден?
     */
    get isNoSearchResultsVisible(): boolean {

        return (
            !this.isStartTypingPlaceholderVisible
            && !this._loading
            && !this.loaded
            && !!(this.pagedSearchFn || this.searchFn)
            && !!this.searchControl.value
        );
    }

    /**
     * Должна ли отображаться опция "NULL"?
     */
    get isNullOptionVisible(): boolean {

        return this.hasNullOption
            && (
                !this.pagedSearchFn && !this.searchFn
                || !this.isNullSelected && !!this.pagedSearchFn
                || !this.isNullSelected && !!this.searchFn
            );
    }

    /**
     * Текст поиска.
     */
    get searchText() {

        return this.selectSearch
            && this.selectSearch.searchSelectInput
            && this.selectSearch.searchSelectInput.nativeElement
            && this.selectSearch.searchSelectInput.nativeElement.value
            || null;
    }

    /**
     * В данный момент выполняется поиск?
     */
    get searching(): boolean {

        return this.searchEnabled
            && (!!this.searchFn || !!this.pagedSearchFn)
            && !!this.searchText
            && this.searchText.length >= this.minLengthToSearch;
    }

    //endregion
    //region Public

    /**
     * Программная установка значения.
     *
     * Тут выполняется асинхронная подгрузка значения, если это необходимо.
     *
     * @param value Значение для установки.
     */
    writeValue(value: any): void {

        if (this.valueControl.value !== value) {

            if (value) {

                if (value instanceof Array) {

                    this.writeValues(value);
                }
                else {

                    this.writeSingleValue(value);
                }
            }
            else {

                this.valueControl.setValue(null);
            }
        }
        setTimeout(() => this.valueControl.markAsTouched());
    }

    /**
     * Программная установка значения.
     *
     * Тут выполняется асинхронная подгрузка одного значения, если это необходимо.
     *
     * @param value Значение для установки.
     */
    writeSingleValue(value: any): void {

        if (value && value.id && value.id != 'null' && (this.pagedSearchFn || this.searchFn) && this.preloading) {

            if (this.pagedSearchFn) {

                let subs = this.pagedSearchFn(null, null, null, [value.id])
                    .subscribe(opts => {

                        if (opts.length) {

                            this.valueControl.setValue(opts[0]);
                        }
                        else {

                            this.valueControl.setValue(value);
                        }

                        subs.unsubscribe();
                    });
            }
            else {

                this.searchFn(null, [value.id])
                    .pipe(take(1))
                    .subscribe(opts => {

                        if (opts.length) {

                            this.valueControl.setValue(opts[0]);
                        }
                        else {

                            this.valueControl.setValue(value);
                        }
                    });
            }
        }
        else if (value && value.id && value.id != 'null' && this.options.length) {

            let original = this.options.filter((option: Option) => option.id === value.id)[0];
            if (original) {

                this.valueControl.setValue({
                    ...original,
                    ...value
                });
            }
            else {

                this.valueControl.setValue(value);
            }
        }
        else {

            this.valueControl.setValue(value);
            this._options = [
                ...this.options,
                value
            ];
        }
    }

    /**
     * Программная установка значения.
     *
     * Тут выполняется асинхронная подгрузка массива значений, если это необходимо.
     *
     * Если значение для установки отлично от значения контрола, то:
     *
     *  - Если есть функция динамической подгрузки и включен прелоадинг, то ищет значения с переданным массивом id
     *  после подгрузки значений.
     *  - Иначе, если есть статические значения для выбора - ищет по id каждого элемента значения для установки среди
     *  статических значений. Для найденных значений происходит "слитие" объектов, для остальных - просто
     *  устанавливается значение элемента.
     *  - При наличии вариантов с id 'null', добавляет первое такое значение в массив значений.
     *
     * @param values Значения для установки.
     */
    writeValues(values: any[]): void {

        const valuesWithId = values.filter((value: any): boolean => value && value.id && value.id !== "null");
        const valuesWithoutId = values.filter((value: any): boolean => !value || !value.id || value.id === "null");
        const nullValueToSet = valuesWithoutId.length ? [valuesWithoutId[0]] : [];

        let valuesToSet = [];

        if ((this.pagedSearchFn || this.searchFn) && this.preloading && valuesWithId.length) {

            if (this.pagedSearchFn) {

                const subs = this.pagedSearchFn(
                    null,
                    null,
                    this.maxSelected ? this.maxSelected : EnteraSelectComponent.FIRST_LOAD_PAGE_SIZE,
                    valuesWithId.map(val => val.id),
                )
                    .subscribe((opts: Option[]): void => {

                        if (opts.length) {

                            valuesToSet = [...valuesToSet, ...opts];
                        } else {

                            valuesToSet = [...valuesToSet, ...valuesWithId];
                        }
                        this.valueControl.setValue([...valuesToSet, ...nullValueToSet]);
                        subs.unsubscribe();
                    });
            }
            else {

                const subs = this.searchFn(null, valuesWithId.map(val => val.id))
                    .subscribe((opts: Option[]): void => {

                        if (opts.length) {

                            valuesToSet = [...valuesToSet, ...opts];
                        } else {

                            valuesToSet = [...valuesToSet, ...valuesWithId];
                        }
                        this.valueControl.setValue([...valuesToSet, ...nullValueToSet]);
                        subs.unsubscribe();
                    });
            }
        }
        else if (this.options.length) {

            let original;
            valuesWithId.forEach(value => {

                original = this.options
                    .filter((option: Option): boolean => option.id === value.id)
                    .find((): boolean => true);

                if (original) {

                    valuesToSet = [ ...valuesToSet, {...original, ...value } ];
                }
                else {

                    valuesToSet = [ ...valuesToSet, value ];
                }
            });

            this.valueControl.setValue([ ...valuesToSet, ...nullValueToSet ]);
        }
        else {

            this.valueControl.setValue([ ...valuesWithId, ...nullValueToSet ]);
        }
    }

    registerOnChange(fn: Function): void {

        this._changeCallback = fn;
    }

    registerOnTouched(fn: Function): void {

        this._touchCallback = fn;
    }

    /**
     * Возвращает текстовое представление варианта для выбора.
     * 
     * @param option Вариант для выбора.
     *
     * @return Текстовое представление варианта для выбора.
     */
    getOptionText(option: Option | Option[]): string {

        const value: Option = option as Option;

        let text;

        if (this.formatOption) {

            text = this.formatOption(value);
        }
        else {

            text = value[this.optionField];
        }

        if (this.searchControl.value && text) {

            text = this._utilService.highlight(text, this.searchControl.value, "highlight");
        }

        if ((!value || !value.id || value.id === "null") && this.hasNullOption && this.nullOptionText) {

            text = this._translateService.instant(this.nullOptionText);
        }

        return text;
    }

    /**
     * Возвращает текстовое представление выбранных вариантов.
     *
     * @param selected Выбранные варианты.
     *
     * @return Текстовое представление выбранных вариантов.
     */
    getResultText(selected: SelectableOption): string {

        let text = "";

        if (!selected || selected === "null") {

            text = this.nullOptionText;
        }
        else {

            if (isOption(selected)) {

                text = this.getOptionResultText(selected);
            }
            else if (isOptionArray(selected)) {

                text = this.getOptionResultText(selected[0]);
            }
            else {

                text = selected;
            }
        }

        return text;
    }

    /**
     * Возвращает вспомогательный текст для указания количества выбранных вариантов.
     *
     * @param selected Выбранные варианты.
     *
     * @return Вспомогательный текст для указания количества выбранных вариантов.
     */
    getAdditionalResultText(selected: SelectableOption): string {

        if (selected && isOptionArray(selected)) {

            return this._translateService.instant(
                "enteraSelect.multiSelect",
                { count: (selected.length - 1) },
            );
        }
        else {

            return "";
        }
    }

    /**
     * Возвращает текстовое представление выбранного варианта.
     *
     * @param selected Выбранный вариант.
     *
     * @return Текстовое представление выбранного варианта.
     */
    getOptionResultText(selected: Option): string {

        let text;

        if (!selected || !selected.id || selected.id === "null") {

            text = this.nullOptionText;
        }
        else if (this.formatResult) {

            text = this.formatResult(selected);
        }
        else {

            text = selected[this.resultField];
        }

        return text;
    }

    /**
     * Возвращает значение варианта для выбора, которое будет использоваться как значение всего контрола.
     * 
     * @param option Вариант для выбора.
     *
     * @return Значение варианта для выбора.
     */
    getOptionValue(option: Option | Option[]): any {

        let value: Option = option as Option;

        if (this.valueField) {

            value = option[this.valueField];
        }

        return value;
    }

    /**
     * Возвращает значение для варианта выбора обозначающего 'null'-значение.
     *
     * @return Значение для варианта выбора обозначающего 'null'-значение.
     */
    getNullOptionValue(): any {

        if (this.valueField) {

            return "null";
        }
        else {

            return { id: "null" };
        }
    }

    /**
     * Открывает выпадашку, подставляет заданную поисковую строку и запускает поиск.
     *
     * @param search Поисковая строка.
     */
    openAndSearch(search: string): void {

        if (!this.matSelect.panelOpen) {

            this.matSelect.open();
        }
        this.selectSearch.searchSelectInput.nativeElement.value = search;
        this.searchControl.setValue(search);
    }

    //endregion
    //region Events

    /**
     * Обработчик клика очистки значений, выбранных в выпадашке.
     *
     * Предотвращает дальнейшее вслытие, так как иначе выпадашка откроется.
     *
     * @param event Событие клика очистки значений, выбранных в выпадашке.
     */
    clearBtnClickHandler(event: any): void {

        this.valueControl.reset(null);
        event.stopPropagation();
    }

    /**
     * Обработчик нажатия кнопок в поле поиска.
     *
     * Останавливает кнопки HOME и END от всплытия, так как иначе mat-select сделает event.preventDefault() для них,
     * что не даст в поле поиска перемещаться в начало или конец поля.
     *
     * @param event Событие нажатия кнопок в поле поиска.
     */
    keydownHandler(event: any): void {

        if (event.keyCode === 35 || event.keyCode === 36) {

            event.stopPropagation();
        }
    }

    /**
     * Обработчик открытия выпадающего списка.
     */
    openSelectHandle(opened: boolean): void {

        this.opened$.next(opened);
    }

    /**
     * Обработчик события изменения выбранного элемента.
     *
     * @param event Событие выбор значения в выпадашке.
     */
    selectionChangeHandler(event: MatSelectChange) {

        this.selectionChange.next(event);
    }

    //endregion
    //region Private

    /**
     * Фильтрует варианты для выбора согласно поисковой строке.
     * 
     * @param search Поисковая строка.
     *
     * @return Отфильтрованные варианты для выбора.
     */
    private _filterStaticOptionsBySearch(search: string): Option[] {

        search = search.toLowerCase();

        return this.options.filter((option: Option): boolean =>
            this.getOptionText(option).toLowerCase().includes(search)
        );
    }

    /**
     * Возвращает список вариантов для выбора без выбранных вариантов.
     *
     * Также, проверяет, нет ли среди выбранных значений вариант, обозначающий 'null'-значение, и в зависимости от этого
     * меняет соответствующий флаг.
     *
     * @param options Список всех вариантов.
     * @param selected Список выбранных вариантов.
     *
     * @return Список вариантов для выбора без выбранных вариантов.
     */
    private _getNotSelectedOptions(options: Option[], selected: Option | Option[]): Option[] {

        if (isOptionArray(selected)) {

            this.isNullSelected = selected.some((selectedOpt: Option) => !!selectedOpt.id && selectedOpt.id === "null");

            return options.filter((opt: Option): boolean =>
                !selected.some((selectedOpt: Option) => selectedOpt.id === opt.id)
            );
        }
        else if (isOption(selected)) {

            this.isNullSelected = !!selected.id && selected.id === "null";

            return options.filter((opt: Option): boolean => opt.id !== (selected as Option).id);
        }
        else {

            this.isNullSelected = false;

            return options;
        }
    }

    /**
     * Включает или отключает контрол в зависимости от входящих данных.
     */
    private _disableOrEnableControl(): void {

        if (this._selectDisabled) {

            this.valueControl.disable();
        }
        else {

            if (
                this._disableOnEmptyOptions
                && (!this.options || this.options.length === 0)
                && !this.pagedSearchFn
                && !this.searchFn
            ) {

                this.valueControl.disable();
            }
            else {
    
                this.valueControl.enable();
            }
        }
    }

    /**
     * Обновляет значение выбранных вариантов у контрола.
     *
     * Если передается пустой массив, то присваивает контролу null-значение.
     *
     * @param value Значение выбранных вариантов у контрола.
     */
    private _setSelected(value: Option | Option[]): void {

        if (value instanceof Array) {

            if (value.length) {

                this.selected$.next(value);
                this.maxSelectedReached = value && this.maxSelected && this.maxSelected <= value.length;
            }
            else {

                this.valueControl.reset(null);
                this.maxSelectedReached = false;
            }
        }
        else {

            this.selected$.next(value);
        }

        this._updateControlState();
        this._changeCallback(value);
    }

    /**
     * Если превышено максимальное количество символов для поиска, открывает выпадашку подставляет заданную поисковую
     * строку и запускает поиск.
     *
     * @param search Строка для поиска.
     *
     * @return true, если количество символов строки для поиска не превысило максимального. Иначе - false.
     */
    private _maxLengthReachedOpenAndSearch(search: string): boolean {

        const result = search.length <= this.maxLengthToSearch;

        if (!result) {

            this.openAndSearch(search.substring(0, this.maxLengthToSearch));
        }

        return result;
    }

    /**
     * Очищает массив динамически подгруженных элементов при каждом изменении поисковой строки.
     *
     * Проверяет, имеет ли передаваемая строка минимальную длину для поиска:
     * - Если да, то осуществляет поиск с помощью функции поиска, и возращает результат.
     * - Иначе вовращает пустой массив.
     *
     * @param search Строка для поиска.
     *
     * @return Список вариантов, соответствующих поисковой строке.
     */
    private _pagedSearchFnAfterSearchChange(search: string): Observable<Option[]> {

        this.searchOptions$.next([]);

        if (search.length < this.minLengthToSearch) {

            this.searchLengthReached = false;

            return of([] as Option[]);
        }
        else {

            this._currentSearchPage = 1;
            this.loading$.next(true);
            this.searchLengthReached = true;

            return this.pagedSearchFn(search, this._currentSearchPage, this.pageSize);
        }
    }

    /**
     * Осуществляет поиск с помощью функции поиска при скролле.
     *
     * @param search Строка для поиска.
     *
     * @return Список вариантов, соответствующих поисковой строке.
     */
    private _pagedSearchFnAfterScroll(search: string): Observable<Option[]> {

        this._currentSearchPage++;
        this.loading$.next(true);

        return this.pagedSearchFn("" + search, this._currentSearchPage, this.pageSize);
    }

    /**
     * Добавляет список динамически подгруженных вариантов к подгруженным ранее вариантам.
     *
     * Если список подгруженных вариантов меньше чем количество вариантов на одной странице поиска, считается, что это
     * последняя страница поиска (currentSearchPage присваивается значение -1).
     *
     * @param newOptions Список динамически подгруженных вариантов для текущей страницы.
     *
     * @return Список всех динамически подгруженных вариантов, соответствующих строке.
     */
    private _addNewSearchPageOptions(newOptions: Option[]): Option[] {

        if (newOptions.length < this.pageSize) {

            this._currentSearchPage = -1;
        }

        return [... this.searchOptions$.getValue(), ...newOptions];
    }

    /**
     * Обновляет состояние внутреннего контрола.
     */
    private _updateControlState(): void {

        if (this._ngControl.enabled) {

            this.valueControl.enabled || this.valueControl.enable();
        }
        else {

            this.valueControl.disabled || this.valueControl.disable();
        }

        if (this._ngControl.touched) {

            this.valueControl.touched || this.valueControl.markAsTouched();
        }
        else {

            this.valueControl.untouched || this.valueControl.markAsUntouched();
        }

        this.valueControl.setErrors(this._ngControl.errors);
    }

    //endregion
}
