import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { CanActivateChild } from '@angular/router';

import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import { RootState } from '../../root/store';
import { currentUserInfoSelector } from '../../root/store';
import { currentUserStateSelector } from '../../root/store';

import { RouteService } from 'src/app/common/services';

import { CurrentUserInfo } from '../../common/models';

/**
 * Route Guard.
 * Проверка возможности захода по URL в зависимости от наличия аутентифицированного пользователя.
 * 
 * Если текущий пользователь есть, то переход по URL возможен, если текущего пользователя нет, то невозможен.
 */
@Injectable({
    providedIn: 'root'
})
export class UserGuard implements CanActivate, CanActivateChild {
    //region Ctor

    constructor(
        private _store: Store<RootState>,
        private _routeService: RouteService
    ) { }

    //endregion
    //region Public

    canActivate(): Observable<boolean> {

        return this._getCurrentUser()
            .pipe(
                tap(currentUser => !currentUser && this._routeService.goToLogin()),
                switchMap(user => of(!!user)),
                catchError(() => of(false))
            );
    }

    canActivateChild(): Observable<boolean> {

        return this.canActivate();
    }

    //endregion
    //region Private

    /**
     * Возвращает данные текущего аутентифицированного пользователя.
     *
     * Если данные по текущему аутентифицированному пользователю не запрашивались, то вызывает их загрузку.
     */
    private _getCurrentUser(): Observable<CurrentUserInfo> {

        return this._store
            .pipe(
                // Запрашиваем состояние текущего пользователя.
                select(currentUserStateSelector),

                // Фильтрация, чтобы дождаться попытки загрузки данных пользователя.
                filter(userState => userState.loaded || userState.failed),

                // Запрашиваем получившиеся данные.
                switchMap(() => this._store.pipe(select(currentUserInfoSelector)))
            );
    }

    //endregion
}
