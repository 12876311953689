import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { BroadcastMessage } from "src/app/common/models/broadcast-message.model";
import { BroadcastMessageSendResponseProps } from "src/app/root/store/actions/props/broadcast-message-send-response-props";
import { ErrorResponseProps } from "src/app/root/store/actions/props/error-response.action-props";
import { ValueProps } from "src/app/root/store/actions/props/value-props";

/**
 * Типы событий, связанные с диалогом для отправки приглашений пользователей в пространства документов.
 */
export enum BroadcastMessagesDlgActionType {
    //region Enum values

    /**
     * Тип события, требующий открытия диалога сообщения пользователю.
     */
    OPEN = "[Broadcast message dialog] Open dialog",

    /**
     * Тип события, требующий закрытия диалога сообщения пользователю.
     */
    CLOSE = "[Broadcast message dialog] Close dialog",

    /**
     * Тип события, требующий закрыть текущее событие с условием напоминания при следующей загрузке страницы.
     */
    REMIND_LATER_CLOSE = "[Broadcast message dialog] Remind later",

    /**
     * Тип события, требующий переключения флага "Больше не показывать".
     */
    TOGGLE_DONT_SHOW_AGAIN_FLAG = "[Broadcast message dialog] Toggle don't show again flag",

    /**
     * Тип события, требующий переключения на предыдущее сообщение.
     */
    SELECT_PREVIOUS = "[Broadcast message dialog] Select previous message",

    /**
     * Тип события, требующий переключения на следующее сообщение.
     */
    SELECT_NEXT = "[Broadcast message dialog] Select next message",

    /**
     * Тип события, требующий отправки ответа на сообщения.
     */
    SEND_RESPONSE = "[Broadcast message dialog] Send response",

    /**
     * Тип события, требующий обработки успешного результата отправки ответа.
     */
    SEND_RESPONSE_SUCCESS = "[Broadcast message dialog] Send response success",

    /**
     * Тип события, требующий обработки неудачного результата отправки ответа.
     */
    SEND_RESPONSE_FAIL = "[Broadcast message dialog] Send response fail",

    //endregion
}

/**
 * Событие, требующее открытия диалога сообщения пользователю.
 */
const open = createAction(BroadcastMessagesDlgActionType.OPEN, props<ValueProps<BroadcastMessage[]>>());

/**
 * Событие, требующее закрытия диалога сообщений Для пользователя.
 */
const close = createAction(BroadcastMessagesDlgActionType.CLOSE);

/**
 * Событие, требующее закрыть текущее событие с условием напоминания при следующей загрузке страницы.
 */
const remindLaterClose = createAction(
    BroadcastMessagesDlgActionType.REMIND_LATER_CLOSE,
    props<{ message: BroadcastMessage }>(),
);

/**
 * Событие, требующее переключения флага "больше не показывать".
 */
const toggleDontShowAgainFlag = createAction(
    BroadcastMessagesDlgActionType.TOGGLE_DONT_SHOW_AGAIN_FLAG,
    props<{ message: BroadcastMessage }>(),
);

/**
 * Событие, требующее показа предыдущего сообщения.
 */
const selectPrevious = createAction(BroadcastMessagesDlgActionType.SELECT_PREVIOUS);

/**
 * Событие, требующее показа предыдущего сообщения.
 */
const selectNext = createAction(BroadcastMessagesDlgActionType.SELECT_NEXT);

/**
 * Событие, отправки ответа на сообщение.
 */
const sendResponse = createAction(
    BroadcastMessagesDlgActionType.SEND_RESPONSE,
    props<BroadcastMessageSendResponseProps>(),
);

/**
 * Событие, требующее обработки успешного результата отправки ответа на сообщение сообщения.
 */
const sendResponseSuccess = createAction(BroadcastMessagesDlgActionType.SEND_RESPONSE_SUCCESS);

/**
 * Событие, требующее обработки неудачного результата отправки ответа на сообщение сообщения.
 */
const sendResponseFail = createAction(
    BroadcastMessagesDlgActionType.SEND_RESPONSE_FAIL,
    props<ErrorResponseProps<void>>(),
);

/**
 * Тип, объединяющий события, связанные с диалогом сообщений для пользователя.
 */
export type BroadcastMessageDlgAction =
    ReturnType<typeof open>
    | ReturnType<typeof close>
    | ReturnType<typeof remindLaterClose>
    | ReturnType<typeof toggleDontShowAgainFlag>
    | ReturnType<typeof selectPrevious>
    | ReturnType<typeof selectNext>
    | ReturnType<typeof sendResponse>
    | ReturnType<typeof sendResponseSuccess>
    | ReturnType<typeof sendResponseFail>
    ;

/**
 * Все события, связанные с диалогом сообщений для пользователя.
 */
export const broadcastMessageDlgActions = {
    open,
    close,
    remindLaterClose,
    toggleDontShowAgainFlag,
    selectPrevious,
    selectNext,
    sendResponse,
    sendResponseSuccess,
    sendResponseFail,
};
