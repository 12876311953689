import { Action } from "@ngrx/store";
import { ApiResponse } from "src/app/common/models";
import { Feedback } from "src/app/root/models";

/**
 * Типы событий, связанные заголовком сайта.
 */
export enum FeedbackActionType {
    INIT = "[Feedback] Init dialog",
    SEND = "[Feedback] Send feedback",
    SEND_SUCCESS = "[Feedback] Send feedback Success",
    SEND_FAIL = "[Feedback] Send feedback Fail",
}

/**
 * Событие инициализации диалога обратной связи.
 */
export class FeedbackInitAction implements Action {
    readonly type = FeedbackActionType.INIT;
    constructor(public payload: Feedback) { }
}

/**
 * Событие отправки данных обратнйо связи.
 */
export class FeedbackSendAction implements Action {
    readonly type = FeedbackActionType.SEND;
    constructor(public payload: string) { }
}

/**
 * Событие успешной отправки данных.
 */
export class FeedbackSendSuccessAction implements Action {
    readonly type = FeedbackActionType.SEND_SUCCESS;
}

/**
 * Событие не успешной отправки данных.
 */
export class FeedbackSendFailAction implements Action {
    readonly type = FeedbackActionType.SEND_FAIL;
    constructor(public payload: ApiResponse) { }
}

/**
 * Тип, объединяющий все события, связанные с заголовком сайта.
 */
export type FeedbackAction =
    | FeedbackInitAction
    | FeedbackSendAction
    | FeedbackSendSuccessAction
    | FeedbackSendFailAction
    ;
