import { BroadcastMessage } from "src/app/common/models/broadcast-message.model";
import { BroadcastMessagesDlgActionType } from "src/app/root/store/actions/broadcast-message-dlg.action";
import { BroadcastMessageDlgAction } from "src/app/root/store/actions/broadcast-message-dlg.action";
import { BroadcastMessagesDlgState } from "src/app/root/store/states/broadcast-messages-dlg.state";

/**
 * Начальное состояние диалога сообщений пользователям.
 */
const initialState: BroadcastMessagesDlgState = {
    broadcastMessages: [],
    currentMessageIndex: 0,
    sendingResponse: false,
    sendingResponseSuccess: false,
    sendingResponseError: null,
};

/**
 * Обработка событий, связанных с состоянием диалога сообщений для пользователей.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 *
 * @return Состояние диалога сообщения пользователям.
 */
export function broadcastMessageDlgReducer(
    state: BroadcastMessagesDlgState = initialState,
    action: BroadcastMessageDlgAction
): BroadcastMessagesDlgState {

    let result: BroadcastMessagesDlgState = state;

    switch (action.type) {

        case BroadcastMessagesDlgActionType.OPEN: {

            const broadcastMessages: BroadcastMessage[] = action.value.map((msg: BroadcastMessage) =>
                ({ ...msg, dontShowAgain: false })
            );

            result = {
                ...state,
                broadcastMessages,
            };
            break;
        }

        case BroadcastMessagesDlgActionType.REMIND_LATER_CLOSE: {

            let newMessageIndex: number = state.currentMessageIndex;

            if (newMessageIndex > 0 && newMessageIndex === (state.broadcastMessages.length - 1)) {

                newMessageIndex --;
            }

            const broadcastMessages: BroadcastMessage[] = state.broadcastMessages
                .filter((message: BroadcastMessage): boolean => message.id !== action.message.id);

            result = {
                ...state,
                broadcastMessages,
                currentMessageIndex: newMessageIndex,
            };
            break;
        }

        case BroadcastMessagesDlgActionType.TOGGLE_DONT_SHOW_AGAIN_FLAG: {

            const broadcastMessages: BroadcastMessage[] = state.broadcastMessages
                .map((message: BroadcastMessage): BroadcastMessage => {

                    if (message.id === action.message.id) {

                        return { ...message, dontShowAgain: !message.dontShowAgain };
                    }
                    else {

                        return message;
                    }
                });

            result = {
                ...state,
                broadcastMessages,
            };
            break;
        }

        case BroadcastMessagesDlgActionType.SELECT_PREVIOUS: {

            let newIndex: number = state.currentMessageIndex - 1;
            if (newIndex < 0) {

                newIndex = state.broadcastMessages.length - 1;
            }

            result = {
                ...state,
                currentMessageIndex: newIndex,
                sendingResponseError: null,
            };
            break;
        }

        case BroadcastMessagesDlgActionType.SELECT_NEXT: {

            let newIndex: number = state.currentMessageIndex + 1;
            if (newIndex === state.broadcastMessages.length) {

                newIndex = 0;
            }

            result = {
                ...state,
                currentMessageIndex: newIndex,
                sendingResponseError: null,
            };
            break;
        }

        case BroadcastMessagesDlgActionType.SEND_RESPONSE: {

            result = {
                ...state,
                sendingResponse: true,
                sendingResponseError: null,
                sendingResponseSuccess: false,
            };
            break;
        }

        case BroadcastMessagesDlgActionType.SEND_RESPONSE_SUCCESS: {

            let newMessageIndex: number = state.currentMessageIndex;
            if (state.currentMessageIndex === (state.broadcastMessages.length - 1)) {

                newMessageIndex --;
            }

            result = {
                ...state,
                sendingResponse: false,
                sendingResponseError: null,
                sendingResponseSuccess: true,
                broadcastMessages: state.broadcastMessages.filter((broadcastMessage: BroadcastMessage) =>
                    broadcastMessage !== state.broadcastMessages[state.currentMessageIndex]
                ),
                currentMessageIndex: newMessageIndex,
            };
            break;
        }

        case BroadcastMessagesDlgActionType.SEND_RESPONSE_FAIL: {

            result = {
                ...state,
                sendingResponse: false,
                sendingResponseError: action.error,
                sendingResponseSuccess: false,
            };
            break;
        }
    }

    return result;
}
