import { HostListener } from "@angular/core";
import { ElementRef } from "@angular/core";
import { Directive } from "@angular/core";
import { RegexConstants } from "src/app/common/utils/regex-constants";
import { NgControl } from "@angular/forms";

/**
 * Директива-декоратор для ввода чисел.
 */
@Directive({
    selector: "[onlyDigits]",
})
export class OnlyDigitsInputDirective {
    //region Constants

    /**
     * Список клавиш, нажатие которых не должно отменяться.
     *
     * @private
     */
    private static readonly _ALLOWED_KEYS: string[] = [
        "Enter",
        "Tab",
        "Escape",
        "Backspace",
        "Delete",
        "PageUp",
        "PageDown",
        "Home",
        "End",
        "ArrowUp",
        "ArrowDown",
        "ArrowLeft",
        "ArrowRight",
        "F1",
        "F2",
        "F3",
        "F4",
        "F5",
        "F6",
        "F7",
        "F8",
        "F9",
        "F10",
        "F11",
        "F12",
    ];

    /**
     * Список клавиш, нажатие которых в связке с ctrl не должно отменяться.
     *
     * @private
     */
    private static readonly _SHORTCUT_KEYS: string[] = [
        "a",
        "x",
        "c",
        "v",
        "ф",
        "ч",
        "с",
        "м",
    ];

    //endregion
    //region Fields

    /**
     * Хост-элемент директивы.
     *
     * @private
     */
    private readonly _element: ElementRef<HTMLInputElement>;

    /**
     * Контрол инпута.
     */
    private _formControl: NgControl;

    //endregion
    //region Ctor

    /**
     * Конструктор директивы.
     *
     * @param formControl Контрол инпута.
     * @param element Хост-элемент директивы.
     */
    constructor(formControl: NgControl, element: ElementRef) {

        this._element = element;
        this._formControl = formControl;
    }

    //endregion
    //region Events

    /**
     * Обработчик события нажатия клавиши.
     *
     * Позволяет вводить только численные значения.
     *
     * @param e Событие нажатия клавиши.
     */
    @HostListener("keydown", ["$event"])
    onKeyDownHandler(e: KeyboardEvent): void {

        if (
            OnlyDigitsInputDirective._ALLOWED_KEYS.includes(e.key)
            || (OnlyDigitsInputDirective._SHORTCUT_KEYS.includes(e.key) && (e.ctrlKey || e.metaKey))
        ) {
            return;
        }

        if (e.key.match(RegexConstants.NOT_DIGIT)) {

            e.preventDefault();
        }
    }

    /**
     * Обработчик события вставки.
     *
     * Удаляет все, кроме чисел, в вставляемом значении из буфера и вставляет полученный текст в инпут с учетом
     * положения курсора. Если в инпуте выделен текст, то заменяет его.
     *
     * @param e Событие вставки.
     */
    @HostListener("paste", ["$event"])
    onPasteHandler(e: ClipboardEvent): void {

        e.preventDefault();

        const pastedInput: string = e.clipboardData.getData("text/plain");
        const element: HTMLInputElement = this._element.nativeElement;

        let result: string = pastedInput.replace(RegexConstants.NOT_DIGIT, "");

        result = element.value.substr(0, element.selectionStart)
            .concat(
                result,
                element.value.substr(element.selectionEnd),
            );

        if (this._formControl) {

            this._formControl.control.setValue(result);
        }
        else {

            element.value = result;
        }
    }

    /**
     * Обработчик события снятия фокуса с инпута.
     *
     * Присваивает не пустое значение HTML-элемента контролу, если он есть.
     */
    @HostListener("blur", ["$event"])
    onBlur(): void {

        const element: HTMLInputElement = this._element.nativeElement;

        if (element.value !== "" && this._formControl) {

            this._formControl.control.setValue(element.value);
        }
    }

    //endregion
}
