import { createSelector } from "@ngrx/store";
import { createFeatureSelector } from "@ngrx/store";
import { Currency } from "src/app/common/models/currency";
import { getRequestResult } from "src/app/common/models/request-state";
import { CurrenciesState } from "src/app/root/store/states/currencies.state";


/**
 * Селектор состояния валют.
 */
const state = createFeatureSelector<CurrenciesState>("currencies");

/**
 * Селектор валют.
 */
const currencies = createSelector(
    state,
    (currenciesState: CurrenciesState): Currency[] =>
        currenciesState && getRequestResult(currenciesState.currencyRequest),
);

/**
 * Объект, объединяющий все селекторы валют.
 */
export const currenciesSelectors = {
    state,
    currencies,
};