import { Injectable } from "@angular/core";

import { Actions } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { catchError } from "rxjs/operators";

import { NewDocumentsCountActionType } from "../../actions";
import { NewDocumentsCountMarkAsSeenAction } from "../../actions";
import { NewDocumentsCountLoadAction } from "../../actions";
import { NewDocumentsCountLoadSuccessAction } from "../../actions";
import { NewDocumentsCountLoadFailAction } from "../../actions";

import { SpaceService } from "src/app/common/services/space.service";
import { DocumentsService } from "src/app/common/services/documents.service";
import { Space } from "src/app/common/models";
import { EnteraDocument } from "src/app/common/models";
import { ApiResponse } from "src/app/common/models";

/**
 * Side-эффекты на события, связанные с количеством новых документов.
 */
@Injectable()
export class NewDocumentsCountEffects {
    //region Ctor

    constructor(
        private actions$: Actions,
        private spaceService: SpaceService,
        private documentsService: DocumentsService
    ) { }

    //endregion
    //region Public

    /**
     * Обработка события требования загрузки кол-ва новых документов.
     */
    @Effect()
    loadNewDocumentsCount$ = this.actions$
        .pipe(
            ofType(NewDocumentsCountActionType.LOAD),
            map((action: NewDocumentsCountLoadAction) => action.payload),
            switchMap((space: Space) =>
                this.spaceService.getNewDocumentsCount(space)
                    .pipe(
                        map(newDocumentsCount => new NewDocumentsCountLoadSuccessAction(newDocumentsCount)),
                        catchError((response: ApiResponse) => of(new NewDocumentsCountLoadFailAction(response)))
                    )
            )
        );

    /**
     * Обработка события требования пометить документ как просмотренный.
     * После выполнения метода пометки документа вызывается уменьшение кол-ва новых документов на 1.
     */
    @Effect({ dispatch: false })
    markDocumentAsSeen$ = this.actions$
        .pipe(
            ofType(NewDocumentsCountActionType.MARK_AS_SEEN),
            map((action: NewDocumentsCountMarkAsSeenAction) => action.payload),
            switchMap((document: EnteraDocument) =>
                this.documentsService.markAsSeen(document)
            )
        );

    //endregion
}