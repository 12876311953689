<div class="balance-info-menu">
    <info-menu
        [ngClass]="{ 'balance-info-menu__red': showUserNotification }"
        [matTooltip]="getExpiryNotificationTooltipText()"
        [matTooltipDisabled]="!showUserNotification"
        icon='description_outline'
        iconFontSet="material-icons-custom-set"
        hoveredIcon='description'
        [label]='balance$ | async'
        [menuItems]='menuItems'
        (menuItemClick)="menuItemClickHandler($event)"
    >
    </info-menu>
</div>
