import { DocumentMatch } from "src/app/spaces/modules/document-matching/models/document-match";
import { MatchTableSelectedElement } from "src/app/spaces/modules/document-matching/models/match-table-selected-element";
import { MatchingActionType } from "src/app/spaces/modules/document-matching/store/actions/matching.actions";
import { MatchingAction } from "src/app/spaces/modules/document-matching/store/actions/matching.actions";
import { MatchingState } from "src/app/spaces/modules/document-matching/store/states/matching.state";
import * as _ from "underscore";
import { BankStatementTransactionMatch } from "src/app/spaces/modules/document-matching/models/bank-statement-transaction-match";
import { BankStatementSubTransactionMatch } from "src/app/spaces/modules/document-matching/models/bank-statement-sub-transaction-match";
import { BillClosedAmount } from "src/app/spaces/modules/document-matching/models/bill-closed-amount";
import { isBillWiseDetailType } from "src/app/spaces/modules/document-matching/models/ledger-bill";

/**
 * Начальное состояние сопоставления документов.
 */
const initialState: MatchingState = {

    /**
     * Документы находятся в процессе создания?
     */
    creatingDocuments: false,

    /**
     * Документы созданы?
     */
    createdDocuments: false,

    /**
     * Ошибка, возникшая при создании документов.
     */
    creatingDocumentsError: null,

    /**
     * Сопоставления документов.
     */
    documentMatches: null,

    /**
     * Выбранный элемент.
     */
    selectedElement: null,

    /**
     * Выбранная строка.
     */
    selectedRow: null,

    /**
     * Пустой список элементов с заполненными чекбоксами.
     */
    markedElements: [],

    /**
     * Пустой список строк, отмеченных чекбоксами.
     */
    markedRows: [],

    /**
     * Состояние отображения просмотрщика скана документа.
     */
    showScanState: false,

    /**
     * Список закрытых сумм по счетам.
     */
    billClosedAmountList: [],

    /**
     * Созданные новые леджеры
     */
    createdLedgerList: [],
};

/**
 * Обработчик событий, связанных с изменением состояния сопоставления документов.
 *
 * @param state Текущее состояние сопоставления документов.
 * @param action Событие.
 *
 * @return Новое состояние.
 */
export function matchingReducer(
    state = initialState,
    action: MatchingAction,
): MatchingState {
    let result = state;

    switch (action.type) {

        case MatchingActionType.START: {

            const documentMatches = [ ...action.documentMatches as DocumentMatch[] ];
            const bsTransactions: BankStatementTransactionMatch[] = _.flatten(
                documentMatches.map(match => match.bankStatement)
                    .filter(Boolean)
                    .map(bs => bs.transactions || []),
                true,
            );
            const bsSubTransactions: BankStatementSubTransactionMatch[] = _.flatten(
                bsTransactions.filter(Boolean)
                    .map(transaction => transaction.subTransactions || []),
                true,
            );
            const billClosedAmountList: BillClosedAmount[] = [ ...bsTransactions, ...bsSubTransactions ]
                .map((transaction): BillClosedAmount => ({ bill: transaction.ledgerBill, amount: transaction.amount }))
                .reduce(updateLedgerBillClosedAmountList, []);

            result = {
                ...initialState,
                documentMatches,
                billClosedAmountList,
            };
            break;
        }

        case MatchingActionType.SELECT_ELEMENT: {

            result = {
                ...state,
                selectedElement: action.value,
            };
            break;
        }

        case MatchingActionType.SELECT_ROW: {

            result = {
                ...state,
                selectedRow: action.value,
            };
            break;
        }

        case MatchingActionType.SWAP_COMPANIES: {

            const documentMatch = state.documentMatches[action.value];

            let updatedMatch = {
                ...documentMatch,
                document: {
                    ...documentMatch.document,
                    supplier: documentMatch.document.customer,
                    customer: documentMatch.document.supplier,
                },
            };

            result = updateMatchingDocument(state, action.value, updatedMatch);

            break;
        }

        case MatchingActionType.SET_COMPANIES_AFTER_SWAP: {

            let documentMatch = { ...state.documentMatches[action.matchIndex] };
            const newCompanyList = action.companies.map(company => ({
                externalCompany: company,
                historicalData: company.historicalData,
                probability: company.probability
            }));
            const newCompany = action.companies.filter(company => company.probability > 0.7)
                .reduce(
                    (prev, curr) => ((prev && prev.probability || 0) > (curr && curr.probability || 0)) && prev || curr,
                    null
                );

            if (action.companyType === "customer") {

                documentMatch = {
                    ...documentMatch,
                    customer: newCompany,
                    matchCustomerList: newCompanyList
                };
            }
            else if (action.companyType === "supplier") {

                documentMatch = {
                    ...documentMatch,
                    supplier: newCompany,
                    matchSupplierList: newCompanyList
                };
            }

            result = updateMatchingDocument(state, action.matchIndex, documentMatch);

            break;
        }

        case MatchingActionType.MARK_ELEMENT: {

            result = {
                ...state,
                markedElements: [ ...state.markedElements, action.value ],
            };
            break;
        }

        case MatchingActionType.CHANGE_INVENTORY: {

            const array: MatchTableSelectedElement[] = state.markedElements
                .map((element: MatchTableSelectedElement): MatchTableSelectedElement => {
                    if (
                        element.matchIndex === action.value.matchIndex
                        && element.itemIndex === action.value.itemIndex
                    ) {
                        return { ...element, itemMode: action.value.itemMode };
                    }
                    return element;
                });

            result = {
                ...state,
                markedElements: array,
            };
            break;
        }

        case MatchingActionType.UNMARK_DOCUMENT_ELEMENT: {

            const markedElements = state.markedElements
                .filter(element => element.matchIndex !== action.value.matchIndex);

            result = {
                ...state,
                markedElements,
            };
            break;
        }

        case MatchingActionType.UNMARK_DOCUMENT_ITEM_ELEMENT: {

            const markedElements = state.markedElements
                .filter(element =>
                    (element.matchIndex !== action.value.matchIndex) || (element.itemIndex !== action.value.itemIndex)
                );

            result = {
                ...state,
                markedElements,
            };
            break;
        }

        case MatchingActionType.MARK_ALL_ELEMENTS: {

            result = {
                ...state,
                markedElements: action.value,
            };
            break;
        }

        case MatchingActionType.UNMARK_ALL_ELEMENTS: {

            result = {
                ...state,
                markedElements: [],
            };
            break;
        }

        case MatchingActionType.SET_MARKED_ROWS: {

            result = {
                ...state,
                markedRows: action.value,
            };
            break;
        }

        case MatchingActionType.UPDATE_LEDGER_BILL_CLOSED_AMOUNT: {

            result = {
                ...state,
                billClosedAmountList: updateLedgerBillClosedAmountList(state.billClosedAmountList, action.value),
            };
            break;
        }

        case MatchingActionType.CREATE_LEDGER: {

            result = {
                ...state,
                createdLedgerList: [ ...state.createdLedgerList, action.value ],
            };
            break;
        }

        case MatchingActionType.EDIT_LEDGER: {

            let list = state.createdLedgerList;
            const index = list.findIndex(item => item.id === action.value.id);

            if (index >= 0) {

                list[index] = action.value;
            }

            result = {
                ...state,
                createdLedgerList: [...list],
            };
            break;
        }

        case MatchingActionType.CANCEL_LEDGER_DIALOG: {

            result = {
                ...state,
                createdLedgerList: [...state.createdLedgerList],
            };
            break;
        }

        case MatchingActionType.CREATE_DOCUMENTS: {

            result = {
                ...state,
                creatingDocuments: true,
                createdDocuments: false,
                creatingDocumentsError: null,
            };
            break;
        }

        case MatchingActionType.CREATE_DOCUMENTS_SUCCESS: {

            result = {
                ...initialState,
                createdDocuments: true
            };
            break;
        }

        case MatchingActionType.CREATE_DOCUMENTS_FAIL: {

            result = {
                ...state,
                creatingDocuments: false,
                createdDocuments: false,
                creatingDocumentsError: action.error,
            };
            break;
        }

        case MatchingActionType.ADD_MATCH_AMOUNT_MODIFIER: {

            result = {
                ...state,
                documentMatches: state.documentMatches.map(match => {

                    if (match.id === action.documentMatch.id) {

                        const existModifiers = match.amountModifiers || [];
                        existModifiers.push(action);

                        return {
                            ...match,
                            amountModifiers: existModifiers,
                        };
                    }
                    else {

                        return match;
                    }
                })
            };
            break;
        }

        case MatchingActionType.DELETE_MATCH_AMOUNT_MODIFIER: {

            result = {
                ...state,
                documentMatches: state.documentMatches.map((match, index) => {

                    if (index === action.matchIndex) {

                        const amountModifiers = match.amountModifiers || [];
                        amountModifiers.splice(action.modifierIndex, 1);
                        return {
                            ...match,
                            amountModifiers,
                        };
                    }
                    else {

                        return match;
                    }
                })
            };
            break;
        }

        case MatchingActionType.DELETE_MATCH: {

            result = {
                ...state,
                documentMatches: state.documentMatches.filter(match => match.id !== action.id),
            };
            break;
        }

        case MatchingActionType.DELETE_MATCHES: {

            let matchIdArray: string[] = [];
            for (let i = 0; i < action.value.length; i++) {

                matchIdArray.push(action.value[i].id);
            }

            result = {
                ...state,
                documentMatches: state.documentMatches.filter(match => !matchIdArray.includes(match.id)),
                markedElements: [],
                selectedElement: null,
            };
            break;
        }

        case MatchingActionType.OPEN_SCAN_VIEWER: {

            result = {

                ...state,
                showScanState: true,
            };
            break;
        }

        case MatchingActionType.CLOSE_SCAN_VIEWER: {

            result = {

                ...state,
                showScanState: false,
            };
            break;
        }

        case MatchingActionType.CANCEL: {

            result = initialState;
            break;
        }
    }

    return result;
}

/**
 * Обновляет сопоставление документа с указанным индексом в состоянии сопоставления.
 *
 * @param state Состояние сопоставления.
 * @param matchIndex Индекс сопоставления документа.
 * @param updatedMatch Обновлённое сопоставление документа.
 *
 * @return Новое состояние сопоставления.
 */
function updateMatchingDocument(state: MatchingState, matchIndex: number, updatedMatch: DocumentMatch): MatchingState {

    return {
        ...state,
        documentMatches: [
            ...state.documentMatches.slice(0, matchIndex),
            {
                ...updatedMatch,
            },
            ...state.documentMatches.slice(matchIndex + 1)
        ]
    };
}



/**
 * Обновляет список счетов с закрытыми по транзакциям суммами.
 *
 * Обновляет закрытую сумму указанного счёта или добавляет счёт в список, если его ещё нет.
 *
 * @param billClosedAmountList Список счетов с закрытыми по всем транзакциям суммами по ним.
 * @param billClosedAmount Счёт с суммой, закрытой по нему одной из транзакций.
 *
 * @return Обновлённый список счетов с закрытыми по всем транзакциям суммами по ним.
 */
function updateLedgerBillClosedAmountList(
    billClosedAmountList: BillClosedAmount[],
    billClosedAmount: BillClosedAmount
) {
    if (!billClosedAmount.bill || isBillWiseDetailType(billClosedAmount.bill.number)) {

        return billClosedAmountList;
    }
    const idx = billClosedAmountList.findIndex(billSum => billSum.bill.number === billClosedAmount.bill.number);

    if (idx === -1) {

        return [...billClosedAmountList, billClosedAmount];
    }
    const updatedBillClosedAmount: BillClosedAmount = {
        ...billClosedAmountList[idx],
        amount: billClosedAmountList[idx].amount + billClosedAmount.amount
    };

    return [
        ...billClosedAmountList.slice(0, idx),
        updatedBillClosedAmount,
        ...billClosedAmountList.slice(idx + 1, billClosedAmountList.length)
    ];
}
