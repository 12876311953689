var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Injector } from '@angular/core';
import { DEFAULT_COUNTER_ID, YANDEX_COUNTERS_CONFIGS } from './metrika.config';
var Metrika = /** @class */ (function () {
    function Metrika(injector) {
        this.defaultCounterId = injector.get(DEFAULT_COUNTER_ID);
        this.counterConfigs = injector.get(YANDEX_COUNTERS_CONFIGS);
        this.positionToId = this.counterConfigs.map(function (config) { return config.id; });
    }
    Metrika.getCounterNameById = function (id) {
        return "yaCounter" + id;
    };
    Metrika.getCounterById = function (id) {
        return window[Metrika.getCounterNameById(id)];
    };
    Metrika.prototype.addFileExtension = function (extensions, counterPosition) {
        return __awaiter(this, void 0, void 0, function () {
            var counter, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.counterIsLoaded(counterPosition)];
                    case 1:
                        counter = _a.sent();
                        counter.addFileExtension(extensions);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.warn('Counter is still loading');
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Metrika.prototype.extLink = function (url, options, counterPosition) {
        if (options === void 0) { options = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var counter, promise, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.counterIsLoaded(counterPosition)];
                    case 1:
                        counter = _a.sent();
                        promise = this.getCallbackPromise(options, url);
                        counter.extLink(url, options);
                        return [2 /*return*/, promise];
                    case 2:
                        error_2 = _a.sent();
                        console.warn('Counter is still loading');
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Metrika.prototype.file = function (url, options, counterPosition) {
        if (options === void 0) { options = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var counter, promise, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.counterIsLoaded(counterPosition)];
                    case 1:
                        counter = _a.sent();
                        promise = this.getCallbackPromise(options, url);
                        counter.file(url, options);
                        return [2 /*return*/, promise];
                    case 2:
                        error_3 = _a.sent();
                        console.warn('Counter is still loading');
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Metrika.prototype.getClientID = function (counterPosition) {
        var counter = this.getCounterByPosition(counterPosition);
        if (counter && counter.reachGoal) {
            return counter.getClientID();
        }
        console.warn('Counter is still loading');
    };
    Metrika.prototype.setUserID = function (userId, counterPosition) {
        return __awaiter(this, void 0, void 0, function () {
            var counter, error_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.counterIsLoaded(counterPosition)];
                    case 1:
                        counter = _a.sent();
                        counter.setUserID(userId);
                        return [2 /*return*/, { userId: userId, counterPosition: counterPosition }];
                    case 2:
                        error_4 = _a.sent();
                        console.warn('Counter is still loading');
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Metrika.prototype.userParams = function (params, counterPosition) {
        return __awaiter(this, void 0, void 0, function () {
            var counter, error_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.counterIsLoaded(counterPosition)];
                    case 1:
                        counter = _a.sent();
                        counter.userParams(params);
                        return [2 /*return*/, { params: params, counterPosition: counterPosition }];
                    case 2:
                        error_5 = _a.sent();
                        console.warn('Counter is still loading');
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Metrika.prototype.params = function (params, counterPosition) {
        return __awaiter(this, void 0, void 0, function () {
            var counter, error_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.counterIsLoaded(counterPosition)];
                    case 1:
                        counter = _a.sent();
                        counter.userParams(params);
                        return [2 /*return*/, { params: params, counterPosition: counterPosition }];
                    case 2:
                        error_6 = _a.sent();
                        console.warn('Counter is still loading');
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Metrika.prototype.replacePhones = function (counterPosition) {
        return __awaiter(this, void 0, void 0, function () {
            var counter, error_7;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.counterIsLoaded(counterPosition)];
                    case 1:
                        counter = _a.sent();
                        counter.replacePhones();
                        return [2 /*return*/, { counterPosition: counterPosition }];
                    case 2:
                        error_7 = _a.sent();
                        console.warn('Counter is still loading');
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Metrika.prototype.notBounce = function (options, counterPosition) {
        if (options === void 0) { options = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var counter, promise, error_8;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.counterIsLoaded(counterPosition)];
                    case 1:
                        counter = _a.sent();
                        promise = this.getCallbackPromise(options, options);
                        counter.notBounce(options);
                        return [2 /*return*/, promise];
                    case 2:
                        error_8 = _a.sent();
                        console.warn('Counter is still loading');
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Metrika.prototype.fireEvent = function (type, options, counterPosition) {
        if (options === void 0) { options = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var counter, promise, error_9;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.counterIsLoaded(counterPosition)];
                    case 1:
                        counter = _a.sent();
                        promise = this.getCallbackPromise(options, options);
                        counter.reachGoal(type, options.params, options.callback, options.ctx);
                        return [2 /*return*/, promise];
                    case 2:
                        error_9 = _a.sent();
                        console.error('error', error_9);
                        console.warn("'Event with type [" + type + "] can't be fired because counter is still loading'");
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Metrika.prototype.hit = function (url, options, counterPosition) {
        if (options === void 0) { options = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var counter, promise, error_10;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.counterIsLoaded(counterPosition)];
                    case 1:
                        counter = _a.sent();
                        promise = this.getCallbackPromise(options, options);
                        counter.hit(url, options);
                        return [2 /*return*/, promise];
                    case 2:
                        error_10 = _a.sent();
                        console.warn("'Hit for page [" + url + "] can't be fired because counter is still loading'");
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Metrika.prototype.getCallbackPromise = function (options, resolveWith) {
        return new Promise(function (resolve, reject) {
            var optionsCallback = options.callback;
            options.callback = function () {
                if (optionsCallback) {
                    optionsCallback.call(this);
                }
                resolve(resolveWith);
            };
        });
    };
    Metrika.prototype.counterIsLoaded = function (counterPosition) {
        var _this = this;
        return new Promise(function (resolve) {
            var counter = _this.getCounterByPosition(counterPosition);
            if (counter && counter.reachGoal) {
                resolve(counter);
            }
            else {
                var intervalId_1 = setInterval(function () {
                    var counter = _this.getCounterByPosition(counterPosition);
                    if (counter && counter.reachGoal) {
                        clearInterval(intervalId_1);
                        resolve(counter);
                    }
                }, 50);
            }
        });
    };
    Metrika.prototype.getCounterByPosition = function (counterPosition) {
        var counterId = this.getCounterIdByPosition(counterPosition);
        return Metrika.getCounterById(counterId);
    };
    Metrika.prototype.getCounterIdByPosition = function (counterPosition) {
        return (counterPosition === undefined)
            ? this.defaultCounterId
            : this.positionToId[counterPosition];
    };
    return Metrika;
}());
export { Metrika };
