import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import { FeedbackDlgComponent } from "../containers/feedback-dlg/feedback-dlg.component";
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@angular/common/http";
import * as i3 from "@ngrx/store";
/**
 * Сервис для работы с диалогом обратной связи.
 */
var FeedbackDlgService = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор сервиса для работы с диалогом обратной связи.
     *
     * @param dialog Сервис для работы с диалогами.
     * @param http HTTP клиент.
     * @param store Хранилище.
     */
    function FeedbackDlgService(dialog, http, store) {
        this._dialog = dialog;
        this._http = http;
        this._store = store;
    }
    //endregion
    //region Public
    /**
     * Открывает диалог обратной связи.
     *
     * @return Объект для управления диалогом и компонентом, который находится в диалоге.
     */
    FeedbackDlgService.prototype.open = function (type, documentId) {
        this._dialog.closeAll();
        return this._dialog.open(FeedbackDlgComponent, {
            data: { type: type, documentId: documentId },
            autoFocus: true,
        });
    };
    /**
     * Отправка обратной связи на сервер.
     *
     * @param feedback Данные для отправки.
     */
    FeedbackDlgService.prototype.send = function (feedback) {
        return this._http.post("/api/v1/feedback", feedback)
            .pipe(map(function (response) { return response.result; }), catchError(function (response) { return throwError(response.error); }));
    };
    FeedbackDlgService.ngInjectableDef = i0.defineInjectable({ factory: function FeedbackDlgService_Factory() { return new FeedbackDlgService(i0.inject(i1.MatDialog), i0.inject(i2.HttpClient), i0.inject(i3.Store)); }, token: FeedbackDlgService, providedIn: "root" });
    return FeedbackDlgService;
}());
export { FeedbackDlgService };
