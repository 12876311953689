import { Injectable } from "@angular/core";
import { select } from "@ngrx/store";
import { Store } from "@ngrx/store";

import { RootState } from "rootStore";
import { currentUserStateSelector } from "rootStore";
import { tap } from "rxjs/operators";
import { take } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { map } from "rxjs/operators";
import { ConfigurationService } from "src/app/common/services/configuration.service";
import { CurrentUserInfo } from "../../common/models";
import { CurrentUserState } from "../store/reducers/current-user.reducer";
import { BitrixWidgetService } from "./bitrix-widget.service";

import { OmnideskWidgetService } from "./omnidesk-widget.service";

/**
 * Сервис для добавления виджета техподдержки на страницу.
 */
@Injectable({
    providedIn: "root"
})
export class SupportWidgetService {
    //region Fields

    /**
     * Сервис для управления и доступа к состоянию приложения.
     */
    private readonly _store: Store<RootState>;

    /**
     * Сервис для добавления виджета Битрикс24 на страницу.
     */
    private readonly _bitrixWidgetService: BitrixWidgetService;

    /**
     * Сервис для добавления виджета Omnidesk на страницу.
     */
    private readonly _omnideskWidgetService: OmnideskWidgetService;

    /**
     * Сервис конфигураций.
     */
    private readonly _configService: ConfigurationService;

    //endregion
    //region Ctor

    /**
     * Конструктор сервиса для добавления виджета техподдержки на страницу.
     *
     * @param store Сервис для управления и доступа к состоянию приложения.
     * @param bitrixWidgetService Сервис для добавления виджета Битрикс24 на страницу.
     * @param omnideskWidgetService Сервис для добавления виджета Omnidesk на страницу.
     * @param configServcie Сервис конфигураций.
     */
    constructor(
        store: Store<RootState>,
        bitrixWidgetService: BitrixWidgetService,
        omnideskWidgetService: OmnideskWidgetService,
        configServcie: ConfigurationService
    ) {
        this._store = store;
        this._bitrixWidgetService = bitrixWidgetService;
        this._omnideskWidgetService = omnideskWidgetService;
        this._configService = configServcie;
    }

    //endregion
    //region Public

    /**
     * Добавляет виджет техподдержки на страницу приложения.
     *
     * Дожидается загрузки данных пользователя, после чего решает чей виджет добавлять. Если у пользователя первое
     * пространство документов имеет флаг, что это клиент, то добавляется виджет Omnidesk'а. В противном случае
     * добавляется виджет Битрикс24.
     */
    add(): void {

        this._store
            .pipe(
                select(currentUserStateSelector),
                filter((state: CurrentUserState): boolean => state.loaded),
                map((state: CurrentUserState): CurrentUserInfo => state.currentUserInfo),
                tap(() => this._configService.loadConfigurations()),
                take(1),
            )
            .subscribe((currentUser: CurrentUserInfo) => {

                if (currentUser && currentUser.belongsToPaidClient) {

                    this._omnideskWidgetService.add();
                }
                else {

                    this._bitrixWidgetService.add();
                }
            });
    }

    //endregion
}
