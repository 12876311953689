var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { PendingExternalElementsActionType } from "src/app/spaces/modules/document-matching/store/actions/pending-external-elements.action";
/**
 * Начальное состояние редактируемых элементов для внешней системы.
 */
var initialState = {
    customers: {},
    suppliers: {},
};
/**
 * Обработчик событий, связанных с редактированием элементов для внешней системы.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 *
 * @return Новое состояние.
 */
export function pendingExternalElementsReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var _a, _b;
    var result = state;
    switch (action.type) {
        case PendingExternalElementsActionType.ADD_SUPPLIER: {
            if (!state.suppliers[action.value.id]) {
                result = __assign({}, state, { suppliers: __assign({}, state.suppliers, (_a = {}, _a[action.value.id] = action.value, _a)) });
            }
            break;
        }
        case PendingExternalElementsActionType.ADD_CUSTOMER: {
            if (!state.customers[action.value.id]) {
                result = __assign({}, state, { customers: __assign({}, state.customers, (_b = {}, _b[action.value.id] = action.value, _b)) });
            }
            break;
        }
        case PendingExternalElementsActionType.UPDATE_SUPPLIERS: {
            var suppliers_1 = __assign({}, state.suppliers);
            action.value.forEach(function (company) { return suppliers_1[company.id] = __assign({}, company); });
            result = __assign({}, state, { suppliers: suppliers_1 });
            break;
        }
        case PendingExternalElementsActionType.UPDATE_CUSTOMERS: {
            var customers_1 = __assign({}, state.customers);
            action.value.forEach(function (company) { return customers_1[company.id] = __assign({}, company); });
            result = __assign({}, state, { customers: customers_1 });
            break;
        }
        case PendingExternalElementsActionType.CLEAR: {
            result = __assign({}, state, { suppliers: {}, customers: {} });
            break;
        }
    }
    return result;
}
