var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ofType } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { of } from "rxjs";
import { filter } from "rxjs/operators";
import { withLatestFrom } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { map } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { DlgService } from "src/app/common/services/dlg.service";
import { UserService } from "src/app/common/services/user.service";
import { purchaseDialogActions } from "src/app/root/store/actions/purchase-dialog.action";
import { PurchaseDialogActionType } from "src/app/root/store/actions/purchase-dialog.action";
import { currentUserStateSelector } from "../reducers";
/**
 * Side-эффекты на события, связанные диалогом о запросе на покупку страниц.
 */
var PurchaseDialogEffect = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор объекта обработки side-эффектов на события, связанные с диалогом о запросе на покупку страниц.
     *
     * @param _actions$ Поток событий в системе.
     * @param _store Хранилище.
     * @param userService Сервис с логикой для работы с пользователем.
     * @param dlgService Сервис для работы с диалогами.
     * @param translateService Сервис перевода сообщений.
     */
    function PurchaseDialogEffect(_actions$, _store, userService, dlgService, translateService) {
        var _this = this;
        this._actions$ = _actions$;
        this._store = _store;
        //endregion
        //region Effects
        /**
         * Обработка события открытия диалога покупки страниц.
         */
        this.openDialog$ = this._actions$
            .pipe(ofType(PurchaseDialogActionType.OPEN_DIALOG), withLatestFrom(this._store.select(currentUserStateSelector)), tap(function (_a) {
            var _ = _a[0], userState = _a[1];
            if (!userState.currentUserInfo.phoneConfirm) {
                _this._dlgService.openPhoneConfirmDialog(userState.currentUserInfo, function () { return _this._store.dispatch(purchaseDialogActions.open); });
            }
        }), filter(function (_a) {
            var _ = _a[0], userState = _a[1];
            return userState.currentUserInfo.phoneConfirm;
        }), tap(function () {
            if (_this._openedDlg) {
                _this._openedDlg.close();
            }
            _this._openedDlg = _this._dlgService.openPurchaseDialog({
                headerKey: "dialogs.purchase.header",
                loading: true,
                disableClose: true,
                confirmCallback: function (contactPhoneNumber) {
                    _this._store.dispatch(purchaseDialogActions.sendRequestPurchase({ value: contactPhoneNumber }));
                },
            });
        }));
        /**
         * Обработка событий отправки запроса о покупке страниц.
         */
        this.sendResponse$ = this._actions$.pipe(ofType(PurchaseDialogActionType.REQUEST_PURCHASE), switchMap(function (props) {
            return _this._userService.sendPurchaseRequest(props.value).pipe(map(function () { return purchaseDialogActions.sendRequestSuccess; }), catchError(function (error) {
                return of(purchaseDialogActions.sendRequestFail({ error: error }));
            }));
        }));
        this._userService = userService;
        this._dlgService = dlgService;
        this._translateService = translateService;
    }
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], PurchaseDialogEffect.prototype, "openDialog$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], PurchaseDialogEffect.prototype, "sendResponse$", void 0);
    return PurchaseDialogEffect;
}());
export { PurchaseDialogEffect };
