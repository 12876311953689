import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

/**
 * Сервис для работы с метрикой Facebook Pixel.
 */
@Injectable({
    providedIn: 'root'
})
export class FacebookPixelService {
    //region Fields

    /**
     * Скрипт для загрузки метрики вставлен в DOM-дерево?
     */
    private _scriptInserted: boolean;

    //endregion
    //region Ctor

    /**
     * Конструктор сервиса для работы с метрикой Facebook Pixel.
     */
    constructor() {

        this._scriptInserted = false;
    }

    //endregion
    //region Public

    /**
     * Добавляет скрипт для загрузки метрики на страницу приложения.
     */
    add(): void {

        const facebookPixelEnabled: boolean = environment.facebookPixel ? environment.facebookPixel.enabled : false;
        const facebookPixelId: string = environment.facebookPixel ? environment.facebookPixel.id : null;

        if (facebookPixelEnabled && !this._scriptInserted && facebookPixelId) {

            const script: HTMLScriptElement = document.createElement('script');
            script.text = `
                !function(f,b,e,v,n,t,s) {
                    if(f.fbq)return;
                    n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq) f._fbq=n;
                    n.push=n;
                    n.loaded=!0;
                    n.version='2.0';
                    n.queue=[];
                    t=b.createElement(e);
                    t.async=!0;
                    t.src=v;
                    s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s);
                }(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '${facebookPixelId}');
                fbq('track', 'PageView');
            `;

            const noscript: HTMLElement = document.createElement('noscript');
            const url: string = `https://www.facebook.com/tr?id=${facebookPixelId}&ev=PageView&noscript=1`;
            noscript.innerHTML = `<img height="1" width="1" style="display:none" src="${url}" alt="" />`;

            document.body.appendChild(script);
            document.body.appendChild(noscript);

            this._scriptInserted = true;
        }
    }

    //endregion
}
