<div
    #uploadDropZone
    class="upload-drop-zone"
    [class.upload-drop-zone_enter]="dropEnter"
    (dragover)="onDragOver($event)"
    (drop)="onDrop($event)"
    (dragenter)="onDragEnter($event)"
    (dragleave)="onDragLeave($event)"
>
    <div class="upload-button-container" *ngIf="uploadButtonVisibility">
        <span class="upload-button" (click)="uploadInput.click()">
            <mat-icon class="mat-icon_green upload-button__icon">add_circle_outline</mat-icon>
            <span *ngIf="multipleFileMode">{{"common.upload-to-recognize-dialog.buttons.select-file" | translate}}</span>
            <span *ngIf="!multipleFileMode">{{"common.upload-to-recognize-dialog.buttons.select-file" | translate}}</span>
            <input
                #uploadInput
                class="upload-button__input"
                type="file"
                [multiple]="multipleFileMode"
                [accept]="availableFileFormats"
                (change)="onChange()"
            />
        </span>
    </div>

    <div class="file-upload-zone">

        <div class="center-horizontal" *ngIf="uploadState && uploadState.filesWithMetaData.length; else emptySelection">
            <entera-file-upload-indicator
                [uploadState]="uploadState"
                (remove)="remove.emit($event)"
            >
            </entera-file-upload-indicator>
        </div>

        <ng-template #emptySelection>
            <div class="select-file">
                <mat-icon class="select-file__icon material-icons-custom-set">cloud_upload_outline</mat-icon>
                {{"common.upload-to-recognize-dialog.drop-zone.text" | translate}}
            </div>
        </ng-template>

    </div>

</div>
