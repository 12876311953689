import { createSelector } from '@ngrx/store';

import { balanceStateSelector } from '../reducers';
import { getBalanceInfo, getBalanceRestCreditCount, BalanceState } from '../reducers/balance.reducer';
import { getBalanceLoading, getBalanceLoaded, getBalanceFailed } from '../reducers/balance.reducer';
import { spaceSelector } from './space.selector';
import { Space } from 'src/app/common/models/space';

/**
 * Пара - текущее пространство, состояние баланса.
 * 
 * Состояние баланса может в некоторый момент относится к предыдущему пространству документов.
 */
export interface SpaceBalanceState {
    space: Space;
    balanceState: BalanceState;
}

/**
 * Селектор данных баланса.
 */
export const balanceInfoSelector = createSelector(balanceStateSelector, getBalanceInfo);

/**
 * Селектор текущего пространства и текущего состояния баланса.
 */
export const spaceBalanceStateSelector = createSelector(
    spaceSelector,
    balanceStateSelector,
    (space, balanceState): SpaceBalanceState => {
        return { space, balanceState }
    }
);

/**
 * Селектор доступного количества кредитов.
 */
export const balanceRestCreditCountSelector = createSelector(balanceInfoSelector, getBalanceRestCreditCount);

/**
 * Селектор флага успешной загрузки данных баланса.
 */
export const balanceLoadedSelector = createSelector(balanceStateSelector, getBalanceLoaded);

/**
 * Селектор флага выполняющейся загрузки данных баланса.
 */
export const balanceLoadingSelector = createSelector(balanceStateSelector, getBalanceLoading);

/**
 * Селектор флага была ли ошибка при загрузке данных баланса.
 */
export const balanceFailedSelector = createSelector(balanceStateSelector, getBalanceFailed);
