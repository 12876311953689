import { MatDialogRef } from "@angular/material";
import { Constants } from "../../../common/models";
/**
 * Компонент диалога для отображения ошибки завершения задачи на распознавание.
 */
var RecognitionTaskErrorDlgComponent = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор компонента диалога для отображения ошибки завершения задачи на распознавание.
     *
     * @param dialogRef Диалог, в котором открывается текущий компонент.
     * @param recognitionTask Задача на распознавание, которая завершилась ошибкой.
     */
    function RecognitionTaskErrorDlgComponent(recognitionTask, dialogRef) {
        this.recognitionTask = recognitionTask;
        this._dialogRef = dialogRef;
    }
    Object.defineProperty(RecognitionTaskErrorDlgComponent.prototype, "files", {
        //endregion
        //region Getters and Setters
        /**
         * Отформатированный список имен файлов в задаче на распознавание.
         */
        get: function () {
            var result = [];
            var resultSumLength = 0;
            for (var _i = 0, _a = this.recognitionTask.files; _i < _a.length; _i++) {
                var file = _a[_i];
                if (resultSumLength < RecognitionTaskErrorDlgComponent.FILES_MAX_LENGTH) {
                    var fileName = void 0;
                    if (resultSumLength + file.name.length <= RecognitionTaskErrorDlgComponent.FILES_MAX_LENGTH) {
                        fileName = file.name;
                        resultSumLength += file.name.length;
                    }
                    else {
                        var fileNameLength = RecognitionTaskErrorDlgComponent.FILES_MAX_LENGTH - resultSumLength;
                        fileNameLength -= Constants.ELLIPSIS.length;
                        fileName = file.name.slice(0, fileNameLength) + Constants.ELLIPSIS;
                        resultSumLength += fileName.length + Constants.ELLIPSIS.length;
                    }
                    result = result.concat([
                        fileName,
                    ]);
                }
            }
            return result;
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Public
    /**
     * Имя файла слишком длинное для отображения в диалоговом окне в одну строчку?
     *
     * @param fileName Имя файла.
     *
     * @return Да/Нет.
     */
    RecognitionTaskErrorDlgComponent.prototype.isFileNameTooLong = function (fileName) {
        return fileName.length > RecognitionTaskErrorDlgComponent.FILE_MAX_LENGTH;
    };
    //endregion
    //region Events
    /**
     * Обработчик нажатия на Esc.
     *
     * Закрывает диалог.
     */
    RecognitionTaskErrorDlgComponent.prototype.onKeyUp = function () {
        this._dialogRef.close();
    };
    //region Constants
    /**
     * Максимальная длина строки имен файлов, которая будет отображаться в диалоге.
     */
    RecognitionTaskErrorDlgComponent.FILES_MAX_LENGTH = 100;
    /**
     * Максимальная длина строки имени файла.
     *
     * Используется для определения необходимости переноса части имени на новую строку.
     */
    RecognitionTaskErrorDlgComponent.FILE_MAX_LENGTH = 30;
    return RecognitionTaskErrorDlgComponent;
}());
export { RecognitionTaskErrorDlgComponent };
