import { EventEmitter } from "@angular/core";
import { UrlUtils } from "src/app/common/utils/url.utils";
/**
 * Компонент выпадающего меню пространств документов текущего пользователя.
 */
var SpacesInfoMenuComponent = /** @class */ (function () {
    function SpacesInfoMenuComponent() {
        //region Constants
        //endregion
        //region Outputs
        /**
         * Исходящее событие - требование создания нового пространство документов.
         */
        this.createSpace = new EventEmitter();
        /**
         * Исходящее событие - требование перехода в выбранное пространство документов.
         */
        this.openSpace = new EventEmitter();
        /**
         * Исходящее событие - требование перехода в настройки выбранного пространства документов.
         */
        this.spaceSettings = new EventEmitter();
        /**
         * Исходящее событие - требование открыть диалог для приглашения пользователей в пространства документов.
         */
        this.openInvitationDialog = new EventEmitter();
        /**
         * Исходящее событие - требование открыть диалог с неизвестной ошибкой.
         */
        this.openErrorDialog = new EventEmitter();
        //endregion
    }
    Object.defineProperty(SpacesInfoMenuComponent.prototype, "space", {
        //endregion
        //region Getters and Setters
        /**
         * Возвращает текущее пространства документов.
         */
        get: function () {
            return this._space;
        },
        //endregion
        //region Inputs
        /**
         * Входящие данные - текущее пространство документов.
         */
        set: function (space) {
            this._space = space;
            this._buildMenuItems();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SpacesInfoMenuComponent.prototype, "spaces", {
        /**
         * Возвращает список пространств документов.
         */
        get: function () {
            return this._spaces;
        },
        /**
         * Входящие данные - список пространств документов.
         */
        set: function (spaces) {
            this._spaces = spaces;
            this._buildMenuItems();
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Hooks
    /**
     * Логика инициализации компонента.
     */
    SpacesInfoMenuComponent.prototype.ngOnInit = function () {
        this._buildMenuItems();
    };
    Object.defineProperty(SpacesInfoMenuComponent.prototype, "spaceName", {
        /**
         * Возвращает название текущего пространства документов, если оно есть.
         */
        get: function () {
            return (this.space ? this.space.name : null);
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Events
    /**
     * Обработчик клика по элементу меню.
     */
    SpacesInfoMenuComponent.prototype.menuItemClickHandler = function (event) {
        if (typeof event === "string") {
            if (event === SpacesInfoMenuComponent._INVITATION_MENU_ITEM_ID) {
                this.openInvitationDialog.emit();
            }
            else if (event === SpacesInfoMenuComponent._CREATE_SPACE_ITEM_ID) {
                this.createSpace.emit();
            }
            else {
                this.openErrorDialog.emit();
            }
        }
        else {
            var selectedSpace = event;
            if (!this._space
                || selectedSpace.id !== this._space.id
                || (UrlUtils.spaceIntegrationSettingsPageUrl(this._space.id) === this.path
                    || UrlUtils.spaceNotificationPageUrl(this._space.id) === this.path)) {
                this.openSpace.emit(event);
            }
        }
    };
    /**
     * Обработчик клика по правой иконке элемента меню.
     */
    SpacesInfoMenuComponent.prototype.menuItemRightIconClickHandler = function (space) {
        if (!this._space
            || space.id !== this._space.id
            || (UrlUtils.spaceIntegrationSettingsPageUrl(this._space.id) !== this.path
                && UrlUtils.spaceNotificationPageUrl(this._space.id) !== this.path)) {
            this.spaceSettings.emit(space);
        }
    };
    //endregion
    //region Private
    /**
     * Формирует элементы выпадающего меню.
     */
    SpacesInfoMenuComponent.prototype._buildMenuItems = function () {
        var newMenuItems = [];
        if (this.canInviteInAnySpace) {
            newMenuItems.push(this._getInvitationItem());
        }
        if (this.canCreateSpace) {
            newMenuItems.push(this._getCreateSpaceItem());
        }
        if (this.spaces) {
            for (var _i = 0, _a = this.spaces; _i < _a.length; _i++) {
                var space = _a[_i];
                newMenuItems.push({
                    id: space,
                    icon: " ",
                    rightIcon: "&#xe8b8;",
                    rightIconTitleKey: "header.spaces.settings.icon",
                    rightIconFontSet: "material-icons-custom-set",
                    text: space.name,
                    selected: (space === this.space)
                });
            }
        }
        this.menuItems = newMenuItems;
    };
    /**
     * Формирует и возвращает элемент меню для приглашения пользователей в пространства документов.
     *
     * @return Элемент меню для приглашения пользователей в пространства документов.
     */
    SpacesInfoMenuComponent.prototype._getInvitationItem = function () {
        return {
            id: SpacesInfoMenuComponent._INVITATION_MENU_ITEM_ID,
            icon: "group_add",
            messageKey: "header.spaces.settings.invitationItem",
            green: true,
        };
    };
    /**
     * Формирует и возвращает элемент меню для создания нового пространства документов.
     *
     * @return Элемент меню для создания нового пространства документов.
     */
    SpacesInfoMenuComponent.prototype._getCreateSpaceItem = function () {
        return {
            id: SpacesInfoMenuComponent._CREATE_SPACE_ITEM_ID,
            icon: "add_circle_outline",
            messageKey: "spaces.root.create",
            green: true,
        };
    };
    /**
     * ID элемента приглашения в меню выбора пространств документов.
     */
    SpacesInfoMenuComponent._INVITATION_MENU_ITEM_ID = "invitationItemId";
    /**
     * ID элемента создания новой папки в меню выбора пространств документов.
     */
    SpacesInfoMenuComponent._CREATE_SPACE_ITEM_ID = "createSpaceItemId";
    return SpacesInfoMenuComponent;
}());
export { SpacesInfoMenuComponent };
