import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { PermissionService } from "src/app/common/services/permission.service";
import { RouteService } from "src/app/common/services/route.service";
import * as i0 from "@angular/core";
import * as i1 from "../../common/services/permission.service";
import * as i2 from "../../common/services/route.service";
/**
 * Route Guard Factory.
 * Общая логика для повторного использования при создания Route Guard основанного на проверке
 * наличия права.
 */
var PermissionGuard = /** @class */ (function () {
    //region Ctor
    function PermissionGuard(_permissionService, _routeService) {
        this._permissionService = _permissionService;
        this._routeService = _routeService;
    }
    //endregion
    //region Public
    /**
     * Возвращает ожидание проверки наличия заданного права. При этом если права у пользователя нет, то
     * выполняется редирект на страницу /forbidden, сообщающую пользователю, что у него нет прав на просмотр
     * запрошенной страницы.
     *
     * Страница /forbidden отображает URL, куда хотел попасть пользователь. Соответственно данный метод принимает
     * параметр path, который передаёт ему guard.
     *
     * Но загвоздка в том, что в полном виде получить URL перехода на страницу не всегда представляется возможным.
     *
     * Есть два варианта перехода на страницу:
     * - через ввод URL в строке браузера
     * - через ссылку внутри приложения
     *
     * И есть два варианта защиты URL'а
     * - путь до страницы закрыт CanLoad guard'ом
     * - путь до страницы закрыт CanActivate guard'ом
     *
     * В случае ввода URL страницы в строке бразуера не важно как защищён URL, т.к. полный путь будет доступен
     * через location. Состояние RouteState при этом ещё будет равно null. Это обрабатывается внутри
     * RouteService.goToForbidden().
     *
     * В случае перехода по URL внутри приложения:
     *
     * - В случае CanLoad guard'а информацию о полном пути можно не получить, если CanLoad стоит в иерархии выше,
     * чем запрашиваемая страница. В этом случае CanLoad получит только чать URL'а, которую он защищает, как
     * входные данные в метод canLoad(route: CanLoadRoute)
     *
     * - В случае CanActivate guard'а информация о полном пути приходит как входные данные в метод
     * canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot).
     *
     * @param permission Право.
     * @param path Путь до страницы.
     */
    PermissionGuard.prototype.hasPermission = function (permission, path) {
        if (path === void 0) { path = null; }
        return this.hasAnyPermission([permission], path);
    };
    /**
     * Возвращает ожидание проверки наличия любого права из заданного списка. При этом если права у пользователя нет, то
     * выполняется редирект на страницу /forbidden, сообщающую пользователю, что у него нет прав на просмотр
     * запрошенной страницы.
     *
     * @param permissions Права.
     * @param path Путь до страницы.
     */
    PermissionGuard.prototype.hasAnyPermission = function (permissions, path) {
        var _this = this;
        if (path === void 0) { path = null; }
        // TODO catchError и редирект на /something-goes-wrong.
        return forkJoin(permissions.map(function (permissionItem) { return _this._permissionService.hasPermission(permissionItem); })).pipe(map(function (permissionResponses) { return permissionResponses.some(Boolean); }), tap(function (hasPermission) {
            if (!hasPermission) {
                _this._routeService.goToForbidden(path);
            }
        }));
    };
    PermissionGuard.ngInjectableDef = i0.defineInjectable({ factory: function PermissionGuard_Factory() { return new PermissionGuard(i0.inject(i1.PermissionService), i0.inject(i2.RouteService)); }, token: PermissionGuard, providedIn: "root" });
    return PermissionGuard;
}());
export { PermissionGuard };
