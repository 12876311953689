import { select } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { currentUserLangSelector } from 'src/app/root/store/selectors/current-user.selector';
import * as i0 from "@angular/core";
var LangService = /** @class */ (function () {
    //region Ctor
    function LangService() {
    }
    //endregion
    //region Public
    /**
     * Выполняет загрузку заданных сообщений в сервис для работы с i18n-сообщениями.
     *
     * @param translateService Сервис для работы с i18n-сообщениями.
     * @param langs Сообщения на разных языках.
     */
    LangService.prototype.loadTranslations = function (translateService, langs) {
        for (var _i = 0, langs_1 = langs; _i < langs_1.length; _i++) {
            var lang = langs_1[_i];
            translateService.setTranslation(lang.lang, lang.messages, true);
        }
    };
    /**
     * Выполняет подписку на событие изменения языка для Datepicker.
     *
     * @param store Состояние приложения.
     * @param dateAdapter Datepicker адаптер для работы с датой.
     */
    LangService.prototype.subscribeOnLangChangeForDatepicker = function (store, dateAdapter) {
        return store
            .pipe(select(currentUserLangSelector), filter(Boolean))
            .subscribe(function (lang) { return dateAdapter.setLocale(lang); });
    };
    LangService.ngInjectableDef = i0.defineInjectable({ factory: function LangService_Factory() { return new LangService(); }, token: LangService, providedIn: "root" });
    return LangService;
}());
export { LangService };
