var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { withLatestFrom } from "rxjs/operators";
import { BalanceActionType } from "../actions"; /* circular dependency break */
import { BalanceLoadAction } from "../actions"; /* circular dependency break */
import { BalanceLoadFailAction } from "../actions"; /* circular dependency break */
import { BalanceLoadSuccessAction } from "../actions"; /* circular dependency break */
import { RecognitionTasksActionType } from "../actions"; /* circular dependency break */
import { spaceSelector } from '../selectors'; /* circular dependency break */
import { SpaceService } from "src/app/common/services";
import { debounceTime } from 'rxjs/operators';
/**
 * Side-эффекты на события, связанные с балансом пространства документов.
 */
var BalanceEffects = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор класса с side-эффектами на события, связанные с балансом пространства документов.
     *
     * @param _actions$ Поток событий, происходящих в приложении.
     * @param _store Сервис для доступа и управления состоянием приложения.
     * @param spaceService Сервис для работы с пространствами документов.
     */
    function BalanceEffects(_actions$, _store, spaceService) {
        var _this = this;
        this._actions$ = _actions$;
        this._store = _store;
        //endregion
        //region Public
        /**
         * Обработка события требования загрузки данных баланса.
         */
        this.loadBalance$ = this._actions$
            .pipe(ofType(BalanceActionType.LOAD), map(function (action) { return action.payload; }), switchMap(function (space) {
            return _this._spaceService.getBalance(space)
                .pipe(map(function (restCreditCount) { return new BalanceLoadSuccessAction({ restCreditCount: restCreditCount, space: space }); }), catchError(function (response) { return of(new BalanceLoadFailAction(response)); }));
        }));
        /**
         * Обработка события после успешного завершения задачи на распознавание.
         *
         * Инициируется событие для запроса баланса с сервера, чтобы обновить значение баланса. В потоке используется
         * задержка, чтобы группировать события завершения задачи, если они происходят быстро друг за другом. Это
         * поможет избежать множества запросов к серверу.
         */
        this.updateBalance$ = this._actions$
            .pipe(ofType(RecognitionTasksActionType.TASK_FINISHED), withLatestFrom(this._store.select(spaceSelector)), debounceTime(250), map(function (_a) {
            var action = _a[0], space = _a[1];
            return new BalanceLoadAction(space);
        }));
        this._spaceService = spaceService;
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], BalanceEffects.prototype, "loadBalance$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], BalanceEffects.prototype, "updateBalance$", void 0);
    return BalanceEffects;
}());
export { BalanceEffects };
