
/**
 * Право на операцию.
 */
export class PermissionItem {
    //region Permissions

    //region Admin

    /**
     * Административные задачи.
     */
    public static readonly ADMIN = new PermissionItem("ADMIN");

    /**
     * Вход под пользователем.
     */
    public static readonly ADMIN_IMPERSONATE = new PermissionItem("ADMIN_IMPERSONATE", PermissionItem.ADMIN);

    /**
     * Контроль баланса пространств.
     */
    public static readonly CLIENT_CONTROL = new PermissionItem("CLIENT_CONTROL", PermissionItem.ADMIN);

    /**
    * Видеть все документы.
    */
    public static readonly SEE_ALL_DOCUMENTS = new PermissionItem("SEE_ALL_DOCUMENTS", PermissionItem.ADMIN);

    /**
     * Изменять пространства документов клиента.
     */
    public static readonly EDIT_CLIENT_SPACES = new PermissionItem("EDIT_CLIENT_SPACES", PermissionItem.CLIENT_CONTROL);

    /**
     * Видеть ссылку на редактирование
     */
    public static readonly ADVANCE_EDIT_ALL_DOCUMENTS =
        new PermissionItem("ADVANCE_EDIT_ALL_DOCUMENTS", PermissionItem.ADMIN);

    /**
     * Изменять баланс клиента.
     */
    public static readonly CHANGE_CLIENT_BALANCE = new PermissionItem(("CHANGE_CLIENT_BALANCE"), PermissionItem.ADMIN);

    /**
     * Увеличивать баланс клиента.
     */
    public static readonly INCREASE_CLIENT_BALANCE
        = new PermissionItem("INCREASE_CLIENT_BALANCE", PermissionItem.ADMIN);

    /**
     * Право изменять данные клиента.
     */
    public static readonly EDIT_CLIENT_INFO = new PermissionItem("EDIT_CLIENT_INFO", PermissionItem.CLIENT_CONTROL);

    /**
     * Право активировать/деактивировать клиента.
     */
    public static readonly  INACTIVATE_CLIENT = new PermissionItem("INACTIVATE_CLIENT", PermissionItem.CLIENT_CONTROL);

    /**
     * Право изменять активность подписки на функционал отчёта реализации.
     */
    public static readonly SALES_REPORT = new PermissionItem(
        "EDIT_SALES_REPORT_SUBSCRIPTION",
        PermissionItem.CLIENT_CONTROL,
    );

    /**
     * Право изменять информацию о партнере.
     */
    public static readonly EDIT_PARTNER_INFO = new PermissionItem("EDIT_PARTNER_INFO", PermissionItem.CLIENT_CONTROL);

    /**
     * Создавать новые пространства документов.
     */
    public static readonly CREATE_CLIENT_SPACE = new PermissionItem(
        "CREATE_CLIENT_SPACE",
        PermissionItem.EDIT_CLIENT_SPACES,
    );

    /**
     * Редактировать пространства документов.
     */
    public static readonly EDIT_CLIENT_SPACE = new PermissionItem(
        "EDIT_CLIENT_SPACE",
        PermissionItem.EDIT_CLIENT_SPACES,
    );

    /**
     * Редактировать данные пространства документов.
     */
    public static readonly EDIT_SPACE_INFO = new PermissionItem(
        "EDIT_SPACE_INFO",
        PermissionItem.EDIT_CLIENT_SPACE,
    );

    /**
     * Изменять баланс пространства документов.
     */
    public static readonly CHANGE_SPACE_BALANCE = new PermissionItem(
        "CHANGE_SPACE_BALANCE",
        PermissionItem.EDIT_CLIENT_SPACE,
    );

    /**
     * Переключать флаги пространства документов.
     */
    public static readonly TOGGLE_SPACE_FLAGS = new PermissionItem(
        "TOGGLE_SPACE_FLAGS",
        PermissionItem.EDIT_CLIENT_SPACE,
    );

    /**
     * Переключать флаги пространства документов.
     */
    public static readonly TOGGLE_EXTENDED_TYPES_SPACE_FLAG = new PermissionItem(
        "TOGGLE_EXTENDED_TYPES_SPACE_FLAG",
        PermissionItem.TOGGLE_SPACE_FLAGS,
    );

    /**
     * Переключать флаг TRIAL-приоритета пространства документов.
     */
    public static readonly TOGGLE_SPACE_TRIAL_FLAG = new PermissionItem(
        "TOGGLE_SPACE_TRIAL_FLAG",
        PermissionItem.TOGGLE_SPACE_FLAGS,
    );

    /**
     * Переключать флаг использования баланса клиента для пространства документов.
     */
    public static readonly TOGGLE_SPACE_USE_CLIENT_BALANCE_FLAG = new PermissionItem(
        "TOGGLE_SPACE_USE_CLIENT_BALANCE_FLAG",
        PermissionItem.TOGGLE_SPACE_FLAGS,
    );

    /**
     * Переключать флаг использования пространства документов для иностранных документов.
     */
    public static readonly TOGGLE_FOREIGN_SPACE_FLAG = new PermissionItem(
        "TOGGLE_FOREIGN_SPACE_FLAG",
        PermissionItem.TOGGLE_SPACE_FLAGS,
    );

    /**
     * Изменять приоритет пространства документов.
     */
    public static readonly UPDATE_SPACE_PRIORITY = new PermissionItem(
        "UPDATE_SPACE_PRIORITY",
        PermissionItem.EDIT_CLIENT_SPACE,
    );

    /**
     * Приглашать пользователей в в папки.
     */
    public static readonly INVITE_USERS_TO_SPACE = new PermissionItem(
        "INVITE_USERS_TO_SPACE",
        PermissionItem.EDIT_CLIENT_SPACE,
    );

    /**
     * Видеть доверенные почты, с которых могут приходить задачи на распознавание в эту папку.
     */
    public static readonly SEE_TRUSTED_EMAILS = new PermissionItem(
        "SEE_TRUSTED_EMAILS",
        PermissionItem.CLIENT_CONTROL,
    );

    /**
     * Редактировать почты, с которых могут приходить задачи на распознавание в эту папку.
     */
    public static readonly EDIT_TRUSTED_EMAILS = new PermissionItem(
        "EDIT_TRUSTED_EMAILS",
        PermissionItem.SEE_TRUSTED_EMAILS,
    );

    /**
     * Перемещать документы из одной папки в другую.
     */
    public static readonly MOVE_DOCUMENTS = new PermissionItem(
        "MOVE_DOCUMENTS",
        PermissionItem.CLIENT_CONTROL,
    );

    //endregion
    //region Client

    /**
     * Права пользователей клиента.
     */
    public static readonly CLIENT = new PermissionItem("CLIENT");

    /**
     * Право на создание нового пространство документов.
     */
    public static readonly CREATE_SPACE = new PermissionItem("CREATE_SPACE", PermissionItem.CLIENT);

    /**
     * Право изменять пространства документов.
     */
    public static readonly EDIT_SPACE = new PermissionItem("EDIT_SPACE", PermissionItem.CLIENT);

    //endregion
    //region User

    /**
     * Права обычных пользователей.
     */
    public static readonly USER = new PermissionItem("USER");

    /**
     * Права пользователя в пространстве документов.
     */
    public static readonly SPACE = new PermissionItem("SPACE", PermissionItem.USER);

    /**
     * Право на изменение интеграции.
     */
    public static readonly INTEGRATION = new PermissionItem("INTEGRATION", PermissionItem.SPACE);

    /**
     * Право на полное удаление задач распознавания.
     */
    public static readonly RECOGNITION_TASK_TOTAL_DELETE = new PermissionItem(
        "RECOGNITION_TASK_TOTAL_DELETE",
        PermissionItem.SPACE
    );

    /**
     * Право пригласить любого другого пользователя в пространство документов.
     *
     * Пользователь может еще не существовать в системе.
     */
    public static readonly INVITE_USERS = new PermissionItem("INVITE_USERS", PermissionItem.SPACE);


    /**
     * <p>Право добавлять доверенные почты, с которых можно принимать задачи на распознавание на эту папку.</p>
     */
    public static readonly MANAGE_TRUSTED_EMAILS = new PermissionItem("MANAGE_TRUSTED_EMAILS", PermissionItem.SPACE);

    /**
     * Право управлять уведомлениями пользователей на почту о состоянии задачи на распознавание.
     */
    public static readonly MANAGE_EMAIL_NOTIFICATIONS = new PermissionItem(
        "MANAGE_EMAIL_NOTIFICATIONS",
        PermissionItem.SPACE,
    );

    //endregion

    //endregion
    //region Fields

    /**
     * Ключ права.
     */
    readonly value: string;

    /**
     * Родительское право.
     */
    readonly parent: PermissionItem;

    //endregion
    //region Ctor

    private constructor(value: string, parent: PermissionItem = null) {
        this.value = value;
        this.parent = parent;
    }

    //endregion
    //region Getters and Setters

    /**
     * I18n-ключ названия права.
     */
    get nameKey(): string {

        return "PermissionItem." + this.value;
    }

    //endregion
}
