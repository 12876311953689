var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Router } from "@angular/router";
import { Actions } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { select } from "@ngrx/store";
import * as LogRocket from "logrocket";
import { of } from "rxjs";
import { takeWhile } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { UserService } from "src/app/common/services/user.service";
import { broadcastMessageDlgActions } from "src/app/root/store/actions/broadcast-message-dlg.action";
import { environment } from "src/environments/environment";
import { FirstUploadDlgService } from "../../services/first-upload-dlg.service";
import { CurrentUserActionType } from "../actions";
import { CurrentUserLoadFailAction } from "../actions";
import { CurrentUserLoadSuccessAction } from "../actions";
import { CurrentUserLogoutFailAction } from "../actions";
import { CurrentUserLogoutSuccessAction } from "../actions";
import { RouterGoAction } from "../actions";
import { CurrentUserRecognitionTaskUploadedAction } from "../actions";
import { UploadToRecognizeActionType } from "../actions";
import { RecognitionTaskCreatedDlgOpenAction } from "../actions/recognition-task-created-dlg.action";
import { uploadToRecognizeSelector } from "../reducers";
import { currentUserInfoSelector } from "../selectors";
/**
 * Side-эффекты на события, связанные с текущим пользователем.
 */
var CurrentUserEffects = /** @class */ (function () {
    //endregion
    //region Ctor
    function CurrentUserEffects(_userService, _actions$, _document, _store, _router, firstUploadDlgService) {
        var _this = this;
        this._userService = _userService;
        this._actions$ = _actions$;
        this._document = _document;
        this._store = _store;
        this._router = _router;
        //endregion
        //region Effects
        /**
         * Обработка события требования загрузки данных текущего пользователя.
         *
         * Если аутентифицированного пользователя нет, то будет редирект на форму логина.
         */
        this.loadCurrentUserInfo$ = this._actions$
            .pipe(ofType(CurrentUserActionType.LOAD), switchMap(function () {
            return _this._userService.getCurrentUser()
                .pipe(map(function (currentUserInfo) { return new CurrentUserLoadSuccessAction(currentUserInfo); }), catchError(function (response) { return of(new CurrentUserLoadFailAction(response)); }));
        }));
        /**
         * Обработка события удачной загрузки данных о пользователе.
         *
         * Если мы находимся на странице welcome, то необходимо совершить переход на главную страницу.
         */
        this.successCurrentUserInfo$ = this._actions$
            .pipe(ofType(CurrentUserActionType.LOAD_SUCCESS), 
        // TODO Хардкод URL'а.
        filter(function () { return _this._router.url === "/welcome"; }), tap(function () { return _this._store.dispatch(new RouterGoAction({ path: [""] })); }));
        /**
         * Обработка событий удачной загрузки данных о пользователе, когда пользователю есть сообщения.
         *
         * Открывает диалог сообщений для пользователя.
         */
        this.openBroadcastMessageDialog$ = this._actions$
            .pipe(ofType(CurrentUserActionType.LOAD_SUCCESS), map(function (action) { return action.payload; }), filter(function (payload) { return !!payload; }), map(function (payload) { return payload.broadcastMessages; }), filter(function (messages) { return !!messages && !!messages.length; }), tap(function (value) { return _this._store.dispatch(broadcastMessageDlgActions.open({ value: value })); }));
        /**
         * Обработка событий удачной загрузки данных о пользователе.
         *
         * Открывает новую сессию в логрокете и идентифицирует пользователя.
         */
        this.identifyForLogrocket$ = this._actions$
            .pipe(ofType(CurrentUserActionType.LOAD_SUCCESS), map(function (action) { return action.payload; }), filter(function (payload) { return !!payload; }), tap(function (value) {
            LogRocket.identify(value.id, {
                name: _this._userService.getUserName(value),
                email: value.email,
            });
        }));
        /**
         * Обработка события требования выхода текущего пользователя из системы.
         */
        this.currentUserLogout$ = this._actions$
            .pipe(ofType(CurrentUserActionType.LOGOUT), switchMap(function () {
            return _this._userService.logout()
                .pipe(map(function () { return new CurrentUserLogoutSuccessAction(); }), catchError(function (response) { return of(new CurrentUserLogoutFailAction(response)); }));
        }));
        /**
         * Обработка успешного выхода текущего пользователя из системы.
         *
         * Завершает текущую сессию логрокета и начинает новую.
         */
        this.currentUserLogoutSuccess$ = this._actions$
            .pipe(ofType(CurrentUserActionType.LOGOUT_SUCCESS), tap(function () {
            // Если был вход под пользователем, то переходим на корневой путь.
            if (_this._currentUser.impersonated) {
                _this._document.location.href = window.location.origin;
            }
            // Если нет, то переходим на лендинг.
            else {
                _this._document.location.href = environment.enteraLandingUrl;
            }
        }));
        /**
         * Обработка успешной загрузки задачи на распознавание.
         *
         * Если для текущего пользователя загруженная задача на распознавание - первая, учитывая все его пространства
         * документов, то отобразить диалог с подсказкой и пометить, что у этого пользователя теперь есть задачи на
         * распознавание.
         */
        this.createCurrentUserRecognitionTaskUploadedAction$ = this._actions$
            .pipe(ofType(UploadToRecognizeActionType.UPLOAD_SUCCESS), filter(function () { return !_this._currentUser.hasRecognitionTasks; }), tap(function () { return _this._firstUploadDlgService.open(); }), map(function () { return new CurrentUserRecognitionTaskUploadedAction(); }));
        /**
         * Обработка успешной загрузки задачи на распознавание.
         *
         * Создаёт событие, требующее открытия диалога после создания задачи на распознавание.
         */
        this.createRecognitionTaskCreatedDlgOpenAction$ = this._actions$
            .pipe(ofType(UploadToRecognizeActionType.UPLOAD_SUCCESS), filter(function () { return _this._currentUser.hasRecognitionTasks; }), filter(function () { return _this._currentUser.settings.showRecognitionTaskCreatedDialog; }), filter(function () { return _this._uploadDialogFlag; }), map(function (action) {
            return new RecognitionTaskCreatedDlgOpenAction(action.payload[0]);
        }));
        this.phoneConfirmCallback$ = this._actions$
            .pipe(ofType(CurrentUserActionType.PHONE_CONFIRM_SUCCESS), map(function (action) { return action.payload.callback; }), filter(Boolean), tap(function (callback) { return callback(); }));
        /**
         * Обработка события удачного создания нового пространства документов.
         */
        this.goToCreatedSpace$ = this._actions$.pipe(ofType(CurrentUserActionType.UPDATE_AND_REDIRECT), map(function (action) {
            return new RouterGoAction({ path: ["/", "spaces", action.payload.id, "documents"] });
        }));
        // Подписываемся на данные текущего пользователя.
        this._store
            .pipe(select(currentUserInfoSelector))
            .subscribe(function (currentUser) { return _this._currentUser = currentUser; });
        this._store
            .pipe(select(uploadToRecognizeSelector), takeWhile(function (state) { return !state.loaded; }))
            .subscribe(function (uploadState) {
            return _this._uploadDialogFlag = uploadState.openPostUploadDialog;
        });
        this._firstUploadDlgService = firstUploadDlgService;
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CurrentUserEffects.prototype, "loadCurrentUserInfo$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], CurrentUserEffects.prototype, "successCurrentUserInfo$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], CurrentUserEffects.prototype, "openBroadcastMessageDialog$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], CurrentUserEffects.prototype, "identifyForLogrocket$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CurrentUserEffects.prototype, "currentUserLogout$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], CurrentUserEffects.prototype, "currentUserLogoutSuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CurrentUserEffects.prototype, "createCurrentUserRecognitionTaskUploadedAction$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CurrentUserEffects.prototype, "createRecognitionTaskCreatedDlgOpenAction$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], CurrentUserEffects.prototype, "phoneConfirmCallback$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CurrentUserEffects.prototype, "goToCreatedSpace$", void 0);
    return CurrentUserEffects;
}());
export { CurrentUserEffects };
