import { SPACE } from "@angular/cdk/keycodes";
import { COMMA } from "@angular/cdk/keycodes";
import { ENTER } from "@angular/cdk/keycodes";
import { OnDestroy } from "@angular/core";
import { ViewChild } from "@angular/core";
import { ElementRef } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { Output } from "@angular/core";
import { Input } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { FormControl } from "@angular/forms";
import { ValidationErrors } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material";
import { MatChipInputEvent } from "@angular/material";
import { Subject } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { filter } from "rxjs/operators";
import { takeUntil } from "rxjs/operators";
import { map } from "rxjs/operators";
import { startWith } from "rxjs/operators";
import { ApiResponse } from "src/app/common/models/api-response";
import { Constants } from "src/app/common/models/constants.model";
import { Space } from "src/app/common/models/space";
import { SpaceIdsAndEmails } from "src/app/common/models/space-ids-and-emails.model";

/**
 * Компонент формы диалога для ввода списка email-ов и выбора списка пространств документов.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "users-and-spaces-input",
    templateUrl: "./users-and-spaces-input.component.html",
    styleUrls: ["./users-and-spaces-input.component.scss"],
})
export class UsersAndSpacesInputComponent implements OnDestroy {
    //region Inputs

    /**
     * Пространства документов, которые доступны пользователю.
     */
    @Input()
    set spaces(spaces: Space[]) {

        this._spaces = spaces.filter(space => !space.deleted);
        this.filteredSpaces.next(spaces.filter(space => !space.deleted));
    }

    /**
     * Предварительно выбранные пространства документов, которые доступны пользователю.
     */
    @Input()
    set preSelectedSpaces(spaces: Space[]) {

        if (spaces) {

            this.selectedSpaces = spaces.filter(preSpace => this._spaces.find(space => space.id === preSpace.id)) || [];
        }
    }

    /**
     * Запрос на сервер выполняется?
     */
    @Input()
    readonly requestLoading: boolean;

    /**
     * Ошибка, которая произошла при выполнении запроса на сервер.
     */
    @Input()
    readonly requestError: ApiResponse;

    /**
     * Запрос на сервер успешно выполнен?
     */
    @Input()
    readonly requestSuccess: boolean;

    /**
     * Ключ i18n заголовка диалога.
     */
    @Input()
    readonly headerKey: string;

    /**
     * i18n-ключ кнопки подтверждения (применения).
     */
    @Input()
    readonly okBtnKey: string;

    /**
     * i18n-ключ описания ошибки.
     */
    @Input()
    readonly errorDescriptionKey: string;

    /**
     * i18n-ключ описания успешного запроса.
     */
    @Input()
    readonly successDescriptionKey: string;

    //endregion
    //region Output

    /**
     * Требование применения введенных данных.
     */
    @Output()
    readonly confirmData: EventEmitter<SpaceIdsAndEmails>;

    /**
     * Требование закрытия текущего диалога.
     */
    @Output()
    readonly closeDialog: EventEmitter<void>;

    //endregion
    //region Fields

    /**
     * Поле ввода имени пространства документов.
     */
    @ViewChild("spaceInput")
    readonly spaceInput: ElementRef<HTMLInputElement>;

    /**
     * Контрол списка email'ов пользователей.
     */
    readonly emailListControl = new FormControl(
        [],
        [(control: AbstractControl) => this._emailListValidator(control)]
    );

    /**
     * Список выбранных пространств документов.
     */
    selectedSpaces: Space[];

    /**
     * Отфильтрованные по введенной части имени пространства документов в выпадающем списке для подсказки.
     */
    readonly filteredSpaces: BehaviorSubject<Space[]>;

    /**
     * Список кодов клавиш, при нажатии на которые будет создаваться новый chip на основе введенного текста.
     */
    readonly chipsSeparatorKeysCodes: number[];

    /**
     * Поле ввода имени пространства документов.
     */
    readonly spaceCtrl: FormControl;

    /**
     * Поле чекбокса выбора всех папок.
     */
    readonly selectAllSpacesCtrl: FormControl = new FormControl(false);

    /**
     * Пространства документов, которые доступны пользователю.
     */
    private _spaces: Space[];

    /**
     * Объект глобальной отписки.
     */
    private _globalUnsubscribe$: Subject<void> = new Subject();

    //endregion
    //region Ctor

    /**
     * Конструктор компонента формы диалога для ввода списка email-ов и выбора списка пространств документов.
     */
    constructor() {

        this._spaces = [];
        this.confirmData = new EventEmitter<SpaceIdsAndEmails>();
        this.closeDialog = new EventEmitter<void>();
        this.selectedSpaces = [];
        this.chipsSeparatorKeysCodes = [ENTER, COMMA, SPACE];
        this.spaceCtrl = new FormControl();
        this.filteredSpaces = new BehaviorSubject<Space[]>([]);

        this.spaceCtrl.valueChanges
            .pipe(
                takeUntil(this._globalUnsubscribe$),
                startWith(""),
                map((spaceName: string): Space[] => this._filter(spaceName)),
            )
            .subscribe((spaces: Space[]): void => this.filteredSpaces.next(spaces));

        this.selectAllSpacesCtrl.valueChanges
            .pipe(
                takeUntil(this._globalUnsubscribe$),
                filter(Boolean),
            )
            .subscribe((): void => {

                if (this.spaceInput) {

                    this.spaceInput.nativeElement.value = "";
                }

                this.spaceCtrl.setValue(null);
                this.selectedSpaces = [];
            });
    }

    //endregion
    //region Hooks

    /**
     * Выполняет логику при уничтожении компоненты.
     */
    ngOnDestroy(): void {

        this._globalUnsubscribe$.complete();
    }

    //endregion
    //region Getters

    /**
     * Выставлен ли чекбокс выбора всех папок?
     */
    get isAllSpacesSelect(): boolean {

        return this.selectAllSpacesCtrl.value;
    }

    //endregion
    //region Public

    /**
     * Email корректен?
     *
     * @param email Проверяемый на корректность email.
     *
     * @return Да/Нет.
     */
    isEmailValid(email: string): boolean {

        return Constants.EMAIL_REGEXP.test(email);
    }

    /**
     * Введенные данные пользователем корректны?
     *
     * @return Да/Нет.
     */
    areDataValid(): boolean {

        const emailsValid: boolean = this.emailListControl.value
            .every((email: string): boolean => this.isEmailValid(email));
        const emailExists: boolean = (this.emailListControl.value.length > 0);
        const spaceExists: boolean = !!this.selectedSpaces.length || (this.isAllSpacesSelect && !!this._spaces.length);

        return (emailExists && spaceExists && emailsValid);
    }

    //endregion
    //region Event

    /**
     * Обработчик нажатия на кнопку для отправки запроса.
     */
    applyBtnClickHandler(): void {

        const selectedSpaces = this.isAllSpacesSelect ? this._spaces : this.selectedSpaces;
        const spaceIdsAndEmails: SpaceIdsAndEmails = {
            emails: this.emailListControl.value,
            spaceIds: selectedSpaces.map((space: Space): string => space.id),
        };

        this.confirmData.emit(spaceIdsAndEmails);
    }

    /**
     * Обработчик нажатия на кнопку закрытия текущего диалога.
     */
    closeDialogButtonHandler(): void {

        this.closeDialog.emit();
    }

    /**
     * Обработка выбора пространства документов из подсказывающего выпадающего списка.
     *
     * @param event Событие для обработки.
     */
    selectionHandler(event: MatAutocompleteSelectedEvent): void {

        const selectedSpaceName: string = event.option.value;

        const removableSpaceIndex: number = this._spaces.findIndex(
            (space: Space): boolean =>
                space.name === selectedSpaceName
                && !this.selectedSpaces.some(spaceInSelection => spaceInSelection.id === space.id)
        );

        this.selectedSpaces = [
            ...this.selectedSpaces,
            this._spaces[removableSpaceIndex],
        ];

        this.filteredSpaces.next(this._filter());

        this.spaceInput.nativeElement.value = "";
        this.spaceCtrl.setValue(null);
    }

    /**
     * Добавляет email в список в случае, если в списке еще нет такого email'а, и очищает строку ввода.
     *
     * @param event Событие для обработки.
     */
    addEmailHandler(event: MatChipInputEvent): void {

        const input = event.input;
        const value = event.value;
        this.emailListControl.markAsTouched();

        if ((value || '').trim() && this.emailListControl.value.indexOf(value.trim()) === -1) {

            this.emailListControl.patchValue([...this.emailListControl.value, value.trim()]);
        }

        if (input) {

            input.value = '';
        }
    }

    /**
     * Обрабатывает текст вставки в поле ввода email так, что все подстроки, которые соответствуют валидному email,
     * попадут в chip-list. Использует для выделения подстрок паттерн валидного email.
     *
     * @param event Событие для обработки.
     */
    emailPasteHandler(event: ClipboardEvent): void {

        const value: string = event.clipboardData.getData('text/plain');
        const input: HTMLInputElement = event.currentTarget as HTMLInputElement;
        let regEx: RegExp = new RegExp(Constants.MULTI_EMAIL_REGEXP.source, 'g');
        let match: RegExpExecArray = regEx.exec(value);

        while (match != null) {

            this.emailListControl.patchValue([...this.emailListControl.value, match[0]]);
            match = regEx.exec(value);
        }
        event.preventDefault();
        event.stopPropagation();
    }

    /**
     * Удаляет email из списка email'ов.
     *
     * @param email Удаляемый email.
     */
    removeEmailHandler(email: string): void {

        const index = this.emailListControl.value.indexOf(email);

        if (index >= 0) {

            const emails = this.emailListControl.value;
            emails.splice(index, 1);
            this.emailListControl.patchValue(emails);
        }
    }

    /**
     * Добавляет пространство документов и очищает строку ввода.
     *
     * @param event Событие для обработки.
     */
    addSpaceHandler(event: MatChipInputEvent): void {

        if ((event.value || "").trim()) {

            const addedSpaceIndex: number = this._spaces.findIndex(
                (space: Space): boolean => space.name === event.value
            );

            const selected: boolean = this.selectedSpaces.some((space: Space): boolean => space.name === event.value);

            if (!selected && addedSpaceIndex >= 0) {

                this.selectedSpaces = [
                    ...this.selectedSpaces,
                    this._spaces[addedSpaceIndex]
                ];

                this.filteredSpaces.next(this._filter());
            }
        }

        if (this.spaceInput) {

            this.spaceInput.nativeElement.value = "";
        }

        this.spaceCtrl.setValue(null);
    }

    /**
     * Удаляет пространство документов из списка.
     *
     * @param space Удаляемое пространство документов.
     */
    removeSpaceHandler(space: Space): void {

        const removableSpaceIndex: number = this.selectedSpaces.findIndex(
            (selectedSpace: Space): boolean => space.name === selectedSpace.name
        );

        this.selectedSpaces.splice(removableSpaceIndex, 1);
        this.filteredSpaces.next(this._filter());
    }

    //endregion
    //region Private

    /**
     * Фильтрует и возвращает пространства документов по введенной части имени пространства документов.
     *
     * @param spaceName Введенная пользователем часть имени пространства документов.
     *
     * @return Пространства документов по введенной части имени пространства документов.
     */
    private _filter(spaceName: string = ""): Space[] {

        let filterValue: string = "";
        if (spaceName) {

            filterValue = spaceName.toLowerCase();
        }

        const result = this._spaces
            .filter((space: Space): boolean => !space.deleted)
            .filter((space: Space): boolean => this.selectedSpaces.indexOf(space) === -1)
            .filter((space: Space): boolean => space.name.toLowerCase().indexOf(filterValue) !== -1);
        return result;
    }

    /**
     * Перебирает список email'ов и возвращает ошибку валидации с первым некорректным email-ом. Если все email'ы
     * корректны - возвращает null.
     *
     * @param control Контрол для валидации.
     *
     * @return ValidationErrors Ошибка валидации.
     */
    private _emailListValidator(control: AbstractControl): ValidationErrors {

        if (control.value === undefined) {

            return null;
        }

        for (let email of control.value) {

            if (!Constants.EMAIL_REGEXP.test(email)) {

                return {incorrectEmail: email};
            }
        }

        return null;
    }

    //endregion
}
