var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { HttpEventType } from "@angular/common/http";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Gtag } from "angular-gtag";
import { spaceSelector } from "src/app/root/store/selectors/index";
import { of } from "rxjs";
import { withLatestFrom } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { last } from "rxjs/operators";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { RecognitionTaskService } from "src/app/common/services";
import { FileHashUtils } from "src/app/common/utils/file-hash.utils";
import { uploadToRecognizeSelector } from "src/app/root/store/reducers/index";
import { SelectedSpaceForMobileSelectors } from "src/app/root/store/selectors/selected-space-for-mobile.selector";
import { UploadTaskToRecognizeAction } from "../../actions";
import { UploadTaskToRecognizeFailAction } from "../../actions";
import { UploadTaskToRecognizeProgressAction } from "../../actions";
import { UploadTaskToRecognizeSuccessAction } from "../../actions";
import { MarkFilesAsDuplicatesAction } from "../../actions";
import { AddFilesToTaskToRecognizeAction } from "../../actions";
import { UploadToRecognizeActionType } from "../../actions";
import { FileExistenceCheckFailedAction } from "../../actions";
/**
 * Side-эффекты на события, связанные с отправкой документов на распознавание.
 */
var UploadToRecognizeEffects = /** @class */ (function () {
    //region Ctor
    function UploadToRecognizeEffects(actions$, store, service, _gtag) {
        var _this = this;
        this.actions$ = actions$;
        this.store = store;
        this.service = service;
        this._gtag = _gtag;
        //endregion
        //region Public
        /**
         * Эффект нажатия кнопки загрузить в диалоге загрузки документов на распознавание.
         * Выполняется загрузка документа на распознавание - создание задачи на распознавание.
         */
        this.upload$ = this.actions$.pipe(ofType(UploadToRecognizeActionType.UPLOAD), tap(function () { return _this._gtag.event("click_recognize_documents"); }), 
        // Загружаем файл
        map(function (action) { return action.payload; }), switchMap(function (payload) {
            return _this.service.createTasks(payload.filesWithMetaData.map(function (fileWithData) { return fileWithData.file; }), payload.spaceId, payload.forceOcrRecognition, payload.forceProcessing, payload.forceQueue, payload.comment, payload.mobile, payload.bankStatement)
                .pipe(
            // Прогресс.
            tap(function (event) {
                if (event.type === HttpEventType.UploadProgress) {
                    var progress = Math.floor(event.loaded * 100 / event.total);
                    _this.store.dispatch(new UploadTaskToRecognizeProgressAction(progress));
                }
            }), 
            // Последнее событие(удача/ошибка).
            last(), 
            // Обработка ответов.
            map(function (last) {
                if (isRecognitionTaskApiResponse(last.body)) {
                    return new UploadTaskToRecognizeSuccessAction([last.body.recognitionTask]);
                }
                else {
                    return new UploadTaskToRecognizeSuccessAction(last.body.recognitionTasks);
                }
            }), 
            // Обработка ошибки.
            catchError(function (response) { return of(new UploadTaskToRecognizeFailAction(response)); }));
        }));
        /**
         * Эффект нажатия кнопки загрузить в диалоге загрузки документов на распознавание через мобильный интерфейс.
         * Выполняется загрузка документа на распознавание - создание задачи на распознавание.
         */
        this.mobileUpload$ = this.actions$.pipe(ofType(UploadToRecognizeActionType.MOBILE_UPLOAD), withLatestFrom(this.store.select(uploadToRecognizeSelector)), map(function (_a) {
            var _ = _a[0], state = _a[1];
            return state;
        }), withLatestFrom(this.store.select(SelectedSpaceForMobileSelectors.space)), map(function (_a) {
            var payload = _a[0], space = _a[1];
            return (__assign({}, payload, { spaceId: space.id, openPostUploadDialog: false, mobile: true }));
        }), map(function (state) { return new UploadTaskToRecognizeAction(state); }));
        /**
         * Эффект успешной отправки файлов на распознавание.
         */
        this.uploadSuccess$ = this.actions$.pipe(ofType(UploadToRecognizeActionType.UPLOAD_SUCCESS), tap(function () { return _this._gtag.event("send_to_recognition_successfully"); }));
        /**
         * Генерирует хеши для файлов.
         */
        this.generateFileHashes$ = this.actions$.pipe(ofType(UploadToRecognizeActionType.GENERATE_HASH_FOR_FILES), switchMap(function (action) { return FileHashUtils.filesToSHA256Hash(action.payload)
            .pipe(map(function (filesWithMetaData) { return new AddFilesToTaskToRecognizeAction(filesWithMetaData); })); }));
        /**
         * Осуществляет запрос проверки существования файлов.
         */
        this.checkFilesExistence$ = this.actions$.pipe(ofType(UploadToRecognizeActionType.ADD_FILES), withLatestFrom(this.store.select(spaceSelector)), switchMap(function (_a) {
            var action = _a[0], space = _a[1];
            return _this.service.checkFilesExistence(space, action.payload.filter(function (file) { return !!file.file.size; }))
                .pipe(map(function (response) { return new MarkFilesAsDuplicatesAction(response.fileMetaDataList); }), catchError(function (error) { return of(new FileExistenceCheckFailedAction(error)); }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UploadToRecognizeEffects.prototype, "upload$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UploadToRecognizeEffects.prototype, "mobileUpload$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], UploadToRecognizeEffects.prototype, "uploadSuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UploadToRecognizeEffects.prototype, "generateFileHashes$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UploadToRecognizeEffects.prototype, "checkFilesExistence$", void 0);
    return UploadToRecognizeEffects;
}());
export { UploadToRecognizeEffects };
function isRecognitionTaskApiResponse(val) {
    return !!val && !!val["recognitionTask"];
}
