var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { requestState } from "src/app/common/models/request-state";
import { MatchingCompaniesActionType } from "src/app/spaces/modules/document-matching/store/actions/matching-companies.action";
/**
 * Начальное состояние сопоставления компаний.
 */
var initialState = {
    customersRequest: requestState.initial(),
    suppliersRequest: requestState.initial(),
};
/**
 * Обработчик событий, связанных с сопоставлением компаний.
 *
 * @param state Текущее состояние сопоставления компаний.
 * @param action Произошедшее событие.
 */
export function matchingCompaniesReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var _a, _b, _c;
    var result = state;
    switch (action.type) {
        case MatchingCompaniesActionType.GET: {
            result = __assign({}, state, (_a = {}, _a[action.companyType + "Request"] = requestState.pending(), _a));
            break;
        }
        case MatchingCompaniesActionType.SUCCESS: {
            result = __assign({}, state, (_b = {}, _b[action.companyType + "Request"] = requestState.success(action.companies), _b));
            break;
        }
        case MatchingCompaniesActionType.FAIL: {
            result = __assign({}, state, (_c = {}, _c[action.companyType + "Request"] = requestState.fail(action.error), _c));
            break;
        }
    }
    return result;
}
