import { HttpClient } from "@angular/common/http";
import { throwError } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
/**
 * Сервис для работы с валютами.
 */
var CurrencyService = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор сервиса для работы с валютами.
     *
     * @param httpClient HTTP клиент.
     */
    function CurrencyService(httpClient) {
        this._httpClient = httpClient;
    }
    //endregion
    //region Public
    /**
     * Запрос на получение списка валют.
     *
     * @return Список валют.
     */
    CurrencyService.prototype.getCurrencies = function () {
        return this._httpClient.get("/api/v1/currencies")
            .pipe(map(function (result) { return result.currencies; }), catchError(function (response) { return throwError(response.error); }));
    };
    CurrencyService.ngInjectableDef = i0.defineInjectable({ factory: function CurrencyService_Factory() { return new CurrencyService(i0.inject(i1.HttpClient)); }, token: CurrencyService, providedIn: "root" });
    return CurrencyService;
}());
export { CurrencyService };
