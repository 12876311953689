import { ActionReducerMap } from "@ngrx/store";
import { createFeatureSelector } from "@ngrx/store";
import { recognitionTaskSourcesReducer } from "src/app/spaces/modules/documents-registry/store/reducers/recognition-task-sources.reducers";
import { sendToExternalSystemReducer } from "src/app/spaces/modules/documents-registry/store/reducers/send-to-external-system.reducer";
import { sendToMatchingReducer } from "src/app/spaces/modules/documents-registry/store/reducers/send-to-matching.reducer";
import { RecognitionTaskSourcesState } from "src/app/spaces/modules/documents-registry/store/states/recognition-task-sources.state";
import { SendToExternalSystemState } from "src/app/spaces/modules/documents-registry/store/states/send-to-external-system.state";
import { SendToMatchingState } from "src/app/spaces/modules/documents-registry/store/states/send-to-matching.state";
import { documentCreatorsReducer } from "./document-creators.reducers";
import { DocumentCreatorsState } from "./document-creators.reducers";
import { documentStatesReducer } from "./document-states.reducers";
import { DocumentStatesState } from "./document-states.reducers";
import { DocumentsArchiveDlgState } from "./documents-archive";
import { documentsArchiveDlgReducer } from "./documents-archive/documents-archive-dlg.reducer";
import { documentsCountReducer } from "./documents-count.reducers";
import { DocumentsCountState } from "./documents-count.reducers";
import { documentsRegistryExportReducer } from "./documents-registry-file";
import { DocumentsRegistryExportState } from "./documents-registry-file";
import { documentsReducer } from "./documents.reducers";
import { DocumentsState } from "./documents.reducers";

/**
 * Состояние страницы реестра документов, состоящее из состояния его частей.
 */
export interface DocumentsRegistryState {

    /**
     * Состояние источников задач на распознавание.
     */
    recognitionTaskSourcesState: RecognitionTaskSourcesState;

    /**
     * Состояние статусов документов.
     */
    documentStatesState: DocumentStatesState;

    /**
     * Состояние создателей документов.
     */
    documentCreatorsState: DocumentCreatorsState;

    /**
     * Состояние документов.
     */
    documentsState: DocumentsState;

    /**
     * Состояние кол-ва документов.
     */
    documentsCountState: DocumentsCountState;

    /**
     * Состояние диалога создания архива.
     */
    documentsArchiveDlgState: DocumentsArchiveDlgState;

    /**
     * Состояние выгрузки реестра документов в Excel-файл.
     */
    documentsRegistryExport: DocumentsRegistryExportState;

    /**
     * Состояние отправки документов во внешнюю систему.
     */
    sendToExternalSystemState: SendToExternalSystemState;

    /**
     * Состояние отправки документов на сопоставление.
     */
    sendToMatchingState: SendToMatchingState;
}

/**
 * Обработчики каждой части состояния реестра документов.
 */
export const reducers: ActionReducerMap<DocumentsRegistryState> = {

    /**
     * Обработчик состояния источников задач на распознавание.
     */
    recognitionTaskSourcesState: recognitionTaskSourcesReducer,

    /**
     * Обработчик состояния статусов документов.
     */
    documentStatesState: documentStatesReducer,

    /**
     * Обработчик состояния создателей документов.
     */
    documentCreatorsState: documentCreatorsReducer,

    /**
     * Обработчик состояния документов.
     */
    documentsState: documentsReducer,

    /**
     * Обработчик состояния кол-ва документов.
     */
    documentsCountState: documentsCountReducer,

    /**
     * Обработчик состояния диалога создания архива.
     */
    documentsArchiveDlgState: documentsArchiveDlgReducer,

    /**
     * Обработчик состояния выгрузки реестра документов в Excel-файл.
     */
    documentsRegistryExport: documentsRegistryExportReducer,

    /**
     * Обработчик состояния отправки документов во внешнюю систему.
     */
    sendToExternalSystemState: sendToExternalSystemReducer,

    /**
     * Обработчик состояния отправки документов на сопоставление.
     */
    sendToMatchingState: sendToMatchingReducer,
};

/**
 * Селектор состояния реестра документов.
 */
export const documentsRegistryStateSelector = createFeatureSelector<DocumentsRegistryState>("documentsRegistry");
