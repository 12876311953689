import {ChangeDetectionStrategy, Component} from '@angular/core';

/**
 * Компонент страницы с сообщением о доступе к несуществующей странице.
 */
@Component({
    selector: 'not-found',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['not-found.component.scss'],
    template: `
        <div>
            TODO: Not found page
        </div>
    `
})
export class NotFoundComponent {

    constructor() { }
}
