import { EventEmitter } from "@angular/core";
import { Output } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { InfoMenuItem } from "src/app/common/models/info-menu-item";
import { environment } from "src/environments/environment";

/**
 * Компонент выпадающего меню "Есть вопросы".
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "have-questions-menu",
    styleUrls: ["have-questions-menu.component.scss"],
    templateUrl: "have-questions-menu.component.html"
})
export class HaveQuestionsMenuComponent {
    //region Outputs

    /**
     * Исходящее событие - открыть диалог обратного звонка.
     */
    @Output()
    readonly callBack = new EventEmitter<void>();

    /**
     * Исходящее событие - открыть диалог обратного звонка.
     */
    @Output()
    readonly knowledgeBase = new EventEmitter<void>();

    /**
     * Исходящее событие - открыть диалог инструкции как начать пользоваться Entera.
     */
    @Output()
    readonly howToStart = new EventEmitter<void>();

    //endregion
    //region Fields

    /**
     * Элементы меню выпадашки.
     */
    readonly menuItems: InfoMenuItem[];

    /**
     * ID элементов меню.
     */
    private readonly _menuItems = {

        /**
         * Купить.
         */
        CALLBACK: "callBack",

        /**
         * База знаний.
         */
        KNOWLEDGE_BASE: "knowledgeBase",

        /**
         * Инструкция.
         */
        HOW_TO_START: "howToStart"
    };

    //endregion
    //region Ctor

    /**
     * Конструктор выпадающего меню "Есть вопросы".
     */
    constructor() {

        // Конфигурация элементов меню.
        this.menuItems = [
            {
                id: this._menuItems.CALLBACK,
                icon: "call",
                messageKey: "common.haveQuestions.callback",
                green: true
            },
            {
                id: this._menuItems.KNOWLEDGE_BASE,
                icon: "book",
                messageKey: "common.haveQuestions.knowledgeBase",
                green: true
            },
        ];

        if (environment.howToStart) {

            this.menuItems.push({
                id: this._menuItems.HOW_TO_START,
                icon: "rocket_launch",
                messageKey: "header.howToStart",
                green: true
            });
        }
    }

    //endregion
    //region Events

    /**
     * Обработчик клика по элементу меню.
     */
    menuItemClickHandler(menuItemId: string) {

        switch (menuItemId) {

            case this._menuItems.CALLBACK: {

                this.callBack.emit();
                break;
            }

            case this._menuItems.KNOWLEDGE_BASE: {

                this.knowledgeBase.emit();
                break;
            }

            case this._menuItems.HOW_TO_START: {

                this.howToStart.emit();
                break;
            }
        }
    }

    //endregion
}
