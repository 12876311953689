import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Moment } from 'moment';
import { DateAdapter } from '@angular/material';

import { RootState } from 'src/app/root/store/reducers';
import { currentUserLangSelector } from 'src/app/root/store/selectors/current-user.selector';

import { I18nMessages } from '../models/i18n-messages';

@Injectable({
    providedIn: 'root'
})
export class LangService {
    //region Ctor

    constructor() { }

    //endregion
    //region Public

    /**
     * Выполняет загрузку заданных сообщений в сервис для работы с i18n-сообщениями.
     * 
     * @param translateService Сервис для работы с i18n-сообщениями.
     * @param langs Сообщения на разных языках.
     */
    loadTranslations(translateService: TranslateService, langs: I18nMessages[]) {

        for (let lang of langs) {

            translateService.setTranslation(lang.lang, lang.messages, true);
        }
    }

    /**
     * Выполняет подписку на событие изменения языка для Datepicker.
     * 
     * @param store Состояние приложения.
     * @param dateAdapter Datepicker адаптер для работы с датой.
     */
    subscribeOnLangChangeForDatepicker(
        store: Store<RootState>,
        dateAdapter: DateAdapter<Moment>
    ): Subscription {

        return store
            .pipe(
                select(currentUserLangSelector),
                filter(Boolean)
            )
            .subscribe(lang => dateAdapter.setLocale(lang));
    }

    //endregion
}