import { createSelector } from "@ngrx/store";
import { DocumentsRegistryState, documentsRegistryStateSelector } from "../reducers";
import { getDocumentsCount } from "../reducers/documents-count.reducers";
import { getDocumentsCountLoading } from "../reducers/documents-count.reducers";
import { getDocumentsCountLoaded } from "../reducers/documents-count.reducers";
import { getDocumentsCountFailed } from "../reducers/documents-count.reducers";
import { recognizedDocumentsSelector } from "rootStore";

/**
 * Селектор состояния кол-ва документов.
 */
export const documentsCountStateSelector = createSelector(
    documentsRegistryStateSelector,
    (state: DocumentsRegistryState) => state.documentsCountState
);

/**
 * Селектор кол-ва документов.
 */
export const documentsCountSelector = createSelector(
    documentsCountStateSelector,
    recognizedDocumentsSelector,
    (state,  recDocs ) => {

        if (state.documentsCount === -1) {

            return -1;
        }
        if (recDocs.length > 0) {

            return state.documentsCount + recDocs.length;
        }

        return state.documentsCount;
    }
);

/**
 * Селектор флага выполняющейся загрузки кол-ва документов.
 */
export const documentsCountLoadingSelector = createSelector(documentsCountStateSelector, getDocumentsCountLoading);

/**
 * Селектор флага успешной загрузки кол-ва документов.
 */
export const documentsCountLoadedSelector = createSelector(documentsCountStateSelector, getDocumentsCountLoaded);

/**
 * Селектор флага неудачной загрузки кол-ва документов.
 */
export const documentsCountFailedSelector = createSelector(documentsCountStateSelector, getDocumentsCountFailed);
