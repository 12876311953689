<div class="bank-statement-upload-dialog">
    <h1 class="bank-statement-upload-dialog__title mat-h1 mat-h1_slim text-center">
        {{"common.upload-bank-statement-dialog.title" | translate}}
    </h1>
    <div *ngIf="!(currentSpace$ | async)?.paid" class="bank-statement-upload-dialog__limit-block text-center">
        <span>{{ "common.upload-bank-statement-dialog.trialLimit" | translate: { limit: (currentSpace$ | async).trialBankStatementLimit} }}</span>
    </div>
    <entera-file-upload-zone
        *ngIf="!uploadState.error && uploadState.progress !== 100 && !uploadState.duplicateCheckingByHash"
        class="bank-statement-upload-dialog__upload-zone"
        [uploadState]="uploadState"
        [multipleFileMode]="false"
        [fileFormats]="['.pdf', '.xls', '.xlsx']"
        (addFiles)="addFilesHandler($event)"
        (remove)="remove.emit($event)"
    >
    </entera-file-upload-zone>
    <div
        *ngIf="
            uploadState
            && uploadState.filesWithMetaData.length
            && !uploadState.error
            && uploadState.progress !== 100
            && !uploadState.duplicateCheckingByHash
        "
        class="bank-statement-upload-dialog__send text-center"
    >
        <button mat-raised-button color="primary" (click)="sendButtonClickHandler()">
            <mat-icon>send</mat-icon>
            {{"common.upload-to-recognize-dialog.buttons.upload" | translate}}
        </button>
    </div>
    <div *ngIf="uploadState.error" class="bank-statement-upload-dialog__error">
        {{ uploadState.error.errorMessage }}
        <hr/>
        <div>
            <button mat-button (click)="closeButtonClickHandler()">
                {{ 'dialogs.close' | translate }}
            </button>
        </div>
    </div>
    <div *ngIf="uploadState.progress === 100 && !uploadState.error || uploadState.duplicateCheckingByHash">
        <mat-spinner class="bank-statement-upload-dialog__spinner"></mat-spinner>
    </div>
    <button
        class="bank-statement-upload-dialog__close"
        (click)="closeButtonClickHandler()"
        mat-icon-button
        color="primary"
    >
        <mat-icon class="mat-icon_large">close</mat-icon>
    </button>
</div>
