import { Action } from '@ngrx/store';

import { ApiResponsePayloadAction } from 'src/app/root/store';
import { ApiResponse } from 'src/app/common/models/api-response';
import { Space } from 'src/app/common/models/space';
import { User } from 'src/app/common/models/user';

/**
 * Типы событий, связанные с создателями документов.
 */
export enum DocumentCreatorsActionType {
    LOAD = '[Documents registry] Load document creators',
    LOAD_SUCCESS = '[Documents registry] Load document creators Success',
    LOAD_FAIL = '[Documents registry] Load document creators Fail',
}

/**
 * Событие, требующее загрузки создателей документов.
 */
export class DocumentCreatorsLoadAction implements Action {
    readonly type = DocumentCreatorsActionType.LOAD;
    constructor(public payload: Space) { }
}

/**
 * Событие успешной загрузки создателей документов.
 */
export class DocumentCreatorsLoadSuccessAction implements Action {
    readonly type = DocumentCreatorsActionType.LOAD_SUCCESS;
    constructor(public payload: User[]) { }
}

/**
 * Событие неудачной загрузки создателей документов.
 */
export class DocumentCreatorsLoadFailAction implements ApiResponsePayloadAction {
    readonly type = DocumentCreatorsActionType.LOAD_FAIL;
    constructor(public payload: ApiResponse) { }
}

/**
 * Тип, объединяющий все события, связанные с создателями документов.
 */
export type DocumentCreatorsAction =
    | DocumentCreatorsLoadAction
    | DocumentCreatorsLoadFailAction
    | DocumentCreatorsLoadSuccessAction;
