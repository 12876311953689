/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-info-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i5 from "@angular/material/menu";
import * as i6 from "@angular/common";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@ngx-translate/core";
import * as i9 from "@angular/cdk/overlay";
import * as i10 from "@angular/cdk/bidi";
import * as i11 from "./user-info-menu.component";
import * as i12 from "../../services/user.service";
var styles_UserInfoMenuComponent = [i0.styles];
var RenderType_UserInfoMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserInfoMenuComponent, data: {} });
export { RenderType_UserInfoMenuComponent as RenderType_UserInfoMenuComponent };
function View_UserInfoMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon_green", null], [2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.green; var currVal_1 = i1.ɵnov(_v, 1).inline; var currVal_2 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _v.parent.context.$implicit.icon; _ck(_v, 2, 0, currVal_3); }); }
function View_UserInfoMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "mat-menu-item"], ["mat-menu-item", ""]], [[2, "mat-menu-item_right-icon", null], [2, "mat-menu-item_selected", null], [2, "mat-menu-item_green", null], [1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.menuItemClickHandler(_v.context.$implicit.id) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_MatMenuItem_0, i4.RenderType_MatMenuItem)), i1.ɵdid(1, 180224, [[1, 4]], 0, i5.MatMenuItem, [i1.ElementRef, i6.DOCUMENT, i7.FocusMonitor, [2, i5.ɵf24]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_UserInfoMenuComponent_2)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_9 = _v.context.$implicit.disabled; _ck(_v, 1, 0, currVal_9); var currVal_10 = _v.context.$implicit.icon; _ck(_v, 3, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.rightIcon; var currVal_1 = _v.context.$implicit.selected; var currVal_2 = _v.context.$implicit.green; var currVal_3 = i1.ɵnov(_v, 1).role; var currVal_4 = i1.ɵnov(_v, 1)._highlighted; var currVal_5 = i1.ɵnov(_v, 1)._triggersSubmenu; var currVal_6 = i1.ɵnov(_v, 1)._getTabIndex(); var currVal_7 = i1.ɵnov(_v, 1).disabled.toString(); var currVal_8 = (i1.ɵnov(_v, 1).disabled || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_11 = (_v.context.$implicit.text ? _v.context.$implicit.text : i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_v.context.$implicit.messageKey))); _ck(_v, 4, 0, currVal_11); }); }
export function View_UserInfoMenuComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "user-info-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 3, "mat-icon", [["aria-haspopup", "true"], ["class", "user-info-menu__icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null], [1, "aria-expanded", 0]], [[null, "mouseenter"], [null, "mouseleave"], [null, "mousedown"], [null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3)._handleMousedown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._handleClick($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseenter" === en)) {
        var pd_3 = ((_co.hovered = true) !== false);
        ad = (pd_3 && ad);
    } if (("mouseleave" === en)) {
        var pd_4 = ((_co.hovered = false) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), i1.ɵdid(3, 1196032, null, 0, i5.MatMenuTrigger, [i9.Overlay, i1.ElementRef, i1.ViewContainerRef, i5.MAT_MENU_SCROLL_STRATEGY, [2, i5.MatMenu], [8, null], [2, i10.Directionality], i7.FocusMonitor], { menu: [0, "menu"] }, null), (_l()(), i1.ɵted(4, 0, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 9, "mat-menu", [["class", "user-info-menu__menu"], ["xPosition", "before"]], null, null, null, i4.View_MatMenu_0, i4.RenderType_MatMenu)), i1.ɵdid(6, 1294336, [["infoMenu", 4]], 2, i5.MatMenu, [i1.ElementRef, i1.NgZone, i5.MAT_MENU_DEFAULT_OPTIONS], { xPosition: [0, "xPosition"], panelClass: [1, "panelClass"] }, null), i1.ɵqud(603979776, 1, { items: 1 }), i1.ɵqud(335544320, 2, { lazyContent: 0 }), i1.ɵprd(2048, null, i5.ɵf24, null, [i5.MatMenu]), (_l()(), i1.ɵeld(10, 0, null, 0, 2, "span", [["class", "mat-menu-item"], ["disabled", ""], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_MatMenuItem_0, i4.RenderType_MatMenuItem)), i1.ɵdid(11, 180224, [[1, 4]], 0, i5.MatMenuItem, [i1.ElementRef, i6.DOCUMENT, i7.FocusMonitor, [2, i5.ɵf24]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵted(12, 0, [" ", " "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_UserInfoMenuComponent_1)), i1.ɵdid(14, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_3 = i1.ɵnov(_v, 6); _ck(_v, 3, 0, currVal_3); var currVal_5 = "before"; var currVal_6 = "user-info-menu__menu"; _ck(_v, 6, 0, currVal_5, currVal_6); var currVal_13 = ""; _ck(_v, 11, 0, currVal_13); var currVal_15 = _co.menuItems; _ck(_v, 14, 0, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); var currVal_2 = (i1.ɵnov(_v, 3).menuOpen || null); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = (_co.hovered ? "person" : "person_outline"); _ck(_v, 4, 0, currVal_4); var currVal_7 = i1.ɵnov(_v, 11).role; var currVal_8 = i1.ɵnov(_v, 11)._highlighted; var currVal_9 = i1.ɵnov(_v, 11)._triggersSubmenu; var currVal_10 = i1.ɵnov(_v, 11)._getTabIndex(); var currVal_11 = i1.ɵnov(_v, 11).disabled.toString(); var currVal_12 = (i1.ɵnov(_v, 11).disabled || null); _ck(_v, 10, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); var currVal_14 = _co.userName; _ck(_v, 12, 0, currVal_14); }); }
export function View_UserInfoMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "user-info-menu", [], null, null, null, View_UserInfoMenuComponent_0, RenderType_UserInfoMenuComponent)), i1.ɵdid(1, 49152, null, 0, i11.UserInfoMenuComponent, [i12.UserService], null, null)], null, null); }
var UserInfoMenuComponentNgFactory = i1.ɵccf("user-info-menu", i11.UserInfoMenuComponent, View_UserInfoMenuComponent_Host_0, { user: "user" }, { userAccount: "userAccount", logout: "logout" }, []);
export { UserInfoMenuComponentNgFactory as UserInfoMenuComponentNgFactory };
