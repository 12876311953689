import { Injectable } from "@angular/core";
import { ofType } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { Actions } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Store } from "@ngrx/store";
import { spaceSelector } from "rootStore";
import { RootState } from "rootStore";
import { of } from "rxjs";
import { tap } from "rxjs/operators";
import { withLatestFrom } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { mergeMap } from "rxjs/operators";
import { map } from "rxjs/operators";
import { ApiResponse } from "src/app/common/models/api-response";
import { Space } from "src/app/common/models/index";
import { moveDocumentsDlgActions } from "src/app/common/store/actions/move-documents-dlg.action";
import { MoveDocumentsDlgActionType } from "src/app/common/store/actions/move-documents-dlg.action";
import { MoveDocumentsActionProps } from "src/app/common/store/actions/props/move-documents.action-props";
import { MovedDocumentsDeleteAction } from "src/app/spaces/modules/documents-registry/store/actions/documents.action";
import { DocumentsMassActionsCleanStateAction } from "src/app/spaces/modules/documents-registry/store/actions/documents.action";
import { newDocumentsSelector } from "src/app/spaces/modules/documents-registry/store/selectors/documents.selector";
import { documentsSelector } from "src/app/spaces/modules/documents-registry/store/selectors/documents.selector";
import { DocumentsService } from "../../services";
import { UserService } from "../../services";

/**
 * Side-эффекты на события, связанные с диалогом перемещения документа.
 */
@Injectable()
export class MoveDocumentsDlgEffect {
    //region Ctor

    /**
     * Контруктор эффектов связанных с диалогом перемещения документа.
     *
     * @param _actions$ Поток событий, происходящих в системе.
     * @param _userService Сервис работы с данными пользователя.
     * @param _store Хранилище.
     * @param _documentsService Сервис по работе с документами.
     */
    constructor(
        private _actions$: Actions,
        private _userService: UserService,
        private _store: Store<RootState>,
        private _documentsService: DocumentsService,
    ) { }

    //endregion
    //region Public

    /**
     * Перемещение документов в другую папку.
     */
    @Effect()
    moveDocumentsToAnotherSpace$ = this._actions$.pipe(
        ofType(MoveDocumentsDlgActionType.MOVE_TO_ANOTHER_SPACE),
        mergeMap((props: MoveDocumentsActionProps) =>
            this._documentsService.moveToAnotherSpace(props)
                .pipe(
                    withLatestFrom(
                        this._store.select(documentsSelector),
                        this._store.select(newDocumentsSelector),
                    ),
                    map(([movedDocumentIdList, documents, newDocuments]) =>
                        [...documents, ...newDocuments].filter(document => movedDocumentIdList.includes(document.id))
                    ),
                    map((documents): Action => moveDocumentsDlgActions.moveDocumentsToAnotherSpaceSuccess({
                        ...props,
                        documents,
                    })),
                    catchError((apiResponse: ApiResponse) =>
                        of(moveDocumentsDlgActions.moveDocumentsToAnotherSpaceFail({ error: apiResponse }))
                    ),
                ),
        ),
    );

    /**
     * Успешное перемещение документов в другую папку.
     */
    @Effect()
    moveDocumentsToAnotherSpaceSuccess$ = this._actions$.pipe(
        ofType(MoveDocumentsDlgActionType.MOVE_TO_ANOTHER_SPACE_SUCCESS),
        map((props: MoveDocumentsActionProps) => new MovedDocumentsDeleteAction(props)),
    );

    /**
     * Обработка события очистки стейта массовых действий документов после перемещения документов в другую папку.
     */
    @Effect()
    cleanStateAfterMassActions$ = this._actions$
        .pipe(
            ofType(MoveDocumentsDlgActionType.MOVE_TO_ANOTHER_SPACE_SUCCESS),
            map((_: never): any => new DocumentsMassActionsCleanStateAction()),
        );

    //endregion
}
