import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';

import { RootState } from 'rootStore';
import { currentUserStateSelector } from 'rootStore';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { CurrentUserInfo } from '../../common/models';
import { User } from '../../common/models';

import { UserService } from '../../common/services';
import { CurrentUserState } from '../store/reducers/current-user.reducer';

/**
 * Сервис для добавления виджета Битрикс24 на страницу.
 */
@Injectable({
    providedIn: 'root'
})
export class BitrixWidgetService {
    //region Fields

    /**
     * Сервис для управления и доступа к состоянию приложения.
     */
    private readonly _store: Store<RootState>;

    /**
     * Сервис для работы с пользователями.
     */
    private readonly _userService: UserService;

    /**
     * Скрипт для загрузки виджета вставлен в DOM-дерево?
     */
    private _scriptInserted: boolean;

    /**
     * Подписка на данные пользователя.
     */
    private _userSubscription: Subscription;

    //endregion
    //region Ctor

    /**
     * Конструктор сервиса для добавления виджета Битрикс24 на страницу.
     *
     * @param store Сервис для управления и доступа к состоянию приложения.
     * @param userService Сервис для работы с пользователями.
     */
    constructor(
        store: Store<RootState>,
        userService: UserService,
    ) {
        this._store = store;
        this._userService = userService;
        this._scriptInserted = false;
    }

    //endregion
    //region Public

    /**
     * Добавляет виджет Битрикс24 на страницу приложения.
     *
     * Сначала дожидается загрузки данных пользователя, после чего добавляет скрипт виджета на страницу приложения.
     */
    add(): void {

        if (!this._scriptInserted && environment.bitrix24 && environment.bitrix24.enable) {

            this._userSubscription = this._store
                .pipe(
                    select(currentUserStateSelector),
                    filter((state: CurrentUserState): boolean => state.loaded),
                    map((state: CurrentUserState): CurrentUserInfo => state.currentUserInfo),
                )
                .subscribe((currentUser: CurrentUserInfo) => {

                    if (currentUser) {

                        window.addEventListener('onBitrixLiveChat', (event: any) => {

                            this._bitrixLiveChatHandler(event, currentUser);
                        });
                    }

                    this._addWidget();
                    setTimeout(() => this._userSubscription.unsubscribe(), 0);
                });
        }
    }

    //endregion
    //region Private

    /**
     * Обработчик загрузки виджета Битрикс24.
     *
     * @param event Событие загрузки виджета Битрикса.
     * @param user Данные текущего пользователя.
     */
    private _bitrixLiveChatHandler(event: any, user: User): void {

        const widget: any = event.detail.widget;
        widget.setCustomData([
            {
                'USER': {
                    'NAME' : this._userService.getFullUserName(user)
                }
            },
            {
                'GRID': [
                    {
                        'NAME' : 'E-mail',
                        'VALUE' : user.email,
                        'DISPLAY' : 'LINE',
                    },
                    {
                        'NAME': 'Сайт',
                        'VALUE': location.hostname,
                        'DISPLAY': 'LINE'
                    },
                    {
                        'NAME': 'Страница',
                        'VALUE': '[url=' + location.href + ']' + (document.title || location.href) + '[/url]',
                        'DISPLAY': 'LINE'
                    },
                ]
            }
        ]);
    }

    /**
     * Добавляет скрипт виджета Битрикс24 на страницу.
     */
    private _addWidget(): void {

        if (!this._scriptInserted) {

            const script: HTMLScriptElement = document.createElement("script");
            script.text = `
                (function(w,d,u){
                    var s=d.createElement('script');
                    s.async=true;
                    s.src=u+'?'+(Date.now()/60000|0);
                    var h=d.getElementsByTagName('script')[0];
                    h.parentNode.insertBefore(s,h);
                })(window,document,'${environment.bitrix24.scriptUrl}');
            `;
            document.body.appendChild(script);

            `(function(w,d,u){
                var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/60000|0);
                var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
        })(window,document,'https://cdn.bitrix24.com/b20760055/crm/site_button/loader_1_6i0oi9.js');`

            this._scriptInserted = true;
        }
    }

    //endregion
}
