<div class="users-spaces-dlg">
    <users-and-spaces-input
        [spaces]="spaces"
        [preSelectedSpaces]="preSelectedSpaces"
        [requestLoading]="requestState?.type === 'pending'"
        [requestError]="requestState?.type === 'fail' && requestState.error || null"
        [requestSuccess]="requestState?.type === 'success'"
        [headerKey]="headerKey"
        [okBtnKey]="okBtnKey"
        [errorDescriptionKey]="errorDescriptionKey"
        [successDescriptionKey]="successDescriptionKey"
        (confirmData)="confirmDlgDataHandler($event)"
        (closeDialog)="closeDialog()"
    >
    </users-and-spaces-input>
</div>
<button *ngIf="requestState?.type !== 'pending'"
    class="mat-dialog__cross-close-btn"
    mat-flat-button
    (click)="closeDialogClickHandler()"
    title='{{ "dialogs.close" | translate }}'
>
    <mat-icon>close</mat-icon>
</button>
