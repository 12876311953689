import { Injectable } from "@angular/core";
import { ofType } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { ApiResponse } from "src/app/common/models/api-response";
import { Currency } from "src/app/common/models/currency";
import { CurrencyService } from "src/app/common/services/currency.service";
import { CurrenciesAction } from "src/app/root/store/actions/currencies.action";
import { currenciesActions } from "src/app/root/store/actions/currencies.action";
import { CurrenciesActionType } from "src/app/root/store/actions/currencies.action";
import { RootState } from "src/app/root/store/reducers/index";

/**
 * Side-эффекты на события, связанные с валютами.
 */
@Injectable()
export class CurrenciesEffects {
    //region Ctor

    constructor(
        private _actions$: Actions,
        private _store: Store<RootState>,
        private _currencyService: CurrencyService,
    ) { }

    //endregion
    //region Effects

    /**
     * Эффект на событие получения валют с сервера.
     */
    @Effect()
    getCurrencies$ = this._actions$.pipe(
        ofType(CurrenciesActionType.LOAD),
        switchMap(() => this._currencyService.getCurrencies()
            .pipe(
                map((currencies: Currency[]): CurrenciesAction => currenciesActions.loadSuccess({ value: currencies })),
                catchError((error: ApiResponse): Observable<CurrenciesAction> =>
                    of(currenciesActions.loadFail({ error }))
                ),
            )
        ),
    );

    //endregion
}