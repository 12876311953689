import { HostListener } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { Inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApiResponse } from "src/app/common/models";
import { RecognitionTask } from "src/app/common/models";

import { RecognitionTaskCreatedDlgData } from "src/app/root/models/recognition-task-created-dlg-data.model";
import { RecognitionTaskCreatedDlgApplyAction } from "src/app/root/store/actions";
import { currentUserStateSelector } from "src/app/root/store/reducers";
import { RootState } from "src/app/root/store/reducers";
import { CurrentUserState } from "src/app/root/store/reducers/current-user.reducer";
import { recognitionTaskCreatedStateSelector } from "src/app/root/store/selectors/recognition-task-created.selectors";
import { RecognitionTaskCreatedState } from "src/app/root/store/states/recognition-task-created.state";

/**
 * Компонент для отображения диалога после создания задачи на распознавание.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "recognition-task-created-dlg",
    templateUrl: "./recognition-task-created-dlg.component.html",
    styleUrls: ["./recognition-task-created-dlg.component.scss"],
})
export class RecognitionTaskCreatedDlgComponent {
    //region Fields

    /**
     * Возвращает принадлежность пользователя клиенту, которой произвёл оплату.
     */
    readonly belongsToPaidClient$: Observable<boolean>;

    /**
     * Необходимо отображать процесс загрузки в диалоге?
     */
    readonly loading$: Observable<boolean>;

    /**
     * Ошибка в процессе применения данных диалога?
     */
    readonly error$: Observable<ApiResponse>;

    /**
     * Диалог, в котором открывается текущий компонент.
     */
    private readonly _dialogRef: MatDialogRef<RecognitionTaskCreatedDlgComponent>;

    /**
     * Сервис для управления и доступа к состоянию приложения.
     */
    private readonly _store: Store<RootState>;

    /**
     * Задача на распознавание, после создания которой открывается диалог.
     */
    private readonly _recognitionTask: RecognitionTask;

    //endregion
    //region Ctor

    /**
     * Конструктор компонента диалога для отображения диалога после создания задачи на распознавание.
     *
     * @param recognitionTask Задача на распознавание, после которой открывается диалог.
     * @param dialogRef Диалог, в котором открывается текущий компонент.
     * @param store Сервис для управления и доступа к состоянию приложения.
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) recognitionTask: RecognitionTask,
        dialogRef: MatDialogRef<RecognitionTaskCreatedDlgComponent>,
        store: Store<RootState>
    ) {
        this._dialogRef = dialogRef;
        this._store = store;
        this._recognitionTask = recognitionTask;

        this.belongsToPaidClient$ = this._store.select(currentUserStateSelector)
            .pipe(
                map((state: CurrentUserState): boolean => state.currentUserInfo.belongsToPaidClient),
            );

        this.loading$ = this._store.select(recognitionTaskCreatedStateSelector)
            .pipe(
                map((state: RecognitionTaskCreatedState): boolean => state.loading),
            );

        this.error$ = this._store.select(recognitionTaskCreatedStateSelector)
            .pipe(
                map((state: RecognitionTaskCreatedState): ApiResponse => state.error),
            );
    }

    //endregion
    //region Public

    /**
     * Обрабатывает принятие диалога с указанными данными.
     */
    applyDialog(data: RecognitionTaskCreatedDlgData): void {

        this._store.dispatch(new RecognitionTaskCreatedDlgApplyAction(data, this._recognitionTask));
    }

    /**
     * Обработчик нажатия на Esc.
     *
     * Закрывает диалог.
     */
    @HostListener("window:keyup.esc")
    onKeyUp() {

        this._dialogRef.close();
    }

    //endregion
}
