import { requestState } from "src/app/common/models/request-state";
import { CurrenciesActionType } from "src/app/root/store/actions/currencies.action";
import { CurrenciesAction } from "src/app/root/store/actions/currencies.action";
import { CurrenciesState } from "src/app/root/store/states/currencies.state";

/**
 * Начальное состояние обработчика событий, связанных с состоянием валют.
 */
const initialState: CurrenciesState = {

    /**
     * Начальное состояние запроса валют с сервера.
     */
    currencyRequest: requestState.initial(),
};

/**
 * Обработчик событий, связанных с состоянием валют.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 *
 * @return Новое состояние.
 */
export function currenciesReducer(
    state: CurrenciesState = initialState,
    action: CurrenciesAction,
): CurrenciesState {
    let result: CurrenciesState = state;

    switch (action.type) {

        case CurrenciesActionType.LOAD: {

            result = {
                ...state,
                currencyRequest: requestState.pending(),
            };

            break;
        }
        case CurrenciesActionType.LOAD_SUCCESS: {

            result = {
                ...state,
                currencyRequest: requestState.success(action.value),
            };

            break;
        }
        case CurrenciesActionType.LOAD_FAIL: {

            result = {
                ...state,
                currencyRequest: requestState.fail(action.error),
            };

            break;
        }
    }

    return result;
}
