/**
 * Право на операцию.
 */
var PermissionItem = /** @class */ (function () {
    //endregion
    //region Ctor
    function PermissionItem(value, parent) {
        if (parent === void 0) { parent = null; }
        this.value = value;
        this.parent = parent;
    }
    Object.defineProperty(PermissionItem.prototype, "nameKey", {
        //endregion
        //region Getters and Setters
        /**
         * I18n-ключ названия права.
         */
        get: function () {
            return "PermissionItem." + this.value;
        },
        enumerable: true,
        configurable: true
    });
    //region Permissions
    //region Admin
    /**
     * Административные задачи.
     */
    PermissionItem.ADMIN = new PermissionItem("ADMIN");
    /**
     * Вход под пользователем.
     */
    PermissionItem.ADMIN_IMPERSONATE = new PermissionItem("ADMIN_IMPERSONATE", PermissionItem.ADMIN);
    /**
     * Контроль баланса пространств.
     */
    PermissionItem.CLIENT_CONTROL = new PermissionItem("CLIENT_CONTROL", PermissionItem.ADMIN);
    /**
    * Видеть все документы.
    */
    PermissionItem.SEE_ALL_DOCUMENTS = new PermissionItem("SEE_ALL_DOCUMENTS", PermissionItem.ADMIN);
    /**
     * Изменять пространства документов клиента.
     */
    PermissionItem.EDIT_CLIENT_SPACES = new PermissionItem("EDIT_CLIENT_SPACES", PermissionItem.CLIENT_CONTROL);
    /**
     * Видеть ссылку на редактирование
     */
    PermissionItem.ADVANCE_EDIT_ALL_DOCUMENTS = new PermissionItem("ADVANCE_EDIT_ALL_DOCUMENTS", PermissionItem.ADMIN);
    /**
     * Изменять баланс клиента.
     */
    PermissionItem.CHANGE_CLIENT_BALANCE = new PermissionItem(("CHANGE_CLIENT_BALANCE"), PermissionItem.ADMIN);
    /**
     * Увеличивать баланс клиента.
     */
    PermissionItem.INCREASE_CLIENT_BALANCE = new PermissionItem("INCREASE_CLIENT_BALANCE", PermissionItem.ADMIN);
    /**
     * Право изменять данные клиента.
     */
    PermissionItem.EDIT_CLIENT_INFO = new PermissionItem("EDIT_CLIENT_INFO", PermissionItem.CLIENT_CONTROL);
    /**
     * Право активировать/деактивировать клиента.
     */
    PermissionItem.INACTIVATE_CLIENT = new PermissionItem("INACTIVATE_CLIENT", PermissionItem.CLIENT_CONTROL);
    /**
     * Право изменять активность подписки на функционал отчёта реализации.
     */
    PermissionItem.SALES_REPORT = new PermissionItem("EDIT_SALES_REPORT_SUBSCRIPTION", PermissionItem.CLIENT_CONTROL);
    /**
     * Право изменять информацию о партнере.
     */
    PermissionItem.EDIT_PARTNER_INFO = new PermissionItem("EDIT_PARTNER_INFO", PermissionItem.CLIENT_CONTROL);
    /**
     * Создавать новые пространства документов.
     */
    PermissionItem.CREATE_CLIENT_SPACE = new PermissionItem("CREATE_CLIENT_SPACE", PermissionItem.EDIT_CLIENT_SPACES);
    /**
     * Редактировать пространства документов.
     */
    PermissionItem.EDIT_CLIENT_SPACE = new PermissionItem("EDIT_CLIENT_SPACE", PermissionItem.EDIT_CLIENT_SPACES);
    /**
     * Редактировать данные пространства документов.
     */
    PermissionItem.EDIT_SPACE_INFO = new PermissionItem("EDIT_SPACE_INFO", PermissionItem.EDIT_CLIENT_SPACE);
    /**
     * Изменять баланс пространства документов.
     */
    PermissionItem.CHANGE_SPACE_BALANCE = new PermissionItem("CHANGE_SPACE_BALANCE", PermissionItem.EDIT_CLIENT_SPACE);
    /**
     * Переключать флаги пространства документов.
     */
    PermissionItem.TOGGLE_SPACE_FLAGS = new PermissionItem("TOGGLE_SPACE_FLAGS", PermissionItem.EDIT_CLIENT_SPACE);
    /**
     * Переключать флаги пространства документов.
     */
    PermissionItem.TOGGLE_EXTENDED_TYPES_SPACE_FLAG = new PermissionItem("TOGGLE_EXTENDED_TYPES_SPACE_FLAG", PermissionItem.TOGGLE_SPACE_FLAGS);
    /**
     * Переключать флаг TRIAL-приоритета пространства документов.
     */
    PermissionItem.TOGGLE_SPACE_TRIAL_FLAG = new PermissionItem("TOGGLE_SPACE_TRIAL_FLAG", PermissionItem.TOGGLE_SPACE_FLAGS);
    /**
     * Переключать флаг использования баланса клиента для пространства документов.
     */
    PermissionItem.TOGGLE_SPACE_USE_CLIENT_BALANCE_FLAG = new PermissionItem("TOGGLE_SPACE_USE_CLIENT_BALANCE_FLAG", PermissionItem.TOGGLE_SPACE_FLAGS);
    /**
     * Переключать флаг использования пространства документов для иностранных документов.
     */
    PermissionItem.TOGGLE_FOREIGN_SPACE_FLAG = new PermissionItem("TOGGLE_FOREIGN_SPACE_FLAG", PermissionItem.TOGGLE_SPACE_FLAGS);
    /**
     * Изменять приоритет пространства документов.
     */
    PermissionItem.UPDATE_SPACE_PRIORITY = new PermissionItem("UPDATE_SPACE_PRIORITY", PermissionItem.EDIT_CLIENT_SPACE);
    /**
     * Приглашать пользователей в в папки.
     */
    PermissionItem.INVITE_USERS_TO_SPACE = new PermissionItem("INVITE_USERS_TO_SPACE", PermissionItem.EDIT_CLIENT_SPACE);
    /**
     * Видеть доверенные почты, с которых могут приходить задачи на распознавание в эту папку.
     */
    PermissionItem.SEE_TRUSTED_EMAILS = new PermissionItem("SEE_TRUSTED_EMAILS", PermissionItem.CLIENT_CONTROL);
    /**
     * Редактировать почты, с которых могут приходить задачи на распознавание в эту папку.
     */
    PermissionItem.EDIT_TRUSTED_EMAILS = new PermissionItem("EDIT_TRUSTED_EMAILS", PermissionItem.SEE_TRUSTED_EMAILS);
    /**
     * Перемещать документы из одной папки в другую.
     */
    PermissionItem.MOVE_DOCUMENTS = new PermissionItem("MOVE_DOCUMENTS", PermissionItem.CLIENT_CONTROL);
    //endregion
    //region Client
    /**
     * Права пользователей клиента.
     */
    PermissionItem.CLIENT = new PermissionItem("CLIENT");
    /**
     * Право на создание нового пространство документов.
     */
    PermissionItem.CREATE_SPACE = new PermissionItem("CREATE_SPACE", PermissionItem.CLIENT);
    /**
     * Право изменять пространства документов.
     */
    PermissionItem.EDIT_SPACE = new PermissionItem("EDIT_SPACE", PermissionItem.CLIENT);
    //endregion
    //region User
    /**
     * Права обычных пользователей.
     */
    PermissionItem.USER = new PermissionItem("USER");
    /**
     * Права пользователя в пространстве документов.
     */
    PermissionItem.SPACE = new PermissionItem("SPACE", PermissionItem.USER);
    /**
     * Право на изменение интеграции.
     */
    PermissionItem.INTEGRATION = new PermissionItem("INTEGRATION", PermissionItem.SPACE);
    /**
     * Право на полное удаление задач распознавания.
     */
    PermissionItem.RECOGNITION_TASK_TOTAL_DELETE = new PermissionItem("RECOGNITION_TASK_TOTAL_DELETE", PermissionItem.SPACE);
    /**
     * Право пригласить любого другого пользователя в пространство документов.
     *
     * Пользователь может еще не существовать в системе.
     */
    PermissionItem.INVITE_USERS = new PermissionItem("INVITE_USERS", PermissionItem.SPACE);
    /**
     * <p>Право добавлять доверенные почты, с которых можно принимать задачи на распознавание на эту папку.</p>
     */
    PermissionItem.MANAGE_TRUSTED_EMAILS = new PermissionItem("MANAGE_TRUSTED_EMAILS", PermissionItem.SPACE);
    /**
     * Право управлять уведомлениями пользователей на почту о состоянии задачи на распознавание.
     */
    PermissionItem.MANAGE_EMAIL_NOTIFICATIONS = new PermissionItem("MANAGE_EMAIL_NOTIFICATIONS", PermissionItem.SPACE);
    return PermissionItem;
}());
export { PermissionItem };
