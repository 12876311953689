import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { ErrorResponseProps } from "src/app/root/store/actions/props/error-response.action-props";
import { ValueProps } from "src/app/root/store/actions/props/value-props";
import { ExternalUnit } from "src/app/spaces/modules/document-matching/models/external-unit";
import { IntegrationProps } from "src/app/spaces/modules/documents-registry/store/actions/props/integration.props";

/**
 * Типы событий, связанных с единицами измерения.
 */
export enum ExternalUnitsActionType {
    LOAD = "[External units] Load",
    LOAD_SUCCESS = "[External units] Successfully load",
    LOAD_FAIL = "[External units] Error occurred while loading",
}

/**
 * Событие получения единиц измерения с сервера.
 */
const load = createAction(ExternalUnitsActionType.LOAD, props<IntegrationProps>());

/**
 * Событие успешного получения единиц измерения с сервера.
 */
const loadSuccess = createAction(ExternalUnitsActionType.LOAD_SUCCESS, props<ValueProps<ExternalUnit[]>>());

/**
 * Событие ошибки при получении единиц измерения с сервера.
 */
const loadFail = createAction(ExternalUnitsActionType.LOAD_FAIL, props<ErrorResponseProps<void>>());

/**
 * Тип, объединяющий все события, связанные с единицами измерения.
 */
export type ExternalUnitsAction =
    | ReturnType<typeof load>
    | ReturnType<typeof loadSuccess>
    | ReturnType<typeof loadFail>;

/**
 * Все события, связанные с единицами измерения.
 */
export const externalUnitsActions = {
    load,
    loadSuccess,
    loadFail,
};
