var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EventEmitter } from "@angular/core";
import { ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material';
import { MatSelect } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { MatSelectSearchComponent } from 'ngx-mat-select-search';
import { of } from 'rxjs';
import { fromEvent } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { take } from "rxjs/operators";
import { startWith } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { switchMap } from "rxjs/operators";
import { debounceTime } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { mergeMap } from "rxjs/operators";
import { withLatestFrom } from "rxjs/operators";
import { UtilsService } from "../../services";
/**
 * Компонент поля формы для выбора из списка значений с возможностью поиска.
 */
var SingleSelectComponent = /** @class */ (function () {
    //endregion
    //region Ctor
    function SingleSelectComponent(_translateService, _cd, _utilService) {
        var _this = this;
        this._translateService = _translateService;
        this._cd = _cd;
        this._utilService = _utilService;
        /**
         * Входящие данные - функция постраничной динамической ajax-загрузки значения для выбора.
         */
        this.pagedSearchFn = null;
        /**
         * Входящие данные - функция динамической ajax-загрузки значения для выбора.
         */
        this.searchFn = null;
        /**
         * Входящие данные - задержка перед выполнением поиска после последнего нажатия на клавиши.
         */
        this.searchDebounce = 170;
        /**
         * Входящие данные - кнопка очистки выбранного значения включена?
         */
        this.clearBtnEnabled = true;
        /**
         * Входящие данные - поле обязательно для заполнения?
         */
        this.required = false;
        /**
         * Входящие данные - отображать custom ошибку для поля?
         */
        this.customError = false;
        /**
         * Размер старницы поиска
         */
        this.pageSize = 10;
        /**
         * Прелоадинг значения выпадашки при асинхронном поиске. Ищется только по id.
         */
        this.preloading = false;
        /**
         * Компактное отображение для таблицы включено?
         */
        this.tableCompact = false;
        //endregion
        //region Outputs
        /**
         * Исходящие данные - событие изменения выбранного элемента.
         */
        this.selectionChange = new EventEmitter();
        /**
         * Исходящие данные - события сброса выбранного элемента.
         */
        this.clearBtnClickEvent = new EventEmitter();
        //endregion
        //region Public fields
        /**
         * Минимальная длина поискового запроса.
         */
        this.minLengthToSearch = 3;
        /**
         * Флаг, что длина поискового запроса достигла нужной длины для выполнения запроса.
         */
        this.searchLengthReached = false;
        /**
         * FormControl привязанный к выпадашке.
         */
        this.valueControl = new FormControl();
        /**
         * FormControl привязанный к полю поиска.
         */
        this.searchControl = new FormControl();
        /**
         * Динамически загруженные варианты для выбора согласно поисковому запросу.
         */
        this.searchOptions$ = new BehaviorSubject([]);
        /**
         * Флаг выполняющейся загрузки динамических вариантов выбора.
         */
        this.loading$ = new BehaviorSubject(false);
        /**
         * Флаг того, что часть динамических вариантов выбора загружена.
         */
        this.loaded = false;
        /**
         * Выбранное значение.
         */
        this.selectedValue = null;
        //endregion
        //region Private fields
        /**
         * Callback, когда выбранное в выпадашке значение изменилось.
         *
         * @private
         */
        this._changeCallback = function (_) { };
        /**
         * Callback, когда пользователь начал взаимодействовать с выпадашкой.
         *
         * @private
         */
        this._touchCallback = function (_) { };
        /**
         * Поле для хранения значений для выбора.
         *
         * @private
         */
        this._options = [];
        /**
         * Поле для хранения флага отключения контрола при отсутствии вариантов для выбора.
         *
         * @private
         */
        this._disableOnEmptyOptions = true;
        /**
         * Функция для сравнения вариантов выбора по id.
         *
         * @private
         */
        this._compareWith = function (o1, o2) { return o1 && o2 && o1['id'] === o2['id']; };
        /**
         * Функция для сравнения вариантов выбора по заданному полю.
         *
         * @private
         */
        this._compareWithField = function (o1, o2) { return o1 && o2 && o1[_this.valueField] === o2[_this.valueField]; };
        /**
         * Поле для хранения флага отключения контрола.
         *
         * @private
         */
        this._selectDisabled = false;
        /**
         * Флаг выполняющейся загрузки динамических вариантов выбора. Внутренее отображение.
         */
        this._loading = false;
        /**
         * Все подписки которые необходимо завершить при уничтожении объекта.
         */
        this._subscriptions = [];
        /**
         * Текущая страница для AJAX запроса поиска. Ставновится -1, если нет больше страниц для поиска.
         */
        this._currentSearchPage = 1;
    }
    Object.defineProperty(SingleSelectComponent.prototype, "optionField", {
        /**
         * Возвращает поле варианта выбора, которое отображается в выпадашке.
         */
        get: function () {
            return (this._optionField ? this._optionField : 'name');
        },
        /**
         * Входящие данные - какое поле варианта выбора отображать в выпадашке. По умолчанию "name".
         */
        set: function (optionField) {
            this._optionField = optionField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingleSelectComponent.prototype, "resultField", {
        /**
         * Возвращает поле выбранного варианта, которое отображается.
         */
        get: function () {
            return (this._resultField ? this._resultField : 'name');
        },
        /**
         * Входящие данные - какое поле выбранного варианта отображать. По умолчанию "name".
         */
        set: function (resultField) {
            this._resultField = resultField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingleSelectComponent.prototype, "options", {
        /**
         * Возвращает значения для выбора.
         */
        get: function () {
            return this._options;
        },
        /**
         * Входящие данные - статические значения для выбора.
         */
        set: function (options) {
            this._options = options;
            this._disableOrEnableControl();
            this.searchControl.setValue("");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingleSelectComponent.prototype, "disableOnEmptyOptions", {
        /**
         * Входящие данные - отключать выпадашку, когда вариантов для выбора нет?
         */
        set: function (disableOnEmptyOptions) {
            this._disableOnEmptyOptions = disableOnEmptyOptions;
            this._disableOrEnableControl();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingleSelectComponent.prototype, "selectDisabled", {
        /**
         * Входящие данные - отключение контрола.
         */
        set: function (disabled) {
            this._selectDisabled = disabled;
            this._disableOrEnableControl();
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Hooks
    SingleSelectComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Поиск среди статически заданных вариантов выбора.
        this.filteredOptions$ = this.searchControl.valueChanges
            .pipe(startWith(''), map(function (search) { return _this.filterOptions(search); }));
        this._subscriptions.push(this.valueControl.valueChanges
            .subscribe(function (value) {
            _this.selectedValue = value;
            _this._changeCallback(value);
            setTimeout(function () { return _this._cd.markForCheck(); }, 100);
        }), this.loading$.subscribe(function (value) { return _this._loading = value; }));
    };
    SingleSelectComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        // Поиск динамически подгружаемых вариантов выбора.
        if (this.pagedSearchFn) {
            // Постраничный запрос активен
            var inProgress_1 = false;
            var oldSearch_1;
            this._subscriptions.push(this.searchControl.valueChanges
                .pipe(debounceTime(this.searchDebounce), startWith(''), filter(function (search) { return oldSearch_1 !== search; }), filter(function (search) {
                var result = search.length <= 1000;
                if (!result) {
                    _this.openAndSearch(search.substring(0, 1000));
                }
                return result;
            }), switchMap(function (search) {
                _this.searchOptions$.next([]);
                oldSearch_1 = search;
                if (search.length < _this.minLengthToSearch) {
                    _this.searchLengthReached = false;
                    return of([]);
                }
                else {
                    _this._currentSearchPage = 1;
                    _this.loading$.next(true);
                    _this.searchLengthReached = false;
                    return _this.pagedSearchFn(search, _this._currentSearchPage, _this.pageSize);
                }
            }))
                .subscribe(function (options) {
                if (options.length < _this.pageSize) {
                    _this._currentSearchPage = -1;
                }
                _this.searchOptions$.next(options);
            }));
            // Подписываемся на скролл панели
            this._subscriptions.push(this.matSelect.openedChange.pipe(
            // Панель открыта
            filter(Boolean), 
            // Переключаемся на событие скролла
            mergeMap(function () { return fromEvent(_this.matSelect.panel.nativeElement, 'scroll'); }), debounceTime(50), map(function (event) { return event.srcElement; }), map(function (srcElement) { return srcElement.scrollHeight - (srcElement.scrollTop + srcElement.clientHeight); }), filter(function (offset) { return offset < 10; }), 
            // Нет больше страниц для поиска
            filter(function () { return _this._currentSearchPage != -1; }), withLatestFrom(this.searchControl.valueChanges), filter(function () { return !inProgress_1; }), mergeMap(function (_a) {
                var _ = _a[0], search = _a[1];
                inProgress_1 = true;
                _this._currentSearchPage++;
                _this.loading$.next(true);
                return _this.pagedSearchFn('' + search, _this._currentSearchPage, _this.pageSize);
            }), map(function (newOptions) {
                if (newOptions.length < _this.pageSize) {
                    _this._currentSearchPage = -1;
                }
                inProgress_1 = false;
                return _this.searchOptions$.getValue().concat(newOptions);
            }))
                .subscribe(function (options) { return _this.searchOptions$.next(options); }));
        }
        else if (this.searchFn) {
            var oldSearch_2;
            this._subscriptions.push(this.searchControl.valueChanges
                .pipe(debounceTime(this.searchDebounce), startWith(''), filter(function (search) { return oldSearch_2 !== search; }), filter(function (search) {
                var result = search.length <= 1000;
                if (!result) {
                    _this.openAndSearch(search.substring(0, 1000));
                }
                return result;
            }), switchMap(function (search) {
                _this.searchOptions$.next([]);
                oldSearch_2 = search;
                if (search.length < _this.minLengthToSearch) {
                    _this.searchLengthReached = false;
                    return of([]);
                }
                else {
                    _this.loading$.next(true);
                    _this.searchLengthReached = false;
                    return _this.searchFn(search);
                }
            }))
                .subscribe(function (options) {
                if (options.length < _this.pageSize) {
                    _this._currentSearchPage = -1;
                }
                _this.searchOptions$.next(options);
            }));
        }
        // Подписываемся на изменение набора динамически загруженных вариантов выбора.
        // Это тот момент, когда загрузка значений завершилась.
        this._subscriptions.push(this.searchOptions$
            .subscribe(function (options) {
            _this.loading$.next(false);
            _this.loaded = !!(options && options.length);
        }));
    };
    SingleSelectComponent.prototype.ngOnDestroy = function () {
        this._subscriptions.forEach(function (sub) { return sub.unsubscribe(); });
    };
    //endregion
    //region ControlValueAccessor
    /**
     * Программная установка значения.
     *
     * Тут выполянется асинхронная подгрузка значения, если это необходимо.
     *
     * @param value Значение для установки.
     */
    SingleSelectComponent.prototype.writeValue = function (value) {
        var _this = this;
        if (value !== undefined && this.valueControl.value !== value) {
            if (value && value.id && value.id != 'null' && (this.pagedSearchFn || this.searchFn) && this.preloading) {
                if (this.pagedSearchFn) {
                    var subs_1 = this.pagedSearchFn(null, null, null, [value.id])
                        .subscribe(function (opts) {
                        if (opts.length) {
                            _this.valueControl.setValue(opts[0]);
                        }
                        else {
                            _this.valueControl.setValue(value);
                        }
                        subs_1.unsubscribe();
                    });
                }
                else {
                    this.searchFn(null, [value.id])
                        .pipe(take(1))
                        .subscribe(function (opts) {
                        if (opts.length) {
                            _this.valueControl.setValue(opts[0]);
                        }
                        else {
                            _this.valueControl.setValue(value);
                        }
                    });
                }
            }
            else if (value && value.id && value.id != 'null' && this.options.length) {
                var original = this.options.filter(function (option) { return option.id === value.id; })[0];
                if (original) {
                    this.valueControl.setValue(__assign({}, original, value));
                }
                else {
                    this.valueControl.setValue(value);
                }
            }
            else {
                this.valueControl.setValue(value);
            }
        }
    };
    SingleSelectComponent.prototype.registerOnChange = function (fn) {
        this._changeCallback = fn;
    };
    SingleSelectComponent.prototype.registerOnTouched = function (fn) {
        this._touchCallback = fn;
    };
    Object.defineProperty(SingleSelectComponent.prototype, "compareWith", {
        //endregion
        //region Getters and Setters
        /**
         * Возвращает функцию для сравнения вараинтов выбора для mat-select.
         */
        get: function () {
            if (this.userCompareWith) {
                return this.userCompareWith;
            }
            if (this.valueField) {
                return this._compareWithField;
            }
            return this._compareWith;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingleSelectComponent.prototype, "searchPlaceholder$", {
        /**
         * Возвращает Observable placeholder'а, пока строка поиска пуста.
         */
        get: function () {
            if (this.searchPlaceholder) {
                return of(this.searchPlaceholder);
            }
            else {
                return this._translateService.get('search.label');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingleSelectComponent.prototype, "startSearchPlaceholder$", {
        /**
         * Возвращает Observable placeholder'а, что пользователю нужно начать вводить, чтобы начался поиск.
         */
        get: function () {
            if (this.startSearchPlaceholder) {
                return of(this.startSearchPlaceholder);
            }
            else {
                return this._translateService.get('search.startTyping');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingleSelectComponent.prototype, "noSearchResultsPlaceholder$", {
        /**
         * Возвращает Observable placeholder'а, когда поиск не вернул результатов.
         */
        get: function () {
            if (this.noSearchResultsPlaceholder) {
                return of(this.noSearchResultsPlaceholder);
            }
            else {
                return this._translateService.get('search.empty.result');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingleSelectComponent.prototype, "isStartTypingPlaceholderVisible", {
        /**
         * Placeholder для приглашения ввода для начала поиска виден?
         */
        get: function () {
            return !!((!this.options || this.options.length === 0)
                && (this.pagedSearchFn || this.searchFn)
                && !this._loading
                && !this.loaded
                && (!this.searchControl.value || !this.searchLengthReached));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingleSelectComponent.prototype, "isSelectedValueExists", {
        /**
         * Вариант с выбранным значением нужно отображать?
         */
        get: function () {
            var result;
            if (this.selectedValue instanceof Object) {
                result = !!this.selectedValue.id;
            }
            else {
                result = !!this.selectedValue;
            }
            return result;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingleSelectComponent.prototype, "isNoSearchResultsVisible", {
        /**
         * Текст, что ничего не найдено виден?
         */
        get: function () {
            return (!this.isStartTypingPlaceholderVisible
                && !this._loading
                && !this.loaded
                && (this.pagedSearchFn || this.searchFn)
                && this.searchControl.value);
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Public
    /**
     * Возвращает текстовое представление варианта для выбора.
     *
     * @param option Вариант для выбора.
     */
    SingleSelectComponent.prototype.getOptionText = function (option) {
        var text = '';
        if (this.formatOption) {
            text = this.formatOption(option);
        }
        else {
            text = option[this.optionField];
        }
        if (this.searchControl.value && text) {
            text = this._utilService.highlight(text, this.searchControl.value, "highlight");
        }
        return text;
    };
    /**
     * Возвращает текстовое представление выбранного варианта.
     *
     * @param selected Выбранный вариант.
     */
    SingleSelectComponent.prototype.getResultText = function (selected) {
        var text = '';
        if ((!selected || !selected.id || selected.id === 'null') && !!this.nullOptionText) {
            text = this.nullOptionText;
        }
        else if (this.formatResult) {
            text = this.formatResult(selected);
        }
        else {
            text = selected[this.resultField];
        }
        return text;
    };
    /**
     * Возвращает значение варианта для выбора, которое будет использоваться как значение всего контрола.
     *
     * @param option Вариант для выбора.
     */
    SingleSelectComponent.prototype.getOptionValue = function (option) {
        var value = option;
        if (this.valueField) {
            value = option[this.valueField];
        }
        return value;
    };
    /**
     * Возвращает значение для варианта выбора обозначающего 'null'-значение.
     */
    SingleSelectComponent.prototype.getNullOptionValue = function () {
        var value = {
            id: 'null'
        };
        if (this.valueField) {
            value = 'null';
        }
        return value;
    };
    /**
     * Открывает выпадашку, подставляет заданную поисковую строку и запускает поиск.
     *
     * @param search Поисковая строка.
     */
    SingleSelectComponent.prototype.openAndSearch = function (search) {
        if (!this.matSelect.panelOpen) {
            this.matSelect.open();
        }
        this.selectSearch.searchSelectInput.nativeElement.value = search;
        this.searchControl.setValue(search);
    };
    //endregion
    //region Events
    /**
     * Обработчик клика очистки значения выбранного в выпадашке.
     */
    SingleSelectComponent.prototype.clearBtnClickHandler = function (event) {
        this.valueControl.reset(null);
        this.clearBtnClickEvent.emit();
        // Предотвращаем дальнейшее всплытие, т.к. иначе выпадашка откроется.
        event.stopPropagation();
    };
    /**
     * Обработчик нажатия кнопок в поле поиска.
     */
    SingleSelectComponent.prototype.keydownHandler = function (event) {
        // Кнопки HOME и END останавливаем от всплытия, т.к. иначе mat-select сделает event.preventDefault()
        // для них, что не даст в поле поиска перемещатся в начало или конец поля.
        if (event.keyCode === 35 || event.keyCode === 36) {
            event.stopPropagation();
        }
    };
    SingleSelectComponent.prototype.selectionChangeHandler = function (event) {
        this.selectionChange.next(event);
    };
    //endregion
    //region Private
    /**
     * Фильтрует варианты для выбора согласно поиковой строке.
     *
     * @param search Поисковая строка.
     */
    SingleSelectComponent.prototype.filterOptions = function (search) {
        var _this = this;
        search = search.toLowerCase();
        return this.options.filter(function (option) { return _this.getOptionText(option).toLowerCase().includes(search); });
    };
    /**
     * Включает или отключает контрол в зависимости от входящих данных.
     */
    SingleSelectComponent.prototype._disableOrEnableControl = function () {
        if (this._selectDisabled) {
            this.valueControl.disable();
        }
        else {
            if (this._disableOnEmptyOptions
                && (!this.options || this.options.length === 0)
                && !this.pagedSearchFn
                && !this.searchFn) {
                this.valueControl.disable();
            }
            else {
                this.valueControl.enable();
            }
        }
    };
    return SingleSelectComponent;
}());
export { SingleSelectComponent };
