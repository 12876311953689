import { IntegrationItemSettings } from "src/app/common/models/integration/integration-item-settings.model";
import { IntegrationItem } from "src/app/common/models/integration/integration-item.model";
import { SupplyType } from "src/app/spaces/modules/document-matching/models/supply-type";
import { SupplyTypesAction } from "src/app/spaces/modules/document-matching/store/actions/supply-types.action";
import { SupplyTypesActionType } from "src/app/spaces/modules/document-matching/store/actions/supply-types.action";
import { SupplyTypesState } from "src/app/spaces/modules/document-matching/store/states/supply-types.state";
import { environment } from "src/environments/environment";

/**
 * Начальное состояние обработчика событий, связанных с состоянием единиц измерения.
 */
const initialState: SupplyTypesState = {

    /**
     * Начальное состояние запроса единиц измерения с сервера.
     */
    supplyTypes: [],
};

/**
 * Обработчик событий, связанных с состоянием единиц измерения.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 *
 * @return Новое состояние.
 */
export function supplyTypesReducer(
    state: SupplyTypesState = initialState,
    action: SupplyTypesAction,
): SupplyTypesState {
    let result: SupplyTypesState = state;

    switch (action.type) {

        case SupplyTypesActionType.LOAD: {

            const integrationItem: IntegrationItem = environment.integrations
                .find((integrationItem: IntegrationItem) => integrationItem.serverType == action.integrationType);
            const itemSettings: IntegrationItemSettings = integrationItem ? integrationItem.items : null;
            const supplyTypes: SupplyType[] = itemSettings ? itemSettings.supplyType : [];

            result = {
                ...state,
                supplyTypes,
            };

            break;
        }
    }

    return result;
}
