var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { NewDocumentsCountActionType } from "../../actions";
import { NewDocumentsCountLoadSuccessAction } from "../../actions";
import { NewDocumentsCountLoadFailAction } from "../../actions";
import { SpaceService } from "src/app/common/services/space.service";
import { DocumentsService } from "src/app/common/services/documents.service";
/**
 * Side-эффекты на события, связанные с количеством новых документов.
 */
var NewDocumentsCountEffects = /** @class */ (function () {
    //region Ctor
    function NewDocumentsCountEffects(actions$, spaceService, documentsService) {
        var _this = this;
        this.actions$ = actions$;
        this.spaceService = spaceService;
        this.documentsService = documentsService;
        //endregion
        //region Public
        /**
         * Обработка события требования загрузки кол-ва новых документов.
         */
        this.loadNewDocumentsCount$ = this.actions$
            .pipe(ofType(NewDocumentsCountActionType.LOAD), map(function (action) { return action.payload; }), switchMap(function (space) {
            return _this.spaceService.getNewDocumentsCount(space)
                .pipe(map(function (newDocumentsCount) { return new NewDocumentsCountLoadSuccessAction(newDocumentsCount); }), catchError(function (response) { return of(new NewDocumentsCountLoadFailAction(response)); }));
        }));
        /**
         * Обработка события требования пометить документ как просмотренный.
         * После выполнения метода пометки документа вызывается уменьшение кол-ва новых документов на 1.
         */
        this.markDocumentAsSeen$ = this.actions$
            .pipe(ofType(NewDocumentsCountActionType.MARK_AS_SEEN), map(function (action) { return action.payload; }), switchMap(function (document) {
            return _this.documentsService.markAsSeen(document);
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], NewDocumentsCountEffects.prototype, "loadNewDocumentsCount$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], NewDocumentsCountEffects.prototype, "markDocumentAsSeen$", void 0);
    return NewDocumentsCountEffects;
}());
export { NewDocumentsCountEffects };
