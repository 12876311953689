import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { map } from 'rxjs/operators';

import { RootState } from 'rootStore';
import { currentUserStateSelector } from 'rootStore';
import { CurrentUserState } from '../store/reducers/current-user.reducer';

import { CurrentUserInfo } from '../../common/models';
import { User } from '../../common/models';
import { environment } from '../../../environments/environment';

/**
 * Сервис для добавления виджета Omnidesk на страницу.
 */
@Injectable({
    providedIn: 'root'
})
export class OmnideskWidgetService {
    //region Fields

    /**
     * Сервис для управления и доступа к состоянию приложения.
     */
    private readonly _store: Store<RootState>;

    /**
     * Скрипт для загрузки виджета вставлен в DOM-дерево?
     */
    private _scriptInserted: boolean;

    /**
     * Подписка на данные пользователя.
     */
    private _userSubscription: Subscription;

    //endregion
    //region Ctor

    /**
     * Конструктор сервиса для добавления виджета Omnidesk на страницу.
     *
     * @param store Сервис для управления и доступа к состоянию приложения.
     */
    constructor(store: Store<RootState>) {

        this._store = store;
        this._scriptInserted = false;
    }

    //endregion
    //region Public

    /**
     * Добавляет виджет Omnidesk на страницу приложения.
     *
     * Сначала дожидается загрузки данных пользователя, после чего добавляет скрипт виджета на страницу приложения.
     */
    add(): void {

        if (!this._scriptInserted) {

            this._userSubscription = this._store
                .pipe(
                    select(currentUserStateSelector),
                    filter((state: CurrentUserState): boolean => state.loaded),
                    map((state: CurrentUserState): CurrentUserInfo => state.currentUserInfo),
                )
                .subscribe((currentUser: CurrentUserInfo) => {

                    this._addWidget(currentUser);
                    setTimeout(() => this._userSubscription.unsubscribe(), 0);
                });
        }
    }

    //endregion
    //region Private

    /**
     * Добавляет скрипт виджета Omnidesk на страницу.
     */
    private _addWidget(user: User): void {

        const widgetId: string = environment.omnidesk.widgetId;

        if (!this._scriptInserted && widgetId) {

            let emailConfig: string = '';
            if (user && user.email) {

                emailConfig = `
                    window.omni[0].widget = {
                        ready: function() {
                            window.omni[0].widget.identify = {
                                user_email: '${user.email}'
                            };
                        }
                    };
                `;
            }

            const script: HTMLScriptElement = document.createElement('script');
            script.text = `
                !function(e,o) {
                    !window.omni?window.omni=[]:'';
                    window.omni.push(o);
                    o.g_config={widget_id:"${widgetId}"};
                    o.email_widget=o.email_widget||{};
                    var w=o.email_widget;
                    w.readyQueue=[];
                    o.config=function(e){this.g_config.user=e};
                    w.ready=function(e){this.readyQueue.push(e)};
                    window.omni[0].config({ user_info: true });
                    ${emailConfig}
                    var r = e.getElementsByTagName("script")[0];
                    c = e.createElement("script");
                    c.type="text/javascript";
                    c.async=!0;
                    c.src="https://omnidesk.ru/bundles/acmesite/js/cwidget0.2.min.js";
                    r.parentNode.insertBefore(c,r);
                } (document, [])
            `;
            document.body.appendChild(script);

            this._scriptInserted = true;
        }
    }

    //endregion
}
