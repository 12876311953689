var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ofType } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from "rxjs";
import { withLatestFrom } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { FeedbackDlgService } from "../../services/feedback-dlg.service";
import { FeedbackSendFailAction } from "../actions";
import { FeedbackSendSuccessAction } from "../actions";
import { FeedbackActionType } from "../actions";
import { feedbackStateSelector } from "../reducers";
/**
 * Side-эффекты на события, связанные с обратной связью клиента.
 */
var FeedbackEffect = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Констрцктор  объекта обработки side-эффектов на события, связанные с обратной связью клиента.
     *
     * @param _actions$ Поток событий в системе.
     * @param _store Хранилище.
     * @param feedbackService Сервис для работы с обратной связи от клиентов.
     */
    function FeedbackEffect(_actions$, _store, feedbackService) {
        var _this = this;
        this._actions$ = _actions$;
        this._store = _store;
        //endregion
        //region Ctor
        /**
         * Обработка события отправки данных обратной связи.
         */
        this.send$ = this._actions$
            .pipe(ofType(FeedbackActionType.SEND), withLatestFrom(this._store.select(feedbackStateSelector)), switchMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this._feedbackService.send({
                type: state.type.id,
                documentId: state.documentId,
                comment: action.payload
            })
                .pipe(map(function (_) { return new FeedbackSendSuccessAction(); }), catchError(function (response) { return of(new FeedbackSendFailAction(response)); }));
        }));
        this._feedbackService = feedbackService;
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], FeedbackEffect.prototype, "send$", void 0);
    return FeedbackEffect;
}());
export { FeedbackEffect };
