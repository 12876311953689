var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { IntegrationActionType } from "src/app/spaces/modules/document-matching/store/actions/integration.actions";
/**
 * Начальное состояние интеграции.
 */
var initialState = {
    /**
     * Интеграция.
     */
    integration: null,
    /**
     * Не загружается.
     */
    loading: false,
    /**
     * Не загружена.
     */
    loaded: false,
    /**
     * Последняя загрузка интеграции не завершалась ошибкой.
     */
    error: null,
};
/**
 * Обработчик событий, связанных с изменением состояния интеграции.
 *
 * @param state Текущее состояние интеграции.
 * @param action Событие.
 *
 * @return Новое состояние.
 */
export function integrationReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        case IntegrationActionType.INIT: {
            result = initialState;
            break;
        }
        case IntegrationActionType.LOAD_INTEGRATION: {
            result = __assign({}, state, { loading: true, loaded: false, error: null });
            break;
        }
        case IntegrationActionType.LOAD_INTEGRATION_SUCCESS: {
            result = __assign({}, state, { integration: action.integration, loading: false, loaded: true });
            break;
        }
        case IntegrationActionType.LOAD_INTEGRATION_FAILED: {
            result = __assign({}, state, { loading: false, error: action.error });
            break;
        }
        case IntegrationActionType.UPDATE_INTEGRATION_SYNC_INFO: {
            result = __assign({}, state, { loading: true, loaded: false, error: null });
            break;
        }
        case IntegrationActionType.UPDATE_INTEGRATION_SYNC_INFO_SUCCESS: {
            result = __assign({}, state, { integration: __assign({}, state.integration, { lastSyncDate: action.integration && action.integration.lastSyncDate || null, settings: action.integration && action.integration.settings || null }), loading: false, loaded: true });
            break;
        }
        case IntegrationActionType.UPDATE_INTEGRATION_SYNC_INFO_FAILED: {
            result = __assign({}, state, { loading: false, error: action.error });
            break;
        }
    }
    return result;
}
