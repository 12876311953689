import { PurchaseDialogActionType } from "src/app/root/store/actions/purchase-dialog.action";
import { PurchaseDialogAction } from "src/app/root/store/actions/purchase-dialog.action";
import { PurchaseDialogState } from "src/app/root/store/states/purchase-dialog.state";

/**
 * Начальное состояние диалога о покупке страниц.
 */
export const initialState: PurchaseDialogState = {

    // Запрос не был успешно отправлен.
    requested: false,

    //В данный момент запрос не выполняется
    requesting: false,

    // Ошибки при загрузке нет.
    error: null,
};

/**
 * Обработка событий, связанных с состоянием диалога покупки страниц.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 */
export function purchaseDialogReducer(
    state = initialState,
    action: PurchaseDialogAction,
): PurchaseDialogState {

    switch (action.type) {

        // На событие открытия диалога.
        case PurchaseDialogActionType.OPEN_DIALOG: {

            return initialState;
        }

        // На событие отправки запроса на покупку страниц.
        case PurchaseDialogActionType.REQUEST_PURCHASE: {

            return {
                ...state,
                requesting: true,
                error: null,
            };
        }

        // На событие успешной отправки запроса на покупку страниц.
        case PurchaseDialogActionType.REQUEST_SUCCESS: {

            return {
                ...state,
                requesting: false,
                requested: true,
            };
        }

        // На событие не успешного запроса на покупку страниц
        case PurchaseDialogActionType.REQUEST_FAIL: {

            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        }
    }

    return state;
}
