var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import { UserService } from "src/app/common/services";
import { CurrentUserSettingsActionType } from "src/app/root/store/actions/current-user-settings.action";
import { SavingShowRecognitionTaskDialogFlagFailedAction } from "src/app/root/store/actions/current-user-settings.action";
import { ShowRecognitionTaskDialogFlagSavedSuccessfullyAction } from "src/app/root/store/actions/current-user-settings.action";
/**
 * Side-эффекты на события, связанные с настройками текущего пользователя.
 */
var CurrentUserSettingsEffect = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор класса side-эффектов на события, связанные с настройками текущего пользователя.
     *
     * @param userService Сервис с логикой для работы с пользователем.
     * @param _actions$ События, происходящие в системе.
     */
    function CurrentUserSettingsEffect(userService, _actions$) {
        var _this = this;
        this._actions$ = _actions$;
        //endregion
        //region Effects
        /**
         * Обработка события с требованием установить значение флага о необходимости показывать пользователю диалог,
         * в котором можно подписаться на получение уведомления о завершении только что созданной задачи на распознавание.
         */
        this.saveShowRecognitionTaskDialogFlag$ = this._actions$
            .pipe(ofType(CurrentUserSettingsActionType.SAVE_SHOW_DIALOG_FLAG), switchMap(function (action) {
            return _this._userService.setShowTaskCreatedDialogFlag(action.flag)
                .pipe(map(function () {
                return new ShowRecognitionTaskDialogFlagSavedSuccessfullyAction(action.flag);
            }), catchError(function (response) {
                return of(new SavingShowRecognitionTaskDialogFlagFailedAction(response));
            }));
        }));
        this._userService = userService;
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CurrentUserSettingsEffect.prototype, "saveShowRecognitionTaskDialogFlag$", void 0);
    return CurrentUserSettingsEffect;
}());
export { CurrentUserSettingsEffect };
