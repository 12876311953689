import { createSelector } from "@ngrx/store";
import { createFeatureSelector } from "@ngrx/store";
import { TrustedEmailsAdditionState } from "src/app/root/store/states/trusted-emails-addition.state";

/**
 * Селектор состояния добавления доверенных почт в пространства документов.
 */
const state = createFeatureSelector<TrustedEmailsAdditionState>("trustedEmailsAddition");

/**
 * Объект, объединяющий состояние запроса добавления доверенных почт в пространства документов.
 */
export const requestState = createSelector(
    state,
    (state: TrustedEmailsAdditionState) => state.addEmailsToSpaceRequest,
)

/**
 * Объект, объединяющий селекторы, которые относятся к состоянию данных добавления доверенных почт.
 */
export const trustedEmailsAdditionSelectors = {
    requestState,
};
