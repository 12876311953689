import { EventEmitter } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { map } from "rxjs/operators";
import { UtilsService } from "../../services/utils.service";
/**
 * ID элементов меню.
 */
var MenuItemId = {
    /**
     * Купить.
     */
    BUY: "buy-credits",
};
/**
 * Компонент выпадающего меню баланса пространства документов.
 */
var BalanceInfoMenuComponent = /** @class */ (function () {
    //endregion
    //region Ctor
    function BalanceInfoMenuComponent(translate, util) {
        this.translate = translate;
        this.util = util;
        //endregion
        //region Output
        /**
         * Исходящее событие - требование перейти на страницу оплаты.
         */
        this.buy = new EventEmitter();
        // Конфигурация элементов меню.
        this.menuItems = [];
        // TODO Вернуть, когда придёт время.
        // [
        //     {
        //         id: MenuItemId.BUY,
        //         icon: 'payment',
        //         messageKey: 'header.buy.credits',
        //         green: true
        //     }
        // ];
    }
    Object.defineProperty(BalanceInfoMenuComponent.prototype, "balance$", {
        //endregion
        //region Getters and Setters
        /**
         * Возвращает строковое представление баланса.
         */
        get: function () {
            var _this = this;
            var i18nKeyList = [
                "header.credits.word",
                "header.credits.endings",
                "header.credits.unlimited",
                "header.credits.until"
            ];
            return this.translate.get(i18nKeyList)
                .pipe(map(function (translate) {
                var value;
                if (typeof _this.restCreditCount === "string") {
                    value = _this.restCreditCount;
                }
                else {
                    value = _this.restCreditCount + " " + _this.util.getWordWithEnding(translate["header.credits.word"], translate["header.credits.endings"], _this.restCreditCount);
                }
                var date;
                if (_this.foreign) {
                    date = _this.util.getISODate(_this.expirationDate) || "-";
                }
                else {
                    date = _this.util.formatDate(_this.expirationDate, _this.translate.getDefaultLang());
                }
                return value + " " + translate["header.credits.until"] + " " + date;
            }));
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Возвращает текст тултипа о том, что истекает подписка или баланс.
     */
    BalanceInfoMenuComponent.prototype.getExpiryNotificationTooltipText = function () {
        var managerTextEnding = this.translate.instant("header.notification.manager");
        var result = "";
        if (this.expiryNotificationType === "BALANCE") {
            result = this.translate.instant("header.notification.balanceExpiring") + " " + managerTextEnding;
        }
        else if (this.expiryNotificationType === "SUBSCRIPTION" || !this.expiryNotificationType) {
            result = this.translate.instant("header.notification.subscriptionExpiring") + " " + managerTextEnding;
        }
        return result;
    };
    //endregion
    //region Events
    /**
     * Обработчик клика по элементу меню.
     */
    BalanceInfoMenuComponent.prototype.menuItemClickHandler = function (menuItemId) {
        // Бросаем нужное событие выше в зависимости от выбранного элемента меню.
        switch (menuItemId) {
            case MenuItemId.BUY: {
                this.buy.emit();
            }
        }
    };
    return BalanceInfoMenuComponent;
}());
export { BalanceInfoMenuComponent };
