import { RouteState } from "src/app/root/store/reducers/router.reducer";

/*
 * Состояние отключения фильтров в реестре документов.
 */
export class DocumentsRegistryDisabledFiltersState {
    //region Ctor

    constructor(
        /**
         * Фильтр по статусам документов отключён?
         */
        public documentStateDisabled: boolean = false
    ) { }

    //endregion
    //region Static

    /**
     * На основе состояния URL формирует состосяние отключения фильтров в реестре документов.
     * 
     * @param routeState Состояние URL'а.
     */
    static fromUrl(routeState: RouteState): DocumentsRegistryDisabledFiltersState {

        const state = new DocumentsRegistryDisabledFiltersState();

        // TODO Хардкод части URL'а.
        if (routeState.path.endsWith('completed')) {

            state.documentStateDisabled = true;
        }

        return state;
    } 

    //endregion
}