import { OnDestroy } from "@angular/core";
import { Inject } from "@angular/core";
import { HostListener } from "@angular/core";
import { OnInit } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog";

import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { distinct } from "rxjs/operators";
import { map } from "rxjs/operators";
import { ApiResponse } from "src/app/common/models";
import { DlgService } from "src/app/common/services";
import { Feedback } from "src/app/root/models";
import { FeedbackInitAction } from "src/app/root/store/actions/feedback.action";
import { FeedbackSendAction } from "src/app/root/store/actions/feedback.action";
import { feedbackStateSelector } from "src/app/root/store/reducers";
import { RootState } from "src/app/root/store/reducers";
import { FeedbackType } from "src/app/root/models";
import { FeedbackState } from "src/app/root/store/reducers/feedback/feedback.state";

/**
 * Комонент диалога обратной связи пользователя.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "entera-feedback-dlg",
    templateUrl: "./feedback-dlg.component.html",
    styleUrls: ["./feedback-dlg.component.scss"],
})
export class FeedbackDlgComponent implements OnInit, OnDestroy {
    //region Fields

    /**
     * Тип обратной связи.
     */
    readonly type: FeedbackType;

    /**
     * Диалог находится в процессе отпраки?
     */
    readonly sending$: Observable<boolean>;

    /**
     * Контроль подписок.
     */
    readonly _subscriptionControl$: Subject<boolean> = new Subject();

    /**
     * Сервис для управления и доступа к состоянию приложения.
     */
    private readonly _store: Store<RootState>;

    /**
     * Диалог, в котором открывается текущий компонент.
     */
    private readonly _dialogRef: MatDialogRef<FeedbackDlgComponent>;

    /**
     * Сервис простых далогов.
     */
    private _dlgService: DlgService;

    //endregion
    //region Ctor

    /**
     * Конструктор компонента диалога обратной связи пользователя.
     *
     * @param store Сервис для управления и доступа к состоянию приложения.
     * @param dialogRef Диалог, в котором открывается текущий компонент.
     * @param data Тип обратной связи.
     * @param dlgService Сервис простых далогов.
     */
    constructor(
        store: Store<RootState>,
        dialogRef: MatDialogRef<FeedbackDlgComponent>,
        @Inject(MAT_DIALOG_DATA) data: Feedback,
        dlgService: DlgService
    ) {
        this._store = store;
        this._dialogRef = dialogRef;
        this.type = data.type as FeedbackType;
        this._dlgService = dlgService;
        this.sending$ = this._store.select(feedbackStateSelector)
            .pipe(
                map((state: FeedbackState) => state.sending),
                distinct()
            );
        this._store.dispatch(new FeedbackInitAction(data));
    }

    //endregion
    //region Hooks

    ngOnInit(): void {

        this._dialogRef.disableClose = true;
        this._store.select(feedbackStateSelector)
            .pipe(
                map((state: FeedbackState) => state.sent),
                distinct(),
                filter(Boolean),
                takeUntil(this._subscriptionControl$)
            )
            .subscribe(() => this._dialogRef.close());
        this._store.select(feedbackStateSelector)
            .pipe(
                map((state: FeedbackState) => state.error),
                distinct(),
                filter(Boolean),
                takeUntil(this._subscriptionControl$)
            )
            .subscribe((error: ApiResponse) => {

                if (!error.errorCode) {

                    error = {
                        ...error,
                        errorCode: "-"
                    };
                }

                this._dialogRef.close();
                this._dlgService.openSimpleDlg({
                    headerKey: "errorDetails.somethingGoesWrong",
                    textKey: "feedback.error",
                    textParams: error
                });
            });
    }

    ngOnDestroy(): void {

        this._subscriptionControl$.complete();
    }



    //endregion
    //region Events

    /**
     * Обработчик нажатия на Esc.
     *
     * Закрывает диалог.
     */
    @HostListener("window:keyup.esc")
    onKeyUp() {

        this._dialogRef.close();
    }

    /**
     * Обработчик события отправки обратной связи.
     *
     * @param comment Комметарий пользователя.
     */
    sendHandler(comment: string) {

        this._store.dispatch(new FeedbackSendAction(comment));
    }

    //endregion
}
