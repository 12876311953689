import { HeaderActionType, HeaderAction } from '../actions/header.action';

/**
 * Состояние заголовка.
 */
export interface HeaderState {

    // Заголовок отображен?
    visible: boolean;

    // Заголовок скрыт?
    hidden: boolean;

    // Скрыт выбор пространств.
    showSpacesInfo: boolean;

    /**
     * Отображение кнопки "How to start".
     */
    howToStartButtonVisibility: boolean;
}

/**
 * Начальное состояние по данным текущего пользователя.
 */
export const initialState: HeaderState = {

    // Заголовок не виден.
    visible: false,

    // Заголовок скрыт.
    hidden: true,

    // Выбор простарнтсва показывается
    showSpacesInfo: true,

    howToStartButtonVisibility: true,
};

/**
 * Обработка состояния заголовка в ответ на события.
 * 
 * @param state Текущее состояние.
 * @param action Событие.
 */
export function headerReducer(state = initialState, action: HeaderAction): HeaderState {

    switch (action.type) {

        // На событие скрытия заголовка.
        case HeaderActionType.HIDE: {

            return {
                ...state,
                visible: false,
                hidden: true
            };
        }

        // На событие отображения заголовка.
        case HeaderActionType.SHOW: {

            return {
                ...state,
                visible: true,
                hidden: false
            };
        }

        case HeaderActionType.HIDE_SPACES_INFO: {
            return {
                ...state,
                showSpacesInfo: false
            };
        }

        case HeaderActionType.SHOW_SPACES_INFO: {
            return {
                ...state,
                showSpacesInfo: true
            };
        }

        case HeaderActionType.HIDE_HOW_TO_START_BUTTON: {
            return {
                ...state,
                howToStartButtonVisibility: false
            };
        }

        case HeaderActionType.SHOW_HOW_TO_START_BUTTON: {
            return {
                ...state,
                howToStartButtonVisibility: true
            };
        }
    }

    return state;
}

/**
 * Возвращает флаг видимости заголовка сайта.
 * 
 * @param state Состояние данных о заголовке сайта.
 */
export const getHeaderVisible = (state: HeaderState) => state.visible;

/**
 * Возвращает флаг скрытости заголовка сайта.
 * 
 * @param state Состояние данных о заголовке сайта.
 */
export const getHeaderHidden = (state: HeaderState) => state.hidden;
