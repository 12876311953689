import { Component } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Store } from "@ngrx/store";
import { RootState } from "rootStore";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { sendToExternalSystemStateSelector } from "src/app/spaces/modules/documents-registry/store/selectors/send-to-external-system.selector";
import { SendToExternalSystemState } from "src/app/spaces/modules/documents-registry/store/states/send-to-external-system.state";
import { ApiResponse } from "../../../common/models";

/**
 * Компонент диалога отправки документов во внешнюю систему.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "send-to-external-system-dlg",
    templateUrl: "./send-to-external-system-dlg.component.html",
    styleUrls: ["./send-to-external-system-dlg.component.scss"],
})
export class SendToExternalSystemDlgComponent {
    // region Fields

    /**
     * Диалог находится в процессе отправки?
     */
    readonly loadingDocs$: Observable<boolean>;

    /**
     * Состояние диалога - ошибка.
     */
    readonly error$: Observable<ApiResponse>;

    /**
     * Состояние диалога - все хорошо.
     */
    readonly okState$: Observable<boolean>;

    //region Private

    /**
     * Сервис для управления и доступа к состоянию приложения.
     */
    private readonly _store: Store<RootState>;

    //endregion
    //region Ctor

    /**
     * <p>Конструктор компонента создания диалога отправки документов во внешнюю систему.</p>
     *
     * @param store Сервис для управления и доступа к состоянию приложения.
     */
    constructor(
        store: Store<RootState>,
    ) {
        this._store = store;

        this.loadingDocs$ = this._store.select(sendToExternalSystemStateSelector)
            .pipe(
                map((state: SendToExternalSystemState): boolean => state.sending),
            );

        this.error$ = this._store.select(sendToExternalSystemStateSelector)
            .pipe(
                map((state: SendToExternalSystemState): ApiResponse => state.error),
            );

        this.okState$ = this._store.select(sendToExternalSystemStateSelector)
            .pipe(
                map((state: SendToExternalSystemState): boolean => state.sent),
            );
    }

    //endregion

}
