import { Injectable } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { withLatestFrom } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { clientControlPageActions } from "src/app/admin/store/actions/client-control-page.action";
import { ClientControlPageStateSelectors } from "src/app/admin/store/selectors/client-control-page.selectors";
import { DlgCreationData } from "src/app/common/models/dlg-creation-data";
import { getRequestResult } from "src/app/common/models/request-state";
import { Space } from "src/app/common/models/space";
import { SpaceIdsAndEmails } from "src/app/common/models/space-ids-and-emails.model";
import { DlgService } from "src/app/common/services/dlg.service";
import { UserSpacesDlgComponent } from "src/app/root/containers/users-spaces-dlg/user-spaces-dlg.component";
import { trustedEmailsAdditionActions } from "src/app/root/store/actions/trusted-emails-addition.action";
import { usersInvitationActions } from "src/app/root/store/actions/users-invitation.action";
import { RootState } from "src/app/root/store/reducers/index";
import { currentUserSelectors } from "src/app/root/store/selectors/current-user.selector";
import { trustedEmailsAdditionSelectors } from "src/app/root/store/selectors/trusted-email-addition.selectors";
import { usersInvitationSelectors } from "src/app/root/store/selectors/users-invitation.selectors";

/**
 * Сервис для работы с диалогами с почтами и папками.
 */
@Injectable({
    providedIn: "root",
})
export class EmailsAndSpacesDlgService {
    //region Fields

    /**
     * Сервис для работы с диалогами.
     */
    private readonly _dlgService: DlgService;

    //endregion
    //region Ctor

    /**
     * Конструктор сервиса для работы с диалогом приглашения пользователей в пространства документов.
     *
     * @param _store Состояние приложения.
     * @param dlgService Сервис для работы с диалогами.
     */
    constructor(
        private _store: Store<RootState>,
        dlgService: DlgService,
    ) {
        this._dlgService = dlgService;
    }

    //endregion
    //region Public

    /**
     * Открывает диалог для приглашения пользователей в пространства документов.
     *
     * В зависимости от флага:
     * - предоставляет списки папок, доступных для приглашения
     * - требует/не требует обновления списка пользователей в админском интерфейсе.
     *
     *
     * @param asAdmin Флаг, который указывает, в каком интерфейсе открыт диалог.
     * @param preSelectedSpaces Список предварительно выбранных папок текущего пользователя.
     */
    openInvitationDlg(asAdmin: boolean, preSelectedSpaces: Space[] = []): void {

        let dlg: MatDialogRef<UserSpacesDlgComponent>;
        let spacesSubscription: Subscription;
        let requestStateSubscription: Subscription;

        const dlgData: DlgCreationData = {
            headerKey: "dialogs.usersInvitation.title",
            okBtnKey: "dialogs.usersInvitation.applyButton",
            errorDescriptionKey: "dialogs.usersInvitation.errorDesc",
            successDescriptionKey: "dialogs.usersInvitation.success",
            openCallback: () => {

                this._store.dispatch(usersInvitationActions.initialize());

                if (asAdmin) {

                    spacesSubscription = this._store.select(ClientControlPageStateSelectors.clientSpaceList)
                        .pipe(
                            filter(Boolean),
                        )
                        .subscribe((spaces: Space[]) => dlg.componentInstance.spaces = spaces);
                }
                else {

                    spacesSubscription = this._store.select(currentUserSelectors.spacesForInvitations)
                        .pipe(
                            filter(Boolean),
                        )
                        .subscribe((spaces: Space[]) => dlg.componentInstance.spaces = spaces);
                }

                dlg.componentInstance.preSelectedSpaces = preSelectedSpaces;

                requestStateSubscription = this._store.select(usersInvitationSelectors.requestState)
                    .pipe(
                        filter(Boolean),
                    )
                    .subscribe(state => {

                        dlg.componentInstance.requestState = state;

                        if (getRequestResult(state) && asAdmin) {

                            this._store.dispatch(clientControlPageActions.updateUsersInfo(getRequestResult(state)));
                        }
                    });
            },
            confirmCallback: (data: SpaceIdsAndEmails) => {

                this._store.dispatch(usersInvitationActions.invite({value: data}));
            },
            closeCallback: () => dlg.close(),
            destroyCallback: () => {

                spacesSubscription.unsubscribe();
                requestStateSubscription.unsubscribe();
            }
        };

        dlg = this._dlgService.openEmailsAndSpacesDlg(dlgData);
    }

    /**
     * Открывает диалог для добавления доверенных почт в пространства документов.
     */
    openTrustedEmailsAdditionDlg(): void {

        let dlg: MatDialogRef<UserSpacesDlgComponent>;
        let spacesSubscription: Subscription;
        let requestStateSubscription: Subscription;

        const dlgData: DlgCreationData = {
            headerKey: "dialogs.trustedEmailsAddition.title",
            okBtnKey: "dialogs.trustedEmailsAddition.applyButton",
            errorDescriptionKey: "dialogs.trustedEmailsAddition.errorDesc",
            successDescriptionKey: "dialogs.trustedEmailsAddition.success",
            openCallback: () => {

                spacesSubscription = this._store.select(ClientControlPageStateSelectors.clientSpaceList)
                    .pipe(
                        filter(Boolean),
                    )
                    .subscribe((spaces: Space[]) => dlg.componentInstance.spaces = spaces);

                requestStateSubscription = this._store.select(trustedEmailsAdditionSelectors.requestState)
                    .pipe(
                        filter(Boolean),
                        withLatestFrom(this._store.select(currentUserSelectors.canSeeTrustedEmails)),
                    )
                    .subscribe(([state, canSeeTrustedEmails]) => {

                        dlg.componentInstance.requestState = state;

                        if (getRequestResult(state) && canSeeTrustedEmails) {

                            this._store.dispatch(
                                clientControlPageActions.updateTrustedEmailsInfo(getRequestResult(state))
                            );
                        }
                    });
                this._store.dispatch(trustedEmailsAdditionActions.initialize());
            },
            confirmCallback: (data: SpaceIdsAndEmails) => {

                this._store.dispatch(trustedEmailsAdditionActions.add({value: data}));
            },
            closeCallback: () => dlg.close(),
            destroyCallback: () => {

                spacesSubscription.unsubscribe();
                requestStateSubscription.unsubscribe();
            }
        };

        dlg = this._dlgService.openEmailsAndSpacesDlg(dlgData);
    }

    //endregion
}
