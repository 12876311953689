import { EventEmitter } from '@angular/core';
import { UserService } from '../../services/user.service';
/**
 * ID элементов меню.
 */
var MenuItemId = {
    /**
     * Личный кабинет.
     */
    USER_ACCOUNT: 'user-account',
    /**
     * Выход.
     */
    LOGOUT: 'logout',
};
/**
 * Компонент выпадающего меню текущего пользователя.
 */
var UserInfoMenuComponent = /** @class */ (function () {
    //endregion
    //region Ctor
    function UserInfoMenuComponent(userService) {
        this.userService = userService;
        /**
         * Исходящее событие - требование перейти в личный кабинет пользователя.
         */
        this.userAccount = new EventEmitter();
        /**
         * Исходящее событие - требование выйти из системы.
         */
        this.logout = new EventEmitter();
        // Конфигурация элементов меню: личный кабинет и выход.
        this.menuItems = [
            // TODO Вернуть, когда придёт время.
            // {
            //     id: MenuItemId.USER_ACCOUNT,
            //     icon: 'account_circle',
            //     messageKey: 'user.account.cabinet'
            // },
            {
                id: MenuItemId.LOGOUT,
                icon: 'exit_to_app',
                messageKey: 'logout'
            }
        ];
    }
    Object.defineProperty(UserInfoMenuComponent.prototype, "userName", {
        //endregion
        //region Getters and Setters
        /**
         * Возвращает имя текущего пользователя.
         */
        get: function () {
            return this.userService.getUserName(this.user);
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Events
    /**
     * Обработчик клика по элементу меню.
     */
    UserInfoMenuComponent.prototype.menuItemClickHandler = function (menuItemId) {
        // Бросаем нужное событие выше в зависимости от выбранного элемента меню.
        switch (menuItemId) {
            case MenuItemId.LOGOUT: {
                this.logout.emit();
            }
            case MenuItemId.USER_ACCOUNT: {
                this.userAccount.emit();
            }
        }
    };
    return UserInfoMenuComponent;
}());
export { UserInfoMenuComponent };
