import { requestState } from "src/app/common/models/request-state";
import { DocumentTypesActionType } from "src/app/root/store/actions/document-types.action";
import { DocumentTypesAction } from "src/app/root/store/actions/document-types.action";
import { DocumentTypesState } from "src/app/root/store/states/document-types.state";

/**
 * Начальное состояние типов документов.
 */
const initialState: DocumentTypesState = {

    documentTypesRequest: requestState.initial(),
}

/**
 * Обработчик событий, связанных с типами документов.
 * 
 * @param state Состояние типов документов.
 * @param action Событие произошедшее в системе.
 */
export function documentTypesReducer(state = initialState, action: DocumentTypesAction): DocumentTypesState {

    let result = state;

    switch(action.type) {

        case DocumentTypesActionType.LOAD: {

            result = {
                ...state,
                documentTypesRequest: requestState.pending(),
            };

            break;
        }

        case DocumentTypesActionType.LOAD_SUCCESS: {

            result = {
                ...state,
                documentTypesRequest: requestState.success(action.value),
            };

            break;
        }

        case DocumentTypesActionType.LOAD_FAIL: {

            return {
                ...state,
                documentTypesRequest: requestState.fail(action.error),
            };

            break;
        }
    }

    return result;
}
