/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-spaces-dlg.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i9 from "@angular/material/icon";
import * as i10 from "../../components/users-invitation/users-and-spaces-input.component.ngfactory";
import * as i11 from "../../components/users-invitation/users-and-spaces-input.component";
import * as i12 from "@angular/common";
import * as i13 from "./user-spaces-dlg.component";
import * as i14 from "@angular/material/dialog";
var styles_UserSpacesDlgComponent = [i0.styles];
var RenderType_UserSpacesDlgComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserSpacesDlgComponent, data: {} });
export { RenderType_UserSpacesDlgComponent as RenderType_UserSpacesDlgComponent };
function View_UserSpacesDlgComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "mat-dialog__cross-close-btn"], ["mat-flat-button", ""]], [[8, "title", 0], [8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialogClickHandler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i9.MatIcon, [i1.ElementRef, i9.MatIconRegistry, [8, null], [2, i9.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"]))], function (_ck, _v) { _ck(_v, 4, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 2).transform("dialogs.close")), ""); var currVal_1 = (i1.ɵnov(_v, 1).disabled || null); var currVal_2 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵnov(_v, 4).inline; var currVal_4 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_3, currVal_4); }); }
export function View_UserSpacesDlgComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "users-spaces-dlg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "users-and-spaces-input", [], null, [[null, "confirmData"], [null, "closeDialog"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("confirmData" === en)) {
        var pd_0 = (_co.confirmDlgDataHandler($event) !== false);
        ad = (pd_0 && ad);
    } if (("closeDialog" === en)) {
        var pd_1 = (_co.closeDialog() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i10.View_UsersAndSpacesInputComponent_0, i10.RenderType_UsersAndSpacesInputComponent)), i1.ɵdid(2, 180224, null, 0, i11.UsersAndSpacesInputComponent, [], { spaces: [0, "spaces"], preSelectedSpaces: [1, "preSelectedSpaces"], requestLoading: [2, "requestLoading"], requestError: [3, "requestError"], requestSuccess: [4, "requestSuccess"], headerKey: [5, "headerKey"], okBtnKey: [6, "okBtnKey"], errorDescriptionKey: [7, "errorDescriptionKey"], successDescriptionKey: [8, "successDescriptionKey"] }, { confirmData: "confirmData", closeDialog: "closeDialog" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserSpacesDlgComponent_1)), i1.ɵdid(4, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.spaces; var currVal_1 = _co.preSelectedSpaces; var currVal_2 = (((_co.requestState == null) ? null : _co.requestState.type) === "pending"); var currVal_3 = (((((_co.requestState == null) ? null : _co.requestState.type) === "fail") && _co.requestState.error) || null); var currVal_4 = (((_co.requestState == null) ? null : _co.requestState.type) === "success"); var currVal_5 = _co.headerKey; var currVal_6 = _co.okBtnKey; var currVal_7 = _co.errorDescriptionKey; var currVal_8 = _co.successDescriptionKey; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = (((_co.requestState == null) ? null : _co.requestState.type) !== "pending"); _ck(_v, 4, 0, currVal_9); }, null); }
export function View_UserSpacesDlgComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "user-spaces-dlg", [], null, [["window", "keyup.esc"]], function (_v, en, $event) { var ad = true; if (("window:keyup.esc" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onKeyUp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_UserSpacesDlgComponent_0, RenderType_UserSpacesDlgComponent)), i1.ɵdid(1, 245760, null, 0, i13.UserSpacesDlgComponent, [i1.ChangeDetectorRef, i14.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserSpacesDlgComponentNgFactory = i1.ɵccf("user-spaces-dlg", i13.UserSpacesDlgComponent, View_UserSpacesDlgComponent_Host_0, { spaces: "spaces", preSelectedSpaces: "preSelectedSpaces", requestState: "requestState" }, {}, []);
export { UserSpacesDlgComponentNgFactory as UserSpacesDlgComponentNgFactory };
