/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mobile-document-upload-file-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i8 from "@angular/material/icon";
import * as i9 from "@ngx-translate/core";
import * as i10 from "./mobile-document-upload-file-button.component";
var styles_MobileDocumentUploadFileButtonComponent = [i0.styles];
var RenderType_MobileDocumentUploadFileButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MobileDocumentUploadFileButtonComponent, data: {} });
export { RenderType_MobileDocumentUploadFileButtonComponent as RenderType_MobileDocumentUploadFileButtonComponent };
export function View_MobileDocumentUploadFileButtonComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { input: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "button", [["class", "mobile-document-upload-file-button"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null], [2, i8.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["add_circle_outline"])), (_l()(), i1.ɵted(6, 0, [" ", "\n"])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, [[1, 0], ["uploadInput", 1]], null, 0, "input", [["accept", ".jpeg,.jpg,.png,.tiff,.tif,.bmp,.pdf,.doc,.docx,.odt,.xls,.xlsx,.rtf,.ods"], ["class", "mobile-document-upload-file-button__input"], ["multiple", ""], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onChange() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.loading; _ck(_v, 2, 0, currVal_2); _ck(_v, 4, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 4).inline; var currVal_4 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("common.upload-to-recognize-dialog.buttons.select-file")); _ck(_v, 6, 0, currVal_5); }); }
export function View_MobileDocumentUploadFileButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mobile-document-upload-file-button", [], null, null, null, View_MobileDocumentUploadFileButtonComponent_0, RenderType_MobileDocumentUploadFileButtonComponent)), i1.ɵdid(1, 49152, null, 0, i10.MobileDocumentUploadFileButtonComponent, [], null, null)], null, null); }
var MobileDocumentUploadFileButtonComponentNgFactory = i1.ɵccf("mobile-document-upload-file-button", i10.MobileDocumentUploadFileButtonComponent, View_MobileDocumentUploadFileButtonComponent_Host_0, { loading: "loading", error: "error" }, { addFiles: "addFiles" }, []);
export { MobileDocumentUploadFileButtonComponentNgFactory as MobileDocumentUploadFileButtonComponentNgFactory };
