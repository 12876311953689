import { createSelector } from '@ngrx/store';

import { commonModuleStateSelector } from "../reducers";
import { CommonModuleState } from "../reducers";
import { PhoneConfirmDlgState } from "../reducers";

/**
 * Селектор состояния диалога подтверждения телефона.
 */
export const phoneConfirmDlgStateSelector = createSelector(
    commonModuleStateSelector,
    (state: CommonModuleState) => state.confirmPhoneDlgState
);

/**
 * Селектор состояния успешной отправки СМС на телефон.
 */
export const phoneConfirmDlgSmsSentSelector = createSelector(
    phoneConfirmDlgStateSelector,
    (state: PhoneConfirmDlgState) => state.smsSent
);

/**
 * Селектор состояния отправки СМС на телефон в данный момент.
 */
export const phoneConfirmDlgSmsSendingSelector = createSelector(
    phoneConfirmDlgStateSelector,
    (state: PhoneConfirmDlgState) => state.smsSending
);

/**
 * Селектор состояния ошибки отправки СМС на телефон.
 */
export const phoneConfirmDlgSmsSentErrorSelector = createSelector(
    phoneConfirmDlgStateSelector,
    (state: PhoneConfirmDlgState) => state.smsSentError
);

/**
 * Селектор состояния успешного подтверждения телефона через код.
 */
export const phoneConfirmDlgCodeConfirmSelector = createSelector(
    phoneConfirmDlgStateSelector,
    (state: PhoneConfirmDlgState) => state.codeConfirm
);

/**
 * Селектор состояния подтверждения телефона через код в код в данный момент.
 */
export const phoneConfirmDlgCodeConfirmingSelector = createSelector(
    phoneConfirmDlgStateSelector,
    (state: PhoneConfirmDlgState) => state.codeConfirming
);

/**
 * Селектор состояния ошибки подтверждения телефона через код..
 */
export const phoneConfirmDlgCodeConfirmErrorSelector = createSelector(
    phoneConfirmDlgStateSelector,
    (state: PhoneConfirmDlgState) => state.codeConfirmError
);
