import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { tap } from 'rxjs/operators';

import { RootState } from '../../root/store';
import { RouterGoAction } from '../../root/store';
import { currentUserInfoSelector } from '../../root/store';
import { RouteService } from 'src/app/common/services/route.service';

/**
 * Route Guard.
 * Логика обработки попытки входа по рутовому пути '/' в зависимости от количества доступных пользователю
 * пространств документов.
 * 
 * Должен использоваться совместо с UserGuard, который инициирует загрузку данных пользователя.
 */
@Injectable({
    providedIn: 'root'
})
export class RootPathGuard implements CanActivate {
    //region Ctor

    constructor(
        private store: Store<RootState>,
        private routeService: RouteService
    ) { }

    //endregion
    //region Public

    canActivate(): Observable<boolean> {

        return this.store
            .pipe(
                select(currentUserInfoSelector),

                // Логика будет выполняться только в случае наличия данных о пользователе.
                filter(currentUser => !!currentUser),

                // В зависимости от количества доступных пользователю пространств документов решается куда 
                // его направить.
                tap((currentUser) => {

                    const actionPayload = this.routeService.getUserSpacesInitRoute(currentUser);
                    setTimeout(() => this.store.dispatch(new RouterGoAction(actionPayload)));
                }),

                // По факту в любом варианте будет редирект, поэтому здесь всегда возвращается true.
                switchMap(() => of(true))
            );
    }

    //endregion
}
