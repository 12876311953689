/**
 * Тип обратной связи.
 */
var FeedbackType = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор типа обратной свзязи пользователя.
     *
     * @param id Идентификатор типа обратной связи.
     */
    function FeedbackType(id) {
        this.id = id;
    }
    Object.defineProperty(FeedbackType.prototype, "titleKey", {
        //endregion
        //region Getter/Setters
        /**
         * Возвращает ключ интернализации для заголовка диалога по этому типу обратной связи.
         */
        get: function () {
            return "feedback.title." + this.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FeedbackType.prototype, "commentKey", {
        /**
         * Возвращает ключ интернализации для подсказки в диалоге по этому типу обратной связи.
         */
        get: function () {
            return "feedback.comment." + this.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FeedbackType.prototype, "placeholderKey", {
        /**
         * Возвращает ключ интернализации для подсказки в поле комментария в диалоге по этому типу обратной связи.
         */
        get: function () {
            return "feedback.placeholder." + this.id;
        },
        enumerable: true,
        configurable: true
    });
    //region Values
    /**
     * Проблемы документа.
     */
    FeedbackType.DOCUMENT_PROBLEMS = new FeedbackType("DOCUMENT_PROBLEMS");
    /**
     * Обратный звонок для пользователя.
     */
    FeedbackType.CALL_BACK = new FeedbackType("CALL_BACK");
    return FeedbackType;
}());
export { FeedbackType };
