import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';
import { of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { RouterGoAction } from '../../root/store';
import { currentUserInfoSelector } from '../../root/store';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
/**
 * Route Guard.
 * Только что зарегистрировавшийся пользователь (кроме того, которого пригласили), у которого ещё нет
 * ни одного пространства документов, должен всегда попадать на страницу создания первого пространства
 * документов.
 *
 * Должен использоваться совместо с UserGuard, который инициирует загрузку данных пользователя.
 */
var NewUserGuard = /** @class */ (function () {
    //region Ctor
    function NewUserGuard(_store) {
        this._store = _store;
    }
    //endregion
    //region Public
    NewUserGuard.prototype.canActivate = function () {
        var _this = this;
        return this._store
            .pipe(select(currentUserInfoSelector), 
        // Логика будет выполняться только в случае наличия данных о пользователе.
        filter(function (currentUser) { return !!currentUser; }), 
        // Новых пользователей редиректим на страницу создания первого пространства документов.
        tap(function (currentUser) {
            if (currentUser.isNew) {
                // TODO Хардкод URL
                setTimeout(function () { return _this._store.dispatch(new RouterGoAction({ path: ['welcome'] })); });
            }
        }), switchMap(function (currentUser) { return of(!currentUser.isNew); }));
    };
    NewUserGuard.prototype.canActivateChild = function () {
        return this.canActivate();
    };
    NewUserGuard.ngInjectableDef = i0.defineInjectable({ factory: function NewUserGuard_Factory() { return new NewUserGuard(i0.inject(i1.Store)); }, token: NewUserGuard, providedIn: "root" });
    return NewUserGuard;
}());
export { NewUserGuard };
