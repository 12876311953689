/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./server-side-error-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../error-details/error-details.component.ngfactory";
import * as i3 from "../error-details/error-details.component";
import * as i4 from "@angular/common";
import * as i5 from "./server-side-error-details.component";
import * as i6 from "@angular/material/dialog";
import * as i7 from "@ngx-translate/core";
var styles_ServerSideErrorDetailsComponent = [i0.styles];
var RenderType_ServerSideErrorDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ServerSideErrorDetailsComponent, data: {} });
export { RenderType_ServerSideErrorDetailsComponent as RenderType_ServerSideErrorDetailsComponent };
export function View_ServerSideErrorDetailsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "server-side-error-details"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "error-details", [["errorCode", "500"]], null, [[null, "goToRoot"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("goToRoot" === en)) {
        var pd_0 = (_co.goToRootHandler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ErrorDetailsComponent_0, i2.RenderType_ErrorDetailsComponent)), i1.ɵdid(2, 114688, null, 0, i3.ErrorDetailsComponent, [], { errorCode: [0, "errorCode"], errorText: [1, "errorText"], hideGoToBtn: [2, "hideGoToBtn"], errors: [3, "errors"] }, { goToRoot: "goToRoot" }), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "500"; var currVal_1 = i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 3).transform(_co.errorText$)); var currVal_2 = true; var currVal_3 = _co.errors; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_ServerSideErrorDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "server-side-error-details", [], null, null, null, View_ServerSideErrorDetailsComponent_0, RenderType_ServerSideErrorDetailsComponent)), i1.ɵdid(1, 114688, null, 0, i5.ServerSideErrorDetailsComponent, [i6.MatDialogRef, i6.MAT_DIALOG_DATA, i7.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ServerSideErrorDetailsComponentNgFactory = i1.ɵccf("server-side-error-details", i5.ServerSideErrorDetailsComponent, View_ServerSideErrorDetailsComponent_Host_0, { errors: "errors" }, {}, []);
export { ServerSideErrorDetailsComponentNgFactory as ServerSideErrorDetailsComponentNgFactory };
