var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions } from '@ngrx/effects';
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { CurrentUserConfirmPhoneSuccessAction } from "rootStore";
import { of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { UserService } from "../../services";
import { PhoneConfirmDlgActionType } from "../actions";
import { PhoneConfirmDialogSendSmsSuccessAction } from "../actions";
import { PhoneConfirmDialogSendSmsFailAction } from "../actions";
import { PhoneConfirmDialogConfirmCodeSuccessAction } from "../actions";
import { PhoneConfirmDialogConfirmCodeFailAction } from "../actions";
/**
 * Side-эффекты на события, связанные с диалогом подтверждения телефона.
 */
var PhoneConfirmDlgEffects = /** @class */ (function () {
    //region Ctor
    /**
     * Контруктор эффектов связанных с диалогом подтверждения телефона.
     *
     * @param _actions$ Поток событий, происходящих в системе.
     * @param _userService Сервис работы с данными пользователя.
     * @param _store Хранилище.
     */
    function PhoneConfirmDlgEffects(_actions$, _userService, _store) {
        var _this = this;
        this._actions$ = _actions$;
        this._userService = _userService;
        this._store = _store;
        //endregion
        //region Public
        /**
         * Обработка события отправки СМС с кодом подтверждения.
         */
        this.sendSms$ = this._actions$
            .pipe(ofType(PhoneConfirmDlgActionType.SEND_SMS), switchMap(function (action) {
            return _this._userService.sendConfirmSms(action.payload)
                .pipe(map(function () { return new PhoneConfirmDialogSendSmsSuccessAction(); }), catchError(function (response) { return of(new PhoneConfirmDialogSendSmsFailAction(response)); }));
        }));
        /**
         * Обработка события подтверждения телефона отправкой кода на сервер.
         */
        this.confirmCode$ = this._actions$
            .pipe(ofType(PhoneConfirmDlgActionType.CONFIRM_CODE), switchMap(function (action) {
            return _this._userService.confirmPhoneByCode(action.payload.code)
                .pipe(map(function () { return new PhoneConfirmDialogConfirmCodeSuccessAction(__assign({}, action.payload, { code: null })); }), catchError(function (response) { return of(new PhoneConfirmDialogConfirmCodeFailAction(response)); }));
        }));
        /**
         * Обработка события успешного подтверждения телефона отправкой кода на сервер.
         */
        this.confirmCodeSuccess$ = this._actions$
            .pipe(ofType(PhoneConfirmDlgActionType.CONFIRM_CODE_SUCCESS), switchMap(function (action) {
            return of(new CurrentUserConfirmPhoneSuccessAction(action.payload));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], PhoneConfirmDlgEffects.prototype, "sendSms$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], PhoneConfirmDlgEffects.prototype, "confirmCode$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], PhoneConfirmDlgEffects.prototype, "confirmCodeSuccess$", void 0);
    return PhoneConfirmDlgEffects;
}());
export { PhoneConfirmDlgEffects };
