var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { MatchingActionType } from "src/app/spaces/modules/document-matching/store/actions/matching.actions";
import * as _ from "underscore";
import { isBillWiseDetailType } from "src/app/spaces/modules/document-matching/models/ledger-bill";
/**
 * Начальное состояние сопоставления документов.
 */
var initialState = {
    /**
     * Документы находятся в процессе создания?
     */
    creatingDocuments: false,
    /**
     * Документы созданы?
     */
    createdDocuments: false,
    /**
     * Ошибка, возникшая при создании документов.
     */
    creatingDocumentsError: null,
    /**
     * Сопоставления документов.
     */
    documentMatches: null,
    /**
     * Выбранный элемент.
     */
    selectedElement: null,
    /**
     * Выбранная строка.
     */
    selectedRow: null,
    /**
     * Пустой список элементов с заполненными чекбоксами.
     */
    markedElements: [],
    /**
     * Пустой список строк, отмеченных чекбоксами.
     */
    markedRows: [],
    /**
     * Состояние отображения просмотрщика скана документа.
     */
    showScanState: false,
    /**
     * Список закрытых сумм по счетам.
     */
    billClosedAmountList: [],
    /**
     * Созданные новые леджеры
     */
    createdLedgerList: [],
};
/**
 * Обработчик событий, связанных с изменением состояния сопоставления документов.
 *
 * @param state Текущее состояние сопоставления документов.
 * @param action Событие.
 *
 * @return Новое состояние.
 */
export function matchingReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        case MatchingActionType.START: {
            var documentMatches = action.documentMatches.slice();
            var bsTransactions = _.flatten(documentMatches.map(function (match) { return match.bankStatement; })
                .filter(Boolean)
                .map(function (bs) { return bs.transactions || []; }), true);
            var bsSubTransactions = _.flatten(bsTransactions.filter(Boolean)
                .map(function (transaction) { return transaction.subTransactions || []; }), true);
            var billClosedAmountList = bsTransactions.concat(bsSubTransactions).map(function (transaction) { return ({ bill: transaction.ledgerBill, amount: transaction.amount }); })
                .reduce(updateLedgerBillClosedAmountList, []);
            result = __assign({}, initialState, { documentMatches: documentMatches,
                billClosedAmountList: billClosedAmountList });
            break;
        }
        case MatchingActionType.SELECT_ELEMENT: {
            result = __assign({}, state, { selectedElement: action.value });
            break;
        }
        case MatchingActionType.SELECT_ROW: {
            result = __assign({}, state, { selectedRow: action.value });
            break;
        }
        case MatchingActionType.SWAP_COMPANIES: {
            var documentMatch = state.documentMatches[action.value];
            var updatedMatch = __assign({}, documentMatch, { document: __assign({}, documentMatch.document, { supplier: documentMatch.document.customer, customer: documentMatch.document.supplier }) });
            result = updateMatchingDocument(state, action.value, updatedMatch);
            break;
        }
        case MatchingActionType.SET_COMPANIES_AFTER_SWAP: {
            var documentMatch = __assign({}, state.documentMatches[action.matchIndex]);
            var newCompanyList = action.companies.map(function (company) { return ({
                externalCompany: company,
                historicalData: company.historicalData,
                probability: company.probability
            }); });
            var newCompany = action.companies.filter(function (company) { return company.probability > 0.7; })
                .reduce(function (prev, curr) { return ((prev && prev.probability || 0) > (curr && curr.probability || 0)) && prev || curr; }, null);
            if (action.companyType === "customer") {
                documentMatch = __assign({}, documentMatch, { customer: newCompany, matchCustomerList: newCompanyList });
            }
            else if (action.companyType === "supplier") {
                documentMatch = __assign({}, documentMatch, { supplier: newCompany, matchSupplierList: newCompanyList });
            }
            result = updateMatchingDocument(state, action.matchIndex, documentMatch);
            break;
        }
        case MatchingActionType.MARK_ELEMENT: {
            result = __assign({}, state, { markedElements: state.markedElements.concat([action.value]) });
            break;
        }
        case MatchingActionType.CHANGE_INVENTORY: {
            var array = state.markedElements
                .map(function (element) {
                if (element.matchIndex === action.value.matchIndex
                    && element.itemIndex === action.value.itemIndex) {
                    return __assign({}, element, { itemMode: action.value.itemMode });
                }
                return element;
            });
            result = __assign({}, state, { markedElements: array });
            break;
        }
        case MatchingActionType.UNMARK_DOCUMENT_ELEMENT: {
            var markedElements = state.markedElements
                .filter(function (element) { return element.matchIndex !== action.value.matchIndex; });
            result = __assign({}, state, { markedElements: markedElements });
            break;
        }
        case MatchingActionType.UNMARK_DOCUMENT_ITEM_ELEMENT: {
            var markedElements = state.markedElements
                .filter(function (element) {
                return (element.matchIndex !== action.value.matchIndex) || (element.itemIndex !== action.value.itemIndex);
            });
            result = __assign({}, state, { markedElements: markedElements });
            break;
        }
        case MatchingActionType.MARK_ALL_ELEMENTS: {
            result = __assign({}, state, { markedElements: action.value });
            break;
        }
        case MatchingActionType.UNMARK_ALL_ELEMENTS: {
            result = __assign({}, state, { markedElements: [] });
            break;
        }
        case MatchingActionType.SET_MARKED_ROWS: {
            result = __assign({}, state, { markedRows: action.value });
            break;
        }
        case MatchingActionType.UPDATE_LEDGER_BILL_CLOSED_AMOUNT: {
            result = __assign({}, state, { billClosedAmountList: updateLedgerBillClosedAmountList(state.billClosedAmountList, action.value) });
            break;
        }
        case MatchingActionType.CREATE_LEDGER: {
            result = __assign({}, state, { createdLedgerList: state.createdLedgerList.concat([action.value]) });
            break;
        }
        case MatchingActionType.EDIT_LEDGER: {
            var list = state.createdLedgerList;
            var index = list.findIndex(function (item) { return item.id === action.value.id; });
            if (index >= 0) {
                list[index] = action.value;
            }
            result = __assign({}, state, { createdLedgerList: list.slice() });
            break;
        }
        case MatchingActionType.CANCEL_LEDGER_DIALOG: {
            result = __assign({}, state, { createdLedgerList: state.createdLedgerList.slice() });
            break;
        }
        case MatchingActionType.CREATE_DOCUMENTS: {
            result = __assign({}, state, { creatingDocuments: true, createdDocuments: false, creatingDocumentsError: null });
            break;
        }
        case MatchingActionType.CREATE_DOCUMENTS_SUCCESS: {
            result = __assign({}, initialState, { createdDocuments: true });
            break;
        }
        case MatchingActionType.CREATE_DOCUMENTS_FAIL: {
            result = __assign({}, state, { creatingDocuments: false, createdDocuments: false, creatingDocumentsError: action.error });
            break;
        }
        case MatchingActionType.ADD_MATCH_AMOUNT_MODIFIER: {
            result = __assign({}, state, { documentMatches: state.documentMatches.map(function (match) {
                    if (match.id === action.documentMatch.id) {
                        var existModifiers = match.amountModifiers || [];
                        existModifiers.push(action);
                        return __assign({}, match, { amountModifiers: existModifiers });
                    }
                    else {
                        return match;
                    }
                }) });
            break;
        }
        case MatchingActionType.DELETE_MATCH_AMOUNT_MODIFIER: {
            result = __assign({}, state, { documentMatches: state.documentMatches.map(function (match, index) {
                    if (index === action.matchIndex) {
                        var amountModifiers = match.amountModifiers || [];
                        amountModifiers.splice(action.modifierIndex, 1);
                        return __assign({}, match, { amountModifiers: amountModifiers });
                    }
                    else {
                        return match;
                    }
                }) });
            break;
        }
        case MatchingActionType.DELETE_MATCH: {
            result = __assign({}, state, { documentMatches: state.documentMatches.filter(function (match) { return match.id !== action.id; }) });
            break;
        }
        case MatchingActionType.DELETE_MATCHES: {
            var matchIdArray_1 = [];
            for (var i = 0; i < action.value.length; i++) {
                matchIdArray_1.push(action.value[i].id);
            }
            result = __assign({}, state, { documentMatches: state.documentMatches.filter(function (match) { return !matchIdArray_1.includes(match.id); }), markedElements: [], selectedElement: null });
            break;
        }
        case MatchingActionType.OPEN_SCAN_VIEWER: {
            result = __assign({}, state, { showScanState: true });
            break;
        }
        case MatchingActionType.CLOSE_SCAN_VIEWER: {
            result = __assign({}, state, { showScanState: false });
            break;
        }
        case MatchingActionType.CANCEL: {
            result = initialState;
            break;
        }
    }
    return result;
}
/**
 * Обновляет сопоставление документа с указанным индексом в состоянии сопоставления.
 *
 * @param state Состояние сопоставления.
 * @param matchIndex Индекс сопоставления документа.
 * @param updatedMatch Обновлённое сопоставление документа.
 *
 * @return Новое состояние сопоставления.
 */
function updateMatchingDocument(state, matchIndex, updatedMatch) {
    return __assign({}, state, { documentMatches: state.documentMatches.slice(0, matchIndex).concat([
            __assign({}, updatedMatch)
        ], state.documentMatches.slice(matchIndex + 1)) });
}
/**
 * Обновляет список счетов с закрытыми по транзакциям суммами.
 *
 * Обновляет закрытую сумму указанного счёта или добавляет счёт в список, если его ещё нет.
 *
 * @param billClosedAmountList Список счетов с закрытыми по всем транзакциям суммами по ним.
 * @param billClosedAmount Счёт с суммой, закрытой по нему одной из транзакций.
 *
 * @return Обновлённый список счетов с закрытыми по всем транзакциям суммами по ним.
 */
function updateLedgerBillClosedAmountList(billClosedAmountList, billClosedAmount) {
    if (!billClosedAmount.bill || isBillWiseDetailType(billClosedAmount.bill.number)) {
        return billClosedAmountList;
    }
    var idx = billClosedAmountList.findIndex(function (billSum) { return billSum.bill.number === billClosedAmount.bill.number; });
    if (idx === -1) {
        return billClosedAmountList.concat([billClosedAmount]);
    }
    var updatedBillClosedAmount = __assign({}, billClosedAmountList[idx], { amount: billClosedAmountList[idx].amount + billClosedAmount.amount });
    return billClosedAmountList.slice(0, idx).concat([
        updatedBillClosedAmount
    ], billClosedAmountList.slice(idx + 1, billClosedAmountList.length));
}
