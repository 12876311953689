import { requestState } from "src/app/common/models/request-state";
import { TrustedEmailsAdditionActionType } from "src/app/root/store/actions/trusted-emails-addition.action";
import { TrustedEmailsAdditionAction } from "src/app/root/store/actions/trusted-emails-addition.action";
import { TrustedEmailsAdditionState } from "src/app/root/store/states/trusted-emails-addition.state";

/**
 * Начальное состояние добавления доверенных почт в пространства документов.
 */
const initialState: TrustedEmailsAdditionState = {
    addEmailsToSpaceRequest: requestState.initial()
};

/**
 * Обработка событий, связанных с состоянием добавления доверенных почт в пространства документов.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 *
 * @return Состояние добавления доверенных почт в пространства документов после обработки событий.
 */
export function trustedEmailsAdditionStateReducer(
    state: TrustedEmailsAdditionState = initialState,
    action: TrustedEmailsAdditionAction
): TrustedEmailsAdditionState {

    let result: TrustedEmailsAdditionState = state;

    switch (action.type) {

        case TrustedEmailsAdditionActionType.INITIALIZE: {

            result = initialState;
            break;
        }

        case TrustedEmailsAdditionActionType.ADD: {

            result = {
                ...state,
                addEmailsToSpaceRequest: requestState.pending(),
            };
            break;
        }

        case TrustedEmailsAdditionActionType.ADDITION_SUCCESS: {

            result = {
                ...state,
                addEmailsToSpaceRequest: requestState.success(action.value),
            };
            break;
        }

        case TrustedEmailsAdditionActionType.ADDITION_FAILED: {

            result = {
                ...state,
                addEmailsToSpaceRequest: requestState.fail(action.value),
            };
            break;
        }
    }

    return result;
}
