import { FeedbackType } from "src/app/root/models";
import { FeedbackActionType } from "../../actions";
import { FeedbackAction } from "../../actions";
import { FeedbackState } from "./feedback.state";

const initialState: FeedbackState = {
    sent: false,
    sending: false,
    error: null
};

/**
 * Обработка состояния обратнйо связи в ответ на события.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 */
export function feedbackReducer(state: FeedbackState = initialState, action: FeedbackAction): FeedbackState {

    let result: FeedbackState = {
        ...state
    };

    switch (action.type) {

        case FeedbackActionType.INIT: {

            result = {
                type: action.payload.type as FeedbackType,
                documentId: action.payload.documentId,
                ...initialState,
            };
            break;
        }
        case FeedbackActionType.SEND: {

            result = {
                ...result,
                sending: true,
                sent: false,
                error: null,
            };
            break;
        }

        case FeedbackActionType.SEND_SUCCESS: {

            result = {
                ...result,
                sending: false,
                sent: true,
                error: null,
            };
            break;
        }

        case FeedbackActionType.SEND_FAIL: {

            result = {
                ...result,
                sending: false,
                sent: false,
                error: action.payload,
            };
            break;
        }
    }

    return result;
}
