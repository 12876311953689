import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
/**
 * Сервис для работы с типами документов.
 */
var DocumentTypesService = /** @class */ (function () {
    //region Ctor
    function DocumentTypesService(httpClient) {
        this.httpClient = httpClient;
    }
    //endregion
    //region Public
    /**
     * Загрузка типов документов с сервера.
     */
    DocumentTypesService.prototype.get = function () {
        return this.httpClient.get('/api/v1/documentTypes')
            .pipe(map(function (response) { return response.documentTypes; }), catchError(function (response) { return throwError(response.error); }));
    };
    DocumentTypesService.ngInjectableDef = i0.defineInjectable({ factory: function DocumentTypesService_Factory() { return new DocumentTypesService(i0.inject(i1.HttpClient)); }, token: DocumentTypesService, providedIn: "root" });
    return DocumentTypesService;
}());
export { DocumentTypesService };
