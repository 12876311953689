var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DocumentsRegistryExportActionType } from "src/app/spaces/modules/documents-registry/store/actions/documents-registry-export.action";
var initialState = {
    loading: false,
    success: false,
    error: null,
};
/**
 * Обработка событий, связанных с состоянием выгрузки реестра документов в Excel-файл.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 *
 * @return ...
 */
export function documentsRegistryExportReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        case DocumentsRegistryExportActionType.EXPORT_DOCUMENTS_REGISTRY: {
            result = initialState;
            result = __assign({}, result, { success: false, loading: true, error: null });
            break;
        }
        case DocumentsRegistryExportActionType.EXPORT_DOCUMENTS_REGISTRY_SUCCESS: {
            result = __assign({}, result, { success: true, loading: false });
            break;
        }
        case DocumentsRegistryExportActionType.EXPORT_DOCUMENTS_REGISTRY_FAIL: {
            result = __assign({}, result, { success: false, loading: false, error: action.payload });
            break;
        }
    }
    return result;
}
