import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { IntegrationTypeActionProps } from "src/app/spaces/modules/document-matching/store/actions/props/integration-type.action.props";

/**
 * Типы событий, связанных с типами поставок.
 */
export enum SupplyTypesActionType {
    LOAD = "[Supply types] Load",
}

/**
 * Событие загрузки типов поставок с environment и сохранения их в хранилище.
 */
const load = createAction(SupplyTypesActionType.LOAD, props<IntegrationTypeActionProps>());

/**
 * Тип, объединяющий все события, связанные с типами поставок.
 */
export type SupplyTypesAction =
    | ReturnType<typeof load>
    ;

/**
 * Все события, связанные с типами поставок.
 */
export const supplyTypesActions = {
    load,
};
