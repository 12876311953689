import { createSelector } from "@ngrx/store";
import { getRequestResult } from "src/app/common/models/request-state";
import { documentMatchingModuleStateSelector } from "src/app/spaces/modules/document-matching/store/reducers/reducers";

/**
 * Селектор настроек сопоставления.
 */
const matchSettingsState = createSelector(
    documentMatchingModuleStateSelector,
    state  => state.matchSettingsState,
);

/**
 * Селектор состояния запроса настроек сопоставления.
 */
const loadRequestState = createSelector(
    matchSettingsState,
    state => state.matchSettingsRequest.type,
);

/**
 * Селектор состояния обновления настроек сопоставления.
 */
const updateRequestState = createSelector(
    matchSettingsState,
    state => state.matchSettingsUpdateRequest,
);

/**
 * Селектор настроек сопоставления.
 */
const matchSettings = createSelector(
    matchSettingsState,
    state => getRequestResult(state.matchSettingsRequest),
);

/**
 * Селекторы, связанные с настройками сопоставления.
 */
export const matchSettingsSelector = {
    matchSettingsState: matchSettingsState,
    loadRequestState: loadRequestState,
    updateRequestState: updateRequestState,
    matchSettings: matchSettings,
};
