var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { NewDocumentsCountActionType } from "../../actions";
/**
 * Состояние по данным о кол-ве новых документов.
 */
var NewDocumentsCountState = /** @class */ (function () {
    function NewDocumentsCountState() {
        /**
         * Кол-во новых документов.
         */
        this.newDocumentsCount = undefined;
        /**
         * Кол-во загружается?
         */
        this.loading = false;
        /**
         * Кол-во успешно загружено?
         */
        this.loaded = false;
        /**
         * Загрузка кол-ва завершилась ошибкой?
         */
        this.failed = false;
    }
    return NewDocumentsCountState;
}());
export { NewDocumentsCountState };
/**
 * Начальное состояние данных о кол-ве новых документов.
 */
var initialState = new NewDocumentsCountState();
/**
 * Обработка состояния данных о кол-ве новых документов в ответ на события.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 */
export function newDocumentsCountReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        // На событие загрузки данных меняем флаг загрузки.
        case NewDocumentsCountActionType.LOAD: {
            result = __assign({}, state, { loading: true, failed: false });
            break;
        }
        // На событие успешной загрузки данных меняем флаги и меняем данные о кол-ве.
        case NewDocumentsCountActionType.LOAD_SUCCESS: {
            var newDocumentsCount = action.payload;
            result = __assign({}, state, { newDocumentsCount: newDocumentsCount, loaded: true, loading: false });
            break;
        }
        // На событие неудачной загрузки данных меняем флаги.
        case NewDocumentsCountActionType.LOAD_FAIL: {
            result = __assign({}, state, { newDocumentsCount: undefined, loaded: false, loading: false, failed: true });
            break;
        }
        // На событие увеличения кол-ва новых документов.
        case NewDocumentsCountActionType.INCREMENT: {
            var newDocumentsCount = state.newDocumentsCount + action.payload;
            result = __assign({}, state, { newDocumentsCount: newDocumentsCount, loaded: false, loading: false, failed: true });
            break;
        }
        // На событие уменьшения кол-ва новых документов.
        case NewDocumentsCountActionType.DECREMENT: {
            var newDocumentsCount = Math.max(0, state.newDocumentsCount - action.payload);
            result = __assign({}, state, { newDocumentsCount: newDocumentsCount, loaded: false, loading: false, failed: true });
            break;
        }
        // На событие очистки состояния данных о кол-ва новых документов.
        case NewDocumentsCountActionType.DECREMENT: {
            result = new NewDocumentsCountState();
            break;
        }
    }
    return result;
}
