import { MatDialog } from "@angular/material";
import { FirstUploadDlgComponent } from "../containers/first-upload-dlg/first-upload-dlg.component"; /* circular dependency break */
import { Constants } from "../../common/models";
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
/**
 * Сервис для вызова диалога с подсказкой после первой загрузки файла на распознавание.
 */
var FirstUploadDlgService = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор сервиса для вызова диалога с подсказкой после первой загрузки файла на распознавание.
     *
     * @param dialog Сервис для работы с диалогами.
     */
    function FirstUploadDlgService(dialog) {
        this._dialog = dialog;
    }
    //endregion
    //region Public
    /**
     * Открывет диалог с подсказкой после первой загрузки файла на распознавание.
     */
    FirstUploadDlgService.prototype.open = function () {
        this._dialog.open(FirstUploadDlgComponent, {
            panelClass: [Constants.DIALOG_PANE_CLASS, "first-upload-dlg-pane"],
            autoFocus: false,
            disableClose: true,
        });
    };
    FirstUploadDlgService.ngInjectableDef = i0.defineInjectable({ factory: function FirstUploadDlgService_Factory() { return new FirstUploadDlgService(i0.inject(i1.MatDialog)); }, token: FirstUploadDlgService, providedIn: "root" });
    return FirstUploadDlgService;
}());
export { FirstUploadDlgService };
