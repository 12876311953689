var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { MatDialog } from "@angular/material";
import { PhoneConfirmDlgComponent } from "src/app/common/components/phone-confirm-dlg/phone-confirm-dlg.component";
import { PurchaseDlgComponent } from "src/app/common/components/purchase-dlg/purchase-dlg.component";
import { SimpleAlertDlgComponent } from "src/app/common/components/simple-alert-dlg/simple-alert-dlg.component";
import { SimplePromptDlgComponent } from "src/app/common/components/simple-prompt-dlg/simple-prompt-dlg.component";
import { TwoInputsDlgComponent } from "src/app/common/components/two-inputs-dlg/two-inputs-dlg.component";
import { WhiteRedGreenButtonsDlgComponent } from "src/app/common/components/white-red-green-buttons-dlg/white-red-green-buttons-dlg.component";
import { Constants } from "src/app/common/models";
import { BroadcastMessagesDlgComponent } from "src/app/root/containers/broadcast-messages-dlg/broadcast-messages-dlg.component";
import { SendToExternalSystemDlgComponent } from "src/app/root/containers/documents-external-system-dlg/send-to-external-system-dlg.component";
import { MoveDocumentsDlgComponent } from "src/app/root/containers/move-document-to-another-space-dlg/move-documents-dlg.component";
import { UserSpacesDlgComponent } from "src/app/root/containers/users-spaces-dlg/user-spaces-dlg.component";
import { CreateLedgerDlgComponent } from "src/app/common/components/create-ledger-dlg/create-ledger-dlg.component";
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
/**
 * Сервис для работы с диалогами.
 */
var DlgService = /** @class */ (function () {
    //region Ctor
    function DlgService(_dialog) {
        this._dialog = _dialog;
    }
    //endregion
    //region Public
    /**
     * Открывает диалог с заданным текстом.
     *
     * @param data I18n-ключ текста для отображения в диалоге.
     */
    DlgService.prototype.openSimpleDlg = function (data) {
        return this._dialog.open(SimpleAlertDlgComponent, {
            data: data,
            autoFocus: false,
            disableClose: data.disableClose,
            panelClass: ['entera-dlg', 'entera-simple-dlg']
        });
    };
    /**
     * Открывает диалог с заданным текстом и разноцветными кнопками.
     *
     * @param data I18n-ключ текста для отображения в диалоге.
     */
    DlgService.prototype.openWhiteRedGreenButtonsDlg = function (data) {
        return this._dialog.open(WhiteRedGreenButtonsDlgComponent, {
            data: data,
            autoFocus: false,
            disableClose: data.disableClose,
            panelClass: ["entera-dlg", "entera-simple-dlg"]
        });
    };
    /**
     * Открывает диалог с заданным текстом и пользовательским вводом.
     *
     * @param data I18n-ключ текста для отображения в диалоге.
     */
    DlgService.prototype.openSimplePromptDialog = function (data) {
        return this._dialog.open(SimplePromptDlgComponent, {
            data: data,
            autoFocus: true,
            disableClose: data.disableClose,
            panelClass: ['entera-dlg', 'entera-simple-dlg']
        });
    };
    /**
     * Открывает диалог с заданным текстом и двумя пользовательскими вводами.
     *
     * @param data Данные для отображения в диалоге.
     */
    DlgService.prototype.openTwoInputsDialog = function (data) {
        return this._dialog.open(TwoInputsDlgComponent, {
            data: data,
            autoFocus: true,
            disableClose: data.disableClose,
            panelClass: ['entera-dlg', 'entera-simple-dlg']
        });
    };
    /**
     * Открывает диалог заявки на покупку страниц.
     *
     * @param data I18n-ключ текста для отображения в диалоге.
     */
    DlgService.prototype.openPurchaseDialog = function (data) {
        return this._dialog.open(PurchaseDlgComponent, {
            data: data,
            autoFocus: true,
            disableClose: data.disableClose,
        });
    };
    /**
     * Открывает диалог подтверждения телефона пользователя.
     *
     * @param user Информация о пользователе системы.
     * @param callback Коллбэк для удачного подтверждения телефона.
     */
    DlgService.prototype.openPhoneConfirmDialog = function (user, callback) {
        return this._dialog.open(PhoneConfirmDlgComponent, {
            data: { user: user, callback: callback },
            autoFocus: true,
            disableClose: true,
        });
    };
    /**
     * Открывает диалог сообщений пользователям.
     */
    DlgService.prototype.openBroadcastMessagesDialog = function () {
        return this._dialog.open(BroadcastMessagesDlgComponent, {
            autoFocus: false,
            disableClose: true,
        });
    };
    /**
     * Открывает диалог отправки документов во внешнюю систему.
     */
    DlgService.prototype.openSendDocumentToExternalSystemDialog = function () {
        return this._dialog.open(SendToExternalSystemDlgComponent, {
            autoFocus: false,
            disableClose: true,
        });
    };
    /**
     * Открывает диалог перемещения документов из одной папки в другую.
     *
     * @param props Данные выбора документов конкретным пользователем.
     */
    DlgService.prototype.openMoveDocumentToAnotherSpaceDialog = function (props) {
        return this._dialog.open(MoveDocumentsDlgComponent, {
            data: __assign({}, props),
            autoFocus: false,
            disableClose: false,
        });
    };
    /**
     * Открывает диалог для работы со списком почт и списком папок.
     *
     * @param data Данные для диалога.
     */
    DlgService.prototype.openEmailsAndSpacesDlg = function (data) {
        return this._dialog.open(UserSpacesDlgComponent, {
            data: data,
            panelClass: [Constants.DIALOG_PANE_CLASS, "users-invitations-dlg-panel"],
            autoFocus: false,
            disableClose: true,
        });
    };
    /**
     * Открывает диалог для создния или редактирования контрагента.
     *
     * @param value Данные для диалога.
     */
    DlgService.prototype.openCreateLedgerDlg = function (value) {
        this._dialog.open(CreateLedgerDlgComponent, {
            data: value,
            autoFocus: false,
            disableClose: false,
        });
    };
    /**
     * Закрыть все открытые диалоги.
     */
    DlgService.prototype.closeAll = function () {
        this._dialog.closeAll();
    };
    DlgService.ngInjectableDef = i0.defineInjectable({ factory: function DlgService_Factory() { return new DlgService(i0.inject(i1.MatDialog)); }, token: DlgService, providedIn: "root" });
    return DlgService;
}());
export { DlgService };
