<entera-feedback
    *ngIf="!(sending$ | async)"
    [type]="type"
    (send)="sendHandler($event)"
>
</entera-feedback>
<mat-spinner
    *ngIf="sending$ | async"
    [diameter]="80"
>
</mat-spinner>
<button
    *ngIf="!(sending$ | async)"
    class="mat-dialog__cross-close-btn"
    mat-flat-button
    mat-dialog-close
    title='{{ "dialogs.close" | translate }}'
>
    <img src="/assets/images/cross-close-btn.svg" alt="">
</button>