import { DocumentRegistrySearchFields } from "src/app/spaces/modules/documents-registry/models/document-registry-search-fields.model";

/**
 * Состояние текстового поиска в реестре документов.
 */
export class DocumentRegistrySearchState implements DocumentRegistrySearchFields {
    //region Ctor

    constructor(
        /**
         * Поисковой запрос.
         */
        public search: string = null,
    ) { }

    //endregion
    //region Public

    /**
     * Возвращает состояние поиска для передачи их на сервер.
     */
    getStateForRequest(): any {

        const state: any = {};

        if (typeof this.search === "string" && this.search) {

            state.search = this.search;
        }

        return state;
    }

    /**
     * Состояние текстового поиска пустое?
     */
    isEmpty(): boolean {

        return (!this.search);
    }

    //endregion
    //region Static

    /**
     * На основе данных с формы поиска или из GET-параметров URL создаёт экземпляр 
     * класса DocumentRegistrySearchState.
     * 
     * @param someState Данные с формы поиска или GET-параметры из URL.
     */
    static from(someState: any): DocumentRegistrySearchState {

        const state = new DocumentRegistrySearchState();

        if (typeof someState.search === "string" && someState.search) {

            state.search = someState.search;
        }

        return state;
    }

    /**
     * Состояния поиска совпадают?
     * 
     * @param state Состояние поиска.
     * @param anotherState Другое состояние поиска.
     */
    static isSameStates(state: DocumentRegistrySearchState, anotherState: DocumentRegistrySearchState) {
        
        return (state && anotherState && state.search === anotherState.search);
    }

    //endregion
}
