import { OnDestroy } from "@angular/core";
import { OnInit } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { select } from "@ngrx/store";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { map } from "rxjs/operators";
import { ApiResponse } from "src/app/common/models/api-response";
import { BroadcastMessageResponse } from "src/app/common/models/broadcast-message-response.model";
import { BroadcastMessage } from "src/app/common/models/broadcast-message.model";
import { broadcastMessageDlgActions } from "src/app/root/store/actions/broadcast-message-dlg.action";
import { RootState } from "src/app/root/store/reducers/index";
import { broadcastMessagesDlgSelectors } from "src/app/root/store/selectors/broadcast-message-dlg.selector";
import { currentUserLangSelector } from "src/app/root/store/selectors/current-user.selector";
import { BroadcastMessagesDlgState } from "src/app/root/store/states/broadcast-messages-dlg.state";

/**
 * Компонент отображения диалога сообщений пользователю.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "broadcast-messages-dlg",
    templateUrl: "./broadcast-messages-dlg.component.html",
    styleUrls: ["./broadcast-messages-dlg.component.scss"],
})
export class BroadcastMessagesDlgComponent implements OnInit, OnDestroy {
    //region Public fields

    /**
     * Язык системы.
     */
    readonly lang$: Observable<string>;

    /**
     * Номер текущего сообщения.
     */
    readonly messageIndex$: Observable<number>;

    /**
     * Количество непрочитанных сообщений.
     */
    readonly messagesCount$: Observable<number>;

    /**
     *  Ошибка, полученная при отправке ответа на сервер.
     */
    readonly responseError$: Observable<ApiResponse>;

    /**
     * Текущее сообщение.
     */
    currentMessage: BroadcastMessage;
    /**
     * Группа контролов.
     */
    formGroup: FormGroup;

    //endregion
    //region Private fields

    /**
     *  Последняя дата изменения отображения сообщения. Необходима для подсчета времени прочтения сообщения.
     */
    private _messageViewChangeDate: number;

    /**
     * Сервис для управления и доступа к состоянию приложения.
     */
    private readonly _store: Store<RootState>;

    /**
     * Объект глобальной отписки.
     */
    private _globalUnsubscribe$: Subject<void> = new Subject();

    /**
     * Вспомогательный сервис для создания Reactive Forms.
     */
    private readonly _fb: FormBuilder;

    //endregion
    //region Ctor

    /**
     * Конструктор компонента отображения диалога для сообщений пользователям.
     *
     * @param store Сервис для управления и доступа к состоянию приложения.
     * @param fb Вспомогательный сервис для создания Reactive Forms.
     */
    constructor(store: Store<RootState>, fb: FormBuilder) {

        this._store = store;
        this._fb = fb;
        this.messageIndex$ = this._store.select(broadcastMessagesDlgSelectors.currentMessageIndex);
        this.messagesCount$ = this._store.select(broadcastMessagesDlgSelectors.messageCount);
        this.responseError$ = this._store.select(broadcastMessagesDlgSelectors.state)
            .pipe(
                map((broadcastMessagesDlgState: BroadcastMessagesDlgState): ApiResponse =>
                    broadcastMessagesDlgState.sendingResponseError
                ),
            );

        this._messageViewChangeDate = Date.now();
        this.lang$ = this._store
            .pipe(
                select(currentUserLangSelector)
            );

        this.formGroup = this._fb.group({ dontShowAgain: this._fb.control(false) });
    }

    //endregion
    //region Hooks

    /**
     * Выполняет логику при инициализации компонента.
     */
    ngOnInit(): void {

        this._store
            .pipe(
                takeUntil(this._globalUnsubscribe$),
                select(broadcastMessagesDlgSelectors.currentMessage),
            )
            .subscribe((message: BroadcastMessage): void => {

                this.currentMessage = message;
                this.formGroup.controls.dontShowAgain.setValue(message && message.dontShowAgain);
            });

        this._store
            .pipe(
                takeUntil(this._globalUnsubscribe$),
                select(broadcastMessagesDlgSelectors.messageCount),
                filter((messageCount: number) => (!messageCount)),
            )
            .subscribe(() => this.closeDialog());
    }

    /**
     * Выполняет логику при уничтожении компонента.
     */
    ngOnDestroy() {

        this._globalUnsubscribe$.complete();
    }

    //endregion
    //region Public

    /**
     * Выбирает предыдущее сообщение.
     */
    selectPreviousMessage(): void {

        this._store.dispatch(broadcastMessageDlgActions.selectPrevious());
        this._messageViewChangeDate = Date.now();
    }

    /**
     * Выбирает следующее сообщение.
     */
    selectNextMessage(): void {

        this._store.dispatch(broadcastMessageDlgActions.selectNext());
        this._messageViewChangeDate = Date.now();
    }

    /**
     * Закрывает диалог.
     */
    closeDialog(): void {

        this._store.dispatch(broadcastMessageDlgActions.close());
    }

    /**
     * Закрывает текущее сообщение.
     *
     * Если при этом указан флаг "больше не показывать" - посылает на сервер ответ "Интересует" для текущего сообщения.
     */
    closeCurrentMessage(): void {

        if (this.formGroup.controls.dontShowAgain.value) {

            this.sendInterestedResponse();
        }
        else {

            this._store.dispatch(broadcastMessageDlgActions.remindLaterClose({ message: this.currentMessage }));
        }
    }

    /**
     * Посылает на сервер ответ "Интересует" для текущего сообщения.
     */
    sendInterestedResponse(): void {

        this._store.dispatch(broadcastMessageDlgActions.sendResponse(this._buildBroadcastMessageResponse(true)));
    }

    /**
     * Посылает на сервер ответ "Не интересует" для текущего сообщения.
     */
    sendNotInterestedResponse(): void {

        this._store.dispatch(broadcastMessageDlgActions.sendResponse(this._buildBroadcastMessageResponse(false)));
    }

    /**
     * Эмиттит событие переключения флага "больше не показывать" для текущего сообщения.
     */
    dontShowAgainFlagToggle(): void {

        this._store.dispatch(broadcastMessageDlgActions.toggleDontShowAgainFlag({ message: this.currentMessage }));
    }

    //endregion
    //region Private

    /**
     * Формирует пейлод для использования его при отправке ответа на сообщение.
     *
     * @param interested Заинтересован ли пользователь в предложении?
     *
     * @return Пейлод для использования его при отправке ответа на сообщение.
     */
    private _buildBroadcastMessageResponse(interested: boolean): BroadcastMessageResponse {

        return {
            interested,
            watchedTime: Date.now() - this._messageViewChangeDate,
            comment: null,
        };
    }

    //endregion
}
