import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { ErrorResponseProps } from "src/app/root/store/actions/props/error-response.action-props";
import { IntegrationProps } from "src/app/spaces/modules/documents-registry/store/actions/props/integration.props";
import { SendToExternalSystemProps } from "src/app/spaces/modules/documents-registry/store/actions/props/send-to-external-system-action.props";
import { MassActionsWithDocumentsProps } from "src/app/spaces/modules/documents-registry/store/actions/props/mass-actions-with-documents.props";

/**
 * Типы событий связанные с отправкой документов во внешнюю систему.
 */
export enum SendToExternalSystemActionType {

    GET_DOCUMENTS_TO_SEND_TO_EXTERNAL_SYSTEM = "[Send document to external system] Get chosen documents",
    CHOOSE_EXTERNAL_SYSTEM = "[Send document to external system] Choose integration",
    SWITCH_TO_MATCHING = "[Send document to external system] Switch to matching",
    SEND_TO_EXTERNAL_SYSTEM = "[Send document to external system] Send document",
    SEND_TO_EXTERNAL_SYSTEM_SUCCESS = "[Send document to external system] Send document success",
    SEND_TO_EXTERNAL_SYSTEM_FAIL = "[Send document to external system] Send document fail",
}

/**
 * Событие массовой отправки документов в стороннюю систему.
 */
const sendDocumentsToExternalSystem = createAction(
    SendToExternalSystemActionType.GET_DOCUMENTS_TO_SEND_TO_EXTERNAL_SYSTEM,
    props<MassActionsWithDocumentsProps>(),
);

/**
 * Событие, требуещее выбора интеграции.
 */
const chooseIntegration = createAction(
    SendToExternalSystemActionType.CHOOSE_EXTERNAL_SYSTEM,
    props<SendToExternalSystemProps>(),
);

/**
 * Событие, требующее переключиться на сопоставление.
 */
const switchToMatching = createAction(
    SendToExternalSystemActionType.SWITCH_TO_MATCHING,
    props<SendToExternalSystemProps & IntegrationProps>(),
);

/**
 * Событие, требующее отправку документов во внешнюю систему.
 */
const sendToExternalSystem = createAction(
    SendToExternalSystemActionType.SEND_TO_EXTERNAL_SYSTEM,
    props<SendToExternalSystemProps & IntegrationProps>(),
);

/**
 * Событие успешной отправки документов во внешнюю систему.
 */
const sendToExternalSystemSuccessfully = createAction(
    SendToExternalSystemActionType.SEND_TO_EXTERNAL_SYSTEM_SUCCESS,
    props<SendToExternalSystemProps>(),
);

/**
 * Событие неудачной отправки документов во внешнюю систему.
 */
const sendToExternalSystemFailed = createAction(
    SendToExternalSystemActionType.SEND_TO_EXTERNAL_SYSTEM_FAIL,
    props<ErrorResponseProps<void>>(),
);

/**
 * Тип, объединяющий все события. связанные с отправкой документов во внешнюю систему.
 */
export type SendToExternalSystemAction =
    | ReturnType<typeof sendDocumentsToExternalSystem>
    | ReturnType<typeof switchToMatching>
    | ReturnType<typeof sendToExternalSystem>
    | ReturnType<typeof chooseIntegration>
    | ReturnType<typeof sendToExternalSystemSuccessfully>
    | ReturnType<typeof sendToExternalSystemFailed>;

/**
 * Все события, связанные с отправкой документов во внешнюю систему.
 */
export const sendToExternalSystemActions = {
    sendDocumentsToExternalSystem,
    switchToMatching,
    sendToExternalSystem,
    chooseIntegration,
    sendToExternalSystemSuccessfully,
    sendToExternalSystemFailed,
};
