import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { ErrorResponseProps } from "src/app/root/store/actions/props/error-response.action-props";
import { ValueProps } from "src/app/root/store/actions/props/value-props";
import { IntegrationProps } from "src/app/spaces/modules/documents-registry/store/actions/props/integration.props";
import { ExternalTallyCompanyGst } from "src/app/spaces/modules/document-matching/models/external-tally-company-gst";

/**
 * Типы событий, связанных с филиалами из Tally.
 */
export enum ExternalTallyCompanyGSTActionType {
    INIT = "[External Tally Company GST] Init request state",
    LOAD = "[External Tally Company GST] Load",
    LOAD_SUCCESS = "[External Tally Company GST] Successfully load",
    LOAD_FAIL = "[External Tally Company GST] Error occurred while loading",
}

/**
 * События инициализации состояния запроса филиалов из Tally.
 */
const init = createAction(ExternalTallyCompanyGSTActionType.INIT);

/**
 * Событие получения филиалов из Tally с сервера.
 */
const load = createAction(ExternalTallyCompanyGSTActionType.LOAD, props<IntegrationProps>());

/**
 * Событие успешного получения филиалов из Tally с сервера.
 */
const loadSuccess = createAction(ExternalTallyCompanyGSTActionType.LOAD_SUCCESS, props<ValueProps<ExternalTallyCompanyGst[]>>());

/**
 * Событие ошибки при получении филиалов из Tally с сервера.
 */
const loadFail = createAction(ExternalTallyCompanyGSTActionType.LOAD_FAIL, props<ErrorResponseProps<void>>());

/**
 * Тип, объединяющий все события, связанные с филиалов из Tally.
 */
export type ExternalTallyCompanyGSTAction =
    | ReturnType<typeof init>
    | ReturnType<typeof load>
    | ReturnType<typeof loadSuccess>
    | ReturnType<typeof loadFail>;

/**
 * Все события, связанные с филиалов из Tally
 */
export const externalTallyCompanyGstActions = {
    init,
    load,
    loadSuccess,
    loadFail,
};
