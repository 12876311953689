import { NewDocumentsCountAction } from "../../actions";
import { NewDocumentsCountActionType } from "../../actions";

/**
 * Состояние по данным о кол-ве новых документов.
 */
export class NewDocumentsCountState {

    /**
     * Кол-во новых документов.
     */
    newDocumentsCount: number = undefined;

    /**
     * Кол-во загружается?
     */
    loading: boolean = false;

    /**
     * Кол-во успешно загружено?
     */
    loaded: boolean = false;

    /**
     * Загрузка кол-ва завершилась ошибкой?
     */
    failed: boolean = false;
}

/**
 * Начальное состояние данных о кол-ве новых документов.
 */
const initialState: NewDocumentsCountState = new NewDocumentsCountState();

/**
 * Обработка состояния данных о кол-ве новых документов в ответ на события.
 * 
 * @param state Текущее состояние.
 * @param action Событие.
 */
export function newDocumentsCountReducer(
    state = initialState, 
    action: NewDocumentsCountAction
): NewDocumentsCountState {
    
    let result = state;

    switch (action.type) {

        // На событие загрузки данных меняем флаг загрузки.
        case NewDocumentsCountActionType.LOAD: {

            result = {
                ...state,
                loading: true,
                failed: false
            };
            break;
        }

        // На событие успешной загрузки данных меняем флаги и меняем данные о кол-ве.
        case NewDocumentsCountActionType.LOAD_SUCCESS: {

            const newDocumentsCount = action.payload;

            result = {
                ...state,
                newDocumentsCount,
                loaded: true,
                loading: false,
            };
            break;
        }

        // На событие неудачной загрузки данных меняем флаги.
        case NewDocumentsCountActionType.LOAD_FAIL: {

            result = {
                ...state,
                newDocumentsCount: undefined,
                loaded: false,
                loading: false,
                failed: true,
            };
            break;
        }

        // На событие увеличения кол-ва новых документов.
        case NewDocumentsCountActionType.INCREMENT: {

            const newDocumentsCount = state.newDocumentsCount + action.payload;
            result = {
                ...state,
                newDocumentsCount,
                loaded: false,
                loading: false,
                failed: true,
            };
            break;
        }

        // На событие уменьшения кол-ва новых документов.
        case NewDocumentsCountActionType.DECREMENT: {

            const newDocumentsCount = Math.max(0, state.newDocumentsCount - action.payload);
            result = {
                ...state,
                newDocumentsCount,
                loaded: false,
                loading: false,
                failed: true,
            };
            break;
        }

        // На событие очистки состояния данных о кол-ва новых документов.
        case NewDocumentsCountActionType.DECREMENT: {

            result = new NewDocumentsCountState();
            break;
        }
    }

    return result;
}