import { Component } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { FormControl } from "@angular/forms";

import { MatDialogRef } from "@angular/material";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { map } from "rxjs/operators";
import { RootState } from "src/app/root/store";
import { purchaseDialogActions } from "src/app/root/store/actions/purchase-dialog.action";
import { currentUserInfoSelector } from "src/app/root/store/selectors/current-user.selector";
import { PurchaseDialogState } from "src/app/root/store/states/purchase-dialog.state";
import { CurrentUserInfo } from "../../models";
import { ApiResponse } from "../../models";
import { purchaseDlgSelectors } from "../../store/selectors/purchase-dlg.selectors";


/**
 * Компонент простого диалога с вводом номера телефона.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'purchase-dlg',
    templateUrl: './purchase-dlg.component.html',
    styleUrls: ['./purchase-dlg.component.scss'],
})
export class PurchaseDlgComponent {
    //region Public fields

    /**
     * Контрол ввода телефона.
     */
    readonly phoneControl: FormControl = new FormControl();

    /**
     * Ошибка, полученная при отправке ответа на сервер.
     */
    readonly responseError$: Observable<ApiResponse>;

    /**
     * В данный момент производится запрос?
     */
    readonly loading$: Observable<boolean>;

    /**
     * Запрос завершен успешно?
     */
    readonly success$: Observable<boolean>

    //endregion
    //region Private fields

    /**
     * Сервис для управления и доступа к состоянию приложения.
     */
    private readonly _store: Store<RootState>;

    /**
     * Ссылка на компонент диалога.
     */
    private readonly _dialogRef: MatDialogRef<PurchaseDlgComponent>

    //endregion
    //region Ctor

    /**
     * Конструктор компонента диалога подтверждения телефона.
     *
     * @param dialogRef Ссылка на компонент диалога.
     * @param store Хранилище.
     */
    constructor(
        dialogRef: MatDialogRef<PurchaseDlgComponent>,
        store: Store<RootState>,
    ) {

        this._store = store;
        this._dialogRef = dialogRef;
        this.loading$ = this._store.select(purchaseDlgSelectors.state).pipe(
            map((state: PurchaseDialogState): boolean => state.requesting)
        );
        this.success$ = this._store.select(purchaseDlgSelectors.state).pipe(
            map((state: PurchaseDialogState): boolean => state.requested)
        );
        this.responseError$ = this._store.select(purchaseDlgSelectors.state).pipe(
            map((state: PurchaseDialogState): ApiResponse => state.error)
        );
        this._store.select(currentUserInfoSelector)
            .pipe(
                map((state: CurrentUserInfo): string => state.phone),
                take(1),
            )
            .subscribe((phoneRaw: string) => this.phoneControl.setValue(phoneRaw.replace(/(\D)+/g, '')));
    }

    //endregion
    //region Hooks

    /**
     * Выполняет логику закрытия диалога.
     */
    onClose() {

        this._dialogRef.close();
    }

    /**
     * Выполняет логику отправки номера телефона.
     */
    onConfirm() {

        this._store.dispatch(
            purchaseDialogActions.sendRequestPurchase({ value: this.phoneControl.value })
        );
    }

    //endregion
}
