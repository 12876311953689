import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { CurrentUserInfo } from "src/app/common/models";
import { ExpiryNotificationType } from "src/app/common/models/expiry-notification-type";
import { environment } from "src/environments/environment";
import { Space } from "../../models/space";
import { currentUserPermissionsSelector } from "rootStore";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { PermissionItem } from "src/app/common/models";
import { RootState } from "rootStore";

/**
 * Компонент фиксированного header'а сайта.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'entera-header',
    styleUrls: ['entera-header.component.scss'],
    templateUrl: 'entera-header.component.html'
})
export class EnteraHeaderComponent {
    //region Inputs

    /**
     * Текущий URL без query.
     */
    @Input()
    readonly path: string;

    /**
     * Входящие данные - данные текущего пользователя.
     */
    @Input()
    user: CurrentUserInfo;

    /**
     * Входящие данные - текущее пространство документов.
     */
    @Input()
    space: Space;

    /**
     * Входящие данные - кол-во доступных кредитов.
     */
    @Input()
    restCreditCount: number | string;

    /**
     * Показывать выбор пространств?
     */
    @Input()
    showSpacesInfo: boolean;

    /**
     * Показывать кнопку "How to start"?
     */
    @Input()
    howToStartButtonVisibility: boolean;

    /**
     * Текущий пользователь может приглашать хотя бы в одно своё пространство документов?
     */
    @Input()
    readonly canInviteInAnySpace: boolean;

    /**
     * Показывать плашку с уведомлением об истечении баланса/подписки?
     */
    @Input()
    showUserNotification: boolean;

    /**
     * Тип уведомления клиента об истечении.
     */
    @Input()
    expiryNotificationType: ExpiryNotificationType;

    //endregion
    //region Outputs

    /**
     * Исходящее событие - клик по лого.
     */
    @Output()
    logo = new EventEmitter<void>();

    /**
     * Исходящее событие - клик по кнопке интеграций.
     */
    @Output()
    integration = new EventEmitter<Space>();

    /**
     * Исходящее событие - требование перейти в личный кабинет пользователя.
     */
    @Output()
    userAccount = new EventEmitter<void>();

    /**
     * Исходящее событие - требование выйти из системы.
     */
    @Output()
    logout = new EventEmitter<void>();

    /**
     * Исходящее событие - требование создания нового пространство документов.
     */
    @Output()
    createSpace = new EventEmitter<void>();

    /**
     * Исходящее событие - требование перехода в выбранное пространство документов.
     */
    @Output()
    openSpace = new EventEmitter<Space>();

    /**
     * Исходящее событие - требование перехода в настройки выбранного пространства документов.
     */
    @Output()
    spaceSettings = new EventEmitter<Space>();

    /**
     * Исходящее событие - требование открыть диалог для приглашения пользователей в пространства документов.
     */
    @Output()
    readonly openInvitationDialog: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Исходящее событие - требование открыть диалог с неизвестной ошибкой.
     */
    @Output()
    readonly openErrorDialog: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Исходящее событие - требование перейти на страницу оплаты для пространства документов.
     */
    @Output()
    spaceBuy = new EventEmitter<Space>();

    /**
     * Исходящее событие - запрос открытия формы обратного звонка.
     */
    @Output()
    callBack = new EventEmitter<void>();

    /**
     * Исходящее событие - запрос открытия страницы базы знаний.
     */
    @Output()
    knowledgeBase = new EventEmitter<void>();

    /**
     * Исходящее событие - открыть диалог инструкции как начать пользоваться Entera.
     */
    @Output()
    readonly howToStart = new EventEmitter<boolean>();

    /**
     * Исходящее событие - клик по кнопке "Купить".
     */
    @Output()
    buyPages = new EventEmitter<void>();

    //endregion
    //region Fields

    /**
     * У текущего пользователя есть право создавать новые пространства документов?
     */
    readonly canCreateSpace$: Observable<boolean>;

    /**
     * Высота заголовка.
     */
    static readonly HEIGHT: number = 57;

    //endregion
    //region Ctor

    /**
     * Конструктор компоненты фиксированного header'а сайта.
     *
     * @param _store Состояние приложения.
     */
    constructor(
        private _store: Store<RootState>
    ) {
        this.canCreateSpace$ = this._store.select(currentUserPermissionsSelector)
            .pipe(
                map((value: { [key: string]: boolean }) =>
                    !!value[PermissionItem.CREATE_SPACE.value]
                ),
            );
    }

    /**
     * Отдельная кнопка "Как начать" включена только для триальных клиентов?
     */
    get separateBtnOnlyForTrials() {

        return environment.howToStart && environment.howToStart.onlyForTrials;
    }

    /**
     * Отображать ли отдельную кнопку как начать пользоваться Entera?
     */
    get showHowToStartBtn() {

        return environment.howToStart
            && (!this.separateBtnOnlyForTrials || (this.user && this.user.spaces.some(space => !space.paid)))
            && this.howToStartButtonVisibility;
    }

    /**
     * Возвращает кол-во кредитов/страниц доступных в папке.
     */
    get creditCount() {

        return this.restCreditCount;
    }

    //endregion
    //region Events

    /**
     * Обработчик клика по лого.
     */
    logoClick() {

        this.logo.emit();
    }

    /**
     * Обработчик клика по кнопке интеграций.
     */
    integrationClick() {

        this.integration.emit(this.space);
    }

    /**
     * Обработчик требования перейти в личный кабинет пользователя.
     */
    userAccountHandler() {

        this.userAccount.emit();
    }

    /**
     * Обработчик требования выйти из системы.
     */
    logoutHandler() {

        this.logout.emit();
    }

    /**
     * Обработчик требования открыть страницу создания нового пространства документов.
     */
    createSpaceHandler() {

        this.createSpace.emit();
    }

    /**
     * Обработчик требования открыть страницу выбранного пространства документов.
     */
    openSpaceHandler(space: Space) {

        this.openSpace.emit(space);
    }

    /**
     * Обработчик требования открыть страницу настроек выбранного пространства документов.
     */
    spaceSettingsHandler(space: Space) {

        this.spaceSettings.emit(space);
    }

    /**
     * Обработчик требования открыть диалог для приглашения пользователей в пространства документов.
     */
    openInvitationDialogHandler(): void {

        this.openInvitationDialog.emit();
    }

    /**
     * Обработчик требования открыть диалог с неизвестной ошибкой.
     */
    openErrorDialogHandler(): void {

        this.openErrorDialog.emit();
    }

    /**
     * Обработчик требования перейти на страницу оплаты для пространства документов.
     */
    buyHandler() {

        this.spaceBuy.emit(this.space);
    }

    callBackHandler() {

        this.callBack.emit();
    }

    /**
     * Обработчик события по клику на кнопку "База знаний".
     */
    knowledgeBaseHandler() {

        this.knowledgeBase.emit();
    }

    /**
     * Обработчик события по клику на кнопку "Как начать".
     */
    howToStartHandler() {

        this.howToStart.emit();
    }

    /**
     * Обработчик клика по кнопке "Купить".
     */
    buyPagesClick() {

        this.buyPages.emit();
    }

    //endregion
}
