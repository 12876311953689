import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { OnInit } from '@angular/core';
import { forwardRef } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { AfterViewInit } from "@angular/core";
import { FormControl } from '@angular/forms';
import { ControlValueAccessor } from '@angular/forms';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectChange } from "@angular/material";
import { ErrorStateMatcher } from '@angular/material';
import { MatSelect } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { MatSelectSearchComponent } from 'ngx-mat-select-search';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { Subscription } from 'rxjs';
import { fromEvent } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { take } from "rxjs/operators";
import { startWith } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { switchMap } from "rxjs/operators";
import { debounceTime } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { mergeMap } from "rxjs/operators";
import { withLatestFrom } from "rxjs/operators";
import { SearchFn } from "src/app/common/models/search-function.model";
import { PagedSearchFn } from "../../models";
import { UtilsService } from "../../services";

/**
 * Вариант для выбора в выпадашке.
 */
export interface Option {

    /**
     * ID значения.
     */
    id: string;

    /**
     * Тектовое представление значения.
     */
    name: string;
}

/**
 * Компонент поля формы для выбора из списка значений с возможностью поиска.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SingleSelectComponent),
            multi: true
        }
    ],
    selector: 'single-select',
    styleUrls: ['single-select.component.scss'],
    templateUrl: 'single-select.component.html'
})
export class SingleSelectComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {
    //region Inputs

    /**
     * Входящие данные - placeholder поля.
     */
    @Input()
    placeholder: string;

    /**
     * Входящие данные - какое поле варианта выбора отображать в выпадашке. По умолчанию "name".
     */
    @Input()
    set optionField(optionField: string) {

        this._optionField = optionField;
    }

    /**
     * Входящие данные - функция для получения текстового представления варианта выбора в вариантах выбора.
     */
    @Input()
    formatOption: (option: Option) => string;

    /**
     * Входящие данные - какое поле выбранного варианта отображать. По умолчанию "name".
     */
    @Input()
    set resultField(resultField: string) {

        this._resultField = resultField;
    }

    /**
     * Входящие данные - функция для получения текстового представления выбранного варианта.
     */
    @Input()
    formatResult: (option: Option) => string;

    /**
     * Входящие данные - какое поле варианта выбора использовать в качестве значения для контрола. 
     * По умолчанию, если это поле не задано, вариант выбора и будет являться значением контрола.
     */
    @Input()
    valueField: string;

    /**
     * Входящие данные - функция для сравнения вариантов выбора, которая будет использоваться в mat-select.
     * По умолчанию в mat-select попадает функция, выполняющая сравнение по полю id.
     * При установке valueField, по умолчанию будет функция, выполняющая сравнение по заданному полю valueField.
     */
    @Input('compareWith')
    userCompareWith: (o1: any, o2: any) => boolean;

    /**
     * Входящие данные - статические значения для выбора.
     */
    @Input()
    set options(options: Option[]) {

        this._options = options;
        this._disableOrEnableControl();
        this.searchControl.setValue("");
    }

    /**
     * Входящие данные - функция постраничной динамической ajax-загрузки значения для выбора.
     */
    @Input()
    pagedSearchFn: PagedSearchFn<Option> = null;

    /**
     * Входящие данные - функция динамической ajax-загрузки значения для выбора.
     */
    @Input()
    searchFn: SearchFn<Option> = null;

    /**
     * Входящие данные - задержка перед выполнением поиска после последнего нажатия на клавиши.
     */
    @Input()
    searchDebounce: number = 170;

    /**
     * Входящие данные - отключать выпадашку, когда вариантов для выбора нет?
     */
    @Input()
    set disableOnEmptyOptions(disableOnEmptyOptions: boolean) {

        this._disableOnEmptyOptions = disableOnEmptyOptions;
        this._disableOrEnableControl();
    }

    /**
     * Входящие данные - поиск по вариантам включён?
     */
    @Input()
    searchEnabled: boolean;

    /**
     * Входящие данные - placeholder, пока строка поиска пуста.
     */
    @Input()
    searchPlaceholder: string;

    /**
     * Входящие данные - placeholder, что пользователю нужно начать вводить, чтобы начался поиск.
     */
    @Input()
    startSearchPlaceholder: string;

    /**
     * Входящие данные - placeholder, когда поиск не вернул результатов.
     */
    @Input()
    noSearchResultsPlaceholder: string;

    /**
     * Входящие данные - отображать вариант выбора, который имеет значение "null"?
     */
    @Input()
    hasNullOption: boolean;

    /**
     * Входящие данные - текст вариант выбора, который имеет значение "null".
     */
    @Input()
    nullOptionText: string;

    /**
     * Входящие данные - подсказка при наведении на кнопку очистки выбранного значения.
     */
    @Input()
    clearBtnTitle: string;

    /**
     * Входящие данные - кнопка очистки выбранного значения включена?
     */
    @Input()
    clearBtnEnabled: boolean = true;

    /**
     * Входящие данные - отключение контрола.
     */
    @Input()
    set selectDisabled(disabled: boolean) {

        this._selectDisabled = disabled;
        this._disableOrEnableControl();
    }

    /**
     * Входящие данные - поле обязательно для заполнения?
     */
    @Input()
    required: boolean = false;

    /**
     * Входящие данные - текст ошибки, когда поле не заполнено, но является обязательным.
     */
    @Input()
    requiredErrorMessage: string;

    /**
     * Входящие данные - отображать custom ошибку для поля?
     */
    @Input()
    customError: boolean = false;

    /**
     * Входящие данные - текст custom-ошибки.
     */
    @Input()
    customErrorMessage: string;

    /**
     * Входящие данные - логика контрола, которая определяет, когда отображать контрол в состоянии ошибки.
     */
    @Input()
    errorStateMatcher: ErrorStateMatcher;

    /**
     * Размер старницы поиска
     */
    @Input()
    pageSize: number = 10;

    /**
     * Прелоадинг значения выпадашки при асинхронном поиске. Ищется только по id.
     */
    @Input()
    preloading: boolean = false;

    /**
     * Компактное отображение для таблицы включено?
     */
    @Input()
    tableCompact: boolean = false;

    //endregion
    //region Outputs

    /**
     * Исходящие данные - событие изменения выбранного элемента.
     */
    @Output()
    selectionChange: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();

    /**
     * Исходящие данные - события сброса выбранного элемента.
     */
    @Output()
    clearBtnClickEvent: EventEmitter<void> = new EventEmitter<void>();

    //endregion
    //region Public fields

    /**
     * Минимальная длина поискового запроса.
     */
    minLengthToSearch: number = 3;

    /**
     * Флаг, что длина поискового запроса достигла нужной длины для выполнения запроса.
     */
    searchLengthReached: boolean = false;

    /**
     * FormControl привязанный к выпадашке.
     */
    valueControl = new FormControl();

    /**
     * FormControl привязанный к полю поиска.
     */
    searchControl = new FormControl();

    /**
     * Отфилтрованные статические значения для выбора согласно поисковому запросу.
     */
    filteredOptions$: Observable<Option[]>;

    /**
     * Динамически загруженные варианты для выбора согласно поисковому запросу.
     */
    searchOptions$: BehaviorSubject<Option[]> = new BehaviorSubject([]);

    /**
     * Флаг выполняющейся загрузки динамических вариантов выбора.
     */
    loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    /**
     * Флаг того, что часть динамических вариантов выбора загружена.
     */
    loaded: boolean = false;

    /**
     * Выбранное значение.
     */
    selectedValue: Option = null;

    /**
     * Компонент выпадающего списка.
     */
    @ViewChild(MatSelect)
    matSelect: MatSelect;

    /**
     * Компонент поля поиска для выпадающего списка.
     */
    @ViewChild(MatSelectSearchComponent)
    selectSearch: MatSelectSearchComponent;

    //endregion
    //region Private fields

    /**
     * Callback, когда выбранное в выпадашке значение изменилось.
     *
     * @private
     */
    private _changeCallback: Function = (_: any) => {};

    /**
     * Callback, когда пользователь начал взаимодействовать с выпадашкой.
     *
     * @private
     */
    private _touchCallback: Function = (_: any) => {};

    /**
     * Поле для хранения значений для выбора.
     *
     * @private
     */
    private _options: Option[] = [];

    /**
     * Поле для хранения флага отключения контрола при отсутствии вариантов для выбора.
     *
     * @private
     */
    private _disableOnEmptyOptions: boolean = true;

    /**
     * Поле для хранения поля объетка, которое отображается в выпадашке.
     *
     * @private
     */
    private _optionField: string;

    /**
     * Поле для хранения поля объекта, которое отображается для выбранного значения.
     *
     * @private
     */
    private _resultField: string;

    /**
     * Функция для сравнения вариантов выбора по id.
     *
     * @private
     */
    private _compareWith = (o1: any, o2: any) => o1 && o2 && o1['id'] === o2['id'];

    /**
     * Функция для сравнения вариантов выбора по заданному полю.
     *
     * @private
     */
    private _compareWithField = (o1: any, o2: any) => o1 && o2 && o1[this.valueField] === o2[this.valueField];

    /**
     * Поле для хранения флага отключения контрола.
     *
     * @private
     */
    private _selectDisabled: boolean = false;

    /**
     * Флаг выполняющейся загрузки динамических вариантов выбора. Внутренее отображение.
     */
    private _loading: boolean = false;

    /**
     * Подписка на изменение списка найденных вариантов выбора. Нужна для второй и далее страницы AJAX поиска.
     */
    private _searchOptionSubscription: Subscription;

    /**
     * Все подписки которые необходимо завершить при уничтожении объекта.
     */
    private _subscriptions: Subscription [] = [];

    /**
     * Текущая страница для AJAX запроса поиска. Ставновится -1, если нет больше страниц для поиска.
     */
    private _currentSearchPage: number = 1;

    //endregion
    //region Ctor

    constructor(
        private _translateService: TranslateService,
        private _cd: ChangeDetectorRef,
        private _utilService: UtilsService,
    ) {}

    //endregion
    //region Hooks

    ngOnInit() {

        // Поиск среди статически заданных вариантов выбора.
        this.filteredOptions$ = this.searchControl.valueChanges
            .pipe(
                startWith(''),
                map(search => this.filterOptions(search))
            );

        this._subscriptions.push(

            this.valueControl.valueChanges
                .subscribe((value: Option) => {
                    this.selectedValue = value;
                    this._changeCallback(value);
                    setTimeout(() => this._cd.markForCheck(), 100);
                })
            ,

            this.loading$.subscribe(value => this._loading = value)
        );
    }

    ngAfterViewInit(): void {

        // Поиск динамически подгружаемых вариантов выбора.
        if (this.pagedSearchFn) {

            // Постраничный запрос активен
            let inProgress: boolean = false;

            let oldSearch;

            this._subscriptions.push(
                this.searchControl.valueChanges
                    .pipe(
                        debounceTime(this.searchDebounce),
                        startWith(''),
                        filter(search => oldSearch !== search),
                        filter(search => {

                            let result = search.length <= 1000;

                            if (!result) {

                                this.openAndSearch(search.substring(0, 1000));
                            }

                            return result
                        }),
                        switchMap((search: string) => {

                            this.searchOptions$.next([]);

                            oldSearch = search;

                            if (search.length < this.minLengthToSearch) {

                                this.searchLengthReached = false;
                                return of([] as Option[]);
                            }
                            else {

                                this._currentSearchPage = 1;
                                this.loading$.next(true);
                                this.searchLengthReached = false;

                                return this.pagedSearchFn(search, this._currentSearchPage, this.pageSize)
                            }
                        }),
                    )
                    .subscribe(options => {

                        if (options.length < this.pageSize) {

                            this._currentSearchPage = -1;
                        }
                        this.searchOptions$.next(options)
                    })
            );

            // Подписываемся на скролл панели
            this._subscriptions.push(
                this.matSelect.openedChange.pipe(
                        // Панель открыта
                        filter(Boolean),
                        // Переключаемся на событие скролла
                        mergeMap(() => fromEvent<Event>(this.matSelect.panel.nativeElement , 'scroll')),
                        debounceTime(50),
                        map((event: Event) => event.srcElement),
                        map((srcElement: Element) => srcElement.scrollHeight - (srcElement.scrollTop + srcElement.clientHeight)),
                        filter((offset: number) => offset < 10),
                        // Нет больше страниц для поиска
                        filter(() => this._currentSearchPage != -1),
                        withLatestFrom(this.searchControl.valueChanges),
                        filter(() => !inProgress),
                        mergeMap(([_, search]) => {
                            inProgress = true;
                            this._currentSearchPage++;
                            this.loading$.next(true);
                            return this.pagedSearchFn('' + search, this._currentSearchPage, this.pageSize)
                        }),
                        map((newOptions: Option[]) => {
                            if (newOptions.length < this.pageSize) {

                               this._currentSearchPage = -1;
                            }

                            inProgress = false;

                            return [... this.searchOptions$.getValue(), ...newOptions]
                        })
                    )
                    .subscribe((options: Option[]) => this.searchOptions$.next(options))
            );
        }
        else if (this.searchFn) {

            let oldSearch;

            this._subscriptions.push(
                this.searchControl.valueChanges
                    .pipe(
                        debounceTime(this.searchDebounce),
                        startWith(''),
                        filter(search => oldSearch !== search),
                        filter(search => {

                            let result = search.length <= 1000;

                            if (!result) {

                                this.openAndSearch(search.substring(0, 1000));
                            }

                            return result
                        }),
                        switchMap((search: string) => {

                            this.searchOptions$.next([]);

                            oldSearch = search;

                            if (search.length < this.minLengthToSearch) {

                                this.searchLengthReached = false;
                                return of([] as Option[]);
                            }
                            else {

                                this.loading$.next(true);
                                this.searchLengthReached = false;

                                return this.searchFn(search);
                            }
                        }),
                    )
                    .subscribe(options => {

                        if (options.length < this.pageSize) {

                            this._currentSearchPage = -1;
                        }
                        this.searchOptions$.next(options)
                    })
            );
        }

        // Подписываемся на изменение набора динамически загруженных вариантов выбора.
        // Это тот момент, когда загрузка значений завершилась.
        this._subscriptions.push(this.searchOptions$
            .subscribe((options: Option[]) => {
                this.loading$.next(false);
                this.loaded = !!(options && options.length);
            })
        );
    }

    ngOnDestroy() {

        this._subscriptions.forEach(sub => sub.unsubscribe());
    }

    //endregion
    //region ControlValueAccessor

    /**
     * Программная установка значения.
     *
     * Тут выполянется асинхронная подгрузка значения, если это необходимо.
     *
     * @param value Значение для установки.
     */
    writeValue(value: any) {

        if (value !== undefined && this.valueControl.value !== value) {

            if (value && value.id && value.id != 'null' && (this.pagedSearchFn || this.searchFn) && this.preloading) {

                if (this.pagedSearchFn) {

                    let subs = this.pagedSearchFn(null, null, null, [value.id])
                        .subscribe(opts => {

                            if (opts.length) {

                                this.valueControl.setValue(opts[0]);
                            }
                            else {

                                this.valueControl.setValue(value);
                            }

                            subs.unsubscribe();
                        });
                }
                else {

                    this.searchFn(null, [value.id])
                        .pipe(take(1))
                        .subscribe(opts => {

                            if (opts.length) {

                                this.valueControl.setValue(opts[0]);
                            }
                            else {

                                this.valueControl.setValue(value);
                            }
                        });
                }
            }
            else if (value && value.id && value.id != 'null' && this.options.length) {

                let original = this.options.filter((option: Option) => option.id === value.id)[0];
                if (original) {

                    this.valueControl.setValue({
                        ...original,
                        ...value
                    });
                }
                else {

                    this.valueControl.setValue(value);
                }
            }
            else {

                this.valueControl.setValue(value);
            }
        }
    }

    registerOnChange(fn: Function) {

        this._changeCallback = fn;
    }

    registerOnTouched(fn: Function) {

        this._touchCallback = fn;
    }

    //endregion
    //region Getters and Setters

    /**
     * Возвращает функцию для сравнения вараинтов выбора для mat-select.
     */
    get compareWith(): (o1: any, o2: any) => boolean {

        if (this.userCompareWith) {

            return this.userCompareWith;
        }

        if (this.valueField) {

            return this._compareWithField;
        }

        return this._compareWith;
    }

    /**
     * Возвращает Observable placeholder'а, пока строка поиска пуста.
     */
    get searchPlaceholder$(): Observable<string> {

        if (this.searchPlaceholder) {

            return of(this.searchPlaceholder);
        }
        else {

            return this._translateService.get('search.label');
        }
    }

    /**
     * Возвращает Observable placeholder'а, что пользователю нужно начать вводить, чтобы начался поиск.
     */
    get startSearchPlaceholder$(): Observable<string> {

        if (this.startSearchPlaceholder) {

            return of(this.startSearchPlaceholder);
        }
        else {

            return this._translateService.get('search.startTyping');
        }
    }

    /**
     * Возвращает Observable placeholder'а, когда поиск не вернул результатов.
     */
    get noSearchResultsPlaceholder$(): Observable<string> {

        if (this.noSearchResultsPlaceholder) {

            return of(this.noSearchResultsPlaceholder);
        }
        else {

            return this._translateService.get('search.empty.result');
        }
    }

    /**
     * Возвращает значения для выбора.
     */
    get options(): Option[] {

        return this._options;
    } 

    /**
     * Возвращает поле варианта выбора, которое отображается в выпадашке.
     */
    get optionField(): string {

        return (this._optionField ? this._optionField : 'name');
    }

    /**
     * Возвращает поле выбранного варианта, которое отображается.
     */
    get resultField(): string {

        return (this._resultField ? this._resultField : 'name');
    }

    /**
     * Placeholder для приглашения ввода для начала поиска виден?
     */
    get isStartTypingPlaceholderVisible(): boolean {

        return !!(
            (!this.options || this.options.length === 0)
            && (this.pagedSearchFn || this.searchFn)
            && !this._loading
            && !this.loaded
            && (!this.searchControl.value || !this.searchLengthReached)
        );
    }

    /**
     * Вариант с выбранным значением нужно отображать?
     */
    get isSelectedValueExists(): boolean {

        let result: boolean;

        if (this.selectedValue instanceof Object) {

            result = !!this.selectedValue.id;
        }
        else {

            result = !!this.selectedValue;
        }

        return result;
    }

    /**
     * Текст, что ничего не найдено виден?
     */
    get isNoSearchResultsVisible(): boolean {

        return (
            !this.isStartTypingPlaceholderVisible
            && !this._loading
            && !this.loaded
            && (this.pagedSearchFn || this.searchFn)
            && this.searchControl.value
        );
    }

    //endregion
    //region Public

    /**
     * Возвращает текстовое представление варианта для выбора.
     * 
     * @param option Вариант для выбора. 
     */
    getOptionText(option: Option): string {

        let text = '';

        if (this.formatOption) {

            text = this.formatOption(option);
        }
        else {

            text = option[this.optionField];
        }

        if (this.searchControl.value && text) {

            text = this._utilService.highlight(text, this.searchControl.value, "highlight");
        }

        return text;
    }

    /**
     * Возвращает текстовое представление выбранного варианта.
     *
     * @param selected Выбранный вариант.
     */
    getResultText(selected: Option): string {

        let text = '';

        if ((!selected || !selected.id || selected.id === 'null') && !!this.nullOptionText) {

            text = this.nullOptionText;
        }
        else if (this.formatResult) {

            text = this.formatResult(selected);
        }
        else {

            text = selected[this.resultField];
        }

        return text;
    }

    /**
     * Возвращает значение варианта для выбора, которое будет использоваться как значение всего контрола.
     * 
     * @param option Вариант для выбора.
     */
    getOptionValue(option: Option): any {

        let value = option;

        if (this.valueField) {

            value = option[this.valueField];
        }

        return value;
    }

    /**
     * Возвращает значение для варианта выбора обозначающего 'null'-значение.
     */
    getNullOptionValue(): any {

        let value: any = {
            id: 'null'
        }; 

        if (this.valueField) {

            value = 'null';
        }
        
        return value;
    }

    /**
     * Открывает выпадашку, подставляет заданную поисковую строку и запускает поиск.
     *
     * @param search Поисковая строка.
     */
    openAndSearch(search: string): void {

        if (!this.matSelect.panelOpen) {

            this.matSelect.open();
        }
        this.selectSearch.searchSelectInput.nativeElement.value = search;
        this.searchControl.setValue(search);
    }

    //endregion
    //region Events

    /**
     * Обработчик клика очистки значения выбранного в выпадашке.
     */
    clearBtnClickHandler(event: any) {

        this.valueControl.reset(null);

        this.clearBtnClickEvent.emit();

        // Предотвращаем дальнейшее всплытие, т.к. иначе выпадашка откроется.
        event.stopPropagation();
    }

    /**
     * Обработчик нажатия кнопок в поле поиска.
     */
    keydownHandler(event: any) {

        // Кнопки HOME и END останавливаем от всплытия, т.к. иначе mat-select сделает event.preventDefault()
        // для них, что не даст в поле поиска перемещатся в начало или конец поля.
        if (event.keyCode === 35 || event.keyCode === 36) {

            event.stopPropagation();
        }
    }

    selectionChangeHandler(event: MatSelectChange) {

        this.selectionChange.next(event);
    }

    //endregion
    //region Private

    /**
     * Фильтрует варианты для выбора согласно поиковой строке.
     * 
     * @param search Поисковая строка.
     */
    private filterOptions(search: string): Option[] {

        search = search.toLowerCase();
        return this.options.filter(option => this.getOptionText(option).toLowerCase().includes(search));
    }

    /**
     * Включает или отключает контрол в зависимости от входящих данных.
     */
    private _disableOrEnableControl() {

        if (this._selectDisabled) {

            this.valueControl.disable();
        }
        else {

            if (
                this._disableOnEmptyOptions
                && (!this.options || this.options.length === 0)
                && !this.pagedSearchFn
                && !this.searchFn
            ) {

                this.valueControl.disable();
            }
            else {
    
                this.valueControl.enable();
            }
        }
    }

    //endregion
}
