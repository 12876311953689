import { PermissionItem } from "src/app/common/models";
import { PermissionGuard } from "./permission.guard";
import * as i0 from "@angular/core";
import * as i1 from "./permission.guard";
/**
 * Route Guard.
 * Проверка наличия права ADMIN для входа в раздел администрирования.
 */
var AdminGuard = /** @class */ (function () {
    //region Ctor
    function AdminGuard(_permissionGuard) {
        this._permissionGuard = _permissionGuard;
    }
    //endregion
    //region Public
    AdminGuard.prototype.canLoad = function (route) {
        return this._permissionGuard.hasAnyPermission([PermissionItem.ADMIN_IMPERSONATE, PermissionItem.CLIENT_CONTROL], route.path);
    };
    AdminGuard.ngInjectableDef = i0.defineInjectable({ factory: function AdminGuard_Factory() { return new AdminGuard(i0.inject(i1.PermissionGuard)); }, token: AdminGuard, providedIn: "root" });
    return AdminGuard;
}());
export { AdminGuard };
