import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { Actions } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { withLatestFrom } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { map } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { PermissionItem } from "src/app/common/models/index";
import { CurrentUserInfo } from "src/app/common/models/index";
import { Space } from "src/app/common/models/space";
import { DlgService } from "src/app/common/services/dlg.service";
import { RootState } from "src/app/root/store/reducers/index";
import { currentUserInfoSelector } from "src/app/root/store/selectors/current-user.selector";
import { currentUserSpacesSelector } from "src/app/root/store/selectors/current-user.selector";
import { RouterAction } from "../actions";
import { RouterActionPayload } from "../actions";
import { RouterActionType } from "../actions"; /* circular dependency break */
import { RouterGoAction } from "../actions"; /* circular dependency break */

/**
 * Side-эффекты на события, связанные с изменением URL'а.
 */
@Injectable()
export class RouterEffects {
    //region Constants

    /**
     * Regex-выражение для проверки, является ли URL ссылкой на реестр документов.
     */
    public static DOCUMENT_REGISTRY_URL_REGEX: RegExp = /spaces\/(.+)\/documents/;

    //endregion
    //region Fields

    /**
     * Сервис для создания диалоговых окон.
     */
    private _dlgService: DlgService;

    /**
     * Сервис для работы с i18n-сообщениями.
     */
    private _translateService: TranslateService;

    //endregion
    //region Ctor

    /**
     * Конструктор Side-эффектов на события, связанные с изменением URL'а.
     * @param _actions$ Экшены.
     * @param _router Сервис для работы с навигацией по приложению.
     * @param _location Сервис для работы с URL.
     * @param _store Сервис для доступа и управления состоянием приложения.
     * @param dlgService Сервис для создания диалоговых окон.
     * @param translateService Сервис для работы с i18n-сообщениями.
     *
     */
    constructor(
        private _actions$: Actions,
        private _router: Router,
        private _location: Location,
        private _store: Store<RootState>,
        dlgService: DlgService,
        translateService: TranslateService,
    ) {
        this._dlgService = dlgService;
        this._translateService = translateService;
    }

    //endregion
    //region Public

    /**
     * Обработка события перехода по заданному URL'у.  
     */
    @Effect({ dispatch: false })
    navigate$ = this._actions$
        .pipe(
            ofType(RouterActionType.GO),
            map((action: RouterGoAction) => action.payload),
            tap(({ path, query: queryParams, extras }) => {
                this._router.navigate(path, { queryParams, ...extras });
            }),
        );

    /**
     * Обработка события перехода по заданному URL'у.
     *
     * В случае отсутствия у текущего пользователя спейс с заданным в URL ID перенаправляет на '/spaces'.
     */
    @Effect()
    redirectToSpaces$ = this._actions$.pipe(
        ofType(RouterActionType.GO),
        map((action: RouterGoAction): RouterActionPayload => action.payload),
        map((payload: RouterActionPayload): string => payload.path.join("/")),
        map((path: string): string => decodeURI(path)),
        filter((path: string): boolean => RouterEffects.DOCUMENT_REGISTRY_URL_REGEX.test(path)),
        map((path: string): RegExpMatchArray => path.match(RouterEffects.DOCUMENT_REGISTRY_URL_REGEX)),
        map((regexMatcher: RegExpMatchArray): string => regexMatcher[1]),
        withLatestFrom(this._store.select(currentUserSpacesSelector)),
        filter(([spaceId, spaces]: [string, Space[]]): boolean => spaces.every((space: Space) => space.id !== spaceId)),
        map(([spaceId, spaces]: [string, Space[]]) => spaceId),
        withLatestFrom(this._store.select(currentUserInfoSelector)),
        map(([spaceId, currentUser]: [string, CurrentUserInfo]) => currentUser.permissions),
        filter((permissions: string[]) => !permissions.includes(PermissionItem.SEE_ALL_DOCUMENTS.value)),
        map((): RouterAction => new RouterGoAction({ path: ["/spaces"] })),
    );

    /**
     * Обработка события возврата назад по истории URL'ов.
     */
    @Effect({ dispatch: false })
    navigateBack$ = this._actions$
        .pipe(
            ofType(RouterActionType.BACK),
            tap(() => this._location.back()),
        );

    /**
     * Обработка события перехода вперёд по истории URL'ов.
     */
    @Effect({ dispatch: false })
    navigateForward$ = this._actions$
        .pipe(
            ofType(RouterActionType.FORWARD),
            tap(() => this._location.forward()),
        );

    //endregion
}
